import { combineReducers } from 'redux';
import { photosReducer } from './data-table/dataTable.reducer';
import { PhotoMetadata } from './data-table/dataTable.types';
import { userReducer } from './user/user.reducer';
import { UserState } from './user/user.types';
import { ContentUploadState } from './content-upload/content-upload.types';
import { contentUploadReducer } from './content-upload/content-upload.reducer';
import { channelReducer } from './channel-routes/channel.reducer';
import { modalReducer } from './modal-visibility/modal.reducer';
import { ModalState } from './modal-visibility/modal.types';
import { SignInBannerState } from './sign-in-banner/sign-in-banner.types';
import { connectRouter, RouterState } from 'connected-react-router';
import {TranslationsState} from "./translations/translations.types";
import { translationsReducer } from "./translations/translations.reducer";
import { signInBannerReducer } from "./sign-in-banner/sign-in-banner.reducer";
import {persistReducer} from "redux-persist";
import storage from 'redux-persist/lib/storage';
import { PersistPartial } from 'redux-persist/lib/persistReducer';
import {photoDetailsReducer} from './photo-details/photo-details.reducer';
import {PhotoDetailsState} from './photo-details/photo-details.types';
import {ChannelState} from './channel-routes/channel.types';
import { registerModalWraperReducer } from "./register-modal-wraper/register-modal-wraper.reducer";
import {RegisterWraperState} from "./register-modal-wraper/register-modal-wraper.types"
import { SearchBarState } from './search-bar/search-bar.types';
import { searchBarReducer } from './search-bar/search-bar.reducer';
import {CartState} from "./cart/cart-page.types";
import cartReducer from "./cart/cart-page.reducer";
import { PageState } from './error-page/error-page.types';
import pageReducer from './error-page/error-page.reducer';
import { SubscriptionState } from "./subscriptions/subscription-page.types";
import subscriptionReducer from "./subscriptions/subscription-page.reducer";
import { OnboardingState } from './onboarding/onboarding.types';
import { onboardingReducer } from './onboarding/onboarding.reducer';
import { LocalContentState } from './local-content/local-content.types';
import { localContentReducer } from './local-content/local-content.reducer';
import { TableFilterState } from './table-filter/table-filter.types';
import { tableFilterReducer } from './table-filter/table-filter.reducer';
import { downloadReducer } from './Downloads/downloads.reducer';
import { DownloadState } from './Downloads/downloads.types';
import { LightBoxState } from './lightbox/lightbox.types';
import lightBoxReducer from './lightbox/lightbox.reducer';


export interface StoreState {
    photos: PhotoMetadata[];
    user: UserState & PersistPartial;
    router: RouterState & PersistPartial;
    searchBar: SearchBarState;
    modal: ModalState;
    translations: TranslationsState;
    contentUpload: ContentUploadState;
    channels: ChannelState & PersistPartial;
    signin: SignInBannerState;
    cart: CartState & PersistPartial;
    photoDetails: PhotoDetailsState & PersistPartial;
    registerWraper: RegisterWraperState;
    onboarding: OnboardingState & PersistPartial;
    localContent: LocalContentState;
    errorPage: PageState;
    subscriptionToBuy: SubscriptionState & PersistPartial;
    tableFilters: TableFilterState;
    downloads: DownloadState;
    lightbox: LightBoxState;
}

const userConfig = {
    key: 'user',
    storage: storage,
    blacklist: ['userLocation', 'toggleUserLocation','userCurrentLocationLoading']
}

const cartConfig = {
    key: 'cart',
    storage: storage,
    blacklist: ['taxForOrder', 'cartError']
}

const channelConfig = {
    key: 'channel',
    storage: storage
}

const detailsConfig = {
    key: 'details',
    storage: storage
}

const subscriptionConfig = {
    key: 'subscriptionToBuy',
    storage: storage,
    blacklist: ['subscriptionBroadcast'],
    timeout: undefined
}

const onboardingConfig = {
    key: 'onboarding',
    storage: storage,
    blacklist: ['aboutYou', 'profileImage', 'isOnboardingValid', 'onboardingError']
}

const routerConfig = {
    key: 'router',
    storage: storage,
    whitelist: ['router']
}

const photoDetailsConfig = {
    key: 'photoDetails',
    storage: storage,
    whitelist: ['item']
}

export const rootReducer = (history: any) => combineReducers<StoreState>({
    photos: photosReducer,
    user: persistReducer(userConfig, userReducer),
    router: persistReducer(routerConfig, connectRouter(history)),
    searchBar: searchBarReducer,
    modal: modalReducer,
    translations: translationsReducer,
    contentUpload: contentUploadReducer,
    channels : persistReducer(channelConfig, channelReducer),
    signin: signInBannerReducer,
    cart: persistReducer(cartConfig, cartReducer),
    photoDetails: persistReducer(photoDetailsConfig, photoDetailsReducer),
    registerWraper:registerModalWraperReducer,
    onboarding: persistReducer(onboardingConfig, onboardingReducer) ,
    localContent: localContentReducer,
    errorPage:pageReducer,
    subscriptionToBuy: persistReducer(subscriptionConfig, subscriptionReducer),
    tableFilters: tableFilterReducer,
    downloads: downloadReducer,
    lightbox: lightBoxReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
