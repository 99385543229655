export enum BannerActionTypes {
    TOGGLE_BANNER_HIDDEN = 'TOGGLE_BANNER_HIDDEN',
    SET_BANNER_SHOWN='SET_BANNER_SHOWN',
    SET_REGISTER_TYPE='SET_REGISTER_TYPE',
}

export interface SignInBannerState{
    hidden: boolean;
    shown: boolean;
    registerType:string;
}