import React from 'react';
import { AppBar, Tabs, Tab, MuiThemeProvider, Tooltip } from '@material-ui/core';
import { ArticleTabsProps, ContentSet, ArticleTabsTheme, SeveritySnackbarEnum, IBroadcastMessage } from './ContentUpload.types';
import { StoreState } from '../../redux/root-reducer';
import { selectChangesToBeSaved, selectContentSets } from '../../redux/content-upload/content-upload.selectors';
import { Dispatch } from 'redux';
import { TContentUploadActions, IBroadcastContentUploadMessage } from '../../redux/content-upload/content-upload.actions';
import { ContentUploadActionTypes } from '../../redux/content-upload/content-upload.types';
import { connect } from 'react-redux';
import TabPanel from './ArticleTabPanel';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import './AddArticle.styles.scss';

const ArticleTabsComponent: React.FC<ArticleTabsProps> = ({ ...props }) => {
    const { contentSetsState, changesToBeSaved, broadcastUploadErrorAction } = props;
    const [value, setValue] = React.useState(0);

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        if (!changesToBeSaved) {
            setValue(newValue);
        } else {
            broadcastUploadErrorAction({
                severity: SeveritySnackbarEnum.warning,
                message: "Changes to article are not saved. Saved them in order to continue"
            });
        }
    }

    const a11yProps = (index: number) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <MuiThemeProvider theme={ArticleTabsTheme}>
            <div className='article-tabs-container'>
                <AppBar position="static">
                    <Tabs value={value} onChange={handleTabChange} variant="scrollable" scrollButtons="auto"
                        aria-label="simple tabs example">
                        {contentSetsState ? contentSetsState.map((set: ContentSet) => (
                            <Tooltip key={set.id} title={set.title} disableFocusListener disableTouchListener>
                                <Tab key={set.id} label={set.title.length > 15 ? set.title.substring(0, 15).concat('...') : set.title} style={{
                                    background: set.coverPhoto ? `linear-gradient(rgba(255,255,255,0.5), rgba(255,255,255,0.5)), url(${set.coverPhoto?.url})` : 'none',
                                    backgroundSize: 'cover'
                                }}
                                    {...a11yProps(contentSetsState.indexOf(set))} />
                            </Tooltip>)) : null}
                    </Tabs>
                </AppBar>
                {contentSetsState.map((set: ContentSet) => (
                    <TabPanel key={set.id} value={value} index={contentSetsState.indexOf(set)} />
                ))}
            </div>
        </MuiThemeProvider>
    )
}

const mapStateToProps = (state: StoreState): { contentSetsState: ContentSet[], changesToBeSaved: boolean } => {
    return {
        contentSetsState: selectContentSets(state),
        changesToBeSaved: selectChangesToBeSaved(state)
    }
}

const mapDispatchToProps = (dispatch: Dispatch<TContentUploadActions>) => {
    return {
        broadcastUploadErrorAction: (data: IBroadcastMessage) => dispatch<IBroadcastContentUploadMessage>({
            type: ContentUploadActionTypes.BROADCAST_MESSAGE, data: data
        })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArticleTabsComponent);