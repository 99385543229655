import gql from 'graphql-tag';

export const LOGOUT_USER = gql`
    mutation logoutUserMutation {
        logout
    }
`;

export const CHANNEL_COVERS = gql`
    query getChannelCoversQuery {
        getChannelCovers{
            channelId
            title
            filePath
            code
          }
    }
`;