import React, { useEffect } from 'react';
import { Route } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { CURRENT_USER } from '../login/queries'
import { connect } from 'react-redux';
import { User, UserActionTypes } from '../../redux/user/user.types';
import { StoreState } from '../../redux/root-reducer';
import { ILoginSuccess, TUserReducerActions } from "../../redux/user/user.actions";
import { Dispatch } from "redux";
import { CallHistoryMethodAction } from "connected-react-router";
import { PrivateRouteProps, ContributorsRoutes, ProMemberRoutes, MemberRoutes, AdminRoutes } from './privateRouter.types'
import ErrorPageComponent from '../error-page/errorPage.component'
import { RoleTypes } from '../register/register.types'
import { selectCurrentUser } from '../../redux/user/user.selectors';

export const checkPath = (routes: any[], path: string): boolean => {
    let hasAccess: boolean = false;
    for (let key in routes) {
        if (routes[key] === path) {
            hasAccess = true;
            break;
        }
    }
    return hasAccess;
}

const PrivateRoute: React.FC<PrivateRouteProps> = (props) => {

    const { currentUser, loginSuccessAction } = props;

    const { data, loading, error } = useQuery(CURRENT_USER);

    const currentPath = props.path
    const contributorsRoutes = Object.values(ContributorsRoutes)
    const proMembersRoutes = Object.values(ProMemberRoutes)
    const membersRoutes = Object.values(MemberRoutes)
    const adminRoutes = Object.values(AdminRoutes)

    function isEmpty(obj: Object) {
        for (let key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    useEffect(() => {
        if (!loading && data && data.currentUser) {
            loginSuccessAction(currentUser);
        }
    }, [data]);

    const checkPathByRole = (): boolean => {
        let hasAccess: boolean = false;
        if (!isEmpty(currentUser)) {
            switch (currentUser.role) {
                case (RoleTypes.Contributor_individual):
                case (RoleTypes.Contributor_agency):
                    hasAccess = checkPath(contributorsRoutes, currentPath);
                    break;
                case (RoleTypes.Pro_organization):
                case (RoleTypes.Pro_freelancer):
                    hasAccess = checkPath(proMembersRoutes, currentPath);
                    break;
                case (RoleTypes.Member):
                    hasAccess = checkPath(membersRoutes, currentPath);
                    break;
                case (RoleTypes.Admin):
                case (RoleTypes.SuperAdmin):
                    hasAccess = checkPath(adminRoutes, currentPath);
                    break;

            }
        }

        return hasAccess;
    }

    const redirectToPath = () => {
        let hasAccess: boolean = checkPathByRole();
        if (hasAccess) {
            return <Route path={props.path} exact={props.exact} component={props.component} />
        }
        else {
            return <ErrorPageComponent history={props.path} />
        }
    }

    return (
        <React.Fragment>
            {redirectToPath()}
        </React.Fragment>
    )

};

const mapStateToProps = (state: StoreState): { currentUser: User } => {
    return {
        currentUser: selectCurrentUser(state),
    }
}

const mapDispatchToProps = (dispatch: Dispatch<TUserReducerActions | CallHistoryMethodAction>) => {
    return {
        loginSuccessAction: (data: User) => {
            dispatch<ILoginSuccess>({ type: UserActionTypes.LoginSuccess, data: data });
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);


