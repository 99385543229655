import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {translationsEN} from '../locales/en/en_translations';
import {translationsDE} from '../locales/de/de_translations';
import {translationsBG} from '../locales/bg/bg_translations';
import {translationsSE} from "../locales/se/se_translations";
import {translationsFR} from "../locales/fr/fr_translations";
import {translationsIT} from "../locales/it/it_translations";
import {initReactI18next} from "react-i18next";

i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources: {
            en: {
                translations: translationsEN
            },
            de: {
                translations: translationsDE
            },
            bg: {
                translations: translationsBG
            },
            se: {
                translations: translationsSE
            },
            fr: {
                translations: translationsFR
            },
            it: {
                translations: translationsIT
            }
        },
        fallbackLng: 'en',
        debug: true,
        ns: ["translations"],
        defaultNS: "translations",
        keySeparator: false,
        interpolation: {
            formatSeparator: ', '
        },
        react: {
            wait: true
        }
    }
).then((result) => {
    // console.log("Public: i18n plugin loaded");
});

export default i18n;