import React, { useEffect, useState } from 'react';
import { TextField, Accordion, AccordionSummary, Select, MenuItem, AccordionActions, Button } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useTranslation } from "react-i18next";
import { EditorsAccordionProps } from './onboarding-page.types';
import './onboarding-page.styles.scss'
import { GET_JOB_TITLE_PROMEMBER } from './queries';
import { useQuery } from '@apollo/react-hooks';
import { useFormik } from "formik";
import { OnboardingEditorsList } from './yup-validation-objects';
import { IBroadcastOnboardingError, IClearOnboardingBroadcast, IEditorsInformation, IValidEditorsAccordion, TOnboardingReducerAction } from '../../redux/onboarding/onboarding.actions';
import { Dispatch } from 'redux';
import { EditorsInformation, OnboardingActionTypes } from '../../redux/onboarding/onboarding.types';
import { connect } from 'react-redux';
import { validateEditorsAccordion } from '../../redux/onboarding/onboarding.functions';
import { IBroadcastMessage, SeveritySnackbarEnum } from '../../components/batch-upload/ContentUpload.types';
import { StoreState } from '../../redux/root-reducer';
import { selectEditorsInformation, selectOnboardingError } from '../../redux/onboarding/onboarding.selectors';
import { SnackbarComponent } from "shared";


const EditorsAccordionComponent: React.FC<EditorsAccordionProps> = ({ ...props }) => {
    const { editorsInformation, accordion, isSaveButtonClicked, isAddEditorButtonCLicked, onboardingError, validateEditorsAccordionAction,
        broadcastOnboardingErrorAction, clearOnboardingBroadcastAction, handleDelete, addNewEditor, addEditorAccordion } = props;
    const { t } = useTranslation();
    const validationSchema = OnboardingEditorsList;
    const [open, setOpen] = useState(true);

    //job titles query
    const { data } = useQuery(GET_JOB_TITLE_PROMEMBER, { fetchPolicy: "no-cache" });
    const [jobTitles, setJobTitles] = useState([]);
    const [showOnboardingSnackbar, setShowOnboardingSnackbar] = useState(false);
    const [currentEditor, setCurrentEditor] = useState<EditorsInformation>();

    useEffect(() => {
        if (editorsInformation.length > 0) {
            const crrEditor = editorsInformation.find((editor) => editor.id === accordion.id);
            if(crrEditor) {
                setCurrentEditor(crrEditor);
            }
        }
    })

    useEffect(() => {
        if (data && data.getJobTitleProMembers) {
            setJobTitles(data.getJobTitleProMembers);
        }
    }, [data]);

    useEffect(() => {
        if (onboardingError.severity && onboardingError.message) {
            setShowOnboardingSnackbar(true);
        }
    }, [onboardingError]);

    const handleClick = () => {
        setOpen(!open);
    };

    const validateNewEditorData = (newEditor: EditorsInformation) => {
        const isValidData = validateEditorsAccordion(newEditor);
        validateEditorsAccordionAction(isValidData.status);
        if (!isValidData.status) {
            broadcastOnboardingErrorAction({ severity: SeveritySnackbarEnum.error, message: "You must fill all data to add another editor." });
        }
        if (isValidData.status) {
            addNewEditor(newEditor);
        }
    }

    const { handleSubmit, handleChange, values, errors } = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: currentEditor ? currentEditor.id : '',
            firstName: currentEditor ? currentEditor.firstName : "",
            lastName: currentEditor ? currentEditor.lastName : "",
            title: currentEditor ? currentEditor.title : "",
            email: currentEditor ? currentEditor.email : "",
            phoneNumber: currentEditor ? currentEditor.phoneNumber : ""
        },
        validateOnBlur: true,
        validationSchema,
        onSubmit(values) {
            const { firstName, lastName, title, email, phoneNumber } = values;
            const newEditor: EditorsInformation = {
                id: accordion.id,
                firstName: firstName,
                lastName: lastName,
                title: title,
                email: email,
                phoneNumber: phoneNumber
            };
            validateNewEditorData(newEditor);
        }
    });


    useEffect(() => {
        if (isAddEditorButtonCLicked && !accordion.validatedData && !isSaveButtonClicked) {
            handleSubmit();
        }
    }, [isAddEditorButtonCLicked, isSaveButtonClicked]);

    useEffect(() => {
        if (accordion.isLastAddedBeforeSave && isAddEditorButtonCLicked) {
            addEditorAccordion();
        }
    }, [accordion.isLastAddedBeforeSave, isAddEditorButtonCLicked]);

    useEffect(() => {
        if (isSaveButtonClicked && !accordion.validatedData && !isAddEditorButtonCLicked) {
            handleSubmit();
        }
    }, [isSaveButtonClicked]);

    const handleClose = () => {
        setShowOnboardingSnackbar(false);
        clearOnboardingBroadcastAction();
    };

    return (
        <React.Fragment>
            <Accordion id='editors-information' className="custom-accordion" expanded={open}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1c-content"
                    id="panel1c-header"
                    className="inner-accordion-editors"
                    onClick={handleClick}
                >
                    <div className="title">{t("Onboarding.Editor.Title")}</div>
                </AccordionSummary>
                <div className="accordion-container">
                    <TextField
                        autoComplete="off"
                        id="firstName-input"
                        onChange={handleChange}
                        name="firstName"
                        label={t("Onboarding.FirstName.Label")}
                        value={values.firstName}
                        helperText={errors.firstName ? errors.firstName : null}
                    />
                    <TextField
                        autoComplete="off"
                        id="lastName-input"
                        onChange={handleChange}
                        name="lastName"
                        label={t("Onboarding.LastName.Label")}
                        value={values.lastName}
                        helperText={errors.lastName ? errors.lastName : null}
                    />
                    <span>
                        <Select variant='standard' name="title" onClick={handleChange} placeholder={t("Onboarding.JobTitle.Label")} className='dropdown-component' defaultValue={t("Onboarding.JobTitle.Label")} >
                            <MenuItem className='gray-color' value={t("Onboarding.JobTitle.Label")} key='Job title' disabled>{t("Onboarding.JobTitle.Label")}</MenuItem>
                            {
                                jobTitles.map((title: string) => {
                                    return <MenuItem value={title} key={title}>{title}</MenuItem>
                                })
                            }
                        </Select>
                        <div className="helper-text-select">
                            {errors.title ? errors.title : null}
                        </div>
                    </span>
                    <TextField
                        autoComplete="off"
                        id="phoneNumber-input"
                        onChange={handleChange}
                        name="phoneNumber"
                        label={t("Onboarding.PhoneNumber")}
                        value={values.phoneNumber}
                        helperText={errors.phoneNumber ? errors.phoneNumber : null}
                    />
                    <TextField
                        autoComplete="off"
                        id="email-input"
                        onChange={handleChange}
                        name="email"
                        label={t("Onboarding.EmailAddress")}
                        value={values.email}
                        helperText={errors.email ? errors.email : null}
                    />
                </div>
                <AccordionActions style={{ justifyContent: 'center' }}>
                    <Button size="small" variant="contained" onClick={(e: any) => handleDelete(accordion.id, e)}
                        className='white-round-button'>
                        {"delete"}
                    </Button>
                </AccordionActions>
            </Accordion>
            <SnackbarComponent showSnackbar={showOnboardingSnackbar} handleClose={handleClose}
                severity={onboardingError.severity}
                message={onboardingError.message} />
        </React.Fragment>

    )
};

const mapStateToProps = (state: StoreState): { onboardingError: IBroadcastMessage, editorsInformation: EditorsInformation[] } => {
    return {
        onboardingError: selectOnboardingError(state),
        editorsInformation: selectEditorsInformation(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch<TOnboardingReducerAction>) => {
    return {
        validateEditorsAccordionAction: (data: boolean) => dispatch<IValidEditorsAccordion>({ type: OnboardingActionTypes.VALIDATE_EDITORS_ACCORDION, data: data }),
        broadcastOnboardingErrorAction: (data: IBroadcastMessage) => dispatch<IBroadcastOnboardingError>({ type: OnboardingActionTypes.BROADCAST_ONBOARDING_ERROR, data: data }),
        clearOnboardingBroadcastAction: () => dispatch<IClearOnboardingBroadcast>({ type: OnboardingActionTypes.CLEAR_ONBOARDING_BROADCAST }),
        saveEditorsInformationAction: (data: EditorsInformation[]) => dispatch<IEditorsInformation>({ type: OnboardingActionTypes.EDITORS_INFORMATION, data: data })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(EditorsAccordionComponent);
