import { parse } from "querystring";
import React, { useEffect, useState } from "react";
import { useLazyQuery } from "react-apollo";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { IBroadcastMessage, LightBoxContent, LightBoxContentObject } from './lightBox.types';
import { DELETE_LIGHT_BOX_CONTENT, GET_LIGHT_BOX_DETAILS } from "./queries";
import StackGrid, {easings, transitions} from "react-stack-grid";
import { Button, Menu, MenuItem, Pagination, Skeleton } from "@mui/material";
import { SmoothImageRender, ConfirmationDialog, SnackbarComponent } from 'shared';
import LightBoxContentContainer from "./lightBoxContentContainer";
import './lightBox.styles.scss';
import { GET_LICENSE_PLAN_BY_USER_ID_AND_TYPE } from "../../components/photo-details/queries";
import ShareIcon from '@mui/icons-material/Share';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter } from '@fortawesome/free-brands-svg-icons';
import CopyToClipboard from "react-copy-to-clipboard";
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { config } from "../../config";
import { SeveritySnackbarEnum } from "../../components/batch-upload/ContentUpload.types";
import { connect } from "react-redux";
import { StoreState } from "../../redux/root-reducer";
import { selectLightBoxBroadcast } from "../../redux/lightbox/lightbox.selector";
import { LightBoxActionTypes } from "../../redux/lightbox/lightbox.types";
import { Dispatch } from "redux";
import { IBroadcastLightBoxMessage, IClearBroadcast, TLightBoxActions } from "../../redux/lightbox/lightbox.actions";
import { selectCurrentUser } from "../../redux/user/user.selectors";
import { User } from "../../redux/user/user.types";

const LightBoxContents: React.FC<any> = ({...props}) => {
  const {lightBoxBroadcast, broadcastAction, clearBroadcast, currentUser} = props;
  const { lightBoxId } = useParams<any>();
  const [lightBoxTitle,setLightBoxTitle] = useState<string>("");
  const [contents,setContents] = useState<LightBoxContentObject[]>([]);
  const [totalPages,setTotalPages] = useState<number>(0)
  const [contentPerPage,setContentPerPage] = useState<number>(10);
  const [lightBoxCoverId,setLightBoxCoverId] = useState<number>(10);
  const [pageNumber,setPageNumber] = useState<number>(0);
  const [lightboxOwnerId,setLightBoxOwnerId] = useState<number>(0);
  const [showLinkCopiedSnackbar,setShowLinkCopiedSnackbar] = useState<boolean>(false);
  const [showBroadcastMessage,setShowBroadcastMessage] = useState<boolean>(false);
  const [standardLicensePlan, setStandardLicensePlan] = useState<any>();
  const [premiumLicensePlan, setPremiumLicensePlan] = useState<any>();
  const [state, setState] = useState({
    duration: 0,
    gutter: 15,
    easing: easings.quartOut,
    transition: 'fadeDown',
    rtl: false,
  });

  const [
    fetchUserStandardLicensePlans,
    { data: standardLicensePlanData, loading: standardLicensePlanLoading, error: standardLicensePlanError },
  ] = useLazyQuery(GET_LICENSE_PLAN_BY_USER_ID_AND_TYPE, {
    fetchPolicy: "cache-and-network",
    onCompleted: (res: any)=>{
      if(res?.getPlanByIdAndType){
        setStandardLicensePlan(res?.getPlanByIdAndType[0])
      }      
    }
  });

  const [
    fetchUserPremiumLicensePlans,
    { data: premiumLicensePlanData, loading: premiumLicensePlanLoading, error: premiumLicensePlanError },
  ] = useLazyQuery(GET_LICENSE_PLAN_BY_USER_ID_AND_TYPE, {
    fetchPolicy: "cache-and-network",
    onCompleted: (res: any)=>{
      if(res?.getPlanByIdAndType){
        setPremiumLicensePlan(res?.getPlanByIdAndType[0])
      }  
    }
  });

  const location = useLocation();

  const { scaleDown } = transitions;

  const [heights,setHeights] = useState<number[]>([]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const { duration, gutter, easing, transition, rtl } = state;

  const [fetchLightBoxContents, { data, loading, error }] = useLazyQuery(
    GET_LIGHT_BOX_DETAILS,
    { 
      fetchPolicy: "cache-and-network",
    }
  );
  const lightBoxPath = config.REACT_APP_PUBLIC_BASE+''+location.pathname;

  const shareToFacebook = config.REACT_APP_FACEBOOK_SOCIAL_MEDIA_SHARE+''+lightBoxPath;

  const shareTwitter = config.REACT_APP_TWEETER_SOCIAL_MEDIA_SHARE+''+lightBoxPath;
  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageNumber(value);
    fetchLightBoxContents({variables: {
      userId: currentUser?.id,
      lightboxId: parseInt(lightBoxId),
      pageNumber: value,
      contentPerPage
    }})
};

  const handleShareClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSnackBarClose = () => {
    setShowLinkCopiedSnackbar(false);
    clearBroadcast();
}

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleBroadcastSnackBarClose = ()=>{
    setShowBroadcastMessage(false);
    clearBroadcast();
  }

  useEffect(()=>{
    if(!showLinkCopiedSnackbar && lightBoxBroadcast.severity && lightBoxBroadcast.message){
      setShowBroadcastMessage(true);
    }
  },[lightBoxBroadcast,showLinkCopiedSnackbar])

  useEffect(()=>{
    if(!standardLicensePlan){
      fetchUserStandardLicensePlans({
        variables: {
          userId: currentUser.id,
          licensingType: 'standard',
        },
      });
    }
    if(!premiumLicensePlan){
      fetchUserPremiumLicensePlans({
        variables: {
          userId: currentUser.id,
          licensingType: 'premium',
        },
      });
    }
  },[]);

  useEffect(() => {
    fetchLightBoxContents({
      variables: {
        userId: currentUser?.id,
        lightboxId: parseInt(lightBoxId),
        pageNumber: 1,
        contentPerPage
      },
    });
  }, []);

  useEffect(() => {
    if(data && data.getUserLightboxDetails.lightboxContents){
      setLightBoxOwnerId(data.getUserLightboxDetails.lightboxDetails.userId)
      setContents(data.getUserLightboxDetails.lightboxContents);
      setLightBoxTitle(data.getUserLightboxDetails.lightboxDetails.lightboxName);
      setLightBoxCoverId(data.getUserLightboxDetails.lightboxDetails.coverId);
      const totalLightBoxContents = data.getUserLightboxDetails.totalLightboxContents;
      if(totalLightBoxContents > 0){
        if(totalLightBoxContents % contentPerPage === 0){
            setTotalPages(totalLightBoxContents / contentPerPage)
        }
        else{
            setTotalPages(Math.floor(totalLightBoxContents / contentPerPage)+1)
        }
      }
    }
  }, [data]);
  
  const handleCopyUrl = ()=>{
    broadcastAction({severity: SeveritySnackbarEnum.info, message: "Url copied to clipboard"});
    setShowLinkCopiedSnackbar(true);
  }

  return <div style={{position: 'relative'}}>
    <div>
      <h2 style={{padding: '0.3rem'}}>{lightBoxTitle}</h2>
      <Button
            style={{position: 'absolute', right: '0', top: '0'}}
            startIcon={<ShareIcon />} variant="outlined"
            onClick={handleShareClick}
          >
            Share
      </Button>
    </div>
    <div className="light-box-contents-container">
      {contents.length > 0 ? contents.map((item: LightBoxContentObject) => 
          <LightBoxContentContainer item={item} standardPlan={standardLicensePlan} premiumPlan={premiumLicensePlan} ownerId={lightboxOwnerId} refetchLightBoxContents={()=>{
            fetchLightBoxContents({
              variables: {
                userId: currentUser?.id,
                lightboxId: parseInt(lightBoxId),
                pageNumber: 1,
                contentPerPage
              },
            });
          }} isCover={item.lightboxId === lightBoxCoverId}/>
      ) : loading && Array.from(Array(contentPerPage).keys()).map((item: number, index: number) => 
              <Skeleton variant="rectangular" width={300} height={400} className='image-skeleton'/>
      )}
    </div>
     <div className="pagination-container">
      {contents.length > 0 ? <Pagination count={totalPages} page={pageNumber === 0 ? 1 : pageNumber} onChange={handlePageChange} /> : "There are no contents in this light box yet"}
    </div> 
    <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={handleClose}>
          <a target="_blank" rel="noopener noreferrer" href={shareToFacebook} className='links'>
            <button className="channel-info-button main-color-button icon-buttons">
              <FontAwesomeIcon className="fbColor" icon={faFacebookF} /> 
            </button>
            Share on Facebook
          </a>
          
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <a target="_blank" rel="noopener noreferrer" href={shareTwitter} className='links'>
            <button className="channel-info-button main-color-button icon-buttons">
              <FontAwesomeIcon className="twColor" icon={faTwitter} /> 
            </button>
            Share on Twitter
          </a> 
          
        </MenuItem>
        <CopyToClipboard text={lightBoxPath} onCopy={handleCopyUrl}>
        <MenuItem onClick={handleClose}>
          
            <button className="channel-info-button main-color-button icon-buttons">
              <FontAwesomeIcon className="linkColor" icon={faLink} /> 
            </button>
          
          Copy URL
        </MenuItem>
        </CopyToClipboard>
    </Menu>
    <SnackbarComponent
        showSnackbar={showLinkCopiedSnackbar}
        handleClose={handleSnackBarClose}
        severity={lightBoxBroadcast.severity}
        message={lightBoxBroadcast.message}
    />
    <SnackbarComponent
        showSnackbar={showBroadcastMessage}
        handleClose={handleBroadcastSnackBarClose}
        severity={lightBoxBroadcast.severity}
        message={lightBoxBroadcast.message}
    />
  </div>;
};

const mapStateToProps = (state: StoreState): {
  lightBoxBroadcast: IBroadcastMessage;
  currentUser: User;
} => {
  return {
    lightBoxBroadcast: selectLightBoxBroadcast(state),
    currentUser: selectCurrentUser(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<TLightBoxActions>) => {
  return {
      broadcastAction: (data: IBroadcastMessage) => dispatch<IBroadcastLightBoxMessage>({
          type: LightBoxActionTypes.BROADCAST_MESSAGE, data: data
      }),
      clearBroadcast: () => dispatch<IClearBroadcast>({type: LightBoxActionTypes.CLEAR_BROADCAST})
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LightBoxContents);

