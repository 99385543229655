export function removeSpaces(stringToUpdate: string) {
	if (stringToUpdate !== undefined) {
		stringToUpdate = stringToUpdate.replace(/\s/g, "");
	}
	return stringToUpdate;
}

export function removeSpecialChars(stringToUpdate: string) {
	if (stringToUpdate !== undefined) {
		stringToUpdate = stringToUpdate.replace(/[^a-zA-Z0-9]/g, "");
	}
	return stringToUpdate;
}

export function isEmpty(obj: any) {
	for (var key in obj) {
		if (obj.hasOwnProperty(key)) return false;
	}
	return true;
}
