import React, { useEffect, useState } from 'react';
import Dropzone, { IFileWithMeta, IInputProps, ILayoutProps, IPreviewProps } from 'react-dropzone-uploader';
import { getDroppedOrSelectedFiles } from 'html5-file-selector'
import { AudioFile, IBroadcastMessage, SeveritySnackbarEnum } from './ContentUpload.types';
import { ContentUploadActionTypes } from '../../redux/content-upload/content-upload.types';
import { StoreState } from 'redux/root-reducer';
import { Dispatch } from 'redux';
import SyncLoader from 'react-spinners/SyncLoader';
import { IAddAudioFile, IBroadcastContentUploadMessage, IDeleteAudioFile, TContentUploadActions } from 'redux/content-upload/content-upload.actions';
import { connect } from 'react-redux';
import { selectAudioFiles } from '../../redux/content-upload/content-upload.selectors';
import { Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ReactAudioPlayer from 'react-audio-player';
import './AddAudioInput.styles.scss';
import {useTranslation} from "react-i18next"
export interface AudioInputProps {
  articleId: string;
  audioFiles: AudioFile[];
  addAudioFileAction: (file: any) => void;
  removeAudioFileAction: (data: string) => void;
  broadcastUploadErrorAction: (data: IBroadcastMessage) => void;
}

// function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
//   return (
//     <MuiThemeProvider theme={LinearProgressTheme}> 
//       <Box display="flex" alignItems="center">
//         <div className='linear-progress-container'>
//           <LinearProgress variant="determinate" {...props} />
//         </div>
//         <Box minWidth={35}>
//           <Typography variant="body2" color="textSecondary">{`${Math.round(
//             props.value,
//           )}%`}</Typography>
//         </Box>
//       </Box>
//     </MuiThemeProvider>
//   );
// }

const AddAudioInput: React.FC<AudioInputProps> = ({...props}) => {
    const {articleId, audioFiles, addAudioFileAction, removeAudioFileAction, broadcastUploadErrorAction} = props;
    const [chosenAudioFile, setAudioFile] = useState<Partial<File>>({});
    const [audioFileUrl, setAudioFileUrl] = useState('');
    const [droppedAudioFile, setDroppedFile] = useState<Partial<AudioFile>>({});
    const [loadingIndicator, setLoadingIndicator] = useState(false);
    const {t} = useTranslation();

    useEffect(() => {
      const hasAudioFileUploaded = audioFiles.find(file => file.articleId === articleId);
      if(hasAudioFileUploaded) {
        setDroppedFile(hasAudioFileUploaded);
        setAudioFileUrl(URL.createObjectURL(hasAudioFileUploaded.fileWithMeta.file));
      } else {
        setDroppedFile({});
      }
    }, [audioFiles])

    const Input = ({ accept, onFiles, getFilesFromEvent }: IInputProps) => {
      return (
        <div>
          {droppedAudioFile && droppedAudioFile.fileWithMeta ? 
            null
          : 
          <div>
            <input style={{ display: 'none' }} id='contained-button-audio-file' type="file" accept={accept} onChange={e => {
                getFilesFromEvent(e).then(chosenFiles => {
                  onFiles(chosenFiles)
                })
              }}
            />
            <label htmlFor="contained-button-audio-file">
                <Button size='small' className='white-button' variant='contained' component='span' endIcon={<CloudUploadIcon/>}>
                    {t("Audio.Upload.Button")}
                </Button>
            </label>
          </div>
          } 
        </div>
      )
    }

    const InputWithFiles = ({}: IInputProps) => {
      return (
          <div className='submit-label'>
          </div>
      )
    }
    
    const DragAndDropPreview = ({}: IPreviewProps) => {
      return (
          <div className="preview-box">
          </div>
      )
    }

    const Layout = ({ input, dropzoneProps, extra: { maxFiles } }: ILayoutProps) => {
      return (
        <div className='audio-input-container'>
            <div {...dropzoneProps} className='audio-input-label'>
                {audioFiles.length < maxFiles && input}
            </div>

            {droppedAudioFile && droppedAudioFile.fileObject ? 
              <div className='preview-container'>
                {/* <span className='preview-filename'>{droppedAudioFile.fileWithMeta!.file.name}</span> */}
                <div className="audio-player">
                  <div className="file-name">{droppedAudioFile.fileWithMeta!.file.name}</div>
                    <ReactAudioPlayer
                    src={audioFileUrl}
                    controls
                    controlsList="nodownload"
                    preload="metadata"
                    />
                </div>
                  <div className='preview-status'> 
                    {loadingIndicator ? 
                    <SyncLoader css={`display: block; margin: 0 auto; border-color: red;`} size={10} 
                        color={"#36D2B3"} loading={loadingIndicator}/>
                        :
                    <span className='preview-button' onClick={() => removeDroppedFile(droppedAudioFile.articleId)}>
                      <CloseIcon/>
                    </span>
                    }
                  </div>
              </div> 
            : null}
        </div>
      )
    }

    const removeDroppedFile = (articleId: string | undefined) => {
      if(articleId) {
        removeAudioFileAction(articleId);
        setAudioFile({});
        setAudioFileUrl('');
        broadcastUploadErrorAction({severity: SeveritySnackbarEnum.success,
          message:t("Audio.Snackbar.Remove.Success")});
      } else {
        broadcastUploadErrorAction({severity: SeveritySnackbarEnum.error,
          message:"Failed to remove audio file"});
      }
    }

    const getFilesFromEvent = async (event: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLElement>) : Promise<any> => {
      return new Promise(resolve => {
          getDroppedOrSelectedFiles(event).then((chosenFiles: any[]) => {
            setAudioFile(chosenFiles[0]);
            resolve(chosenFiles.map(f => f.fileObject));
          }).catch((error: any) => {
            broadcastUploadErrorAction({severity: SeveritySnackbarEnum.error,
              message:t("Audio.Snackbar.Upload.Fail")});
        })
      })
    }

    const onChangeStatus = (file: IFileWithMeta, status: any) => {
        if (status === 'preparing') {
          setLoadingIndicator(true);
          let audioFile: AudioFile = {
              articleId: articleId,
              fileWithMeta: file, 
              fileObject: chosenAudioFile
          };

          setDroppedFile(audioFile);
        } else if (status === 'done') {
          setLoadingIndicator(false);
          broadcastUploadErrorAction({severity: SeveritySnackbarEnum.success,
            message:t("Audio.Snackbar.Upload.Success")});

          let audioFile: AudioFile = {
            articleId: articleId,
            fileWithMeta: file, 
            fileObject: chosenAudioFile
          };
          addAudioFileAction(audioFile);
        }
    }
  
    return (
      <Dropzone
        accept="audio/*"
        InputComponent={Input}
        multiple={false}
        LayoutComponent={Layout}
        PreviewComponent={DragAndDropPreview}
        getFilesFromEvent={getFilesFromEvent}
        inputWithFilesContent={InputWithFiles}
        onChangeStatus={onChangeStatus}
        classNames={{dropzone: 'audio-input-container'}}
      />
    )
  }

  const mapStateToProps = (state: StoreState): {audioFiles: AudioFile[]} => {
    return {
        audioFiles: selectAudioFiles(state)
    }
}

const mapDispatchToProps = (dispatch: Dispatch<TContentUploadActions>) => {
    return {
        addAudioFileAction: (data: any) => dispatch<IAddAudioFile>({type: ContentUploadActionTypes.ADD_AUDIO_FILE, data: data}),
        removeAudioFileAction: (data: string) => dispatch<IDeleteAudioFile>({type: ContentUploadActionTypes.DELETE_AUDIO_FILE, data: data}),
        broadcastUploadErrorAction: (data: IBroadcastMessage) => dispatch<IBroadcastContentUploadMessage>({
            type: ContentUploadActionTypes.BROADCAST_MESSAGE, data: data
        })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddAudioInput);