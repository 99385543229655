import React from 'react';
import { Channel, ChannelCoverProps } from './home.types';
import './masonry-item.styles.scss';
import ChannelButton from './channel-button.component';

const MasonryItem: React.FC<ChannelCoverProps> = ({ item }) => {
    const channel: Channel = {
        id: item.channelId,
        title: item.title!,
        code: item.code,
    }
    
    const handleContexMenu = (event: any) => {
        event.preventDefault()
    }

    return (
        <div
            className='item-container'
            onContextMenu={handleContexMenu}
        >
            <div className='image'>
                {
                    item.filePath && (
                        <img src={item.filePath} alt={item.title}
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                            }}
                        />

                    )}</div>

            <div className="buttonContainer"><ChannelButton channel={channel} isInPic={true} /></div>
            {/* <div className='image-infotip'> */}
                {/* <div className='infotip-container'> */}
                    {/* <div className='infotip-left'>
                        <span className='title'>{item.title}</span>
                        <span className='description'>{item.description}</span>
                        <span className={`channel-location  ${channel.code + 'Link'}`}>
                            <Link className="addtionalLinkStyle" to='/'>{item.title}</Link>
                            ﹥
                            <Link className="addtionalLinkStyle" to='/'>SubChannel name</Link>
                            ﹥
                            <Link className="addtionalLinkStyle" to='/'>Thread name</Link>
                        </span>
                    </div> */}
                    {/* <div className='infotip-right'>
                        <span className='author'>Photo by: {item.author}</span>
                        <span className='location'>{item.location}</span> */}
                        {/*TODO: format the date!*/}
                        {/*<span className='date-added'>{new Date(item.datePublished).toLocaleDateString()}</span>*/}
                        {/* <span className='date-added'>{item.datePublished}</span>
                    </div>
                </div> */}
                {/* {item.url ?
                    (<div className="linkContainer">
                        <a href={item.url} target="_blank"
                            className={`infotip-button  ${channel.code + 'Link'}`} rel="noreferrer"> {item.url} </a>
                    </div>
                    ) : null} */}
            {/* </div>  */}
        </div>
    );
}

export default MasonryItem;