import { RoleTypes } from "../register/register.types";
import { LicensingTypes } from "../batch-upload/EditContentMetadata.types";
import { getSubscriptionItemsNumbersByType } from "../cart/cart-page-helperFunctions";
import { ItemForCart, ItemTypeLicensing } from "./photo-details.types";
import { User } from "../contributor-content/contributor-content.types";

export const checkIfPlanHasAvailableAmount = (
  cartItemsFromSub: ItemForCart[],
  availableAmount: number,
  type: string
) => {
  const currentAmountOfItems = getSubscriptionItemsNumbersByType(
    cartItemsFromSub
  );
  if (
    type === LicensingTypes.STANDARD &&
    currentAmountOfItems.standard < availableAmount
  ) {
    return true;
  } else if (
    type === LicensingTypes.PREMIUM &&
    currentAmountOfItems.premium < availableAmount
  ) {
    return true;
  } else {
    return false;
  }
};

export const capitalizeFirstLetter = ( stringToCapitalize: string ) => {
  return stringToCapitalize.charAt( 0 ).toUpperCase() + stringToCapitalize.slice( 1 );
};

