import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import { Grow, Typography } from '@material-ui/core';
import "./my-license-page.styles.scss";
import { GET_ALL_LICENSING_PLANS, GET_USER_LICENSING_PLANS } from "./queries";
import { LicensePlan } from "../photo-details/photo-details.types";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  IAddSubscription,
  TSubscriptionReducerActions
} from "../../redux/subscriptions/subscription-page.actions";
import { SubscriptionActionTypes } from "../../redux/subscriptions/subscription-page.types";
import { GetLicensingPlanPageProps, Header, UserLicensingPlan } from "./my-license-page.types";
import { GetLicensingPlanPageRow } from "./license-page.containers";
import { useTranslation } from "react-i18next";
import "./my-license-page.styles.scss";
import { StoreState } from "redux/root-reducer";
import { User } from "../../redux/user/user.types";
import { selectCurrentUser } from "../../redux/user/user.selectors";
import { filterPlansByType } from "./license-page-helperFunctions";
import SyncLoader from "react-spinners/SyncLoader";


const GetLicensingPlanPage: React.FC<GetLicensingPlanPageProps> = ({
  ...props
}) => {
  const {currentUser, addSubscription, handlePageChange } = props;
  const { t } = useTranslation();

  const [licensingPlans, setLicensingPlans] = useState<LicensePlan[]>([]);
  const [userPlans, setUserPlans] = useState<UserLicensingPlan[]>([]);
  const [filteredPlans, setFilteredPlans] = useState<LicensePlan[]>([]);

  const [getAllPlans, { data, loading, error, refetch }] = useLazyQuery(GET_ALL_LICENSING_PLANS, { fetchPolicy: "cache-and-network" });
  const [userPlanDataQuery, { data :userPlanData, loading: userPlanLoading, error: userplanError, refetch: userPlanRefecth }] = useLazyQuery(
    GET_USER_LICENSING_PLANS,
    { fetchPolicy: "cache-and-network" }
  );

  // useEffect( () => {
  //   if(filteredPlans.length>0)
  //   {
  //     setInterval(function(){
  //       window.location.reload();
  //      }, 900000);
    
  //   }
  // }, [filteredPlans])

  useEffect(() => {
    getAllPlans();
    
    userPlanDataQuery({variables: { userId: currentUser.id }});
  }, []);

  useEffect(() => {
    if (data && data.getAvailableLicensingPlans) {
      setLicensingPlans(data.getAvailableLicensingPlans);
    }
  }, [data]);

  useEffect(() => {
    if (userPlanData && userPlanData.getUserLicensingPlans) {
      setUserPlans(userPlanData.getUserLicensingPlans);
    }
  }, [userPlanData]);

  useEffect( () => {
    if(licensingPlans.length > 0) {
      setFilteredPlans(filterPlansByType(licensingPlans, userPlans))
    }
  }, [licensingPlans, userPlans])

  const getPlan = (plan: LicensePlan) => {
    addSubscription(plan);
    handlePageChange();
  };

  const headers: Header = {
    headerId: t("My.License.Page.Headers.Id"),
    headerContentType: t("My.License.Page.Headers.Content.Type"),
    headerPlanType: t("My.License.Page.Headers.PlanType"),
    headerAmount: t("My.License.Page.Headers.Amount"),
    headerPeriod: t("My.License.Page.Headers.Period"),
    headerPlanPrice: t("My.License.Page.Headers.PlanPrice")
  };

  return (
    <div className="license-page-display">
      <h3 className="license-page-headline">
        {t("Get.License.Plans.Page.Main.Header")}
      </h3>
      <div className="license-page-table">
        <Grow in={true}>
          <React.Fragment>
            <GetLicensingPlanPageRow isHeader={true} header={headers} />
            {loading ? 
              <SyncLoader css={`display: block; margin: 0 auto; border-color: red;`} size={20} 
              color={"#36D2B3"} loading={loading}/>
            : 
              filteredPlans.map((plan, index) => {
                return (
                  <GetLicensingPlanPageRow
                    key={index}
                    isHeader={false}
                    tableItems={plan}
                    getPlan={getPlan}
                  />
                );
              }) 
            }

            {filteredPlans.length === 0 && !loading ?
            <Typography variant="h6" gutterBottom color="textPrimary">
                No plans available for purchase at this current time
            </Typography> 
              : null}
          </React.Fragment>
        </Grow>
      </div>
    </div>
  );
};

const mapStateToProps = (state: StoreState): { currentUser: User; } => {
  return {
    currentUser: selectCurrentUser(state),
  };
};

const mapDispatchToProps = ( dispatch: Dispatch<TSubscriptionReducerActions> ) => {
  return {
    addSubscription: (item: LicensePlan) =>
      dispatch<IAddSubscription>({
        type: SubscriptionActionTypes.ADD_SUBSCRIPTION,
        data: item
      })
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(GetLicensingPlanPage);
