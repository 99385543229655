import {
  ExecutionResult,
  MutationFunctionOptions,
  OperationVariables
} from "react-apollo";
import { LicensingTypes } from "../batch-upload/EditContentMetadata.types";
import { ItemForCart } from "../photo-details/photo-details.types";
import {
  TaxLineItemInput,
  ContentOrderDto,
  SubscriptionItemsNumbersByType,
  UserPlanToUpdateParams,
  LicenseOriginTypes,
  CartTableHeadings,
  InvoiceTemplateTypes
} from "./cart-page.types";
import { UserLicensingPlan } from "../my-license-page/my-license-page.types";
import { TaxForOrder } from "../../redux/cart/cart-page.types";
import { Addresses } from "../../redux/onboarding/onboarding.types";
import { AddressInput, AddressTypes, LocationInput } from "../onboarding/onboarding-page.types";

export const generateInvoiceTableSubscription = (
  cartItemsFromSub: ItemForCart[]
) => {
  let invoiceTable = `<table style='border:1px black solid; width: 85%; border-collapse: collapse; text-allign:center;'>
    <tr>
    <th style='border:1px black solid;'>Preview</th>
    <th style='border:1px black solid;'>Code</th>
    <th style='border:1px black solid;'>Headline</th style='border:1px black solid;'>
    </tr>`;

  cartItemsFromSub.map(mapItem => {
    let rowString =
      "<tr><td style='border:1px black solid;'>" +
      "<img style='max-width:60px;' src = '" +
      mapItem.content.pathToFileCompressed +
      "'/></td><td style='border:1px black solid;'>" +
      mapItem.content.code +
      "</td><td style='border:1px black solid;'>" +
      mapItem.content.headline +
      "</td></tr>";
    invoiceTable = invoiceTable + rowString;
  });

  invoiceTable += "</table>";
  return invoiceTable;
};

export const generateInvoiceTable = (cartItems: ItemForCart[], tax: TaxForOrder | null) => {

  let invoiceTable = `<table style='border:1px black solid; width: 85%; border-collapse: collapse; text-allign:center;'>
  <tr>
    <th style='border:1px black solid;'>Preview</th>
    <th style='border:1px black solid;'>Code</th>
    <th style='border:1px black solid;'>Headline</th style='border:1px black solid;'>
    <th style='border:1px black solid;'>Size</th>
    <th style='border:1px black solid;'>Price</th>
    <th style='border:1px black solid;'>Tax</th>
    <th style='border:1px black solid;'>Gross price</th>
  </tr>`;

  cartItems.map(mapItem => {

    let taxItem = tax?.breakdown ? tax.breakdown.lineItems.find(
      (lineItem: any) => lineItem.id === mapItem.content.id
    ) : 0; 

    const taxToCollect = taxItem?.taxCollectable ? taxItem.taxCollectable : parseFloat(getToTwoDecimals(taxItem))
    const totalLineItemPrice = mapItem.flatRate + taxToCollect;
    const size = mapItem.content.size ? mapItem.content.size : "N/A";

    let rowString =
      "<tr><td style='border:1px black solid;'>" +
      "<img style='max-width:60px;' src = '" +
      mapItem.content.pathToFileCompressed +
      "'/></td><td style='border:1px black solid;'>" +
      mapItem.content.code +
      "</td><td style='border:1px black solid;'>" +
      mapItem.content.headline +
      "</td><td style='border:1px black solid;'>" +
      size +
      "</td><td style='border:1px black solid;'> $" +
      mapItem.flatRate +
      "</td><td style='border:1px black solid;'> $" +
      taxToCollect +
      "</td><td style='border:1px black solid;'> $" +
      totalLineItemPrice +
      "</td></tr>";
    invoiceTable = invoiceTable + rowString;
  });

  invoiceTable += "</table>";
  return invoiceTable;
};

export const getContentIds = (
  cartItems: ItemForCart[],
  cartItemsFromSub: ItemForCart[],
  tax: TaxForOrder | null,
  currentUserRole?: string
) => {
  let itemIds: ContentOrderDto[] = [];
  cartItems.map((item: ItemForCart) => {
    let taxItem = tax?.breakdown ? tax.breakdown.lineItems.find(
      (lineItem: any) => lineItem.id === item.content.id
    ) : 0;
    const taxToCollect = taxItem?.taxableAmount ? taxItem.taxableAmount : parseFloat(getToTwoDecimals(taxItem));
    let contentItemToAdd: ContentOrderDto = {
      contentId: item.content.id,
      size: item.content.size,
      amount: item.content.amount,
      totalPrice: item.content.totalPrice,
      contentTax: taxToCollect,
      licenseOrigin: LicenseOriginTypes.STANDARD,
      period: item.content.period,
      role: currentUserRole,
      contentPrice: item.flatRate,
      isbn: item.isbn,
      issn: item.issn
    };
    itemIds.push(contentItemToAdd);
  });

  cartItemsFromSub.map((item: ItemForCart) => {

    let contentItemToAdd: ContentOrderDto = {
      contentId: item.content.id,
      size: item.content.size,
      amount: item.content.amount,
      totalPrice: item.content.totalPrice,
      licenseOrigin: item.content.period
        ? LicenseOriginTypes.SUBSCRIPTION
        : LicenseOriginTypes.CREDIT,
      contentTax: parseFloat(getToTwoDecimals(0)),
      period: item.content.period,
      role: currentUserRole,
      contentPrice: item.flatRate,
      isbn: item.isbn,
      issn: item.issn
    };
    itemIds.push(contentItemToAdd);
  });

  return itemIds;
};

export const getAudioFileName = (audioPath: string) => {
  const splitName = audioPath.split("/");
  const name = splitName[splitName.length - 1];

  return name;
};

export const sendInvoice = (
  userEmail: string,
  standardLicenseItems: string,
  subscriptionItems: string,
  total: number,
  orderId:number,
  invoiceTemplateType: InvoiceTemplateTypes,
  sendInvoiceMutation: (
    options?: MutationFunctionOptions<any, OperationVariables> | undefined
  ) => Promise<ExecutionResult<any>>
) => {
  return sendInvoiceMutation({
    variables: {
      details: {
        email: userEmail,
        accessTemplate: invoiceTemplateType,
        replacements: {
          tableItems: standardLicenseItems,
          subscriptionItems: subscriptionItems,
          total: total
        }
      },
      orderId:orderId,
      invoiceTemplateType: invoiceTemplateType
    }
  })
    .then((result: any) => {
      if (result.data.sendInvoice) {
        return Promise.resolve(result.data.sendInvoice);
      }
    })
    .catch((error: any) => {
      return Promise.reject(error);
    });
};

export const getInvoiceTemplateType = (cartItems: ItemForCart[], cartItemsFromSubs: ItemForCart[]) => {
  if(cartItems.length > 0 && cartItemsFromSubs.length > 0) {
    return InvoiceTemplateTypes.MIXED;
  } else if(cartItemsFromSubs.length > 0) {
    return InvoiceTemplateTypes.SUBSCRIPTION;
  } else {
    return InvoiceTemplateTypes.STANDARD;
  }
}

export const getLineItems = (items: ItemForCart[]) => {
  
  const lineItems = items.map((item: ItemForCart) => {

    const lineItemId = item.content.licensingType === LicensingTypes.EDITORIAL ?  `${item.content.id.toString()}_${item.editorialSize}` : item.content.id.toString(); 

    const lineItem: TaxLineItemInput = {
      id: lineItemId,
      quantity: 1,
      unit_price: item.flatRate,
      discount: 0
    };

    return lineItem;
  });

  return lineItems;
};

export const getLineItemsWithSub = (items: ItemForCart[]) => {
  const lineItems = items.map((item: ItemForCart) => {

    const lineItemId = item.content.id.toString(); 

    const lineItem: TaxLineItemInput = {
      id: lineItemId,
      quantity: 1,
      unit_price: 0,
      discount: 0
    };

    return lineItem;
  });

  return lineItems;
}

export const getSubscriptionItemsNumbersByType = (subItems: ItemForCart[]) => {
  let standard = 0;
  let premium = 0;

  subItems.map(item => {
    if (item.content.licensingType === LicensingTypes.STANDARD) {
      standard += 1;
    } else if (item.content.licensingType === LicensingTypes.PREMIUM) {
      premium += 1;
    }
  });

  const finalCount: SubscriptionItemsNumbersByType = {
    standard,
    premium
  };

  return finalCount;
};

export const getUserLicensingPlanIdByLicensingType = (
  licensingPlans: UserLicensingPlan[]
) => {
  let plansIds = {
    standard: 0,
    premium: 0
  };
  licensingPlans.map(userPlan => {
    if (userPlan.plan.licensingType === LicensingTypes.STANDARD) {
      plansIds.standard = userPlan.id;
    } else if (userPlan.plan.licensingType === LicensingTypes.PREMIUM) {
      plansIds.premium = userPlan.id;
    }
  });
  return plansIds;
};

export const getUpdateParamsForPlan = (
  subItems: ItemForCart[],
  licensingPlans: UserLicensingPlan[]
) => {
  let planIds = getUserLicensingPlanIdByLicensingType(licensingPlans);
  let itemCounts = getSubscriptionItemsNumbersByType(subItems);

  let fullParams: UserPlanToUpdateParams[] = [];
  if (planIds.standard !== 0) {
    let standard = {
      userLicensingPlanId: planIds.standard,
      itemCount: itemCounts.standard
    };
    fullParams.push(standard);
  }
  if (planIds.premium !== 0) {
    let premium = {
      userLicensingPlanId: planIds.premium,
      itemCount: itemCounts.premium
    };
    fullParams.push(premium);
  }

  return fullParams;
};

export const getToTwoDecimals = (price: number) => {
  return parseFloat(price.toString()).toFixed(2);
};


export const checkIfShowColumn = (heading:CartTableHeadings, isSub?:boolean) => {
  if(!isSub || (heading !== CartTableHeadings.PRICE && heading !== CartTableHeadings.SIZE))
  {
    return true
  }
  else{
    return false
  }
}

export const getLocationInput = (useAnotherBillingAddress: boolean, additionalBillingAddress: Addresses | null, userBillingAddress: Addresses | undefined ) => {
  if (useAnotherBillingAddress && additionalBillingAddress) {
    const input: LocationInput = {
      country: additionalBillingAddress.country,
      countryCode: additionalBillingAddress.countryCode,
      stateProvince: additionalBillingAddress.state,
      stateCode: additionalBillingAddress.stateCode,
      city: additionalBillingAddress.city
    };

    return input;
  } else if (userBillingAddress) {
    const input: LocationInput = {
      country: userBillingAddress.country,
      countryCode: userBillingAddress.countryCode,
      stateProvince: userBillingAddress.state,
      stateCode: userBillingAddress.stateCode,
      city: userBillingAddress.city
    };

    return input;
  }
}

export const getAddressInput = (useAnotherBillingAddress: boolean, additionalBillingAddress: Addresses | null, userBillingAddress: Addresses | undefined) => {
  if (useAnotherBillingAddress && additionalBillingAddress) {
    const input: AddressInput = {
      addressType: AddressTypes.BILLING,
      addressLine1: additionalBillingAddress.addressLineOne,
      addressLine2: additionalBillingAddress.addressLineTwo,
      zipCode: additionalBillingAddress.zipCode
    }

    return input;
  } else if (userBillingAddress) {
    const input: AddressInput = {
      addressType: AddressTypes.BILLING,
      addressLine1: userBillingAddress.addressLineOne,
      addressLine2: userBillingAddress.addressLineTwo,
      zipCode: userBillingAddress.zipCode
    }

    return input;
  }
}

export const createStripeAddressToken = (fullName: string, useAnotherBillingAddress: boolean, additionalBillingAddress: Addresses | null, userBillingAddress: Addresses | undefined) => {
  let stripeAddressToken = {};

  if (userBillingAddress && !useAnotherBillingAddress) {
    stripeAddressToken = {
      name: fullName,
      address_country: userBillingAddress.country,
      address_city: userBillingAddress.city,
      address_state: userBillingAddress.state,
      address_zip: userBillingAddress.zipCode,
      address_line1: userBillingAddress.addressLineOne !== "" ? userBillingAddress.addressLineOne : userBillingAddress.addressLineTwo
    }
  } else if (additionalBillingAddress && useAnotherBillingAddress) {
    stripeAddressToken = {
      name: fullName,
      address_country: additionalBillingAddress.country,
      address_city: additionalBillingAddress.city,
      address_state: additionalBillingAddress.state,
      address_zip: additionalBillingAddress.zipCode,
      address_line1: additionalBillingAddress.addressLineOne !== "" ? additionalBillingAddress.addressLineOne : additionalBillingAddress.addressLineTwo
    }
  }

  return stripeAddressToken;
}

export const getRemainingDays = (purchaseDate: Date, expirationDate: Date) => {
  const time1 = new Date(purchaseDate);
  const time2 = new Date(expirationDate);
  const diffInTime = time2.getTime() - time1.getTime();
  const diffInDays = diffInTime / (1000 * 3600 * 24);

  return Math.floor(diffInDays);
}

export const getExpirationDate = (date: Date) => {
  const expirationDate = new Date(date);

  return expirationDate.toLocaleDateString('en-US');
}

export const checkPrmiseResults = (results:any[]) => {
  let arePromisesReslovedProperly = true;
  results.map( (result) => {
    if(result.status === "rejected" || result.value === undefined)
    {
      arePromisesReslovedProperly = false;
    }
  })

  return arePromisesReslovedProperly
}