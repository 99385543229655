import React, { useEffect, useState } from "react";
import "suneditor/dist/css/suneditor.min.css";
import GeneralInfoMembers from "./step-one-general-info.component";
import AboutYouComponent from "./step-one-about-you.component";
import { StoreState } from "redux/root-reducer";
import { connect } from "react-redux";
import { selectCurrentUserId, selectCurrentUserRole } from "../../redux/user/user.selectors";
import { AddressTypes, OnboardingStepOneProps } from "./onboarding-page.types";
import AddressComponent from "./step-one-address.component";
import { RoleTypes } from "../register/register.types";
import  PersonResponsibleForAccountComponent  from "./step-one-account-person.component";
import LicensingOnbording  from "./step-one-licensing.component";
import AppropriateChannelsComponent from "./step-one-channels-for-content.component";
import { useTranslation } from "react-i18next";
import EditorsListComponent from "./editors-information.component";

const OnboardingStepOne: React.FC<OnboardingStepOneProps> = ({ ...props }) => {
  const { currentUserRole } = props;
  const { t } = useTranslation();

  const isContributor = currentUserRole === RoleTypes.Contributor_individual || currentUserRole === RoleTypes.Contributor_agency;
  const isProOrganization = currentUserRole === RoleTypes.Pro_organization;

  return (
    <div className="dropdown-wrapper">
      <h2 className="headline">
        {t("Message.Welcome")}
      </h2>
      <GeneralInfoMembers userRole={currentUserRole!}/>
      <PersonResponsibleForAccountComponent userRole={currentUserRole!} />
      <AddressComponent userRole={currentUserRole!} addressType={AddressTypes.MAILING} />
      {currentUserRole !== RoleTypes.Member ? (
        <AddressComponent userRole={currentUserRole!} addressType={AddressTypes.BILLING} />
      ) : null}
      {
        isProOrganization ? 
        <EditorsListComponent/> : null
      }
      {isContributor ? (
        <LicensingOnbording userRole={currentUserRole!} />
      ) : null}
      {isContributor ? (
        <AppropriateChannelsComponent  />
      ) : null}
      <AboutYouComponent />
    </div>
  );
};

const mapStateToProps = (state: StoreState): { currentUserRole: string, currentUserId: number } => {
  return {
    currentUserRole: selectCurrentUserRole(state),
    currentUserId: selectCurrentUserId(state),
  };
};

export default connect(mapStateToProps, null)(OnboardingStepOne);
