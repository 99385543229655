import React, { useEffect, useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { TextField, Accordion, AccordionSummary, Grow, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import { StoreState } from "redux/root-reducer";
import { Dispatch } from "redux";
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";
import { useTranslation } from "react-i18next";
import { SubscriptionPaymentProps } from "./my-license-page.types";
import { useFormik } from "formik";
import { LicensePlan } from "../photo-details/photo-details.types";
import { selectCurrentSubscriptionToPurhcase, selectCurrentSubscriptionTotal, selectTaxSub } from "../../redux/subscriptions/subscription-page.selector";
import {
  IAddTaxForPlan,
  IBroadcastSubscriptionMessage,
  IEmptySubscription,
  TSubscriptionReducerActions
} from "../../redux/subscriptions/subscription-page.actions";
import { SubscriptionActionTypes } from "../../redux/subscriptions/subscription-page.types";
import { User } from "../../redux/user/user.types";
import { selectCurrentUser } from "../../redux/user/user.selectors";
import "./my-license-page.styles.scss";
import { BUY_LICENSE_PLAN, CONFIRM_LICENSE_PLAN, DELETE_LICENSING_PLAN, SEND_LICENSE_PLAN_INVOICE } from "./queries";
import { stripePayment } from "../stripe-payment-axios-function/stripe-axios-payment-function";
import { CallHistoryMethodAction } from "connected-react-router";
import { PaymentMethods, TaxLineItemInput } from "../cart/cart-page.types";
import { Addresses } from "../../redux/onboarding/onboarding.types";
import { CALCULATE_TAX_FOR_ORDER, CREATE_TRANSACTION_TAXJAR, GET_USER_BILLING_ADDRESS } from "../cart/queries";
import SyncLoader from "react-spinners/SyncLoader";
import AddressComponent from "../onboarding/step-one-address.component";
import { AddressTypes, buttonStyles } from "../onboarding/onboarding-page.types";
import { selectBillingAddress } from "../../redux/cart/cart-page.selector";
import { IBroadcastMessage, SeveritySnackbarEnum } from "../batch-upload/ContentUpload.types";
import { TaxForOrder } from "../../redux/cart/cart-page.types";
import  LicensePurchaseSummary  from "./license-order-summary.component";
import { capitalizeFirstLetter } from "../photo-details/photo-details-helper-functions";
import { createStripeAddressToken, getAddressInput, getLocationInput } from "../cart/cart-page-helperFunctions";
import { Stripe, StripeCardElement, StripeElements } from "@stripe/stripe-js";
import PulseLoader from 'react-spinners/PulseLoader';
import { CardDetailsList } from "../onboarding/yup-validation-objects";


const SubscriptionPayment: React.FC<SubscriptionPaymentProps> = ({...props}) => {
  const { currentUser, planToPurchase, tax, total, additionalBillingAddress, 
    removeSubscriptions, addTaxAction, handlePageChange, 
    broadcastSubscriptionAction } = props;
    
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(PaymentMethods.CARD);
  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    cardType: selectedPaymentMethod
  });

  const [processing, setProcessing] = useState(false);
  const [stripe] = useState(useStripe());
  const [elements] = useState(useElements());
  const [addSubscriptionMutation] = useMutation(BUY_LICENSE_PLAN);
  const [confirmLicensePlanMutation] = useMutation(CONFIRM_LICENSE_PLAN);
  const [removeSubscriptionMutation] = useMutation(DELETE_LICENSING_PLAN);
  const [sendLicensePlanInvoice] = useMutation(SEND_LICENSE_PLAN_INVOICE);

  const [userBillingAddress, setUserBillingAddress] = useState<Addresses | undefined>(undefined);
  const [useAnotherBillingAddress, setUseAnotherBilling] = useState(false);
  const [purchasedPlanId, setPurchasedPlanId] = useState(0);

  const [showAddressValidationError, setAddressValidationError] = useState(false);
  const [addressValidationError, setAddressError] = useState("");
  const [showCardValidationError, setCardValidationError] = useState(false);
  const [cardValidationError, setCardError] = useState("");
  const [stripeTransactionDetails, setStripeTransactionDetails] = useState({
    id: "",
    date: new Date()
  });

  const { data: billingData, loading: billingLoading, error: billingError } = useQuery(GET_USER_BILLING_ADDRESS, {variables: {userId: currentUser.id}});
  const [calculateTaxForOrder, {data: taxData, loading: taxLoading, error: taxError}] = useLazyQuery(CALCULATE_TAX_FOR_ORDER);
  const [createTaxjarTransaction, {data: transactionData, loading: transactionLoading, error: transactionError}] = useLazyQuery(CREATE_TRANSACTION_TAXJAR);

  const { t } = useTranslation();

  const cardElementOptions = {
    hidePostalCode: true
  };

  useEffect(() => {
    if(taxData && taxData.calculateTaxForOrder) {
      addTaxAction(taxData.calculateTaxForOrder);
    }
  }, [taxData])

  useEffect(() => {
    if(stripeTransactionDetails.id !== "") {
      createTransaction();
    }
  }, [stripeTransactionDetails])

  useEffect(() => {
    if(taxError) {
      broadcastSubscriptionAction({severity: SeveritySnackbarEnum.error, 
        message: "Failed to calculate tax for this billing address. Try again with a different address"});
    }

    if(transactionError) {
      broadcastSubscriptionAction({severity: SeveritySnackbarEnum.error, message: "Failed to create order transaction."});
    }
  }, [taxError, transactionError]);

  useEffect(() => {
    //here we recalculate if an address is changed or fetched
    if((useAnotherBillingAddress && additionalBillingAddress) || (!useAnotherBillingAddress && userBillingAddress)) {
      calculateTax();
    }
  }, [useAnotherBillingAddress, userBillingAddress, additionalBillingAddress])

  useEffect(() => {
    if(purchasedPlanId !== 0) {
      confirmPayment();
    }
  }, [purchasedPlanId])

  useEffect(() => {
    if(billingData && billingData.getUserBillingAddress) {
      if(billingData.getUserBillingAddress.country) {
        const addr = billingData.getUserBillingAddress;
 
        setUserBillingAddress({country: addr.country ? addr.country.country : "",
          countryCode: addr.country ? addr.country.countryCode : "",
          state: addr.province ? addr.province.stateProvince : "",
          stateCode: addr.province ? addr.province.stateCode : "",
          city: addr.city ? addr.city.city : "",
          zipCode: addr.zipCode ? addr.zipCode.zipCodeNumber : 0,
          addressLineOne: addr.address ? addr.address.addressLine1 : "",
          addressLineTwo: addr.address ? addr.address.addressLine2 : ""});
      } else {
        broadcastSubscriptionAction({severity: SeveritySnackbarEnum.info, 
          message: "Failed to find your billing address. Use this form to fill in your valid billing address."});
        setUseAnotherBilling(true);
      }
      
    } 
  }, [billingData]);

  const getLineItemsLicense = (items: LicensePlan[]) => {
    const lineItems = items.map((item: LicensePlan) => {
      const lineItem: TaxLineItemInput = {
        id: item.id.toString(),
        unit_price: item.totalPrice,
        discount: 0
      }
  
      return lineItem;
    })
  
    return lineItems;
  }

  const calculateTax = () => {
    const locationInput = getLocationInput(useAnotherBillingAddress, additionalBillingAddress, userBillingAddress);
    const addressInput = getAddressInput(useAnotherBillingAddress, additionalBillingAddress, userBillingAddress);

    calculateTaxForOrder({ variables: 
    {
      userId: currentUser.id, 
      totalAmount: total, 
      lineItems: getLineItemsLicense([planToPurchase]), 
      locationInput: locationInput,
      addressInput: addressInput
    }});
  }

  const createTransaction = () => {
    const locationInput = getLocationInput(useAnotherBillingAddress, additionalBillingAddress, userBillingAddress);
    const addressInput = getAddressInput(useAnotherBillingAddress, additionalBillingAddress, userBillingAddress);

    createTaxjarTransaction({
      variables: {
        stripeId: stripeTransactionDetails.id, 
        stripeDate: stripeTransactionDetails.date, 
        lineItems: getLineItemsLicense([planToPurchase]), 
        locationInput: locationInput, 
        addressInput: addressInput, 
        totalTax: tax?.amountToCollect, 
        totalAmount: total
      }
    })
  }

  const getLicensePlanId = () => {
    return planToPurchase.id;
  }

  const validationSchema = CardDetailsList;

  const { handleSubmit, handleChange, values, errors } = useFormik({
    initialValues: {
      firstName: state.firstName,
      lastName: state.lastName
    },
    validateOnBlur: true,
    validationSchema,
    onSubmit(values) {
      const { firstName, lastName } = values;
      if(firstName.trim() !== "" && lastName.trim() !== "") {
        checkout();
      }
    }
  });

  const checkIfAddressIsValid = () => {
    return (userBillingAddress?.countryCode) || 
          (additionalBillingAddress?.countryCode) ? true : false;
  }

  const checkIfPageIsValid = (stripe: Stripe | null, elements: StripeElements | null, cardElement: StripeCardElement | null) => {
    return stripe && elements && cardElement;
  }

  const taxIsValid = () => {
    return tax;
  }

  const checkout = async () => {
    resetValidationErrors();
    setProcessing(true);
    
    const cardElement = elements ? elements.getElement(CardElement) : null;
    if (!checkIfPageIsValid(stripe, elements, cardElement) || !taxIsValid()) {
      registerOrderHandlingError();
      return;
    }

    if(!checkIfAddressIsValid()) {
      displayAddressValidationError();
      return;
    }

    //create the payment intent
    await stripe!.createPaymentMethod({
      type: PaymentMethods.CARD,
      card: cardElement!
    }).then((result: any) => {
      if(result.error) {
        displayCardValidationError(result.error.message);
      } else {
        addSubscriptionToUser();
      }
    }).catch((error: any) => {
      registerPaymentProcessError();
      return;
    })
  };

  const addSubscriptionToUser = () => {
    addSubscriptionMutation({
      variables: {
        userId: currentUser!.id,
        licensePlanId: getLicensePlanId(),
        tax: tax ? tax.amountToCollect : 0
      }
    })
    .then((result: any) => {
      if (result.data.buyLicensePlan) {
        const planId = result.data.buyLicensePlan.id;
        setPurchasedPlanId(planId);
      }
    })
    .catch((error: any) => {
      registerOrderHandlingError();
    });
  }

  const confirmPayment = async () => {
    const cardElement = elements ? elements.getElement(CardElement) : null;
    if(cardElement) {
      const fullName = state.firstName + " " + state.lastName;
      const addressToken = createStripeAddressToken(fullName, useAnotherBillingAddress, additionalBillingAddress, userBillingAddress);
      const paymentToken = await stripe!.createToken(cardElement, addressToken)
        .catch((error: any) => {
          //remove subscription because payment confirmation has failed
          removeSubscription();
          registerPaymentProcessError();
          return;
        });
      const amountInCents = Math.floor(total * 100);

      await stripePayment(paymentToken, amountInCents, "usd", currentUser!.email)
        .then((result: any) => {
          if(result.paid) {
            confirmUserPlan();
            //send invoice
            sendInvoiceForLicensePlan();
            setStripeTransactionDetails({id: result.id, date: new Date(result.created * 1000)});
          }
        }).catch((error: any) => {
          //remove subscription because payment confirmation has failed
          removeSubscription();
          registerStripeError(error);
        });
    } else {
      registerPaymentProcessError();
    }
  }

  const confirmUserPlan = () => {
    confirmLicensePlanMutation({
      variables: {
        planId: purchasedPlanId
      }
    }).then((result: any) => {
      if(result.data.confirmLicensePlan) {
        setTimeout(() => {
          handlePageChange();
          removeSubscriptions();
        }, 3000);
       
        broadcastSubscriptionAction({severity: SeveritySnackbarEnum.success, 
          message: `${capitalizeFirstLetter(planToPurchase.licensingType)} license plan successfully added to active user plans`});
        setProcessing(false);
      }
    }).catch((error: any) => {
      registerPaymentProcessError();
    })
  }

  const sendInvoiceForLicensePlan = () => {
    sendLicensePlanInvoice({
      variables: {
        userId: currentUser!.id,
        licensePlanId: getLicensePlanId()
      }
    }).catch((error: any) => {
      registerOrderHandlingError();
    })
  }

  const removeSubscription = () => {
    removeSubscriptionMutation({variables: {userId: currentUser.id, licensePlanId: getLicensePlanId()}})
      .catch((error: any) => {
        registerOrderHandlingError();
      });
  }

  const resetValidationErrors = () => {
    setAddressValidationError(false);
    setAddressError("");
    setCardValidationError(false);
    setCardError("");
  }

  const displayAddressValidationError = () => {
    setAddressValidationError(true);
    setProcessing(false);
    setAddressError("Invalid billing address. Make sure you have submitted a valid country and state.");
  }

  const displayCardValidationError = (error: string) => {
    setCardValidationError(true);
    setProcessing(false);
    setCardError(error);
  }

  const registerStripeError = (errorMessage: string) => {
    broadcastSubscriptionAction({ severity: SeveritySnackbarEnum.error, message: `Payment error: ${errorMessage}` });
    setProcessing(false);
  }

  const registerPaymentProcessError = () => {
    broadcastSubscriptionAction({ severity: SeveritySnackbarEnum.error, message: t("Cart.Snackbar.Unsuccessful") });
    setProcessing(false);
  } 

  const registerOrderHandlingError = () => {
    broadcastSubscriptionAction({ severity: SeveritySnackbarEnum.error, message: t("Cart.Order.Unsuccessful") });
    setProcessing(false);
  }

  const toogleUseAnotherBillingAddress = () => {
    setUseAnotherBilling(!useAnotherBillingAddress);
  }

  return (
    <div className="cart-payment-container">
      {billingLoading ? 
        <div className="loading-box">
          <SyncLoader css={`display: block; margin: 0 auto; border-color: red;`} size={20} 
            color={"#36D2B3"} loading={billingLoading}/>
        </div>
      : null}
      <React.Fragment>
          {/* Uncomment when implemented */}
          {/* <button className="cart-paypal-checkout"><h2>Checkout with PayPal</h2></button> */}

        {/* BILLING ADDRESS */}
        {useAnotherBillingAddress ? 
          <Grow in={true}>
            <AddressComponent isInCheckout={true}
            userRole={currentUser.role!} addressType={AddressTypes.BILLING}/>
          </Grow>
          :
          <Grow in={true}>
            <Accordion expanded={true}>
              <AccordionSummary aria-controls="panel1c-content" id="panel1c-header">
                <div className="title">{t("Onboarding.Address.Billing.Address.Heading")}</div>
              </AccordionSummary>
                <div className="accordion-container">
                  <TextField
                    fullWidth
                    autoComplete="off"
                    id="country-input"
                    disabled={true}
                    label={t("Onboarding.Country.Label")}
                    name="country"
                    value={userBillingAddress ? userBillingAddress.country : ''}
                  />
                  <TextField
                    fullWidth
                    autoComplete="off"
                    id="province-input"
                    disabled={true}
                    label={t("Onboarding.State.Label")}
                    name="state"
                    value={userBillingAddress ? userBillingAddress.state : ''}
                  />
                  <TextField
                    fullWidth
                    autoComplete="off"
                    id="city-input"
                    disabled={true}
                    label={t("Onboarding.City.Label")}
                    name="city"
                    value={userBillingAddress ? userBillingAddress.city : ''}
                  />
                  <TextField
                    fullWidth
                    autoComplete="off"
                    id="zipCode-input"
                    disabled={true}
                    label={t("Onboarding.ZipCode")}
                    name="zipCode"
                    value={userBillingAddress ? userBillingAddress.zipCode : ''}
                  />
                  <TextField
                    fullWidth
                    autoComplete="off"
                    id="addressLine1-input"
                    disabled={true}
                    label={t("Onboarding.Address.AddressLine1")}
                    name="addressLineOne"
                    value={userBillingAddress ? userBillingAddress.addressLineOne : ''}
                  />
                  <TextField
                    fullWidth
                    autoComplete="off"
                    id="addressLine2-input"
                    disabled={true}
                    label={t("Onboarding.Address.AddressLine2")}
                    name="addressLineTwo"
                    value={userBillingAddress ? userBillingAddress.addressLineTwo : ''}
                  />
              </div>
            </Accordion>
          </Grow>
        }

        {showAddressValidationError ? 
          <Typography variant="body2" gutterBottom color="error">
            {addressValidationError}
          </Typography>
        : null}

        <Grow in={true}>
          <button onClick={() => toogleUseAnotherBillingAddress!()} className="back-button">
            <span className="button-text">
              {useAnotherBillingAddress ? t("Cart.Billing.Cancel.Billing") : t("Cart.Billing.Add.Another")}
            </span>
          </button>
        </Grow>

        {/* USE A CREDIT/DEBIT CARD */}
        <form onSubmit={handleSubmit}>
          <div className="details-container">
            <div className="detail">
              <Grow in={selectedPaymentMethod === PaymentMethods.CARD}>
                <Accordion expanded={true} >
                  <AccordionSummary id="panel1c-header">
                    <div className="title">
                      {t("Choose.Card.Type")}
                    </div>   
                  </AccordionSummary>
                  <div className="accordion-container">
                    <TextField
                      autoComplete="off"
                      type="text"
                      label="First name"
                      value={values.firstName}
                      name="firstName"
                      onChange={handleChange}
                      helperText={errors.firstName ? errors.firstName : null}
                    />
                    <TextField
                      autoComplete="off"
                      type="text"
                      label="Last name"
                      value={values.lastName}
                      name="lastName"
                      onChange={handleChange}
                      helperText={errors.lastName ? errors.lastName : null}
                    />

                    <CardElement options={cardElementOptions}/>

                    {showCardValidationError ? 
                      <Typography variant="body2" gutterBottom color="error">
                        {cardValidationError}
                      </Typography>
                    : null}
                  </div>
                </Accordion>
              </Grow>
            </div>
            <div className="detail">
              {/* ORDER SUMMARY */}
              <LicensePurchaseSummary loading={taxLoading} />
            </div>
          </div>

          <button type="submit" className="pay-button" disabled={processing}>
              {processing ?
              <PulseLoader css={`display: block; margin: 0 auto;`} size={10} margin={3}  
                color={"#EBEBEB"} loading={processing}/>
              : 
              "Complete checkout"}
          </button>
        </form>
      </React.Fragment>
    </div>
  );
};

const mapStateToProps = (state: StoreState): { planToPurchase: LicensePlan; currentUser: User;
  total: number, additionalBillingAddress: Addresses | null; tax: TaxForOrder | null; } => {
  return {
    planToPurchase: selectCurrentSubscriptionToPurhcase(state),
    total: selectCurrentSubscriptionTotal(state),
    currentUser: selectCurrentUser(state),
    additionalBillingAddress: selectBillingAddress(state),
    tax: selectTaxSub(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch<CallHistoryMethodAction | TSubscriptionReducerActions>) => {
  return {
    removeSubscriptions: () => dispatch<IEmptySubscription>({type: SubscriptionActionTypes.EMPTY_SUBSCRIPTION}),
    broadcastSubscriptionAction: (data: IBroadcastMessage) => dispatch<IBroadcastSubscriptionMessage>({
      type: SubscriptionActionTypes.BROADCAST_SUBSCRIPTION_MESSAGE, data: data
    }),
    addTaxAction: (data: TaxForOrder) => dispatch<IAddTaxForPlan>({type: SubscriptionActionTypes.ADD_TAX, data: data}),
  };
   
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionPayment);
