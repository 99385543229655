import {FetchPhotosAction} from './dataTable.actions';

export interface PhotoMetadata {
    id: number;
    title: string;
    url: string;
    thumbnailUrl: string;
}

export interface DataTableState {
    columns: string[];
    data: PhotoMetadata[];
    isLoading?: boolean;
}

export enum ActionTypes {
    fetchPhotos
}

export type Action = FetchPhotosAction;