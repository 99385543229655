import { Location } from '../contributor-content/contributor-content.types';

export const getLocation = (location: Location) => {
  let currentLocation = "N/A";

  if (location) {

    const cityExists = location.city && location.city.city;
    if (cityExists) {
      currentLocation = location.city.city;
    }

    const provinceExists = location.province && location.province.stateProvince;
    if (provinceExists) {

      if (currentLocation && currentLocation !== "N/A") {
        currentLocation = currentLocation + ", " + location.province.stateProvince;
      }
      else {
        currentLocation = location.province.stateProvince;
      }

    }

    const countryExists = location.country && location.country.country;
    if (countryExists) {
      if (currentLocation && currentLocation !== "N/A") {
        currentLocation = currentLocation + ", " + location.country.country;
      }
      else {
        currentLocation = location.country.country;
      }
    }
  }

  return currentLocation;
}
