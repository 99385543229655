
export interface RapidApiCountryDto {
    country: string;
    countryCode: string;
}

export interface RapidApiProvinceDto{
    province: string;
    isoCode: string;
    countryId: string;
}

export interface RapidApiCityDto{
    city: string;
    latitude: number;
    longitude: number;
}

export interface RapidApiDivisionDto {
    country: string;
    countryCode: string;
    regionCode: string;
    latitude: number;
    longitude: number;
}

export enum LocationGroups {
    COUNTRY = 'country',
    PROVINCE = 'stateProvince',
    CITY = 'city'
  }