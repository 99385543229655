import gql from 'graphql-tag';

export const GET_CONTENT_IN_CONTEXT = gql`
    query getContentInContextQuery($channelId: Int!, $subChannelId: Int!, $threadId: Int!){
        getContentInContext(channelId: $channelId, subChannelId: $subChannelId, threadId: $threadId) {
            content_id
            content_code
            content_caption
            content_headline
            content_publish_date
            content_path_to_file
            content_path_to_file_compressed
            content_external_link
            channel_title
            subChannel_title
            thread_title
            user_last_name
            user_first_name
            location_country
        }
    }
`;

export const GET_CONTENT_IN_NAVIGATION = gql`
    query getAllContentInNavigationQuery($channelId: Int, $subChannelId: Int, $threadId: Int, $status: String!, $first: Int!, $after: String!){
        getAllContentInNavigation(status: $status, channelId: $channelId , subChannelId: $subChannelId, threadId: $threadId, first: $first, after: $after){
            edges {
                node{
                    contentSetId
                    articleId
                    content {
                            id
                            code
                            headline
                            pathToFileCompressed
                            pathToFileThumbnail
                            externalLink
                            externalLinkMessage
                            publishDate
                            licensingType
                        }
                        uploadDate
                        type
                        title
                        contentNavigations {
                            channel {
                                id
                                title
                            }
                            subChannel {
                                id
                                title
                            }
                            thread {
                                id
                                title
                            }
                        }
                        contributor {
                            username
                            role
                            person {
                                id
                                firstName
                                lastName
                            }
                            company {
                                id
                                name
                            }
                        }
                    }
                }
                pageInfo{
                    hasNextPage
                    endCursor
                }
                totalSearchResults
            }
    }
`;

export const GET_CH_IDS = gql`
    mutation mutationGetIdByTitle($type: String!, $title: String!){
        getIdByTitle(type: $type, title:$title)
    }
`;