import React, { useEffect, useState } from "react";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { GET_ALL_CONTENT_COUNT, GET_CONTRIBUTOR_CONTENT } from "./queries";
import { StoreState } from "../../redux/root-reducer";
import { selectCurrentUser, selectDataTableRefreshState } from "../../redux/user/user.selectors";
import { connect } from "react-redux";
import { createTheme } from '@material-ui/core/styles';
import { FormControl, FormGroup, FormLabel, MenuItem, MuiThemeProvider, Select, TextField, Button, Link } from "@material-ui/core";
import Moment from "react-moment";
import './uploaded-content.styles.scss';
import { ContentStatus, UploadedContentProps, UserUploadedContent } from "./contributor-content.types";
import { useTranslation } from "react-i18next";
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import SyncLoader from 'react-spinners/SyncLoader';
import { ImageContainer } from "./image-container.component";
import { getLocation } from "../locations/locations.functions";
import { User, UserActionTypes } from "../../redux/user/user.types";
import PreferredCurrencyComponent from './prefferedCurrency.component';
import { selectUploadError } from "../../redux/content-upload/content-upload.selectors";
import { SnackbarComponent, SearchBarComponent, RefreshDateTime } from 'shared';
import { IBroadcastMessage, SeveritySnackbarEnum } from "../../components/batch-upload/ContentUpload.types";
import { Autocomplete, InputLabel } from "@mui/material";
import { Thread } from "../../components/channelsSidebar/channelSideBars.types";
import { Channel } from "../../components/home-component/home.types";
import { Subchannel } from "../../components/onboarding/onboarding-page.types";
import { LocationFilterProps } from "../../redux/search-bar/search-bar.types";
import { selectChannelFilter, selectContentStatus, selectContentType, selectFetchError, selectLocationFilter, selectPublishDate, selectSubchannelFilter, selectThreadFilter, selectUploadDate } from "../../redux/table-filter/table-filter.selectors";
import { TableFilterActions } from "../../redux/table-filter/table-filter.types";
import { IBroadcastFetchError, IClearBroadcastFetchError, IToggleFilterChannel, IToggleFilterContentStatus, IToggleFilterContentType, IToggleFilterLocation, IToggleFilterPublishDate, IToggleFilterSubchannel, IToggleFilterThread, IToggleFilterUploadDate, IToggleResetFilter, TTableFiltersReducerActions } from "../../redux/table-filter/table-filter.actions";
import { Dispatch } from "redux";
import { RapidApiCityDto, RapidApiCountryDto, RapidApiProvinceDto } from "../../globals/locations/locations.types";
import axios from "axios";
import { GET_CHANNELS } from "../../components/channels/queries";
import { GET_SUBCHANNELS, GET_THREADS } from "../../components/channelsSidebar/queries";
import { IToggleDataTableRefreshState, TUserReducerActions } from "../../redux/user/user.actions";
import {config} from "../../config";

const UploadedContent: React.FC<UploadedContentProps> = ({ ...props }) => {

  const { t } = useTranslation();
  const { currentUser, history, updateError, contentStatusFilter, contentTypeFilter, uploadDateFilter, publishDateFilter,
    channelFilter, subchannelFilter, threadFilter, locationFilter, dataTableRefreshState, fetchError,
    toggleContentTypeFilterAction, toggleContentStatusFilterAction, toggleUploadDateFilterAction,
    togglePublishDateFilterAction, toggleChannelFilterAction, toggleSubchannelFilterAction, toggleThreadFilterAction,
    toggleLocationFilterAction, resetTableFilterAction, toggleDataTableRefreshState, broadcastFetchErrorAction, clearBroadcastErrorAction } = props;

  const currentUserId = currentUser.id;
  const today = new Date();

  //QUERIES
  const [fetchData, { data, loading, error, refetch }] = useLazyQuery(GET_CONTRIBUTOR_CONTENT, {
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    onError(error) {
      broadcastFetchErrorAction({ severity: SeveritySnackbarEnum.error, message: t("Error.Fetch.UploadedContent") })
    }
  });

  const { data: countData, refetch: countRefetch } = useQuery(GET_ALL_CONTENT_COUNT, {
    variables: {
      contributorId: currentUserId,
      status: null,
      isFMC: null
    },
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true
  });

  const [allUploadedContentCount, setAllUploadedContentCount] = useState({
    singleContents: 0,
    contentSets: 0,
    articles: 0
  });

  const { data: dataChannel, error: error1 } = useQuery(GET_CHANNELS);
  const [fetchSubchannels, { data: dataSub }] = useLazyQuery(GET_SUBCHANNELS);
  const [fetchThreads, { data: dataThread }] = useLazyQuery(GET_THREADS);

  //USE STATE HOOKS
  const [uploadedContent, setUploadedContent] = useState<UserUploadedContent[]>([]);

  const [updateCurrencySnackbarShow, setUpdateCurrencySnackbarShow] = useState(false);
  const [fetchErrorSnackbarShow, setFetchErrorSnackbarShow] = useState(false);
  const [channels, setChannels] = useState<Channel[]>([]);
  const [subchannels, setSubchannels] = useState<Subchannel[]>([]);
  const [threads, setThreads] = useState<Thread[]>([]);

  const [selectedChannel, setSelectedChannel] = useState<Channel>({ id: 0, title: "", code: "" });
  const [selectedSubchannel, setSelectedSubchannel] = useState<Subchannel>({ id: 0, title: "" });
  const [selectedThread, setSelectedThread] = useState<Thread>({ id: 0, title: "" });

  const [startUploadDate, setStartUploadDate] = useState<string | null>(null);
  const [endUploadDate, setEndUploadDate] = useState<string | null>(null);

  const [startPublishDate, setStartPublishDate] = useState<string | null>(null);
  const [endPublishDate, setEndPublishDate] = useState<string | null>(null);

  const [countries, setCountries] = useState<RapidApiCountryDto[]>([]);
  const [provinces, setProvinces] = useState<RapidApiProvinceDto[]>([]);
  const [cities, setCities] = useState<RapidApiCityDto[]>([]);

  const [selectedCountry, setSelectedCountry] = useState<RapidApiCountryDto | null>(null);
  const [selectedProvince, setSelectedProvince] = useState<RapidApiProvinceDto | null>(null);
  const [selectedCity, setSelectedCity] = useState<RapidApiCityDto | null>(null);

  const [pageNumber, setPageNumber] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [searchString, setSearchString] = useState<string>('');
  const [fullContentCount, setFullContentCount] = useState<number>(0)

  //USE EFFECT HOOKS
  useEffect(() => {
    if (updateError.severity && updateError.message) {
      setUpdateCurrencySnackbarShow(true);
    }
  }, [updateError]);

  useEffect(() => {
    if (fetchError.severity && fetchError.message) {
      setFetchErrorSnackbarShow(true);
    }
  }, [fetchError]);

  useEffect(() => {
    if (countData && countData.getAllContentCountInDatatables) {
      setAllUploadedContentCount({
        singleContents: countData.getAllContentCountInDatatables.singleContentCount,
        contentSets: countData.getAllContentCountInDatatables.contentSetsCount,
        articles: countData.getAllContentCountInDatatables.articlesCount
      });
    }
  }, [countData]);

  useEffect(() => {
    axios({
      method: 'GET',
      url: `${config.REACT_APP_PUBLIC_SERVER_HOST}/countries`,
    })
      .then((res: any) => setCountries(res.data))
      .catch((error: any) => {
        // console.log(error)
      })
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      axios({
        method: 'GET',
        url: `${config.REACT_APP_PUBLIC_SERVER_HOST}/provinces/${selectedCountry.countryCode}`,
      })
        .then((res: any) => {
          setProvinces(res.data)
        })
        .catch((error: any) => {
          console.log(error)
        });
      toggleLocationFilterAction({ city: [], stateProvince: "", country: selectedCountry.country });
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedCountry && selectedProvince) {
      axios({
        method: 'GET',
        url: `${config.REACT_APP_PUBLIC_SERVER_HOST}/cities/${selectedCountry.countryCode}/${selectedProvince.isoCode}`
      })
        .then((res: any) => {
          setCities(res.data)
        })
        .catch((error: any) => {
          // console.log(error)
        });
      toggleLocationFilterAction({ city: [], stateProvince: selectedProvince.province, country: selectedCountry.country });
    }
  }, [selectedCountry, selectedProvince]);

  useEffect(() => {
    if (selectedCity && selectedProvince && selectedCountry) {
      toggleLocationFilterAction({ city: [selectedCity.city], stateProvince: selectedProvince.province, country: selectedCountry.country });
    }
  }, [selectedCountry, selectedProvince, selectedCity])

  useEffect(() => {
    if (startUploadDate && !endUploadDate) {
      toggleUploadDateFilterAction([new Date(startUploadDate), today]);
    } else if (startUploadDate && endUploadDate) {
      let endDate = new Date(endUploadDate);
      endDate.setHours(today.getHours());
      toggleUploadDateFilterAction([new Date(startUploadDate), new Date(endDate)]);
    } else if (!startUploadDate && !endUploadDate) {
      toggleUploadDateFilterAction([]);
    }
  }, [startUploadDate, endUploadDate]);

  useEffect(() => {
    if (startPublishDate && !endPublishDate) {
      togglePublishDateFilterAction([new Date(startPublishDate), today]);
    } else if (startPublishDate && endPublishDate) {
      let endDate = new Date(endPublishDate);
      endDate.setHours(today.getHours());
      togglePublishDateFilterAction([new Date(startPublishDate), new Date(endDate)]);
    } else if (!startPublishDate && !endPublishDate) {
      togglePublishDateFilterAction([]);
    }
  }, [startPublishDate, endPublishDate]);

  useEffect(() => {
    if (dataChannel && dataChannel.getAllChannels) {
      dataChannel.getAllChannels.sort((a: Channel, b: Channel) => a.title.localeCompare(b.title))
      setChannels(dataChannel.getAllChannels);
    }
  }, [dataChannel]);

  useEffect(() => {
    if (dataSub && dataSub.getAllSubChannelsByChannelId) {
      setSubchannels(dataSub.getAllSubChannelsByChannelId);
    }
  }, [dataSub]);

  useEffect(() => {
    if (dataThread && dataThread.getAllThreadsBySubChannelId) {
      setThreads(dataThread.getAllThreadsBySubChannelId);
    }
  }, [dataThread]);

  useEffect(() => {
    fetchData({
      variables: {
        pageNumber: 0,
        contentPerPage: rowsPerPage,
        contributorId: currentUser.id,
        searchString: searchString,
        contentType: contentTypeFilter,
        contentStatus: contentStatusFilter,
        uploadDate: uploadDateFilter,
        publishDate: publishDateFilter,
        channelId: channelFilter && channelFilter.id ? channelFilter.id : 0,
        subchannelId: subchannelFilter && subchannelFilter.id ? subchannelFilter.id : 0,
        threadId: threadFilter && threadFilter.id ? threadFilter.id : 0,
        channelTitle: channelFilter && channelFilter.title ? channelFilter.title : '',
        subchannelTitle: subchannelFilter && subchannelFilter.title ? subchannelFilter.title : '',
        threadTitle: threadFilter && threadFilter.title ? threadFilter.title : '',
        countryName: locationFilter && locationFilter.country ? locationFilter.country : "",
        provinceName: locationFilter && locationFilter.stateProvince ? locationFilter.stateProvince : "",
        cityName: locationFilter && locationFilter.city ? locationFilter.city[0] : []
      }
    })
    setPageNumber(0);
  }, [rowsPerPage, contentTypeFilter, contentStatusFilter, uploadDateFilter, publishDateFilter, channelFilter, subchannelFilter, threadFilter, locationFilter]);

  useEffect(() => {
    if (data && data.getUserUploadedContent) {
      setUploadedContent(data.getUserUploadedContent.userUploadedContent);
      setFullContentCount(data.getUserUploadedContent.allResultsCount);
    }
  }, [data])

  //FUNCTIONS
  const callRefetch = () => {
    refetch();
    countRefetch();
  }

  const handleAddMore = () => {
    history.push('/content-upload');
  };

  const handleClose = () => {
    setUpdateCurrencySnackbarShow(false);
  }

  const handleCloseFetchError = () => {
    setFetchErrorSnackbarShow(false);
    clearBroadcastErrorAction();
  }

  const getStatus = (status: string) => {
    if (status === ContentStatus.INCOMPLETE) {
      return "Incomplete";
    } else if (status === ContentStatus.PUBLISHED) {
      return "Published";
    } else if (status === ContentStatus.DENIED) {
      return "Denied";
    } else if (status === ContentStatus.PENDING_APPROVAL) {
      return "Pending approval";
    }
  };

  const handleChangeChannels = (event: any, newValue: any) => {
    if (channels) {
      const channel = channels.filter(option => {
        return option.title === newValue;
      })[0]
      setSelectedChannel(channel);
      toggleChannelFilterAction(channel);
      toggleSubchannelFilterAction({ id: 0, title: '' });
      toggleThreadFilterAction({ id: 0, title: '' })
      setSelectedSubchannel({ id: 0, title: '' });
      setSelectedThread({ id: 0, title: '' });
      if (channel && channel.id) {
        fetchSubchannels({ variables: { id: channel.id } })
      }
    }
  }

  const handleChangeSubchannels = (event: any, newValue: any) => {
    if (subchannels) {
      const subchannel = subchannels.filter(option => {
        return option.title === newValue;
      })[0]
      toggleSubchannelFilterAction(subchannel);
      toggleThreadFilterAction({ id: 0, title: '' });
      setSelectedSubchannel(subchannel);
      setSelectedThread({ id: 0, title: '' });
      if (subchannel && subchannel.id) {
        fetchThreads({ variables: { id: subchannel.id } })
      }
    }
  }

  const handleChangeThreads = (event: any, newValue: any) => {
    if (threads) {
      const thread = threads.filter(option => {
        return option.title === newValue;
      })[0]
      toggleThreadFilterAction(thread);
      setSelectedThread(thread);
    }
  };

  const handleAutoCompleteCountriesChange = (event: any, newValue: any) => {
    if (countries) {
      const country = countries.filter(option => {
        return option.country === newValue;
      })[0]
      setSelectedCountry(country);
      setSelectedProvince(null);
      setSelectedCity(null);
    }
  }
  const handleAutoCompleteProvinceChange = (event: any, newValue: any) => {
    if (provinces) {
      const province = provinces.filter(option => {
        return option.province === newValue;
      })[0];

      setSelectedProvince(province);
      setSelectedCity(null);
    }
  }
  const handleAutoCompleteCityChange = (event: any, newValue: any) => {
    if (cities) {
      const city = cities.filter(option => {
        return option.city === newValue;
      })[0];

      setSelectedCity(city);
    }
  };

  const onSubmitSearch = (searchString: string) => {
    fetchData({ variables: { pageNumber: 0, contentPerPage: rowsPerPage, contributorId: currentUser.id, searchString: searchString } });
    setPageNumber(0);
    setSearchString(searchString)
  };

  const handleResetSearch = () => {
    fetchData({ variables: { pageNumber: 0, contentPerPage: rowsPerPage, contributorId: currentUser.id, searchString: "" } });
    setPageNumber(0);
    setSearchString("")
  }

  const customSearchRender = (searchText: string, handleSearch: any, hideSearch: any, options: any) => {
    return <SearchBarComponent handleSearch={onSubmitSearch} hideSearch={hideSearch} resetSearch={handleResetSearch} />
  };

  const columns = [
    {
      name: t("ContributorTable.Column.Preview"),
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: t("ContributorTable.Column.Code"),
      options: {
        sort: false,
        filter: false
      }
    },
    {
      name: t("ContributorTable.Column.Headline"),
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: t("ContributorTable.Column.Type"),
      options: {
        reset: true,
        sort: false,
        filterType: 'custom' as 'custom',
        customFilterListOptions: {
          render: (v: any) => v,
          update: (filterList: any, filterPos: any, index: any) => {
            filterList[index].splice(filterPos, 1);
            if (filterList[index].length === 0) {
              toggleContentTypeFilterAction('')
            }
            return filterList;
          },
        },
        filterOptions: {
          names: [],
          display: (filterList: any, onChange: any, index: any, column: any) => {
            const optionValues = ['set', 'article', 'image'];
            return (
              <FormControl>
                <InputLabel htmlFor='select-multiple-chip'>
                  Content type
                </InputLabel>
                <Select
                  value={filterList[index]}
                  renderValue={(selected: any) => selected}
                  onChange={event => {
                    filterList[index][0] = event.target.value;
                    toggleContentTypeFilterAction(filterList[index][0]);
                    onChange(filterList[index], index, column);
                  }}
                >
                  {optionValues.map(item => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
          }
        }
      }
    },
    {
      name: t("ContributorTable.Column.Status"),
      options: {
        reset: true,
        filter: true,
        sort: false,
        filterType: 'custom' as 'custom',
        customFilterListOptions: {
          render: (v: any) => v,
          update: (filterList: any, filterPos: any, index: any) => {
            filterList[index].splice(filterPos, 1);
            if (filterList[index].length === 0) {
              toggleContentStatusFilterAction('')
            }
            return filterList;
          },
        },
        filterOptions: {
          names: [],
          display: (filterList: any, onChange: any, index: any, column: any) => {
            const optionValues = [ContentStatus.PUBLISHED, ContentStatus.PENDING_APPROVAL, ContentStatus.INCOMPLETE, ContentStatus.DENIED];
            return (
              <FormControl>
                <InputLabel htmlFor='select-multiple-chip'>
                  Content status
                </InputLabel>
                <Select
                  value={filterList[index]}
                  renderValue={(selected: any) => selected}
                  onChange={event => {
                    filterList[index][0] = event.target.value;
                    toggleContentStatusFilterAction(filterList[index][0]);
                    onChange(filterList[index], index, column);
                  }}
                >
                  {optionValues.map(item => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
          }
        }
      }
    },
    {
      name: t("ContributorTable.Column.UploadedOn"),
      options: {
        reset: true,
        sort: false,
        filterType: 'custom' as 'custom',
        customFilterListOptions: {
          render: (v: any) => {
            if (v[0] && v[1]) {
              return `Start date: ${v[0]}, End date: ${v[1]}`;
            } else if (v[0]) {
              return `Start date: ${v[0]}`;
            } else if (v[1]) {
              return `End date: ${v[1]}`;
            }
            return " ";
          },
          update: (filterList: any, filterPos: any, index: any) => {
            toggleUploadDateFilterAction([]);
            setStartUploadDate(null);
            setEndUploadDate(null);
            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, '');
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }

            return filterList;
          },
        },
        filterOptions: {
          names: [],
          display: (filterList: any, onChange: any, index: any, column: any) => (
            <div>
              <FormLabel>Upload date</FormLabel>
              <FormGroup row>
                <TextField
                  id="startDate"
                  label="From"
                  type="date"
                  autoComplete="off"
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={filterList[index][0] || ""}
                  onChange={event => {
                    filterList[index][0] = event.target.value;
                    setStartUploadDate(filterList[index][0]);
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: "45%", marginRight: "5%" }}
                />
                <TextField
                  id="endDate"
                  label="To"
                  type="date"
                  autoComplete="off"
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={filterList[index][1] || ""}
                  onChange={event => {
                    filterList[index][1] = event.target.value;
                    setEndUploadDate(filterList[index][1]);
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: "45%", marginRight: "5%" }}
                />
              </FormGroup>
            </div>
          )
        }
      }
    },
    {
      name: t("ContributorTable.Column.PublishedOn"),
      options: {
        reset: true,
        sort: false,
        filterType: 'custom' as 'custom',
        customFilterListOptions: {
          render: (v: any) => {
            if (v[0] && v[1]) {
              return `Start date: ${v[0]}, End date: ${v[1]}`;
            } else if (v[0]) {
              return `Start date: ${v[0]}`;
            } else if (v[1]) {
              return `End date: ${v[1]}`;
            }
            return " ";
          },
          update: (filterList: any, filterPos: any, index: any) => {
            togglePublishDateFilterAction([]);
            setStartPublishDate(null);
            setEndPublishDate(null);
            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, '');
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }

            return filterList;
          },
        },
        filterOptions: {
          names: [],
          display: (filterList: any, onChange: any, index: any, column: any) => (
            <div>
              <FormLabel>Publish date</FormLabel>
              <FormGroup row>
                <TextField
                  id="startDate"
                  label="From"
                  type="date"
                  autoComplete="off"
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={filterList[index][0] || ""}
                  onChange={event => {
                    filterList[index][0] = event.target.value;
                    setStartPublishDate(filterList[index][0])
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: "45%", marginRight: "5%" }}
                />
                <TextField
                  id="endDate"
                  label="To"
                  type="date"
                  autoComplete="off"
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={filterList[index][1] || ""}
                  onChange={event => {
                    filterList[index][1] = event.target.value;
                    setEndPublishDate(filterList[index][1])
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: "45%", marginRight: "5%" }}
                />
              </FormGroup>
            </div>
          )
        }
      }
    },
    {
      name: "Navigation",
      options: {
        reset: true,
        sort: false,
        filterType: "custom" as "custom",
        customFilterListOptions: {
          render: (v: any) => {
            if (v[0] && v[1] && v[2]) {
              return `Channel: ${v[0]}, Subchannel: ${v[1]}, Thread: ${v[2]}`;
            } else if (v[0] && v[1]) {
              return `Channel: ${v[0]}, Subchannel: ${v[1]}`;
            } else if (v[0]) {
              return `Channel: ${v[0]}`;
            } else if (v[1]) {
              return `Subchannel: ${v[1]}`;
            } else if (v[2]) {
              return `Thread: ${v[2]}`;
            }
            return " ";
          },
          update: (filterList: any, filterPos: any, index: any) => {
            toggleChannelFilterAction({ id: 0, title: '', code: '' });
            toggleSubchannelFilterAction({ id: 0, title: '' });
            toggleThreadFilterAction({ id: 0, title: '' });
            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, '');
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }

            return filterList;
          }
        },
        filterOptions: {
          display: (filterList: any, onChange: any, index: any, column: any) => {
            return (
              <div>
                <FormLabel>Navigation</FormLabel>
                <FormGroup row>
                  <Autocomplete
                    id="channel-autocomplete"
                    disableClearable
                    options={channels.map((channel) => channel.title)}
                    style={{ width: "30%", marginLeft: "1.5%", marginRight: "1.5%" }}
                    onChange={(event: any, value: any) => {
                      filterList[index][0] = value;
                      handleChangeChannels(event, value);
                      onChange(filterList[index], index, column);
                    }}
                    value={selectedChannel && selectedChannel.title ? selectedChannel.title : ''}
                    renderInput={(params: any) =>
                      <TextField
                        {...params}
                        id="channel"
                        placeholder="Channel"
                        type="text"
                        autoComplete="off"
                        InputLabelProps={{
                          shrink: true
                        }}
                        name="channel-filter" className='text-field'
                      />
                    }
                  />
                  <Autocomplete
                    id="subchannel-autocomplete"
                    disableClearable
                    options={subchannels.map((subchannel) => subchannel.title)}
                    style={{ width: "30%", marginLeft: "1.5%", marginRight: "1.5%" }}
                    onChange={(event: any, value: any) => {
                      filterList[index][1] = value;
                      handleChangeSubchannels(event, value);
                      onChange(filterList[index], index, column);
                    }}
                    value={selectedSubchannel && selectedSubchannel.title ? selectedSubchannel.title : ''}
                    renderInput={(params: any) =>
                      <TextField
                        {...params}
                        id="subchannel"
                        placeholder="Subchannel"
                        type="text"
                        autoComplete="off"
                        InputLabelProps={{
                          shrink: true
                        }}
                        name="subchannel-filter" className='text-field'
                      />
                    }
                  />
                  <Autocomplete
                    id="thread-autocomplete"
                    disableClearable
                    options={threads.map((thread) => thread.title)}
                    style={{ width: "30%", marginLeft: "1.5%", marginRight: "1.5%" }}
                    onChange={(event: any, value: any) => {
                      filterList[index][2] = value;
                      handleChangeThreads(event, value);
                      onChange(filterList[index], index, column);
                    }}
                    value={selectedThread && selectedThread.title ? selectedThread.title : ""}
                    renderInput={(params: any) =>
                      <TextField
                        {...params}
                        id="thread"
                        placeholder="Thread"
                        type="text"
                        autoComplete="off"
                        InputLabelProps={{
                          shrink: true
                        }}
                        name="thread-filter" className='text-field'
                      />
                    }
                  />
                </FormGroup>
              </div>
            )
          }
        }
      }
    },
    {
      name: t("ContributorTable.Column.Location"),
      options: {
        reset: true,
        filterType: "custom" as "custom",
        sort: false,
        customFilterListOptions: {
          render: (v: any) => {
            if (v[0] && v[1] && v[2]) {
              return `Country: ${v[0]}, Province: ${v[1]}, City: ${v[2]}`;
            } else if (v[0] && v[1]) {
              return `Country: ${v[0]}, Province: ${v[1]}`;
            } else if (v[0]) {
              return `Country: ${v[0]}`;
            } else if (v[1]) {
              return `Provice: ${v[1]}`;
            } else if (v[2]) {
              return `City: ${v[2]}`;
            }
            return " ";
          },
          update: (filterList: any, filterPos: any, index: any) => {
            toggleLocationFilterAction({ city: [], stateProvince: "", country: "" });
            setSelectedCountry(null);
            setSelectedProvince(null);
            setSelectedCity(null)
            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, '');
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }

            return filterList;
          }
        },
        filterOptions: {
          display: (filterList: any, onChange: any, index: any, column: any) => {
            return (
              <div>
                <FormLabel>Location</FormLabel>
                <FormGroup row>
                  <Autocomplete
                    id="autocomplete-country"
                    style={{ width: "30%", marginLeft: "1.5%", marginRight: "1.5%" }}
                    options={countries.map((option) => option.country)}
                    disableClearable
                    value={selectedCountry ? selectedCountry.country : ""}
                    onChange={(event: any, value: any) => {
                      filterList[index][0] = value;
                      handleAutoCompleteCountriesChange(event, value);
                      onChange(filterList[index], index, column);
                    }}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'countryInput',
                        }}
                        autoComplete='countryInput'
                        id="country-input" variant='standard' placeholder="Country" name='countryInput'
                      />
                    )}
                  />
                  <Autocomplete
                    id="province-autocomplete"
                    style={{ width: "30%", marginLeft: "1.5%", marginRight: "1.5%" }}
                    value={selectedProvince ? selectedProvince.province : ""}
                    options={provinces.map((option) => option.province)}
                    disableClearable
                    onChange={(event: any, value: any) => {
                      filterList[index][1] = value;
                      handleAutoCompleteProvinceChange(event, value);
                      onChange(filterList[index], index, column);
                    }}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'provinceInput',
                        }}
                        autoComplete='provinceInput'
                        id="province-input" variant='standard' placeholder="Province" name='provinceInput'
                      />
                    )}
                  />
                  <Autocomplete
                    id="city-autocomplete"
                    style={{ width: "30%", marginLeft: "1.5%", marginRight: "1.5%" }}
                    value={selectedCity ? selectedCity.city : ""}
                    options={cities.map((option) => option.city)}
                    disableClearable
                    autoHighlight
                    onChange={(event: any, value: any) => {
                      filterList[index][2] = value;
                      handleAutoCompleteCityChange(event, value);
                      onChange(filterList[index], index, column);
                    }}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'cityInput',
                        }}
                        autoComplete='cityInput'
                        id="city-input" variant='standard' placeholder="City" name='cityInput'
                      />
                    )}
                  />
                </FormGroup>
              </div>
            )
          }
        }
      }
    },
    {
      name: t("ContributorTable.Column.ExternalLink"),
      options: {
        filter: false,
        sort: false
      }
    }
  ];

  const options: MUIDataTableOptions = {
    count: fullContentCount,
    page: pageNumber,
    rowsPerPage: rowsPerPage,
    jumpToPage: true,
    serverSide: true,
    selectableRowsHeader: false,
    selectableRowsOnClick: false,
    selectableRows: "none",
    filter: true,
    fixedHeader: false,
    responsive: "standard",
    download: false,
    print: false,
    searchText: searchString,
    textLabels: {
      body: {
        noMatch: loading ?
          <SyncLoader css={`display: block; margin: auto 0; width: 100%; height: 100%; z-index: 100;`}
            size={20} color={"#36D2B3"} loading={loading} />
          :
          t("ContributorTable.NoResults.Message"),
      },
    },
    customToolbar: () => {
      return (
        <PreferredCurrencyComponent />
      )
    },
    customSearchRender: customSearchRender,
    onChangePage: (currentPage: number) => {
      fetchData({
        variables: {
          pageNumber: currentPage,
          contentPerPage: rowsPerPage,
          contributorId: currentUser.id,
          searchString: searchString ? searchString : "",
          contentType: contentTypeFilter,
          contentStatus: contentStatusFilter,
          uploadDate: uploadDateFilter,
          publishDate: publishDateFilter,
          channelId: channelFilter && channelFilter.id ? channelFilter.id : 0,
          subchannelId: subchannelFilter && subchannelFilter.id ? subchannelFilter.id : 0,
          threadId: threadFilter && threadFilter.id ? threadFilter.id : 0,
          channelTitle: channelFilter && channelFilter.title ? channelFilter.title : '',
          subchannelTitle: subchannelFilter && subchannelFilter.title ? subchannelFilter.title : '',
          threadTitle: threadFilter && threadFilter.title ? threadFilter.title : '',
          countryName: locationFilter && locationFilter.country ? locationFilter.country : "",
          provinceName: locationFilter && locationFilter.stateProvince ? locationFilter.stateProvince : "",
          cityName: locationFilter && locationFilter.city ? locationFilter.city[0] : []
        }
      })
      setPageNumber(currentPage);
    },
    onFilterChange: (changedColumn: any, filterList: any, type: any) => {
      if (type === 'reset') {
        resetTableFilterAction();
        setSelectedChannel({ id: 0, title: '', code: '' });
        setSelectedSubchannel({ id: 0, title: '' });
        setSelectedThread({ id: 0, title: '' });
        setSelectedCountry(null);
        setSelectedProvince(null);
        setSelectedCity(null)
      }
    },
    onChangeRowsPerPage: (numberOfRows: number) => {
      setRowsPerPage(numberOfRows);
    }
  };

  const getMuiTheme = (options: MUIDataTableOptions) => {
    return createTheme({
      overrides:
      {
        MuiPaper: {
          elevation4: {
            margin: '1%'
          }
        },
        MuiTableCell:
        {
          root:
          {
            paddingLeft: "40px",
            maxWidth: "140px",
          }
        },
        MuiToolbar:
        {
          root:
          {
            width: '95%',
            height: "100px",
            "&:last-child": {
              height: "90px",
            }

          }
        },
        MuiTablePagination:
        {
          root:
          {
            width: "100%",
            display: "flex",
            justifyContent: "center"
          }
        }
      }
    })
  };

  const getExternalLink = (link: string, linkMessage: string) => {
    if (link !== "N/A") {
      return <Link target="_blank" href={`${link}`} rel="noopener" underline="hover">
        {linkMessage ? linkMessage : "Link"}
      </Link>
    } else {
      return <Link href="#" rel="noopener" underline="hover">
        {link}
      </Link>
    }

  }

  const createTableRow = (item: UserUploadedContent) => {
    return [
      <ImageContainer src={item.content.pathToFileThumbnail} />,
      item.content.code,
      decodeURI(item.title), item.type,
      <span className={item.status ? item.status : item.content.status}>
        {item.status ? getStatus(item.status) : getStatus(item.content.status)}
      </span>,
      <Moment format="DD/MM/YYYY">{item.uploadDate}</Moment>,
      item.publishDate ? <Moment
        format="DD/MM/YYYY">{item.publishDate}</Moment> : "not published",
      item.contentNavigations.length > 0
        ?
        item.contentNavigations.map(nav => {
          return <span key={nav.channel.id} className={nav.thread.status}> {nav.channel.title + ' => ' + nav.subChannel.title + ' => ' + nav.thread.title + "\n"}</span>
        }) : "No navigations",
      getLocation(item.location),
      getExternalLink(decodeURI(item.content.externalLink), decodeURI(item.content.externalLinkMessage))
    ]
  }

  return (
    <div>
      <span className='results-container'>{allUploadedContentCount.singleContents} Images | {allUploadedContentCount.contentSets} Content sets | {allUploadedContentCount.articles} Articles</span>
      <div className="contributer-table-heading">
        <h2>{t("ContributorTable.Title")}</h2>
      </div>
      <div className="refetch-container">
        <RefreshDateTime loading={loading} autoRefreshOn={dataTableRefreshState}
          refreshTime={30000} refreshFunc={callRefetch} toggleReduxFunction={toggleDataTableRefreshState} />

        <Button
          size="small"
          className="upload-white-button"
          variant="contained"
          onClick={handleAddMore}
        >
          {t("ContributorTable.AddMore.Button")} +
        </Button>
      </div>
      <MuiThemeProvider theme={getMuiTheme(options)}>
        <MUIDataTable title={""} columns={columns} options={options}
          data={uploadedContent.map((item: UserUploadedContent) => createTableRow(item))} />
      </MuiThemeProvider>
      <SnackbarComponent showSnackbar={updateCurrencySnackbarShow} handleClose={handleClose}
        severity={updateError.severity}
        message={updateError.message} />
      <SnackbarComponent showSnackbar={fetchErrorSnackbarShow} handleClose={handleCloseFetchError}
        severity={fetchError.severity}
        message={fetchError.message} />
    </div>
  );
}

const mapStateToProps = (state: StoreState): {
  currentUser: User, updateError: IBroadcastMessage, contentStatusFilter: string, contentTypeFilter: string,
  uploadDateFilter: Date[], publishDateFilter: Date[], channelFilter: Channel,
  subchannelFilter: Subchannel, threadFilter: Thread, locationFilter: LocationFilterProps,
  dataTableRefreshState: boolean, fetchError: IBroadcastMessage
} => {
  return {
    currentUser: selectCurrentUser(state),
    updateError: selectUploadError(state),
    contentStatusFilter: selectContentStatus(state),
    contentTypeFilter: selectContentType(state),
    uploadDateFilter: selectUploadDate(state),
    publishDateFilter: selectPublishDate(state),
    channelFilter: selectChannelFilter(state),
    subchannelFilter: selectSubchannelFilter(state),
    threadFilter: selectThreadFilter(state),
    locationFilter: selectLocationFilter(state),
    dataTableRefreshState: selectDataTableRefreshState(state),
    fetchError: selectFetchError(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<TTableFiltersReducerActions | TUserReducerActions>) => {
  return {
    toggleContentTypeFilterAction: (data: string) => dispatch<IToggleFilterContentType>({ type: TableFilterActions.TOGGLE_TABLE_FILTER_CONTENT_TYPE, data: data }),
    toggleContentStatusFilterAction: (data: string) => dispatch<IToggleFilterContentStatus>({ type: TableFilterActions.TOGGLE_TABLE_FILTER_CONTENT_STATUS, data: data }),
    toggleUploadDateFilterAction: (data: Date[]) => dispatch<IToggleFilterUploadDate>({ type: TableFilterActions.TOGGLE_TABLE_FILTER_UPLOAD_DATE, data: data }),
    togglePublishDateFilterAction: (data: Date[]) => dispatch<IToggleFilterPublishDate>({ type: TableFilterActions.TOGGLE_TABLE_FILTER_PUBLISH_DATE, data: data }),
    toggleChannelFilterAction: (data: Channel) => dispatch<IToggleFilterChannel>({ type: TableFilterActions.TOGGLE_TABLE_FILTER_CHANNEL, data: data }),
    toggleSubchannelFilterAction: (data: Subchannel) => dispatch<IToggleFilterSubchannel>({ type: TableFilterActions.TOGGLE_TABLE_FILTER_SUBCHANNEL, data: data }),
    toggleThreadFilterAction: (data: Thread) => dispatch<IToggleFilterThread>({ type: TableFilterActions.TOGGLE_TABLE_FILTER_THREAD, data: data }),
    toggleLocationFilterAction: (data: LocationFilterProps) => dispatch<IToggleFilterLocation>({ type: TableFilterActions.TOGGLE_TABLE_FILTER_LOCATION, data: data }),
    resetTableFilterAction: () => dispatch<IToggleResetFilter>({ type: TableFilterActions.RESET_TABLE_FILTERS }),
    toggleDataTableRefreshState: (data: boolean) => dispatch<IToggleDataTableRefreshState>({
      type: UserActionTypes.TOGGLE_DATA_TABLE_REFRESH_STATE,
      data: data
    }),
    broadcastFetchErrorAction: (data: IBroadcastMessage) => dispatch<IBroadcastFetchError>({ type: TableFilterActions.BROADCAST_ERROR, data: data }),
    clearBroadcastErrorAction: () => dispatch<IClearBroadcastFetchError>({ type: TableFilterActions.CLEAR_BROADCAST })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadedContent);
