import React, { useEffect, useState } from "react";
import {
  GetLicensingPlanPageRowProps,
  MyLicensingPlanPageRowProps
} from "./my-license-page.types";
import { useTranslation } from "react-i18next";
import "./my-license-page.styles.scss";
import { getExpDate, getPlanPeriod } from "./license-page-helperFunctions";
import { getToTwoDecimals } from "../cart/cart-page-helperFunctions";
import { capitalizeFirstLetter } from "../photo-details/photo-details-helper-functions";

export const GetLicensingPlanPageRow: React.FC<GetLicensingPlanPageRowProps> = ({
  ...props
}) => {
  const { tableItems, header, isHeader, getPlan } = props;
  const { t } = useTranslation();

  return (
    <div
      className="license-page-rows"
      key={isHeader ? header!.headerId : tableItems!.id}
    >
      <div className="license-page-cells hidden-tabs">
        {isHeader ? header!.headerId : tableItems!.id}
      </div>
      <div className="license-page-cells">
        {isHeader ? header!.headerContentType : capitalizeFirstLetter(tableItems!.licensingType)}
      </div>
      <div className="license-page-cells">
        {isHeader ? header!.headerPlanType : tableItems!.type}
      </div>
      <div className="license-page-cells">
        {isHeader ? header!.headerAmount : tableItems!.amount}
      </div>
      <div className="license-page-cells">
        {isHeader
          ? header!.headerPeriod
          : t(getPlanPeriod(tableItems!.period))}
      </div>
      <div className="license-page-cells">
        {isHeader ? header!.headerPlanPrice : "$" + getToTwoDecimals(tableItems!.totalPrice)}
      </div>
      <div className="license-page-cells">
        {!isHeader ? 
          <button
            className="get-license-plans-button"
            onClick={() => {
              getPlan!(tableItems!);
            }}
          >
            {t("My.License.Page.Headers.Get.Plan.Button")}
          </button>
          : null
        }
      </div>
    </div>
  );
};

export const MyLicensingPlanPageRow: React.FC<MyLicensingPlanPageRowProps> = ({
  ...props
}) => {
  const { tableItems, header, isHeader } = props;
  const { t } = useTranslation();
  return (
    <div
      className="license-page-rows"
      key={isHeader ? header!.headerId : tableItems!.id}
    >
      <div className="license-page-cells hidden-tabs">
        {isHeader ? header!.headerId : tableItems!.id}
      </div>
      <div className="license-page-cells">
        {isHeader ? header!.headerContentType : capitalizeFirstLetter(tableItems!.plan.licensingType)}
      </div>
      <div className="license-page-cells">
        {isHeader ? header!.headerPlanType : tableItems!.plan.type}
      </div>
      <div className="license-page-cells">
        {isHeader ? header!.headerAmount : tableItems!.amountLeft}
      </div>
      <div className="license-page-cells">
        {isHeader ? header!.expDate : getExpDate(tableItems!.expirationDate)}
      </div>
      <div className="license-page-cells">
        {isHeader
          ? header!.headerPeriod
          : t(getPlanPeriod(tableItems!.plan.period))}
          
      </div>
      <div className="license-page-cells">
        {isHeader ? header!.headerPlanPrice : "$" + getToTwoDecimals(tableItems!.plan.totalPrice)}
      </div>
    </div>
  );
};
