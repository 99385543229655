import { ImageList, ImageListItem, ListItem } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import './local-content.styles.scss'
import { LocalContentProps, useStyles } from './local-content.types';
import { StoreState } from '../../redux/root-reducer';
import { UserCoordinates } from '../../redux/user/user.types';
import { selectUserCoordinates } from '../../redux/user/user.selectors';
import { connect } from 'react-redux';
import axios from 'axios';
import { RapidApiCityDto } from '../../globals/locations/locations.types';
import { GET_LOCAL_CONTENT } from './queries';
import { useLazyQuery } from '@apollo/react-hooks';
import { LocalContent, LocalContentActionTypes } from '../../redux/local-content/local-content.types';
import { selectLocalContentData } from '../../redux/local-content/local-content.selectors';
import { ILoadedData, IResetLoadedContent, TLocalContentReducerActions } from '../../redux/local-content/local-content.actions';
import { Dispatch } from 'redux';
import { SmoothImageRender } from 'shared';
import { CallHistoryMethodAction, push } from 'connected-react-router';
import { SubChannel, Thread } from '../channelsSidebar/channelSideBars.types';
import { TPhotoDetailsActions } from '../../redux/photo-details/photo-details.actions';
import { PhotoDetailsActionTypes } from '../../redux/photo-details/photo-details.types';
import { ContentTypes } from '../../components/contents/content.types';
import { Channel } from '../../components/home-component/home.types';
import { selectCurrentChannel, selectCurrentSubChannel, selectCurrentThread } from '../../redux/channel-routes/channel.selectors';
import { LoadingSpinner } from "shared";
import {config} from "../../config";

const LocalContentComponent: React.FC<LocalContentProps> = ({ ...props }) => {
    const { userCoordinates, localContentData, selectedChannel, selectedSubchannel, selectedThread,
        redirectToPhotoDetals, setPhotoDetailsState, loadedDataAction, resetLocalContentAction } = props;
    const classes = useStyles();
    const [nearbyCities, setNearbyCities] = useState<RapidApiCityDto[]>([]);
    const [fetchLocalData, { data, loading, error }] = useLazyQuery(GET_LOCAL_CONTENT, {
        fetchPolicy: 'no-cache'
    });

    const returnNullId = (id: any, type: string) => {
        if (id > 0 && ((type === "channel" && selectedSubchannel.id === -1 && selectedThread.id === -1)
            || (type === "subchannel" && selectedThread.id === -1) || type === "thread")) {
            return id
        }
        else {
            return null
        }
    }

    useEffect(() => {
        if (nearbyCities.length > 0) {
            resetLocalContentAction();
            fetchLocalData({
                variables: {
                    nearbyCities: nearbyCities.map((city: RapidApiCityDto) => { return city.city }),
                    channelId: returnNullId(selectedChannel.id, "channel"),
                    subchannelId: returnNullId(selectedSubchannel.id, "subchannel"),
                    threadId: returnNullId(selectedThread.id, "thread"),
                }
            })
        }
    }, [nearbyCities, selectedChannel, selectedSubchannel, selectedThread]);

    useEffect(() => {
        if (data && data.getAllLocalContent) {
            loadedDataAction(data.getAllLocalContent);
        }
    }, [data]);


    useEffect(() => {
        if (userCoordinates && userCoordinates.latitude && userCoordinates.longitude) {
            axios({
                method: 'GET',
                url: `${config.REACT_APP_PUBLIC_SERVER_HOST}/nearbyCities/${userCoordinates.longitude}/${userCoordinates.latitude}`,
            })
                .then((res: any) => {
                    setNearbyCities(res.data);
                })
                .catch((error: any) => {
                    // console.log(error)
                })
        }
    }, []);

    const handleImageClick = (content: LocalContent) => {
        setPhotoDetailsState(content);
        const threadId = content.contentNavigations[0].thread.id;
        const contentType = content.articleId ? ContentTypes.ARTICLE : ContentTypes.CONTENT_SET;
        let path = content.content.id.toString() + '/thread/' + threadId;
        if (contentType === ContentTypes.ARTICLE) {
            path = `article/${content.articleId}/set/${content.contentSetId}/${content.content.id}/thread/${threadId}`;
        }
        else if (contentType === ContentTypes.CONTENT_SET) {
            path = `set/${content.contentSetId}/${content.content.id}/thread/${threadId}`;
        }
        redirectToPhotoDetals(path);
    }

    return (
        <div className='local-content-container'>
            <div className='local-content-heading'>Local content</div>
            
            <ImageList rowHeight={80} cols={1} classes={{ root: classes.gridListRoot }}>
                {localContentData.map((content: LocalContent) => (
                    <ImageListItem key={content.content.id} cols={1} classes={{ root: classes.gridListTileRoot }}>
                        <ListItem button className='list-item' aria-label="selectable-image">
                            <SmoothImageRender src={content.content.pathToFileCompressed}
                                onClick={() => handleImageClick(content)}
                                className="image-container" loading='auto' />
                            <div>{content.content.headline}</div>
                        </ListItem>
                    </ImageListItem>
                ))}
            </ImageList>
        </div>
    );
};

const mapStateToProps = (state: StoreState): { userCoordinates: UserCoordinates | null, localContentData: LocalContent[], 
    selectedChannel: Channel, selectedSubchannel: SubChannel, selectedThread: Thread } => {
    return {
        userCoordinates: selectUserCoordinates(state),
        localContentData: selectLocalContentData(state),
        selectedChannel: selectCurrentChannel(state),
        selectedSubchannel: selectCurrentSubChannel(state),
        selectedThread: selectCurrentThread(state)
    };
};

const mapDispatchToProps = (dispatch: Dispatch<CallHistoryMethodAction | TLocalContentReducerActions | TPhotoDetailsActions>) => {
    return {
        redirectToPhotoDetals: (path: string) => dispatch(push(`/content/${path}`)),
        setPhotoDetailsState: (item: any) => dispatch<TPhotoDetailsActions>({
            type: PhotoDetailsActionTypes.STORE_PHOTO_DETAILS,
            data: item
        }),
        loadedDataAction: (data: LocalContent[]) => {
            dispatch<ILoadedData>({ type: LocalContentActionTypes.LOADED_DATA, data: data })
        },
        resetLocalContentAction: () => dispatch<IResetLoadedContent>({ type: LocalContentActionTypes.RESET_LOADED_CONTENT})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocalContentComponent);


