import React, { useState, useEffect } from "react";
import { DescriptionProps, SocialAndPricingProps, ShareContainerProps, ItemTypeLicensing, EditorialRate, EditorialPriceBoxProps, SubscriptionBuyBoxProps, PriceBoxMessageNonLoggedProps } from "./photo-details.types";
import { GET_EDITORIAL_RATES } from "./queries";
import { useLazyQuery } from "@apollo/react-hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faTwitter, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import AddToCartButton from "./photo-details-add-to-cart-button.component";
import { flatMap, isPlainObject } from "lodash";
import { capitalizeFirstLetter } from "../photo-details/photo-details-helper-functions";
import { TextField } from "@material-ui/core";
import { props } from "lodash/fp";
import { getToTwoDecimals } from "../cart/cart-page-helperFunctions";
import {config} from "../../config";
import { downloadContent } from "../../components/utils/download";
import { StoreState } from "../../redux/root-reducer";
import { User } from "../../redux/user/user.types";
import { selectCurrentUser } from "../../redux/user/user.selectors";
import { connect, useDispatch, useSelector } from "react-redux";
import { IBroadcastMessage, SeveritySnackbarEnum } from "../../components/batch-upload/ContentUpload.types";
import { IBroadcastFetchError, TTableFiltersReducerActions } from "../../redux/table-filter/table-filter.actions";
import { TableFilterActions } from "../../redux/table-filter/table-filter.types";
import { Dispatch } from "redux";
import { selectFetchError } from "../../redux/table-filter/table-filter.selectors";
import { PhotoDetailsActionTypes } from "../../redux/photo-details/photo-details.types";
import { selectBroadcastMessage } from "../../redux/photo-details/photo-details.selector";
import { SnackbarComponent } from 'shared';
import { IClearBroadcastMessage, TPhotoDetailsActions } from "../../redux/photo-details/photo-details.actions";
import { applyRegexRules } from "../../components/utils/helpers";
import { regexRules } from "../../constants/regexData";

// DESCTIPTION BOX
export const Description: React.FC<DescriptionProps> = ({ ...props }) => {
  return (
    <div
      className="description-container"
      dangerouslySetInnerHTML={{ __html: props.description }}>
    </div>

  );
};

//PURCHASING CONTAINERS

export const PriceBoxMessageNonLogged: React.FC<PriceBoxMessageNonLoggedProps> = ({
  ...props
}) => {
  const { signIn } = props;
  const { t } = useTranslation();

  return (
    <div className="price-box">
      <span className="price">
        {t("Photo.Detail.Not.Logged.User.Pricing.Message.Part.One")} <span className="sign-in" onClick={signIn}>
          {t("Banner.Login.button").toLocaleLowerCase()}
        </span> {t("Photo.Detail.Not.Logged.User.Pricing.Message.Part.Two")}
      </span>
    </div>
  );
};

export const SocialAndPricing: React.FC<SocialAndPricingProps> = ({
  ...props
}) => {
  const { item, isPartOfPlan, isOwned, licensingPlan, currentUser, ownedSizes } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  
  const handleDownloadError = ()=>{
    dispatch({ type: PhotoDetailsActionTypes.BROADCAST_MESSAGE, data: { severity: SeveritySnackbarEnum.error, message: t("Error.Download.Content") } })
  }

  const handleContentDownload= ()=>{
    if(currentUser?.token){
      downloadContent({purchasedImages: [item?.content?.id], token: currentUser?.token, fileName: item?.content?.headline, onError: handleDownloadError})
    }
  }
  return (
    <span>
      {item.content.licensingType === ItemTypeLicensing.isEditorial ? (
        <EditorialPriceBox item={item} ownedSizes={ownedSizes} />
      ) : isOwned ? (
        <div className="owned-box-info">
          <p className="owned-text">
            {t("Photo.Details.Container.Already.Own.This.Item")}
          </p>
          <button onClick={()=>{
            if(currentUser?.token){
              downloadContent({purchasedImages: item.content.id,token: currentUser.token, fileName: item.content.code || "wi_image", onError: handleDownloadError})
            }
          }} className="download-button" type="submit">
          {t("Download.button")}
        </button>
        </div>
      ) : isPartOfPlan ? (
        <SubscriptionBuyBox item={item} licensingPlan={licensingPlan} />
      ) : (
        <div className="price-box">
          <span className="price">
            <div>
              {t("Photo.Details.Container.Price")}
              {getToTwoDecimals(item.flatRate)}
            </div>
          </span>
          {!isOwned ? (
            <AddToCartButton item={item} isPartOfPlan={isPartOfPlan} />
          ) : <button className="editorial-download-button" onClick={()=>{
            handleContentDownload()
          }}>Download</button>}
        </div>
      )}
    </span>
  );
};

export const SubscriptionBuyBox: React.FC<SubscriptionBuyBoxProps> = ({
  ...props
}) => {
  const { item, licensingPlan } = props;
  const { t } = useTranslation();
  if (item && licensingPlan) {
    item.flatRate = licensingPlan.plan.totalPrice / licensingPlan.plan.amount;
  }
  return (
    <div className="subscription-box">
      <div className="subscription-box-info">
        {t("Photo.Details.Containers.Part.Of.Subscription.Part.One") +
          licensingPlan?.amountLeft +
          t("Photo.Details.Containers.Part.Of.Subscription.Part.Two")}
      </div>
      <AddToCartButton item={item} isPartOfPlan={true} />
    </div>
  );
};

export const EditorialPriceBox: React.FC<EditorialPriceBoxProps> = ({
  ...props
}) => {
  const { item, ownedSizes } = props;
  const [editorialRates, setEditoralRates] = useState<EditorialRate[]>([]);
  const [
    fetchEditorialRates,
    { data, loading, error, refetch }
  ] = useLazyQuery(GET_EDITORIAL_RATES, { fetchPolicy: "no-cache" });
  const { t } = useTranslation();
  const [isbn, setIsbn] = useState("");
  const [issn, setIssn] = useState("");
  const [showBroadcastMessage, setShowBroadcastMessage] = useState(false);
  const broadcastMessage = useSelector((state: StoreState)=> state.photoDetails.broadcastMessage);
  const dispatch = useDispatch();
  const currentUser = useSelector((state: StoreState)=>state.user.currentUser)
  const handleDownloadError = ()=>{
    dispatch({ type: PhotoDetailsActionTypes.BROADCAST_MESSAGE, data: { severity: SeveritySnackbarEnum.error, message: t("Error.Download.Content") } })
  }

  const handleEditorialContentDownload= (size: string)=>{
    if(currentUser?.token){
      downloadContent({purchasedImages: [item?.content?.id], token: currentUser?.token, fileName: item?.content?.headline, onError: handleDownloadError, size})
    }
  }

  const handleCloseBroadcastMessage = ()=>{
    setShowBroadcastMessage(false);
    dispatch({ type: PhotoDetailsActionTypes.CLEAR_BROADCAST })
  }

  useEffect(() => {
      fetchEditorialRates();
  }, [fetchEditorialRates]);

  useEffect(() => {
    if (data && data.getEditorialRates) {
      setEditoralRates(data.getEditorialRates);
    }
  }, [data]);

  useEffect(() => {
    if (broadcastMessage?.severity && broadcastMessage?.message) {
      setShowBroadcastMessage(true);
      setTimeout(()=>{ 
        handleCloseBroadcastMessage()
      },1000)
    }
  }, [broadcastMessage]);

  // TODO: Uncomment when ISBN and ISSN are decided
  // const handleISBNISSN = (e: any) => {
  //   if (e.target.name === "issn") {
  //     setIssn(e.target.value);
  //   } else {
  //     setIsbn(e.target.value);
  //   }
  // };

  return (
    <div className="price-box-editorial">
      {item
        ? editorialRates.map((editorialRate, index) => {
          return (
            <div className="editorial-rate" key={index}>
              <span className="price">
                <div>
                  {capitalizeFirstLetter(editorialRate.size)} -{" "}
                  {t("Photo.Details.Container.Price")}
                  {getToTwoDecimals(editorialRate.rate)}
                </div>
              </span>
              {ownedSizes.includes(editorialRate.size) ? <button className="editorial-download-button" onClick={()=>{
                handleEditorialContentDownload(editorialRate.size)
              }}>Download</button> : <AddToCartButton
                item={{
                  content: {
                    id: item.content.id,
                    pathToFileCompressed: item.content.pathToFileCompressed,
                    pathToFileThumbnail: item.content.pathToFileThumbnail,
                    headline: item.content.headline,
                    code: item.content.code,
                    publishDate: item.content.publishDate,
                    caption: item.content.caption,
                    type: item.content.type,
                    licensingType: item.content.licensingType,
                    externalLink: item.content.externalLink,
                    externalLinkMessage: item.content.externalLinkMessage,
                    pathToFile: item.content.pathToFile,
                    flatRate: editorialRate.rate,
                    size: editorialRate.size
                  },
                  flatRate: editorialRate.rate,
                  isbn: isbn,
                  issn: issn
                }}
                size={editorialRate.size}
                isPartOfPlan={false}
              />}
            </div>
          );
        })
        : null}
      <SnackbarComponent showSnackbar={showBroadcastMessage} handleClose={handleCloseBroadcastMessage}
        severity={broadcastMessage?.severity}
        message={broadcastMessage?.message} />
      {/* TODO: Uncomment when the ISBN and ISSN logic is decided */}
      {/* <TextField onChange={handleISBNISSN} label="ISBN" name="isbn"></TextField>
      <TextField onChange={handleISBNISSN} label="ISSN" name="issn"></TextField> */}
    </div>
  );
};

export const checkEnumVal = (myEnum: any, valueToCheck: string): any => {
  let checkFlag: any;
  (Object.keys(myEnum) as (keyof typeof myEnum)[]).map((key: any) => {
    if (myEnum[key] === valueToCheck) {
      checkFlag = myEnum[key];
    }
  });
  return checkFlag;
};

// EXTERNAL SHARE

export const ShareContainer: React.FC<ShareContainerProps> = ({ ...props }) => {
  const shareToFacebook =
    config.REACT_APP_FACEBOOK_SOCIAL_MEDIA_SHARE + props.currentPath;
  const shareToTweeter =
    config.REACT_APP_TWEETER_SOCIAL_MEDIA_SHARE + props.currentPath;

  return (
    <div className="social-container">
      {/*
            Connect to feature then uncomment
            <button className="channel-info-button main-color-button add-to-set-button">Add to your own
                content +
            </button> */}

      <a target="_blank" rel="noopener noreferrer" href={shareToFacebook}>
        <button className="channel-info-button main-color-button icon-buttons">
          <FontAwesomeIcon className="fbColor" icon={faFacebookF} />
        </button>
      </a>

      <a target="_blank" rel="noopener noreferrer" href={shareToTweeter}>
        <button className="channel-info-button main-color-button icon-buttons">
          <FontAwesomeIcon className="twColor" icon={faTwitter} />
        </button>
      </a>

      {/*
        Removed instagram button as it has no working solution for the moment
      <button className="channel-info-button main-color-button icon-buttons ">
        <FontAwesomeIcon className="inColor " icon={faInstagram} />
      </button> */}

      <button
        onClick={() => props.copyToClipboard()}
        className="channel-info-button main-color-button icon-buttons share-button"
      >
        <FontAwesomeIcon icon={faCopy} />
      </button>
    </div>
  );
};

const mapStateToProps = (
  state: StoreState
): {
  currentUser: User;
} => {
  return {
    currentUser: selectCurrentUser(state),
  };
};

export default connect(mapStateToProps, null)(EditorialPriceBox);