import gql from 'graphql-tag';

export const FETCH_METADATA = gql`

    query fetchMetadataQuery($contentId: Int!, $threadIds: [Int!]!, $suggestThreadSubchannelId: Int, $suggestThreadTitle: String) {
        getContentById(contentId: $contentId) {
            objectName
            caption
            headline
            externalLink
            externalLinkMessage
        }
        fetchLocation(contentId: $contentId) {
            country{
                country
                countryCode
            }
            province{
                stateProvince
                stateCode
            }
            city{
                city
            }
            locationCoordinate {
                longitude
                latitude
            }
        }
        fetchLicensingType(contentId: $contentId)
        fetchKeywords(contentId: $contentId) {
            name
        }
        fetchCopyright(contentId: $contentId) {
            byLine
            credit
            copyrightNotice
            specialInstructions
            sourceUser {
                id
                company {
                    id
                    name
                }
            }
        }
        fetchFileSources(contentId: $contentId) {
            source
            createdDate
            createdTime
            uploadedDate
            originalFileName
        }
        fetchFileDetails(contentId: $contentId) {
            fileName
            fileSize
            fileType
            imageWidth
            imageHeight
            cameraModel
        }
        getFullContentNavigation(threadIds: $threadIds, suggestThreadSubchannelId: $suggestThreadSubchannelId, suggestThreadTitle: $suggestThreadTitle) {
            channel {
                id
                title
            }
            subChannel {
                id
                title
            }
            thread {
                id
                title
            }
        }
    }
`;