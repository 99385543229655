import { createSelector } from 'reselect';
import { StoreState } from 'redux/root-reducer';

const selectChannel = (state:StoreState) => state.channels;

export const selectCurrentChannel = createSelector(
    [selectChannel],
    (selectChannel) => selectChannel.selectedChannel
);

export const selectCurrentSubChannel = createSelector(
    [selectChannel],
    (selectChannel) => selectChannel.selectedSubChannel
);

export const selectCurrentThread = createSelector(
    [selectChannel],
    (selectChannel) => selectChannel.selectedThread
);

export const selectGalleryView = createSelector(
    [selectChannel],
    (selectChannel) => selectChannel.galleryViewEnabled
);