import { TaxForOrder } from "../cart/cart-page.types";
import { LicensePlan } from "../../components/photo-details/photo-details.types";
import { IBroadcastMessage } from "../../components/batch-upload/ContentUpload.types";

export enum SubscriptionActionTypes {
  ADD_SUBSCRIPTION = "ADD_SUBSCRIPTION",
  REMOVE_SUBSCRIPTION = "REMOVE_SUBSCRIPTION",
  EMPTY_SUBSCRIPTION = "EMPTY_SUBSCRIPTION_CART",
  ADD_TAX = "ADD_TAX",
  BROADCAST_SUBSCRIPTION_MESSAGE = "BROADCAST_SUBSCRIPTION_MESSAGE",
  CLEAR_BROADCAST = "CLEAR_BROADCAST"
}

export interface SubscriptionState {
  subscriptionPlan: LicensePlan;
  total:number;
  subTotal:number;
  taxForOrder: TaxForOrder | null;
  subscriptionBroadcast: IBroadcastMessage;
}
