import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { StoreState } from '../../redux/root-reducer';
import { selectToggleUserLocation, selectUserLocationPermission } from '../../redux/user/user.selectors';
import { IGetUserCurrentLocation, IToggleUserCurrentLocation, IUserCurrentLocationLoading, TUserReducerActions } from '../../redux/user/user.actions';
import { UserActionTypes, UserLocation } from '../../redux/user/user.types';
import { LocationTrackerTypes } from './local-content.types';

const LocationTracker: React.FC<LocationTrackerTypes> = ({...props}) => {
    const { toggleUserLocation, toggleUserLocationPopup, toggleGetUserCurrentLocationAction, toggleUserCurrentLocationLoading } = props;
   
    const userLocationOptions = {
        enableHighAccuracy: false,
        maximumAge: 0
      };

    const getUserLocationSuccess = (position: GeolocationPosition) => {
        toggleUserCurrentLocationLoading(false);
        toggleGetUserCurrentLocationAction({
            gavePermission: true,
            userCoordinates: {
                longitude: position.coords.longitude,
                latitude: position.coords.latitude
            }
        })
    }


    const id = navigator.geolocation.watchPosition(getUserLocationSuccess, undefined, userLocationOptions)
    useEffect(() => {
        if (toggleUserLocation) {
            toggleUserCurrentLocationLoading(true);
            navigator.geolocation.getCurrentPosition((position) => {
                toggleUserCurrentLocationLoading(false);
                toggleGetUserCurrentLocationAction({
                    gavePermission: true,
                    userCoordinates: {
                        longitude: position.coords.longitude,
                        latitude: position.coords.latitude
                    }
                })
                toggleUserLocationPopup(false);
            },undefined,userLocationOptions)
            navigator.geolocation.clearWatch(id);
            
        }
    }, [toggleUserLocation]);

    return (
        <div></div>
    )
}

const mapStateToProps = (state: StoreState): { toggleUserLocation: boolean } => {
    return {
        toggleUserLocation: selectToggleUserLocation(state)
    }
}

const mapDispatchToProps = (dispatch: Dispatch<TUserReducerActions>) => {
    return {
        toggleGetUserCurrentLocationAction: (data: UserLocation) => dispatch<IGetUserCurrentLocation>({
            type: UserActionTypes.GET_USER_CURRENT_LOCATION,
            data: data
        }),
        toggleUserLocationPopup: (data: boolean) => dispatch<IToggleUserCurrentLocation>({
            type: UserActionTypes.TOGGLE_USER_CURRENT_LOCATION,
            data: data
        }),
        toggleUserCurrentLocationLoading: (data: boolean) => dispatch<IUserCurrentLocationLoading>({
            type: UserActionTypes.TOGGLE_USER_CURRENT_LOCATION_LOADING,
            data: data
        }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationTracker);