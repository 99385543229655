import { ItemForCart } from "../photo-details/photo-details.types";
import React from "react";
import {
  CartTableHeadings,
  CartTableProps,
  SingleContentTypes
} from "./cart-page.types";
import { Typography } from '@material-ui/core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import {
  checkIfShowColumn,
  getAudioFileName, getToTwoDecimals
} from "./cart-page-helperFunctions";
import {capitalizeFirstLetter} from '../photo-details/photo-details-helper-functions'
import { CartPreview } from "./cart-step-one-preview.component";

export const CartTable: React.FC<CartTableProps> = ({ ...props }) => {
  const { cartItems, cartIsForSub, subtotal, handleRemove } = props;
  const { t } = useTranslation();

  const getItemHeadline = (item: ItemForCart) => {
    if (item.content.type === SingleContentTypes.Image) {
      return item.content.headline;
    } else if (item.content.type === SingleContentTypes.Audio && item.content.pathToFile) {
      return getAudioFileName(item.content.pathToFile);
    } 
  };
  return (
    <table className="cart-table-datatable">
      <thead>
        <tr>
          {Object.values(CartTableHeadings).map(heading => {
            return (
                checkIfShowColumn(heading, cartIsForSub)?<th key={heading} className="cart-table-cells">
                {t("CartTableHeading." + heading)}</th>:null
            );
          })}
        </tr>
      </thead>
      <tbody>
        {cartItems.length > 0 ?cartItems.map((item: ItemForCart, index) => { return(
          <tr key={index}>
            <td className="column-content">
              <CartPreview item={item} />
            </td>
            <td className="column-content">{getItemHeadline(item)}</td>
            <td className="column-content">
              {capitalizeFirstLetter(item.content?.type)}
            </td>
            <td className="column-content">
              {capitalizeFirstLetter(item.content?.licensingType)}
            </td>
            {!cartIsForSub ? <td className="column-content">${getToTwoDecimals(item.flatRate)}</td> : null}
            
            {!cartIsForSub ?<td className="column-content">{item.content.size?capitalizeFirstLetter(item.content.size):"N/A"}</td>: null}
            <td className="column-content">
              <FontAwesomeIcon
                onClick={() => {
                  handleRemove(item);
                }}
                className="remove-button"
                icon={faTimes}
              />
            </td>
          </tr>
        )})
        :
        <tr>
          <th colSpan={Object.values(CartTableHeadings).length}>
            <div className="cart-table-total">
              <Typography color="textPrimary" gutterBottom variant="h6">
                Your cart is empty
              </Typography>
            </div>
          </th>
        </tr>
      }
      </tbody>
      <tfoot>
        <tr>
          <th colSpan={Object.values(CartTableHeadings).length}>
            {cartIsForSub ? 
              <div className="cart-table-total-gray">
                Total credits: {cartItems.length}
              </div>  
              :
              <div className="cart-table-total-gray">
                Total price: ${subtotal}
              </div>
            }
          </th>
        </tr>
      </tfoot>
    </table>
  );
};
