import React, { useEffect, useState } from 'react';
import { Button, FormControlLabel, MuiThemeProvider, TextField, Tooltip } from '@material-ui/core';
import { ArticleComponentProps, AddArticleTheme, Article, IBroadcastMessage, SeveritySnackbarEnum, textfieldStyles } from './ContentUpload.types';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import SunEditor from 'suneditor-react';
import { StoreState } from '../../redux/root-reducer';
import { selectArticles, selectChangesToBeSaved } from '../../redux/content-upload/content-upload.selectors';
import { Dispatch } from 'redux';
import { TContentUploadActions, IUploadArticle, IUpdateArticle, IBroadcastContentUploadMessage, IDeleteArticle, IToggleChangesToBeSaved } from '../../redux/content-upload/content-upload.actions';
import { ContentUploadActionTypes } from '../../redux/content-upload/content-upload.types';
import { connect } from 'react-redux';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import './AddArticle.styles.scss';
import AddAudioInput from './AddAudioInput';
import { useTranslation } from "react-i18next";
import Edit from "@material-ui/icons/Edit";
import InputAdornment from "@material-ui/core/InputAdornment";

export const AddArticleComponent: React.FC<ArticleComponentProps> = ({ ...props }) => {
    const classes = textfieldStyles();
    const { contentSetId, changesToBeSaved, articles, uploadArticleAction, updateArticleAction,
        broadcastUploadErrorAction, deleteArticleAction, toggleChangesToBeSavedAction } = props;

    const [isArticleFound, setIsArticleFound] = useState(false);
    const [articleHeading, setArticleHeading] = useState('');
    const [articleText, setArticleText] = useState('');
    const [firstLoad, setFirstLoad] = useState(true);
    const [editMode, setEditMode] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        if (contentSetId) {
            const selectedArticle = articles.find(article => article.contentSetId === contentSetId);
            if (selectedArticle) {
                setIsArticleFound(true);
                setArticleText(selectedArticle.text);
                setArticleHeading(selectedArticle.title);
            } else {
                setIsArticleFound(false);
                setArticleText("");
                setArticleHeading("");
            }
        }
    }, [articles]);

    const handleFiles = async (files: FileList | null) => {
        if (files![0] && contentSetId && files![0].type === "text/plain") {
            await readFileContent(files![0]).then((content: any) => {
                let updatedArticle: Article = {
                    contentSetId: contentSetId,
                    title: files![0].name.substring(0, files![0].name.length - 4),
                    text: content
                }
                uploadArticleAction(updatedArticle);
                broadcastUploadErrorAction({
                    severity: SeveritySnackbarEnum.success,
                    message: t("Article.Snackbar.Upload.Success")
                });
            }).catch((error: any) => {
                broadcastUploadErrorAction({
                    severity: SeveritySnackbarEnum.success,
                    message: t("Article.Snackbar.Upload.Fail")
                });
            })
        } else {
            broadcastUploadErrorAction({
                severity: SeveritySnackbarEnum.error,
                message: t("Article.Snackbar.Wrong.Type")
            });
        }
    }

    const readFileContent = (file: File) => {
        const reader = new FileReader();

        return new Promise((resolve, reject) => {
            reader.onload = (event: any) => resolve(event.target.result);
            reader.onerror = (error: any) => reject(error);
            reader.readAsText(file);
        })
    }

    const handleDrop = (event: DragEvent) => {
        //drop article functionality???
        return true;
    }

    const handleChange = (event: any) => {
        event.preventDefault();
        const value = event.target.value;
        if (!changesToBeSaved) {
            toggleChangesToBeSavedAction(true);
        }

        setArticleHeading(value);
    };

    const handleContentOnChange = (text: string) => {
        if (!changesToBeSaved && !firstLoad) {
            toggleChangesToBeSavedAction(true);
        }

        setFirstLoad(false);
        setArticleText(text);
    }

    const handleOnInputTrigger = (event: any) => {
        event.preventDefault();
        if (!changesToBeSaved) {
            toggleChangesToBeSavedAction(true);
        }
    }

    const saveChanges = (contentSetId: string | undefined) => {
        if (articleHeading.length <= 1
            || articleHeading.length > 200
            || articleText.length <= 1) {
            broadcastUploadErrorAction({
                severity: SeveritySnackbarEnum.warning,
                message: t("Article.Snackbar.SaveChanges.Fail")
            });
            return;
        }

        if (contentSetId) {
            let hasArticle = articles.find(article => article.contentSetId === contentSetId);
            if (hasArticle) {
                updateArticleAction({ contentSetId, title: articleHeading, text: articleText });
                broadcastUploadErrorAction({
                    severity: SeveritySnackbarEnum.success,
                    message: t("Article.Snackbar.Update.Success")
                });
            } else {
                let newArticle: Article = {
                    contentSetId: contentSetId,
                    title: articleHeading,
                    text: articleText
                }
                uploadArticleAction(newArticle);
                broadcastUploadErrorAction({
                    severity: SeveritySnackbarEnum.success,
                    message: t("Article.Snackbar.Upload.Success")
                });
            }
            setEditMode(false);
            toggleChangesToBeSavedAction(false);
        }
    }

    const removeArticle = () => {
        const deletedArticle = articles.find(article => article.contentSetId === contentSetId);

        if (deletedArticle) {
            deleteArticleAction(deletedArticle.contentSetId);
            broadcastUploadErrorAction({
                severity: SeveritySnackbarEnum.success,
                message: t("Article.Snakbar.RemoveArticle.Success")
            });
        } else {
            broadcastUploadErrorAction({
                severity: SeveritySnackbarEnum.error,
                message: t("Article.Snackbar.Remove.Fail") + contentSetId
            });
        }
    }

    const isArticleChanged = () => {
        return changesToBeSaved
            && (articleHeading.length > 0 || articleText.length > 0);
    }

    const handleClick = () => {
        setEditMode(true);
    }

    return (
        <div className='add-article-container'>
            <MuiThemeProvider theme={AddArticleTheme}>
                <div className='article-header'>
                    <Tooltip title={articleHeading} disableFocusListener disableTouchListener>
                        <FormControlLabel aria-label='content-set-title'
                            onClick={(event) => event.stopPropagation()}
                            onFocus={(event) => event.stopPropagation()}
                            control={
                                <TextField
                                    autoComplete="off"
                                    name="textfield"
                                    value={articleHeading}
                                    margin="normal"
                                    placeholder="Set article name..."
                                    onChange={handleChange}
                                    inputProps={{ maxLength: 200 }}
                                    InputProps={
                                        {
                                            endAdornment: !editMode ? (
                                                <InputAdornment position="end"
                                                    classes={{ root: classes.inputAdornmentRoot }}
                                                    onClick={handleClick}>
                                                    <Edit style={{ fill: 'black', cursor: 'pointer' }} />
                                                </InputAdornment>) : null
                                            ,
                                            classes: {
                                                root: classes.inputBaseRoot,
                                                input: classes.inputBase,
                                                underline: classes.inputUnderline,
                                                formControl: classes.formControl
                                            }
                                        }}
                                />
                            } label='' />
                    </Tooltip>
                    {isArticleChanged() ?
                        <Button size='small' className='save-button'
                            onClick={() => saveChanges(contentSetId)} variant='contained'
                            component='span' endIcon={<SaveIcon />}>
                            {t("Article.Save.Changes")}
                        </Button>
                        : null}
                </div>


                <div className="text-field-container">
                    <SunEditor lang="en" name="sunEditor" setContents={articleText} placeholder={t("Article.SunEditor.Placeholder")}
                        setOptions={{
                            customPlugins: [], buttonList: [['bold', 'underline', 'italic',
                                'strike', 'list']]
                        }} onInput={handleOnInputTrigger} onDrop={handleDrop}
                        onChange={handleContentOnChange} />
                </div>

                <div className='button-group-article'>
                    {articles.find(article => article.contentSetId === contentSetId) ?
                        <Button size='small' className='white-button' onClick={removeArticle} variant='contained' component='span'
                            endIcon={<DeleteIcon />}>
                            {t("Article.Remove.Button")}
                        </Button>
                        :
                        <React.Fragment>
                            <input accept=".txt" className='upload-input' id="contained-button-file" multiple={false} type="file"
                                onChange={(e) => handleFiles(e.target.files)} />
                            <label htmlFor="contained-button-file">
                                <Button size='small' className='white-button' variant='contained' component='span'
                                    endIcon={<CloudUploadIcon />}>
                                    {t("Article.UploadTXT.Button")}
                                </Button>
                            </label>
                        </React.Fragment>
                    }
                    {isArticleFound ?
                        <AddAudioInput articleId={contentSetId!} />
                        : null}
                </div>
            </MuiThemeProvider>
        </div>
    )
}

const mapStateToProps = (state: StoreState): { articles: Article[], changesToBeSaved: boolean } => {
    return {
        articles: selectArticles(state),
        changesToBeSaved: selectChangesToBeSaved(state)
    }
}

const mapDispatchToProps = (dispatch: Dispatch<TContentUploadActions>) => {
    return {
        uploadArticleAction: (data: Article) => dispatch<IUploadArticle>({ type: ContentUploadActionTypes.UPLOAD_ARTICLE, data: data }),
        updateArticleAction: (data: Article) => dispatch<IUpdateArticle>({ type: ContentUploadActionTypes.UPDATE_ARTICLE, data: data }),
        broadcastUploadErrorAction: (data: IBroadcastMessage) => dispatch<IBroadcastContentUploadMessage>({
            type: ContentUploadActionTypes.BROADCAST_MESSAGE, data: data
        }),
        deleteArticleAction: (data: string) => dispatch<IDeleteArticle>({ type: ContentUploadActionTypes.DELETE_ARTICLE, data: data }),
        toggleChangesToBeSavedAction: (data: boolean) => dispatch<IToggleChangesToBeSaved>({ type: ContentUploadActionTypes.TOGGLE_SAVED_CHANGES, data: data })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddArticleComponent);