import gql from 'graphql-tag';

export const SINGLE_UPLOAD = gql`
  mutation singleUploadMutation($file: Upload!, $userId: String!) {
    singleUpload(file: $file, userId: $userId) {
      id
      filename
      mimetype
      encoding
      key
      url
      code
    }
  }
`;

export const DELETE_CONTENT = gql`
  mutation deleteContentMutation($id: Int!) {
    deleteContent(id: $id)
  }
`;

export const UPLOAD_SET = gql`
  mutation uploadSet($userId: String!, $contentIds: [Int!]!, $setName: String!, $coverId: Int!, $selectedContentUploadMethod: String) {
    uploadSet(userId: $userId, contentIds: $contentIds, setName: $setName, coverId: $coverId, selectedContentUploadMethod: $selectedContentUploadMethod ) {
      contentSetId
      contentIds
    }
  }
`;


export const ADD_ARTICLE = gql`
  mutation addArticle($contentSetId: Int!, $text: String!, $title: String!, $audioId: Int) {
    addArticle(contentSetId: $contentSetId, text: $text, title: $title, audioId: $audioId)
  }
`;

export const UPDATE_KEYWORDS = gql`
    mutation updateKeywords($contentId: Int!, $keywordsToBeAdded: [String!]!) {
        updateKeywords(contentId: $contentId, keywordsToBeAdded: $keywordsToBeAdded) {
            keywordId
            name
        }
    }
`;

export const DELETE_KEYWORDS = gql`
    mutation deleteKeywords($contentId: Int!, $keywordsToBeDeleted: [String!]!) {
      deleteKeywords(contentId: $contentId, keywordsToBeDeleted: $keywordsToBeDeleted)
    }
`;

export const UPDATE_KEYWORDSTOSET = gql`
    mutation updateKeywordsToSet($contentIds: [Int!]!, $keywordsToBeAdded: [String!]!, $keywordsToBeDeleted: [String!]!) {
        updateKeywordsToSet(contentIds: $contentIds, input: {keywordsToBeAdded: $keywordsToBeAdded, keywordsToBeDeleted: $keywordsToBeDeleted }) 
    }
`;

export const UPDATE_COPYRIGHT = gql`
    mutation updateCopyright($contentId: Int!, $byLine: String!, $credit: String!, $copyrightNotice: String!, $specialInstructions: String!, $sourceUserId: Int) {
        updateCopyright(contentId: $contentId, input: { byLine: $byLine, credit: $credit, copyrightNotice: $copyrightNotice, specialInstructions: $specialInstructions, sourceUserId: $sourceUserId}) {
            copyrightId
            byLine
            credit
            copyrightNotice
            specialInstructions
        }
    }
`;

export const UPDATE_COPYRIGHTS = gql`
    mutation updateCopyrights($contentIds: [Int!]!, $byLine: String!, $credit: String!, $copyrightNotice: String!, $specialInstructions: String!, $sourceUserId: Int) {
      updateCopyrights(contentIds: $contentIds, input: { byLine: $byLine, credit: $credit, copyrightNotice: $copyrightNotice, specialInstructions: $specialInstructions, sourceUserId: $sourceUserId})
    }
`

export const UPDATE_FILE_DETAILS = gql`
    mutation updateFileDetails($contentId: Int!, $fileName: String!, $fileSize: String!, $fileType: String!, $imageWidth: Float!, $imageHeight: Float!, $cameraModel: String!) {
        updateFileDetails(contentId: $contentId, input: { fileName: $fileName, fileSize: $fileSize, fileType: $fileType, imageWidth: $imageWidth, imageHeight: $imageHeight, cameraModel: $cameraModel}) {
            fileDetailId
            fileName
            fileSize
            fileType
            imageWidth
            imageHeight
            cameraModel
        }
    }
`;

export const UPDATE_FILE_SOURCE = gql`
    mutation updateFileSource($contentId: Int!, $source: String!, $createdDate: DateTime!, $createdTime: DateTime!, $uploadedDate: DateTime!, $originalFileName: String!) {
        updateFileSource(contentId: $contentId, input: { source: $source, createdDate: $createdDate, createdTime: $createdTime, uploadedDate: $uploadedDate, originalFileName: $originalFileName}) {
            fileSourceId
            source
            createdDate
            createdTime
            uploadedDate
            originalFileName
        }
    }
`;

export const UPDATE_LOCATION = gql`
    mutation updateLocation($contentId: Int!, $country: String!, $stateProvince: String!, $city: String!, $countryCode: String!, $stateCode: String!, $longitude: Float!, $latitude: Float!) {
        updateLocation(contentId: $contentId, input: { country: $country, stateProvince: $stateProvince, city: $city, countryCode: $countryCode, stateCode: $stateCode, longitude: $longitude, latitude: $latitude}) {
          country{
            country
            countryCode
          }
          province{
            stateProvince
            stateCode
          }
          city{
            city
          }
          locationCoordinate {
            longitude
            latitude
          }
        }
    }
`;

export const ASSIGN_CONTENT = gql`
  mutation assignContentMutation($threadIds: [Int!]!, $contentId: Int!, $subchannelId: Int, $suggestedThreadTitle: String, $userId: Int) {
    assignContent(threadIds: $threadIds, contentId: $contentId, subchannelId: $subchannelId, suggestedThreadTitle: $suggestedThreadTitle, userId: $userId)
  }
`

export const ASSIGN_CONTENT_SET = gql`
  mutation assignContentSet($threadIds: [Int!]!, $contentSetId: Int!, $subchannelId: Int, $suggestedThreadTitle: String, $userId: Int) {
    assignContentSet(threadIds: $threadIds, contentSetId: $contentSetId, subchannelId: $subchannelId, suggestedThreadTitle: $suggestedThreadTitle, userId: $userId)
  }
`

export const REQUEST_SFTP_ACCESS = gql`
  mutation requestSftpAccess($id: Int!) {
    requestSftpAccess(id: $id)
  }
`;

export const SFTP_INFO = gql`
query sftpInfo {
  sftpInfo{
    sftpAccessRequest
		hasSFTPAccess
    hasPublicSFTPAccess
  }
}
`;

export const GET_SFTP_FOLDERS = gql`
  query getSetFolderNames($sftpFolder: String!) {
    getSetFolderNames(sftpFolder: $sftpFolder)
  }
`;

export const UPLOAD_SFTP_SET_FOLDER = gql`
  mutation uploadSFTPSetFolder($sftpSetFolder: String!, $userId: Int!) {
    uploadSFTPSetFolder(sftpSetFolder: $sftpSetFolder, userId: $userId) {
        key
        value {
          id
          filename
          mimetype
          code
          url
        }
    }
  }
`;

export const CLEAN_SFTP_TEMP_FOLDER = gql`
  mutation cleanTempFolder($id: Int!) {
    cleanTempFolder(id: $id)
  }
`;

export const CONTRIBUTOR_AGENCY = gql`
    query getContributorAgenciesQuery {
      getContributorAgencies {
            id
            email
            authStrategy
            role
            person {
              id
              firstName
              lastName
            }
            company {
              id
              name
            }
        }
    }
`;

export const UPDATE_LICENSE_TYPE = gql`
    mutation updateLicensingTypeMutation($id: Int!, $licensingType: String!) {
      updateLicensingType(id: $id, licensingType: $licensingType)
    }
`;

export const UPDATE_LICENSE_TYPES = gql`
    mutation updateLicensingTypesMutation($contentIdArray: [Int!]!, $licensingType: String!) {
      updateLicensingTypes(contentIdArray: $contentIdArray, licensingType: $licensingType)
    }
`;


export const SAVE_USER_INCOMPLETE_FLOW = gql`
  mutation saveUserIncompleteUploadFlow($userId: Int!, $flowTitle: String!, $contentIds: [Int!]!, $contentSetIds: [Int!]) {
    saveUserIncompleteUploadFlow(userId: $userId, flowTitle: $flowTitle, contentIds: $contentIds, contentSetIds: $contentSetIds)
  }
`;

export const SET_MULTIPLE_CONTENTS_HASH = gql`
  mutation setMultipleContentHash($contentIds: [Int!]!) {
    setMultipleContentsHash(contentIds: $contentIds)
  }
`;

export const UPDATE_MAIN_INFORMATION = gql`
  mutation updateMainInformation($contentId: Int!, $objectName: String!, $headline: String!, $caption: String!, $externalLink: String!, $externalLinkMessage: String!) {
    updateMainInformation(contentId: $contentId, input: {objectName: $objectName, headline: $headline, caption: $caption, externalLink: $externalLink, externalLinkMessage: $externalLinkMessage}){
      id,
      objectName,
      headline,
      caption,
      externalLink,
      externalLinkMessage
    }
  }
`

export const UPDATE_MAIN_INFORMATION_SET = gql`
  mutation updateMainInformationToSet($contentIds: [Int!]!, $objectName: String!, $headline: String!, $caption: String!, $externalLink: String!, $externalLinkMessage: String!){
    updateMainInformationToSet(contentIds: $contentIds, input: {objectName: $objectName, headline: $headline, caption: $caption, externalLink: $externalLink, externalLinkMessage: $externalLinkMessage})
  }
`

export const UPDATE_LOCATIONS = gql`
  mutation updateLocations($contentIds: [Int!]!, $country: String!, $stateProvince: String!, $city: String!, $countryCode: String!, $stateCode: String!, $longitude: Float!, $latitude: Float!) {
    updateLocations(contentIds: $contentIds, input: { country: $country, stateProvince: $stateProvince, city: $city, countryCode: $countryCode, stateCode: $stateCode, longitude: $longitude, latitude: $latitude})
  }
`

export const GET_ALL_THREADS = gql `
  query getAllThreadsBySubchannelId($subchannelId: Int!) {
    getThreadsBySubchannelId(subchannelId: $subchannelId) {
      title
      id
      status
    }
  }
`