import React, { useEffect, useState } from "react";
import {GET_MOSTFOLLOWED} from './queries'
import { TrendingProps,useStyles } from './trendingContent.types';
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import './trendingContent.styles.scss'
import { connect } from "react-redux";
import { ChannelActionTypes } from "../../redux/channel-routes/channel.types";
import {Dispatch} from "redux";
import { IAddSubChannel, TChannelReducerActions, IAddThread, IAddChannel } from "../../redux/channel-routes/channel.actions";
import { CallHistoryMethodAction, push } from "connected-react-router";
import { composePathThreads } from "../channelsSidebar/helperFunctions";
import './trendingContent.styles.scss'
import { Channel } from "../home-component/home.types";
import {Popover, Typography } from "@material-ui/core";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import { GET_CONTENT_NAVIGATION_THREAD_BY_ID } from "../../components/photo-details/queries";
import { Thread } from "../../components/channelsSidebar/channelSideBars.types";

const TrendingContentComponent: React.FC<TrendingProps> = ({...props}) => {
    const [trendingContent, setTrendingContent ] = useState<Thread[]>([]);
    const threads = useQuery(GET_MOSTFOLLOWED);
    const [dataQuery,{data,loading,error}] = useLazyQuery(GET_CONTENT_NAVIGATION_THREAD_BY_ID);
    const [contentNavigation, setContentNavigation] = useState([]);
    const { selectChannelAction, selectSubChannelAction, selectThreadAction, redirectToThread, colors } = props;
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined
    const [dimensions,setDimensions] = useState({
      height:window.innerHeight,
      width:window.innerWidth
    })
    useEffect((): any=>{
      const handleResize=()=>{
        setDimensions({
          height:window.innerHeight,
          width:window.innerWidth
        })
      }
      window.addEventListener('resize',handleResize)

      return (event:any) =>{
        window.removeEventListener('resize',handleResize)
      }
    })

    useEffect(() => {
        if(threads.data && threads.data.mostFollowedThreads) {
          setTrendingContent(threads.data.mostFollowedThreads);
        }
    }, [threads.data]);

    useEffect(() => {
      if(data && data.getContentNavigationByThreadId){
        setContentNavigation(data.getContentNavigationByThreadId);
        const res = data.getContentNavigationByThreadId[0];
        selectChannelAction({id: res.channel.id, title: res.channel.title, code: res.channel.code});
        selectSubChannelAction({id: res.subChannel.id, title: res.subChannel.title, code: res.channel.code});
        selectThreadAction({id: res.thread.id, title: res.thread.title, code: res.channel.code});
        redirectToThread(res.channel.title, res.subChannel.title, res.thread.title);
      }
    },[data]);

    const handleOnclick = (threadId: number) => {
      const threadIds = [threadId]
      dataQuery({variables: {threadIds}})
    }
    const handleOpenPopover=(event:any)=>{
      setAnchorEl(event.currentTarget)
    }

    const handleClosePopover = ()=>{
      setAnchorEl(null)
    }
    
    return (
      <div className='trending-container'>
          <span className="trendingTitle">Trending now</span>
            <button
              onClick={handleOpenPopover}
              color='inherit'
              ari-label='open popover'
              className = {window.innerWidth > 560 ? 'hide' : 'show'}
              >
              <ArrowDropDown/>
            </button>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClosePopover}
              anchorOrigin={{
                vertical:'bottom',
                horizontal:"center"
              }}
              transformOrigin={{
                vertical:'top',
                horizontal:'center'
              }}>
                <Typography classes = {{root:classes.typography}}>
                    {trendingContent.map((trending,index) => {
                      return <span key={index} className="trendingItem" onClick={() => {handleOnclick(trending.id); setAnchorEl(null)}}>{trending.title}</span>
                  })}
                </Typography>

            </Popover>
              {trendingContent.map((trending,index) => {
                  return <span key={index} style={colors && {backgroundColor: `${colors[1]}`, padding: '0.5rem', borderRadius: '20px'}} className="trendingSpan" onClick={() => handleOnclick(trending.id)}>{trending.title}</span>
              })}
      </div>
    );
};

const mapDispatchToProps = (dispatch: Dispatch< CallHistoryMethodAction | TChannelReducerActions>) => {
  return {
    selectChannelAction: (channel: Channel) => dispatch<IAddChannel>({
        type: ChannelActionTypes.ADD_SELECTED_CHANNEL,
        data: channel
    }),
    selectSubChannelAction: (subChannel: Channel) => dispatch<IAddSubChannel>({
      type: ChannelActionTypes.ADD_SELECTED_SUBCHANNEL,
      data: subChannel
    }),
    selectThreadAction: (thread: Channel) => dispatch<IAddThread>({
      type: ChannelActionTypes.ADD_SELECTED_THREAD,
      data: thread
    }),
    redirectToThread: (channel:string, subChannel: string, thread: string) => dispatch(push(`/channels/${composePathThreads(channel, subChannel, thread)}`)),

  }
}


export default connect(null, mapDispatchToProps)(TrendingContentComponent);
