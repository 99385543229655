import { ContentFromOptions, ContentTypesForSearch } from "../search-bar/searchBar.types";
import { Channel } from "../home-component/home.types";
import { SubChannel, Thread } from "../channelsSidebar/channelSideBars.types";
import { LocationFilterProps, LocationNearMeFilterProps } from "../../redux/search-bar/search-bar.types";
import { UserUploadedContent } from "../contributor-content/contributor-content.types";
import { User, UserCoordinates, UserLocation } from "../../redux/user/user.types";
import { IBroadcastMessage } from "components/batch-upload/ContentUpload.types";
import { ItemForCart } from "../../components/photo-details/photo-details.types";

export interface ContentDisplayProps {
    searchString: string;
    selectedChannel: Channel;
    selectedThread: SubChannel;
    selectedSubChannel: Thread;
    contentFrom: number | ContentFromOptions; 
    contentType: ContentTypesForSearch;
    dateFilter: Date[];
    colourFilter: any;
    locationFilter: LocationFilterProps; 
    locationNearMeFilter: LocationNearMeFilterProps; 
    channelFilter: Channel; 
    subchannelFilter: SubChannel; 
    threadFilter: Thread;
    searchBarExpanded: boolean;
    userLocationPermission: boolean;
    userCurrentLocationLoading: boolean;
    userCoordinates: UserCoordinates | null;
    searchBroadcastMessage: IBroadcastMessage;
    addToCartBroadcastMessage: IBroadcastMessage;
    contentPerPage: number;
    currentUser?: User;
    resetFiltersAction: () => void;
    broadcastSearchMessage: (data: IBroadcastMessage) => void;
    toggleUserLocationPopup: (data: boolean) => void;
    toggleLoadingSearchResults: (data: boolean) => void;
    toggleUserCurrentLocationLoading: (data: boolean) => void;
    loadTotalResultsForChannelContexAction: (data: number) => void;
    toggleChannelFilterAction: (data: Channel) => void;
    toggleSubchannelFilterAction: (data: SubChannel) => void;
    toggleThreadFilterAction: (data: Thread) => void;
    toggleLocationsNearMeFilter: (data: LocationNearMeFilterProps) => void;
    toggleContentPerPage: (data: number) => void;
    clearBroadcast: () => void;
    clearAddToCartBroadcast: () => void;
}

export interface ContentListProps {
    loadingSearch: boolean;
    tileData: UserUploadedContent[];
    contentPerPage: number;
}
export interface ContentItemProps {
    item: UserUploadedContent;
    isInSearch: boolean;
    channelFilter: Channel;
    subchannelFilter: SubChannel;
    threadFilter: Thread; 
    path: string;
    selectedChannel: Channel;
    selectedThread: SubChannel;
    selectedSubChannel: Thread;
    currentUser: User;
    currentCartItems: ItemForCart[];
    cartItemsFromSub: ItemForCart[];
    redirectToPhotoDetals: (path: string) => void;
    setPhotoDetailsState: (item: any) => void;
    redirectToSubChannel: (channel: string, subchannel: string) => void;
    redirectToThread: (channel: string, subchannel: string, thread: string) => void;
    redirectToChannel: (channel: string) => void;
    selectChannelAction: (channel: Channel) => void;
    selectSubChannelAction: (subChannel: SubChannel) => void;
    selectThreadAction: (thread: Thread) => void;
    addToSubscriptionCartAction: (data: ItemForCart) => void;
    broadCastMessage: (data: IBroadcastMessage) => void;
    addToCartAction: ( item: ItemForCart ) => void;
    clearBroadcast: ( ) => void;
}

export enum ContentTypes {
    CONTENT_SET = 'Content set',
    ARTICLE = 'Article',
    CONTENT = 'Content',
    AUDIO = 'Audio',
    IMAGE = 'image',
}