interface Config {
    REACT_APP_PUBLIC_BASE?: string;
    REACT_APP_PUBLIC_SERVER_HOST?: string;
    REACT_APP_FACEBOOK_SOCIAL_MEDIA_SHARE?: string;
    REACT_APP_TWEETER_SOCIAL_MEDIA_SHARE?: string;
    REACT_APP_STRIPE_LOAD_KEY?: string;
    REACT_APP_CLOUDFRONT_PATH?: string;
    SKIP_PREFLIGHT_CHECK?: boolean;
}

export const config: Config = {
    REACT_APP_PUBLIC_BASE:"",
    REACT_APP_PUBLIC_SERVER_HOST:"",
    REACT_APP_FACEBOOK_SOCIAL_MEDIA_SHARE: "",
    REACT_APP_TWEETER_SOCIAL_MEDIA_SHARE: "",
    REACT_APP_STRIPE_LOAD_KEY: "",
    REACT_APP_CLOUDFRONT_PATH: "",
    SKIP_PREFLIGHT_CHECK: false,
};

if(process.env.REACT_APP_ENV_MODE == "development"){
    config.REACT_APP_PUBLIC_BASE="http://localhost:8080";
    config.REACT_APP_PUBLIC_SERVER_HOST="http://localhost:3000/public";
    config.REACT_APP_FACEBOOK_SOCIAL_MEDIA_SHARE="https://www.facebook.com/sharer/sharer.php?u=";
    config.REACT_APP_TWEETER_SOCIAL_MEDIA_SHARE="https://twitter.com/intent/tweet?url=";
    config.REACT_APP_STRIPE_LOAD_KEY="pk_test_51L8ef6EPqrR8iGbOAMSIqEU4P64ERpcQGDgYjSnrHQHjeUsHsG8tPieB8Zkaq23PnFdu6v5Vypza46hiqY5DsKVQ00mKo1ZpRs";
    config.REACT_APP_CLOUDFRONT_PATH="https://d39z20ibtp0e4s.cloudfront.net";
    config.SKIP_PREFLIGHT_CHECK=true;
}
if(process.env.REACT_APP_ENV_MODE == "stagging"){
    config.REACT_APP_PUBLIC_BASE="https://public.worldillustrated.com";
    config.REACT_APP_PUBLIC_SERVER_HOST="https://api.worldillustrated.com/public";
    config.REACT_APP_FACEBOOK_SOCIAL_MEDIA_SHARE="https://www.facebook.com/sharer/sharer.php?u=";
    config.REACT_APP_TWEETER_SOCIAL_MEDIA_SHARE="https://twitter.com/intent/tweet?url=";
    // config.REACT_APP_STRIPE_LOAD_KEY="pk_test_51L8ef6EPqrR8iGbOAMSIqEU4P64ERpcQGDgYjSnrHQHjeUsHsG8tPieB8Zkaq23PnFdu6v5Vypza46hiqY5DsKVQ00mKo1ZpRs";
    config.REACT_APP_STRIPE_LOAD_KEY="pk_live_51Ja0dIBhvrgoOW8T63WFNyW9PduEfKwNm17KfLbcxefnhnhLOdvPHESK19fHVsR39ncnYi3FDx9aNeYnBNQWUw8T00uSfEKO4c";
    config.REACT_APP_CLOUDFRONT_PATH="https://d38hhp6342myl9.cloudfront.net";
    config.SKIP_PREFLIGHT_CHECK=true;
}