import React from 'react';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';
import { History } from "history";
import HomeComponent from './components/home-component/home';
import OnboardingPage  from './components/onboarding/onboarding-page.component'
import ChangePassword from './components/forgot-password/changePasswordComponent';
import ContentUploadPage from './components/batch-upload/ContentUploadPage';
import { selectCurrentUser, selectHasUserCompletedOnboarding } from './redux/user/user.selectors';
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
} from "@apollo/client";
import { createUploadLink } from 'apollo-upload-client'
import { connect } from 'react-redux';
import { User } from './redux/user/user.types';
import Navbar from "./components/navigation/Navbar";
import ChannelsComponent from './components/channels/ChannelCompopnent'
import { StoreState } from './redux/root-reducer';
import PrivateRoute from './components/privateRouter/privateRouter'
import './App.scss';
import ChannelContext from "./components/channels/ChannelContext";
import { ConnectedRouter } from 'connected-react-router';
import PhotoDetailsPage from './components/photo-details/photo-details-page.component'
import { selectCurrentChannel } from "./redux/channel-routes/channel.selectors";
import UploadedContent from "./components/contributor-content/uploadedContent";
import SearchBarResults from './components/search-bar/searchBarResults.component';
import CartPage from "./components/cart/cart-page.component";
import { Channel, TileData } from './components/home-component/home.types';
import { selectCurrentlySelectedPhoto, selectPhotoDetails } from './redux/photo-details/photo-details.selector';
import ErrorPageComponent from './components/error-page/errorPage.component';
import { selectSearchBarExpanded } from './redux/search-bar/search-bar.selectors';
import SearchBarExpanded from './components/search-bar/searchBarExpanded.component';
import { Collapse, Paper } from '@material-ui/core';
import { decomposeSearchQueryUrl } from './components/search-bar/searchBar.types';
import MyLicensePage from './components/my-license-page/my-license-page';
import MyDownloadsPage from './components/my-downloads-page/myDownloads';
import GetLicensePaymentPage from './components/my-license-page/get-license-payment-page';
import  LocationTracker from './components/local-content/location-tracker.component';
import { AlertComponent } from 'shared';
import CookieConsentComponent from "./components/cookies/cookie-consent.component";
import { selectCurrentPathname } from './redux/router/router.selectors';
import {config} from "./config";
import LightBox from './components/LightBox/lightBox';
import LightBoxContents from './components/LightBox/lightBoxContentsPage';
import MySales from './components/my-sales-page/my-sales';
import TermsConditions from './components/TermsCondition/termsAndConditions';
import PrivacyPolicy from './components/PrivacyPolicy/privacyPolicy';
import Contact from './components/contact/contact';

//import { useCookies } from 'react-cookie';

const client = new ApolloClient({
    cache: new InMemoryCache(),
    //@ts-ignore
    link: createUploadLink({
        uri: `${config.REACT_APP_PUBLIC_SERVER_HOST}/graphql`,
        headers: {
            "keep-alive": "true"
        }
    }),
});


interface AppProps {
    currentUser: User;
    userHasCompletedOnboarding: boolean;
    history: History;
    selectedChannel: string;
    item: TileData;
    selectedItemId: number;
    searchBarExpanded: boolean;
    currentPathname: string;
}

function renderChannelContextPage(routeProps: any) {
    return <React.Fragment>
        <ChannelContext routeParams={routeProps.match.params} />
    </React.Fragment>;
}

function renderContentDetailsPage(routeProps: any) {
    return <React.Fragment>
        <PhotoDetailsPage routeParams={routeProps.match.params} />
    </React.Fragment>;
}

function renderExpandedSearchOrChannels(searchBarExpanded: boolean) {
    return searchBarExpanded ?
        <Collapse in={searchBarExpanded} timeout={1000}>
            <Paper elevation={4} style={{ height: '100%', paddingTop: '1%' }}>
                <SearchBarExpanded/>
            </Paper>
        </Collapse>
        :
        <ChannelsComponent />;
}

const App = (props: AppProps) => {
    const { history, searchBarExpanded } = props;
    //const [cookies, setCookie] = useCookies(['sessionId']);
    const currentPath = window.location.pathname;
    return (
        <ApolloProvider client={client}>
            <div className="App">
                <ConnectedRouter history={history}>
                    <AlertComponent currentUser={props.currentUser} userHasCompletedOnboarding={props.userHasCompletedOnboarding} path={props.currentPathname} />
                    <Navbar/>
                    <LocationTracker/>
                    <Switch>
                        <Route path={'/channels/:channelName/:subchannelName?/:threadName?'}
                            render={(routeProps: RouteComponentProps) => renderChannelContextPage({ ...routeProps })}
                            exact={true}
                        />
                        <Route path={'/content/:contentType/:contentArticleId/set/:contentSetId/:contentId/thread/:threadId'}
                            render={(routeProps: RouteComponentProps) =>
                                renderContentDetailsPage({ ...routeProps })} exact={true}
                        />
                        <Route path={'/content/:contentType/:contentSetId/:contentId/thread/:threadId'}
                            render={(routeProps: RouteComponentProps) =>
                                renderContentDetailsPage({ ...routeProps })} exact={true}
                        />
                        <Route path={'/content/:contentId/thread/:threadId'} render={(routeProps: RouteComponentProps) =>
                            renderContentDetailsPage({ ...routeProps })} exact={true}
                        />
                        <PrivateRoute path='/my-license-page' component={MyLicensePage} exact={false} />
                        <PrivateRoute path='/my-downloads' component={MyDownloadsPage} exact={false} />
                        <PrivateRoute path='/get-license-plan-page' component={GetLicensePaymentPage} exact={false} />
                        <Route path='/change-password' component={ChangePassword} />
                        <Route exact path="/" render={() => (
                            <React.Fragment>
                                {renderExpandedSearchOrChannels(searchBarExpanded)}
                                <HomeComponent currentUser={props.currentUser} />
                            </React.Fragment>
                        )}
                        />
                        <PrivateRoute path='/content-upload' component={ContentUploadPage} exact={false} />
                        <PrivateRoute path='/onboarding' component={OnboardingPage} exact={false} />
                        <PrivateRoute path='/my-content' component={UploadedContent} exact={false} />
                        <PrivateRoute path='/light-box' component={LightBox} exact={true} />
                        <Route path='/termsconditions' component={TermsConditions} exact={false} />
                        <Route path='/privacypolicy' component={PrivacyPolicy} exact={false} />
                        <Route path='/contact' component={Contact} exact={false} />
                        <Route path='/light-box/:lightBoxId' component={LightBoxContents} exact={true} />
                        <PrivateRoute path='/my-sales' component={MySales} exact={false} />
                        <Route path='/results' component={SearchBarResults} exact={false} />
                        <PrivateRoute path='/cart' component={CartPage} exact={false} />
                        <Route component={ErrorPageComponent} />
                    </Switch>
                </ConnectedRouter>
                <CookieConsentComponent/>
                <script data-ad-client="ca-pub-7894768197794559" async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js" />
            </div>
        </ApolloProvider>
    );
}

const mapStateToProps = (state: StoreState): {
    currentUser: User, userHasCompletedOnboarding: boolean, selectedChannel: Channel,
    item: TileData, selectedItemId: number, searchBarExpanded: boolean, currentPathname: string
} => {
    return {
        currentUser: selectCurrentUser(state),
        selectedChannel: selectCurrentChannel(state),
        item: selectPhotoDetails(state),
        selectedItemId: selectCurrentlySelectedPhoto(state),
        searchBarExpanded: selectSearchBarExpanded(state),
        userHasCompletedOnboarding: selectHasUserCompletedOnboarding(state),
        currentPathname: selectCurrentPathname(state),
    }
}

export default connect(mapStateToProps, null)(App);

