import { BannerActionTypes, SignInBannerState} from './sign-in-banner.types';
import { TSignInBannerReducerActions } from './sign-in-banner.actions';
import {RoleTypes} from "../../components/register/register.types";

const InitialState: SignInBannerState = {
    hidden: true,
    shown: false,
    registerType: RoleTypes.Pro_organization //TODO: return to Member when clients want member functionalities
};

export const signInBannerReducer = (state = InitialState, action: TSignInBannerReducerActions) : SignInBannerState => {
    switch(action.type) {
        case BannerActionTypes.TOGGLE_BANNER_HIDDEN:
            return { 
                ...state,
                hidden: action.data
            };
        case BannerActionTypes
        .SET_BANNER_SHOWN:
            return { 
                ...state,
                shown: action.data
            };
        case BannerActionTypes.SET_REGISTER_TYPE:
            return { 
                ...state,
                registerType: action.data
            };
        default:
            return state;
    }
}
export default signInBannerReducer;