import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Accordion, AccordionSummary, Divider, Grow } from "@material-ui/core";
import '../cart/cart-page.styles.scss';
import { StoreState } from '../../redux/root-reducer';
import { TaxForOrder } from "../../redux/cart/cart-page.types";
import SyncLoader from 'react-spinners/SyncLoader';
import { LicenseSummaryProps } from './my-license-page.types';
import { selectCurrentSubscriptionSubtotal, selectCurrentSubscriptionToPurhcase, selectCurrentSubscriptionTotal, selectTaxSub } from '../../redux/subscriptions/subscription-page.selector';
import { Dispatch } from 'redux';
import { IEmptySubscription, TSubscriptionReducerActions } from 'redux/subscriptions/subscription-page.actions';
import { SubscriptionActionTypes } from '../../redux/subscriptions/subscription-page.types';
import { getExpirationDate, getRemainingDays, getToTwoDecimals } from '../cart/cart-page-helperFunctions';
import { LicensePlan } from '../photo-details/photo-details.types';
import { capitalizeFirstLetter } from '../photo-details/photo-details-helper-functions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      margin: theme.spacing(0.5),
    },
    section1: {
      margin: theme.spacing(3, 2),
      textAlign: 'left',
      position: 'relative',
    },
    section2: {
      margin: theme.spacing(2),
    },
    section3: {
      margin: theme.spacing(3, 1, 1),
    },
    loadingBox: {
        width: '100%',
        height: '100%',            
        position: 'absolute',
        top: '0',
        left: '0',
        opacity: '1',
        zIndex: 19
    }
  }),
);

const LicensePurchaseSummary: React.FC<LicenseSummaryProps> = ({...props}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { planToPurchase, loading, subtotal, total, tax } = props;
    const [planPurchaseDate] = useState(new Date(Date.now()))
    const [planExpirationDate, setPlanExpirationDate] = useState<Date | null>(null);

    useEffect(() => {
        if(planToPurchase.id && planToPurchase.period) {
            const expirationDate = new Date(Date.now() + planToPurchase.period * 86400000);
            setPlanExpirationDate(expirationDate);
        }
        
    }, [planToPurchase])

    return (
    <Grow in={true}>
        <Accordion expanded={true} >
            <AccordionSummary id="panel1c-header">
              <div className="title">
               {t("Cart.Order.Summary")}
              </div>   
            </AccordionSummary>
            {loading ? 
                <div className={classes.loadingBox}>
                <SyncLoader css={`display: block; margin: 0 auto; border-color: red;`} size={20} 
                    color={"#36D2B3"} loading={loading}/>
                </div>
            : null}
            {/* LOADING FOR BOTH TAX AND LICENSE */}
            <div className="accordion-container">
                {planExpirationDate ? 
                    <Grid container direction="row" spacing={3}>
                        <Grid item xs>
                            <Typography color="textPrimary" variant="h6">
                                {`${getRemainingDays(planPurchaseDate, planExpirationDate)} Days ${planToPurchase.licensingType}, `}
                                {`${capitalizeFirstLetter(planToPurchase.licensingType)} License plan`}
                            </Typography>
                            <Typography color="textPrimary" variant="subtitle1">
                                {`Purchases available with this plan: ${planToPurchase.amount}`}
                            </Typography>
                            <Typography color="textPrimary" variant="body2">
                                {`Expiration date for your plan is ${getExpirationDate(planExpirationDate)}`}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography gutterBottom variant="h6">
                                {`$${getToTwoDecimals(planToPurchase.totalPrice)}`}
                            </Typography>
                        </Grid>
                    </Grid>
                : null}
                <Divider/>
                <Grid container alignItems="center">
                    <Grid item xs>
                        <Typography color="textPrimary" variant="body1">
                            Subtotal
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography gutterBottom variant="h6">
                        {`$${getToTwoDecimals(subtotal)}`}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container alignItems="center">
                    <Grid item xs>
                        <Typography color="textPrimary" variant="body1">
                            VAT/GST
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography gutterBottom variant="h6">
                        {`$${tax ? getToTwoDecimals(tax.amountToCollect) : 0.00}`}
                        </Typography>
                    </Grid>
                </Grid>
                <Divider />
                <Grid container alignItems="center">
                    <Grid item xs>
                        <Typography color="textPrimary" variant="h6">
                            Total amount to pay
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography gutterBottom variant="h6">
                        {`$${getToTwoDecimals(total)}`}
                        </Typography>
                    </Grid>
                </Grid>
            </div>
        </Accordion>
    </Grow>
    )
}
const mapStateToProps = (state: StoreState): { planToPurchase: LicensePlan; subtotal: number, total: number; tax: TaxForOrder | null } => {
    return {
        planToPurchase: selectCurrentSubscriptionToPurhcase(state),
        subtotal: selectCurrentSubscriptionSubtotal(state),
        total: selectCurrentSubscriptionTotal(state),
        tax: selectTaxSub(state)
    };
};

const mapDispatchToProps = (dispatch: Dispatch<TSubscriptionReducerActions>) => {
    return {
        removeSubscriptions: () =>
      dispatch<IEmptySubscription>({
        type: SubscriptionActionTypes.EMPTY_SUBSCRIPTION
      }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LicensePurchaseSummary);