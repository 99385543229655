import gql from 'graphql-tag';

export const GET_CHANNELS = gql`
query getAllChannelsQuery {
    getAllChannels {
        id
        title
        code
    }
}
`;

export const GET_CHANNEL_BY_ID = gql`
    query getChannelById($channelId: Int!) {
        getChannelById(channelId: $channelId) {
            id
            title
            code
        }
    }
`;

export const GET_SUBCHANNEL_BY_ID = gql`
    query getSubchannelById($subchannelId: Int!) {
        getSubchannelById(subchannelId: $subchannelId) {
            id
            title
        }
    }
`;

export const GET_THREAD_BY_ID = gql`
    query getThreadById($threadId: Int!) {
        getThreadById(threadId: $threadId) {
            id
            title
        }
    }
`;