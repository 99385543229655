import gql from 'graphql-tag';

export const SUBSCRIBE = gql`
mutation subscribeMutation($userId: Float!, $channelVariationId: Float!,  $channelVariation: String! ){
	subscribe(userId:$userId, channelVariationId: $channelVariationId, channelVariation: $channelVariation)
}
`;

export const UNSUBSCRIBE = gql`
mutation unsubscribeMutation($userId: Float!, $channelVariationId: Float!,  $channelVariation: String! ){
	unSubscribe(userId:$userId, channelVariationId: $channelVariationId, channelVariation: $channelVariation)
}
`;

export const CHECK_FOLLOWING = gql`
mutation checkIfFollowingMutation($userId: Float!, $channelVariationId: Float!,  $channelVariation: String! ){
	checkIfFollowing(userId:$userId, channelVariationId: $channelVariationId, channelVariation: $channelVariation)
}
`;

export const GET_CHANNEL_CONTENT_COUNT = gql`
query getChannelContentCountQuery($channelId : Int!){
  getChannelContentCount(channelId: $channelId)
}
`;

export const GET_SUBCHANNEL_CONTENT_COUNT = gql`
query getSubChannelContentCountQuery($subChannelId : Int!){
  getSubChannelContentCount(subChannelId: $subChannelId)
}
`;

export const GET_THREAD_CONTENT_COUNT = gql`
query getThreadContentCountQuery($threadId : Int!){
  getThreadContentCount(threadId: $threadId)
}
`;

// export const GET_CHCONTENT = gql`
// mutation getChannelContentMutation($id : Float!){
//   getChannelContent(id: $id){
//     content_path_to_file
//     content_id
//   }
// }
// `;

// export const GET_SUBCONTENT = gql`
// mutation getSubchannlesContentMutatuin($id : Float!){
//   getSubChannelContent(id: $id){
//     content_path_to_file
//     content_id
//   }
// }
// `;

// export const GET_THCONTENT = gql`
// mutation getThreadContentMutation($id : Float!){
//   getThreadContent(id: $id){
//     content_path_to_file
//     content_id
//   }
// }
// `;



