import gql from 'graphql-tag';
import { USER_SOURCE_FRAGMENT } from './fragments';

export const SEARCH_USERS = gql`
    ${USER_SOURCE_FRAGMENT}
    query searchUsers($title: String!, $first: Int!, $after: String!) {
        searchUsers(title: $title, first: $first, after: $after) {
            searchEdges{
                node{
                    _index
                    _type
                    _id
                    _score
                    _source {
                        ...UserSource
                    }
                    _version
                    matched_queries
                }
            }
            pageInfo{
                hasNextPage
                endCursor
            }
            totalSearchResults
        }
    }
`;

export const GET_ALL_SEARCH_RESULTS = gql `
query getAllSearchResults(
    $searchString: String!
    $contentFromOptions: String
    $contentFromUser: Int
    $contentType: String
    $dateFilter: [DateTime!]
    $locationCountryFilter: String
    $locationProvinceFilter: String
    $locationCityFilter: [String!]
    $channelFilterId: Int
    $subchannelFilterId: Int
    $threadFilterId: Int
    $channelFilterTitle: String
    $subchannelFilterTitle: String
    $threadFilterTitle: String
    $pageNumber: Int!
    $contentPerPage: Int!
    $userId: Int
  ) {
    search(
      input: {
        searchString: $searchString
        contentFromOptions: $contentFromOptions
        contentFromUser: $contentFromUser
        contentType: $contentType
        dateFilter: $dateFilter
        locationCountryFilter: $locationCountryFilter
        locationProvinceFilter: $locationProvinceFilter
        locationCityFilter: $locationCityFilter
        channelFilterId: $channelFilterId
        subchannelFilterId: $subchannelFilterId
        threadFilterId: $threadFilterId
        channelFilterTitle: $channelFilterTitle
        subchannelFilterTitle: $subchannelFilterTitle
        threadFilterTitle: $threadFilterTitle
      }
      pageNumber: $pageNumber
      contentPerPage: $contentPerPage
      userId: $userId
    ) {
      allResultsCount
      userUploadedContent {
        articleId
        contentSetId
        uploadDate
        publishDate
        title
        type
        status
        hash
        content {
          id
          code
          pathToFileCompressed
          pathToFileThumbnail
          externalLink
          externalLinkMessage
          objectName
          headline
          licensingType
          type
          caption
          isOwned
          size
        }
        contentRate{
          flatRate
        }
        contentNavigations {
          channel {
            id
            title
            code
          }
          subChannel {
            id
            title
          }
          thread {
            id
            title
          }
        }
        contributor {
          username
          person {
            id
          }
          company {
            id
          }
        }
        location {
          country {
            country
          }
          province {
            stateProvince
          }
          city {
            city
          }
        }
      }
    }
  }
`;