import React, { useEffect, useState } from "react";
import { TextField, Accordion, AccordionSummary } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { Channel } from "../home-component/home.types";
import { GET_CHANNELS } from "../channels/queries";
import {
  GET_SUBCHANNEL_FOR_SELECTED_CHANNEL,
  GET_THREADS_FOR_SELECTED_SUBCHANNEL
} from "./queries";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useLazyQuery } from "@apollo/react-hooks";
import {
  Subchannels,
  Subchannel,
  Threads,
  OnboardingStepTwoProps
} from "./onboarding-page.types";
import { useTranslation } from "react-i18next";
import { Thread } from "../channelsSidebar/channelSideBars.types";

export const OnboardinStepTwo: React.FC<OnboardingStepTwoProps> = ({
  ...props
}) => {
  const { headline } = props;
  const [channelQuery, setChannelsQuery] = useState(GET_CHANNELS);
  const [tags, setTags] = useState<String[]>([]);
  const [channels, setChannels] = useState<Channel[]>([]);
  const [subchannels, setSubchannels] = useState<Subchannels[]>([]);
  const [threads, setThreads] = useState<Threads[]>([]);
  const [selectedChannels, setSelectedChannels] = useState<Channel[]>([]);
  const [selectedSubChannels, setselectedSubChannels] = useState<Subchannel[]>(
    []
  );
  const [selectedThreads, setselectedThreads] = useState<Thread[]>([]);
  const [filteredSubchannels, setFilteredSubchannels] = useState<Subchannels[]>(
    subchannels
  );
  const [filteredThreads, setFilteredThreads] = useState<Threads[]>(threads);

  const [dataQuery, { data, loading, error }] = useLazyQuery(channelQuery);

  const [openSubchannel, setOpenSubchannel] = useState(false);
  const [openThread, setOpenThread] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (data && data.getAllChannels) {
      setChannels(data.getAllChannels);
    } else if (data && data.getSubchannelsForSelectedChannels) {
      setSubchannels(data.getSubchannelsForSelectedChannels);
    } else if (data && data.getThreadsForSelectedSubchannel) {
      setThreads(data.getThreadsForSelectedSubchannel);
    }
  }, [data]);

  useEffect(() => {
    if (subchannels.length !== 0) {
      setFilteredSubchannels(subchannels);
    }
    if (threads.length !== 0) {
      setFilteredThreads(threads);
    }
  }, [threads, subchannels]);

  useEffect(() => {
    if (channelQuery === GET_CHANNELS) {
      dataQuery();
    } else if (channelQuery === GET_SUBCHANNEL_FOR_SELECTED_CHANNEL) {
      const channelIds: number[] = selectedChannels.map(channel => {
        return channel.id;
      });

      dataQuery({ variables: { channelIds: channelIds } });
    } else if (channelQuery === GET_THREADS_FOR_SELECTED_SUBCHANNEL) {
      let channelId;
      const subChannelIds = selectedSubChannels.map(subChannel => {
        channelId = subChannel.channelId;
        return subChannel.id;
      });

      dataQuery({
        variables: { channelId: channelId, subchannelIds: subChannelIds }
      });
    }
  }, [channelQuery, dataQuery, selectedChannels, selectedSubChannels]);

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter" && e.target.value !== "") {
      let newKeyWords: String[] = [e.target.value.toString()].concat(tags);
      setTags(newKeyWords);
      e.target.value = "";
    }
  };
  const handleDeleteKeyWord = (index: any) => {
    const newKeyWords = tags.filter(tag => index !== tags.indexOf(tag));
    setTags(newKeyWords);
  };

  //-----------Channel Select----------------
  const handleChannelSelect = (channel: Channel) => {
    if (selectedChannels.includes(channel)) {
      const tempSelectedChannels = selectedChannels.map(selectedChannel => {
        return selectedChannel;
      });

      setSelectedChannels(tempSelectedChannels);
      if (tempSelectedChannels.length === 0) {
        setOpenSubchannel(false);
        setOpenThread(false);
      }
    } else {
      setSelectedChannels(selectedChannels.concat([channel]));
    }
    setChannelsQuery(GET_SUBCHANNEL_FOR_SELECTED_CHANNEL);
  };

  //-----------SubChannel Select----------------
  const handleSubchannelSelect = (subchannel: Subchannel) => {
    if (doesItInclude(selectedSubChannels, subchannel)) {
      const tempSelectedChannels = selectedSubChannels.filter(
        selectedSubChannel => selectedSubChannel.title !== subchannel.title
      );

      setselectedSubChannels(tempSelectedChannels);

      if (tempSelectedChannels.length === 0) {
        setOpenThread(false);
      }
    } else {
      setselectedSubChannels(selectedSubChannels.concat([subchannel]));
    }

    setChannelsQuery(GET_THREADS_FOR_SELECTED_SUBCHANNEL);
  };
  //----------------Threads Select------------------
  const handelSelectThread = (thread: Thread) => {
    if (doesItInclude(selectedThreads, thread)) {
      const tempSelectedChannels = selectedThreads.filter(
        selectedThread => selectedThread.title !== thread.title
      );
      setselectedThreads(tempSelectedChannels);
    } else {
      setselectedThreads(selectedThreads.concat([thread]));
    }
  };
  //------------------get channel colors-------
  const getChannelColor = (code: string) => {
    if (code) {
      return code.concat("-add");
    }
  };
  //-----------------open subchannel accordion---------------------
  const handleOpenSubchannel = (event: any) => {
    if (event.target.id !== "searchfield-channels") {
      setOpenSubchannel(!openSubchannel);
    }
  };

  //-----------------open thread accordion-------------------------
  const handleOpenThread = (event: any) => {
    if (event.target.id !== "searchfield-threads") {
      setOpenThread(!openThread);
    }
  };
  //----------------sub channel search----------------------------
  const handleSearchSubchannels = (event: any) => {
    let searchParam = event.target.value.toLocaleLowerCase();
    let result: Subchannels[] = [];
    subchannels.forEach(function(subchannel: Subchannels) {
      const subchannelResults = subchannel.subchannels.filter(
        currentSubchannel => {
          if (
            currentSubchannel.title.toLocaleLowerCase().includes(searchParam)
          ) {
            return currentSubchannel;
          }
        }
      );
      if (subchannelResults.length !== 0) {
        result.push({
          channel: subchannel.channel,
          subchannels: subchannelResults
        });
      }
    }, {});
    setFilteredSubchannels(result);
  };

  //--------------threads search----------------------------------
  const handleSearchThreads = (event: any) => {
    let searchParam = event.target.value.toLocaleLowerCase();
    let result: Threads[] = [];
    threads.forEach(function(thread: Threads) {
      const threadResults = thread.threads.filter(currentThread => {
        if (currentThread.title.toLocaleLowerCase().includes(searchParam)) {
          return currentThread;
        }
      });
      if (threadResults.length !== 0) {
        result.push({
          channel: thread.channel,
          subchannel: thread.subchannel,
          threads: threadResults
        });
      }
    }, {});
    setFilteredThreads(result);
  };

  //check if list includes an item since .includes does not work when re-render
  const doesItInclude = (list: any, item: any) => {
    let includes = false;
    for (let i = 0; i < list.length; i++) {
      if (list[i].title === item.title) {
        includes = true;
        break;
      }
    }
    return includes;
  };

  return (
    <div className="step-two-container">
      <span className="step-two-headline"> {headline}</span>
      <div className="step-two-options">
        <div className="options-head">
          <h3>{t("Onboarding.StepTwo.Subjects.Head")}</h3>
        </div>
        <div className="options-content">
          <div className="options-visualisation-wrapper">
            <h4>{t("Onboarding.StepTwo.Topics.Head")}</h4>
            <div className="tags">
              {tags.map((tag, index) => {
                return (
                  <button className="keyword-button" key={index}>
                    <span>
                      {"#" + tag + " "}
                      <FontAwesomeIcon
                        className="keyword-delete"
                        key={index}
                        onClick={() => handleDeleteKeyWord(index)}
                        icon={faTimesCircle}
                      />
                    </span>
                  </button>
                );
              })}
            </div>
          </div>
          <div className="text-field-wrapper">
            <TextField
              autoComplete="off"
              onKeyDown={handleKeyDown}
              label={t("Onboarding.StepTwo.Keywords")}
            />
          </div>
        </div>
      </div>
      <div className="step-two-options">
        <div className="options-head">
          <h3>Channels</h3>
        </div>
        <div className="options-content">
          <div className="channels-visualisation-wrapper">
            {channels.map(channel => {
              return (
                <button
                  onClick={() => handleChannelSelect(channel)}
                  className={`channel-button-add ${
                    selectedChannels.includes(channel)
                      ? getChannelColor(channel.code!)
                      : ""
                  }`}
                  key={channel.id}
                >
                  <span className="button-text">
                    {channel.title + " "}
                    <AddCircleOutlineIcon className="channel-add" />
                  </span>
                </button>
              );
            })}
          </div>
        </div>
      </div>

      <Accordion
        disabled={selectedChannels.length === 0}
        expanded={openSubchannel}
      >
        <AccordionSummary
          onClick={handleOpenSubchannel}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          <div className="accordion-header-container">
            <div className="title">Subchannels</div>
            <TextField
              autoComplete="off"
              onChange={handleSearchSubchannels}
              placeholder={t("Navbar.SearchBar")}
              id="searchfield-channels"
              className="searchfield"
            />
          </div>
        </AccordionSummary>
        <div className="options-content">
          <div className="subchannel-visualisation-wrapper">
            {filteredSubchannels.map((subChannelPerChannel, index) => {
              return (
                <div className="subchannel-rows" key={index}>
                  {subChannelPerChannel.subchannels.map(subChannel => {
                    return (
                      <button
                        onClick={() => handleSubchannelSelect(subChannel)}
                        className={`channel-button-add ${
                          doesItInclude(selectedSubChannels, subChannel)
                            ? getChannelColor(
                                subChannelPerChannel.channel.code!
                              )
                            : ""
                        }`}
                        key={subChannel.id}
                      >
                        <span className="button-text">
                          {subChannel.title + " "}
                          <AddCircleOutlineIcon className="channel-add" />
                        </span>
                      </button>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </Accordion>
      <Accordion disabled={threads.length === 0} expanded={openThread}>
        <AccordionSummary
          onClick={handleOpenThread}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          <div className="accordion-header-container">
            <div className="title">Threads</div>
            <TextField
              autoComplete="off"
              onChange={handleSearchThreads}
              placeholder={t("Navbar.SearchBar")}
              id="searchfield-threads"
              className="searchfield"
            />
          </div>
        </AccordionSummary>
        <div className="options-content">
          <div className="subchannel-visualisation-wrapper">
            {filteredThreads.map((threadPerSubChannel, index) => {
              if (threadPerSubChannel.threads.length !== 0) {
                return (
                  <div className="subchannel-rows" key={index}>
                    {threadPerSubChannel.threads.map(thread => {
                      return (
                        <button
                          onClick={() => handelSelectThread(thread)}
                          className={`channel-button-add ${
                            doesItInclude(selectedThreads, thread)
                              ? getChannelColor(
                                  threadPerSubChannel.channel.code!
                                )
                              : ""
                          }`}
                          key={thread.id}
                        >
                          <span className="button-text">
                            {thread.title + " "}
                            <AddCircleOutlineIcon className="channel-add" />
                          </span>
                        </button>
                      );
                    })}
                  </div>
                );
              }
            })}
          </div>
        </div>
      </Accordion>
    </div>
  );
};
