import React, {useState, useEffect} from "react";
import StackGrid, {easings, transitions} from "react-stack-grid";
import sizeMe from 'react-sizeme';
import {ContentListProps, ContentTypes} from "./content.types";
import ContentItem from "./ContentItem";
import { UserUploadedContent } from "../contributor-content/contributor-content.types";
import { Skeleton } from "@mui/material";

const ContentList: React.FC<ContentListProps> = ({...props}) => {
    const { scaleDown } = transitions;
    const {loadingSearch, tileData, contentPerPage} = props
    const [state, setState] = useState({
        duration: 0,
        gutter: 15,
        easing: easings.quartOut,
        transition: 'fadeDown',
        rtl: false,

    });

    const [heights,setHeights] = useState<number[]>([]);

    const { duration, gutter, easing, transition, rtl } = state;

    const getItemKey = (item: UserUploadedContent) => {
        if(item.type === ContentTypes.CONTENT_SET) {
            return `${item.contentSetId}_set`;
        } else if(item.type === ContentTypes.ARTICLE) {
            return `${item.articleId}_${item.contentSetId}_article`;
        } else {
            return `${item.content.id}_content`;
        }
    }

    useEffect(()=>{
        const newHeights: number[] = [];
        for(let i=0;i<contentPerPage;++i){
            newHeights.push(Math.floor(Math.random() * 100)+300)
        }
        setHeights([...newHeights])
    },[])

    return (
        <React.Fragment>
            <StackGrid
                    monitorImagesLoaded={true}
                    duration={duration}
                    columnWidth={window.innerWidth >= 1820 ? "20%" : window.innerWidth >= 1024 ? "25%" : window.innerWidth >= 768 ? "33%" : "50%"}
                    easing={easing}
                    transition={transition}
                    gutterHeight={gutter}
                    gutterWidth={gutter}
                    rtl={rtl}
                    appear={scaleDown.fadeDown}
                    appeared={scaleDown.appeared}
                    enter={scaleDown.enter}
                    entered={scaleDown.entered}
                    leaved={scaleDown.leaved}
                    >
                    {tileData.length > 0 ? tileData.map((item: UserUploadedContent) => (
                        <ContentItem key={getItemKey(item)} item={item} isInSearch={false}/>
                    )) : loadingSearch && Array.from(Array(contentPerPage).keys()).map((item: number, index: number) => 
                            <Skeleton variant="rectangular" width={240} height={heights[index]} className='image-skeleton'/>
                    )}
            </StackGrid>
        </React.Fragment>
    );
}


export default sizeMe()(ContentList);