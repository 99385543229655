import { makeStyles } from "@material-ui/core"

export enum RoleTypes {
    Contributor_individual = "contributor_individual",
    Contributor_agency = "contributor_agency",
    Pro_organization = "pro_organization",
    Pro_freelancer = "pro_freelancer",
    Member = "member",
    Admin = "admin",
    SuperAdmin = "superadmin"
}

export type RegisterModalProps = {
    registeredUser: boolean;
    toggleLoginModal: boolean;
    toggleForgotPasswordModal: boolean;
    toggleRegisterModal:boolean;
    toggleRegisterAsRoleModal:boolean;
    resetTogglesModalAction: () => void;
    toggleRegister:() => void;
    toggleRegisterAsRole:()=>void;
    toggleBanner: (hide:boolean) => void;
    setRegisterType: (registerType:string) => void;
}

export const dialogStyles = makeStyles((theme) => ({
    dialogRoot: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    dialogPaper: {
        borderRadius: '25px !important',
        overflowY: 'hidden'
    },
    dialogPaperWidthSm:{
        maxWidth:'none'
    },
    closeButton:{
        padding:'0px !important'
    }
}))