export enum DownloadActionTypes {
    TOGGLE_DOWNLOADING_CONTENT = "TOGGLE_DOWNLOADING_CONTENT",
    TOGGLE_DATE_PURCHASED = "TOGGLE_DATE_PURCHASED",
    TOGGLE_SIZE_PURCHASED = "TOGGLE_SIZE_PURCHASED",
    RESET_CONTENT = "RESET_CONTENT",
} 

export interface DownloadState {
    downloadingContents: any;
    datePurchased: string[];
    sizePurchased: string;
}