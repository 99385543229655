import React, { useEffect, useState } from 'react';
import { useLazyQuery } from 'react-apollo';
import { CHECK_IS_CONTENT_OWNED, GET_EDITORIAL_RATES } from '../queries';
import { EditorialRate } from '../photo-details.types';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from '../photo-details-helper-functions';
import { getToTwoDecimals } from '../../cart/cart-page-helperFunctions';
import AddToCartButton from '../photo-details-add-to-cart-button.component';
import './photoContainer.scss';
import { StoreState } from '../../../redux/root-reducer';
import { User } from '../../../redux/user/user.types';
import { selectCurrentUser } from '../../../redux/user/user.selectors';
import { connect, useDispatch, useSelector } from 'react-redux';
import { downloadContent } from '../../utils/download';
import { EditorialPriceBoxContentProps } from './PhotoContainer.types';
import { PhotoDetailsActionTypes } from '../../../redux/photo-details/photo-details.types';
import { SeveritySnackbarEnum } from '../../../components/batch-upload/ContentUpload.types';
import { SnackbarComponent } from 'shared';
import { IClearBroadcastMessage, TPhotoDetailsActions } from '../../../redux/photo-details/photo-details.actions';
import { Dispatch } from 'redux';

const EditorialPriceBoxContent: React.FC<EditorialPriceBoxContentProps> = ({
    ...props
  }) => {
    const { contentInfo, currentUser, clearBroadcastAction } = props;
    const [editorialRates, setEditorialRates] = useState<EditorialRate[]>([]);
    const [ownedSizes, setOwnedSizes] = useState<string[]>([]);
    const [
      fetchEditorialRates,
      { data, loading, error, refetch }
    ] = useLazyQuery(GET_EDITORIAL_RATES, { fetchPolicy: "no-cache" });
    const { t } = useTranslation();
    const [showBroadcastMessage, setShowBroadcastMessage] = useState(false);

    const [
      fetchOwnedData,
      {
        data: ownedData,
        loading: ownedLoading,
        error: ownedError,
        refetch: ownedRefetch,
      },
    ] = useLazyQuery(CHECK_IS_CONTENT_OWNED, { fetchPolicy: "no-cache" });

    const [isbn, setIsbn] = useState("");
    const [issn, setIssn] = useState("");

    const dispatch = useDispatch();

    const broadcastMessage = useSelector((state: StoreState)=> state.photoDetails.broadcastMessage);

    const handleDownloadError = ()=>{
      dispatch({ type: PhotoDetailsActionTypes.BROADCAST_MESSAGE, data: { severity: SeveritySnackbarEnum.error, message: t("Error.Download.Content") } })
    }

    const handleEditorialContentDownload= (size: string)=>{
      if(currentUser?.token){
        downloadContent({purchasedImages: [contentInfo?.id], token: currentUser?.token, fileName: contentInfo?.headline, size, onError: handleDownloadError})
      }
    }

    const handleCloseBroadcastMessage = ()=>{
      setShowBroadcastMessage(false);
      if(clearBroadcastAction)clearBroadcastAction();
    }

    useEffect(() => {
      if (broadcastMessage?.severity && broadcastMessage?.message) {
        setShowBroadcastMessage(true);
      }
    }, [broadcastMessage]);

    useEffect(() => {
      fetchEditorialRates();
    }, []);

    useEffect(()=>{
      if(currentUser?.id){
        fetchOwnedData({
          variables: {
            contentId: parseInt(contentInfo?.id),
            userId: currentUser?.id,
          },
        });
      }
    },[currentUser])
  
    useEffect(() => {
      if (data && data.getEditorialRates) {
        setEditorialRates(data.getEditorialRates);
      }
    }, [data]);

    useEffect(() => {
      if (ownedData) {
        setOwnedSizes(ownedData.checkIfContentIsOwned.sizes);
      }
    }, [ownedData]);
  
    // TODO: Uncomment when ISBN and ISSN are decided
    // const handleISBNISSN = (e: any) => {
    //   if (e.target.name === "issn") {
    //     setIssn(e.target.value);
    //   } else {
    //     setIsbn(e.target.value);
    //   }
    // };
  
    return (
      <div className="price-box-editorial-content">
        {contentInfo
          ? editorialRates.map((editorialRate, index) => {
            return (
              <div className="editorial-rate" key={index}>
                <span className="price">
                  <div>
                    {capitalizeFirstLetter(editorialRate.size)} -{" "}
                    {t("Photo.Details.Container.Price")}
                    {getToTwoDecimals(editorialRate.rate)}
                  </div>
                </span>
                {ownedSizes.includes(editorialRate?.size) ? <button className="editorial-download-button" onClick={()=>{
                handleEditorialContentDownload(editorialRate.size)
              }}>Download</button> : <AddToCartButton
                  item={{
                    content: {
                      id: contentInfo.id,
                      pathToFileCompressed: contentInfo.pathToFileCompressed,
                      pathToFileThumbnail: contentInfo.pathToFileThumbnail,
                      headline: contentInfo.headline,
                      code: contentInfo.code,
                      publishDate: contentInfo.publishDate,
                      caption: contentInfo.caption,
                      type: contentInfo.type,
                      licensingType: contentInfo.licensingType,
                      externalLink: contentInfo.externalLink,
                      externalLinkMessage: contentInfo.externalLinkMessage,
                      pathToFile: contentInfo.pathToFile,
                      flatRate: editorialRate.rate,
                      size: editorialRate.size
                    },
                    flatRate: editorialRate.rate,
                    isbn: isbn,
                    issn: issn
                  }}
                  size={editorialRate.size}
                  isPartOfPlan={false}
                />}
              </div>
            );
          })
          : null}
        <SnackbarComponent showSnackbar={showBroadcastMessage} handleClose={handleCloseBroadcastMessage}
        severity={broadcastMessage?.severity}
        message={broadcastMessage?.message} />
        {/* TODO: Uncomment when the ISBN and ISSN logic is decided */}
        {/* <TextField onChange={handleISBNISSN} label="ISBN" name="isbn"></TextField>
        <TextField onChange={handleISBNISSN} label="ISSN" name="issn"></TextField> */}
      </div>
    );
  };

  const mapStateToProps = (
    state: StoreState
  ): {
    currentUser: User;
  } => {
    return {
      currentUser: selectCurrentUser(state),
    };
  };
  
  const mapDispatchToProps = (dispatch: Dispatch<TPhotoDetailsActions>) => {
    return {
      clearBroadcastAction: () => dispatch<IClearBroadcastMessage>({ type: PhotoDetailsActionTypes.CLEAR_BROADCAST })
    };
  };

  export default connect(mapStateToProps, mapDispatchToProps)(EditorialPriceBoxContent);

