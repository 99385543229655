import gql from 'graphql-tag';

export const GET_LOCAL_CONTENT = gql`
  query getAllLocalContent($nearbyCities: [String!]!, $channelId: Int, $subchannelId: Int, $threadId: Int){
    getAllLocalContent(nearbyCities: $nearbyCities, channelId: $channelId , subchannelId: $subchannelId, threadId: $threadId) {
      contentSetId
      articleId
      content {
        id
        pathToFileCompressed
        headline
        type
      }
      contentNavigations{
        channel {
          id
          title
        }
        subChannel {
          id
          title
        }
        thread {
          id
          title
        }
      }
    }
  }
`
