import React, {useState} from 'react';
import Dropzone, { ILayoutProps, IFileWithMeta, IPreviewProps } from 'react-dropzone-uploader';
import { DragAndDropOnboardingProps, dragAndDropStyles } from './onboarding-page.types';
import CancelIcon from '@material-ui/icons/Cancel';
import { Box, IconButton } from '@material-ui/core';
import Image from 'react-async-image';
import { DroppedFile } from '../../components/batch-upload/ContentUpload.types';
import { useTranslation } from "react-i18next"
import { StoreState } from 'redux/root-reducer';
import { Dispatch } from 'redux';
import { IDropProfileImage, IRemoveDroppedProfileImage, TOnboardingReducerAction } from '../../redux/onboarding/onboarding.actions';
import { OnboardingActionTypes } from '../../redux/onboarding/onboarding.types';
import { connect } from 'react-redux';
import './dragAndDropOnboarding.styles.scss'
import { selectDroppedFile } from '../../redux/onboarding/onboarding.selectors';
import {SnackbarComponent} from 'shared';

const DragAndDropOnboarding: React.FC<DragAndDropOnboardingProps> = ({ ...props }) => {
    const { t } = useTranslation();
    const { profileImage, removeDroppedFileAction, addProfileImageAction } = props;
    const styles = dragAndDropStyles();
    const urls = new WeakMap();
    const [showImageSizeSnackbar,setshowImageSizeSnackbar] = useState(false);

    const getBlobUrl = (blob: File) => {
        if (blob.size > 5000000) {
            setshowImageSizeSnackbar(true);
            return "";
        }
        if (urls.has(blob)) {
            return urls.get(blob)
        }
        else {
            let url = URL.createObjectURL(blob)
            urls.set(blob, url)
            return url
        }
    }

    const RemoveUploadedFileButton = (props: { fileWithMeta?: IFileWithMeta }) => {
        return (
            <Box position="absolute" display="inline-flex" top='30px' left='55%'>
                <IconButton onClick={() => removeUploadedFile(props.fileWithMeta)}>
                    <CancelIcon fontSize='small' classes={{ root: styles.svgIcon }} />
                </IconButton>
            </Box>
        )
    }

    const removeUploadedFile = (fileWithMeta?: IFileWithMeta) => {
        removeDroppedFileAction();
        if (fileWithMeta) {
            fileWithMeta.remove();
        }
    }

    const Layout = ({ input, submitButton, previews, dropzoneProps, files }: any) => {
        return (
            <div className='dropzone-container'>
                {previews !== null && previews.length === 0 ?
                    <div {...dropzoneProps} className='dropzone-label'>
                        {input}
                    </div>
                    :
                    null
                }
                <div className='preview-container'>
                    {previews && previews.length > 0
                        ?
                        previews
                        :
                        (profileImage && profileImage.fileWithMeta && getBlobUrl(profileImage.fileWithMeta.file) ?
                            <div className="preview-box">
                              
                                <RemoveUploadedFileButton />
                                
                                <Image src={getBlobUrl(profileImage.fileWithMeta.file)} className="image"
                                    loading='auto' placeholder={<div className="placeholder">{t("ContentUpload.Failed.Displayment")}</div>}
                                />
                            </div>
                            : null)
                    }
                </div>
                {files.length > 0 && submitButton}
            </div>
        )
    }

    const Preview = ({ fileWithMeta, percent }: any) => {

        return (
            <div className="preview-box">
                <RemoveUploadedFileButton fileWithMeta={fileWithMeta} />
                <Image src={getBlobUrl(fileWithMeta.file)} className="image"
                    loading='auto' placeholder={<div className="placeholder">{t("ContentUpload.Failed.Displayment")}</div>}
                />
            </div>
        )
    }

    const onChangeStatus = (file: IFileWithMeta, status: any) => {
        if (status === 'done') {
            let droppedFile: DroppedFile = {
                fileWithMeta: file,
                fileObject: file.file.name
            };
            addProfileImageAction(droppedFile);
        }
    }
    const handleClose = () => {
        setshowImageSizeSnackbar(false);
      };
    return (
        <>
            <Dropzone
                accept="image/*"
                LayoutComponent={Layout}
                onChangeStatus={onChangeStatus}
                PreviewComponent={Preview}
                inputContent={t("Onboarding.File.Upload")}
                maxFiles={1}
                multiple={false}
                canCancel={false}
                classNames={{
                    dropzone: 'dropzone-container',
                    inputLabel: 'dropzone-inputLabel'
                }}
            />
            <SnackbarComponent
                showSnackbar={showImageSizeSnackbar}
                handleClose={handleClose}
                severity="error"
                message="Please keep image size shorter than 5MB"
            />
        </>
        
    )
}

const mapStateToProps = (state: StoreState): { profileImage: DroppedFile | null } => {
    return {
        profileImage: selectDroppedFile(state)
    }
}

const mapDispatchToProps = (dispatch: Dispatch<TOnboardingReducerAction>) => {
    return {
        addProfileImageAction: (data: DroppedFile) => dispatch<IDropProfileImage>({ type: OnboardingActionTypes.ADD_PROFILE_IMAGE, data: data }),
        removeDroppedFileAction: () => dispatch<IRemoveDroppedProfileImage>({ type: OnboardingActionTypes.REMOVE_PROFILE_IMAGE })
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(DragAndDropOnboarding);

