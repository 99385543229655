import React, { useState, useRef } from 'react';
import { Button, ButtonGroup, Paper, MenuList, MenuItem, Popper, Grow } from '@mui/material';
import { ContentTypeFilterProps, ContentTypesForSearch } from './searchBar.types';
import { ClickAwayListener } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import './searchResultsFilters.styles.scss';
import { StoreState } from '../../redux/root-reducer';
import { selectContentTypeFilter } from '../../redux/search-bar/search-bar.selectors';
import { IToggleFilterContentType, TSearchReducerActions } from '../../redux/search-bar/search-bar.actions';
import { Dispatch } from 'redux';
import { SearchBarActions } from '../../redux/search-bar/search-bar.types';
import { connect } from 'react-redux';

const ContentTypeFilterComponent: React.FC<ContentTypeFilterProps> = ({...props}) => {
    const {contentType, isInChannelContex, toggleContentTypeFilter} = props;
    const { t } = useTranslation();
    const anchorRef = React.useRef<HTMLButtonElement>(null);
    const [open, setOpen] = useState(false);

    const openContentTypeDropdown = () => {
        setOpen(prevState => !prevState);
    }

    const handleListKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Tab') {
          event.preventDefault();
          setOpen(false);
        } else if (event.key === 'Escape') {
            setOpen(false);
        }
    }

    const handleContentTypeChange = (event: any, value: ContentTypesForSearch) => {
        event.preventDefault();

        toggleContentTypeFilter(value);
        setTimeout(() => {
            setOpen(false);
        }, 500);
    }

    const handleClose = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
          return;
        }
    
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Button ref={anchorRef} size={isInChannelContex ? "small" : "medium"} aria-controls={open ? 'menu-list-grow' : undefined}
                variant="contained" className='white-round-button'
                onClick={openContentTypeDropdown} endIcon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}>
                {t("ContentType." + contentType.toString())}
            </Button>
            <Popper open={open} style={{ zIndex: 5 }} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                    <MenuItem value={ContentTypesForSearch.ALL} 
                                        onClick={(event: any) => handleContentTypeChange(event, ContentTypesForSearch.ALL)}>{t("ContentType." + ContentTypesForSearch.ALL)}</MenuItem>
                                    <MenuItem value={ContentTypesForSearch.IMAGE} 
                                        onClick={(event: any) => handleContentTypeChange(event, ContentTypesForSearch.IMAGE)}>{t("ContentType." + ContentTypesForSearch.IMAGE)}</MenuItem>
                                    {/* <MenuItem value={ContentTypesForSearch.VIDEO} onClick={handleContentTypeChange}>{t("ContentType." + ContentTypesForSearch.VIDEO)}</MenuItem> */}
                                    <MenuItem value={ContentTypesForSearch.ARTICLE} 
                                        onClick={(event: any) => handleContentTypeChange(event, ContentTypesForSearch.ARTICLE)}>{t("ContentType." + ContentTypesForSearch.ARTICLE)}</MenuItem>
                                    <MenuItem value={ContentTypesForSearch.AUDIO} 
                                        onClick={(event: any) => handleContentTypeChange(event, ContentTypesForSearch.AUDIO)}>{t("ContentType." + ContentTypesForSearch.AUDIO)}</MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </React.Fragment>
    )
}

const mapStateToProps = (state: StoreState) : {contentType: ContentTypesForSearch;} => {
    return {
        contentType: selectContentTypeFilter(state),
    }
}

const mapDispatchToProps = (dispatch: Dispatch<TSearchReducerActions>) => {
    return {
        toggleContentTypeFilter:(data: ContentTypesForSearch) => dispatch<IToggleFilterContentType>({
            type: SearchBarActions.TOGGLE_FILTER_CONTENT_TYPE,
            data: data
        }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentTypeFilterComponent);