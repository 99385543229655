import gql from 'graphql-tag';

export const UPDATE_COOKIE_STATUS = gql`
    mutation updatedUserCookieStatusMutation($userId: Int!) {
        updatedUserCookieStatus(userId: $userId)
    }
`
export const GET_USER_COOKIE_STATUS = gql`
    query getHasUserAccpetedCookiesQuery($userId: Int!){
        getHasUserAcceptedCookies(userId: $userId)
    }
`;