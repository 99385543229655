import React, { useState, useEffect } from 'react';
import { DragAndDropProps, UploadedFileResponse, ContentSet, DroppedFile, IBroadcastMessage, SeveritySnackbarEnum, ImageUploadStatus } from './ContentUpload.types';
import Dropzone, { ILayoutProps, IInputProps, IFileWithMeta, IPreviewProps } from 'react-dropzone-uploader';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import SyncLoader from 'react-spinners/SyncLoader';
import { DELETE_CONTENT } from './queries';
import CancelIcon from '@material-ui/icons/Cancel';
import { Box, IconButton } from '@material-ui/core';
import {MuiThemeProvider} from '@material-ui/core/styles';
import {
    TContentUploadActions,
    IUploadFiles,
    IDeleteFiles,
    IUploadSet,
    IAddFile,
    IRemoveFile,
    IBroadcastContentUploadMessage,
    IChunkedFile
} from '../../redux/content-upload/content-upload.actions';
import { Dispatch } from 'redux';
import { CloseButtonTheme } from './ContentUpload.types';
import { ContentUploadActionTypes } from '../../redux/content-upload/content-upload.types';
import { connect } from 'react-redux';
import { StoreState } from '../../redux/root-reducer';
import { selectUploadedFiles, selectDroppedFiles, selectRadioButtonValue, selectImageUploadStatus } from '../../redux/content-upload/content-upload.selectors';
import { useMutation } from '@apollo/react-hooks';
import { selectCurrentUserId } from '../../redux/user/user.selectors';
import { getDroppedOrSelectedFiles } from 'html5-file-selector';
import Collapse from '@material-ui/core/Collapse';
import 'react-dropzone-uploader/dist/styles.css';
import './DragAndDropContent.styles.scss';
import {useTranslation} from "react-i18next"
import Image from 'react-async-image';
import Resizer from "react-image-file-resizer";
import ImageUploadingDisplay from "./ImageUploadingDisplay";
import CircularProgress from '@mui/material/CircularProgress';

const DragAndDropContentStep: React.FC<DragAndDropProps> = ({...props}) => {
    const {t} = useTranslation();
    const {droppedFiles, uploadedFiles, userId,imageUploadStatus, setUploadingNewFiles, deleteFilesAction, uploadDroppedFile, 
        removeDroppedFile, broadcastUploadErrorAction } = props;
    const [loadingIndicator, setLoadingIndicator] = useState(false);
    const [chosenFiles, setChosenFiles] = useState<File[]>([]);
    const [lastDroppedFilesLength, setLastDroppedFilesLength] = useState<number>(0);
    const urls = new WeakMap();
    const [thumbList, setThumbList] = useState<any>("");
    useEffect(() => {
        if(droppedFiles.length === lastDroppedFilesLength + chosenFiles.length && typeof(setUploadingNewFiles) === 'function') {
            setUploadingNewFiles!(false);
            setLastDroppedFilesLength(droppedFiles.length);


            let obj: any = {};
            droppedFiles.map((file: any, ind)=>{
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                resizeThumb(file.fileObject.fileObject).then((res: any)=>{
                    obj[file.fileWithMeta.meta.id] = res;

                    setThumbList(JSON.stringify(obj));

                })
            })
        }

    }, [droppedFiles]);

    const getBlobUrl = (blob: File) => {
        if (urls.has(blob)) {
            return urls.get(blob)
        } else {
            let url = URL.createObjectURL(blob)
            urls.set(blob, url)
            return url
        }
    }


    const [deleteFileMutation] = useMutation(DELETE_CONTENT);
    const deleteFile = (id: number): void => {
        deleteFileMutation({variables: {id: id}
        }).then((result: any) => {
            deleteFilesAction([result.data.deleteContent]);
            broadcastUploadErrorAction({severity: SeveritySnackbarEnum.success,
                message:t("ContentUpload.DeleteFile.Success")});
        }).catch((error: any) => {
            broadcastUploadErrorAction({severity: SeveritySnackbarEnum.error,
                message:t("ContentUpload.DeleteFile.Error")});
        })
    }

    const RemoveUploadedFileButton = (props: { fileId: number, fileWithMeta?: IFileWithMeta}) => {
        return (
            <MuiThemeProvider theme={CloseButtonTheme}>
                <Box className='iconBox'>
                    <IconButton className='iconButton' onClick={() => removeUploadedFile(props.fileId, props.fileWithMeta)}>
                        <CancelIcon className='iconFont' fontSize='small'  /> 
                    </IconButton>
                </Box>
            </MuiThemeProvider>
        )
    }

    const removeUploadedFile = (fileId: number, fileWithMeta?: IFileWithMeta) => {
        let deletedFile = uploadedFiles.find(file => file.id === fileId);
        if(deletedFile) {
            deleteFile(deletedFile.id);
            deleteFilesAction([fileId]);
            if(fileWithMeta)
                fileWithMeta.remove();
        } else {
            removeDroppedFile(fileId);
            setLastDroppedFilesLength(lastDroppedFilesLength - 1);
            if(fileWithMeta)
                fileWithMeta.remove();
            broadcastUploadErrorAction({severity: SeveritySnackbarEnum.success,
                message:t("ContentUpload.DeleteFile.Success")});
        }
    }

    const Layout = ({ input, submitButton, previews, dropzoneProps, files }: ILayoutProps) => {
        return (
            <div className='dropzone-container'>
                <div {...dropzoneProps} className='dropzone-label'>
                    {input}
                </div>

                <div className='preview-container'>
                    {previews && previews.length > 0
                        ? 
                        previews 
                        :
                        droppedFiles.map((droppedFile: DroppedFile) => (
                            <div key={droppedFile.fileWithMeta.meta.id} className="preview-box">
                                <RemoveUploadedFileButton fileId={parseInt(droppedFile.fileWithMeta.meta.id)} />
                                {/*<Image src={} className="image"*/}
                                {/*    loading='auto' placeholder={<div className="placeholder">{t("ContentUpload.Failed.Displayment")}</div>}*/}
                                {/*/>*/}
                            </div> 
                        ))
                    }
                    
                </div>

                {userId ?
                    (files.length > 0 && submitButton)
                :
                    <div className='error-box'>{t("LoggedIn.Error")}</div>
                }
            </div>
        )
    }

    const resizeFile = (file: File) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                200,
                200,
                "JPEG",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "base64"
            );
    });

    const resizeThumb = async (file: any) => {
        try {
            const image = await resizeFile(file);
            return image;
        } catch (err) {
            console.log(err);
        }
    };


    const Preview = ({fileWithMeta}: any) => {

        return (
            <>
                <div key={fileWithMeta.meta.id} className="preview-box">
                    <RemoveUploadedFileButton fileId={parseInt(fileWithMeta.meta.id, 10)} fileWithMeta={fileWithMeta} />
                    <div className={"imageWrap"}>
                        {imageUploadStatus[fileWithMeta.meta.id] === true &&<div className={"layer"}/>}
                        <div className={"upload-loader"}>
                            {imageUploadStatus[fileWithMeta.meta.id] === true && <CircularProgress style={{'color': '#0caf95'}} size={40} thickness={4}/>}
                        </div>
                        <Image src={thumbList.length > 0 ? JSON.parse(thumbList)[fileWithMeta.meta.id] : ""} className="image"
                            loading='auto' placeholder={<div className="placeholder">{t("ContentUpload.Failed.Displayment")}</div>}
                        />
                        <ImageUploadingDisplay id={fileWithMeta.meta.id}/>
                    </div>
                    {/*<Image src={getBlobUrl(fileWithMeta.file)} className="image"*/}
                    {/*    loading='auto' placeholder={<div className="placeholder">{t("ContentUpload.Failed.Displayment")}</div>}*/}
                    {/*/>*/}
                    <p className='fileName'>{fileWithMeta.file.name.split(".")[0].substr(0,20)+"."+fileWithMeta.file.name.split(".")[1]}</p>
                </div>
            </>
        )
    }

    const Input = ({ accept, onFiles }: IInputProps) => {
        return (
            <div>
                {droppedFiles.length === 0 && uploadedFiles.length === 0 ?
                    <label className="dropzone-inputLabel">
                        <input style={{ display: 'none' }} type="file"
                            accept={accept} multiple onChange={(event: any) => {
                                getFilesFromEvent(event).then(chosenFiles => {
                                  onFiles(chosenFiles);
                                })
                              }}
                        />
                        {loadingIndicator ?       
                        <SyncLoader css={`display: block; margin: 0 auto; border-color: red;`} size={20} 
                        color={"#0caf95"} loading={loadingIndicator}/>
                        :
                        <>
                            <CloudUploadIcon className='upload-icon'/>
                            <span style={{cursor: "pointer", color:"#0caf95", fontSize: '1.5rem'}}>
                                {t("Click.Drag.Label")}
                            </span>
                        </>
                        } 
                    </label>
                    :
                    <label className='submit-label'>
                        <input style={{ display: 'none' }} type="file"
                            accept={accept} multiple onChange={(event: any) => {
                                getFilesFromEvent(event).then(chosenFiles => {
                                    onFiles(chosenFiles);
                                })
                            }}
                            />                        
                            {loadingIndicator ?       
                        <SyncLoader css={`display: block; margin: 0 auto; border-color: red;`} size={20} 
                        color={"#36D2B3"} loading={loadingIndicator}/>
                        :
                        <Collapse in={true} timeout={500}>
                            <span className='label-text'>{t("Click.Drag.Label")}</span>
                            <CloudUploadIcon className='upload-icon'/>
                        </Collapse>
                        } 
                    </label>
                }
            </div>
        )
    }

    const getFilesFromEvent = async (event: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLElement>) : Promise<any> => {
        setLoadingIndicator(true);
        if(typeof(setUploadingNewFiles) === 'function') {
            setUploadingNewFiles!(true);
        }

        let invalidFiles: string[] = [];

        return new Promise((resolve, reject) => {
            getDroppedOrSelectedFiles(event).then((uploadedFiles: File[]) => {
                const validFiles = uploadedFiles.filter((uploadedFile: any) => {
                    const fileType = uploadedFile.fileObject.type;
                    const type = fileType.substr(fileType.indexOf('/') + 1);
                    if(type == "jpg" || type == "jpeg") {
                        return uploadedFile;
                    }
                    else {
                        invalidFiles.push(uploadedFile.fileObject.name);
                    }
                });
                if(validFiles.length + droppedFiles.length <= 50) {
                    setChosenFiles(validFiles);
                    setLoadingIndicator(false);
                    resolve(validFiles.map((f: any) => {
                        
                        return f.fileObject;
                    }));
                } else {
                    setLoadingIndicator(false);
                    broadcastUploadErrorAction({severity: SeveritySnackbarEnum.error,
                        message:t("Validation.File.Count")});
                    reject();
                } 
            }).catch((error: any) => {
              reject(error);
            }).finally(() => {
                if(invalidFiles.length > 0) {
                    broadcastUploadErrorAction({
                        severity: SeveritySnackbarEnum.error,
                        message: t("ContentUpload.CertainFiles.Fail") + `${invalidFiles.join(", ")}` + "because their format is invalid."
                    });
                    setLoadingIndicator(false);
                    setUploadingNewFiles!(false);
                }
            })
        })
      }

    const onChangeStatus = (file: IFileWithMeta, status: any) => {
        if (status==='done') {
            const droppedFile: DroppedFile = {
                fileWithMeta: file, 
                fileObject: chosenFiles.find(f => f.name === file.file.name)
            };

            uploadDroppedFile(droppedFile);            
        }
    }
    
    return (
        <Dropzone
            accept="image/jpg,image/jpeg"
            disabled={userId ? false : true}
            LayoutComponent={Layout}
            onChangeStatus={onChangeStatus}
            getFilesFromEvent={getFilesFromEvent}
            PreviewComponent={Preview}
            InputComponent={Input}
            maxFiles={50}
            classNames={{dropzone: 'dropzone-container', 
            inputLabel: 'dropzone-inputLabel'
        }}
        />
    )
}

const mapStateToProps = (state: StoreState): {uploadedFiles: UploadedFileResponse[]; droppedFiles: DroppedFile[]
    ; userId: number | undefined; selectedRadioButton: string; imageUploadStatus: any;} => {
    return {
        uploadedFiles: selectUploadedFiles(state),
        droppedFiles: selectDroppedFiles(state),
        userId: selectCurrentUserId(state),
        selectedRadioButton: selectRadioButtonValue(state),
        imageUploadStatus: selectImageUploadStatus(state),
    }
}

const mapDispatchToProps = (dispatch: Dispatch<TContentUploadActions>) => {
    return {
        uploadFilesAction: (data: UploadedFileResponse[]) => dispatch<IUploadFiles>({type: ContentUploadActionTypes.UPLOAD_FILES, data: data}),
        deleteFilesAction: (data: number[]) => dispatch<IDeleteFiles>({type: ContentUploadActionTypes.DELETE_FILES, data: data}),
        uploadSetAction: (data: ContentSet) => dispatch<IUploadSet>({type: ContentUploadActionTypes.UPLOAD_SET, data: data}),
        uploadDroppedFile: (data: DroppedFile) => dispatch<IAddFile>({type: ContentUploadActionTypes.ADD_FILE, data: data}),
        removeDroppedFile: (data: number) => dispatch<IRemoveFile>({type: ContentUploadActionTypes.REMOVE_FILE, data: data}),
        broadcastUploadErrorAction: (data: IBroadcastMessage) => dispatch<IBroadcastContentUploadMessage>({
            type: ContentUploadActionTypes.BROADCAST_MESSAGE, data: data
        }),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(DragAndDropContentStep);