import {DownloadActionTypes, DownloadState } from './downloads.types';
import { TDownloadActions } from './downloads.actions';

const InitialState: DownloadState = {
    downloadingContents: [],
    datePurchased: [],
    sizePurchased: ""
};

export const downloadReducer = (state = InitialState, action: TDownloadActions) => {
    switch(action.type) {
        case DownloadActionTypes.TOGGLE_DOWNLOADING_CONTENT: 
            if(action.data.operation === "ADD"){
                return {
                    ...state,
                    downloadingContents: [...state.downloadingContents, {id: action.data.id, dateTime: action.data.dateTime, size: action.data.size}]
                }
            }
            else{
                const updatedDownloadingContents = state.downloadingContents.filter((content: any) => {
                    return content.id !== action.data.id || content.dateTime !== action.data.dateTime || content.size !== action.data.size;
                });
                return {
                    ...state,
                    downloadingContents: [...updatedDownloadingContents]
                }
            }
      
        case DownloadActionTypes.TOGGLE_DATE_PURCHASED: 
            return {
                ...state,
                datePurchased: action.data
            }
        case DownloadActionTypes.TOGGLE_SIZE_PURCHASED: 
            return {
                ...state,
                sizePurchased: action.data
            }
        case DownloadActionTypes.RESET_CONTENT: 
            return {
                ...InitialState
            }
        default: 
            return state;
    }
}