import React, { useState, useEffect } from 'react';
import { ContentSet, PopperComponentProps } from './ContentUpload.types';
import DoneIcon from '@material-ui/icons/Done';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Button, TextField, InputAdornment, IconButton, MenuItem, Popper, Grow, Paper, MenuList } from '@material-ui/core';
import './ManageContent.styles.scss';
import { useTranslation } from "react-i18next";

const PopperComponent: React.FC<PopperComponentProps> = ({...props}) => {
    const { currentSetId, selectedSetId, selectedContentSetImages, newSetTitle, contentSets, handleChange, moveToExistingSet, moveToNewSet} = props;
    const [anchor, setAnchorElem] = useState<null | HTMLElement>(null);
    const [setTitle, setNewTitle] = useState<string>('');
    const {t} = useTranslation();
    useEffect(() => {
        setNewTitle(newSetTitle);
    }, [newSetTitle])

    const toggleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElem(anchor ? null : event.currentTarget);
    };

    const handlePressEnter = (event: any) => {
        if(event.keyCode == 13){
            setAnchorElem(null);
            moveToNewSet();
        }
    }
    
    return (
        <div>
            <Button size='small' className='white-button'
                aria-controls="move-to-set-menu" aria-haspopup="true" 
                disabled={selectedContentSetImages.length === 0 || selectedSetId !== currentSetId} 
                onClick={toggleMenuClick} 
                variant='contained' component="span"
                endIcon={<ExpandMoreIcon />}>
                {t("Move.To.Set.Button")}
            </Button>
            <Popper open={Boolean(anchor)} anchorEl={anchor} 
            role={undefined} transition disablePortal>
            {({ TransitionProps, placement }) => (
                <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' 
                    ? 'center top' : 'center bottom' }}>
                    <Paper>
                        <MenuList autoFocusItem={Boolean(anchor)} id="menu-list-grow">
                        {contentSets!.map((set: ContentSet) => {
                                if(set.id !== currentSetId) {
                                    return <MenuItem key={set.id} onClick={() => {
                                        setAnchorElem(null);
                                        moveToExistingSet(set);
                                    }}>
                                        {set.title}
                                    </MenuItem>
                                }
                            })
                        }
                            <MenuItem onKeyDown={e => e.stopPropagation()}>
                                <TextField variant='standard' name='newSet' autoFocus value={setTitle} 
                                    placeholder={t('NewSet.Placeholder')} data-shrink={false} onChange={handleChange}
                                    onKeyDown={handlePressEnter}
                                    InputProps={{
                                        endAdornment: setTitle.length > 0 ? (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => {
                                                setAnchorElem(null);
                                                moveToNewSet();
                                                }}>
                                                <DoneIcon />
                                            </IconButton>
                                        </InputAdornment>
                                        ) : (
                                        null
                                        )
                                }}/>
                            </MenuItem>
                        </MenuList>
                    </Paper>
                </Grow>
                )}
            </Popper>
        </div>
    )
}

export default PopperComponent;