import { makeStyles } from "@material-ui/core";
import { Channel } from "../../components/home-component/home.types";
import { LocalContent } from "../../redux/local-content/local-content.types";
import { UserCoordinates, UserLocation } from "../../redux/user/user.types";
import { SubChannel, Thread } from '../channelsSidebar/channelSideBars.types';

export interface LocationTrackerTypes {
  toggleUserLocation: boolean;
  toggleUserLocationPopup: (data: boolean) => void;
  toggleGetUserCurrentLocationAction: (data: UserLocation) => void;
  toggleUserCurrentLocationLoading: (data: boolean) => void;
}

export interface LocalContentProps {
  userCoordinates: UserCoordinates | null;
  localContentData: LocalContent[];
  selectedChannel: Channel;
  selectedSubchannel: SubChannel;
  selectedThread: Thread;
  resetLocalContentAction: () => void;
  setPhotoDetailsState: (item: any) => void;
  redirectToPhotoDetals: (path: string) => void;
  loadedDataAction: (data: LocalContent[]) => void;
}

export const useStyles = makeStyles((theme) => ({
  gridListTileRoot: {
    height: '100% !important'
  },
  gridListRoot: {
    maxHeight: '60vh'
  }
}));

