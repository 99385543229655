import React, { useState, useEffect } from 'react';
import { SearchBarExpandedProps, SearchCategoryTypes, ContentTypesForSearch, SearchFiltersType, SearchBarFiltersProps, ContentFromOptions, composeSearchQueryUrl, ChannelTypes, SearchFieldTypes, useStyles, SearchClearSections } from './searchBar.types';
import { Box, Input, TextField, Divider, Button, Grid, Typography, IconButton, Autocomplete, AutocompleteInputChangeReason, AutocompleteChangeReason } from '@mui/material';
import { Dispatch } from 'redux';
import { IExpandSearchBar, IResetContentFromFilter, IResetDateFilter, IResetFilters, IResetLocationsFilter, IToggleFilterChannel, IToggleFilterContentFrom, IToggleFilterContentType, IToggleFilterSubchannel, IToggleFilterThread, IToggleSearchString, TSearchReducerActions } from '../../redux/search-bar/search-bar.actions';
import { LocationFilterProps, SearchBarActions, UserSource } from '../../redux/search-bar/search-bar.types';
import { connect } from 'react-redux';
import { useLazyQuery } from '@apollo/react-hooks';
import SearchIcon from '@material-ui/icons/Search';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import CloseIcon from '@material-ui/icons/Close';
import SearchBarChannelsNavigation from './searchBarChannelsNavigation.component';
import { CallHistoryMethodAction, push, replace } from 'connected-react-router';
import DateFilterComponent from './dateFilterComponent';
import { StoreState } from '../../redux/root-reducer';
import { selectColourFilter, selectContentFromFilter, selectContentTypeFilter, selectDateFilter, selectLocationFilter, selectSearchString } from '../../redux/search-bar/search-bar.selectors';
import { useTranslation } from 'react-i18next';
import './searchBar.styles.scss';
import { selectChannelFilter, selectSubchannelFilter, selectThreadFilter } from '../../redux/search-bar/search-bar.selectors';
import { Channel } from '../home-component/home.types';
import { SubChannel, Thread } from '../channelsSidebar/channelSideBars.types';
import ContentTypeFilterComponent from './contentTypeFilterComponent';
import LocationFilterComponent from './locationsFilterComponent';
import { SEARCH_USERS } from './queries';
import { compose } from 'lodash/fp';
import { removeSpaces, removeSpecialChars } from '../../components/navigation/helperFunctions';
import { composePathSubChannles, composePathThreads } from '../channelsSidebar/helperFunctions';
import { IAddChannel, IAddSubChannel, IAddThread, TChannelReducerActions } from '../../redux/channel-routes/channel.actions';
import { ChannelActionTypes } from '../../redux/channel-routes/channel.types';
import ContributorTypeFilterComponent from './contributorTypeFilterComponent';

const SearchBarExpanded: React.FC<SearchBarExpandedProps> = ({ ...props }) => {
    const { searchString, contentFrom, contentType, dateFilter, colourFilter, locationFilter,
        channelFilter, subchannelFilter, threadFilter, path, 
        selectChannelAction, selectSubChannelAction, selectThreadAction, toggleSearchStringAction,
        redirectToSearchResultsPage, toggleContentFromFilter, toggleContentTypeFilter,
        resetFiltersAction, toggleSearchBarExpanded, resetContentFromFilterAction, 
        resetDateFilterAction, resetLocationsFilterAction,
        redirectToChannel, redirectToSubChannel, redirectToThread } = props;

    const classes = useStyles();
    const [searchValue, setSearchValue] = useState("");
    const [searchFilters, setSearchFilters] = useState<SearchFiltersType>({
        selectedSearchCategory: SearchCategoryTypes.CONTENT
    });
    const { t } = useTranslation();
    const [selectedUser, setSelectedUser] = useState<string>("");
    const [autoCompleteUserResults, setAutoCompleteUserResults] = useState<UserSource[]>([]);
    const [userSearchString, setUserSearchString] = useState<string>("");

    // QUERIES
    const [searchUsersQuery, { data, loading, error }] = useLazyQuery(SEARCH_USERS);

    useEffect(() => {
        searchString ? setSearchValue(searchString) : setSearchValue("");
    }, [searchString])

    useEffect(() => {
        if(data && data.searchUsers && userSearchString !== "") {
            const userObjects = data.searchUsers.searchEdges.map((hit: any) => {
                return hit.node._source;
            })
            setAutoCompleteUserResults(userObjects);
        }
    }, [data])
    
    const handleSearchStringChange = (event: any) => {
        event.preventDefault();
        const value = event.target.value;

        setSearchValue(value);
    }

    const onKeyPress = (event: any) => {
        if (event.keyCode === 13) {
            search();
        }
    }

    const handleUserSearchChange = (event: any, value: any, reason: AutocompleteInputChangeReason) => {        
        if (reason === "input") {
            setUserSearchString(value);
            searchUsersQuery({variables: {title: value, first: 40, after: ''}});
        }
    }

    const handleAutoCompleteUserSearchChange = (event: any, newValue: any, reason: AutocompleteChangeReason) => {
        if (reason === "selectOption") {
            const user = autoCompleteUserResults.find((result: UserSource) => result.username === newValue);
            if(user) {
                setSelectedUser(user.username);
                toggleContentFromFilter(user.id)
                setUserSearchString("");
            }
        } else if(reason === "clear") {
            toggleContentFromFilter(ContentFromOptions.PRO_CONTRIBUTORS);
            resetContentFromUserFilters();
        }
    }

    // const handleRadioChange = (event: any) => {
    //     event.preventDefault();
    //     let value = event.target.value;
    //     setSearchFilters({ ...searchFilters, selectedSearchCategory: value });
        
    //     if (value === SearchCategoryTypes.MEMBER_PROFILES) {
    //         //searchUsersQuery();
    //     }
    // }

    const resetSearchString = () => {
        setSearchValue("");
        toggleSearchStringAction("");
    }

    const resetContentFromUserFilters = () => {
        setSelectedUser("");
        setAutoCompleteUserResults([]);
        setUserSearchString("");
    }

    const resetAllFilters = () => {
        setSearchFilters({ selectedSearchCategory: SearchCategoryTypes.CONTENT });
        resetFiltersAction();
    }

    const handleClear = (type: string) => {
        if (type === SearchClearSections.DATE_FILTER) {
            resetDateFilterAction();
        }
        else if (type === SearchClearSections.LOCATIONS_FILTER) {
            resetLocationsFilterAction();
        }
        else if (type === SearchClearSections.CONTENT_FROM_FILTER) {
            resetContentFromFilterAction();
            resetContentFromUserFilters();
        }
    }

    const handleToggleButtonChange = (event: any) => {
        event.preventDefault();
        const value = event.currentTarget.value;
        if (contentFrom === value) {
            toggleContentFromFilter(0);
        } else {
            toggleContentFromFilter(value);
        }
    }

    const search = () => {
        toggleSearchBarExpanded();

        if(isInChannelContext()) {
            toggleSearchStringAction(searchValue);
            //when filters are changed from advanced search in /channels url
            if(threadFilter.id) {
                selectSubChannelAction(subchannelFilter);
                selectThreadAction(threadFilter);
                redirectToThread(channelFilter.title, subchannelFilter.title, threadFilter.title);
            } else if(subchannelFilter.id) {
                selectSubChannelAction(subchannelFilter);
                selectThreadAction({id: -1, title: ''});
                redirectToSubChannel(channelFilter.title, subchannelFilter.title);
            } else {
                selectSubChannelAction({id: -1, title: ''});
                selectThreadAction({id: -1, title: ''});
                redirectToChannel(channelFilter.title);
            }
        } else {
            toggleSearchStringAction(searchValue);
            redirectToSearchResultsPage({
                searchString: searchValue,
                searchType: searchFilters.selectedSearchCategory,
                contentFrom,
                contentType,
                locationFilter,
                dateFilter,
                channelFilter,
                subchannelFilter,
                threadFilter
            });
        }
    }

    const isInChannelContext = () => {
        return path.indexOf("/channels") > -1;
    }

    const isInResultsPage = () => {
        return path.indexOf("/results") > -1;
    }

    return (
        <Box>
            <div className="search-bar-container">
                <Box component="form" sx={{display: 'flex', alignItems: 'center', width: '100%' }}>
                    <Input
                        sx={{ ml: 1, flex: 1 }}
                        inputProps={{ 'aria-label': 'search_wil' }}
                        value={searchValue}
                        classes={{underline: classes.inputUnderline}}
                        placeholder={t("Navbar.SearchBar")}
                        onKeyDown={onKeyPress}
                        onChange={handleSearchStringChange}
                        startAdornment={
                            searchValue === "" ?
                            <React.Fragment>
                                <IconButton type="button" sx={{ p: '4px' }} aria-label="search" size="large">
                                    <SearchIcon />
                                </IconButton>
                                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                            </React.Fragment>
                            : null
                        }
                        endAdornment={
                            searchValue === "" ? 
                                <IconButton type="button" onClick={() => toggleSearchBarExpanded()} 
                                    sx={{ p: '10px' }} aria-label="advanced_search_toggle" size="large">
                                    <ExpandLessIcon />
                                </IconButton>
                                :
                                <React.Fragment>
                                    <IconButton type="reset" onClick={resetSearchString} 
                                        sx={{ p: '10px' }} aria-label="reset" size="large">
                                        <CloseIcon />
                                    </IconButton>
                                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                                    <IconButton type="submit" onClick={() => search()} 
                                        sx={{ p: '10px' }} aria-label="search" size="large">
                                        <SearchIcon />
                                    </IconButton>
                                </React.Fragment>
                        }
                    />
                </Box>
            </div>
            <div className='expanded-search-container'>
                <div className='advanced-search-first-column'>
                    {/*TODO: Uncomment when search for users is enabled*/}
                    {/* <div className='radio-buttons-search'>
                        {
                            selectedChannel.id === -1 ?
                                <RadioGroup aria-label="search-categories" className='center-radio-buttons' name="search-categories" row
                                    value={searchFilters.selectedSearchCategory} onChange={handleRadioChange}>
                                    <FormControlLabel value={SearchCategoryTypes.CONTENT} control={<GreenRadio size="small" />}
                                        classes={{ root: classes.formControlLabelRoot }}
                                        label={t("Content.RadioButton")} />
                                    <FormControlLabel value={SearchCategoryTypes.MEMBER_PROFILES} control={<GreenRadio size="small" />}
                                        classes={{ root: classes.formControlLabelRoot }}
                                        label={t("MemberProfiles.Radio.Button")} />
                                </RadioGroup> : null
                        }
                    </div> */}

                        {/* <button placeholder={t("ContentFrom." + ContentFromOptions.ALL_MEMBERS)} 
                            className={contentFrom === ContentFromOptions.ALL_MEMBERS ? 'text-field-content-from-selected' : 'text-field-content-from'} 
                            onClick={handleToggleButtonChange} value={ContentFromOptions.ALL_MEMBERS}
                            >
                            {t("ContentFrom." + ContentFromOptions.ALL_MEMBERS)}
                        </button> */}
                    <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={0.5} sx={{width: '100%'}}>
                        <Grid item xs={3}>
                            <Typography color="textPrimary" align="left" variant="body2">{t("ContentFrom.Field")}</Typography>
                        </Grid>
                        <Grid container direction="row" justifyContent="flex-start" item xs={4}>
                            <ContributorTypeFilterComponent isInChannelContex={true} />
                        </Grid>
                        <Grid item xs={4}>
                            <Autocomplete 
                                id="autocomplete-search-users"
                                value={selectedUser ? selectedUser : ""}
                                options={autoCompleteUserResults.map((result: UserSource) => result.username)}
                                autoHighlight
                                noOptionsText={"No users found"}
                                onInputChange={handleUserSearchChange}
                                onChange={handleAutoCompleteUserSearchChange}
                                renderInput={(params: any) => (
                                    <TextField {...params}
                                        fullWidth
                                        autoComplete="off"
                                        variant='standard'
                                        placeholder={t("ContentFrom.Placeholder")}
                                        name={SearchFieldTypes.CONTENT_FROM_FIELD}/>
                                )}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton size="small" onClick={() => handleClear(SearchClearSections.CONTENT_FROM_FILTER)}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>

                    <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={0.5} sx={{width: '100%'}}>
                        <Grid item xs={3}>
                            <Typography color="textPrimary" align="left" variant="body2">{t("ContentType.Field")}</Typography>
                        </Grid>
                        <Grid container direction="row" justifyContent="flex-start" item xs={4}>
                            <ContentTypeFilterComponent isInChannelContex={true}/>
                        </Grid>
                    </Grid>
                        
                    <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={0.5} sx={{width: '100%'}}>
                        <Grid item xs={3}>
                            <Typography color="textPrimary" align="left" variant="body2">{t("Location.Field")}</Typography>
                        </Grid>
                        <Grid container direction="row" justifyContent="flex-start" item xs={8}>
                            <LocationFilterComponent isInChannelContex={true} />
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton size="small" onClick={() => handleClear(SearchClearSections.LOCATIONS_FILTER)}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>

                    <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={0.5} sx={{width: '100%'}}>
                        <Grid item xs={3}>
                            <Typography color="textPrimary" align="left" variant="body2">{t("Date.Field")}</Typography>
                        </Grid>
                        <Grid container direction="row" justifyContent="flex-start" item xs={8}>
                            <DateFilterComponent isInResults={false} isInChannelContex={true} />
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton size="small" onClick={() => handleClear(SearchClearSections.DATE_FILTER)}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>

                    {/* TODO: Uncomment when functionallity is ready */}
                    {/* <hr/>
                    <div className="input-row">
                        <label className='textfield-label' htmlFor="location-input">{t("Colour.Field")}</label>
                    </div>
                    <ColourFilterComponent/> */}
                </div>
                
                {/* SHOW CHANNELS DEPENDING ON SELECTED FIELD FROM SECOND ROW */}
                <SearchBarChannelsNavigation/>
            </div>
            <div className="expanded-search-button-section">
                {isInChannelContext() || isInResultsPage() ? 
                <Button onClick={() => toggleSearchBarExpanded()} className="clear-button">
                    Close
                    <CloseIcon style={{ marginLeft: '15px' }} />
                </Button>
                : null}
                <Button onClick={resetAllFilters} className="clear-button">
                    {t("ClearAllFilters.Button")}
                    <CloseIcon style={{ marginLeft: '15px' }} />
                </Button>
                <Button variant="contained" className="white-round-button" onClick={search}>
                    {"Search"}
                    <SearchIcon style={{ marginLeft: '15px' }} />
                </Button>
            </div>
        </Box>
    )
}

const mapStateToProps = (state: StoreState): { searchString: string,
    contentFrom: number | ContentFromOptions, contentType: ContentTypesForSearch,
    dateFilter: Date[], colourFilter: any, locationFilter: LocationFilterProps,
    channelFilter: Channel, subchannelFilter: SubChannel, threadFilter: Thread, path: string
} => {
    return {
        searchString: selectSearchString(state),
        contentFrom: selectContentFromFilter(state),
        contentType: selectContentTypeFilter(state),
        dateFilter: selectDateFilter(state),
        colourFilter: selectColourFilter(state),
        locationFilter: selectLocationFilter(state),
        channelFilter: selectChannelFilter(state),
        subchannelFilter: selectSubchannelFilter(state),
        threadFilter: selectThreadFilter(state),
        path: state.router.location.pathname
    }
}

const mapDispatchToProps = (dispatch: Dispatch<TSearchReducerActions | CallHistoryMethodAction | TChannelReducerActions>) => {
    return {
        selectChannelAction: (channel: Channel) => dispatch<IAddChannel>({
            type: ChannelActionTypes.ADD_SELECTED_CHANNEL,
            data: channel
        }),
        selectSubChannelAction: (subChannel: SubChannel) => dispatch<IAddSubChannel>({
            type: ChannelActionTypes.ADD_SELECTED_SUBCHANNEL,
            data: subChannel
        }),
        selectThreadAction: (thread: Thread) => dispatch<IAddThread>({
            type: ChannelActionTypes.ADD_SELECTED_THREAD,
            data: thread
        }),
        toggleSearchStringAction: (data: string) => dispatch<IToggleSearchString>({
            type: SearchBarActions.TOGGLE_SEARCH_STRING,
            data: data
        }),
        toggleContentFromFilter: (data: number | ContentFromOptions) => dispatch<IToggleFilterContentFrom>({
            type: SearchBarActions.TOGGLE_FILTER_CONTENT_FROM,
            data: data
        }),
        toggleContentTypeFilter: (data: ContentTypesForSearch) => dispatch<IToggleFilterContentType>({
            type: SearchBarActions.TOGGLE_FILTER_CONTENT_TYPE,
            data: data
        }),
        redirectToSearchResultsPage: (data: SearchBarFiltersProps) => dispatch(push(composeSearchQueryUrl(data))),
        resetFiltersAction: () => dispatch<IResetFilters>({
            type: SearchBarActions.RESET_FILTERS
        }),
        toggleSearchBarExpanded: () => dispatch<IExpandSearchBar>({ type: SearchBarActions.EXPAND_SEARCH_BAR }),
        resetDateFilterAction: () => dispatch<IResetDateFilter>({ type: SearchBarActions.RESET_DATE_FILTER }),
        resetLocationsFilterAction: () => dispatch<IResetLocationsFilter>({ type: SearchBarActions.RESET_LOCATIONS_FILTER }),
        resetContentFromFilterAction: () => dispatch<IResetContentFromFilter>({ type: SearchBarActions.RESET_CONTENT_FROM_FILTER }),
        redirectToChannel: (channel : string) => dispatch(push(`/channels/${compose(removeSpaces, removeSpecialChars)(channel)}`)),
        redirectToSubChannel: (channel: string, subChannel: string) => dispatch(push(`/channels/${composePathSubChannles(channel, subChannel)}`)),
        redirectToThread: (channel: string, subChannel: string, thread: string) => dispatch(push(`/channels/${composePathThreads(channel, subChannel, thread)}`))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchBarExpanded);