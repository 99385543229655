import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { PersonResponsibleForAccountProps, buttonStyles } from "./onboarding-page.types";
import { TextField, Accordion, AccordionSummary, AccordionActions, Button, Select, MenuItem } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { RoleTypes } from "../register/register.types";
import { useLazyQuery } from "@apollo/react-hooks";
import { OnboardingAccountPersonAllYup } from "./yup-validation-objects";
import { GET_JOB_TITLE_CONTRIBUTER, GET_JOB_TITLE_PROMEMBER } from "./queries";
import { Dispatch } from "redux";
import { IPersonResponsibleForAccount, IToggleMailingAddressAccordion, TOnboardingReducerAction } from "../../redux/onboarding/onboarding.actions";
import { OnboardingActionTypes, PersonResponsibleForAccount } from "../../redux/onboarding/onboarding.types";
import { connect } from "react-redux";
import { StoreState } from "../../redux/root-reducer";
import { selectResponsiblePersonAccordionState, selectResponsiblePersonInfromation } from "../../redux/onboarding/onboarding.selectors";
import './onboarding-page.styles.scss';

const PersonResponsibleForAccountComponent: React.FC<PersonResponsibleForAccountProps> = ({ ...props }) => {
  const { userRole, savePersonResponsibleForAccountInfoAction, toggleMailingAddressAccordionAction,
    responsiblePerson, toggleResponsiblePersonAccordion } = props;
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState({ jobTitle: true});
  const classes = buttonStyles();
  
  const { t } = useTranslation();

  const [dataQuery, setDataQuery] = useState(GET_JOB_TITLE_CONTRIBUTER);
  const [getJobTitles, { data, loading, error }] = useLazyQuery(dataQuery, { fetchPolicy: "no-cache" });
  const [jobTitles, setJobTitles] = useState([]);

  const isPromember = userRole === RoleTypes.Pro_freelancer || userRole === RoleTypes.Pro_organization;
  const isContributor = userRole === RoleTypes.Contributor_agency || userRole === RoleTypes.Contributor_individual;
  const isMember = userRole === RoleTypes.Member;
  const isAgency = userRole === RoleTypes.Contributor_agency;

  useEffect(() => {
    if(toggleResponsiblePersonAccordion) {
      setOpen(true);
      getJobTitles();
    }
  }, [toggleResponsiblePersonAccordion])

  useEffect(() => {
    if (isPromember) {
      setDataQuery(GET_JOB_TITLE_PROMEMBER);
    }
    else if (isContributor) {
      setDataQuery(GET_JOB_TITLE_CONTRIBUTER);
    }
  }, [userRole]);

  useEffect(() => {
    if (dataQuery === GET_JOB_TITLE_PROMEMBER) {
      if (data && data.getJobTitleProMembers) {
        setJobTitles(data.getJobTitleProMembers);
      }
    }
    else if (dataQuery === GET_JOB_TITLE_CONTRIBUTER) {
      if (data && data.getJobTitleContributors) {
        setJobTitles(data.getJobTitleContributors);
      }
    }
  }, [data]);

  useEffect(() => {
    if (isMember || isAgency) {
      setShow({ jobTitle: false });
    }
  }, [userRole]);

  const getValidationSchema = () => {
    if (isMember || isAgency) {
      return OnboardingAccountPersonAllYup.OnboardingAccountPersonMemberYup;
    } else {
      return OnboardingAccountPersonAllYup.OnboardingAccountPersonDefaultYup;
    }
  };

  const handleClick = () => {
    setOpen(!open);
  };

  const validationSchema = getValidationSchema();

  const { handleSubmit, handleChange, values, errors } = useFormik({
    initialValues: {
      firstName: responsiblePerson.firstName ? responsiblePerson.firstName : "",
      lastName: responsiblePerson.lastName ? responsiblePerson.lastName : "",
      jobTitle: "",
      email: responsiblePerson.email ? responsiblePerson.email : "",
      phoneNumber: responsiblePerson.phoneNumber ? responsiblePerson.phoneNumber :  ""
    },
    validateOnBlur: true,
    validationSchema,
    onSubmit(values) {
      const { firstName, lastName, jobTitle, email, phoneNumber } = values;
      savePersonResponsibleForAccountInfoAction({
        firstName: values.firstName,
        lastName: values.lastName,
        jobTitle: values.jobTitle,
        email: values.email,
        phoneNumber: values.phoneNumber
      });
      setOpen(false);
      toggleMailingAddressAccordionAction(true);
    }
  });

  return (
    <form onSubmit={handleSubmit}>
      <Accordion expanded={open}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
          onClick={handleClick}
        >
          <div className="title">{t("Onboarding.AccountPerson.Headline")}</div>
        </AccordionSummary>
        <div className="accordion-container">
          <TextField
            autoComplete="off"
            id="firstName-basic"
            onChange={handleChange}
            name="firstName"
            label={t("Onboarding.FirstName.Label")}
            value={values.firstName}
            helperText={errors.firstName ? errors.firstName : null}
          />
          <TextField
            autoComplete="off"
            id="lastName-basic"
            onChange={handleChange}
            name="lastName"
            label={t("Onboarding.LastName.Label")}
            value={values.lastName}
            helperText={errors.lastName ? errors.lastName : null}
          />
          {show.jobTitle ? (
            <span>
              <Select variant='standard' name="jobTitle" onClick={handleChange} placeholder={t("Onboarding.JobTitle.Label")} className='dropdown-component' defaultValue={responsiblePerson.jobTitle ? responsiblePerson.jobTitle : t("Onboarding.JobTitle.Label")}>
                <MenuItem className='gray-color' value={t("Onboarding.JobTitle.Label")} key='Job title' disabled>{t("Onboarding.JobTitle.Label")}</MenuItem>
                {
                  jobTitles.map((jobTitle: string) => {
                    return <MenuItem value={jobTitle} key={jobTitle}>{jobTitle}</MenuItem>
                  })
                }
              </Select>
              <div className="helper-text-select">
                {errors.jobTitle ? errors.jobTitle : null}
              </div>
            </span>
          ) : null}
          <TextField
            autoComplete="off"
            id="email-basic"
            onChange={handleChange}
            name="email"
            label={t("Onboarding.EmailAddress")}
            value={values.email}
            helperText={errors.email ? errors.email : null}
          />
          <TextField
            autoComplete="off"
            id="phoneNumber-basic"
            onChange={handleChange}
            name="phoneNumber"
            label={t("Onboarding.PhoneNumber")}
            value={values.phoneNumber}
            helperText={errors.phoneNumber ? errors.phoneNumber : null}
          />
        </div>
        <AccordionActions style={{justifyContent: 'center'}}>
          <Button type="submit" classes={{root: classes.greenButtonRoot}}>
            {t("Onboarding.StepOne.Save.button")}
          </Button>
        </AccordionActions>
      </Accordion>
    </form>
  );
};

const mapStateToProps = (state: StoreState): { responsiblePerson: PersonResponsibleForAccount, toggleResponsiblePersonAccordion: boolean } => {
  return {
    responsiblePerson: selectResponsiblePersonInfromation(state),
    toggleResponsiblePersonAccordion: selectResponsiblePersonAccordionState(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch<TOnboardingReducerAction>) => {
  return {
    savePersonResponsibleForAccountInfoAction: (data: PersonResponsibleForAccount) => dispatch<IPersonResponsibleForAccount>({ type: OnboardingActionTypes.PERSON_RESPONSIBLE_FOR_ACCOUNT, data: data }),
    toggleMailingAddressAccordionAction: (data: boolean) => dispatch<IToggleMailingAddressAccordion>({ type: OnboardingActionTypes.TOGGLE_MAILING_ADDRESS, data: data})
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonResponsibleForAccountComponent);
