import gql from 'graphql-tag';

export const GET_SUBCHANNELS = gql`
query getAllSubchannelsByChannelIdQuery ($id: Int!) {
  getAllSubChannelsByChannelId(id: $id) {
      id
      title
      channelId
  }
}
`;

export const GET_THREADS = gql`
    query getAllThreadsBySubchannelIdQuery ($id: Int!) {
        getAllThreadsBySubChannelId(id: $id) {
            id
            title
            subChannelId
        }
    }
`;

export const GET_THREADS_CONTENT_COUNT = gql`
query getEveryThreadContentCountinSubchannelMutation($id: Float!) {
  getEveryThreadContentCountinSubchannel(id: $id) {
    id
    count
    title
  }
}
`;

