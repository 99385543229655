import React, { useEffect, useState } from 'react';
import { Typography, Container, Button, CircularProgress } from '@material-ui/core';
import { StoreState } from '../../redux/root-reducer';
import { User, UserActionTypes } from '../../redux/user/user.types';
import { selectCurrentUser } from '../../redux/user/user.selectors';
import { IBroadcastMessage, SeveritySnackbarEnum, UploadViaSFTPProps } from './ContentUpload.types';
import { REQUEST_SFTP_ACCESS, SFTP_INFO } from './queries';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IBroadcastContentUploadMessage, TContentUploadActions } from '../../redux/content-upload/content-upload.actions';
import { ContentUploadActionTypes } from '../../redux/content-upload/content-upload.types';
import { ISetSFTPAccessForAccount, TUserReducerActions } from '../../redux/user/user.actions';
import { useTranslation } from 'react-i18next';

export const UploadViaSFTP: React.FC<UploadViaSFTPProps> = ({ ...props }) => {
    const { currentUser, broadcastUploadErrorAction, setSFTPAccessForAccountAction } = props;
    const { data, loading, error } = useQuery(SFTP_INFO, {fetchPolicy: 'no-cache'});
    const [requestSFTPAccessMutation] = useMutation(REQUEST_SFTP_ACCESS);
    const [sftpUser, setSFTPUser] = useState('');
    const [requestingSFTPAccess, setRequestingSFTPAccess] = useState(false);
    const { t } = useTranslation();
    const [sftpRequestSent,setSFTPRequestSent] = useState<boolean>(false);

    const requestSFTPAccess = (userId: number): void => {
        setRequestingSFTPAccess(true);
        requestSFTPAccessMutation({
            variables: { id: userId }
        }).then((result: any) => {
            setSFTPRequestSent(true)
            broadcastUploadErrorAction({
                severity: SeveritySnackbarEnum.success,
                message: t("FTP.Request.Sent.Success")
            });
            setRequestingSFTPAccess(false);
        }).catch((error: any) => {
            broadcastUploadErrorAction({
                severity: SeveritySnackbarEnum.error,
                message: t("FTP.Request.Sent.Fail")
            });
            setRequestingSFTPAccess(false);
        })
    }

    useEffect(() => {
        if (data && data.sftpInfo.hasSFTPAccess) {
            setSFTPUser(`${currentUser.username}_sftp`);
            setSFTPAccessForAccountAction(true);
        } else {
            setSFTPUser('');
            setSFTPAccessForAccountAction(false);
        }
        if(data && data.sftpInfo.sftpAccessRequest){
            setSFTPRequestSent(true)
        }
        else{
            setSFTPRequestSent(false)
        }
    }, [data])

    const handleGenerateSFTPUser = (userId: number | undefined) => {
        if (userId) {
            requestSFTPAccess(userId);
        } else {
            broadcastUploadErrorAction({
                severity: SeveritySnackbarEnum.error,
                message: t("UserId.Fail")
            });
        }
    }

    return (
        <Container maxWidth="md">
            <Typography variant="h4" align="center" component="h3" gutterBottom>
                {t("Typography.FTP.Upload")}
            </Typography>
            <Typography variant="body2" align="center" paragraph>
                {t("FTP.Upload.paragraph")}
                <br />
            </Typography>
            <Typography variant="body2" align="center" gutterBottom>
                {t("FTP.Upload.gutterBottom")}
                <br />
                {t("FTP.Upload.Request")} <b>sftp.worldillustrated.com</b>
                <br />
                {t("FTP.Upload.Username")}
                <b>
                    {sftpUser ? sftpUser : t("FTP.No.Account")}
                </b>

                {/*<br/>
                 
            - your private SSH key - a special file that serves as the password to your account that 
            you will receive as an attachment to the email when your user was successfully generated  
            */}
                <br />

                {!sftpUser ?
                    <div>
                        <br />
                        {t("Warning.Generate.FTPAccount")}
                    </div> : <div></div>}

            </Typography>

            {sftpUser ?

                <Typography style={{textAlign: 'center'}} variant="subtitle1" color="error" align="left" paragraph>
                    {t("FTP.Upload.Active.Account")} {sftpUser}
                </Typography>

                :
                <Button style={(currentUser.sftpAccessRequest || sftpRequestSent) ? {backgroundColor: '#bfbfbf',color: 'white', margin: '1rem 0'} : {backgroundColor: '#0caf95', margin: '1rem 0', color: 'white'}} disabled={currentUser.sftpAccessRequest || sftpRequestSent || requestingSFTPAccess || loading} variant="contained" onClick={() => handleGenerateSFTPUser(currentUser.id)}>{ requestingSFTPAccess || loading ? <CircularProgress style={{color: 'white'}} size={20}/> : currentUser.sftpAccessRequest || sftpRequestSent ? t("RequestSent.FTPUser.Button") : t("Request.FTPUser.Button")}</Button>
            }
        </Container>
    )
}

const mapStateToProps = (state: StoreState): { currentUser: User } => {
    return {
        currentUser: selectCurrentUser(state)
    }
}

const mapDispatchToProps = (dispatch: Dispatch<TContentUploadActions | TUserReducerActions>) => {
    return {
        broadcastUploadErrorAction: (data: IBroadcastMessage) => dispatch<IBroadcastContentUploadMessage>({
            type: ContentUploadActionTypes.BROADCAST_MESSAGE, data: data
        }),
        setSFTPAccessForAccountAction: (data: boolean) => dispatch<ISetSFTPAccessForAccount>({
            type: UserActionTypes.SET_SFTP_ACCESS,
            data: data
        })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadViaSFTP);