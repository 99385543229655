import { Channel } from "../home-component/home.types";
import { History } from "history";
import { User as ReduxUser} from '../../redux/user/user.types'
import { IBroadcastMessage } from "../batch-upload/ContentUpload.types";

export interface MyDownloadsProps {
    currentUser: ReduxUser;
    updateError: IBroadcastMessage;
    history: History;
    contentStatusFilter: string;
    contentTypeFilter: string;
    dataTableRefreshState: boolean;
    fetchError: IBroadcastMessage;
    downloadingContents: any;
    datePurchased: string[];
    selectedSize: string;
    toggleUploadDateFilterAction: (data: Date[]) => void;
    resetTableFilterAction: () => void;
    toggleDataTableRefreshState: (data: boolean) => void;
    broadcastError: (data: IBroadcastMessage) => void;
    clearBroadcastErrorAction: () => void;
    toggleDownloadingContents: (date: any) => void;
    toggleDatePurchased: (date: any) => void;
    toggleSize: (date: any) => void;
}

export interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

export interface PreferredCurrencyProps { 
    currentUser: ReduxUser;
    broadcastCurrencyUpdateAction: (data: IBroadcastMessage) => void;
}

export interface UserUploadedContent {
    contentSetId: number;
    articleId: number;
    audioId: number;
    contentNavigations: ContentNavigation[];
    content: Content;
    hash: string;
    publishDate: Date;
    uploadDate: Date;
    type: string;
    title: string;
    status: string;
    location: Location;
    contributor: User;
}
export interface User {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    username?:string;
    role?: string;
    subscriptions?: any;
    organizationName?: any;
}
export interface ContentNavigation {
    channel: Channel;
    subChannel: SubChannel;
    thread: Thread;
}

export interface SubChannel extends Channel {
    channelId: number;
}

export interface Thread extends Channel {
    subChannelId: number;
    status: string;
    hasContent : boolean;
}
export interface Country {
    country: string;
}

export interface Province {
    stateProvince: string;
}

export interface City {
    city: string;
}
export interface Location {
    country: Country;
    province: Province;
    city: City;
}
export interface Content {
    id: number;
    headline: string;
    type: string;
    status: string;
    publishDate: Date;
    externalLink: string;
    externalLinkMessage: string;
    caption: string;
    pathToFileCompressed: string;
    pathToFileThumbnail: string;
    code: string;
}

export interface Currency {
    id: number;
    value: string;
    symbol: string;
}
export enum CurrencyType {
    usd = '$',
    eur = '€',
    gbp = '£',
}

export enum ContentStatus {
    INCOMPLETE = "incomplete",
    PUBLISHED = "approved",
    PENDING_APPROVAL = "pending",
    DENIED = "rejected",
}

export enum LicenseOrigins{
    STANDARD = 'standard',
    CREDIT = 'credit',
    SUBSCRIPTION = 'subscription',
}