import React, { useEffect, useState } from "react";
import { PhotoContainerProps } from "./PhotoContainer.types";
import "./photoContainer.scss";
import { connect, useDispatch } from "react-redux";
import { User } from "../../../redux/user/user.types";
import { selectCurrentUser } from "../../../redux/user/user.selectors";
import { StoreState } from "../../../redux/root-reducer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faCartPlus, faDownload } from "@fortawesome/free-solid-svg-icons";
import { config } from "../../../config";
import { Dispatch } from "redux";
import {
  IBroadcastMessages,
  IClearBroadcastMessage,
  IToggleClickedImageToAddToLightBox,
  TPhotoDetailsActions,
} from "../../../redux/photo-details/photo-details.actions";
import { PhotoDetailsActionTypes } from "../../../redux/photo-details/photo-details.types";
import { selectBroadcastMessage, selectClickedImageToAddToLightBox } from "../../../redux/photo-details/photo-details.selector";
import { useLazyQuery } from "react-apollo";
import {
  CHECK_IS_CONTENT_OWNED,
  GET_CONTENT_INFO,
  GET_LICENSE_PLAN_BY_USER_ID_AND_TYPE,
} from "../queries";
import AddToCartButton from "../photo-details-add-to-cart-button.component";
import { checkIfPlanHasAvailableAmount } from "../photo-details-helper-functions";
import {
  selectCurrentCartItems,
  selectCurrentSubItems,
} from "../../../redux/cart/cart-page.selector";
import { EditorialSizes, ItemForCart } from "../photo-details.types";
import {
  IAddToCart,
  IAddToSubscriptionCart,
  TCartReducerActions,
} from "../../../redux/cart/cart-page.actions";
import { CartActionTypes } from "../../../redux/cart/cart-page.types";
import { EditorialPriceBox, checkEnumVal } from "../photo-details-containers";
import { SnackbarComponent } from "shared";
import { Dialog, DialogContent, DialogTitle, IconButton, TextField } from "@mui/material";
import SyncLoader from 'react-spinners/SyncLoader';
import CloseIcon from '@material-ui/icons/Close';
import "../photo-details.styles.scss";
import EditorialPriceBoxContent from "./editorialPriceBoxContent";
import { downloadContent } from "../../utils/download";
import { IBroadcastMessage } from "../../../components/batch-upload/ContentUpload.types";
import { SeveritySnackbarEnum } from "../../../components/batch-upload/ContentUpload.types";
import { useTranslation } from "react-i18next";

const PhotoContainer: React.FC<PhotoContainerProps> = ({ ...props }) => {
  const {
    src,
    alt,
    showBorder,
    content,
    currentSubItems,
    currentCartItems,
    toggleClickedImageToAddToLightBox,
    handleShowAddToLightBoxModal,
    addToSubscriptionCartAction,
    broadcastMessageAction,
    addToCartAction,
    onClick,
    currentUser,
    colors,
    contentId,
    showAddToLightBoxButton = false,
    showAddToCartButton = false,
  } = props;
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isValidSrc, setIsValidSrc] = useState(!!src);
  const [hovering, setHovering] = useState(false);
  const [clickedImageToPurchase, setClickedImageToPurchase] = useState<any>();
  const [licensingPlan, setLicensingPlan] = useState<any>([]);
  const [isPartOfPlan, setIsPartOfPlan] = useState<boolean>(false);
  const [showAddToCartSnackbar, setShowAddToCartSnackbar] = useState(false);
  const [contentLicenseType, setContentLicenseType] = useState("");
  const [showEditorialModal, setShowEditorialModal] = useState(false);
  const {t} = useTranslation()
  const [
    fetchUserLicensePlans,
    { data: licensePlan, loading: licensePlanLoading, error: licensePlanError },
  ] = useLazyQuery(GET_LICENSE_PLAN_BY_USER_ID_AND_TYPE, {
    fetchPolicy: "cache-and-network",
    onCompleted: (res: any)=>{
      setLicensingPlan(res.getPlanByIdAndType);
    }
  });
  
  const handleDownloadError = ()=>{
    broadcastMessageAction({ severity: SeveritySnackbarEnum.error, message: t("Error.Download.Content") })
  }

  const [checkIfContentIsOwned, { data: checkIfOwnedData, loading: checkIfOwnedLoading, error: checkIfOwnedError }] = useLazyQuery(CHECK_IS_CONTENT_OWNED,
    {  
      fetchPolicy: "cache-and-network",
      onCompleted: (res: any)=>{
        if(res.checkIfContentIsOwned.isOwned){
         if(currentUser?.token){
          downloadContent({purchasedImages: [clickedImageToPurchase?.content?.id],token: currentUser?.token, fileName: clickedImageToPurchase?.content?.headline, onError: handleDownloadError})
         }
        }
        else{
          fetchUserLicensePlans({
            variables: {
              userId: currentUser?.id,
              licensingType: clickedImageToPurchase?.content?.licensingType,
            },
          });
        }
      }
    });

  const [fetchPhotoDetails, { data, loading, error, refetch }] = useLazyQuery(
    GET_CONTENT_INFO,
    { 
      fetchPolicy: "no-cache",
      onCompleted: (res: any)=>{
        setClickedImageToPurchase(res.getFullContentInfo);
        setContentLicenseType(res.getFullContentInfo?.content?.licensingType);
        if(res.getFullContentInfo?.content?.licensingType === 'editorial'){
          setShowEditorialModal(true);
        }
        else{
          checkIfContentIsOwned({
            variables: {
              contentId: res.getFullContentInfo?.content?.id,
              userId: currentUser?.id,
            }
          })
        }
      }
    }
  );

  const handleOnHover = () => {
    setHovering(true);
  };

  const handleMouseLeave = () => {
    setHovering(false);
  };
  const handleSetImageAction =  () => {
    if (currentUser) {
      fetchPhotoDetails({
        variables: {
          contentId,
          userId: currentUser?.id,
        },
      });
    }
  };

  const handleClose = () => {
    setShowAddToCartSnackbar(false);
  };


  const handleCloseEditorialPriceModal = () => {
    setShowEditorialModal(false);
  };

  
  const userIsAllowedToPurchase = (): boolean => {
    if (currentUser?.id) {
      return (
        currentUser?.role === "pro_freelancer" ||
        currentUser?.role === "pro_organization"
      );
    }
    return false;
  };

  useEffect(()=>{
    if(clickedImageToPurchase){
      if(clickedImageToPurchase?.content?.licensingType === 'editorial' && clickedImageToPurchase?.content?.id){
        setShowEditorialModal(true);
      }
      else{
        setShowEditorialModal(false);
      }
    }
  },[clickedImageToPurchase])

  useEffect(() => {
    if (licensePlan && clickedImageToPurchase && currentUser && contentLicenseType !== 'editorial') {
      const contentToAdd: ItemForCart = {
        content: {
          ...clickedImageToPurchase.content,
          amount:
            licensingPlan.length > 0 ? licensingPlan[0].plan.amount : undefined,
          totalPrice:
            licensingPlan.length > 0
              ? licensingPlan[0].plan.totalPrice
              : undefined,
          period:
            licensingPlan.length > 0 && licensingPlan[0].plan.period
              ? licensingPlan[0].plan.period
              : undefined,
          role: currentUser.role,
        },
        // editorialSize: editorialSize,
        flatRate: clickedImageToPurchase.contentRate.flatRate,
      };

      if (
        clickedImageToPurchase &&
        licensingPlan.length > 0 &&
        checkIfPlanHasAvailableAmount(
          currentSubItems,
          licensingPlan[0].amountLeft!,
          licensingPlan[0].plan.licensingType!
        )
      ) {
        addToSubscriptionCartAction(contentToAdd);
        setShowAddToCartSnackbar(true); 
        setIsPartOfPlan(true);
      } else {
        addToCartAction(contentToAdd);
        setShowAddToCartSnackbar(true);
        setIsPartOfPlan(false);
      }
    }
  }, [licensingPlan]);

  return (
    <div
      className="image-box"
      style={
        showBorder && imageLoaded ? { border: "1rem solid " + colors } : {}
      }
    >
      {isValidSrc ? (
        <img
          src={src}
          alt={alt}
          className={`smooth-image image-${imageLoaded ? "visible" : "hidden"}`}
          onLoad={() => setImageLoaded(true)}
          onClick={onClick}
          onError={() => setIsValidSrc(false)}
        />
      ) : (
        <img
          src={`${config.REACT_APP_CLOUDFRONT_PATH}/assets/default_image.png`}
          alt={"no-picture-available"}
          className={`smooth-no-image`}
        />
      )}

      {currentUser?.id && showAddToLightBoxButton && imageLoaded && (
        <button
          className="add-to-light-box"
          style={{ backgroundColor: colors }}
          onClick={(e: any) => {
            toggleClickedImageToAddToLightBox(contentId || 0);
            handleShowAddToLightBoxModal();
          }}
        >
          <FontAwesomeIcon
            className="icon-button"
            style={{ backgroundColor: colors, marginRight: "0.2rem" }}
            icon={faAdd}
          />
          Add to light box
        </button>
      )}

      {userIsAllowedToPurchase() && showAddToCartButton && !content?.content?.isOwned && (
        <button
          className={`add-to-cart-icon-button`}
          style={{ backgroundColor: colors }}
          onClick={(e: any) => {
            handleSetImageAction();
          }}
        >
          <FontAwesomeIcon
            className="icon-button"
            style={{ backgroundColor: colors, marginRight: "0.2rem" }}
            icon={faCartPlus}
          />
        </button>
      )}
      {userIsAllowedToPurchase() && !showAddToCartButton && content?.content?.isOwned && (
        <button
          className={`add-to-cart-icon-button`}
          style={{ backgroundColor: colors }}
          onClick={(e: any) => {
            handleSetImageAction();
          }}
        >
          <FontAwesomeIcon
            className="icon-button"
            style={{ backgroundColor: colors, marginRight: "0.2rem" }}
            icon={faDownload}
          />
        </button>
      )}
      <SnackbarComponent
        showSnackbar={showAddToCartSnackbar}
        handleClose={handleClose}
        severity="success"
        message="Added to cart"
      />
      
      <Dialog open={showEditorialModal} closeAfterTransition={true}
         aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description">
          <DialogTitle>
            <b>Select sizes</b>
          <IconButton
            aria-label="close"
            onClick={handleCloseEditorialPriceModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          </DialogTitle>
          <DialogContent>
            <EditorialPriceBoxContent contentInfo={clickedImageToPurchase?.content}/>
          </DialogContent>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (
  state: StoreState
): {
  currentUser: User;
  clickedImageToAddToLightBox: number;
  currentSubItems: ItemForCart[];
  currentCartItems: ItemForCart[];
  broadcastMessage: IBroadcastMessage;
} => {
  return {
    currentUser: selectCurrentUser(state),
    clickedImageToAddToLightBox: selectClickedImageToAddToLightBox(state),
    currentSubItems: selectCurrentSubItems(state),
    currentCartItems: selectCurrentCartItems(state),
    broadcastMessage: selectBroadcastMessage(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<TPhotoDetailsActions | TCartReducerActions>
) => {
  return {
    toggleClickedImageToAddToLightBox: (id: number) =>
      dispatch<IToggleClickedImageToAddToLightBox>({
        type: PhotoDetailsActionTypes.SET_CLICKED_IMAGE_TO_ADD_TO_CLICK_BOX,
        data: id,
      }),
    addToCartAction: (item: ItemForCart) =>
      dispatch<IAddToCart>({ type: CartActionTypes.ADD_TO_CART, data: item }),
    addToSubscriptionCartAction: (item: ItemForCart) =>
      dispatch<IAddToSubscriptionCart>({
        type: CartActionTypes.ADD_TO_SUBSCRIPTION_CART,
        data: item,
      }),
    broadcastMessageAction: (data: IBroadcastMessage) =>
    dispatch<IBroadcastMessages>({
      type: PhotoDetailsActionTypes.BROADCAST_MESSAGE,
      data: data,
    }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PhotoContainer);
