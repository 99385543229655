export const translationsBG = {
  "Register.title": "Регистрация",
  "Register.button": "Регистрация",
  "Login.title": "Вход",
  "Login.button": "Вход",
  "Login.Banner.Message" : "Влезте",
  "Home": "Начален екран",
  "Channel.Military": "Военни",
  "Channel.Our planet": "Нашата планета",
  "Channel.Arts & Culture":"Изкуства и Култура",
  "Channel.Style":"Мода",
  "Channel.Music":"Музика",
  "Channel.Hobbies":"Хоби",
  "Channel.News":"Новини",
  "Channel.Famous":"Популярни",
  "Channel.Sport":"Спорт",
  "Channel.Health":"Здраве",
  "Channel.Food & Drink":"Храни и Напитки",
  "Channel.Tech & Science": "Наука и Технологии",
  "Channel.Junior":"Младежки",
  "Channel.Community":"Общност",
  "Channel.Transport":"Транспорт",
  "UserNav.Uploaded.Content":"Качено съдържание",
  "UserNav.Logout": "Изход",
  "Display.CurrenUser.Email":"Потребител: ",
  "Navbar.Home.Button":"Начало",
  "Navbar.SearchBar" : "Търсене",
  "SignIn.noAccount":"Все още нямате акаунт?",
  "Login.Banner.SignIn.Message":"в world illustrated, за да имате достъп до членските функции",
  "Login.Banner.Register.Message":"Споделяйте съдъжанието си и използвайте членските функции",
  "Login.Banner.Free":"напълно безплатно",
  "Content.Upload.Preview":"Преглед",
  "Contet.Upload.Code":"Код",
  "Content.Upload.Headline":"Заглавие",
  "Content.Upload.Type":"Тип",
  "Content.Upload.Status":"Статус",
  "Content.Upload.UploadedOn":"Качено на",
  "Content.Upload.PublishedOn":"Публикувано на",
  "Content.Upload.Location":"Локация",
  "Content.Upload.Navigation":"Навигация",
  "Content.Upload.ExternalLink": "Линк",
  "Conten.Upload.AddMore":"Добавяне +",
  "Uploaded.Content.Title":"Качено съдържание",
  "Upload.Public.Content.Info":"Качете като публично съдържание",
  "Upload.Content.Description.Text":"Каченото съдържание ще бъде публикувано след одобрение от администратора. След това ще бъде видимо за всеки, който използва сайта. Можете да разгледате ръководството по-долу за повече информация относно правилата и изискванията за одобрение на съдържанието",
  "Download.Guide":"Изтегляне на ръководство за качване",
  "Download.Browser":"Качване чрез браузър",
  "Download.sFTP":"Качване чрез sFTP",
  "Individual.Items":"Един елемент",
  "Content.Set":"Набор от елементи",
  "Back.Button":"Назад",
  "Complete.Button":"Завърши",
  "NextStep.Button":"Напред",
  "LoggedIn.Error":"Влезте в профила си за да качите съдържание",
  "Click.Drag.Label":"Щракнете или плъзнете тук",
  "Enter.Channel":"Канал:",
  "Enter.Subchannel":"Подканал:",
  "Enter.Thread":"Тема:",
  "Category.Label":"Категория (за набор от съдържание)",
  "AddToAnother.Button":"Добави към друг",
  "Save.Changes.Button":"Запази",
  "Main.Information.Label":"Основна информация",
  "ObjectName.Input.Label":"Име на обект:",
  "Headline.Input.Label":"Заглавие:",
  "Caption.Input.Label":"Надпис:",
  "ExternalLink.Input.Label":"Линк:",
  "Location.Label":"Локация",
  "Country.Input.Label":"Държава:",
  "Province.Input.Label":"Област",
  "City.Input.Label":"Град:",
  "CountryCode.Input.Label":"Код на държавата:",
  "GPSLatitude.Input.Label":"GPS ширина:",
  "GPSLongitude.Input.Label":"GPS дължина:",
  "License.Label":"Лиценз",
  "LicenseType.Input.Label":"Тип:",
  "Editorial.LicenseType.Menu.Item":"Редакционен",
  "Premium.LicenseType.Menu.Item":"Премиен",
  "Microstock.LicenseType.Menu.Item":"Микростока",
  "MemberOnly.LicenseType.Menu.Item":"Членски",
  "Keyword.Label":"Ключови думи",
  "Keywords.Add.Button":"Добави",
  "Copyright.Label":"Авторско право",
  "By-line.Input.Label":"Автор:",
  "Credit.Input.Label":"Права:",
  "CopyrightNotice.Input.Label":"Известие:",
  "SpecialInstructions.Input.Label":"Специални инструкции:",
  "Source.Label":"Източник",
  "Source.Input.Label":"Източник:",
  "CreatedDate.Input.Label":"Дата на създаване:",
  "CreatedTime.Input.Label":"Час на създаване:",
  "UploadedDate.Input.Label":"Дата на качване:",
  "OriginalFilename.Input.Label":"Оригинално име на файл:",
  "File.Label":"Файл",
  "Filename.Input.Label":"Име на файл:",
  "Filesize.Input.Label":"Размер на файл:",
  "Filetype.Input.Label":"Тип на файл:",
  "ImageWidth.Input.Label":"Ширина на снимка:",
  "ImageHeight.Input.Label":"Дължина на снимка:",
  "CameraModel.Input.Label":"Модел на камерата:",
  "Select.As.Cover.Button":"Добави като корица:",
  "Remove.Content.Set.Button":"Премахни набора от елемнти",
  "Uploaded.Files.Error.Message":"Качените файлове трябва да бъдат подредени в набори от съдържание, преди да преминете към следващата стъпка",
  "Add.Another.Set.Button":"Добави нов сет",
  "Move.To.Set.Button":"Премести в сет",
  "Step.SFTPStep":"Качване с sFTP",
  "Step.PickItems":"Избери елемент",
  "Step.ManageContent":"Управление на съдържанието",
  "Step.AddArticle":"Добави статия(по избор)",
  "Step.ReviewData":"Преглед на данните",
  "Add.Keywords.Placeholder":"Добави ключови думи...",
  "Filters.Date.To":'До',
  "Filters.Date.From":'От'
};