export enum ErrorPagesTypes {
    ToggleNoAccessPage= "TOGGLE_NO_ACCESS_PAGE",
    ToggleWrongPathPage = "TOGGLE_WRONG_PATH_PAGE",
    ToggleNotLoggedPage = "TOGGLE_NOT_LOGGED__PAGE",
    ResetTogglesPage = "RESET_TOGGLES_PAGE"
}

export interface PageState {
    toggleNoAccessPage: boolean;
    toggleWrongPathPage: boolean;
    toggleNotLoggedPage: boolean;
}