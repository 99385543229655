import { IBroadcastMessage } from "../batch-upload/ContentUpload.types";
import { LicensePlan } from "../photo-details/photo-details.types";
import { Addresses } from "../../redux/onboarding/onboarding.types";
import { User } from "../../redux/user/user.types";
import { TaxForOrder } from "../../redux/cart/cart-page.types";

export interface MyLicensingPlanPageProps {
  currentUser: User;
  redirectToGetPlansPage: () => void;
  removeSubscriptions: () => void;
}
export enum LicenseBuyingSteps {
  SELECT_PLAN = "Get.License.Plans.Select.Plan.Step.Label",
  PAYMENT = "Get.License.Plans.Payments.Step.Label",
  COMPLETE = "Get.License.Plans.Complete.Step.Label"
}

export interface LicensePaymentPageProps {
  subscriptionBroadcast: IBroadcastMessage;
  redirectToLicensePage: () => void;
  broadcastSubscriptionAction: (data: IBroadcastMessage) => void;
  clearBroadcast: () => void;
}

export enum planPeriods {
  MONTHLY = "My.License.Page.Plan.Periods.Monthly",
  YEARLY = "My.License.Page.Plan.Periods.Yearly"
}


export interface GetLicensingPlanPageProps {
  currentUser: User;
  addSubscription: (item: LicensePlan) => void;
  handlePageChange: () => void;
}

export interface SubscriptionPaymentProps {
  currentUser: User;
  planToPurchase: LicensePlan;
  total: number;
  tax: TaxForOrder | null;
  additionalBillingAddress: Addresses | null;
  removeSubscriptions: () => void;
  handlePageChange: () => void;
  broadcastSubscriptionAction: (data: IBroadcastMessage) => void;
  addTaxAction: (data: TaxForOrder) => void;
}

export interface Header {
  headerId: string;
  headerContentType: string;
  headerPlanType: string;
  headerAmount: string;
  headerPeriod: string;
  headerPlanPrice: string;
  expDate?: string;
  headerGet?: string;
}

export interface GetLicensingPlanPageRowProps {
  tableItems?: LicensePlan;
  isHeader: boolean;
  header?: Header;
  getPlan?: (plan: LicensePlan) => void;
}

export interface MyLicensingPlanPageRowProps {
  tableItems?: UserLicensingPlan;
  isHeader: boolean;
  header?: Header;
}

export interface UserLicensingPlan {
  id: number;
  amountLeft: number;
  purchaseDate: Date;
  expirationDate: Date;
  plan: LicensePlan;
}


export interface LicenseSummaryProps {
  planToPurchase: LicensePlan;
  loading: boolean;
  subtotal: number;
  total: number;
  tax: TaxForOrder | null;
}