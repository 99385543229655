export enum UserActionTypes {
    Login = "LOGIN_START",
    LoginSuccess = "LOGIN_SUCCESS",
    LoginError = "LOGIN_FAILED",
    RegisterUser = "REGISTER_START",
    RegisterUserSuccess = "REGISTER_SUCCESS",
    RegisterUserError = "REGISTER_FAILED",
    LogoutUser = "LOGOUT_START",
    LogoutUserSuccess = "LOGOUT_SUCCESS",
    LogoutUserError = "LOGOUT_FAILED",
    LoginThirdParty = "THIRDPARTY_SUCCESS",
    LoginThirdPartyError = "THIRDPARTY_FAILED",
    SET_SFTP_ACCESS = "SET_SFTP_ACCESS",
    GET_USER_CURRENT_LOCATION ="GET_USER_CURRENT_LOCATION",
    TOGGLE_USER_CURRENT_LOCATION = "TOGGLE_USER_CURRENT_LOCATION",
    TOGGLE_DATA_TABLE_REFRESH_STATE = "TOGGLE_DATA_TABLE_REFRESH_STATE",
    SET_COMPLETED_ONBOARDING = "SET_COMPLETED_ONBOARDING",
    SET_USER_PROFILE_IMAGE = "SET_USER_PROFILE_IMAGE",
    TOGGLE_USER_CURRENT_LOCATION_LOADING = "TOGGLE_USER_CURRENT_LOCATION_LOADING",
    TOGGLE_LOADING_SEARCH_RESULTS = "TOGGLE_LOADING_SEARCH_RESULTS"
}

export interface RegisterState {
    username: string;
    email: string;
    password: string;
    confirmPassword: string;
    agreedWithTerms: boolean;
}

export interface LoginState {
    email: string;
    password?: string;
}

export interface UserState {
    awaitingAuthentication: boolean;
    authenticated: boolean;
    registeredUser: boolean;
    currentUser: User;
    userHasSFTPAccess: boolean;
    userLocation: UserLocation;
    toggleUserLocation: boolean;
    dataTableRefreshState: boolean;
    profileImageUrl: string;
    userCurrentLocationLoading: boolean;
    loadingSearchResults: boolean;
}

export interface UserLocation {
    gavePermission: boolean;
    userCoordinates: UserCoordinates | null;
}

export interface UserCoordinates {
    longitude: number;
    latitude: number;
}
export interface Person {
    firstName: string;
    lastName: string;
}

export interface Company {
    id: number;
    name: string;
}

export interface User {
    id?: number,
    email: string;
    username: string;
    role?: string;
    hasPublicSFTPAccess?: boolean;
    sftpAccessRequest?: boolean;
    subscriptions?: any;
    licensePlanId?: number;
    uploadFromOtherSources?: boolean;
    preferredCurrency?: string;
    person?: Person;
    company?: Company;
    hasCompletedOnboarding?: boolean;
    logInTime?: Date;
    logInMaxAge?: number;
    token?: string;
}