import React, { useState, useEffect } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { Button, Paper, Popper, MenuList, ClickAwayListener, TextField, ButtonGroup, Grow } from '@mui/material';
import { DateFilterComponentProps, SearchFieldTypes } from './searchBar.types';
import { Dispatch } from 'redux';
import { SearchBarActions } from '../../redux/search-bar/search-bar.types';
import { IResetDateFilter, IToggleFilterDate, TSearchReducerActions } from '../../redux/search-bar/search-bar.actions';
import { connect } from 'react-redux';
import './searchResultsFilters.styles.scss';
import { StoreState } from '../../redux/root-reducer';
import { useTranslation } from 'react-i18next';
import { selectDateFilter } from '../../redux/search-bar/search-bar.selectors';
import DateRangePicker, { DateRange } from '@mui/lab/DateRangePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import engbLocale from 'date-fns/locale/en-GB';
import enusLocale from 'date-fns/locale/en-US';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

const DateFilterComponent: React.FC<DateFilterComponentProps> = ({ ...props }) => {
    const { dateFilter, toggleDateFilter, isInResults, resetDateFilterAction, isInChannelContex } = props;
    const [open, setOpen] = useState(false);
    const [openYearPicker, setOpenYearPicker] = useState(false);
    const [openDecadePicker, setOpenDecadePicker] = useState(false);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [validationMessage, setValidationMessage] = useState("");
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [isSelected, setIsSelected] = useState(false);
    const [year, setYear] = useState<Date | null>(null);
    //const [decade, setDecade] = useState<number>();
    const { t } = useTranslation();

    const isDateValid = dateFilter.length === 2;

    const localeMap = {
        us: enusLocale,
        en: engbLocale,
    };
      
    const maskMap = {
        us: '__/__/____',
        en: '__/__/____',
    };

    const [locale, setLocale] = React.useState<keyof typeof maskMap>('en');

    const selectLocale = (newLocale: any) => {
        setLocale(newLocale);
    };

    useEffect(() => {
        if (isDateValid) {
            setStartDate(dateFilter[0]);
            setEndDate(dateFilter[1]);
        }
    }, [dateFilter]);

    useEffect(() => {
        if(startDate && !endDate && !isSelected) {
            setEndDate(new Date());
        }
    }, [startDate])

    const handleSubmit = () => {
        if (startDate && endDate) {
            if (startDate < endDate) {
                setValidationMessage("");
                toggleDateFilter([startDate, endDate]);
                setOpen(false);
            } else {
                setValidationMessage(t("Date.Validation.InvalidDate"));
            }
        } else {
            setValidationMessage(t("Date.Validation.SelectBoth"));
        }

    }

    const handleCloseYear = () => {
        setOpenYearPicker(false);
    };

    const handleCloseDateRange = () => {
        setOpen(false);
    }

    const openDatePickerMenu = (event: any) => {
        setAnchorEl(event.currentTarget);
        handleCloseYear();
        setOpen(prevState => !prevState);
    }

    const openYearPickerMenu = (event: any) => {
        setAnchorEl(event.currentTarget);
        handleCloseDateRange();
        setOpenYearPicker(prevState => !prevState);
    }

    // const openDecadePickerMenu = () => {
    //     setOpen(false);
    //     setOpenYearPicker(false);
    //     setOpenDecadePicker(prevState => !prevState);
    // }

    // const handleChange = (event: any) => {
    //     event.preventDefault();
    //     const value = event.target.value;
    //     setDecade(value);
    //     setOpenDecadePicker(false);
    // }

    const handleYearSelect = (year: any) => {
        const firstDate = new Date();
        firstDate.setDate(1);
        firstDate.setMonth(0);
        firstDate.setFullYear(year.getFullYear());
        const secondDate = new Date();
        secondDate.setDate(31);
        secondDate.setMonth(11);
        secondDate.setFullYear(year.getFullYear());
        setYear(year);
        setOpenYearPicker(false);
        toggleDateFilter([firstDate, secondDate]);
    }

    const handleTodayDate = () => {
        const today = new Date();
        const yestarday = new Date(today);
        yestarday.setDate(yestarday.getDate() - 1);

        setIsSelected(prevState => !prevState);
        if (isSelected) {
            resetDatesState();
            resetDateFilterAction();
        }
        else {
            toggleDateFilter([yestarday, today]);
        }
    }

    useEffect(() => {
        if(isSelected && dateFilter.length === 0) {
            setIsSelected(false);
        }
        else if(dateFilter.length === 0) {
            resetYearState();
            resetDatesState();
        }
    }, [dateFilter])

    const resetDateFilter = () => {
        resetDatesState();
        resetDateFilterAction();
        setOpen(false);
    }

    const resetDatesState = () => {
        setStartDate(null);
        setEndDate(null);
    }

    const resetYearState = () => {
        setYear(null);
    }

    return (
        <React.Fragment>
            {
                !isInResults ?
                    <React.Fragment >
                        {!isDateValid ?
                            <React.Fragment>
                                <Button size="small" variant="contained"
                                    className={isSelected ? " selected-round-button" : 'white-round-button'} onClick={handleTodayDate}>
                                    Today
                                </Button>
                                <Button size="small" aria-controls={openYearPicker ? 'menu-list-grow' : undefined}
                                    variant="contained" className='white-round-button'
                                    onClick={openYearPickerMenu} endIcon={openYearPicker ? <ExpandLessIcon /> : <ExpandMoreIcon />}>
                                    {year ? year.getFullYear() : "Year"}
                                </Button>
                            </React.Fragment>
                        : null}
                        {/* <Button ref={anchorRef} size="small" aria-controls={openDecadePicker ? 'menu-list-grow' : undefined}
                            variant="contained" className='white-round-button'
                            onClick={openDecadePickerMenu} endIcon={openDecadePicker ? <ExpandLessIcon /> : <ExpandMoreIcon />}>
                            {decade ? decade : "Decade"}
                        </Button> */}
                        {/* <Select name={SearchFieldTypes.DECADE_PICKER}
                            value={decade ? decade : "Decade"} onChange={handleChange}
                            input={<BootstrapInput />}>
                            {
                                Array.from(Array(21), (e, decade) => {
                                    return <MenuItem key={decade} value={decade + 1}>{decade + 1}</MenuItem>
                                })
                            }
                        </Select> */}
                    </React.Fragment>
                : null}
            <Button size={isInChannelContex ? "small" : "medium"} aria-controls={open ? 'menu-list-grow' : undefined}
                variant="contained" className={isInChannelContex ? 'white-round-button' : 'white-round-button-channel-contex'}
                onClick={openDatePickerMenu} endIcon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}>
                {dateFilter.length === 2 ?
                    `${dateFilter[0].toLocaleDateString('en-GB')}-${dateFilter[1].toLocaleDateString('en-GB')}`
                    : `${t("Date.Field")}`}
            </Button>
            <Popper open={open} style={{ zIndex: 5 }} anchorEl={anchorEl} role={undefined} transition disablePortal>
                {({ TransitionProps, placement }) => (
                <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                    <Paper>
                        <ClickAwayListener onClickAway={handleCloseDateRange}>
                            <MenuList autoFocusItem={open} variant="menu" id="menu-list-grow">
                                <div className='date-filter-container'>
                                    <div className="date-validation-field">{validationMessage}</div>
                                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap[locale]}>
                                        <DatePicker
                                            mask={maskMap[locale]}
                                            label={startDate ? null : "Start date"}
                                            value={startDate}
                                            maxDate={new Date()}
                                            onChange={(newValue) => {
                                                setStartDate(newValue);
                                            }}
                                            renderInput={(params) => 
                                                <TextField InputLabelProps={{shrink: false}} {...params} />
                                            }
                                        />
                                    </LocalizationProvider>
                                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap[locale]}>
                                        <DatePicker
                                            mask={maskMap[locale]}
                                            label={endDate ? null : "End date"}
                                            value={endDate}
                                            maxDate={new Date()}
                                            onChange={(newValue) => {
                                                setEndDate(newValue);
                                            }}
                                            renderInput={(params) => 
                                                <TextField InputLabelProps={{shrink: false}} {...params} />
                                            }
                                        />
                                    </LocalizationProvider>
                                    <ButtonGroup disableElevation >
                                        <Button onClick={handleSubmit} className='white-round-button'>
                                            {"Save"}
                                        </Button>
                                        {dateFilter.length === 2 ? 
                                            <Button onClick={resetDateFilter} className='white-round-button'>
                                                {"Reset"}
                                            </Button>
                                        :
                                            <Button onClick={handleCloseDateRange} className='white-round-button'>
                                                {"Close"}
                                            </Button>
                                        }
                                    </ButtonGroup>
                                </div>
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
                )}
            </Popper>
            <Popper open={openYearPicker} style={{ zIndex: 5 }} anchorEl={anchorEl} role={undefined} transition disablePortal>
            {({ TransitionProps, placement }) => (
                <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                    <Paper>
                        <ClickAwayListener onClickAway={handleCloseYear}>
                            <MenuList autoFocusItem={open} id="menu-list-grow">
                                <div className='date-filter-container'>
                                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap[locale]}>
                                        <DatePicker
                                            mask={maskMap[locale]}
                                            views={['year']}
                                            label="Year"
                                            value={year}
                                            maxDate={new Date()}
                                            onChange={(newValue) => {
                                                handleYearSelect(newValue);
                                            }}
                                            renderInput={(params) => <TextField {...params} helperText={null} />}
                                            />
                                    </LocalizationProvider>
                                </div>
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
                )}
            </Popper>
            {/* <Select variant="outlined" name={SearchFieldTypes.DECADE_PICKER}
                value={decade} onChange={handleChange} open={openDecadePicker}
                input={<BootstrapInput />}>
                {
                    Array.from(Array(21), (e, decade) => {
                        return <MenuItem key={decade} value={decade + 1}>{decade + 1}</MenuItem>
                    })
                }
            </Select> */}
        </React.Fragment>
    )
}


const mapStateToProps = (state: StoreState): { dateFilter: Date[] } => {
    return {
        dateFilter: selectDateFilter(state)
    }
}

const mapDispatchToProps = (dispatch: Dispatch<TSearchReducerActions>) => {
    return {
        toggleDateFilter: (data: Date[]) => dispatch<IToggleFilterDate>({
            type: SearchBarActions.TOGGLE_FILTER_DATE,
            data: data
        }),
        resetDateFilterAction: () => dispatch<IResetDateFilter>({ type: SearchBarActions.RESET_DATE_FILTER }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DateFilterComponent);