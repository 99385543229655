import { CartActionTypes, CartState } from "./cart-page.types";
import { TCartReducerActions } from "./cart-page.actions";
import { ItemForCart } from "../../components/photo-details/photo-details.types";
import { IBroadcastMessage } from "../../components/batch-upload/ContentUpload.types";
import { addToCart, calculateSubtotal, checkItemToRemove } from "./cart-page.helperFunctions";
import { getToTwoDecimals } from "../../components/cart/cart-page-helperFunctions";

const InitialState: CartState = {
  itemsInCart: [],
  itemsFromSub: [],
  subtotal: 0,
  total: 0,
  additionalBillingAddress: null,
  taxForOrder: null,
  cartError: {} as IBroadcastMessage
};

const cartReducer = (
  state = InitialState,
  action: TCartReducerActions
): CartState => {
  switch (action.type) {
    case CartActionTypes.ADD_TO_CART:
      return {
        ...state,
        itemsInCart: addToCart(state.itemsInCart, action.data),
        subtotal: calculateSubtotal(state.itemsInCart, action.data),
        total: parseFloat(getToTwoDecimals(state.subtotal + action.data.flatRate))
      };
    case CartActionTypes.ADD_TO_SUBSCRIPTION_CART:
      return {
        ...state,
        itemsFromSub: addToCart(state.itemsFromSub, action.data)
      };

    case CartActionTypes.REMOVE_FROM_CART:
      return {
        ...state,
        itemsInCart: state.itemsInCart.filter((item: ItemForCart) => checkItemToRemove(item, action.data)),
        itemsFromSub: state.itemsFromSub.filter((item: ItemForCart) => item.content.id !== action.data.content.id),
        subtotal: parseFloat(getToTwoDecimals(state.subtotal - action.data.flatRate < 0 ? 0 : state.subtotal - action.data.flatRate))
      };
    case CartActionTypes.ADD_TAX: 
      return {
        ...state,
        total: parseFloat(getToTwoDecimals(state.subtotal + action.data.amountToCollect)),
        taxForOrder: action.data
      }
    case CartActionTypes.BROADCAST_CART_ERROR:
      return {
        ...state,
        cartError: action.data
      }
    case CartActionTypes.ADD_BILLING_ADDRESS:
      return {
        ...state,
        additionalBillingAddress: action.data
      }
    case CartActionTypes.CLEAR_BROADCAST: 
      return {
        ...state,
        cartError: {} as IBroadcastMessage
      }
    case CartActionTypes.EMPTY_CART:
      return {
        ...state,
        itemsInCart: [],
        itemsFromSub: [],
        additionalBillingAddress: null,
        taxForOrder: null,
        subtotal: 0,
        total: 0
      };
    default:
      return state;
  }
};

export default cartReducer;
