import React, { useState, useEffect } from "react";
import { Dispatch } from "redux";
import CartStepOne from "./cart-step-one.component";
import {
  CartPageProps,
  CartSteps,
  stepperTheme
} from "./cart-page.types";
import { Stepper, Step, StepLabel, MuiThemeProvider } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import CartPayment from "./cart-step-payment.component";
import { loadStripe } from "@stripe/stripe-js";
import { useLazyQuery } from "@apollo/react-hooks";
import {
  IToggleLogin,
  TModalReducerActions
} from "../../redux/modal-visibility/modal.actions";
import { ModalActionTypes } from "../../redux/modal-visibility/modal.types";
import { Elements } from "@stripe/react-stripe-js";
import { StoreState } from "redux/root-reducer";
import { connect } from "react-redux";
import {
  selectCartError,
  selectCartItemsCount,
  selectCurrentCartItems,
  selectCurrentSubItems
} from "../../redux/cart/cart-page.selector";
import { User } from "../../redux/user/user.types";
import { selectCurrentUser } from "../../redux/user/user.selectors";
import { CallHistoryMethodAction } from "connected-react-router";
import { ItemForCart } from "../photo-details/photo-details.types";
import { IBroadcastCartError, IClearBroadcast, TCartReducerActions } from "../../redux/cart/cart-page.actions";
import { CartActionTypes } from "../../redux/cart/cart-page.types";
import { SnackbarComponent } from "shared";
import './cart-page.styles.scss';
import { IBroadcastMessage, SeveritySnackbarEnum } from "../batch-upload/ContentUpload.types";
import CompleteBannerContainer from "./cart-page-complete.component";
import { GET_USER_LICENSING_PLANS } from "../my-license-page/queries";
import { UserLicensingPlan } from "../my-license-page/my-license-page.types";
import CartStepSubscriptions from "./cart-step-subscriptions.component";
import { LicensingTypes } from "../batch-upload/EditContentMetadata.types";
import {config} from "../../config";

const CartPage: React.FC<CartPageProps> = ({ ...props }) => {
  const { cartItems, cartItemsFromSub, currentUser, cartError, cartItemsCount,
    toggleLoginModalAction, broadcastCartAction, clearBroadcast } = props;

  const [steps, setSteps] = useState([
    CartSteps.MANAGE,
    CartSteps.COMPLETE
  ]);
  const [currentStep, setCurrentStep] = useState(CartSteps.MANAGE);
  const [paymentSnackbar, setPaymentSnackbar] = useState(false);
  const [isPaymentRequired, setIsPaymentRequired] = useState(false);
  const [licensePlans, setLicensePlans] = useState<UserLicensingPlan[]>([]);
  const [fetchUserPlanData, { data: planData, loading: planLoading, error: planError }] = useLazyQuery(GET_USER_LICENSING_PLANS, { fetchPolicy: "no-cache" });

  const [stripePromise, setStripePromise] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if(stripePromise === null)
      loadStripeInstance();
  }, [])

  useEffect(() => {
    if (currentUser) {
      fetchUserPlanData({ variables: { userId: currentUser.id } });
    }
  }, [currentUser]);

  useEffect(() => {
    if (planData && planData.getUserLicensingPlans) {
      setLicensePlans(planData.getUserLicensingPlans);
    }
  }, [planData]);

  useEffect(() => {
    if (cartItemsFromSub.length > 0 && cartItems.length > 0) {
      setIsPaymentRequired(true);
      setSteps([CartSteps.MANAGE, CartSteps.SUBSCRIPTION, CartSteps.PAYMENT, CartSteps.COMPLETE]);
    } else if (cartItems.length > 0) {
      setIsPaymentRequired(true);
      setSteps([CartSteps.MANAGE, CartSteps.PAYMENT, CartSteps.COMPLETE]);
    } else if (cartItemsFromSub.length > 0) {
      setSteps([CartSteps.MANAGE, CartSteps.SUBSCRIPTION, CartSteps.COMPLETE]);
    }
  }, [cartItems, cartItemsFromSub]);

  useEffect(() => {
    if (cartError.message && cartError.severity) {
      setPaymentSnackbar(true);
    }
  }, [cartError])

  const loadStripeInstance = async () => {
    await loadStripe(config.REACT_APP_STRIPE_LOAD_KEY!)
      .then((result: any) => {
        setStripePromise(result);
      }).catch((error: any) => {
        broadcastCartAction({severity: SeveritySnackbarEnum.error, message: "Failed to load instance of Stripe"});
      })
  }

  const handleClose = () => {
    setPaymentSnackbar(false);
    clearBroadcast();
  };

  const getCurrentStepIndex = () => {
    const currentStepIndex = steps.findIndex(step => step === currentStep);
    return currentStepIndex;
  }

  // Navigation
  const handleNextPage = () => {
    if (!currentUser?.role) {
      toggleLoginModalAction();
      broadcastCartAction({ severity: SeveritySnackbarEnum.error, message: t("Cart.User.Not.Valid") });
    }

    setCurrentStep(steps[getCurrentStepIndex() + 1]);
  }

  const handleBack = () => {
    const currentStepIndex = getCurrentStepIndex();
    if (currentStepIndex > 0) {
      setCurrentStep(steps[currentStepIndex - 1]);
    }
  };

  const licenseIsInvalid = () => {
    const standardItemsCount = cartItemsFromSub.filter((item: ItemForCart) => item.content.licensingType === LicensingTypes.STANDARD).length;
    const premiumItemsCount = cartItemsFromSub.filter((item: ItemForCart) => item.content.licensingType === LicensingTypes.PREMIUM).length;

    const standardLicense = licensePlans.find((plan: UserLicensingPlan) => plan.plan.licensingType === LicensingTypes.STANDARD);
    const premiumLicense = licensePlans.find((plan: UserLicensingPlan) => plan.plan.licensingType === LicensingTypes.PREMIUM);

    if (standardItemsCount > 0 && (!standardLicense || standardLicense.amountLeft < standardItemsCount)) {
      return true;
    }

    if (premiumItemsCount > 0 && (!premiumLicense || premiumLicense.amountLeft < premiumItemsCount)) {
      return true;
    }

    return false;
  }

  const getStepContent = (currentStep: CartSteps) => {
    if (currentStep === CartSteps.MANAGE) {
      return <CartStepOne currentUser={currentUser} 
        userHasValidLicensePlans={!licenseIsInvalid()} 
        handlePageChange={handleNextPage} />;
    }
    else if (currentStep === CartSteps.SUBSCRIPTION) {
      return <CartStepSubscriptions licensePlans={licensePlans}
        handlePageChange={handleNextPage}
        handlePageBack={handleBack}
        isLicensePlanInvalid={licenseIsInvalid()} />
    }
    else if (currentStep === CartSteps.PAYMENT && isPaymentRequired) {
      return <Elements stripe={stripePromise}>
              <CartPayment handlePageBack={handleBack} handlePageChange={handleNextPage} />
            </Elements>;
    }
    else if (currentStep === CartSteps.COMPLETE) {
      return <CompleteBannerContainer />
    }
  }

  // const showNextPageButton = () => {
  //   const currentStepIndex = getCurrentStepIndex();

  //   if (currentStepIndex <= steps.length - 3 && !licenseIsInvalid() && cartItemsCount !== 0) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  // const showPreviousPageButton = () => {
  //   if (currentStep !== CartSteps.COMPLETE && cartItemsCount !== 0) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  return (
    <div className="page-wrapper">
      <MuiThemeProvider theme={stepperTheme}>
          <Stepper activeStep={getCurrentStepIndex()} alternativeLabel>
            {steps.map((label: any) => (
              <Step key={label}>
                <StepLabel>{t(label)}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {getStepContent(currentStep)}
      </MuiThemeProvider>

      <SnackbarComponent
        showSnackbar={paymentSnackbar}
        handleClose={handleClose}
        severity={cartError.severity}
        message={cartError.message}
      />
    </div>
  );
}

const mapStateToProps = (state: StoreState): {
  cartItems: ItemForCart[]; cartItemsFromSub: ItemForCart[]; currentUser: User; 
  cartError: IBroadcastMessage; cartItemsCount: number; } => {
  return {
    cartItems: selectCurrentCartItems(state),
    cartItemsFromSub: selectCurrentSubItems(state),
    currentUser: selectCurrentUser(state),
    cartError: selectCartError(state),
    cartItemsCount: selectCartItemsCount(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch<TModalReducerActions | CallHistoryMethodAction | TCartReducerActions>) => {
  return {
    toggleLoginModalAction: () => dispatch<IToggleLogin>({ type: ModalActionTypes.ToggleLoginModal }),
    broadcastCartAction: (data: IBroadcastMessage) => dispatch<IBroadcastCartError>({
      type: CartActionTypes.BROADCAST_CART_ERROR, data: data
    }),
    clearBroadcast: () => dispatch<IClearBroadcast>({type: CartActionTypes.CLEAR_BROADCAST})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CartPage);
