import gql from 'graphql-tag';

export const GET_CONTRIBUTOR_CONTENT = gql`
 query getUserUploadedContent($pageNumber: Int!, $contentPerPage: Int!, $contributorId: Int!, $searchString: String, $contentType: String, $contentStatus: String, $uploadDate: [DateTime!], 
                        $publishDate: [DateTime!], $channelId: Int, $subchannelId: Int, $threadId: Int, $channelTitle: String, $subchannelTitle: String, $threadTitle: String, $countryName: String, $provinceName: String, $cityName: String) {
    getUserUploadedContent(pageNumber: $pageNumber, contentPerPage: $contentPerPage, contributorId: $contributorId, input: {searchString: $searchString, contentType: $contentType, contentStatus: $contentStatus, uploadDate: $uploadDate,
                        publishDate: $publishDate, channelId: $channelId, subchannelId: $subchannelId, threadId: $threadId, channelTitle: $channelTitle, subchannelTitle: $subchannelTitle, threadTitle: $threadTitle, countryName: $countryName, provinceName: $provinceName, cityName: $cityName}) {
            allResultsCount
            userUploadedContent {
                articleId
                contentSetId
                title
                type
                status
                uploadDate
                publishDate
                content {
                    id
                    code
                    pathToFileThumbnail
                    externalLink
                    externalLinkMessage
                }
                contentNavigations {
                    channel {
                        id
                        title
                    }
                    subChannel {
                        id
                        title
                    }
                    thread {
                        id
                        title
                        status
                    }
                }
                location {
                    country {
                        country
                    }
                    province{
                        stateProvince
                    }
                    city {
                        city
                    }
                }
            }
        }
    }
`;

export const UPDATE_PREFFERED_CURRENCY = gql`
    mutation updatePrefferedCurrency($userId: Int!, $prefferedCurrency: String!) {
        updatePrefferedCurrency(userId: $userId, prefferedCurrency: $prefferedCurrency)
    }
`

export const GET_ALL_CONTENT_COUNT = gql`
    query getAllContentCountInDatatables($contributorId: Int, $status: String, $isFMC: Boolean) {
        getAllContentCountInDatatables(contributorId: $contributorId, status: $status, isFMC: $isFMC){
            articlesCount
            contentSetsCount
            singleContentCount
        }
    }
`