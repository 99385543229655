import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  FormControlLabel,
  RadioGroup,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionActions,
  Button,
  Radio,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from "@material-ui/core";
import "suneditor/dist/css/suneditor.min.css";
import { GeneralInformationProps, buttonStyles } from "./onboarding-page.types";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { IGeneralInformationPerson, IGeneralInformationCompany, TOnboardingReducerAction, IToggleResponsiblePersonAccordion } from "../../redux/onboarding/onboarding.actions";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { RoleTypes } from "../register/register.types";
import { GET_COMPANY_TYPES, GET_CONTENT_TYPE, GET_FULL_USER_DETAILS } from "./queries";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { OnboardingGeneralInfoAllYup } from "./yup-validation-objects";
import './onboarding-page.styles.scss'
import { Gender, GeneralInformationCompany, GeneralInformationPerson, OnboardingActionTypes } from "../../redux/onboarding/onboarding.types";
import { StoreState } from "../../redux/root-reducer";
import { selectGeneralInformationCompany, selectGeneralInformationPerson } from "../../redux/onboarding/onboarding.selectors";
import { User } from "../../redux/user/user.types";
import { selectCurrentUser } from "../../redux/user/user.selectors";

const GeneralInfoMembers: React.FC<GeneralInformationProps> = ({ ...props }) => {
  const { userRole, saveGeneralInformationPersonAction, saveGeneralInformationCompanyAction, toggleResponsiblePersonAccordionAction,
    generalInformationPerson, generalInformationCompany, currentUser } = props;
  const [open, setOpen] = useState(true);
  const classes = buttonStyles();

  const currentDate = new Date().getFullYear();

  const { t } = useTranslation();

  const [dataQuery, setDataQuery] = useState(GET_COMPANY_TYPES);
  const [getDataInfo, { data, loading, error, refetch }] = useLazyQuery(dataQuery, { fetchPolicy: "no-cache" });
  const [companyTypes, setCompanyTypes] = useState([]);
  const [contentTypes, setContentTypes] = useState([]);
  const isPerson = userRole === RoleTypes.Contributor_individual || userRole === RoleTypes.Pro_freelancer;
  const [show, setShow] = useState({
    firstName: true,
    lastName: true,
    organizationName: false,
    gender: true,
    year: true,
    vat: false,
    companyType: false,
    contentType: false,
    newsletterEmail: false,
    accountingEmail: false
  });

  const getYears = (date: any) => {
    let yearArr = [];
    for (let i = date; i >= date - 100; i--) {
      yearArr.push(i);
    }
    return yearArr;
  };

  const years = getYears(currentDate);

  useEffect(() => {
    if (dataQuery === GET_COMPANY_TYPES) {
      if (data && data.getCompanyTypes) {
        setCompanyTypes(data.getCompanyTypes);
        setDataQuery(GET_CONTENT_TYPE);
      }
    } else if (dataQuery === GET_CONTENT_TYPE) {
      if (data && data.getContentTypesOnboarding) {
        setContentTypes(data.getContentTypesOnboarding);
      }
    }
  }, [data]);

  useEffect(() => {
    if (userRole) {
      getDataInfo();
      if (userRole === RoleTypes.Contributor_individual) {
        setShow({
          firstName: true,
          lastName: true,
          organizationName: false,
          gender: false,
          year: true,
          vat: true,
          companyType: false,
          contentType: false,
          newsletterEmail: false,
          accountingEmail: false
        });
      }
      if (userRole === RoleTypes.Pro_freelancer) {
        setShow({
          firstName: true,
          lastName: true,
          organizationName: false,
          gender: false,
          year: true,
          vat: true,
          companyType: false,
          contentType: false,
          newsletterEmail: true,
          accountingEmail: true
        });
      }
      if (userRole === RoleTypes.Contributor_agency) {
        setShow({
          firstName: false,
          lastName: false,
          organizationName: true,
          gender: false,
          year: false,
          vat: true,
          companyType: false,
          contentType: false,
          newsletterEmail: false,
          accountingEmail: false
        });
      }
      if (userRole === RoleTypes.Pro_organization) {
        setShow({
          firstName: false,
          lastName: false,
          organizationName: true,
          gender: false,
          year: false,
          vat: true,
          companyType: true,
          contentType: true,
          newsletterEmail: true,
          accountingEmail: true
        });
      }
    }
  }, [userRole]);

  const getValidationSchema = () => {
    if (userRole === RoleTypes.Contributor_individual) {
      return OnboardingGeneralInfoAllYup.OnboardingGeneralInfoIndividualYup;
    } else if (userRole === RoleTypes.Contributor_agency) {
      return OnboardingGeneralInfoAllYup.OnboardingGeneralInfoAgencyYup;
    } else if (userRole === RoleTypes.Pro_organization) {
      return OnboardingGeneralInfoAllYup.OnboardingGeneralInfoOrganizationYup;
    } else  if (userRole === RoleTypes.Pro_freelancer){
      return OnboardingGeneralInfoAllYup.OnboardingGeneralInfoFreelancerYup;
    } else {
      return OnboardingGeneralInfoAllYup.OnboardingGeneralInfoDefaultYup;
    }
  };

  const validationSchema = getValidationSchema();
  const { handleSubmit, handleChange, values, errors } = useFormik({
    initialValues: {
      firstName: generalInformationPerson.firstName ? generalInformationPerson.firstName : currentUser && currentUser.person ? currentUser.person.firstName : "",
      lastName: generalInformationPerson.lastName ? generalInformationPerson.lastName : currentUser && currentUser.person ? currentUser.person.lastName : "",
      organizationName: generalInformationCompany.organizationName ? generalInformationCompany.organizationName : currentUser && currentUser.company ? currentUser.company.name : "",
      gender: "",
      yearOfBirth: generalInformationPerson.yearOfBirth ? generalInformationPerson.yearOfBirth : "",
      vatNumber: generalInformationCompany.vatNumber ? generalInformationCompany.vatNumber : (generalInformationPerson.vatNumber ? generalInformationPerson.vatNumber : ""),
      companyType: "",
      contentType: "",
      newsletterEmail: generalInformationCompany.emailNewsletter ? generalInformationCompany.emailNewsletter : (generalInformationPerson.emailNewsletter ? generalInformationPerson.emailNewsletter : ""),
      accountingEmail: generalInformationCompany.emailAccounting ? generalInformationCompany.emailAccounting : (generalInformationPerson.emailAccounting ? generalInformationPerson.emailAccounting : "")
    },
    validateOnBlur: true,
    validationSchema,
    onSubmit(values) {
      const { firstName, lastName, organizationName, yearOfBirth, vatNumber, gender, companyType, contentType, newsletterEmail, accountingEmail } = values;
      if (isPerson) {
        saveGeneralInformationPersonAction({
          firstName: firstName,
          lastName: lastName,
          yearOfBirth: parseInt(yearOfBirth.toString()),
          vatNumber: vatNumber,
          emailAccounting: accountingEmail,
          emailNewsletter: newsletterEmail
        });
      }
      else {
        saveGeneralInformationCompanyAction({
          organizationName: organizationName,
          vatNumber: vatNumber,
          companyType: companyType,
          contentType: contentType,
          emailNewsletter: newsletterEmail,
          emailAccounting: accountingEmail
        });
      }

      setOpen(false);
      toggleResponsiblePersonAccordionAction();
    }
  });

  return (
    <form onSubmit={handleSubmit}>
      <Accordion expanded={open}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
          onClick={() => {
            setOpen(!open);
          }}
        >
          <div className="title">{t("Onboarding.StepOne.GeneralInfo")}</div>
        </AccordionSummary>
        <div className="accordion-container">
          {show.firstName ? (
            <TextField
              autoComplete="off"
              id="firstName"
              onChange={handleChange}
              name="firstName"
              label={t("Onboarding.FirstName.Label")}
              value={values.firstName}
              helperText={errors.firstName ? errors.firstName : null}
            />
          ) : null}
          {show.lastName ? (
            <TextField
              autoComplete="off"
              id="lastName"
              onChange={handleChange}
              name="lastName"
              label={t("Onboarding.LastName.Label")}
              value={values.lastName}
              helperText={errors.lastName ? errors.lastName : null}
            />
          ) : null}
          {show.organizationName ? (
            <TextField
              autoComplete="off"
              id="organizationName"
              onChange={handleChange}
              name="organizationName"
              label={t("Onboarding.OrganizationName")}
              value={values.organizationName}
              helperText={errors.organizationName ? errors.organizationName : null}
            />
          ) : null}
          {show.gender ? (
            <span>
              <Accordion id="gender" className="custom-accordion">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1c-content"
                  className="inner-accordion"
                >
                  <div className="gray-color">{t("Onboarding.Gender.Label")}</div>
                </AccordionSummary>
                <RadioGroup
                  aria-label="quiz"
                  name="gender"
                  defaultValue=""
                  onClick={handleChange}
                >
                  <FormControlLabel
                    className="gray-color"
                    value={Gender.MALE}
                    control={<Radio />}
                    label={t("Onboarding.Gender.Male")}
                  />
                  <FormControlLabel
                    className="gray-color"
                    value={Gender.FEMALE}
                    control={<Radio />}
                    label={t("Onboarding.Gender.Female")}
                  />
                  <FormControlLabel
                    className="gray-color"
                    value={Gender.NO_ANSWER}
                    control={<Radio />}
                    label={t("Onboarding.Gender.PrefereNotToAnswer")}
                  />
                </RadioGroup>
              </Accordion>
              <div className="helper-text-select">
                {errors.gender ? errors.gender : null}
              </div>
            </span>
          ) : null}
           {show.year ? (
            <TextField
              autoComplete="off"
              id="yearOfBirth"
              onChange={handleChange}
              name="yearOfBirth"
              label={t("Onboarding.YearOfBirth.Label")}
              value={values.yearOfBirth}
              helperText={errors.yearOfBirth ? errors.yearOfBirth : null}
            />
          ) : null}
          
          {show.vat ? (
            <TextField
              autoComplete="off"
              id="vatNumber"
              onChange={handleChange}
              name="vatNumber"
              label={t("Onboarding.VatNumber")}
              value={values.vatNumber}
              helperText={errors.vatNumber ? errors.vatNumber : null}
            />
          ) : null}
          {show.companyType ? (
            <span>
              <Select variant='standard' name="companyType" onClick={handleChange} placeholder={t("Onboarding.CompnayType.Label")} className='dropdown-component' defaultValue={generalInformationCompany.companyType ? generalInformationCompany.companyType : t("Onboarding.CompnayType.Label")}>
                <MenuItem className='gray-color' value={t("Onboarding.CompnayType.Label")} key='Company type' disabled>{t("Onboarding.CompnayType.Label")}</MenuItem>
                {
                  companyTypes.map((companyType: string) => {
                    return <MenuItem value={companyType} key={companyType}>{companyType}</MenuItem>
                  })
                }
              </Select>
              <div className="helper-text-select">
                {errors.companyType ? errors.companyType : null}
              </div>
            </span>
          ) : null}
          {show.contentType ? (
            <span>
              <Select variant='standard' name="contentType" onClick={handleChange} placeholder={t("Onboarding.ContentType.Label")} className='dropdown-component' defaultValue={generalInformationCompany.contentType ? generalInformationCompany.contentType : t("Onboarding.ContentType.Label")}>
                <MenuItem id='gray-color' className='gray-color' value={t("Onboarding.ContentType.Label")} key='Content type' disabled>{t("Onboarding.ContentType.Label")}</MenuItem>
                {
                  contentTypes.map((contentType: string) => {
                    return <MenuItem value={contentType} key={contentType}>{contentType}</MenuItem>
                  })
                }
              </Select>
              <div className="helper-text-select">
                {errors.contentType ? errors.contentType : null}
              </div>
            </span>
          ) : null}
          {show.newsletterEmail ? (
            <TextField
              autoComplete="off"
              id="newsletterEmail"
              onChange={handleChange}
              name="newsletterEmail"
              label={t("Onboarding.EmailNewsletter.Label")}
              value={values.newsletterEmail}
              helperText={errors.newsletterEmail ? errors.newsletterEmail : null}
            />
          ) : null}
          {show.accountingEmail ? (
            <TextField
              autoComplete="off"
              id="accountingEmail"
              onChange={handleChange}
              name="accountingEmail"
              label={t("Onboarding.EmailAccounting.Label")}
              value={values.accountingEmail}
              helperText={errors.accountingEmail ? errors.accountingEmail : null}
            />
          ) : null}
        </div>
        <AccordionActions style={{ justifyContent: 'center' }}>
          <Button type="submit" classes={{ root: classes.greenButtonRoot }}>
            {t("Onboarding.StepOne.Save.button")}
          </Button>
        </AccordionActions>
      </Accordion>
    </form>
  );
};

const mapStateToProps = (state: StoreState): { currentUser: User, generalInformationPerson: GeneralInformationPerson, generalInformationCompany: GeneralInformationCompany } => {
  return {
    currentUser: selectCurrentUser(state),
    generalInformationPerson: selectGeneralInformationPerson(state),
    generalInformationCompany: selectGeneralInformationCompany(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<TOnboardingReducerAction>) => {
  return {
    saveGeneralInformationPersonAction: (data: GeneralInformationPerson) => dispatch<IGeneralInformationPerson>({ type: OnboardingActionTypes.GENERAL_INFO_PERSON, data: data }),
    saveGeneralInformationCompanyAction: (data: GeneralInformationCompany) => dispatch<IGeneralInformationCompany>({ type: OnboardingActionTypes.GENERAL_INFO_COMPANY, data: data }),
    toggleResponsiblePersonAccordionAction: () => dispatch<IToggleResponsiblePersonAccordion>({ type: OnboardingActionTypes.TOGGLE_RESPONSIBLE_PERSON })
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralInfoMembers);
