import React , {useState}from 'react';

export const ImageContainer = ({...props}) => 
{
    const [showHover, toggleShowHover] =useState(false);
    const handleHover = () =>
    {
        toggleShowHover(true);
    }
    const handleMouseOut = () =>
    {
        toggleShowHover(false);
    }
    return(
        <div>
            <img className="thumbnail" onMouseOver={handleHover} onMouseOut={handleMouseOut} src={props.src} alt={props.src}/>
            {showHover? <img className="preview" src={props.src} alt={props.src}/> :null}
            
        </div>

    )
    
}