import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { IToggleNoAccess, IToggleNotLogged, IToggleWrongPath, TPageReducerActions } from '../../redux/error-page/error-page.actions';
import { StoreState } from 'redux/root-reducer'
import { User, UserActionTypes, UserState } from '../../redux/user/user.types'
import {ErrorPage} from 'shared'
import { ErrorPagesTypes } from '../../redux/error-page/error-page.types';
import { selectNoAccessPage, selectNotLoggedPage, selectWrongPathPage } from '../../redux/error-page/error-page.selectors';

const mapStateToProps = (state: StoreState): { toggleNoAccessPage: boolean, toggleNotLoggedPage: boolean, toggleWrongPathPage: boolean, user:UserState, route: string } => {
    return {
        user: state.user,
        route: state.router.location.pathname,
        toggleNoAccessPage: selectNoAccessPage(state),
        toggleNotLoggedPage: selectNotLoggedPage(state),
        toggleWrongPathPage: selectWrongPathPage(state)
    }
}

const mapDispatchToProps = (dispatch: Dispatch<TPageReducerActions>) => {
    return {
        toggleNoAccessPageAction: () => dispatch<IToggleNoAccess>({type: ErrorPagesTypes.ToggleNoAccessPage}),
        toggleWrongPathPageAction: () => dispatch<IToggleWrongPath>({type: ErrorPagesTypes.ToggleWrongPathPage}),
        toggleNotLoggedPageAction: () => dispatch<IToggleNotLogged>({type: ErrorPagesTypes.ToggleNotLoggedPage})
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ErrorPage)