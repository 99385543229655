import {createSelector} from 'reselect';
import {StoreState} from 'redux/root-reducer';

const selectPhoto = (state: StoreState) => state.photoDetails;

export const selectPhotoDetails = createSelector(
    [selectPhoto],
    (selectPhoto) => selectPhoto.item
);

export const selectCurrentlySelectedPhoto = createSelector(
    [selectPhoto],
    (selectPhoto) => selectPhoto.currentSelectedPhoto
);

export const selectClickedImageToAddToLightBox = createSelector(
    [selectPhoto],
    (selectPhoto) => selectPhoto.clickedImageToAddToLightBox
)

export const selectBroadcastMessage = createSelector(
    [selectPhoto],
    (selectPhoto) => selectPhoto.broadcastMessage
)