import gql from 'graphql-tag';

export const GET_MOSTFOLLOWED = gql`
query mostFollowedThreadsQuery {
  mostFollowedThreads 
  {
    id
    title
  } 
}
`;

export const GET_PATH_FROM_THREAD_ID = gql`
  query getPathFromThreadIdQuery ($id: Int!) {
    getPathFromThreadId(id: $id) {
      thread_id
      thread_title
      channel_id
      subchannel_id
      subchannel_title
      channel_title
    }
  }
`;