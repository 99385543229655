import axios from "axios";
import {config} from "../../config";

export const stripePayment = async (tokenCart: any, price: number, currency: string, email: string) => {
  return axios({
    url: `${config.REACT_APP_PUBLIC_SERVER_HOST}/payment`,
    method: "POST",
    data: {
      token: tokenCart,
      amount: price,
      currency: currency,
      email: email
    }
  })
    .then((response: any) => {
      if (response.data.success) {
        return Promise.resolve(response.data.success);
      }
    })
    .catch((error: any) => {
      return Promise.reject(error);
    });
};
