import { StoreState } from "redux/root-reducer";
import { createSelector } from "reselect";

const selectTableFilter = (state: StoreState) => state.tableFilters;

export const selectContentStatus = createSelector(
    [selectTableFilter],
    (tableFilter) => tableFilter.contentStatus
);

export const selectContentType = createSelector(
    [selectTableFilter],
    (tableFilter) => tableFilter.contentType
);

export const selectUploadDate = createSelector(
    [selectTableFilter],
    (tableFilter) => tableFilter.uploadDate
);

export const selectPublishDate = createSelector(
    [selectTableFilter],
    (tableFilter) => tableFilter.publishDate
);

export const selectChannelFilter = createSelector(
    [selectTableFilter],
    (tableFilter) => tableFilter.channelFilter
);

export const selectSubchannelFilter = createSelector(
    [selectTableFilter],
    (tableFilter) => tableFilter.subchannelFilter
);

export const selectThreadFilter = createSelector(
    [selectTableFilter],
    (tableFilter) => tableFilter.threadFilter
);

export const selectLocationFilter = createSelector(
    [selectTableFilter],
    (tableFilter) => tableFilter.locationFilter
);

export const selectFetchError = createSelector(
    [selectTableFilter],
    (tableFilter) => tableFilter.fetchError
)