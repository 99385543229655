import React, { useEffect, useState } from "react";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { GET_ORDER_LIST } from "./queries";
import { StoreState } from "../../redux/root-reducer";
import { selectCurrentUser, selectDataTableRefreshState } from "../../redux/user/user.selectors";
import { connect, useSelector } from "react-redux";
import { createTheme } from '@material-ui/core/styles';
import { FormGroup, FormLabel, MenuItem, MuiThemeProvider, Select, TextField, Button, Link, CircularProgress, FormControl, InputLabel } from "@material-ui/core";
import Moment from "react-moment";
import './my-downloads.styles.scss';
import {  LicenseOrigins, MyDownloadsProps, TabPanelProps } from "./myDownloads.types";
import { useTranslation } from "react-i18next";
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import SyncLoader from 'react-spinners/SyncLoader';
import { ImageContainer } from "./image-container.component";
import { User, UserActionTypes } from "../../redux/user/user.types";
import { selectUploadError } from "../../redux/content-upload/content-upload.selectors";
import { SnackbarComponent, SearchBarComponent, RefreshDateTime } from 'shared';
import { IBroadcastMessage, SeveritySnackbarEnum } from "../batch-upload/ContentUpload.types";
import { selectContentStatus, selectContentType, selectFetchError } from "../../redux/table-filter/table-filter.selectors";
import { TableFilterActions } from "../../redux/table-filter/table-filter.types";
import { IBroadcastFetchError, IClearBroadcastFetchError, IToggleFilterUploadDate, IToggleResetFilter, TTableFiltersReducerActions } from "../../redux/table-filter/table-filter.actions";
import { Dispatch } from "redux";
import { IToggleDataTableRefreshState, TUserReducerActions } from "../../redux/user/user.actions";
import { downloadContent } from "../../components/utils/download";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownload } from "@fortawesome/free-solid-svg-icons";
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { IToggleDatePurchased, IToggleDownloadingContents, IToggleSizePurchased, TDownloadActions } from "../../redux/Downloads/downloads.actions";
import { DownloadActionTypes } from "../../redux/Downloads/downloads.types";
import { selectDownloadingContents, selectDatePurchased, selectedSize } from '../../redux/Downloads/downloads.selectors';
import {config} from '../../config';
import { useLocation } from "react-router";
import { filter } from "jszip";

const MyDownloadsPage: React.FC<MyDownloadsProps> = ({ ...props }) => {
  const { t } = useTranslation();
  const { currentUser, history, updateError, contentStatusFilter, contentTypeFilter, selectedSize, dataTableRefreshState, fetchError, datePurchased, downloadingContents, toggleSize, toggleDatePurchased, toggleDownloadingContents, resetTableFilterAction, toggleDataTableRefreshState, broadcastError, clearBroadcastErrorAction } = props;

  const [value, setValue] = useState<number>(0);
  const [selectedLicenseOrigin, setSelectedLicenseOrigin] = useState<any>();
  const [orders, setOrders] = useState<any>([]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  //QUERIES
  const [fetchOrders, { data, loading, error, refetch }] = useLazyQuery(GET_ORDER_LIST, {
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    onError(error) {
      broadcastError({ severity: SeveritySnackbarEnum.error, message: t("Error.Fetch.Downloads") })
    }
  });
  //USE STATE HOOKS

  const [updateCurrencySnackbarShow, setUpdateCurrencySnackbarShow] = useState(false);
  const [fetchErrorSnackbarShow, setFetchErrorSnackbarShow] = useState(false);

  const [pageNumber, setPageNumber] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [searchString, setSearchString] = useState<string>('');
  const [changedFilter, setChangedFilter] = useState<any>([]);

  //USE EFFECT HOOKS
  useEffect(()=>{
    clearBroadcastErrorAction();
  },[]);

  useEffect(()=>{
    if(data){
      setOrders([...data?.userOrderList?.contentOrders])
    }
  },[data]);

  useEffect(()=>{
    fetchOrders({ variables: {  
        photographerName: "",
        size: "",
        orderDate: [],
        searchString: "",
        pageNumber: 1,
        contentPerPage: rowsPerPage, 
        licenseOrigin: selectedLicenseOrigin,
        userId: currentUser.id 
      } 
    });
    setPageNumber(0);
  },[selectedLicenseOrigin, rowsPerPage]);

  useEffect(()=>{
    if(changedFilter){
      fetchOrders({ variables: {  
        photographerName: "",
        size: selectedSize.toLocaleLowerCase() ,
        orderDate: datePurchased,
        searchString: "",
        pageNumber: 1,
        contentPerPage: rowsPerPage, 
        licenseOrigin: selectedLicenseOrigin,
        userId: currentUser.id 
      } 
    });
    if(selectedSize === "")setPageNumber(0)
    }
  },[selectedSize,datePurchased,changedFilter])
  useEffect(()=>{
    setOrders([]);
    toggleDatePurchased([])
    if(value === 0){
      setSelectedLicenseOrigin(LicenseOrigins.STANDARD)
    }
    else if(value === 1){
      setSelectedLicenseOrigin(LicenseOrigins.CREDIT)
    }
    else if(value === 2){
      setSelectedLicenseOrigin(LicenseOrigins.SUBSCRIPTION)
    }
  },[value])
  useEffect(() => {
    if (updateError.severity && updateError.message) {
      setUpdateCurrencySnackbarShow(true);
    }
  }, [updateError]);

  useEffect(() => {
    if (fetchError.severity && fetchError.message) {
      setFetchErrorSnackbarShow(true);
    }
  }, [fetchError]);

  //FUNCTIONS
  const callRefetch = () => {
    refetch();
  }

  const handleClose = () => {
    setUpdateCurrencySnackbarShow(false);
  }

  const handleCloseFetchError = () => {
    setFetchErrorSnackbarShow(false);
    clearBroadcastErrorAction();
  }

  const onSubmitSearch = (searchString: string) => {
    fetchOrders({ variables: {    
        photographerName: "",
        size: "",
        orderDate: [],
        searchString: searchString,  
        pageNumber: 1,
        contentPerPage: rowsPerPage, 
        licenseOrigin: selectedLicenseOrigin,
        userId: currentUser.id 
      } 
    });
    setPageNumber(0);
    setSearchString(searchString)
  };

  const handleResetSearch = () => {
    fetchOrders({ variables: {  
        photographerName: "",
        size: "",
        orderDate: [],
        searchString: "",    
        pageNumber: 1,
        contentPerPage: rowsPerPage, 
        licenseOrigin: selectedLicenseOrigin,
        userId: currentUser.id 
      } 
    });
    setPageNumber(0);
    setSearchString("")
  }

  const customSearchRender = (searchText: string, handleSearch: any, hideSearch: any, options: any) => {
    return <SearchBarComponent handleSearch={onSubmitSearch} hideSearch={hideSearch} resetSearch={handleResetSearch} />
  };

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <span>{children}</span>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const standardColumns: any = [
    {
      name: t("DownloadTable.Column.Thumbnail"),
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: t("DownloadTable.Column.Code"),
      options: {
        sort: false,
        filter: false
      }
    },
    {
      name: t("DownloadTable.Column.Title"),
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: t("DownloadTable.Column.PhotographerName"),
      options: {
        reset: true,
        sort: false,
        filter: false
      }
    },

    {
      name: t("DownloadTable.Column.SizePurchased"),
      options: {
          reset: true,
          sort: false,
          filterType: 'custom' as 'custom',
          filterList: selectedSize ? [selectedSize] : null,
          customFilterListOptions: {
              render: (v: any) => {
                if(v[0])return `Size: ${v[0]}`
                return " "
              },
              update: (filterList: any, filterPos: any, index: any) => {
                  filterList[index] = [];
                  if (filterList[index].length === 0) {
                      toggleSize("")
                  }
                  return filterList;
              },
          },
          filterOptions: {
              names: [],
              display: (filterList: any, onChange: any, index: any, column: any) => {
                  const optionValues = ['Large', 'Medium','Small','Online only'];
                  return (
                      <FormControl style={{ width: "200px", marginRight: "5%" }}>
                          <InputLabel htmlFor='select-multiple-chip'>
                              Size
                          </InputLabel>
                          <Select
                              value={filterList[index]}
                              renderValue={(selected: any) => selected}
                              onChange={event => {
                                  filterList[index][0] = event.target.value;
                                  toggleSize(filterList[index][0].toString());
                                  onChange(filterList[index], index, column);
                                  setChangedFilter(true);
                              }}
                          >
                              {optionValues.map(item => (
                                  <MenuItem key={item} value={item}>
                                      {item}
                                  </MenuItem>
                              ))}
                          </Select>
                      </FormControl>
                  );
              }
          }
      }
    },
    {
      name: t("DownloadTable.Column.DatePurchased"),
      options: {
          reset: true,
          sort: false,
          filterType: "custom" as "custom",
          filterList: datePurchased || null,
          customFilterListOptions: {
              render: (v: any) => {
                  if (v[0] && v[1]) {
                      return `Start date: ${v[0]}, End date: ${v[1]}`;
                  } else if (v[0]) {
                      return `Start date: ${v[0]}`;
                  } else if (v[1]) {
                      return `End date: ${v[1]}`;
                  }
                  return " ";
              },
              update: (filterList: any, filterPos: any, index: any) => {
                  toggleDatePurchased([]);
                  if (filterPos === 0) {
                      filterList[index].splice(filterPos, 1, '');
                  } else if (filterPos === 1) {
                      filterList[index].splice(filterPos, 1);
                  } else if (filterPos === -1) {
                      filterList[index] = [];
                  }

                  return filterList;
              },
          },
          filterOptions: {
              names: [],
              display: (filterList: any, onChange: any, index: any, column: any) => (
                  <div>
                      <FormLabel>Date Purchased</FormLabel>
                      <FormGroup row>
                          <TextField
                              id="startDate"
                              label="From"
                              type="date"
                              autoComplete="off"
                              InputLabelProps={{
                                  shrink: true
                              }}
                              value={filterList[index][0] || ""}
                              onChange={event => {
                                  filterList[index][0] = event.target.value;
                                  onChange(filterList[index], index, column);
                              }}
                              style={{ width: "100%", marginRight: "5%" }}
                          />
                          <TextField
                              id="endDate"
                              label="To"
                              type="date"
                              autoComplete="off"
                              InputLabelProps={{
                                  shrink: true
                              }}
                              value={filterList[index][1] || ""}
                              onChange={event => {
                                  filterList[index][1] = event.target.value;
                                  onChange(filterList[index], index, column);
                              }}
                              style={{ width: "100%", marginRight: "5%" }}
                          />
                      </FormGroup>
                  </div>
              )
          }
      }
    },
    {
      name: t("DownloadTable.Column.Link"),
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({ style: { minWidth: "50px", maxWidth: "200px", wordWrap: 'break-word' }}),
      }
    },
    {
      name: t("DownloadTable.Column.Download"),
      options: {
        filter: false,
        sort: false,
      }
    }
  ];

  
  const creditSubscriptionColumns = [
    {
      name: t("DownloadTable.Column.Thumbnail"),
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: t("DownloadTable.Column.Code"),
      options: {
        sort: false,
        filter: false
      }
    },
    {
      name: t("DownloadTable.Column.Title"),
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: t("DownloadTable.Column.PhotographerName"),
      options: {
        reset: true,
        sort: false,
        filter: false
      }
    },
    {
      name: t("DownloadTable.Column.DatePurchased"),
      options: {
          reset: true,
          sort: false,
          filterType: "custom" as "custom",
          filterList: datePurchased || null,
          customFilterListOptions: {
              render: (v: any) => {
                  if (v[0] && v[1]) {
                      return `Start date: ${v[0]}, End date: ${v[1]}`;
                  } else if (v[0]) {
                      return `Start date: ${v[0]}`;
                  } else if (v[1]) {
                      return `End date: ${v[1]}`;
                  }
                  return " ";
              },
              update: (filterList: any, filterPos: any, index: any) => {
                  toggleDatePurchased([]);
                  if (filterPos === 0) {
                      filterList[index].splice(filterPos, 1, '');
                  } else if (filterPos === 1) {
                      filterList[index].splice(filterPos, 1);
                  } else if (filterPos === -1) {
                      filterList[index] = [];
                  }

                  return filterList;
              },
          },
          filterOptions: {
              names: [],
              display: (filterList: any, onChange: any, index: any, column: any) => (
                  <div>
                      <FormLabel>Date Purchased</FormLabel>
                      <FormGroup style={{width: '300px'}} row>
                          <TextField
                              id="startDate"
                              label="From"
                              type="date"
                              autoComplete="off"
                              InputLabelProps={{
                                  shrink: true
                              }}
                              value={filterList[index][0] || ""}
                              onChange={event => {
                                  filterList[index][0] = event.target.value;
                                  onChange(filterList[index], index, column);
                              }}
                              style={{ width: "100%", marginRight: "5%" }}
                          />
                          <TextField
                              id="endDate"
                              label="To"
                              type="date"
                              autoComplete="off"
                              InputLabelProps={{
                                  shrink: true
                              }}
                              value={filterList[index][1] || ""}
                              onChange={event => {
                                  filterList[index][1] = event.target.value;
                                  onChange(filterList[index], index, column);
                              }}
                              style={{ width: "100%", marginRight: "5%" }}
                          />
                      </FormGroup>
                  </div>
              )
          }
      }
    },
    {
      name: t("DownloadTable.Column.Link"),
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({ style: { minWidth: "50px", maxWidth: "200px", wordWrap: 'break-word' }}),
      }
    },
    {
      name: t("DownloadTable.Column.Download"),
      options: {
        filter: false,
        sort: false,
      }
    }
  ];

  const options: MUIDataTableOptions = {
    pagination: true,
    count: data?.userOrderList?.totalOrders,
    jumpToPage: true,
    selectableRowsHeader: true,
    selectableRowsOnClick: false,
    selectableRows: "none",
    filter: true,
    filterType: "dropdown",
    serverSide: true,
    searchText: searchString,
    page: pageNumber,
    rowsPerPage: rowsPerPage,
    fixedHeader: false,
    responsive: "standard",
    download: false,
    print: false,
    textLabels: {
      body: {
        noMatch: loading ?
          <SyncLoader css={`display: block; margin: auto 0; width: 100%; height: 100%; z-index: 100;`}
            size={20} color={"#36D2B3"} loading={loading} />
          :
          orders.length === 0 && t("DownloadTable.NoResults.Message"),
      },
    },
    customSearchRender: customSearchRender,
    onChangePage: (currentPage: number) => {
      fetchOrders({ variables: {  
          photographerName: "",
          size: selectedSize.toLocaleLowerCase() || "",
          orderDate: datePurchased.length > 0 ? datePurchased : [],
          searchString: searchString || "",  
          pageNumber: currentPage + 1,
          contentPerPage: rowsPerPage,
          licenseOrigin: selectedLicenseOrigin,
          userId: currentUser.id 
        } 
      });
      setPageNumber(currentPage);
    },
    onChangeRowsPerPage: (numberOfRows: number) => {
      setRowsPerPage(numberOfRows);
    },
    onFilterChange: (changedColumn: any, filterList: any, type: any) => {
      if (type === 'reset') {
          setPageNumber(0);
          toggleSize("");
          toggleDatePurchased([]);
      }
      else{
        const index = selectedLicenseOrigin === LicenseOrigins.STANDARD ? 5 : 4;
        const datePurchasedFrom: string = filterList[index].length === 2 ? filterList[index][0] : "";
        const datePurchasedTo: string = filterList[index].length === 2 ? filterList[index][1] : "";
        if (datePurchasedFrom && datePurchasedTo) {
          toggleDatePurchased([datePurchasedFrom, datePurchasedTo]);
        }
      }
  },
  };

  const getMuiTheme = (options: MUIDataTableOptions) => {
    return createTheme({
      overrides:
      {
        MuiPaper: {
          elevation4: {
            margin: '1%'
          }
        },
        MuiTableCell:
        {
          root:
          {
            paddingLeft: "40px",
            maxWidth: "140px",
          }
        },
        MuiToolbar:
        {
          root:
          {
            width: '95%',
            height: "100px",
            "&:last-child": {
              height: "90px",
            }

          }
        },
        MuiTablePagination:
        {
          root:
          {
            width: "100%",
            display: "flex",
            justifyContent: "center"
          }
        }
      }
    })
  };

  const getExternalLink = (link: string, linkMessage?: string) => {
    if (link !== "N/A") {
      return <Link target="_blank" href={`${link}`} rel="noopener" underline="hover">
        {linkMessage ? linkMessage : "Link"}
      </Link>
    } else {
      return <Link href="#" rel="noopener" underline="hover">
        {link}
      </Link>
    }

  }

  const handleDownloadError = ()=>{
    broadcastError({ severity: SeveritySnackbarEnum.error, message: t("Error.Download.Content") })
  }

  const handleDownload = (id: number,dateTime: string,size: string, code: string)=>{
    toggleDownloadingContents({id,dateTime,size,operation: 'ADD'});
    if(currentUser?.token){
      downloadContent({purchasedImages: [id],token: currentUser.token, fileName: code,dateTime: dateTime, size: size, onDownloaded: toggleDownloadingContents, onError: handleDownloadError})
    }
  }

  const createTableRow = (item: any) => {
    const {pathToFileThumbnail: thumbnail, code, headline} = item.content;
    const datePurchased = item.order.orderDate;
    const size = item.size;
    const photographerName = item?.content?.copyright?.byLine || "N/A";
    const contentId = item.content.id;
    const contentSetId = item.content.contentInSet[0].contentSetId;
    const threadId = item.content.threadContents[0].threadId;
    const articleId = item.content.contentInSet[0].contentSet.article?.articleId;
    const articlePath = `${config.REACT_APP_PUBLIC_BASE}/content/article/${articleId}/set/${contentSetId}/${contentId}/thread/${threadId}`;
    const contentSetPath = `${config.REACT_APP_PUBLIC_BASE}/content/set/${contentSetId}/${contentId}/thread/${threadId}`;
    const imagePath = `${config.REACT_APP_PUBLIC_BASE}/content/${contentId}/thread/${threadId}`;

    const downloadingContent = downloadingContents.findIndex((content: any) => content.id === contentId && content.dateTime === datePurchased && content.size === size) >= 0;
    let link = "";
    if(articleId){
      link = articlePath;
    }
    else if(contentSetId){
      link = contentSetPath;
    }
    else{
      link = imagePath;
    }
    const row = [
      <ImageContainer src={thumbnail} />,
      code,
      headline, 
      photographerName,
      size,
      <Moment format="DD/MM/YYYY">{datePurchased}</Moment>,
      getExternalLink(link),
      <button disabled={downloadingContent} className="download-button" onClick={()=>{
        handleDownload(item.content.id,item.order.orderDate,size,item.content.code)
      }}>
          { downloadingContent ? <CircularProgress style={{color:'white'}} size={15}/> : <span><FontAwesomeIcon className='icon' icon={faCloudDownload}/>
          Download</span> }
      </button>
    ];
    if(value !== 0){
      row.splice(4,1);
    }
    return row;
  }

  return (
    <div>
      <div className="refetch-container">
      <h2 style={{margin: '0 1.2rem'}}>{t("MyDownloads.Title")}</h2>
        <RefreshDateTime loading={loading} autoRefreshOn={dataTableRefreshState}
          refreshTime={30000} refreshFunc={callRefetch} toggleReduxFunction={toggleDataTableRefreshState} />
      </div>
      
      <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs TabIndicatorProps={{ style: { backgroundColor: 'white'}}}  style={{margin: '0 2.7rem'}} value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab style={{borderRadius: '10px', marginBottom: '0.2rem'}} label="Standard" wrapped/>
              <Tab style={{borderRadius: '10px', marginBottom: '0.2rem'}} label="Credit"  wrapped/>
              <Tab style={{borderRadius: '10px', marginBottom: '0.2rem'}} label="Subscription" wrapped />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <MuiThemeProvider theme={getMuiTheme(options)}>
              <MUIDataTable title={""} columns={standardColumns} options={options}
                data={orders.map((item: any) => createTableRow(item))} />
            </MuiThemeProvider>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <MuiThemeProvider theme={getMuiTheme(options)}>
              <MUIDataTable title={""} columns={creditSubscriptionColumns} options={options}
                data={orders.map((item: any) => createTableRow(item))} />
            </MuiThemeProvider>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <MuiThemeProvider theme={getMuiTheme(options)}>
              <MUIDataTable title={""} columns={creditSubscriptionColumns} options={options}
                data={orders.map((item: any) => createTableRow(item))} />
            </MuiThemeProvider>
          </TabPanel>
      </Box>
      <SnackbarComponent showSnackbar={updateCurrencySnackbarShow} handleClose={handleClose}
        severity={updateError.severity}
        message={updateError.message} />
      <SnackbarComponent showSnackbar={fetchErrorSnackbarShow} handleClose={handleCloseFetchError}
        severity={fetchError.severity}
        message={fetchError.message} />
    </div>
  );
}

const mapStateToProps = (state: StoreState): {
  currentUser: User; updateError: IBroadcastMessage; contentStatusFilter: string; contentTypeFilter: string;
  dataTableRefreshState: boolean; fetchError: IBroadcastMessage; downloadingContents: number[]; datePurchased: string[]; selectedSize: string;
} => {
  return {
    currentUser: selectCurrentUser(state),
    updateError: selectUploadError(state),
    contentStatusFilter: selectContentStatus(state),
    contentTypeFilter: selectContentType(state),
    dataTableRefreshState: selectDataTableRefreshState(state),
    downloadingContents: selectDownloadingContents(state),
    datePurchased: selectDatePurchased(state),
    selectedSize: selectedSize(state),
    fetchError: selectFetchError(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<TTableFiltersReducerActions | TUserReducerActions | TDownloadActions>) => {
  return {
    resetTableFilterAction: () => dispatch<IToggleResetFilter>({ type: TableFilterActions.RESET_TABLE_FILTERS }),
    toggleDataTableRefreshState: (data: boolean) => dispatch<IToggleDataTableRefreshState>({
      type: UserActionTypes.TOGGLE_DATA_TABLE_REFRESH_STATE,
      data: data
    }),
    broadcastError: (data: IBroadcastMessage) => dispatch<IBroadcastFetchError>({ type: TableFilterActions.BROADCAST_ERROR, data: data }),
    toggleDownloadingContents: (data: any) => dispatch<IToggleDownloadingContents>({ type: DownloadActionTypes.TOGGLE_DOWNLOADING_CONTENT, data: data }),
    toggleDatePurchased: (data: any) => dispatch<IToggleDatePurchased>({ type: DownloadActionTypes.TOGGLE_DATE_PURCHASED, data: data }),
    toggleSize: (data: any) => dispatch<IToggleSizePurchased>({ type: DownloadActionTypes.TOGGLE_SIZE_PURCHASED, data: data }),
    clearBroadcastErrorAction: () => dispatch<IClearBroadcastFetchError>({ type: TableFilterActions.CLEAR_BROADCAST })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyDownloadsPage);
