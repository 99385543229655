import React, { useState, useEffect } from 'react';
import { ContentFromOptions, ContentTypesForSearch, SearchBarResultsProps, SearchCategoryTypes, decomposeSearchQueryUrl, SearchBarFiltersProps, composeSearchQueryUrl, SearchBarUrlProps } from './searchBar.types';
import { SEARCH_USERS, GET_ALL_SEARCH_RESULTS } from './queries';
import { useLazyQuery } from '@apollo/react-hooks';
import { Dispatch } from 'redux';
import { IBroadcastSearchMessage, IClearAddToCartBroadcast, IContentPerPage, ILoadContentSearchResults, ILoadSearchResultsCount, ILoadUserSearchResults, IResetSearchResults, IToggleFilterChannel, IToggleFilterColour, IToggleFilterContentFrom, IToggleFilterContentType, IToggleFilterDate, IToggleFilterLocation, IToggleFilterSubchannel, IToggleFilterThread, IToggleSearchString, TSearchReducerActions } from '../../redux/search-bar/search-bar.actions';
import { CallHistoryMethodAction, push } from 'connected-react-router';
import { UserSource, SearchBarActions, LocationFilterProps } from '../../redux/search-bar/search-bar.types';
import { StoreState } from '../../redux/root-reducer';
import { connect } from 'react-redux';
import SearchResultsFilters from './searchResultsFilters';
import {
    selectAddToCartBroadcastMessage,
    selectChannelFilter, selectColourFilter, selectContentFromFilter,
    selectContentPerPage,
    selectContentSearchResults,
    selectContentTypeFilter, selectDateFilter,
    selectLocationFilter,
    selectSearchBarExpanded,
    selectSearchBroadcastMessage,
    selectSearchString,
    selectSubchannelFilter,
    selectThreadFilter,
    selectTotalResultsSearch,
    selectUserSearchResults
} from '../../redux/search-bar/search-bar.selectors';
import './searchBarResults.styles.scss';
import { SnackbarComponent } from 'shared';
import ResultsInfiniteLoader from './resultsInfiniteLoader';
import SyncLoader from 'react-spinners/SyncLoader';
import { Channel } from '../home-component/home.types';
import { SubChannel, Thread } from '../channelsSidebar/channelSideBars.types';
import { GET_THREAD_BY_ID, GET_CHANNEL_BY_ID, GET_SUBCHANNEL_BY_ID } from '../channels/queries';
import { useTranslation } from 'react-i18next';
import { selectCurrentChannel } from '../../redux/channel-routes/channel.selectors';
import TrendingContent from '../trendingContent/TrendingContent';
import SearchIcon from '@material-ui/icons/Search';
import { UserUploadedContent } from '../contributor-content/contributor-content.types';
import { IBroadcastMessage, SeveritySnackbarEnum } from '../batch-upload/ContentUpload.types';
import { selectCurrentUser } from '../../redux/user/user.selectors';
import { User } from '../../redux/user/user.types';

const SearchBarResults: React.FC<SearchBarResultsProps> = ({ ...props }) => {
    const { totalResults, searchUrl, searchString, addToCartBroadcastMessage,
        contentFrom, contentType, dateFilter, locationFilter, colourFilter, channelFilter,
        subchannelFilter, threadFilter, selectedChannel, searchBarExpanded, searchBroadcastMessage, contentPerPage, currentUser,toggleContentPerPage, clearAddToCartBroadcast,
        loadTotalResultsAction, loadContentSearchResultsAction,
        loadUserSearchResultsAction, toggleSearchStringAction, toggleContentFromFilter,
        toggleContentTypeFilter, toggleDateFilter, toggleFilterColour, toggleLocationFilter,
        toggleChannelFilterAction, toggleSubchannelFilterAction, toggleThreadFilterAction, resetSearchResultsAction,
        redirectToSearchResultsPage, broadcastSearchMessage } = props;

    const { t } = useTranslation();
    const [searchParamsFromUrl, setSearchParamsFromUrl] = useState<SearchBarUrlProps | null>(null);
    const [finishedLoading, setFinishedLoading] = useState(false);
    const [finishedSettingFilters, setFinishedSettingFilters] = useState(false);
    const [showSearchSnackbar, setSearchSnackbar] = useState(false);
    const [totalPage, setTotalPage] = useState(0);
    const [showNoResultsFound, setShowNoResultsFound] = useState(false);
    const [showAddToCartSnackbar, setShowAddToCartSnackbar] = useState(false);

    //INFINITE LOADING AND TILE DATA
    const [pageNumber, setPageNumber] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [getChannel, { data: channelData }] = useLazyQuery(GET_CHANNEL_BY_ID);
    const [getSubchannel, { data: subchannelData }] = useLazyQuery(GET_SUBCHANNEL_BY_ID);
    const [getThread, { data: threadData }] = useLazyQuery(GET_THREAD_BY_ID);

    const [searchAll, { data: dataAll, loading: loadingAll, error: errorAll, refetch: refetchAll }] = useLazyQuery(GET_ALL_SEARCH_RESULTS, { fetchPolicy: "no-cache" });

    const isContentTypeSelected = Object.values(ContentTypesForSearch).includes(contentType as ContentTypesForSearch);

    useEffect(()=>{
        return ()=>{
            clearAddToCartBroadcast();
        }
    },[])

    useEffect(() => {
        if (addToCartBroadcastMessage.severity && addToCartBroadcastMessage.message) {
            setShowAddToCartSnackbar(true);
        }
        else{
            setShowAddToCartSnackbar(false);
        }
    }, [addToCartBroadcastMessage])

    useEffect(() => {
        if (dataAll && dataAll.search) {
            const results = dataAll.search.userUploadedContent;
            if(totalPage === 0){
                let total = Math.floor(dataAll.search.allResultsCount/contentPerPage);
                if(dataAll.search.allResultsCount % contentPerPage !== 0){
                    total += 1;
                }
                setTotalPage(total);
            }
            if (dataAll.search.allResultsCount === 0) {
                setShowNoResultsFound(true);
            } else {
                setShowNoResultsFound(false);
            }
            loadContentSearchResultsAction([...results]);
        }
    }, [dataAll?.search?.allResultsCount]);
    
    useEffect(()=>{
        if(dataAll && dataAll.search){
            let total = Math.floor(dataAll.search.allResultsCount/contentPerPage);
            if(contentPerPage < dataAll.search.allResultsCount && dataAll.search.allResultsCount % contentPerPage !== 0){
                total += 1;
            }
            setTotalPage(total);
            searchContentQuery(0);
            if(contentPerPage === 100){
                setPageNumber(0);
            }
        }
    },[contentPerPage])

    useEffect(() => {
        if (searchBroadcastMessage.severity && searchBroadcastMessage.message) {
            setSearchSnackbar(true);
        }
    }, [searchBroadcastMessage])

    useEffect(() => {
        if (errorAll) {
            broadcastSearchMessage({
                severity: SeveritySnackbarEnum.error,
                message: `Error occured while performing the search request: ${errorAll.message}`
            });
            setFinishedLoading(true);
            setShowNoResultsFound(true)
        }

    }, [errorAll])

    useEffect(() => {
        if (searchUrl) {
            resetSearchResults();
            setFinishedSettingFilters(false);
            setFinishedLoading(false);
            setSearchParamsFromUrl(decomposeSearchQueryUrl(searchUrl));
        }
    }, [searchUrl])

    useEffect(() => {
        if (searchParamsFromUrl) {
            toggleFiltersFromUrl(searchParamsFromUrl);
        }
    }, [searchParamsFromUrl])

    useEffect(() => {
        if (finishedLoading && !searchBarExpanded) {
            updateSearchUrl();
            callSearch();
        }
    }, [contentType, searchString, contentFrom, dateFilter, locationFilter, colourFilter, channelFilter, subchannelFilter, threadFilter])

    useEffect(() => {
        if (finishedSettingFilters && !finishedLoading) {
            callSearch();
        }
    }, [finishedSettingFilters])

    useEffect(() => {
        if (channelData && channelData.getChannelById) {
            toggleChannelFilterAction(channelData.getChannelById);
            //set flag to true if there is no subchannel filter
            if (searchParamsFromUrl && !searchParamsFromUrl.subchannelFilterUrl) {
                setFinishedSettingFilters(true);
            }
        }
    }, [channelData]);

    useEffect(() => {
        if (subchannelData && subchannelData.getSubchannelById) {
            toggleSubchannelFilterAction(subchannelData.getSubchannelById);
            //set flag to true if there is no subchannel filter
            if (searchParamsFromUrl && !searchParamsFromUrl.threadFilterUrl) {
                setFinishedSettingFilters(true);
            }
        }
    }, [subchannelData]);

    useEffect(() => {
        if (threadData && threadData.getThreadById) {
            toggleThreadFilterAction(threadData.getThreadById);
            setFinishedSettingFilters(true);
        }
    }, [threadData]);

    useEffect(() => {
        if (dataAll && dataAll.search) {
            const searchResults = dataAll.search.userUploadedContent;

            loadTotalResultsAction(dataAll.search.allResultsCount);


            if (dataAll.search.allResultsCount === 0) {
                resetSearchResults();
            } else {
                loadContentSearchResultsAction(searchResults);
            }
            setFinishedLoading(true);
        }
    }, [dataAll]);

    const handleShowAddToCartSnackbarClose = () => {
        setShowAddToCartSnackbar(false);
        clearAddToCartBroadcast();
    };

    const toggleFiltersFromUrl = (params: SearchBarUrlProps) => {
        const { searchStringUrl, searchTypeUrl, contentFromUrl, contentTypeUrl, dateFilterUrl,
            countryFilterUrl, provinceFilterUrl, cityFilterUrl, channelFilterUrl, subchannelFilterUrl, threadFilterUrl } = params;
        toggleSearchStringAction(searchStringUrl);

        if (contentFromUrl && (Object.values(ContentFromOptions).filter((value: string) => value === contentFromUrl)).length > 0) {
            toggleContentFromFilter(contentFromUrl as ContentFromOptions);
        } else if (contentFromUrl) {
            toggleContentFromFilter(parseInt(contentFromUrl));
        }

        if (contentTypeUrl && isContentTypeSelected) {
            toggleContentTypeFilter(contentTypeUrl as ContentTypesForSearch);
        }

        if (dateFilterUrl) {
            const datesArray = dateFilterUrl.split("/").map((dateString: string) => { return new Date(dateString) });
            if (datesArray.length === 2 && datesArray !== dateFilter) {
                toggleDateFilter(datesArray);
            }
        }

        if (countryFilterUrl || provinceFilterUrl || cityFilterUrl) {
            toggleLocationFilter({
                country: countryFilterUrl ? countryFilterUrl : "",
                stateProvince: provinceFilterUrl ? provinceFilterUrl : "",
                city: cityFilterUrl ? cityFilterUrl.split("_") : []
            })
        }

        if (channelFilterUrl) {
            getChannel({ variables: { channelId: parseInt(channelFilterUrl) } });
        } else {
            //if there is no channel filter, that means there are no navigation filters applied, and we set flag to true
            setFinishedSettingFilters(true);
        }

        if (subchannelFilterUrl) {
            getSubchannel({ variables: { subchannelId: parseInt(subchannelFilterUrl) } });
        }

        if (threadFilterUrl) {
            getThread({ variables: { threadId: parseInt(threadFilterUrl) } });
        }
    }

    const resetSearchResults = () => {
        resetSearchResultsAction();
        setPageNumber(0);
        setHasMore(true);
        setTotalPage(0);
    }

    const updateSearchUrl = () => {
        const newSearchUrl = composeSearchQueryUrl({
            searchString,
            searchType: SearchCategoryTypes.CONTENT,
            contentFrom,
            contentType,
            locationFilter,
            dateFilter,
            channelFilter,
            subchannelFilter,
            threadFilter
        });

        if (newSearchUrl !== `/results${searchUrl}`) {
            redirectToSearchResultsPage(newSearchUrl);
        }
    }

    const callSearch = () => {
        if (searchParamsFromUrl) {
            if (searchParamsFromUrl.searchTypeUrl === SearchCategoryTypes.BOTH) {
                searchContentQuery();
                searchUsersQuery(searchString);
            } else if (searchParamsFromUrl.searchTypeUrl === SearchCategoryTypes.CONTENT) {
                searchContentQuery();
            } else if (searchParamsFromUrl.searchTypeUrl == SearchCategoryTypes.MEMBER_PROFILES) {
                searchUsersQuery(searchString);
            }
        }
    }

    const searchContentQuery = (page?: number) => {
        searchAll({
            variables: {
                searchString: decodeURI(encodeURI(searchString)),
                contentFromOptions: typeof contentFrom === 'string' ? contentFrom : undefined,
                contentFromUser: typeof contentFrom === 'number' ? contentFrom : 0,
                contentType,
                dateFilter,
                locationCountryFilter: locationFilter.country,
                locationProvinceFilter: locationFilter.stateProvince,
                locationCityFilter: locationFilter.city,
                channelFilterId: channelFilter.id,
                subchannelFilterId: subchannelFilter.id,
                threadFilterId: threadFilter.id,
                channelFilterTitle: channelFilter.title,
                subchannelFilterTitle: subchannelFilter.title,
                threadFilterTitle: threadFilter.title,
                pageNumber: page !== undefined ? page : pageNumber,
                userId: currentUser?.id,
                contentPerPage
            }
        });
    }

    const searchUsersQuery = (value: string) => {
        //resetSearchResults();
        //TODO: Uncomment when search for users is implemented
        // searchUsers({ variables: { title: value } });
    }

    const isLoading = () => {
        return loadingAll || !finishedLoading;
    }

    const handleClose = () => {
        setSearchSnackbar(false);
    }
    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPageNumber(value);
        searchContentQuery(value-1);
    };
    const handleContentPerPageChange = (event: any)=>{
        toggleContentPerPage(Number(event.target.value));
    }
    return (
        <React.Fragment>
            <div className='search-result-title'>
                Search results for "{searchString}" ({totalResults})
            </div>
            <SearchResultsFilters showSearchBar={true} isInChannelContex={false} />

            <div className="search-results-container">
                <div className="search-results">
                    {isLoading() ?
                        <React.Fragment>
                            <div className="loading-overlay"></div>
                            <SyncLoader css={`position: fixed;
                                z-index: 999; left: 40%`} size={20}
                                color={"#36D2B3"} loading={true} />
                        </React.Fragment>
                        : null}

                   {!showNoResultsFound && 
                   <ResultsInfiniteLoader
                        loadingAll={loadingAll}
                        totalPage={totalPage}
                        pageNumber={pageNumber}
                        handleChange={handleChange}
                        handleContentPerPageChange={handleContentPerPageChange}
                        />}

                    {showNoResultsFound && finishedLoading ?
                        <div className='no-results-container'>
                            <div className="search-icon">
                                <SearchIcon fontSize="large" />
                            </div>
                            <h3 className="heading">
                                Your search {searchString ? <span> for "{searchString}"</span> : ''} didn't return any results.
                            </h3>
                            <p className="suggestions-heading">
                                {t("Suggestions.Label")}
                            </p>
                            <ul className="suggestions">
                                <li>{t("Suggestions.SpelledCorrectly")}</li>
                                <li>{t("Suggestions.DifferentKeywords")}</li>
                                <li>{t("Suggestions.GeneralKeywords")}</li>
                                <li>{t("Suggestions.FewerKeywords")}</li>
                            </ul>
                        </div>
                        : null}
                </div>
                <div className='ads-container'>
                    <TrendingContent />
                </div>
            </div>
            <SnackbarComponent
                showSnackbar={showAddToCartSnackbar}
                handleClose={handleShowAddToCartSnackbarClose}
                severity={addToCartBroadcastMessage.severity}
                message={addToCartBroadcastMessage.message}
            />
        </React.Fragment>
    )
}

const mapStateToProps = (state: StoreState): {
    searchUrl: string; searchString: string;
    totalResults: number; contentFrom: number | ContentFromOptions; contentType: ContentTypesForSearch;
    dateFilter: Date[]; colourFilter: any; locationFilter: LocationFilterProps; channelFilter: Channel;
    subchannelFilter: SubChannel; threadFilter: Thread; selectedChannel: Channel; searchBarExpanded: boolean;
    userSearchResults: UserSource[]; contentSearchResults: UserUploadedContent[]; searchBroadcastMessage: IBroadcastMessage; contentPerPage: number; addToCartBroadcastMessage: IBroadcastMessage; currentUser: User;
} => {
    return {
        searchUrl: state.router.location.search,
        searchString: selectSearchString(state),
        totalResults: selectTotalResultsSearch(state),
        contentFrom: selectContentFromFilter(state),
        contentType: selectContentTypeFilter(state),
        dateFilter: selectDateFilter(state),
        colourFilter: selectColourFilter(state),
        locationFilter: selectLocationFilter(state),
        channelFilter: selectChannelFilter(state),
        subchannelFilter: selectSubchannelFilter(state),
        threadFilter: selectThreadFilter(state),
        selectedChannel: selectCurrentChannel(state),
        searchBarExpanded: selectSearchBarExpanded(state),
        userSearchResults: selectUserSearchResults(state),
        contentSearchResults: selectContentSearchResults(state),
        searchBroadcastMessage: selectSearchBroadcastMessage(state),
        contentPerPage: selectContentPerPage(state),
        addToCartBroadcastMessage: selectAddToCartBroadcastMessage(state),
        currentUser: selectCurrentUser(state),
    }
}

const mapDispatchToProps = (dispatch: Dispatch<TSearchReducerActions | CallHistoryMethodAction>) => {
    return {
        loadTotalResultsAction: (data: number) => dispatch<ILoadSearchResultsCount>({
            type: SearchBarActions.LOAD_TOTAL_RESULTS,
            data: data
        }),
        loadContentSearchResultsAction: (data: UserUploadedContent[]) => dispatch<ILoadContentSearchResults>({
            type: SearchBarActions.LOAD_CONTENT_SEARCH_RESULTS,
            data: data
        }),
        loadUserSearchResultsAction: (data: UserSource[]) => dispatch<ILoadUserSearchResults>({
            type: SearchBarActions.LOAD_USER_SEARCH_RESULTS,
            data: data
        }),
        toggleSearchStringAction: (data: string) => dispatch<IToggleSearchString>({
            type: SearchBarActions.TOGGLE_SEARCH_STRING,
            data: data
        }),
        toggleContentFromFilter: (data: number | ContentFromOptions) => dispatch<IToggleFilterContentFrom>({
            type: SearchBarActions.TOGGLE_FILTER_CONTENT_FROM,
            data: data
        }),
        toggleContentTypeFilter: (data: ContentTypesForSearch) => dispatch<IToggleFilterContentType>({
            type: SearchBarActions.TOGGLE_FILTER_CONTENT_TYPE,
            data: data
        }),
        toggleDateFilter: (data: Date[]) => dispatch<IToggleFilterDate>({
            type: SearchBarActions.TOGGLE_FILTER_DATE,
            data: data
        }),
        toggleLocationFilter: (data: LocationFilterProps) => dispatch<IToggleFilterLocation>({
            type: SearchBarActions.TOGGLE_FILTER_LOCATION,
            data: data
        }),
        toggleFilterColour: (data: string) => dispatch<IToggleFilterColour>({
            type: SearchBarActions.TOGGLE_FILTER_COLOUR,
            data: data
        }),
        toggleChannelFilterAction: (data: Channel) => dispatch<IToggleFilterChannel>({
            type: SearchBarActions.TOGGLE_FILTER_CHANNEL,
            data: data
        }),
        toggleSubchannelFilterAction: (data: SubChannel) => dispatch<IToggleFilterSubchannel>({
            type: SearchBarActions.TOGGLE_FILTER_SUBCHANNEL,
            data: data
        }),
        toggleThreadFilterAction: (data: Thread) => dispatch<IToggleFilterThread>({
            type: SearchBarActions.TOGGLE_FILTER_THREAD,
            data: data
        }),
        resetSearchResultsAction: () => dispatch<IResetSearchResults>({
            type: SearchBarActions.RESET_SEARCH_RESULTS
        }),
        redirectToSearchResultsPage: (url: string) => dispatch(push(url)),
        broadcastSearchMessage: (data: IBroadcastMessage) => dispatch<IBroadcastSearchMessage>({
            type: SearchBarActions.BROADCAST_SEARCH_ERROR,
            data: data
        }),
        toggleContentPerPage: (data: number) => dispatch<IContentPerPage>({
            type: SearchBarActions.TOGGLE_CONTENT_PER_PAGE,
            data:data
        }),
        clearAddToCartBroadcast: () =>
        dispatch<IClearAddToCartBroadcast>({ type: SearchBarActions.CLEAR_ADD_TO_CART_BROADCAST }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchBarResults);
