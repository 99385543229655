import {compose} from 'lodash/fp';
import {removeSpaces, removeSpecialChars} from '../navigation/helperFunctions';

export  function composePathSubChannles(channel : string, subchannel: string) {
  const path = ''+ compose(removeSpaces, removeSpecialChars)(channel) + '/' + compose(removeSpaces, removeSpecialChars)(subchannel)
  return path;
}

export  function composePathThreads(channel : string, subchannel: string, thread: string) {
  const path = ''+ compose(removeSpaces, removeSpecialChars)(channel) + '/' + compose(removeSpaces, removeSpecialChars)(subchannel) + '/' + compose(removeSpaces, removeSpecialChars)(thread);
  return path;
}

export  function composePathChannels(channel : string) {
  const path = ''+ compose(removeSpaces, removeSpecialChars)(channel);
  return path;
}

export function returnStringArrayLast(str : string){
  const stringArr = str.split(" ") ;
}

export function getRandomInt(max : number) {
  return Math.floor(Math.random() * Math.floor(max));
}

export function isDouble(labelText : string) {
 labelText = labelText.replace(/\s/g, '');
 return labelText.length >= 17;
}

export function removeSubArray (myArray : string[], toRemove : string[]) {
 return myArray = myArray.filter( function( el ) {
    return toRemove.indexOf( el ) < 0;
  } );
}

export function includesLash (label : string) {
  return label.includes("/");
}

export function getWordsBeforeThe16letter(buttonTextArr : string[], arrBefore16 : string[]){
  let count = 0;
  let flag = false;
  while(count < 17 && flag === false){
      for(let i = 0; i < buttonTextArr.length; i++){
          let countWordChars = buttonTextArr[i].length;
          count += countWordChars;
          if(i === buttonTextArr.length - 1){
              flag = true;
          }
          if(count >= 17){
              break;
          }
          else{
              arrBefore16.push(buttonTextArr[i]);
          }

      } 
  }
}

export function getButtonTextByLines(buttonTextArr: string[], arrBefore16: string[]){

  let secondLineOfButtonArr = removeSubArray(buttonTextArr, arrBefore16);
        
  let firstLineOfButton = arrBefore16.join(" ");
  let secondLineOfButton = secondLineOfButtonArr.length > 0 ? secondLineOfButtonArr.join(" ") : "";

  return {
    firstLineOfButton,
    secondLineOfButton
  }

}