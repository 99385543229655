import React, { useEffect, useState } from "react";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { GET_SALES } from "./queries";
import { StoreState } from "../../redux/root-reducer";
import { selectCurrentUser, selectDataTableRefreshState } from "../../redux/user/user.selectors";
import { connect } from "react-redux";
import { createTheme } from '@material-ui/core/styles';
import { FormControl, FormGroup, FormLabel, MenuItem, MuiThemeProvider, Select, TextField, Button, Link } from "@material-ui/core";
import Moment from "react-moment";
import './my-sales.styles.scss';
import { ContentStatus, UploadedContentProps, UserUploadedContent } from "./my-sales.types";
import { useTranslation } from "react-i18next";
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import SyncLoader from 'react-spinners/SyncLoader';
import { ImageContainer } from "./my-sales.component";
import { getLocation } from "../locations/locations.functions";
import { User, UserActionTypes } from "../../redux/user/user.types";
import PreferredCurrencyComponent from './prefferedCurrency.component';
import { selectUploadError } from "../../redux/content-upload/content-upload.selectors";
import { SnackbarComponent, SearchBarComponent, RefreshDateTime } from 'shared';
import { IBroadcastMessage, SeveritySnackbarEnum } from "../batch-upload/ContentUpload.types";
import { Autocomplete, InputLabel } from "@mui/material";
import { Thread } from "../channelsSidebar/channelSideBars.types";
import { Channel } from "../home-component/home.types";
import { Subchannel } from "../onboarding/onboarding-page.types";
import { LocationFilterProps } from "../../redux/search-bar/search-bar.types";
import { selectChannelFilter, selectContentStatus, selectContentType, selectFetchError, selectLocationFilter, selectPublishDate, selectSubchannelFilter, selectThreadFilter, selectUploadDate } from "../../redux/table-filter/table-filter.selectors";
import { TableFilterActions } from "../../redux/table-filter/table-filter.types";
import { IBroadcastFetchError, IClearBroadcastFetchError, IToggleFilterChannel, IToggleFilterContentStatus, IToggleFilterContentType, IToggleFilterLocation, IToggleFilterPublishDate, IToggleFilterSubchannel, IToggleFilterThread, IToggleFilterUploadDate, IToggleResetFilter, TTableFiltersReducerActions } from "../../redux/table-filter/table-filter.actions";
import { Dispatch } from "redux";
import { RapidApiCityDto, RapidApiCountryDto, RapidApiProvinceDto } from "../../globals/locations/locations.types";
import axios from "axios";
import { GET_CHANNELS } from "../channels/queries";
import { GET_SUBCHANNELS, GET_THREADS } from "../channelsSidebar/queries";
import { IToggleDataTableRefreshState, TUserReducerActions } from "../../redux/user/user.actions";
import {config} from "../../config";

const MySales: React.FC<UploadedContentProps> = ({ ...props }) => {

  const { t } = useTranslation();
  const { currentUser, history, updateError, contentStatusFilter, contentTypeFilter, uploadDateFilter, publishDateFilter,
    channelFilter, subchannelFilter, threadFilter, locationFilter, dataTableRefreshState, fetchError,
    resetTableFilterAction, toggleDataTableRefreshState, broadcastFetchErrorAction, clearBroadcastErrorAction } = props;

  //QUERIES
  const [fetchData, { data, loading, error, refetch }] = useLazyQuery(GET_SALES, {
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    onError(error) {
      broadcastFetchErrorAction({ severity: SeveritySnackbarEnum.error, message: t("Error.Fetch.Sales") })
    }
  });

  //USE STATE HOOKS
  const [sales, setSales] = useState<any>([]);
  const [earnedToday, setEarnedToday] = useState<any>();
  const [earnedAllTime, setEarnedAllTime] = useState<any>();
  const [totalSold, setTotalSold] = useState<any>();

  const [updateCurrencySnackbarShow, setUpdateCurrencySnackbarShow] = useState(false);
  const [fetchErrorSnackbarShow, setFetchErrorSnackbarShow] = useState(false);

  const [pageNumber, setPageNumber] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [searchString, setSearchString] = useState<string>('');

  //USE EFFECT HOOKS
  useEffect(() => {
    if (updateError.severity && updateError.message) {
      setUpdateCurrencySnackbarShow(true);
    }
  }, [updateError]);

  useEffect(() => {
    if (fetchError.severity && fetchError.message) {
      setFetchErrorSnackbarShow(true);
    }
  }, [fetchError]);


  useEffect(() => {
    fetchData({
      variables: {
        userId: currentUser.id,
        pageNumber: 1,
        contentPerPage: rowsPerPage,
      }
    })
    setPageNumber(0);
  }, [rowsPerPage, contentTypeFilter, contentStatusFilter, uploadDateFilter, publishDateFilter, channelFilter, subchannelFilter, threadFilter, locationFilter]);

  useEffect(() => {
    if (data && data.getUserSalesHistory) {
      setSales(data.getUserSalesHistory.contentSales);
      setEarnedToday(data.getUserSalesHistory.todayEarn);
      setEarnedAllTime(data.getUserSalesHistory.totalEarn);
      setTotalSold(data.getUserSalesHistory.totalSales);
    }
  }, [data])

  //FUNCTIONS
  const callRefetch = () => {
    refetch();
  }

  const handleAddMore = () => {
    history.push('/content-upload');
  };

  const handleClose = () => {
    setUpdateCurrencySnackbarShow(false);
  }

  const handleCloseFetchError = () => {
    setFetchErrorSnackbarShow(false);
    clearBroadcastErrorAction();
  }

  const onSubmitSearch = (searchString: string) => {
    fetchData({ variables: { pageNumber: 0, contentPerPage: rowsPerPage, userId: currentUser.id} });
    setPageNumber(0);
    setSearchString(searchString)
  };

  const handleResetSearch = () => {
    fetchData({ variables: { pageNumber: 1, contentPerPage: rowsPerPage, userId: currentUser.id } });
    setPageNumber(0);
    setSearchString("")
  }

  const customSearchRender = (searchText: string, handleSearch: any, hideSearch: any, options: any) => {
    return <SearchBarComponent handleSearch={onSubmitSearch} hideSearch={hideSearch} resetSearch={handleResetSearch} />
  };

  const columns = [
    t("Sales.Column.Preview"),
    t("Sales.Column.Buyer"),
    t("Sales.Column.Code"),
    t("Sales.Column.Headline"),
    t("Sales.Column.Date"),
    t("Sales.Column.Income"),
    t("Sales.Column.Tax"),
  ];

  const options: MUIDataTableOptions = {
    count: totalSold,
    page: pageNumber,
    rowsPerPage: rowsPerPage,
    jumpToPage: true,
    serverSide: true,
    selectableRowsHeader: false,
    selectableRowsOnClick: false,
    selectableRows: "none",
    filter: false,
    search: false,
    fixedHeader: false,
    responsive: "standard",
    download: false,
    print: false,
    searchText: searchString,
    textLabels: {
      body: {
        noMatch: loading ?
          <SyncLoader css={`display: block; margin: auto 0; width: 100%; height: 100%; z-index: 100;`}
            size={20} color={"#36D2B3"} loading={loading} />
          :
          t("Sales.NoResults.Message"),
      },
    },
    customSearchRender: customSearchRender,
    onChangePage: (currentPage: number) => {
      fetchData({
        variables: {
          pageNumber: currentPage,
          contentPerPage: rowsPerPage,
          userId: currentUser.id,
        }
      })
      setPageNumber(currentPage);
    },
    onFilterChange: (changedColumn: any, filterList: any, type: any) => {
      if (type === 'reset') {
        resetTableFilterAction();
      }
    },
    onChangeRowsPerPage: (numberOfRows: number) => {
      setRowsPerPage(numberOfRows);
    }
  };

  const getMuiTheme = (options: MUIDataTableOptions) => {
    return createTheme({
      overrides:
      {
        MuiPaper: {
          elevation4: {
            margin: '1%'
          }
        },
        MuiTableCell:
        {
          root:
          {
            paddingLeft: "40px",
            maxWidth: "140px",
          }
        },
        MuiToolbar:
        {
          root:
          {
            width: '95%',
            height: "100px",
            "&:last-child": {
              height: "90px",
            }

          }
        },
        MuiTablePagination:
        {
          root:
          {
            width: "100%",
            display: "flex",
            justifyContent: "center"
          }
        },
        MuiTypography:
        {
          root:
          {
            textAlign: "left"
          }
        }
      }
    })
  };

  const getExternalLink = (link: string, linkMessage: string) => {
    if (link !== "N/A") {
      return <Link target="_blank" href={`${link}`} rel="noopener" underline="hover">
        {linkMessage ? linkMessage : "Link"}
      </Link>
    } else {
      return <Link href="#" rel="noopener" underline="hover">
        {link}
      </Link>
    }

  }

  const createTableRow = (item: any) => {
    return [
      <ImageContainer src={item.content.pathToFileThumbnail} />,
      item.order.user.person.firstName + " " + item.order.user.person.lastName,
      item.content.code,
      item.content.headline,
      <Moment format="DD/MM/YYYY">{item.order.orderDate}</Moment>,
      `$${item.sellerCommission.toFixed(2)}`,
      item.contentTax,
    ]
  }

  const salesStats = ()=>{
    return <p style={{textAlign: 'left'}}>
      <span style={{fontWeight: 'bold'}}>Today: </span>${earnedToday},  <span style={{fontWeight: 'bold', marginLeft: '1rem'}}>All time: </span>${earnedAllTime},  <span style={{fontWeight: 'bold', marginLeft: '1rem'}}>Sold: </span>{totalSold} Items
      </p>
  }
  return (
    <div>
      <div className="sales-table-heading">
        <h2>{t("Sales.Title")}</h2>
      </div>
      <div className="refetch-container">
        <RefreshDateTime loading={loading} autoRefreshOn={dataTableRefreshState}
          refreshTime={30000} refreshFunc={callRefetch} toggleReduxFunction={toggleDataTableRefreshState} />

        <Button
          size="small"
          className="upload-white-button"
          variant="contained"
          onClick={handleAddMore}
        >
          {t("Sales.AddMore.Button")} +
        </Button>
      </div>
      <MuiThemeProvider theme={getMuiTheme(options)}>
        <MUIDataTable title={salesStats()} columns={columns} options={options}
          data={sales.map((item: any) => createTableRow(item))} />
      </MuiThemeProvider>
      <SnackbarComponent showSnackbar={updateCurrencySnackbarShow} handleClose={handleClose}
        severity={updateError.severity}
        message={updateError.message} />
      <SnackbarComponent showSnackbar={fetchErrorSnackbarShow} handleClose={handleCloseFetchError}
        severity={fetchError.severity}
        message={fetchError.message} />
    </div>
  );
}

const mapStateToProps = (state: StoreState): {
  currentUser: User, updateError: IBroadcastMessage, contentStatusFilter: string, contentTypeFilter: string,
  uploadDateFilter: Date[], publishDateFilter: Date[], channelFilter: Channel,
  subchannelFilter: Subchannel, threadFilter: Thread, locationFilter: LocationFilterProps,
  dataTableRefreshState: boolean, fetchError: IBroadcastMessage
} => {
  return {
    currentUser: selectCurrentUser(state),
    updateError: selectUploadError(state),
    contentStatusFilter: selectContentStatus(state),
    contentTypeFilter: selectContentType(state),
    uploadDateFilter: selectUploadDate(state),
    publishDateFilter: selectPublishDate(state),
    channelFilter: selectChannelFilter(state),
    subchannelFilter: selectSubchannelFilter(state),
    threadFilter: selectThreadFilter(state),
    locationFilter: selectLocationFilter(state),
    dataTableRefreshState: selectDataTableRefreshState(state),
    fetchError: selectFetchError(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<TTableFiltersReducerActions | TUserReducerActions>) => {
  return {
    toggleContentTypeFilterAction: (data: string) => dispatch<IToggleFilterContentType>({ type: TableFilterActions.TOGGLE_TABLE_FILTER_CONTENT_TYPE, data: data }),
    toggleContentStatusFilterAction: (data: string) => dispatch<IToggleFilterContentStatus>({ type: TableFilterActions.TOGGLE_TABLE_FILTER_CONTENT_STATUS, data: data }),
    toggleUploadDateFilterAction: (data: Date[]) => dispatch<IToggleFilterUploadDate>({ type: TableFilterActions.TOGGLE_TABLE_FILTER_UPLOAD_DATE, data: data }),
    togglePublishDateFilterAction: (data: Date[]) => dispatch<IToggleFilterPublishDate>({ type: TableFilterActions.TOGGLE_TABLE_FILTER_PUBLISH_DATE, data: data }),
    toggleChannelFilterAction: (data: Channel) => dispatch<IToggleFilterChannel>({ type: TableFilterActions.TOGGLE_TABLE_FILTER_CHANNEL, data: data }),
    toggleSubchannelFilterAction: (data: Subchannel) => dispatch<IToggleFilterSubchannel>({ type: TableFilterActions.TOGGLE_TABLE_FILTER_SUBCHANNEL, data: data }),
    toggleThreadFilterAction: (data: Thread) => dispatch<IToggleFilterThread>({ type: TableFilterActions.TOGGLE_TABLE_FILTER_THREAD, data: data }),
    toggleLocationFilterAction: (data: LocationFilterProps) => dispatch<IToggleFilterLocation>({ type: TableFilterActions.TOGGLE_TABLE_FILTER_LOCATION, data: data }),
    resetTableFilterAction: () => dispatch<IToggleResetFilter>({ type: TableFilterActions.RESET_TABLE_FILTERS }),
    toggleDataTableRefreshState: (data: boolean) => dispatch<IToggleDataTableRefreshState>({
      type: UserActionTypes.TOGGLE_DATA_TABLE_REFRESH_STATE,
      data: data
    }),
    broadcastFetchErrorAction: (data: IBroadcastMessage) => dispatch<IBroadcastFetchError>({ type: TableFilterActions.BROADCAST_ERROR, data: data }),
    clearBroadcastErrorAction: () => dispatch<IClearBroadcastFetchError>({ type: TableFilterActions.CLEAR_BROADCAST })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MySales);
