import axios from "axios";
import fileDownload from "js-file-download";
import {config as conf} from '../../config';
import { selectCurrentUser } from "../../redux/user/user.selectors";
import { User } from "../../redux/user/user.types";
import { StoreState } from "../../redux/root-reducer";
import { connect, useSelector } from "react-redux";

interface DownloadDetails{
    purchasedImages: number[] | number;
    token: string;
    fileName: string;
    dateTime?: string;
    size?: string;
    onDownloaded?: (data: any) => void;
    onError?: () => void;
}
export const downloadContent = (props: DownloadDetails): void=>{
    const {purchasedImages, token, fileName, dateTime, size} = props;
    axios({
        url: `${conf.REACT_APP_PUBLIC_SERVER_HOST}/download?contentIds=[${purchasedImages}]`,
        params: {
            "token": token
        },
        method: "GET",
        responseType: "arraybuffer",
    }).then((res) => {
        fileDownload(res.data, `${fileName}.zip`);
        const downloadedContentIdParameter: any = res?.config?.url?.split("=")[1];
        const downloadedContentId: number = parseInt(downloadedContentIdParameter.substr(1,downloadedContentIdParameter.length-2))
        if(props.onDownloaded)props.onDownloaded({id: downloadedContentId,dateTime: dateTime,size: size,operation: 'REMOVE'})
    }).catch(error =>{
        if(props.onError)props.onError();
        const downloadedContentIdParameter: string = error?.config?.url.split("=")[1];
        const downloadedContentId: number = parseInt(downloadedContentIdParameter.substr(1,downloadedContentIdParameter.length-2))
        if(props.onDownloaded)props.onDownloaded({id: downloadedContentId,dateTime: dateTime,size: size,operation: 'REMOVE'})
    });
}

