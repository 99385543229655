import { EditorsInformation, OnboardingActionTypes, OnboardingState } from './onboarding.types';
import { TOnboardingReducerAction } from './onboarding.actions';
import { LicensingTypes } from '../../components/batch-upload/EditContentMetadata.types';
import { validateOnboardingData } from './onboarding.functions';
import { IBroadcastMessage } from 'components/batch-upload/ContentUpload.types';

const InitialState: OnboardingState = {
    generalInformationPerson: { firstName: '', lastName: '', vatNumber: '', yearOfBirth: 0 },
    generalInformationCompany: { organizationName: '', vatNumber: '' },
    personResponsibleForAccount: { firstName: '', lastName: '', email: '', phoneNumber: '', jobTitle: '' },
    mailingAddress: { country: '', countryCode: '', state: '', stateCode: '', city: '', zipCode: '', addressLineOne: '', addressLineTwo: '' },
    billingAddress: { country: '', countryCode: '', state: '', stateCode: '', city: '', zipCode: '', addressLineOne: '', addressLineTwo: '' },
    licensing: { licenseType: LicensingTypes.STANDARD, prefferedCurrency: '', agreeForMerchandise: false }, //only for contributors
    appropriateChannels: { channels: [] }, //only for contributors
    aboutYou: { profileImage: null, shortBiography: '' }, //not required
    editorsInformation: [], //not required and only for organizations
    profileImage: null, //not required
    accordionsState: {toggleGeneralInformation: true, toggleResponsiblePerson: false, toggleMailingAddress: false, 
                        toggleBillingAddress: false, toggleLicensing: false, toggleAppropriateChannels: false,
                        toggleAboutYou: false, toggleEditorsInformation: false},
    isUseMailingAddressChecked: false,
    isOnboardingValid: {status: false, validationMessage: ""},
    isValidEditorAccordion: false,
    onboardingError: {} as IBroadcastMessage
};

export const onboardingReducer = (state = InitialState, action: TOnboardingReducerAction): OnboardingState => {
    switch (action.type) {
        case OnboardingActionTypes.GENERAL_INFO_PERSON: {
            return {
                ...state,
                generalInformationPerson: action.data
            }
        }
        case OnboardingActionTypes.GENERAL_INFO_COMPANY: {
            return {
                ...state,
                generalInformationCompany: action.data
            }
        }
        case OnboardingActionTypes.PERSON_RESPONSIBLE_FOR_ACCOUNT: {
            return {
                ...state,
                personResponsibleForAccount: action.data
            }
        }
        case OnboardingActionTypes.MAILING_ADDRESS: {
            return {
                ...state,
                mailingAddress: action.data
            }
        }
        case OnboardingActionTypes.BILLING_ADDRESS: {
            return {
                ...state,
                billingAddress: action.data
            }
        }
        case OnboardingActionTypes.LICENSING: {
            return {
                ...state,
                licensing: action.data
            }
        }
        case OnboardingActionTypes.APPROPRIATE_CHANNELS: {
            return {
                ...state,
                appropriateChannels: action.data
            }
        }
        case OnboardingActionTypes.ABOUT_YOU: {
            return {
                ...state,
                aboutYou: action.data
            }
        }
        case OnboardingActionTypes.EDITORS_INFORMATION: {
            return {
                ...state,
                editorsInformation: action.data
            }
        }
        case OnboardingActionTypes.ADD_PROFILE_IMAGE: {
            return {
                ...state,
                profileImage: action.data
            }
        }
        case OnboardingActionTypes.REMOVE_PROFILE_IMAGE: {
            return {
                ...state,
                profileImage: null
            }
        }
        case OnboardingActionTypes.TOGGLE_GENERAL_INFORMATION: {
            return{
                ...state,
                accordionsState: {...state.accordionsState, toggleGeneralInformation: true}
            }
        }
        case OnboardingActionTypes.TOGGLE_RESPONSIBLE_PERSON: {
            return  {
                ...state,
                accordionsState: { ...state.accordionsState, toggleResponsiblePerson: true }
            }
        }
        case OnboardingActionTypes.TOGGLE_MAILING_ADDRESS: {
            return  {
                ...state,
                accordionsState: {...state.accordionsState, toggleMailingAddress: action.data}
            }
        }
        case OnboardingActionTypes.TOGGLE_BILLING_ADDRESS: {
            return  {
                ...state,
                accordionsState: {...state.accordionsState, toggleBillingAddress: action.data}
            }
        }
        case OnboardingActionTypes.TOGGLE_LICENSING: {
            return  {
                ...state,
                accordionsState: {...state.accordionsState, toggleLicensing: true}
            }
        }
        case OnboardingActionTypes.TOGGLE_APPROPRIATE_CHANNELS: {
            return  {
                ...state,
                accordionsState: {...state.accordionsState, toggleAppropriateChannels: true}
            }
        }
        case OnboardingActionTypes.TOGGLE_ABOUT_YOU: {
            return  {
                ...state,
                accordionsState: {...state.accordionsState, toggleAboutYou: true}
            }
        }
        case OnboardingActionTypes.TOGGLE_EDITORS_INFORMATION: {
            return  {
                ...state,
                accordionsState: {...state.accordionsState, toggleEditorsInformation: true}
            }
        }
        case OnboardingActionTypes.REMOVE_EDITOR: {
            return {
                ...state,
                editorsInformation: state.editorsInformation.filter((editor: EditorsInformation) => editor.id !== action.data)
            }
        }
        case OnboardingActionTypes.SET_IS_MAILING_ADDRESS_CHECKED: {
            return {
                ...state,
                isUseMailingAddressChecked: action.data
            }
        }
        case OnboardingActionTypes.VALIDATE_ONBOARDING_DATA: {
            return {
                ...state,
                isOnboardingValid: validateOnboardingData(state, action.data)
            }
        }
        case OnboardingActionTypes.VALIDATE_EDITORS_ACCORDION: {
            return {
                ...state,
                isValidEditorAccordion: action.data
            }
        }
        case OnboardingActionTypes.BROADCAST_ONBOARDING_ERROR: {
            return {
                ...state,
                onboardingError: action.data
            }
        }
        case OnboardingActionTypes.CLEAR_ONBOARDING_BROADCAST: {
            return {
                ...state,
                onboardingError: {} as IBroadcastMessage
            }
        }
        case OnboardingActionTypes.RESET_ONBOARDING: {
            return InitialState;
        }
        default:
            return state;
    }
}

