import { RegisterWraperActionTypes, RegisterWraperState} from './register-modal-wraper.types';
import { TRegisterModalWraperReducerActions } from './register-modal-wraper.actions';
import { string } from 'yup';

const InitialState: RegisterWraperState = {
    hidden: true,

};

export const registerModalWraperReducer = (state = InitialState, action: TRegisterModalWraperReducerActions) : RegisterWraperState => {
    switch(action.type) {
        case RegisterWraperActionTypes.TOGGLE_REGISTER_WRAPER_HIDDEN:
            return { 
                ...state,
                hidden: action.data
            };
        default:
            return state;
    }
}
export default registerModalWraperReducer;