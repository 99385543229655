import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import SubscriptionPayment from "./get-license-payment";
import { stepperTheme } from "../cart/cart-page.types";
import { Stepper, Step, StepLabel, MuiThemeProvider } from "@material-ui/core";
import { LicenseBuyingSteps, LicensePaymentPageProps } from "./my-license-page.types";
import { useTranslation } from "react-i18next";
import GetLicensingPlanPage from "./get-license-page.component";
import "./my-license-page.styles.scss";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { CallHistoryMethodAction, push } from "connected-react-router";
import { IBroadcastMessage, SeveritySnackbarEnum } from "../batch-upload/ContentUpload.types";
import { CompleteBanner, SnackbarComponent } from  "shared";
import { StoreState } from "../../redux/root-reducer";
import { selectSubscriptionBroadcast } from "../../redux/subscriptions/subscription-page.selector";
import { IBroadcastSubscriptionMessage, IClearBroadcast, TSubscriptionReducerActions } from "../../redux/subscriptions/subscription-page.actions";
import { SubscriptionActionTypes } from "../../redux/subscriptions/subscription-page.types";
import {config} from "../../config";

export const GetLicensePaymentPage: React.FC<LicensePaymentPageProps> = ({ ...props }) => {
  const {subscriptionBroadcast, redirectToLicensePage, broadcastSubscriptionAction, clearBroadcast} = props;
  const [paymentSnackbar, setPaymentSnackbar] = useState(false);
  const [stripePromise, setStripePromise] = useState(null);

  const [steps] = useState([
    LicenseBuyingSteps.SELECT_PLAN,
    LicenseBuyingSteps.PAYMENT,
    LicenseBuyingSteps.COMPLETE
  ]);
  const [currentStep, setCurrentStep] = useState(LicenseBuyingSteps.SELECT_PLAN);
  const { t } = useTranslation();

  useEffect(() => {
    if(stripePromise === null)
      loadStripeInstance();
  }, [])

  useEffect(() => {
    if(subscriptionBroadcast.message && subscriptionBroadcast.severity) {
      setPaymentSnackbar(true);
    }
  }, [subscriptionBroadcast])

  const loadStripeInstance = async () => {
    await loadStripe(config.REACT_APP_STRIPE_LOAD_KEY!)
      .then((result: any) => {
        setStripePromise(result);
      }).catch((error: any) => {
        broadcastSubscriptionAction({severity: SeveritySnackbarEnum.error, message: "Failed to load instance of Stripe"});
      })
  }

  const getCurrentStepIndex = () => {
    const currentStepIndex = steps.findIndex(step => step === currentStep);
    return currentStepIndex;
  }

  const handleNextPage = () => {
    setCurrentStep(steps[getCurrentStepIndex() + 1]);
  };

  const handleClose = () => {
    setPaymentSnackbar(false);
    clearBroadcast();
  }

  const getStepContent = () => {
    if(currentStep === LicenseBuyingSteps.SELECT_PLAN) {
      return <GetLicensingPlanPage
                handlePageChange={handleNextPage}/>
    } else if(currentStep === LicenseBuyingSteps.PAYMENT) {
      return <Elements stripe={stripePromise}>
              <SubscriptionPayment handlePageChange={handleNextPage}/>
            </Elements>
    } else if(currentStep === LicenseBuyingSteps.COMPLETE) {
      return <CompleteBanner redirect={redirectToLicensePage} headerText={t("License.Complete.Banner.Heading")} isInOnboarding={true}
        heading={t("License.Complete.Banner.Caption")} contentText={t("License.Complete.Banner.ContentText")}
        imagePath="https://bucket-wi-dev1.s3.eu-north-1.amazonaws.com/home/health.jpg"/>
    }
  }

  return (
    <div className="page-wrapper">
      <MuiThemeProvider theme={stepperTheme}>
        <Stepper activeStep={getCurrentStepIndex()} alternativeLabel>
          {steps.map((label: any) => (
            <Step key={label}>
              <StepLabel>{t(label)}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </MuiThemeProvider>
      {getStepContent()}

      <SnackbarComponent
        showSnackbar={paymentSnackbar}
        handleClose={handleClose}
        severity={subscriptionBroadcast.severity}
        message={subscriptionBroadcast.message}
        />
    </div>
  );
};

const mapStateToProps = (state: StoreState): { subscriptionBroadcast: IBroadcastMessage; } => {
  return {
    subscriptionBroadcast: selectSubscriptionBroadcast(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<CallHistoryMethodAction | TSubscriptionReducerActions>) => {
  return {
    redirectToLicensePage: () => dispatch(push(`/my-license-page`)),
    broadcastSubscriptionAction: (data: IBroadcastMessage) => dispatch<IBroadcastSubscriptionMessage>({
      type: SubscriptionActionTypes.BROADCAST_SUBSCRIPTION_MESSAGE, data: data
    }),
    clearBroadcast: () => dispatch<IClearBroadcast>({type: SubscriptionActionTypes.CLEAR_BROADCAST})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GetLicensePaymentPage);
