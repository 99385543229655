import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Paper, Popper, Grow, ClickAwayListener, TextField } from '@material-ui/core';
import { LocationFilterComponentProps } from './searchBar.types';
import { StoreState } from '../../redux/root-reducer';
import { LocationFilterProps, SearchBarActions } from '../../redux/search-bar/search-bar.types';
import { IBroadcastSearchMessage, IResetLocationsFilter, IToggleFilterLocation, TSearchReducerActions } from '../../redux/search-bar/search-bar.actions';
import { Dispatch } from 'redux';
import { selectLocationFilter } from '../../redux/search-bar/search-bar.selectors';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RapidApiCityDto, RapidApiCountryDto, RapidApiProvinceDto } from '../../globals/locations/locations.types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import SyncLoader from 'react-spinners/SyncLoader';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { selectUserCoordinates, selectUserLocationPermission } from '../../redux/user/user.selectors';
import './searchResultsFilters.styles.scss'
import { UserActionTypes, UserCoordinates } from '../../redux/user/user.types';
import { IBroadcastMessage, SeveritySnackbarEnum } from '../batch-upload/ContentUpload.types';
import { IToggleUserCurrentLocation, TUserReducerActions } from '../../redux/user/user.actions';
import {config} from "../../config";

const LocationFilterComponent: React.FC<LocationFilterComponentProps> = ({ ...props }) => {
    const { locationFilter, isInChannelContex, userLocationPermission, userCoordinates, 
        toggleUserLocationPopup, toggleLocationsFilter, resetLocationsFilterAction, broadcastSearchMessage } = props;
    const [open, setOpen] = useState(false);
    const anchorRefButton = React.useRef<HTMLButtonElement>(null);
    const { t } = useTranslation();

    const [countries, setCountries] = useState<RapidApiCountryDto[]>([]);
    const [provinces, setProvinces] = useState<RapidApiProvinceDto[]>([]);
    const [cities, setCities] = useState<RapidApiCityDto[]>([]);
    const [countryCode, setCountryCode] = useState<string>("");
    const [loadingNearbyCities, setLoadingNearbyCities] = useState(false);

    const [selectedCountry, setSelectedCountry] = useState<RapidApiCountryDto | null>(null);
    const [selectedProvince, setSelectedProvince] = useState<RapidApiProvinceDto | null>(null);
    const [selectedCity, setSelectedCity] = useState<RapidApiCityDto | null>(null);

    useEffect(() => {
        axios({
            method: 'GET',
            url: `${config.REACT_APP_PUBLIC_SERVER_HOST}/countries`,
        })
            .then((res: any) => setCountries(res.data))
            .catch((error: any) => {
                // console.log(error)
            })
    }, []);

    useEffect(() => {
        if (selectedCountry) {
            setCountryCode(selectedCountry.countryCode);
            axios({
                method: 'GET',
                url: `${config.REACT_APP_PUBLIC_SERVER_HOST}/provinces/${selectedCountry.countryCode}`,
            })
                .then((res: any) => {
                    setProvinces(res.data)
                })
                .catch((error: any) => {
                    // console.log(error)
                });
        }
    }, [selectedCountry]);

    useEffect(() => {
        if (selectedProvince) {
            getCities(countryCode, selectedProvince.isoCode, false);
        }
    }, [selectedProvince]);

    const getCities = (country: string, provinceCode: string, searchingNearMe: boolean) => {
        return axios({
            method: 'GET',
            url: `${config.REACT_APP_PUBLIC_SERVER_HOST}/cities/${country}/${provinceCode}`,
        })
        .then((res: any) => {
            if(searchingNearMe) {
                const cityFilters = res.data.map((city: RapidApiCityDto) => {
                    return city.city;
                });

                toggleLocationsFilter({
                    city: cityFilters,
                    stateProvince: "",
                    country: ""
                });

                setLoadingNearbyCities(false);
            }
            else {
                setCities(res.data);
            }
        })
        .catch((error: any) => {
            setLoadingNearbyCities(false);
        })
    }

    const getNearbyCities = () => {
        if(userCoordinates) {
            axios({
                method: 'GET',
                url: `${config.REACT_APP_PUBLIC_SERVER_HOST}/nearbyDivisions/${userCoordinates.longitude}/${userCoordinates.latitude}`,
            })
            .then((res: any) => {
                const countryCode = res.data.countryCode;
                const regionCode = res.data.regionCode;

                getCities(countryCode, regionCode, true);
            })
            .catch((error: any) => {
                broadcastSearchMessage({severity: SeveritySnackbarEnum.error, message: "Error occured when fetching nearest locations. Please try again."});
                setLoadingNearbyCities(false);
            })
        }
    }

    const handleClose = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRefButton.current && anchorRefButton.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpen(false);
        resetSelectedLocationsState();
    };

    const resetSelectedLocationsState = () => {
        setSelectedCountry(null);
        setSelectedProvince(null);
        setSelectedCity(null);
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();
        toggleLocationsFilter({
            city: selectedCity ? [selectedCity.city] : [],
            stateProvince: selectedProvince ? selectedProvince.province : "",
            country: selectedCountry ? selectedCountry.country : ""
        });

        setOpen(false);
        resetSelectedLocationsState();
    }

    const openLocationMenu = () => {
        setOpen(prevState => !prevState);
    }

    const handleOnClickCountry = (country: RapidApiCountryDto) => {
        setSelectedCountry(country);
    };

    const handleOnClickProvince = (province: RapidApiProvinceDto) => {
        setSelectedProvince(province);
    };

    const handleOnClickCity = (city: RapidApiCityDto) => {
        setSelectedCity(city);
    }

    const isNearMeSelected = () => {
        return locationFilter.country === "" && locationFilter.stateProvince === "" && locationFilter.city.length > 0;
    }

    const handleAutoCompleteCountriesChange = (event: any, newValue: any) => {
        if (countries) {
            const country = countries.filter(option => {
                return option.country === newValue;
            })[0]
            handleOnClickCountry(country);
        }
    }
    const handleAutoCompleteProvinceChange = (event: any, newValue: any) => {
        if (provinces) {
            const province = provinces.filter(option => {
                return option.province === newValue;
            })[0];

            handleOnClickProvince(province);
        }
    }
    const handleAutoCompleteCityChange = (event: any, newValue: any) => {
        if (cities) {
            const city = cities.filter(option => {
                return option.city === newValue;
            })[0];

            handleOnClickCity(city);
        }
    }

    const locationIsToggled = () => {
        return locationFilter.country !== "";
    }

    return (
        <React.Fragment>
            <Button ref={anchorRefButton} id="content-by" size={isInChannelContex ? "small" : "medium"} variant="contained" className={isInChannelContex ? 'white-round-button' : 'white-round-button-channel-contex'} onClick={openLocationMenu}
                endIcon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />} aria-controls={open ? 'menu-list-grow' : undefined}>
                {
                    locationIsToggled() ?
                        `${locationFilter.country ? locationFilter.country : ""}${locationFilter.stateProvince ? '/' + locationFilter.stateProvince : ""}${locationFilter.city ? '/' + locationFilter.city : ""}`
                        : `${t("ContributorTable.Column.Location")}`
                }
            </Button>

            <Popper open={open} style={{ zIndex: 10 }} anchorEl={anchorRefButton.current} role={undefined} transition disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <div className='location-filter-container'>
                                    <Autocomplete
                                        id="autocomplete-country"
                                        style={{ width: '80%', padding: '2% 2%' }}
                                        options={countries.map((option) => option.country)}
                                        disableClearable
                                        value={selectedCountry ? selectedCountry.country : ""}
                                        onChange={handleAutoCompleteCountriesChange}
                                        renderInput={(params: any) => (
                                            <TextField
                                                {...params}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'countryInput',
                                                }}
                                                autoComplete='countryInput'
                                                id="country-input" variant='standard' placeholder="Enter country" name='country-input'
                                            />
                                        )}
                                    />
                                    <Autocomplete
                                        id="province-autocomplete"
                                        style={{ width: '80%', padding: '2% 2%' }}
                                        value={selectedProvince ? selectedProvince.province : ""}
                                        options={provinces.map((option) => option.province)}
                                        disableClearable
                                        onChange={handleAutoCompleteProvinceChange}
                                        renderInput={(params: any) => (
                                            <TextField
                                                {...params}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'provinceInput',
                                                }}
                                                autoComplete='provinceInput'
                                                id="province-input" variant='standard' placeholder="Enter province" name='province-input'
                                            />
                                        )}
                                    />
                                    <Autocomplete
                                        id="city-autocomplete"
                                        style={{ width: '80%', padding: '2% 2%' }}
                                        value={selectedCity ? selectedCity.city : ""}
                                        options={cities.map((option) => option.city)}
                                        disableClearable
                                        autoHighlight
                                        onChange={handleAutoCompleteCityChange}
                                        renderInput={(params: any) => (
                                            <TextField
                                                {...params}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'cityInput',
                                                }}
                                                autoComplete='cityInput'
                                                id="city-input" variant='standard' placeholder="Enter city" name='city-input'
                                            />
                                        )}
                                    />
                                    <ButtonGroup disableElevation >
                                        <Button onClick={handleSubmit} className='white-round-button'>
                                            {"Save"}
                                        </Button>
                                        <Button onClick={handleClose} className='white-round-button'>
                                            {"Close"}
                                        </Button>
                                    </ButtonGroup>
                                </div>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </React.Fragment>

    )
}

const mapStateToProps = (state: StoreState): { locationFilter: LocationFilterProps;
    userLocationPermission: boolean; userCoordinates: UserCoordinates | null; } => {
    return {
        locationFilter: selectLocationFilter(state),
        userLocationPermission: selectUserLocationPermission(state),
        userCoordinates: selectUserCoordinates(state)
    }
}

const mapDispatchToProps = (dispatch: Dispatch<TSearchReducerActions | TUserReducerActions>) => {
    return {
        toggleLocationsFilter: (data: LocationFilterProps) => dispatch<IToggleFilterLocation>({
            type: SearchBarActions.TOGGLE_FILTER_LOCATION,
            data: data
        }),
        broadcastSearchMessage: (data: IBroadcastMessage) => dispatch<IBroadcastSearchMessage>({
            type: SearchBarActions.BROADCAST_SEARCH_ERROR,
            data: data
        }),
        toggleUserLocationPopup: (data: boolean) => dispatch<IToggleUserCurrentLocation>({
            type: UserActionTypes.TOGGLE_USER_CURRENT_LOCATION,
            data: data
        }),
        resetLocationsFilterAction: () => dispatch<IResetLocationsFilter>({ type: SearchBarActions.RESET_LOCATIONS_FILTER })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationFilterComponent);