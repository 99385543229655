import { ImageContainer } from "../contributor-content/image-container.component";
import React from "react";
import { ReactPreviewProps, SingleContentTypes } from "./cart-page.types";
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import ReactAudioPlayer from "react-audio-player";

export const CartPreview: React.FC<ReactPreviewProps> = ({ ...props }) => {
  const { item } = props;
  return (
    <span>
      {item.content.type === SingleContentTypes.Image ? (
        <ImageContainer src={item.content?.pathToFileCompressed} />
      ) : item.content.type === SingleContentTypes.Audio ? (
        <LibraryMusicIcon fontSize="large"/>
      ) : null}
    </span>
  );
};
