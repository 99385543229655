import * as React from "react";
import Button from "@mui/material/Button";
import DeleteIcon from '@mui/icons-material/Delete';
import ShareIcon from '@mui/icons-material/Share';
import { IBroadcastMessage, LightBoxProps } from "./lightBox.types";
import './lightBox.styles.scss';
import { useHistory, useLocation } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faLink, faTrash } from "@fortawesome/free-solid-svg-icons";
import {config} from "../../config";
import { Menu, MenuItem, Tooltip } from "@mui/material";
import CopyToClipboard from "react-copy-to-clipboard";
import { StoreState } from "../../redux/root-reducer";
import { selectLightBoxBroadcast } from "../../redux/lightbox/lightbox.selector";
import { Dispatch } from "redux";
import { IBroadcastLightBoxMessage, IClearBroadcast, TLightBoxActions } from "../../redux/lightbox/lightbox.actions";
import { LightBoxActionTypes } from "../../redux/lightbox/lightbox.types";
import { connect } from "react-redux";
import { SeveritySnackbarEnum } from "../../components/batch-upload/ContentUpload.types";

const LightBoxCard: React.FC<LightBoxProps> = ({...props}) => {
  const { contentLightboxId, lightboxName,coverId,userId,timestamp,cover, handleSelectedLightBoxTitle, handleDeleteLightBox, handleConfirmationDialogOpen, broadcastAction} = props;
  const history = useHistory();
  const location = useLocation();
  const openLightBoxContentsPage = ()=>{
    history.push(`/light-box/${contentLightboxId}`)
  }
  const imageSrc = cover ? cover?.pathToFileThumbnail : `${config.REACT_APP_CLOUDFRONT_PATH}/assets/default_image.png`;
  const lightBoxPath = config.REACT_APP_PUBLIC_BASE+''+location.pathname+'/'+contentLightboxId;
  const shareToFacebook =
  config.REACT_APP_FACEBOOK_SOCIAL_MEDIA_SHARE+''+lightBoxPath;

  const shareTwitter =
  config.REACT_APP_TWEETER_SOCIAL_MEDIA_SHARE+''+lightBoxPath;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleShareClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCopyUrl = ()=>{
    broadcastAction({severity: SeveritySnackbarEnum.info, message: "Url copied to clipboard"});
  }
  return (
    <div className="light-box-card">
      <div onClick={openLightBoxContentsPage} className="cover-image-container">
        <img className="cover-image" src={imageSrc} alt="" />
      </div>
      <p className="light-box-title">
        {lightboxName}
      </p>
      <div className="light-box-card-actions">
          <Button
            startIcon={<ShareIcon />} variant="outlined"
            onClick={handleShareClick}
          >
            Share
          </Button>
          <Button startIcon={<DeleteIcon />} style={{backgroundColor: 'red', color: 'white'}} variant="contained" onClick={()=>{
            handleDeleteLightBox(contentLightboxId);
            handleConfirmationDialogOpen(true);
            handleSelectedLightBoxTitle(lightboxName);
          }}>Delete</Button>
          {/* <button className="channel-info-button main-color-button icon-buttons" >
            <FontAwesomeIcon className="deleteColor" icon={faTrash} />
          </button> */}
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuItem onClick={handleClose}>
            <a target="_blank" rel="noopener noreferrer" href={shareToFacebook} className='links'>
              <button className="channel-info-button main-color-button icon-buttons">
                <FontAwesomeIcon className="fbColor" icon={faFacebookF} /> 
              </button>
              Share on Facebook
            </a>
            
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <a target="_blank" rel="noopener noreferrer" href={shareTwitter} className='links'>
              <button className="channel-info-button main-color-button icon-buttons">
                <FontAwesomeIcon className="twColor" icon={faTwitter} /> 
              </button>
              Share on Twitter
            </a> 
            
          </MenuItem>
          <CopyToClipboard text={lightBoxPath} onCopy={handleCopyUrl}>
          <MenuItem onClick={handleClose}>
            
              <button className="channel-info-button main-color-button icon-buttons">
                <FontAwesomeIcon className="linkColor" icon={faLink} /> 
              </button>
            
            Copy URL
          </MenuItem>
          </CopyToClipboard>
      </Menu>
      </div>
    </div>
  );
};

const mapStateToProps = (state: StoreState): {
  lightBoxBroadcast: IBroadcastMessage;
} => {
  return {
    lightBoxBroadcast: selectLightBoxBroadcast(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch<TLightBoxActions>) => {
  return {
      broadcastAction: (data: IBroadcastMessage) => dispatch<IBroadcastLightBoxMessage>({
          type: LightBoxActionTypes.BROADCAST_MESSAGE, data: data
      }),
      clearBroadcast: () => dispatch<IClearBroadcast>({type: LightBoxActionTypes.CLEAR_BROADCAST})
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LightBoxCard);