import React, { useEffect, useRef, useState } from 'react';
import ContentList from "./ContentList";
import { GET_ALL_SEARCH_RESULTS } from "../search-bar/queries";
import { SnackbarComponent } from "shared";
import { ContentDisplayProps, ContentTypes } from "./content.types";
import { useLazyQuery } from '@apollo/react-hooks';
import './contentList.styles.scss';
import { LocationFilterProps, LocationNearMeFilterProps, SearchBarActions } from '../../redux/search-bar/search-bar.types';
import { IBroadcastAddToCartMessage, IBroadcastSearchMessage, IClearAddToCartBroadcast, IClearBroadcast, IContentPerPage, ILoadSearchResultsChannelContexCount, IResetFilters, IToggleFilterChannel, IToggleFilterLocation, IToggleFilterLocationNearMe, IToggleFilterSubchannel, IToggleFilterThread, TSearchReducerActions } from '../../redux/search-bar/search-bar.actions';
import { Dispatch } from 'redux';
import { Channel } from '../home-component/home.types';
import { SubChannel, Thread } from '../channelsSidebar/channelSideBars.types';
import { connect } from 'react-redux';
import SyncLoader from 'react-spinners/SyncLoader';
import { ContentFromOptions, ContentTypesForSearch } from '../search-bar/searchBar.types';
import { StoreState } from '../../redux/root-reducer';
import { selectAddToCartBroadcastMessage, selectChannelFilter, selectColourFilter, selectContentFromFilter, selectContentPerPage, selectContentTypeFilter, selectDateFilter, selectLocationFilter, selectLocationNearMeFilter, selectSearchBarExpanded, selectSearchBroadcastMessage, selectSearchString, selectSubchannelFilter, selectThreadFilter } from '../../redux//search-bar/search-bar.selectors';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@material-ui/icons/Search';
import { UserUploadedContent } from '../contributor-content/contributor-content.types';
import { Pagination } from '@mui/material';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import { selectCurrentUser, selectUserCoordinates, selectUserCurrentLocationLoading, selectUserLocationPermission } from '../../redux/user/user.selectors';
import { IToggleLoadingSearchResults, IToggleUserCurrentLocation, IUserCurrentLocationLoading, TUserReducerActions } from '../../redux/user/user.actions';
import { User, UserActionTypes, UserCoordinates } from '../../redux/user/user.types';
import { IBroadcastMessage, SeveritySnackbarEnum } from '../../components/batch-upload/ContentUpload.types';
import axios from 'axios';
import { RapidApiCityDto } from '../../globals/locations/locations.types';
import {config} from '../../config';
import ContentItem from './ContentItem';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight,faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { generateChannelColorArray } from '../../components/channelScreen/helperFunctions';
import globalVariables from '../../assets/globalVariables.module.scss';


const ContentDisplay: React.FC<ContentDisplayProps> = ({ ...props }) => {
    
    const { searchString, selectedChannel, selectedThread, selectedSubChannel,
        channelFilter, subchannelFilter, threadFilter, colourFilter, dateFilter, locationFilter, locationNearMeFilter,
        contentType, contentFrom, searchBarExpanded,userLocationPermission, searchBroadcastMessage, addToCartBroadcastMessage, currentUser, userCurrentLocationLoading, contentPerPage, toggleContentPerPage, toggleUserCurrentLocationLoading, resetFiltersAction, toggleLocationsNearMeFilter, toggleUserLocationPopup, loadTotalResultsForChannelContexAction,
        toggleChannelFilterAction, toggleSubchannelFilterAction, toggleThreadFilterAction, broadcastSearchMessage, clearBroadcast, clearAddToCartBroadcast, toggleLoadingSearchResults, userCoordinates } = props;
    const { t } = useTranslation();
    const perPageOptions = [25,50,75,100];
    const [tileData, setTileData] = useState<UserUploadedContent[]>([]);
    const [isInitialRenderFinished, setInitialRender] = useState(false);
    const [showChannelsSnackbar, setShowChannelsSnackbar] = useState(false);
    const [showNoResultsFound, setShowNoResultsFound] = useState(false);

    const [totalPage, setTotalPage] = useState(0);
    const [pageNumber, setPageNumber] = useState(0);
    const [showSearchSnackbar, setShowSearchSnackbar] = useState(false);

    const [callSearchAll, { data: dataAll, loading: loadingSearch, error: errorAll }] = useLazyQuery(GET_ALL_SEARCH_RESULTS, { fetchPolicy: 'no-cache' });
    const [callSearchNearMe, { data: dataAllNearMe, loading: loadingContentsNearMe, error: errorLoadingContentsNearMe }] = useLazyQuery(GET_ALL_SEARCH_RESULTS, { fetchPolicy: 'no-cache' });
    const imageContainerRef = useRef<any>();
    const sliderRef = useRef<any>();
    const [images, setImages] = useState<any>([]);
    const [sliderPage, setSliderPage] = useState(0);
    const [left, setLeft] = useState(0);
    const [allContentFetched, setAllContentFetched] = useState(false);
    const [gettingNearByDivisions, setGettingNearByDivisions] = useState(false);
    const [gettingNearByCities, setGettingNearByCities] = useState(false);
    const [processedContentsNearMe, setProcessedContentsNearMe] = useState(false);
    const [locationEnabled, setLocationEnabled] = useState(false);
    const [sliderArrowVisibility,setSliderArrowVisibility] = useState(false);
    const [showAddToCartSnackbar, setShowAddToCartSnackbar] = useState(false);
    const [colors, setColors] = useState<string[]>([]);


    const resetLoaderProps = () => {
        setTileData([]);
        loadTotalResultsForChannelContexAction(0);
        setPageNumber(0);
        setTotalPage(0);
    }
    const search = (page?: number) => {
        callSearchAll({
            variables: {
                searchString,
                contentFromOptions: typeof contentFrom === 'string' ? contentFrom : undefined,
                contentFromUser: typeof contentFrom === 'number' ? contentFrom : 0,
                contentType,
                dateFilter,
                locationCountryFilter: locationFilter.country,
                locationProvinceFilter: locationFilter.stateProvince,
                locationCityFilter: locationFilter.city,
                channelFilterId: channelFilter.id,
                subchannelFilterId: subchannelFilter.id,
                threadFilterId: threadFilter.id,
                channelFilterTitle: channelFilter.title,
                subchannelFilterTitle: subchannelFilter.title,
                threadFilterTitle: threadFilter.title,
                pageNumber: page !== undefined ? page : pageNumber,
                userId: currentUser?.id,
                contentPerPage
            }
        })
    }
    const searchNearMe = (page?: number) => {
        callSearchNearMe({
            variables: {
                searchString,
                contentFromOptions: typeof contentFrom === 'string' ? contentFrom : undefined,
                contentFromUser: typeof contentFrom === 'number' ? contentFrom : 0,
                contentType,
                dateFilter,
                locationCountryFilter: locationNearMeFilter.country,
                locationProvinceFilter: locationNearMeFilter.stateProvince,
                locationCityFilter: locationNearMeFilter.city,
                channelFilterId: channelFilter.id,
                subchannelFilterId: subchannelFilter.id,
                threadFilterId: threadFilter.id,
                channelFilterTitle: channelFilter.title,
                subchannelFilterTitle: subchannelFilter.title,
                threadFilterTitle: threadFilter.title,
                pageNumber: page !== undefined ? page : pageNumber,
                userId: currentUser?.id,
                contentPerPage
            }
        })
    }

    const getCities = (country: string, provinceCode: string, searchingNearMe: boolean) => {
        setGettingNearByCities(true);
        return axios({
            method: 'GET',
            url: `${config.REACT_APP_PUBLIC_SERVER_HOST}/cities/${country}/${provinceCode}`,
        })
        .then((res: any) => {
            const cityFilters = res.data.map((city: RapidApiCityDto) => {
                return city.city;
            });
            toggleLocationsNearMeFilter({
                city: cityFilters,
                stateProvince: "",
                country: ""
            });
            setGettingNearByCities(false);
        })
        .catch((error: any) => {
            setGettingNearByCities(false);
            broadcastSearchMessage({severity: SeveritySnackbarEnum.error, message: "Error occured when fetching nearest locations. Please try again."});
        })
    }

    const getNearbyDivisions = () => {
        if(userCoordinates) {
            setProcessedContentsNearMe(true);
            setGettingNearByDivisions(true);
            axios({
                method: 'GET',
                url: `${config.REACT_APP_PUBLIC_SERVER_HOST}/nearbyDivisions/${userCoordinates.longitude}/${userCoordinates.latitude}`,
            })
            .then((res: any) => {
                const countryCode = res.data.countryCode;
                const regionCode = res.data.regionCode;
                setGettingNearByDivisions(false);
                getCities(countryCode, regionCode, true);
            })
            .catch((error: any) => {
                setGettingNearByDivisions(false);
                broadcastSearchMessage({severity: SeveritySnackbarEnum.error, message: "Error occured when fetching nearest locations. Please try again."});
            })
        }
    }

    const handleNearMeButton = () => {
        toggleUserLocationPopup(true);
    }
    
    const isLoading = () => {
        return loadingSearch;
    }

    const handleClose = () => {
        setShowChannelsSnackbar(false);
    }
    
    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPageNumber(value);
        search(value-1);
    };

    const handleContentPerPageChange = (event: any)=>{
        toggleContentPerPage(Number(event.target.value));
    }

    const handleShowAddToCartSnackbarClose = () => {
        setShowAddToCartSnackbar(false);
        clearAddToCartBroadcast();
    };

    const getItemKey = (item: UserUploadedContent) => {
        if(item.type === ContentTypes.CONTENT_SET) {
            return `${item.contentSetId}_set`;
        } else if(item.type === ContentTypes.ARTICLE) {
            return `${item.articleId}_${item.contentSetId}_article`;
        } else {
            return `${item.content.id}_content`;
        }
    }
    const handleClickRight = () => {
        if (left <= imageContainerRef?.current?.scrollLeft) {
            imageContainerRef.current.scroll({
                left: left + 500,
                behavior: "smooth"
            });
            setLeft((prev) => prev + 500);
            setSliderPage(sliderPage + 1);
            if(!allContentFetched)searchNearMe(sliderPage + 1);
        }
    };

    const handleClickLeft = () => {
    if (sliderPage > 0 && left >= 500) {
        imageContainerRef.current.scroll({
            left: left - 500,
            behavior: "smooth"
        });
        setLeft((prev) => prev - 500);
        setSliderPage(sliderPage - 1);
    }
    };
  
    const handleResize = () => {
        if(sliderRef?.current?.clientWidth <= imageContainerRef?.current?.clientWidth){
            setSliderArrowVisibility(true)
        }
        else{
            setSliderArrowVisibility(false)
        }
      };
    
    useEffect(() => {
        setColors(generateChannelColorArray(selectedChannel.code, globalVariables))
    }, [selectedChannel]);
    
    useEffect(()=>{
        return ()=>{
            clearAddToCartBroadcast();
            clearBroadcast();
        }
    },[])
    useEffect(()=>{
        toggleLoadingSearchResults(loadingSearch)
    },[loadingSearch]);

    useEffect(() => {
        if(sliderRef?.current?.clientWidth && imageContainerRef?.current?.clientWidth){
            handleResize();
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [sliderRef?.current,imageContainerRef?.current]);
    
    useEffect(() => {
        if ('permissions' in navigator) {
          navigator.permissions.query({name: 'geolocation'}).then((permissionStatus) => {
            if(permissionStatus.state === 'denied'){
                setLocationEnabled(false);
            }
            else{
                setLocationEnabled(true); 
            }
          });
        }
      }, []);

    useEffect(()=>{
        return ()=>{
            resetFiltersAction()
        }
    },[])
    useEffect(()=>{
        if(locationEnabled){
            toggleUserLocationPopup(true);
            if(!userLocationPermission && userCurrentLocationLoading){    
                toggleUserLocationPopup(true);
                broadcastSearchMessage({severity: SeveritySnackbarEnum.info, message: "Getting location"});
            }
            else if(gettingNearByDivisions || gettingNearByCities){
                broadcastSearchMessage({severity: SeveritySnackbarEnum.info, message: "Getting contents near you"});
            }
            else{
                toggleUserCurrentLocationLoading(false);
                broadcastSearchMessage({severity: SeveritySnackbarEnum.info, message: ""});
            }
        }
    },[locationEnabled, userLocationPermission, userCurrentLocationLoading, gettingNearByDivisions, gettingNearByCities])
    
    useEffect(() => {
        if (searchBroadcastMessage.severity && searchBroadcastMessage.message) {
            setShowSearchSnackbar(true);
        }
        else{
            setShowSearchSnackbar(false);
        }
    }, [searchBroadcastMessage])

    useEffect(() => {
        if (addToCartBroadcastMessage.severity && addToCartBroadcastMessage.message) {
            setShowAddToCartSnackbar(true);
        }
        else{
            setShowAddToCartSnackbar(false);
        }
    }, [addToCartBroadcastMessage])

    useEffect(() => {
        if(locationNearMeFilter.city?.length === 0 && userLocationPermission){
            handleNearMeButton();
        }
        else if(locationNearMeFilter.city.length > 0){
            searchNearMe(sliderPage);
            toggleUserLocationPopup(false);
        }
    }, [locationNearMeFilter, userLocationPermission]);
    
    useEffect(() => {
    if (dataAllNearMe && dataAllNearMe?.search && images.length === dataAllNearMe?.search?.allResultsCount) {
        setAllContentFetched(true);
    }
    }, [images,dataAllNearMe]);

    useEffect(()=>{
        if(userCoordinates && locationNearMeFilter.city.length === 0 && !processedContentsNearMe){
            getNearbyDivisions();
        }
    },[userCoordinates, locationNearMeFilter, processedContentsNearMe])
    useEffect(() => {
        if (dataAllNearMe && dataAllNearMe.search) {
            const results = dataAllNearMe.search.userUploadedContent   
            setImages(images.concat(results));
        }
    }, [dataAllNearMe]);
    useEffect(() => {
        if (errorAll) {
            resetLoaderProps();
            setShowChannelsSnackbar(true);
        }
    }, [errorAll])
    
    useEffect(() => {
        if (errorLoadingContentsNearMe) {
            resetLoaderProps();
            setShowChannelsSnackbar(true);
        }
    }, [errorLoadingContentsNearMe])

    useEffect(() => {
        if (!isInitialRenderFinished) {
            search();
            setInitialRender(true);
        }
    }, [isInitialRenderFinished])

    useEffect(() => {
        //when filters are changed after initial render
        if (!searchBarExpanded && isInitialRenderFinished) {
            resetLoaderProps();
            search(0);
        }
    }, [contentType, searchString, contentFrom, dateFilter, locationFilter, colourFilter, channelFilter, subchannelFilter, threadFilter])
    
    useEffect(() => {
        //when filters are changed after initial render
        if (!searchBarExpanded && isInitialRenderFinished) {
            setImages([])
            if(locationNearMeFilter.city.length > 0)searchNearMe(0);
        }
    }, [contentType, searchString, contentFrom, dateFilter, locationNearMeFilter, colourFilter, channelFilter, subchannelFilter, threadFilter])
    useEffect(() => {
        //when channel context channels are changed after initial render
        if (selectedChannel.id !== -1) {
            toggleChannelFilterAction(selectedChannel);
        }
        if (selectedSubChannel.id !== -1) {
            toggleSubchannelFilterAction(selectedSubChannel)
        } else {
            toggleSubchannelFilterAction({ id: 0, title: '' });
        }
        if (selectedThread.id !== -1) {
            toggleThreadFilterAction(selectedThread)
        } else {
            toggleThreadFilterAction({ id: 0, title: '' });
        }
    }, [selectedChannel, selectedSubChannel, selectedThread]);

    useEffect(() => {
        if (dataAll && dataAll.search) {
            const results = dataAll.search.userUploadedContent;
            if(totalPage === 0){
                let total = Math.floor(dataAll.search.allResultsCount/contentPerPage);
                if(dataAll.search.allResultsCount % contentPerPage !== 0){
                    total += 1;
                }
                setTotalPage(total);
            }
            setTileData([...results]);
            if (dataAll.search.allResultsCount === 0) {
                setShowNoResultsFound(true);
            } else {
                setShowNoResultsFound(false);
            }

            loadTotalResultsForChannelContexAction(dataAll.search.allResultsCount);
        }
    }, [dataAll?.search?.allResultsCount]);

    useEffect(()=>{
        if(dataAll && dataAll.search){
            let total = Math.floor(dataAll.search.allResultsCount/contentPerPage);
            if(contentPerPage < dataAll.search.allResultsCount && dataAll.search.allResultsCount % contentPerPage !== 0){
                total += 1;
            }
            setTotalPage(total);
            search(0);
            if(contentPerPage === 100){
                setPageNumber(0);
            }
        }
    },[contentPerPage])

    return (
        <div id="scroll-target" className='infinite-scroll-wrapper'>

            {showNoResultsFound ?
                <div className='no-results-container'>
                    <div className="search-icon">
                        <SearchIcon fontSize="large" />
                    </div>
                    <h3 className="heading">
                        Your  search {searchString ? <span> for "{searchString}"</span> : ''} didn't return any results.
                    </h3>
                    <p className="suggestions-heading">
                        {t("Suggestions.Label")}
                    </p>
                    <ul className="suggestions">
                        <li>{t("Suggestions.SpelledCorrectly")}</li>
                        <li>{t("Suggestions.DifferentKeywords")}</li>
                        <li>{t("Suggestions.GeneralKeywords")}</li>
                        <li>{t("Suggestions.FewerKeywords")}</li>
                    </ul>
                </div>
                : null}


            {tileData.length > 0 ? <ContentList loadingSearch={loadingSearch} tileData={tileData} contentPerPage={contentPerPage} /> : null}
            
            <SyncLoader css={`display: block; margin: 2rem auto 0 auto;`} size={20} 
                        color={colors[0]} loading={gettingNearByDivisions || gettingNearByCities || loadingContentsNearMe || userCurrentLocationLoading}/>
           {images.length > 0 ? 
           <div className='slider-container'>
            <p className='near-me-title'>Near me</p>
            <div ref={sliderRef} className="slider">
                <button style={{ display: sliderArrowVisibility ? 'inline-block' : 'none' }} className="left-arrow" onClick={handleClickLeft}>
                    <FontAwesomeIcon className='icon-button' icon={faAngleLeft}/>
                </button>
                <div ref={imageContainerRef} className="slider-images-container">
                    {images.map((item: any) => (
                    <div className="item">
                        <ContentItem key={getItemKey(item)} item={item} isInSearch={false}/>
                    </div>
                    ))}
                </div>
                <button style={{ display: sliderArrowVisibility ? 'inline-block' : 'none' }} className="right-arrow" onClick={handleClickRight}>
                    <FontAwesomeIcon className='icon-button' icon={faAngleRight}/>
                </button>
            </div>
           </div>
           : 
            !locationEnabled ? <p style={{color: 'red'}}>In order to use Near Me, you need to allow World Illustrated to access your location and reload the page.</p> : !loadingContentsNearMe && !gettingNearByDivisions && !gettingNearByCities && !userCurrentLocationLoading && <p className='no-nearby-content-warning'>There are no content of this category near your location at this time.</p> }
            {totalPage > 0 ? <div className='pagination-container'>
                <span className='pagination-label'>Per page</span>
                <FormControl>
                    <Select
                        value={contentPerPage.toString()}
                        onChange={handleContentPerPageChange}
                    >
                    {perPageOptions.map(item=><MenuItem value={item}>{item}</MenuItem>)}
                    </Select>
                </FormControl>
                <Pagination className='pagination-component' count={totalPage} page={pageNumber === 0 ? 1 : pageNumber} onChange={handleChange} />
            </div> : null}
            <SnackbarComponent
                showSnackbar={showChannelsSnackbar}
                handleClose={handleClose}
                severity="error"
                message="Server error occurred while trying to get content"
            />
            <SnackbarComponent
                showSnackbar={showSearchSnackbar}
                handleClose={handleClose}
                severity={searchBroadcastMessage.severity}
                message={searchBroadcastMessage.message}
            />
            <SnackbarComponent
                showSnackbar={showAddToCartSnackbar}
                handleClose={handleShowAddToCartSnackbarClose}
                severity={addToCartBroadcastMessage.severity}
                message={addToCartBroadcastMessage.message}
            />
        </div>
    );
};

const mapStateToProps = (state: StoreState): {
    searchString: string;
    contentFrom: number | ContentFromOptions; contentType: ContentTypesForSearch;
    dateFilter: Date[]; colourFilter: any; locationFilter: LocationFilterProps; locationNearMeFilter: LocationNearMeFilterProps; channelFilter: Channel;
    subchannelFilter: SubChannel; threadFilter: Thread; searchBarExpanded: boolean; userLocationPermission: boolean; userCoordinates: UserCoordinates | null; searchBroadcastMessage: IBroadcastMessage; addToCartBroadcastMessage: IBroadcastMessage; userCurrentLocationLoading: boolean; contentPerPage: number; currentUser: User;
} => {
    return {
        searchString: selectSearchString(state),
        contentFrom: selectContentFromFilter(state),
        contentType: selectContentTypeFilter(state),
        dateFilter: selectDateFilter(state),
        colourFilter: selectColourFilter(state),
        locationFilter: selectLocationFilter(state),
        locationNearMeFilter: selectLocationNearMeFilter(state),
        channelFilter: selectChannelFilter(state),
        subchannelFilter: selectSubchannelFilter(state),
        threadFilter: selectThreadFilter(state),
        searchBarExpanded: selectSearchBarExpanded(state),
        userLocationPermission: selectUserLocationPermission(state),
        userCoordinates: selectUserCoordinates(state),
        searchBroadcastMessage: selectSearchBroadcastMessage(state),
        addToCartBroadcastMessage: selectAddToCartBroadcastMessage(state),
        userCurrentLocationLoading: selectUserCurrentLocationLoading(state),
        contentPerPage: selectContentPerPage(state),
        currentUser: selectCurrentUser(state),
    }
}

const mapDispatchToProps = (dispatch: Dispatch<TSearchReducerActions | TUserReducerActions>) => {
    return {
        loadTotalResultsForChannelContexAction: (data: number) => dispatch<ILoadSearchResultsChannelContexCount>({
            type: SearchBarActions.LOAD_TOTAL_RESULTS_CHANNEL_CONTEX,
            data: data
        }),
        toggleChannelFilterAction: (data: Channel) => dispatch<IToggleFilterChannel>({
            type: SearchBarActions.TOGGLE_FILTER_CHANNEL,
            data: data
        }),
        toggleSubchannelFilterAction: (data: SubChannel) => dispatch<IToggleFilterSubchannel>({
            type: SearchBarActions.TOGGLE_FILTER_SUBCHANNEL,
            data: data
        }),
        toggleThreadFilterAction: (data: Thread) => dispatch<IToggleFilterThread>({
            type: SearchBarActions.TOGGLE_FILTER_THREAD,
            data: data
        }),
        toggleUserLocationPopup: (data: boolean) => dispatch<IToggleUserCurrentLocation>({
            type: UserActionTypes.TOGGLE_USER_CURRENT_LOCATION,
            data: data
        }),
        broadcastSearchMessage: (data: IBroadcastMessage) => dispatch<IBroadcastSearchMessage>({
            type: SearchBarActions.BROADCAST_SEARCH_ERROR,
            data: data
        }),
        toggleLocationsNearMeFilter: (data: LocationNearMeFilterProps) => dispatch<IToggleFilterLocationNearMe>({
            type: SearchBarActions.TOGGLE_FILTER_LOCATION_NEAR_ME,
            data: data
        }),
        resetFiltersAction: () => dispatch<IResetFilters>({
            type: SearchBarActions.RESET_FILTERS
        }),
        toggleContentPerPage: (data: number) => dispatch<IContentPerPage>({
            type: SearchBarActions.TOGGLE_CONTENT_PER_PAGE,
            data:data
        }),
        toggleUserCurrentLocationLoading: (data: boolean) => dispatch<IUserCurrentLocationLoading>({
            type: UserActionTypes.TOGGLE_USER_CURRENT_LOCATION_LOADING,
            data: data
        }),
        toggleLoadingSearchResults: (data: boolean) => dispatch<IToggleLoadingSearchResults>({
            type: UserActionTypes.TOGGLE_LOADING_SEARCH_RESULTS,
            data: data
        }),
        clearBroadcast: () =>
        dispatch<IClearBroadcast>({ type: SearchBarActions.CLEAR_BROADCAST }),
        clearAddToCartBroadcast: () =>
        dispatch<IClearAddToCartBroadcast>({ type: SearchBarActions.CLEAR_ADD_TO_CART_BROADCAST }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentDisplay);