import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { IBroadcastMessage, LightBoxContentObject } from "./lightBox.types";
import "./lightBox.styles.scss";
import {
  SmoothImageRender,
  ConfirmationDialog,
  SnackbarComponent,
} from "shared";
import Button from "@mui/material/Button";
import AddToCartIcon from "@mui/icons-material/ShoppingCart";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useMutation } from "react-apollo-hooks";
import { DELETE_LIGHT_BOX_CONTENT } from "./queries";
import { connect } from "react-redux";
import { LightBoxActionTypes } from "../../redux/lightbox/lightbox.types";
import {
  IBroadcastLightBoxMessage,
  IClearBroadcast,
  TLightBoxActions,
} from "../../redux/lightbox/lightbox.actions";
import { Dispatch } from "redux";
import { StoreState } from "redux/root-reducer";
import { selectLightBoxBroadcast } from "../../redux/lightbox/lightbox.selector";
import { SeveritySnackbarEnum } from "../batch-upload/ContentUpload.types";
import { useLazyQuery } from "react-apollo";
import {
  CHECK_IS_CONTENT_OWNED,
  GET_CONTENT_INFO,
  GET_LICENSE_PLAN_BY_USER_ID_AND_TYPE,
} from "../photo-details/queries";
import { selectCurrentUser } from "../../redux/user/user.selectors";
import { User } from "../../redux/user/user.types";
import AddToCartButton from "../photo-details/photo-details-add-to-cart-button.component";
import SyncLoader from "react-spinners/SyncLoader";
import { checkIfPlanHasAvailableAmount } from "../photo-details/photo-details-helper-functions";
import {
  selectCurrentCartItems,
  selectCurrentSubItems,
} from "../../redux/cart/cart-page.selector";
import { ItemForCart } from "../photo-details/photo-details.types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faRightToBracket,
  faDownload,
  faCartPlus,
} from "@fortawesome/free-solid-svg-icons";
import { downloadContent } from "../utils/download";
import AddToLightBoxModal from "../../components/photo-details/AddToLightBoxModal";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";
import EditorialPriceBoxContent from "../../components/photo-details/photo-container/editorialPriceBoxContent";
import { IAddToCart, IAddToSubscriptionCart, TCartReducerActions } from "../../redux/cart/cart-page.actions";
import { CartActionTypes } from "../../redux/cart/cart-page.types";
import { ContentRate } from '../../generated/graphql';
import { useTranslation } from "react-i18next";

const LightBoxContentContainer: React.FC<any> = ({ ...props }) => {
  const {
    item,
    broadcastAction,
    refetchLightBoxContents,
    currentUser,
    cartItemsFromSub,
    ownerId,
    lightBoxBroadcast,
    clearBroadcast,
    currentCartItems,
    addToSubscriptionCartAction,
    addToCartAction,
    standardPlan,
    premiumPlan
  } = props;
  const [showActions, setShowActions] = useState<boolean>(false);
  const history = useHistory();
  const [deleteLightBoxContentMutation] = useMutation(DELETE_LIGHT_BOX_CONTENT);
  const [confirmationDialogOpen, setConfirmationDialogOpen] =
    useState<boolean>(false);
  const [deletingLightBoxContent, setDeletingLightBoxContent] =
    useState<boolean>(false);
  const [selectedContentInfo, setSelectedContentInfo] = useState<any>();
  const [licensingPlan, setLicensingPlan] = useState<any>();
  const [isPartOfPlan, setIsPartOfPlan] = useState<boolean>(false);
  const [contentOwned, setContentOwned] = useState<boolean>(false);
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [showAddToCartSnackbar, setShowAddToCartSnackbar] = useState(false);
  const { t } = useTranslation();


  const [showAddToLightBoxModal, setShowAddToLightBoxModal] =
    useState<boolean>(false);
  const [clickedImageToAddToLightBox, setClickedImageToAddToLightBox] =
    useState<number>(0);
  const [showEditorialModal, setShowEditorialModal] = useState(false);

  const [
    fetchFullContentInfo,
    { data: contentInfo, loading: contentInfoLoading, error: contentInfoError },
  ] = useLazyQuery(GET_CONTENT_INFO, {
    fetchPolicy: "cache-and-network",
  });

  const [
    checkIfContentIsOwned,
    {
      data: checkIfOwnedData,
      loading: checkIfOwnedLoading,
      error: checkIfOwnedError,
    },
  ] = useLazyQuery(CHECK_IS_CONTENT_OWNED, {
    fetchPolicy: "cache-and-network",
    onCompleted: (res: any) => {
      if(res.checkIfContentIsOwned.isOwned){
        handleDownload()
      }
      else{
        const contentToAdd: ItemForCart = {
          content: {
            ...item.contentInfo,
            amount:
              licensingPlan ? licensingPlan.plan.amount : undefined,
            totalPrice:
              licensingPlan
                ? licensingPlan.plan.totalPrice
                : undefined,
            period:
              licensingPlan && licensingPlan.plan.period
                ? licensingPlan.plan.period
                : undefined,
            role: currentUser.role,
          },
          // editorialSize: editorialSize,
          flatRate: item?.contentRate?.flatRate,
        };
        if (
          licensingPlan &&
          checkIfPlanHasAvailableAmount(
            currentCartItems,
            licensingPlan.amountLeft!,
            licensingPlan.plan.licensingType!
          )
        ) {
            addToSubscriptionCartAction(contentToAdd);
            setShowAddToCartSnackbar(true);
            setIsPartOfPlan(true);
            broadcastAction({
              severity: SeveritySnackbarEnum.success,
              message: "Added to cart",
            });
        } else {
          addToCartAction(contentToAdd);
          setShowAddToCartSnackbar(true);
          setIsPartOfPlan(false);
          broadcastAction({
            severity: SeveritySnackbarEnum.success,
            message: "Added to cart",
          });
        }
      }
    },
  });

  const handleClick = () => {
    if (item?.contentInfo?.licensingType === "editorial") {
      setShowEditorialModal(true);
    } else {
      checkIfContentIsOwned({
        variables: {
          contentId: item?.contentInfo?.id,
          userId: currentUser?.id,
        },
      });
    }
  };

  const handleSeeDetails = () => {
    const { contentId, contentSetId, articleId, threadId } = item;
    const articlePath = `/content/article/${articleId}/set/${contentSetId}/${contentId}/thread/${threadId}`;
    const contentSetPath = `/content/set/${contentSetId}/${contentId}/thread/${threadId}`;
    const imagePath = `/content/${contentId}/thread/${threadId}`;

    let path = "";
    if (articleId) {
      path = articlePath;
    } else if (contentSetId) {
      path = contentSetPath;
    } else {
      path = imagePath;
    }
    history.push(path);
  };

  const handleHover = () => {
    setShowActions(true);
  };

  const handleMouseLeave = () => {
    setShowActions(false);
  };

  const deleteLightBoxContent = () => {
    deleteLightBoxContentMutation({
      variables: {
        lightboxId: item.lightboxId,
        contentId: item.contentId,
      },
    })
      .then((res: any) => {
        if (res.data.deleteLightboxContent) {
          setConfirmationDialogOpen(false);
          setDeletingLightBoxContent(false);
          setShowSnackbar(true);
          broadcastAction({
            severity: SeveritySnackbarEnum.success,
            message: "Image deleted successfully",
          });
          refetchLightBoxContents();
        }
      })
      .catch((e) => {
        setConfirmationDialogOpen(false);
        setShowSnackbar(true);
        broadcastAction({
          severity: SeveritySnackbarEnum.error,
          message: "Image deletion failed",
        });
      });
  };

  const handleConfirmationDialogOpen = () => {
    setConfirmationDialogOpen(true);
  };

  const handleConfirmationDialogClose = () => {
    setConfirmationDialogOpen(false);
  };

  const handleConfirmationDialogAgree = () => {
    setDeletingLightBoxContent(true);
    deleteLightBoxContent();
  };

  const handleDownloadError = ()=>{
    broadcastAction({ severity: SeveritySnackbarEnum.error, message: t("Error.Download.Content") })
  }

  const handleDownload = () => {
    if(currentUser?.token){
      downloadContent({
        purchasedImages: [item.contentId],
        token: currentUser.token,
        fileName: "wi_image",
        onError: handleDownloadError
      });
    }
  };

  const handleAddToLightBoxClose = () => {
    setShowAddToLightBoxModal(false);
  };

  const handleAddToLightBoxSnackBarOpen = () => {
    setShowSnackbar(true);
  };

  const handleAddToLightBoxSnackBarClose = () => {
    setShowSnackbar(false);
  };

  const handleAddToLightBox = () => {
    setClickedImageToAddToLightBox(item.contentId);
    setShowAddToLightBoxModal(true);
  };

  const handleEditorialModalClose = () => {
    setShowEditorialModal(false);
  };
  const userIsAllowedToPurchase = (): boolean => {
    if (currentUser?.id) {
      return (
        currentUser?.role === "pro_freelancer" ||
        currentUser?.role === "pro_organization"
      );
    }
    return false;
  };

  const checkIfContentIsPurchaseable = (): boolean => {
    return !item.contentInfo.isOwned && (item.contentInfo.licensingType === 'editorial' || item.contentRate || item.contentInfo.licensingType === premiumPlan?.plan?.licensingType || item.contentInfo.licensingType === standardPlan?.plan?.licensingType);
  };

  const checkIfContentIsDownloadable = (): boolean => {
    return item.contentInfo.isOwned;
  };

  const handleClose = () => {
    setShowAddToCartSnackbar(false);
    clearBroadcast();
  };

  useEffect(()=>{
    if(item?.contentInfo?.licensingType === 'premium' && premiumPlan){
      setLicensingPlan(premiumPlan)
    }
    else if(item?.contentInfo?.licensingType === 'standard' && standardPlan){
      setLicensingPlan(standardPlan)
    }
  },[item,premiumPlan,standardPlan])
  useEffect(() => {
    if (contentInfo && contentInfo.getFullContentInfo) {
      setSelectedContentInfo(contentInfo.getFullContentInfo);
    }
  }, [contentInfo]);
  useEffect(() => {
    if (
      selectedContentInfo &&
      licensingPlan &&
      checkIfPlanHasAvailableAmount(
        cartItemsFromSub,
        licensingPlan.amountLeft!,
        licensingPlan.plan.licensingType!
      )
    ) {
      setIsPartOfPlan(true);
    } else {
      setIsPartOfPlan(false);
    }
  }, [licensingPlan, selectedContentInfo, cartItemsFromSub]);

  useEffect(() => {
    if (checkIfOwnedData) {
      setContentOwned(checkIfOwnedData.checkIfContentIsOwned);
    }
  }, [checkIfOwnedData]);

  useEffect(() => {
    if (!showSnackbar) {
      clearBroadcast();
    }
  }, [showSnackbar]);

  return (
    <div
      key={item.contentInLightboxId}
      className="light-box-content-container"
      onMouseEnter={handleHover}
      onMouseLeave={handleMouseLeave}
    >
      <div className="light-box-image-container" onClick={handleSeeDetails}>
        <img
          className="light-box-image"
          src={item.contentInfo.pathToFileCompressed}
          alt={item.contentInfo.headline}
        />
      </div>
      {contentInfoLoading ? (
        <SyncLoader
          css={`
            display: block;
            margin: 0 auto;
            border-color: red;
            position: absolute;
            top: 50%;
            left: 40%;
          `}
          size={20}
          color={"#36D2B3"}
          loading={contentInfoLoading}
        />
      ) : (
        currentUser?.id && (
          <div
            className="light-box-content-image-actions-container"
          >
            {userIsAllowedToPurchase() && checkIfContentIsPurchaseable() && (
              <FontAwesomeIcon
                style={{ width: "40px", height: "40px", color: "green" }}
                icon={faCartPlus}
                onClick={handleClick}
              />
            )}
            {userIsAllowedToPurchase() && checkIfContentIsDownloadable() && (
              <FontAwesomeIcon
                style={{ width: "40px", height: "40px", color: "green" }}
                icon={faDownload}
                onClick={handleClick}
              />
            )}
            {ownerId === currentUser.id ? (
              <FontAwesomeIcon
                style={{ width: "40px", height: "40px", color: "red" }}
                icon={faTrash}
                onClick={handleConfirmationDialogOpen}
              />
            ) : (
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={handleAddToLightBox}
              >
                Add to light box
              </Button>
            )}
          </div>
        )
      )}
      <ConfirmationDialog
        open={confirmationDialogOpen}
        title={`Are you sure you want to delete this image?`}
        loading={deletingLightBoxContent}
        contentText={""}
        rejectButtonText={"Cancel"}
        acceptButtonText={"Delete"}
        handleClose={handleConfirmationDialogClose}
        handleConfirmationDialogAgree={handleConfirmationDialogAgree}
      />
      <AddToLightBoxModal
        showAddToLightBoxModal={showAddToLightBoxModal}
        handleAddToLightBoxClose={handleAddToLightBoxClose}
        handleAddToLightBoxSnackBarOpen={handleAddToLightBoxSnackBarOpen}
        clickedImageToAddToLightBox={clickedImageToAddToLightBox}
        contentDetailsIds={{
          contentId: item.contentId,
          contentSetId: item.contentSetId,
          articleId: item.articleId,
          threadId: item.threadId,
        }}
      />
      <SnackbarComponent
        showSnackbar={showSnackbar}
        handleClose={handleAddToLightBoxSnackBarClose}
        severity={lightBoxBroadcast.severity}
        message={lightBoxBroadcast.message}
      />
      <Dialog
        open={showEditorialModal}
        closeAfterTransition={true}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <b>Select sizes</b>
          <IconButton
            aria-label="close"
            onClick={handleEditorialModalClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <EditorialPriceBoxContent contentInfo={item?.contentInfo} />
        </DialogContent>
      </Dialog>
      <SnackbarComponent
        showSnackbar={showAddToCartSnackbar}
        handleClose={handleClose}
        severity={lightBoxBroadcast.severity}
        message={lightBoxBroadcast.message}
      />
    </div>
  );
};

const mapStateToProps = (
  state: StoreState
): {
  lightBoxBroadcast: IBroadcastMessage;
  currentUser: User;
  cartItemsFromSub: ItemForCart[];
  currentCartItems: ItemForCart[];
} => {
  return {
    currentUser: selectCurrentUser(state),
    lightBoxBroadcast: selectLightBoxBroadcast(state),
    cartItemsFromSub: selectCurrentSubItems(state),
    currentCartItems: selectCurrentCartItems(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<TLightBoxActions | TCartReducerActions>) => {
  return {
    broadcastAction: (data: IBroadcastMessage) =>
      dispatch<IBroadcastLightBoxMessage>({
        type: LightBoxActionTypes.BROADCAST_MESSAGE,
        data: data,
      }),
    addToCartAction: (item: ItemForCart) =>
      dispatch<IAddToCart>({ type: CartActionTypes.ADD_TO_CART, data: item }),
    addToSubscriptionCartAction: (item: ItemForCart) =>
      dispatch<IAddToSubscriptionCart>({
        type: CartActionTypes.ADD_TO_SUBSCRIPTION_CART,
        data: item,
      }),
    clearBroadcast: () =>
      dispatch<IClearBroadcast>({ type: LightBoxActionTypes.CLEAR_BROADCAST }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LightBoxContentContainer);
