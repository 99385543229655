import React from "react";
import PhotoDetails from "./photo-details.component";
import ChannelScreenComponent from "../channelScreen/channelScreen";
import { PhotoDetailsPageProps } from "./photo-details.types";

const PhotoDetailsPage: React.FC<PhotoDetailsPageProps> = ( { ...props } ) => {
  const { routeParams } = props;

  return (
    <div className="details-page">
      <ChannelScreenComponent isInChannelScreen={false} />
      <PhotoDetails routeParams={routeParams} />
    </div>
  );
};

export default PhotoDetailsPage;
