import { planPeriods, UserLicensingPlan } from "./my-license-page.types";
import { LicensingTypes } from "../batch-upload/EditContentMetadata.types";
import { LicensePlan } from "../photo-details/photo-details.types";

export const checkIfUserHasBothLicenseTypes = (userPlans:UserLicensingPlan[]) => {
    const hasStandard = userPlans.find((plan: UserLicensingPlan) => plan.plan.licensingType === LicensingTypes.STANDARD);
    const hasPremium = userPlans.find((plan: UserLicensingPlan) => plan.plan.licensingType === LicensingTypes.PREMIUM);

    return hasStandard && hasPremium ? true : false;
}

export const filterPlansByType = (plans: LicensePlan[], userPlans: UserLicensingPlan[]) =>
{
    const ownedPlanTypes = userPlans.map((userPlan: UserLicensingPlan) => {
        return userPlan.plan.licensingType;
    })

    const uniquePlans = ownedPlanTypes.filter(getUniqueValues);

    return plans.filter((plan) => {
        if(!uniquePlans.includes(plan.licensingType)) 
            return plan;
    })
}

export const getUniqueValues = (value: any, index: any, self: any) => {
    return self.indexOf(value) === index;
}

export const getPlanPeriod = (period:number | undefined) =>
{
    if(period === 30 )
    {
        return planPeriods.MONTHLY
    }
    else if(period === 365)
    {
        return planPeriods.YEARLY
    }
    else 
    {
        return "N/A"
    }
    
}

export const getExpDate = (date:Date) => {
    if(date){
        return date.toString().slice(0, 10)
    }
    else return "N/A"
}