import { connect } from 'react-redux';
import {flowRight as compose} from 'lodash';
import { LoginState, UserState, UserActionTypes, User } from '../../redux/user/user.types';
import { TUserReducerActions, IStartLogin, ILoginSuccess, ILoginFailure, ILogoutSuccess, ILogoutFailure, IStartLogout } from '../../redux/user/user.actions';
import { StoreState } from '../../redux/root-reducer';
import { LOGIN_USER } from './queries';
import { LOGOUT_USER } from '../home-component/queries';
import { graphql } from 'react-apollo';
import { Dispatch } from 'redux';
import { Login } from 'shared';
import { push, CallHistoryMethodAction } from 'connected-react-router';
import {  IResetToggles, TModalReducerActions, IToggleForgotPassword, IToggleRegisterAsRole } from '../../redux/modal-visibility/modal.actions';
import { ModalActionTypes } from '../../redux/modal-visibility/modal.types';
import {
    TTranslationsReducerActions
} from "../../redux/translations/translations.actions";
import {TranslationsState} from "../../redux/translations/translations.types";
import { IResetPage, TPageReducerActions } from '../../redux/error-page/error-page.actions';
import { ErrorPagesTypes } from '../../redux/error-page/error-page.types';

const mapStateToProps = (state: StoreState) : {user: UserState, route: string, isAdmin: boolean, translations: TranslationsState} => {
    return {
        user: state.user,
        route: state.router.location.pathname,
        translations: state.translations,
        isAdmin: false
    }
}

const mapDispatchToProps = (dispatch: Dispatch<TUserReducerActions | CallHistoryMethodAction | TModalReducerActions | TTranslationsReducerActions | TPageReducerActions>) => {
    return {
        loginUserAction: (data: LoginState) => dispatch<IStartLogin>({type: UserActionTypes.Login, data: data}),
        loginSuccessAction: (data: User) => {
            dispatch<ILoginSuccess>({type: UserActionTypes.LoginSuccess, data: data});
            dispatch<IResetToggles>({type: ModalActionTypes.ResetTogglesModal});
            dispatch<IResetPage>({type:ErrorPagesTypes.ResetTogglesPage})
        },
        loginFailureAction: (message: string) => dispatch<ILoginFailure>({type: UserActionTypes.LoginError, data: message}),
        logoutUserAction: () => dispatch<IStartLogout>({ type: UserActionTypes.LogoutUser }),
        logoutSuccessAction: () => dispatch<ILogoutSuccess>({ type: UserActionTypes.LogoutUserSuccess }),
        logoutFailureAction: (message: string) => dispatch<ILogoutFailure>({
            type: UserActionTypes.LogoutUserError,
            data: message
        }),
        redirectToHome: () => dispatch(push('/')),
        redirectToOnboarding: () => dispatch(push('/onboarding')),
        authenticateWithThirdParty: (socNetwork: string) => {
            window.location.replace(socNetwork);
        },
        toggleForgotPasswordModalAction: () => dispatch<IToggleForgotPassword>({type: ModalActionTypes.ToggleForgotPasswordModal}),
        toggleRegisterAsRoleModalAction:()=>dispatch<IToggleRegisterAsRole>({type:ModalActionTypes.ToggleRegisterAsRoleModal})
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(compose(graphql(LOGIN_USER, {name: "loginUserMutation"}), graphql(LOGOUT_USER, {name: 'logoutUserMutation'}))(Login));
