import React, { useEffect, useState } from 'react';
import {
    Stepper, Step, StepLabel, Button, RadioGroup, FormControlLabel,
    Radio, RadioProps, withStyles, MuiThemeProvider, LinearProgress, Paper, makeStyles, Link
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {
    ContentUploadPageProps,
    contentUploadTheme,
    UploadedFileResponse,
    ContentSet,
    RadioButtonGroup,
    ContentUploadMethods,
    Article,
    DroppedFile,
    UploadSteps,
    AudioFile,
    IndividualImageCategory,
    IBroadcastMessage,
    SeveritySnackbarEnum,
    SFTPCompressedFilesResponse,
    SFTPFilesResponse,
    TIME_BEFORE_REDIRECT,
    ImageUploadStatus
} from './ContentUpload.types';
import EditContentMetadata from './EditContentMetadata.component';
import DragAndDropContentStep from './DragAndDropContentStep';
import ArticleTabsComponent from './ArticleTabsComponent';
import ManageContent from './ManageContent';
import { StoreState } from '../../redux/root-reducer';
import {
    selectUploadedFiles,
    selectContentSets,
    selectDroppedFiles,
    selectArticles,
    selectRadioButtonValue,
    selectAudioFiles,
    selectIndividualImagesCategory,
    selectUploadError
} from '../../redux/content-upload/content-upload.selectors';
import { connect } from 'react-redux';
import { selectCurrentUser, selectCurrentUserId, selectCurrentUserSFTPAccess } from '../../redux/user/user.selectors';
import { Dispatch } from 'redux';
import SyncLoader from 'react-spinners/SyncLoader';
import {
    IBroadcastContentUploadMessage,
    IRemoveFile,
    IResetContentUpload,
    IToggleImageUploadStatus,
    IToggleRadioButton,
    IUpdateArticle,
    IUpdateSet,
    IUploadFiles,
    IUploadSet,
    TContentUploadActions
} from '../../redux/content-upload/content-upload.actions';
import { ContentUploadActionTypes } from '../../redux/content-upload/content-upload.types';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { ADD_ARTICLE, ASSIGN_CONTENT, ASSIGN_CONTENT_SET, CLEAN_SFTP_TEMP_FOLDER, GET_SFTP_FOLDERS, SAVE_USER_INCOMPLETE_FLOW, SET_MULTIPLE_CONTENTS_HASH, SFTP_INFO, UPLOAD_SET, UPLOAD_SFTP_SET_FOLDER } from './queries';
import { v4 as uuidv4 } from 'uuid';
import { GET_CHANNELS } from '../channels/queries';
import { SnackbarComponent } from 'shared';
import { uploadToBucket } from './uploadToBucketRequest';
import './ContentUpload.styles.scss';
import { useTranslation } from "react-i18next"
import UploadViaSFTP from './UploadViaSFTP';
import { ConfirmationDialog } from 'shared';
import { RoleTypes } from '../../components/register/register.types';
import { User } from '../../redux/user/user.types';
import fileDownload from 'js-file-download'
import axios from 'axios';
import {config as conf} from '../../config';

export const GreenRadio = withStyles({
    root: {
        color: 'rgb(12, 175, 149) !important',
        '&$checked': {
            color: 'rgb(12, 175, 149)',
        },
    },
    checked: {},
})((props: RadioProps) => <Radio color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
    label: {
        marginLeft: '10px !important'
    }
}));

const ContentUploadPage: React.FC<ContentUploadPageProps> = ({ ...props }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const {
        uploadedFiles, articles, droppedFiles, audioFiles, individualImageCategories,
        contentSets, userId, uploadError, currentUserSFTPAccess, currentUser, uploadFilesAction, uploadSetAction, updateSetAction,
        updateArticleAction, toggleRadioButtonAction, removeDroppedFile, resetReduxState,
        broadcastUploadErrorAction,toggleImageUploadStatusAction, history, currentUserId
    } = props;
    const [steps, setSteps] = useState([UploadSteps.PICK_ITEMS, UploadSteps.REVIEW_DATA])
    const [activeStep, setActiveStep] = useState(0);
    const [selectedRadioButton, setSelectedRadioButton] = useState(RadioButtonGroup.SET);
    const [selectedContentUploadMethod, setSelectedContentUploadMethod] = useState(ContentUploadMethods.BROWSER_UPLOAD);
    const [loadingIndicator, setLoadingIndicator] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('');
    const [channels, setAllChannels] = useState([]);
    const [sftpSetFolders, setSftpSetFolders] = useState([]);
    const [uploadingNewFiles, setUploadingNewFiles] = useState(false);

    const { data, loading, error } = useQuery(GET_CHANNELS);
    const [fetchFolderData, { data: folderData, loading: loadingFolderData, error: errorFolderData }] = useLazyQuery(GET_SFTP_FOLDERS,
        {
            fetchPolicy: "no-cache",
        });

    const [showContentUploadSnackbar, setContentUploadSnackbar] = useState(false);
    const [uploadingImagesIndicator, setUploadingImagesIndicator] = useState(false);
    const [sftpLoadingIndicator, setSFTPLoadingIndicator] = useState(false);
    const [progressBarValue, setProgressBarValue] = useState(0);
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
    const [clearFolderDialogOpen, setClearFolderDialogOpen] = useState(false);
    const [incompleteFlowDialogOpen, setIncompleteFlowDialogOpen] = useState(false);
    const [savingProgressAsIncomplete, setSavingProgressAsIncomplete] = useState(false);
    const [flowTitle, setFlowTitle] = useState("");
    const { data: sftpInfoData, loading: sftpInfoDataLoading, error: sftpInfoDataError } = useQuery(SFTP_INFO, {fetchPolicy: 'no-cache'});

    const [files, setFiles] = useState<any>([]);

    var uploadedFilesCounter = 0;

    const path = require('path');
    const url = `${conf.REACT_APP_PUBLIC_SERVER_HOST}/graphql`;
    const config = {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'content-type': 'multipart/form-data'
        }
    }

    useEffect(()=>{

    },[])

    useEffect(() => {
        if (selectedRadioButton === RadioButtonGroup.SET) {
            setSteps([UploadSteps.PICK_ITEMS, UploadSteps.MANAGE_CONTENT, UploadSteps.ADD_ARTICLE, UploadSteps.REVIEW_DATA]);
        }
        else {
            setSteps([UploadSteps.PICK_ITEMS, UploadSteps.REVIEW_DATA]);
        }
        resetReduxState();
    }, []);

    useEffect(() => {
        if (data && data.getAllChannels) {
            setAllChannels(data.getAllChannels);
        }
    }, [data]);

    useEffect(() => {
        if (folderData && folderData.getSetFolderNames) {
            setSftpSetFolders(folderData.getSetFolderNames);
        }
    }, [folderData]);

    useEffect(() => {
        if (uploadError.severity && uploadError.message) {
            setContentUploadSnackbar(true);
        }
    }, [uploadError])

    useEffect(() => {
        if (savingProgressAsIncomplete) {
            if (contentSets.length > 0) {
                handleContentSetUpload();
            } else if (uploadedFiles.length > 0 && individualImageCategories.length > 0) {
                //if it has both uploaded files and some navigations
                handleAssignToThread();
            } else {
                //only has files uploaded
                const contentIds = uploadedFiles.map((file: UploadedFileResponse) => { return file.id });

                saveIncompleteFlow(userId!, flowTitle, contentIds, []);
            }
        }
    }, [savingProgressAsIncomplete])

    const [cleanTempFolderMutation] = useMutation(CLEAN_SFTP_TEMP_FOLDER);
    const cleanTempFolder = (userId: number): Promise<boolean> => {
        return cleanTempFolderMutation({ variables: { id: userId } })
            .then((response: any) => {
                return Promise.resolve(response.data.cleanTempFolder);
            }).catch((error: any) => {
                return Promise.reject(error);
            })
    }

    const [uploadSFTPSetFolder] = useMutation(UPLOAD_SFTP_SET_FOLDER);
    const uploadSFTPSetFolderMutation = (sftpSetFolderName: string, userId: number) => {
        return uploadSFTPSetFolder({ variables: { sftpSetFolder: sftpSetFolderName, userId: userId } })
            .then((response: any) => {
                return Promise.resolve(response.data.uploadSFTPSetFolder);
            })
            .catch((error: any) => {
                return Promise.reject(error);
            })
    }

    const [addArticleMutation] = useMutation(ADD_ARTICLE);
    const addArticle = (contentSetId: number, text: string, title: string, audioId?: number): Promise<number> => {
        return addArticleMutation({
            variables: { contentSetId: contentSetId, text: text, title: title, audioId: audioId }
        }).then((result: any) => {
            if (result.data.addArticle) {
                return Promise.resolve(result.data.addArticle);
            }
        }).catch((error: any) => {
            return Promise.reject(error);
        });
    }

    const [assignContentMutation] = useMutation(ASSIGN_CONTENT);
    const assignContent = (threadIds: number[], contentId: number, subchannelId?: number, suggestedThreadTitle?: string, userId?: number): Promise<any> => {
        return assignContentMutation({
            variables: { threadIds: threadIds, contentId: contentId, subchannelId: subchannelId, suggestedThreadTitle: suggestedThreadTitle, userId: userId }
        }).then((result: any) => {
            return Promise.resolve(result);
        }).catch((error: any) => {
            broadcastUploadErrorAction({ severity: SeveritySnackbarEnum.error, message: error.graphQLErrors[0].message })
            return Promise.reject(error);
        })
    }

    const [assignContentSetMutation] = useMutation(ASSIGN_CONTENT_SET);
    const assignContentSet = (threadIds: number[], contentSetId: number, subchannelId?: number, suggestedThreadTitle?: string, userId?: number): Promise<any> => {
        return assignContentSetMutation({
            variables: { threadIds: threadIds, contentSetId: contentSetId, subchannelId: subchannelId, suggestedThreadTitle: suggestedThreadTitle, userId: userId }
        }).then((result: any) => {
            return Promise.resolve(result);
        }).catch((error: any) => {
            broadcastUploadErrorAction({ severity: SeveritySnackbarEnum.error, message: error.graphQLErrors[0].message })
            return Promise.reject(error);
        })
    }


    const [uploadContentSetMutation] = useMutation(UPLOAD_SET);
    const uploadSet = (userId: string, contentSet: ContentSet, selectedContentUploadMethod: string) => {
        const contentSetIds = contentSet.files.map((file) => {
            return file.id
        });
        return uploadContentSetMutation({
            variables: {
                userId: userId, contentIds: contentSetIds, setName: contentSet.title,
                coverId: contentSet.coverPhoto ? contentSet.coverPhoto.id : null,
                selectedContentUploadMethod: selectedContentUploadMethod
            }
        }).then((response: any) => {
            let temp = contentSet;

            if (response.data.uploadSet) {
                let responseProps = response.data.uploadSet;
                temp.uploadResponse = responseProps;
            }
            return Promise.resolve(temp);
        })
            .catch((error: any) => {
                return Promise.reject(error.response);
            });
    }

    const [saveIncompleteUploadFlowMutation] = useMutation(SAVE_USER_INCOMPLETE_FLOW, {
        onCompleted(data) {
            if (data.saveUserIncompleteUploadFlow) {
                broadcastUploadErrorAction({
                    severity: SeveritySnackbarEnum.success,
                    message: "Changes saved successfully to World illustrated."
                });
                redirectToContributorsPage();
            }
            setSavingProgressAsIncomplete(false);
        }, onError(err) {
            setSavingProgressAsIncomplete(false);
            broadcastUploadErrorAction({
                severity: SeveritySnackbarEnum.error,
                message: 'Failed to save content to World Illustrated. Try again'
            });
            redirectToContributorsPage();
        }
    });
    const saveIncompleteFlow = (userId: number, flowTitle: string, contentIds: number[], contentSetIds: number[]) => {
        return saveIncompleteUploadFlowMutation({
            variables: { userId, flowTitle, contentIds, contentSetIds }
        })
    }

    const [setMultipleContentsHashMutation] = useMutation(SET_MULTIPLE_CONTENTS_HASH);
    const setMultipleContentsHash = (contentIds: number[]) => {
        return setMultipleContentsHashMutation({
            variables: {
                contentIds: contentIds
            }
        }).then((result: any) => {
            return Promise.resolve(result);
        }).catch((error: any) => {
            return Promise.reject(error);
        })
    }

    const redirectToContributorsPage = () => {
        setTimeout(() => {
            resetReduxState();
            history.push('/my-content')
        }, TIME_BEFORE_REDIRECT);
    }


    const handleNext = () => {
        if(selectedContentUploadMethod === ContentUploadMethods.SFTP_UPLOAD && activeStep === 0 && !sftpInfoData?.sftpInfo?.hasPublicSFTPAccess){
            broadcastUploadErrorAction({
                severity: SeveritySnackbarEnum.error,
                message: t("ContentUpload.Snackbar.noPublicSFTPAccess")
            });
            return;
        }
        if (activeStep === steps.length - 1) {
            setLoadingIndicator(true);
            //HERE and check if individual
            if (selectedRadioButton === RadioButtonGroup.SET) {
                if (contentSets.find(set => set.selectedThreadIds === undefined || set.selectedThreadIds.length === 0) && contentSets.length > 0) {
                    broadcastUploadErrorAction({
                        severity: SeveritySnackbarEnum.error,
                        message: t("ContentUpload.Snackbar.SelectNav")
                    });
                    setLoadingIndicator(false);
                    return;
                } else {
                    handleContentSetUpload();
                }
            } else if (selectedRadioButton === RadioButtonGroup.INDIVIDUAL && selectedContentUploadMethod === ContentUploadMethods.BROWSER_UPLOAD) {
                if (individualImageCategories.length !== uploadedFiles.length && uploadedFiles.length > 0) {
                    broadcastUploadErrorAction({
                        severity: SeveritySnackbarEnum.error,
                        message: t("ContentUpload.Snackbar.SelectNav")
                    });
                    setLoadingIndicator(false);
                    return;
                } else {
                    addHashToContent();
                    handleAssignToThread();
                }
            }
        } else if (activeStep === steps.length - 3 && selectedRadioButton === RadioButtonGroup.SET) {
            if (contentSets.find(set => set.coverPhoto === undefined) || contentSets.find(set => set.title === "")) {
                broadcastUploadErrorAction({
                    severity: SeveritySnackbarEnum.error,
                    message: t("ContentUpload.Snackbar.SelectCoverTitle")
                });
            } else {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        } else if (activeStep === 0 && selectedContentUploadMethod === ContentUploadMethods.SFTP_UPLOAD) {
            //set confirmation dialog state to true
            setConfirmationDialogOpen(true);
            fetchFolderData({ variables: { sftpFolder: `${currentUser.username}_sftp` } });
            //then if confirmed setActiveStep to next value
        } else if (activeStep === 0 && droppedFiles.length > 0) {
            handleUpload();
            // setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };


    const addHashToContent = () => {
        let contentInSetIds: number[] = [];
        const contentIds = uploadedFiles.map((file) => {
            return file.id;
        });

        contentSets.map((contentSet: ContentSet) => {
            contentSet.files.map((file) => {
                contentInSetIds.push(file.id);
            })
        })

        if (selectedRadioButton === RadioButtonGroup.INDIVIDUAL && uploadedFiles.length === 1) {
            setMultipleContentsHash([uploadedFiles[0].id]);
        }
        else if (selectedRadioButton === RadioButtonGroup.SET) {
            setMultipleContentsHash(contentInSetIds);
        }
        else {
            setMultipleContentsHash(contentIds);
        }
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleAssignToThread = () => {
        setLoadingMessage(t("ContentUpload.AssingToThread"));
        let assignPromises: Promise<any>[] = [];

        if (contentSets.length > 0) {
            assignPromises = contentSets.map(async (set: ContentSet) => {
                if (set.selectedThreadIds && set.uploadResponse) {
                    if (set.suggestedThreadTitle) {
                        return await assignContentSet(set.selectedThreadIds!, set.uploadResponse!.contentSetId, set.subchannel!.id, set.suggestedThreadTitle, currentUserId);
                    }
                    else {
                        return await assignContentSet(set.selectedThreadIds!, set.uploadResponse!.contentSetId);
                    }
                }
            })
        } else if (individualImageCategories.length > 0) {
            assignPromises = individualImageCategories.map(async (imageCategory: IndividualImageCategory) => {
                if (imageCategory.suggestedThreadTitle) {
                    return await assignContent(imageCategory.selectedThreads!, imageCategory.contentId, imageCategory.subchannel!.id, imageCategory.suggestedThreadTitle, currentUserId);
                }
                else {
                    return await assignContent(imageCategory.selectedThreads!, imageCategory.contentId);
                }
            })
        }

        Promise.all(assignPromises).then((result: any) => {
            setLoadingMessage('');
            if (selectedContentUploadMethod === ContentUploadMethods.SFTP_UPLOAD) {
                setClearFolderDialogOpen(true);
            } else if (savingProgressAsIncomplete) {
                //call save mutation
                const contentIds = uploadedFiles.map((file: UploadedFileResponse) => { return file.id });
                const contentSetIds = contentSets.map((contentSet: ContentSet) => {
                    return contentSet.uploadResponse ? contentSet.uploadResponse?.contentSetId : 0
                });

                saveIncompleteFlow(userId!, flowTitle, contentIds, contentSetIds.filter((setId: number) => { return setId !== 0 }));
            } else {
                broadcastUploadErrorAction({
                    severity: SeveritySnackbarEnum.success,
                    message: t("ContentUpload.Snackbar.Success")
                });
                setLoadingIndicator(false);
                redirectToContributorsPage();
            }
        }).catch((error: any) => {
            setLoadingIndicator(false);
            setLoadingMessage("")
            broadcastUploadErrorAction({
                severity: SeveritySnackbarEnum.error,
                message: t("ContentUpload.Snackbar.AssignToThread.Fail")
            });
        })
    }

    const handleAudioFilesUpload = () => {
        let failedAudioFiles: string[] = [];

        // if (audioFiles.length !== 0) {
        //     setLoadingMessage(t("ContentUpload.Snackbar.Uploading"));
        //     const allPromises = audioFiles.map(async file => {
        //         return await uploadToBucket(file.fileWithMeta, userId!, url, config)
        //             .then(async (singleResponse: UploadedFileResponse | string) => {
        //                 if (typeof singleResponse === 'string') {
        //                     failedAudioFiles.push(file.fileWithMeta.file.name);
        //                 }
        //                 return Promise.resolve(singleResponse);
        //             });
        //     });
        //
        //     Promise.all(allPromises).then((result: any) => {
        //         result.map((fileResponse: UploadedFileResponse) => {
        //             if (fileResponse.blobObject) {
        //                 let uploadedAudioFile = audioFiles.find(file => file.fileWithMeta.file.name === fileResponse.blobObject!.name);
        //                 if (uploadedAudioFile) {
        //                     let articleForAudioFile = articles.find(article => article.contentSetId === uploadedAudioFile?.articleId);
        //                     if (articleForAudioFile) {
        //                         articleForAudioFile.audioId = fileResponse.id;
        //                         updateArticleAction(articleForAudioFile);
        //                     }
        //                 }
        //             }
        //         })
        //     }).catch((error) => {
        //         setLoadingIndicator(false);
        //         broadcastUploadErrorAction({
        //             severity: SeveritySnackbarEnum.error,
        //             message: t("ContentUpload.Audio.Fail")
        //         });
        //     }).finally(() => {
        //         handleArticleUpload();
        //     });
        // } else {
        //     if (failedAudioFiles.length > 0) {
        //         broadcastUploadErrorAction({
        //             severity: SeveritySnackbarEnum.error,
        //             message: t("ContentUpload.MultipleAudio.Upload.Fail") + ` ${failedAudioFiles.join(", ")}`
        //         });
        //     }
        //     handleArticleUpload();
        // }
        handleArticleUpload();
    }

    const handleArticleUpload = () => {
        setLoadingMessage(t("ContentUpload.Snackbar.UploadingArticle"));

        const allPromises = articles.map(async article => {
            let set = contentSets.find(set => set.id === article.contentSetId);
            if (set) {
                return await addArticle(set.uploadResponse!.contentSetId, article.text, article.title, article.audioId);
            }
        })
        //refactor needed here
        Promise.all(allPromises).then((result: any) => {
            //setLoadingIndicator(false);
        }).catch((error: any) => {
            setLoadingIndicator(false);
            broadcastUploadErrorAction({
                severity: SeveritySnackbarEnum.error,
                message: t("ContentUpload.Snackbar.Upload.Fail")
            });
        }).finally(() => {
            handleAssignToThread();
        })

    }

    const updateProgressValue = async (counter: number) => {
        return new Promise((resolve, reject) => {
            setProgressBarValue((counter * 100) / droppedFiles.length);
            resolve((uploadedFilesCounter * 100) / droppedFiles.length);
        })
    }

    const handleUpload = () => {
        setUploadingImagesIndicator(true);
        setLoadingMessage(`Uploaded ${uploadedFilesCounter} of ${droppedFiles.length}...`);
        const failedFiles: string[] = [];

        if (userId) {
            const allPromises = droppedFiles.map(async file => {
                toggleImageUploadStatusAction({
                    id: file.fileWithMeta.meta.id,
                    isUploading: true
                })
                return await uploadToBucket(file.fileWithMeta.meta.id, file.fileWithMeta, userId!, url, config)
                    .then(async (singleResponse: UploadedFileResponse) => {
                        toggleImageUploadStatusAction({
                            id: singleResponse.index,
                            isUploading: false
                        })
                        uploadedFilesCounter++;
                        await updateProgressValue(uploadedFilesCounter);
                        removeDroppedFile(parseInt(file.fileWithMeta.meta.id, 10));
                        setLoadingMessage(`Uploaded ${uploadedFilesCounter} of ${droppedFiles.length}...`);
                        return await Promise.resolve(singleResponse);
                    }).catch((error: any) => {
                        console.log("error",error)
                        failedFiles.push(file.fileWithMeta.file.name);
                    });
            });
            
            Promise.all(allPromises).then((results: any) => {
                const successfulResults = results.filter((result: any) => {
                    return result != null;
                });
            
                if (selectedRadioButton === RadioButtonGroup.SET) {
                    arrangeInSets(droppedFiles, successfulResults);
                } else {
                    uploadFilesAction(successfulResults);
                }
            }).catch((error) => {
                setUploadingImagesIndicator(false);
                broadcastUploadErrorAction({
                    severity: SeveritySnackbarEnum.error,
                    message: t("ContentUpload.Snackbar.Error")
                });
                setLoadingMessage("");
            }).finally(() => {
                if (failedFiles.length > 0) {
                    broadcastUploadErrorAction({
                        severity: SeveritySnackbarEnum.error,
                        message: t("ContentUpload.CertainFiles.Fail") + `${failedFiles.join(", ")}`
                    });
                }
                setUploadingImagesIndicator(false);
                setLoadingMessage("");
                uploadedFilesCounter = 0;
            });

            const flies = [...files, ...droppedFiles];
            setFiles(flies);
        }
    }


    const handleContentSetUpload = () => {
        setLoadingMessage(t("ContentUpload.Snackbar.Upload.ContentSet"));
        if (userId) {
            const allPromises = contentSets.map(async set => {
                return await uploadSet(userId.toString(), set, selectedContentUploadMethod);
            });

            Promise.all(allPromises).then((result: any) => {
                result.map((set: any) => {
                    updateSetAction(set);
                })
            }).catch((error: any) => {
                setLoadingIndicator(false);
                broadcastUploadErrorAction({
                    severity: SeveritySnackbarEnum.error,
                    message: t("ContentUpload.Set.Fail")
                });
            }).finally(() => {
                handleAudioFilesUpload();
            })
        }
    };

    const hasDuplicates = (droppedFiles: DroppedFile[] | undefined) => {
        const values = droppedFiles!.map((droppedFile) => {
            return droppedFile.fileWithMeta.file.name;
        })
        const duplicate = values!.some(function (file, idx) {
            const fileinx = values!.indexOf(file);
            return fileinx !== idx
        });

        return duplicate;
    }

    const removeDuplicates = (droppedFiles: DroppedFile[] | undefined) => {
        const hasDuplicateValues = hasDuplicates(droppedFiles);

        if (hasDuplicateValues) {
            const uniqueFiles = droppedFiles!.reduce((unique: DroppedFile[], o) => {
                if (!unique.some(obj => obj.fileWithMeta.file.name === o.fileWithMeta.file.name)) {
                    unique.push(o);
                }
                return unique;
            }, [])
            return uniqueFiles;
        }
        return droppedFiles;
    }
    const arrangeInSets = (allFiles: DroppedFile[] | undefined, uploadedFiles: UploadedFileResponse[]) => {
        const newSets: ContentSet[] = [];
        const uniqueAllFiles = removeDuplicates(allFiles);
        for (let i = 0; i < uniqueAllFiles!.length; i++) {
            const parentDirectory = path.basename(path.dirname(uniqueAllFiles![i].fileObject.fullPath));

            if (newSets.filter(set => set.title === parentDirectory).length < 1 && parentDirectory !== ".") {
                const contentSet: ContentSet = {
                    id: uuidv4(),
                    title: parentDirectory,
                    files: []
                }
                newSets.push(contentSet);
            }

            const uploadedFile = uploadedFiles.find(file => {
                if (file.blobObject) {
                    return file.blobObject.name === uniqueAllFiles![i].fileWithMeta.file.name
                }
            });

            if (uploadedFile) {
                if (parentDirectory !== "." && uniqueAllFiles![i].fileObject) {
                    try {
                        newSets.find(set => set.title === parentDirectory)!.files.push(uploadedFile);
                    } catch (err) {
                        console.log(err);
                    }
                } else {
                    uploadFilesAction([uploadedFile]);
                }
            }
        }

        newSets.map((set: ContentSet) => {
            uploadSetAction(set);
        })
    }

    const handleRadioChange = (event: any) => {
        event.preventDefault();
        const value = event.target.value;
        setSelectedRadioButton(value);
        toggleRadioButtonAction(value);

        if (value === RadioButtonGroup.INDIVIDUAL) {
            setSteps([UploadSteps.PICK_ITEMS, UploadSteps.REVIEW_DATA]);
        } else if (value === RadioButtonGroup.SET) {
            setSteps([UploadSteps.PICK_ITEMS, UploadSteps.MANAGE_CONTENT, UploadSteps.ADD_ARTICLE, UploadSteps.REVIEW_DATA]);
        }

    };
    const handleUploadSFTP = (event: any) => {
        event.preventDefault();
        setSelectedContentUploadMethod(ContentUploadMethods.SFTP_UPLOAD)
        setSelectedRadioButton(RadioButtonGroup.SET);
        toggleRadioButtonAction(RadioButtonGroup.SET);

        setSteps([UploadSteps.SFTP_STEP, UploadSteps.MANAGE_CONTENT, UploadSteps.ADD_ARTICLE, UploadSteps.REVIEW_DATA]);
    }

    const handleUploadBrowser = (event: any) => {
        event.preventDefault();
        setSelectedContentUploadMethod(ContentUploadMethods.BROWSER_UPLOAD);
        setSelectedRadioButton(RadioButtonGroup.SET);
        toggleRadioButtonAction(RadioButtonGroup.SET);

        setSteps([UploadSteps.PICK_ITEMS, UploadSteps.MANAGE_CONTENT, UploadSteps.ADD_ARTICLE, UploadSteps.REVIEW_DATA]);
    }

    const handleIncompleteUploadFlow = () => {
        setIncompleteFlowDialogOpen(true);
    }

    const getStepContent = (index: number) => {
        switch (steps[index]) {
            case UploadSteps.SFTP_STEP:
                return <UploadViaSFTP />;
            case UploadSteps.PICK_ITEMS:
                return <DragAndDropContentStep setUploadingNewFiles={setUploadingNewFiles} />;
            case UploadSteps.MANAGE_CONTENT:
                return <ManageContent selectedContentUploadMethod={selectedContentUploadMethod} />;
            case UploadSteps.ADD_ARTICLE:
                return <ArticleTabsComponent />;
            case UploadSteps.REVIEW_DATA:
                return <EditContentMetadata editSetFromAdmin={false} channels={channels} />;
            default:
                return null;
        }
    }

    const handleClose = () => {
        setContentUploadSnackbar(false);
    };

    const handleConfirmationDialogClose = () => {
        setConfirmationDialogOpen(false);
        setClearFolderDialogOpen(false);
        setIncompleteFlowDialogOpen(false);
    };

    const handleConfirmationDialogAgree = () => {
        if (!folderData) {
            setConfirmationDialogOpen(false);
            broadcastUploadErrorAction({
                severity: SeveritySnackbarEnum.warning,
                message: "There are no files for upload yet."
            })
        }
        else if (sftpSetFolders.length > 0 && userId) {
            fetchSFTPFolders(sftpSetFolders, userId);
            setConfirmationDialogOpen(false);
            setSFTPLoadingIndicator(true);
            setLoadingMessage(t("ContentUpload.Fetch.TempFolder"));
        }
    }

    const handleIncompleteFlowDialogAgree = (value: string) => {
        setIncompleteFlowDialogOpen(false);

        let setHasCoverPhotoMissing = contentSets.find(set => set.coverPhoto === undefined);
        if (contentSets.length > 0 && setHasCoverPhotoMissing) {
            broadcastUploadErrorAction({
                severity: SeveritySnackbarEnum.error,
                message: "Specify a cover photo to each of your existing content set before saving your changes"
            });
        } else {
            setSavingProgressAsIncomplete(true);
            setLoadingIndicator(true);
            if (value !== "") {
                setFlowTitle(value);
            }
        }
    }

    const handleClearFolderDialogAgree = () => {
        setClearFolderDialogOpen(false);
        setLoadingMessage(t("ContentUpload.Delete.TempFolder"));

        if (userId) {
            cleanTempFolder(userId).then((response: boolean) => {
                if (response) {
                    broadcastUploadErrorAction({
                        severity: SeveritySnackbarEnum.success,
                        message: t("ContentUpload.Snackbar.Success")
                    });
                    setSftpSetFolders([]);
                    redirectToContributorsPage();
                }
            }).catch((error: any) => {
                broadcastUploadErrorAction({
                    severity: SeveritySnackbarEnum.error,
                    message: t("Delete.TempFolder.Fail") + error
                });
                setSFTPLoadingIndicator(false);
                setLoadingMessage("");
            });
        }
    }

    const fetchSFTPFolders = async (folderData: string[], userId: number) => {

        const folderPromises = folderData.map(async (folderName) => {
            return await uploadSFTPSetFolderMutation(folderName, userId)
                .then((currentSet: SFTPFilesResponse) => {
                    let setToAdd: ContentSet = {
                        id: uuidv4(),
                        title: currentSet.key,
                        files: currentSet.value
                    }

                    if (contentSets.filter(set => set.title === setToAdd.title).length === 0) {
                        uploadSetAction(setToAdd);
                    }

                    return Promise.resolve(currentSet);
                })
                .catch((error: any) => {
                    setSFTPLoadingIndicator(false);
                    broadcastUploadErrorAction({
                        severity: SeveritySnackbarEnum.error,
                        message: t("Fetch.FSTP.Error") + error
                    });
                })
        });

        setActiveStep((prevActiveStep) => prevActiveStep + 1);

        await Promise.all(folderPromises)
            .then(() => {
                setSFTPLoadingIndicator(false);
                setLoadingMessage("");
            })
    }

    const onDownload = () => {
        const url = `${conf.REACT_APP_CLOUDFRONT_PATH}/assets/pdf/GUIDELINES.pdf`;
        axios.get(url, {
            responseType: 'blob',
        }).then((res) => {
            fileDownload(res.data, "SubmissionGuidelines.pdf");
        })
    };

    return (
        <div className="content-upload-container">
            <MuiThemeProvider theme={contentUploadTheme}>
                <div className="container-left">
                    <RadioGroup row
                        aria-label="channels"
                        name="channels"
                        value={selectedRadioButton}
                        onChange={handleRadioChange}
                    >
                        <FormControlLabel
                            value={RadioButtonGroup.INDIVIDUAL}
                            control={<GreenRadio size="small" />}
                            label={t("Individual.Items")}
                            classes={{ label: classes.label }}
                            disabled={activeStep > 0 || selectedContentUploadMethod === ContentUploadMethods.SFTP_UPLOAD}
                        />
                        <FormControlLabel
                            value={RadioButtonGroup.SET}
                            control={<GreenRadio size="small" />}
                            label={t("Content.Set")}
                            classes={{ label: classes.label }}
                            disabled={activeStep > 0 || selectedContentUploadMethod === ContentUploadMethods.SFTP_UPLOAD}
                        />
                    </RadioGroup>
                    <div className="upload-information">
                        <label className="header-label">
                            {t("Upload.Public.Content.Info")}
                        </label>
                        <p className='header-text'>{t("Upload.Content.Description.Text")}</p>

                        <p className='header-text'>{t("Upload.Content.FTP.Description.Text")}</p>
                        <Button
                            size="small"
                            className="submission-green-button"
                            variant="contained"
                            disabled={activeStep !== 0 || currentUser.role !== RoleTypes.Contributor_agency || selectedContentUploadMethod === ContentUploadMethods.SFTP_UPLOAD}
                            onClick={handleUploadSFTP}
                        >
                            {t("Download.sFTP")}
                        </Button>
                        <Button
                            size="small"
                            className="submission-white-button"
                            variant="contained"
                            disabled={currentUser.role !== RoleTypes.Contributor_agency || selectedContentUploadMethod === ContentUploadMethods.BROWSER_UPLOAD}
                            onClick={handleUploadBrowser}
                        >
                            {t("Download.Browser")}
                        </Button>
                        <Link onClick={onDownload}>
                            <Button
                                size="small"
                                className="submission-white-button"
                                variant="contained">
                                {t("Download.Guide")}
                            </Button>
                        </Link>

                        {activeStep !== 0 && selectedRadioButton === RadioButtonGroup.SET && currentUser.role === RoleTypes.Contributor_agency ?
                            <Button
                                size="small"
                                className="submission-white-button"
                                disabled={uploadingImagesIndicator}
                                onClick={handleIncompleteUploadFlow}
                                variant="contained"
                            >
                                {t("Upload.Incomplete.Text")}
                            </Button>
                            : null}
                        {uploadingNewFiles || uploadingImagesIndicator || loadingIndicator ?
                            <SyncLoader
                                css={`display: block; margin: 10px auto;
                            text-align: center; width: 100%; height: 100%; z-index: 100;`}
                                size={20}
                                color={"#36D2B3"}
                                loading={uploadingNewFiles}
                            />
                            :
                            <div className="button-group-progress">
                                <Button
                                    size="small"
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    className="white-button"
                                    variant="contained"
                                >
                                    {" "}
                                    <ArrowBackIcon /> {t("Back.Button")}
                                </Button>
                                <Button
                                    size="small"
                                    className="green-button"
                                    disabled={
                                        (droppedFiles.length === 0 &&
                                            uploadedFiles.length === 0 &&
                                            contentSets.length === 0 &&
                                            selectedContentUploadMethod === ContentUploadMethods.BROWSER_UPLOAD) ||
                                        (selectedRadioButton === RadioButtonGroup.SET &&
                                            uploadedFiles.length > 0 &&
                                            activeStep === 1)
                                        || (!currentUserSFTPAccess && selectedContentUploadMethod === ContentUploadMethods.SFTP_UPLOAD)
                                    }
                                    onClick={handleNext}
                                    variant="contained"
                                >
                                    {activeStep === steps.length - 1 ? t("Complete.Button") : t("NextStep.Button")}
                                    {activeStep === steps.length - 1 ? null : <ArrowForwardIcon />}
                                </Button>
                            </div>}

                        <div className="loading-indicator">
                            {uploadingImagesIndicator ?
                                <LinearProgress variant="determinate" value={progressBarValue} />
                                : null}

                            {sftpLoadingIndicator ?
                                <LinearProgress variant="indeterminate" />
                                : null}

                            <SyncLoader
                                css={`display: block; margin: auto 0; width: 100%; height: 100%; z-index: 100;`}
                                size={20}
                                color={"#36D2B3"}
                                loading={loadingIndicator}
                            />
                            <div className='loader-message'>{loadingMessage}</div>
                        </div>
                    </div>
                </div>

                <div className="stepper-container">
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label: any) => (
                            <Step key={label}>
                                <StepLabel>
                                    {t("Step." + label)}
                                </StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <Paper elevation={0} style={{border: activeStep === 0 ? '3px solid #95cec5' : 'none'}}>
                        {loadingIndicator ? null : getStepContent(activeStep)}
                    </Paper>
                </div>
            </MuiThemeProvider>
            <SnackbarComponent showSnackbar={showContentUploadSnackbar} handleClose={handleClose}
                severity={uploadError.severity}
                message={uploadError.message} />
            <ConfirmationDialog open={confirmationDialogOpen} title={t("FileZilla.Finished")}
                loading={loadingFolderData}
                contentText={t("FTP.Upload.Confirm.FileZilla")}
                rejectButtonText={t("SFTP.ConfirmationDialog.Reject.Button")} acceptButtonText={t("SFTP.ConfirmationDialog.Accept.Button")}
                handleClose={handleConfirmationDialogClose}
                handleConfirmationDialogAgree={handleConfirmationDialogAgree} />

            <ConfirmationDialog open={clearFolderDialogOpen} title={t("Delete.TempFolder.Confirmation")}
                contentText={t("FTP.Upload.Delete.Folder")}
                acceptButtonText={t("Okay.Button")}
                handleClose={handleConfirmationDialogClose}
                handleConfirmationDialogAgree={handleClearFolderDialogAgree} />

            <ConfirmationDialog open={incompleteFlowDialogOpen} title="Save your upload changes?"
                contentText="Your changes to your current upload flow will be saved and you can continue with later on"
                rejectButtonText="No, i don't want to save"
                acceptButtonText="Yes, save changes"
                enableTextField={true}
                textFieldLabel="Upload flow title"
                handleClose={handleConfirmationDialogClose}
                handleConfirmationDialogAgree={handleIncompleteFlowDialogAgree} />
            {/* <Prompt
                    when={isFormIncomplete}
                    message="Are you sure you want to leave?" /> */}
        </div>
    );
}

const mapStateToProps = (state: StoreState): {
    uploadedFiles: UploadedFileResponse[],
    articles: Article[], droppedFiles: DroppedFile[], contentSets: ContentSet[],
    audioFiles: AudioFile[], userId: number | undefined, individualImageCategories: IndividualImageCategory[],
    selectedRadioButtonValue: string, uploadError: IBroadcastMessage, currentUserSFTPAccess: boolean, currentUserId: number,
    currentUser: User
} => {
    return {
        uploadedFiles: selectUploadedFiles(state),
        articles: selectArticles(state),
        droppedFiles: selectDroppedFiles(state),
        contentSets: selectContentSets(state),
        audioFiles: selectAudioFiles(state),
        userId: selectCurrentUserId(state),
        individualImageCategories: selectIndividualImagesCategory(state),
        selectedRadioButtonValue: selectRadioButtonValue(state),
        uploadError: selectUploadError(state),
        currentUserSFTPAccess: selectCurrentUserSFTPAccess(state),
        currentUserId: selectCurrentUserId(state),
        currentUser: selectCurrentUser(state)
    }
}

const mapDispatchToProps = (dispatch: Dispatch<TContentUploadActions>) => {
    return {
        uploadFilesAction: (data: UploadedFileResponse[]) =>
            dispatch<IUploadFiles>({
                type: ContentUploadActionTypes.UPLOAD_FILES,
                data: data,
            }),
        uploadSetAction: (data: ContentSet) =>
            dispatch<IUploadSet>({
                type: ContentUploadActionTypes.UPLOAD_SET,
                data: data,
            }),
        updateSetAction: (data: ContentSet) =>
            dispatch<IUpdateSet>({
                type: ContentUploadActionTypes.UPDATE_SET,
                data: data,
            }),
        updateArticleAction: (data: Article) => dispatch<IUpdateArticle>({
            type: ContentUploadActionTypes.UPDATE_ARTICLE,
            data: data
        }),
        toggleRadioButtonAction: (data: string) =>
            dispatch<IToggleRadioButton>({
                type: ContentUploadActionTypes.TOGGLE_RADIO_BUTTON,
                data: data,
            }),
        toggleImageUploadStatusAction: (data: ImageUploadStatus) =>
            dispatch<IToggleImageUploadStatus>({
                type: ContentUploadActionTypes.TOGGLE_IMAGE_UPLOAD_STATUS,
                data: data,
            }),
        removeDroppedFile: (data: number) =>
            dispatch<IRemoveFile>({
                type: ContentUploadActionTypes.REMOVE_FILE,
                data: data,
            }),
        resetReduxState: () => dispatch<IResetContentUpload>({ type: ContentUploadActionTypes.RESET_CONTENT }),
        broadcastUploadErrorAction: (data: IBroadcastMessage) => dispatch<IBroadcastContentUploadMessage>({
            type: ContentUploadActionTypes.BROADCAST_MESSAGE, data: data
        }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentUploadPage);
