import React, { useEffect, useState } from "react";
import { Stepper, Step, StepLabel, MuiThemeProvider } from "@material-ui/core";
import {
  OnboardingSteps,
  stepperTheme,
  OnboardingPageProps,
  AddressInput,
  LocationInput,
  AddressTypes
} from "./onboarding-page.types";
import "./onboarding-page.styles.scss";
import OnboardingStepOne from "./onboarding-step-one.component";
import { OnboardinStepTwo } from "./onboarding-step-two.component";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { StoreState } from "../../redux/root-reducer";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import OnboardingLicensingStep from "./onboarding-step-licensing";
import {
  selectCurrentUser,
  selectCurrentUserId,
  selectHasUserCompletedOnboarding
} from "../../redux/user/user.selectors";
import { User, UserActionTypes } from "../../redux/user/user.types";
import { RoleTypes } from "../../components/register/register.types";
import { CompleteBanner } from "shared";
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";
import {
  ADD_ABOUT_YOU_WITHOUT_PROFILE_IMAGE,
  ADD_ADDRESS,
  ADD_CHANNELS_FOR_CONTENT,
  ADD_GENERAL_INFORMATION,
  ADD_LICENSING,
  ADD_LIST_OF_EDITORS,
  ADD_RESPONSIBLE_PERSON,
  CHECK_USER_ONBOARDING_STATUS,
  UPDATE_USER_ONBOARDING_STATUS
} from "./queries";
import {
  selectAboutYouInformation,
  selectAppropriateChannels,
  selectBillingAddressInformation,
  selectEditorsInformation,
  selectGeneralInformationCompany,
  selectGeneralInformationPerson,
  selectIsOnboardingValid,
  selectLicensingInformation,
  selectMailingAddressInformation,
  selectResponsiblePersonInfromation
} from "../../redux/onboarding/onboarding.selectors";
import {
  AboutYou,
  Addresses,
  AppropriateChannels,
  EditorsInformation,
  GeneralInformationCompany,
  GeneralInformationPerson,
  Licensing,
  OnboardingActionTypes,
  OnboardingValidation,
  PersonResponsibleForAccount
} from "../../redux/onboarding/onboarding.types";
import { Channel } from "components/home-component/home.types";
import { uploadToBucketProfileImage } from "./uploadToBucketProfileImage";
import {
  IBroadcastMessage,
  SeveritySnackbarEnum
} from "../../components/batch-upload/ContentUpload.types";
import { selectUploadError } from "../../redux/content-upload/content-upload.selectors";
import { Dispatch } from "redux";
import {
  IBroadcastContentUploadMessage,
  TContentUploadActions
} from "../../redux/content-upload/content-upload.actions";
import { ContentUploadActionTypes } from "../../redux/content-upload/content-upload.types";
import { SnackbarComponent } from "shared";
import {
  ISetCompletedOnboarding,
  ISetUserProfileImage,
  TUserReducerActions
} from "../../redux/user/user.actions";
import { useHistory } from "react-router";
import { LoadingSpinner } from "shared";
import { TOnboardingReducerAction } from "../../redux/onboarding/onboarding.actions";
import { IValidateOnboarding } from "../../redux/onboarding/onboarding.actions";
import {config as conf} from '../../config';

const OnboardingPage: React.FC<OnboardingPageProps> = ({ ...props }) => {
  const {
    currentUser,
    currentUserId,
    generalInformationPerson,
    generalInformationCompany,
    responsiblePerson,
    licensing,
    mailingAddress,
    billingAddress,
    editorsInformation,
    appropriateChannels,
    aboutYou,
    onboardingError,
    hasUserCompletedOnboarding,
    isOnboardingValid,
    broadcastOnboardingErrorAction,
    setCompletedOnboardingAction,
    setUserProfileImageAction,
    validateOnboardingDataAction
  } = props;
  const [steps, setSteps] = useState([OnboardingSteps.GENERAL_INFORMATION, OnboardingSteps.PREFERENCES, OnboardingSteps.LICENSING, OnboardingSteps.COMPLETE]);
  const [activeStep, setActiveStep] = useState(0);
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [contentTextMessage, setContentTextMessage] = useState("");
  const [showOnboardingSnackbar, setShowOnboardingSnackbar] = useState(false);

  const { t } = useTranslation();
  const history = useHistory();

  const currentUserRole = currentUser ? currentUser.role : null;
  const isProMember = currentUserRole === RoleTypes.Pro_freelancer || currentUserRole === RoleTypes.Pro_organization;
  const isContributor = currentUserRole === RoleTypes.Contributor_agency || currentUserRole === RoleTypes.Contributor_individual;
  const isFreelancer = currentUserRole === RoleTypes.Pro_freelancer;
  const isOrganization = currentUserRole === RoleTypes.Pro_organization;
  const isAgency = currentUserRole === RoleTypes.Contributor_agency;

  const proMemberMessage = t("Onboarding.Complete.ProMember.ContentText");
  const memberMessage = t("Onboarding.Complete.Member.ContentText");
  const contributorMessage = t("Onboarding.Complete.Contributor.ContentText");
  //TODO: create changing of images and remove link
  const imagePath = "https://bucket-wi-dev1.s3.eu-north-1.amazonaws.com/home/health.jpg";
  const url = `${conf.REACT_APP_PUBLIC_SERVER_HOST}/graphql`;
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "content-type": "multipart/form-data"
    }
  };
  const [profileImageUrl, setProfileImageUrl] = useState("")

  const [updateUserOnboardingStatusMutation] = useMutation(UPDATE_USER_ONBOARDING_STATUS);
  const updateUserOnboardingStatus = (userId: number) => {
    return updateUserOnboardingStatusMutation({
      variables: {
        userId: userId
      }
    })
      .then((response: any) => {
        return Promise.resolve(response.data.updateUserOnboardingStatus);
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
  };

  useEffect(() => {
    if (onboardingError.severity && onboardingError.message) {
      setShowOnboardingSnackbar(true);
    }
  }, [onboardingError]);

  useEffect(() => {
    const ac = new AbortController();

    if(isOnboardingValid.status) {
      submitOnboardingData();
    } else if(isOnboardingValid.validationMessage !== "") {
      broadcastOnboardingErrorAction({severity: SeveritySnackbarEnum.error, message: isOnboardingValid.validationMessage});
    }
    return () => ac.abort();
  }, [isOnboardingValid])

  useEffect(() => {
    if (hasUserCompletedOnboarding) {
      setSteps([]);
    } else {
      if (isProMember) {
        setSteps([
          OnboardingSteps.GENERAL_INFORMATION,
          OnboardingSteps.LICENSING,
          OnboardingSteps.COMPLETE
        ]);
      } else if (isContributor) {
        setSteps([
          OnboardingSteps.GENERAL_INFORMATION,
          OnboardingSteps.COMPLETE
        ]);
      }
    }
  }, []);

  useEffect(() => {
    if (isProMember) {
      setContentTextMessage(proMemberMessage);
    } else if (isContributor) {
      setContentTextMessage(contributorMessage);
    } else {
      setContentTextMessage(memberMessage);
    }
  }, []);

  const billingAddressLocations: LocationInput = {
    country: billingAddress ? billingAddress.country : "",
    countryCode: billingAddress ? billingAddress.countryCode : "",
    stateProvince: billingAddress ? billingAddress.state : "",
    stateCode: billingAddress ? billingAddress.stateCode : "",
    city: billingAddress ? billingAddress.city : ""
  };

  const mailingAddressLocations: LocationInput = {
    country: mailingAddress ? mailingAddress.country : "",
    countryCode: mailingAddress ? mailingAddress.countryCode : "",
    stateProvince: mailingAddress ? mailingAddress.state : "",
    stateCode: mailingAddress ? mailingAddress.stateCode : "",
    city: mailingAddress ? mailingAddress.city : ""
  };

  const billingAddressInput: AddressInput = {
    addressLine1: billingAddress ? billingAddress.addressLineOne : "",
    addressLine2: billingAddress ? billingAddress.addressLineTwo : "",
    zipCode: billingAddress ? billingAddress.zipCode : ""
  };

  const mailingAddressInput: AddressInput = {
    addressLine1: mailingAddress ? mailingAddress.addressLineOne : "",
    addressLine2: mailingAddress ? mailingAddress.addressLineTwo : "",
    zipCode: mailingAddress ? mailingAddress.zipCode : ""
  };

  const [addGeneralInformationMutation] = useMutation(ADD_GENERAL_INFORMATION);
  const addGeneralInformationPerson = (userId: number, input: GeneralInformationPerson
  ) => {
    return addGeneralInformationMutation({
      variables: {
        userId: userId,
        firstName: input.firstName,
        lastName: input.lastName,
        yearOfBirth: input.yearOfBirth,
        vatNumber: input.vatNumber,
        emailAccounting: isFreelancer ? input.emailAccounting : null,
        emailNewsletter: isFreelancer ? input.emailNewsletter : null
      }
    })
      .then((response: any) => {
        return Promise.resolve(response.data.addGeneralInformation);
      })
      .catch((error: any) => {
        broadcastOnboardingErrorAction({
          severity: SeveritySnackbarEnum.error,
          message: "Couldn't add general information " + error.graphQLErrors[0].message
        });
      });
  };

  const addGeneralInformationCompany = (userId: number, input: GeneralInformationCompany) => {
    return addGeneralInformationMutation({
      variables: {
        userId: userId,
        organizationName: input.organizationName,
        vatNumber: input.vatNumber,
        userContentType: isOrganization ? input.contentType : "",
        companyType: isOrganization ? input.companyType : "",
        emailAccounting: isOrganization ? input.emailAccounting : null,
        emailNewsletter: isOrganization ? input.emailNewsletter : null
      }
    })
      .then((response: any) => {
        return Promise.resolve(response.data.addGeneralInformation);
      })
      .catch((error: any) => {
        broadcastOnboardingErrorAction({
          severity: SeveritySnackbarEnum.error,
          message: "Couldn't add general information " + error.graphQLErrors[0].message
        });
      });
  };

  const [addResponsiblePersonMutation] = useMutation(ADD_RESPONSIBLE_PERSON);
  const addResponsiblePerson = (userId: number, input: PersonResponsibleForAccount
  ) => {
    return addResponsiblePersonMutation({
      variables: {
        userId: userId,
        firstName: input.firstName,
        lastName: input.lastName,
        email: input.email,
        phoneNumber: input.phoneNumber,
        jobTitle: !isAgency ? input.jobTitle : ""
      }
    })
      .then((response: any) => {
        return Promise.resolve(response.data.addResponsiblePerson);
      })
      .catch((error: any) => {
        broadcastOnboardingErrorAction({
          severity: SeveritySnackbarEnum.error,
          message: "Couldn't add responsible person information " + error.graphQLErrors[0].message
        });
      });
  };

  const [addLicensingMutation] = useMutation(ADD_LICENSING);
  const addLicensing = (userId: number, input: Licensing) => {
    return addLicensingMutation({
      variables: {
        userId: userId,
        licensingType: input.licenseType,
        prefferedCurrency: input.prefferedCurrency,
        agreeMerchandise: input.agreeForMerchandise
      }
    })
      .then((response: any) => {
        return Promise.resolve(response.data.addLicensing);
      })
      .catch((error: any) => {
        broadcastOnboardingErrorAction({
          severity: SeveritySnackbarEnum.error,
          message: "Couldn't add licensing information " + error.graphQLErrors[0].message
        });
      });
  };

  const [addAddressMutation] = useMutation(ADD_ADDRESS);
  const addBillingAddress = (userId: number, locationInput: LocationInput, addressInput: AddressInput) => {
    return addAddressMutation({
      variables: {
        userId: userId,
        country: locationInput.country,
        countryCode: locationInput.countryCode,
        stateProvince: locationInput.stateProvince,
        stateCode: locationInput.stateCode,
        city: locationInput.city,
        addressType: AddressTypes.BILLING,
        addressLine1: addressInput.addressLine1,
        addressLine2: addressInput.addressLine2,
        zipCode: addressInput.zipCode
      }
    })
      .then((response: any) => {
        return Promise.resolve(response.data.addAddress);
      })
      .catch((error: any) => {
        broadcastOnboardingErrorAction({
          severity: SeveritySnackbarEnum.error,
          message: "Couldn't add billing address " + error.graphQLErrors[0].message
        });
      });
  };

  const addMailingAddress = (userId: number, locationInput: LocationInput, addressInput: AddressInput) => {
    return addAddressMutation({
      variables: {
        userId: userId,
        country: locationInput.country,
        countryCode: locationInput.countryCode,
        stateProvince: locationInput.stateProvince,
        stateCode: locationInput.stateCode,
        city: locationInput.city,
        addressType: AddressTypes.MAILING,
        addressLine1: addressInput.addressLine1,
        addressLine2: addressInput.addressLine2,
        zipCode: addressInput.zipCode
      }
    })
      .then((response: any) => {
        return Promise.resolve(response.data.addAddress);
      })
      .catch((error: any) => {
        broadcastOnboardingErrorAction({
          severity: SeveritySnackbarEnum.error,
          message: "Couldn't add maillig address " + error.graphQLErrors[0].message
        });
      });
  };

  const [addChannelsForContentMutation] = useMutation(ADD_CHANNELS_FOR_CONTENT);
  const addChannelsForContent = (userId: number, input: AppropriateChannels) => {
    const channelIds = input.channels.map((channel: Channel) => {
      return channel.id;
    });
    return addChannelsForContentMutation({
      variables: {
        userId: userId,
        channelIds: channelIds
      }
    })
      .then((response: any) => {
        return Promise.resolve(response.data.addChannelsForContent);
      })
      .catch((error: any) => {
        broadcastOnboardingErrorAction({
          severity: SeveritySnackbarEnum.error,
          message: "Couldn't add appropriate channels " + error.graphQLErrors[0].message
        });
      });
  };

  const [addListOfEditorsInformationMutation] = useMutation(ADD_LIST_OF_EDITORS);
  const addListOfEditorsInformation = (userId: number, input: EditorsInformation) => {
    return addListOfEditorsInformationMutation({
      variables: {
        userId: userId,
        firstName: input.firstName,
        lastName: input.lastName,
        editorTitle: input.title,
        email: input.email,
        phoneNumber: input.phoneNumber
      }
    })
      .then((response: any) => {
        return Promise.resolve(response.data.addListOfEditorsInformation);
      })
      .catch((error: any) => {
        broadcastOnboardingErrorAction({
          severity: SeveritySnackbarEnum.error,
          message:
            "Couldn't add list of editors " + error.graphQLErrors[0].message
        });
      });
  };

  const [addAboutYouWithoutProfileImageMutation] = useMutation(ADD_ABOUT_YOU_WITHOUT_PROFILE_IMAGE);
  const addAboutYouWithoutProfileImage = (userId: number, shortBiography: string) => {
    return addAboutYouWithoutProfileImageMutation({
      variables: {
        userId: userId,
        shortBiography: shortBiography
      }
    })
    .then((response) => {
      return Promise.resolve(response.data.addAboutYouWithoutProfileImage);
    })
    .catch((error: any) => {
      broadcastOnboardingErrorAction({
        severity: SeveritySnackbarEnum.error,
        message:
          "Couldn't add biography " + error.graphQLErrors[0].message
      });
    });
  }

  const handleAddEitorsInformation = async () => {
    let assignPromises: Promise<any>[] = [];
    if (editorsInformation) {
      assignPromises = editorsInformation.map(
        async (editor: EditorsInformation) => {
          return await addListOfEditorsInformation(currentUserId!, editor);
        }
      );
    }
    Promise.all(assignPromises)
      .then((result: any) => {
        return Promise.resolve(result);
      })
      .catch((error: any) => {
        broadcastOnboardingErrorAction({
          severity: SeveritySnackbarEnum.error,
          message: "Couldn't add editors " + error.graphQLErrors[0].message
        });
      });
  };

  const handleGeneralInformation = async () => {
    if (currentUserId) {
      if (!isOrganization && !isAgency) {
        await addGeneralInformationPerson(currentUserId, generalInformationPerson!)
          .then((result: any) => {
            return Promise.resolve(result);
          })
          .catch((error: any) => {
            broadcastOnboardingErrorAction({
              severity: SeveritySnackbarEnum.error,
              message: "Couldn't add general information " + error.graphQLErrors[0].message
            });
          });
      } else {
        await addGeneralInformationCompany(currentUserId, generalInformationCompany!)
          .then((result: any) => {
            return Promise.resolve(result);
          })
          .catch((error: any) => {
            broadcastOnboardingErrorAction({
              severity: SeveritySnackbarEnum.error,
              message: "Couldn't add general information " + error.graphQLErrors[0].message
            });
          });
      }
    }
  };

  const submitOnboardingData = async () => {
    await handleStepOne()
      .then(async (result: any) => {
        if (result) {
          setCompletedOnboardingAction(true);
          await updateUserOnboardingStatus(currentUserId);
          setLoadingIndicator(false);
          setActiveStep(activeStep + 1);
        }
      })
      .catch((error: any) => {
        if (error) {
          broadcastOnboardingErrorAction({
            severity: SeveritySnackbarEnum.error,
            message: error
          });
        } else {
          broadcastOnboardingErrorAction({
            severity: SeveritySnackbarEnum.error,
            message: "You must fill all fields to proceed to next step"
          });
        }
      });
  }

  const handleStepOne = async () => {
    let result = true;
    setLoadingIndicator(true);
    try {
      if (currentUserId) {
        await handleGeneralInformation()
          .then(result => { return Promise.resolve(result)})
          .catch(error => {
            throw new Error("Couldn't add general information for user: " + error.graphQLErrors[0].message);
          });
        await addResponsiblePerson(currentUserId, responsiblePerson!)
          .then(result => { return Promise.resolve(result)})
          .catch(error => {
            throw new Error("Couldn't add responsible person: " + error.graphQLErrors[0].message);
          });
        await addMailingAddress(currentUserId, mailingAddressLocations, mailingAddressInput)
          .then(result => { return Promise.resolve(result)})
          .catch(error => {
            throw new Error("Couldn't add mailing address: " + error.graphQLErrors[0].message);
          });
        await addBillingAddress(currentUserId, billingAddressLocations, billingAddressInput)
          .then(result => { return Promise.resolve(result)})
          .catch(error => {
            throw new Error("Couldn't add billing address: " + error.graphQLErrors[0].message);
          });
        if (aboutYou && aboutYou.profileImage !== null) {
          await uploadToBucketProfileImage(aboutYou.profileImage!.fileWithMeta, currentUserId, aboutYou.shortBiography, url, config)
            .then(result => {
              setProfileImageUrl(result);
            })
            .catch(error => {
              throw new Error("Couldn't add 'About you' information: " + error.graphQLErrors[0].message);
            });
        }
        else if (aboutYou && aboutYou.profileImage === null  && aboutYou.shortBiography) {
          await addAboutYouWithoutProfileImage(currentUserId, aboutYou.shortBiography)
            .then(result => {
              return Promise.resolve(result)
            })
            .catch(error => {
              throw new Error("Couldn't add 'About you' information: " + error.graphQLErrors[0].message);
            });
        }
        if (isContributor) {
          await addLicensing(currentUserId!, licensing!)
            .then(result => { return Promise.resolve(result)})
            .catch(error => {
              throw new Error("Couldn't add licensing information: " + error.graphQLErrors[0].message);
            });
          await addChannelsForContent(currentUserId!, appropriateChannels!)
            .then(result => { return Promise.resolve(result)})
            .catch(error => {
              throw new Error("Couldn't add appropriate channels: " + error.graphQLErrors[0].message);
            });
        }
        if (isOrganization && editorsInformation.length > 0) {
          await handleAddEitorsInformation()
            .then(result => { return Promise.resolve(result)})
            .catch(error => {
              throw new Error("Couldn't add editors information: " + error.graphQLErrors[0].message);
            });
        }
      }
    } catch (error: any) {
      broadcastOnboardingErrorAction({
        severity: SeveritySnackbarEnum.error,
        message: error
      });
      setLoadingIndicator(false);
      result = false;
    }

    return result;
  };

  useEffect(() => {
    if(profileImageUrl) {
      setUserProfileImageAction(profileImageUrl);
    }
  }, [profileImageUrl])

  const handleNextPage = () => {
    if (activeStep === 0 && currentUserId && !hasUserCompletedOnboarding) {
      validateOnboardingDataAction(currentUser);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleClose = () => {
    setShowOnboardingSnackbar(false);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleExploreMoreButton = () => {
    history.push("/");
  };

  const getStepContent = (index: number) => {
    switch (steps[index]) {
      case OnboardingSteps.GENERAL_INFORMATION:
        return <OnboardingStepOne />;
      case OnboardingSteps.PREFERENCES:
        return (
          <OnboardinStepTwo headline={t("Onboarding.StepTwo.Headline.Member")} />
        );
      case OnboardingSteps.LICENSING:
        return <OnboardingLicensingStep handlePageChange={handleNextPage} />;
      case OnboardingSteps.COMPLETE:
        return (
          <CompleteBanner
            headerText={t("Onboarding.Complete.HeaderText")}
            heading={t("Onboarding.Complete.Heading")}
            contentText={contentTextMessage}
            imagePath={imagePath}
            isInOnboarding={true}
            redirect={handleExploreMoreButton}
          />
        );
      default:
        return (
          <CompleteBanner
            headerText={t("Onboarding.Complete.HeaderText")}
            heading={t("Onboarding.Complete.Heading")}
            contentText={contentTextMessage}
            imagePath={imagePath}
            isInOnboarding={true}
            redirect={handleExploreMoreButton}
          />
        );
    }
  };

  return (
    <React.Fragment>
      <MuiThemeProvider theme={stepperTheme}>
        <div className="page-wrapper">
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label: any) => (
              <Step key={label}>
                <StepLabel>{t(label)}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {loadingIndicator ? <LoadingSpinner /> : getStepContent(activeStep)}
        </div>
      </MuiThemeProvider>
      <div className="step-navigation">
        {
          steps[activeStep] !== OnboardingSteps.COMPLETE ?
            <React.Fragment>
              <button
                className={`back-button ${activeStep === 0 || activeStep === steps.length - 1 ? "hidden-button" : ""}`}
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                <span className="button-text">
                  <ArrowBackIcon />
                  {t("Back.Button")}
                </span>
              </button>
              <button className={`next-page ${activeStep === steps.length - 1 ? "hidden-button" : ""}`} onClick={handleNextPage} disabled={activeStep === steps.length - 1}>
                <span className="button-text">
                  {steps[activeStep] === OnboardingSteps.LICENSING ? t("Skip.Button") : t("NextStep.Button")}
                  {<ArrowForwardIcon />}
                </span>
              </button>
            </React.Fragment>
            : null
        }

      </div>
      <SnackbarComponent
        showSnackbar={showOnboardingSnackbar}
        handleClose={handleClose}
        severity={onboardingError.severity}
        message={onboardingError.message}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (
  state: StoreState
): {
  currentUser: User;
  currentUserId: number;
  generalInformationPerson: GeneralInformationPerson;
  generalInformationCompany: GeneralInformationCompany;
  responsiblePerson: PersonResponsibleForAccount;
  licensing: Licensing;
  mailingAddress: Addresses;
  billingAddress: Addresses;
  appropriateChannels: AppropriateChannels;
  editorsInformation: EditorsInformation[];
  aboutYou: AboutYou;
  onboardingError: IBroadcastMessage;
  hasUserCompletedOnboarding: boolean;
  isOnboardingValid: OnboardingValidation;
} => {
  return {
    currentUser: selectCurrentUser(state),
    currentUserId: selectCurrentUserId(state),
    generalInformationPerson: selectGeneralInformationPerson(state),
    generalInformationCompany: selectGeneralInformationCompany(state),
    responsiblePerson: selectResponsiblePersonInfromation(state),
    licensing: selectLicensingInformation(state),
    mailingAddress: selectMailingAddressInformation(state),
    billingAddress: selectBillingAddressInformation(state),
    editorsInformation: selectEditorsInformation(state),
    appropriateChannels: selectAppropriateChannels(state),
    aboutYou: selectAboutYouInformation(state),
    onboardingError: selectUploadError(state),
    hasUserCompletedOnboarding: selectHasUserCompletedOnboarding(state),
    isOnboardingValid: selectIsOnboardingValid(state)
  };
};

const mapDispatchToProps = ( dispatch: Dispatch<TContentUploadActions | TOnboardingReducerAction | TUserReducerActions> ) => {
  return {
    broadcastOnboardingErrorAction: (data: IBroadcastMessage) =>
      dispatch<IBroadcastContentUploadMessage>({
        type: ContentUploadActionTypes.BROADCAST_MESSAGE,
        data: data
      }),
    setCompletedOnboardingAction: (data: boolean) =>
      dispatch<ISetCompletedOnboarding>({
        type: UserActionTypes.SET_COMPLETED_ONBOARDING,
        data: data
      }),
    setUserProfileImageAction: (data: string) => dispatch<ISetUserProfileImage>({ type: UserActionTypes.SET_USER_PROFILE_IMAGE, data: data }),
    validateOnboardingDataAction: (data: User) => dispatch<IValidateOnboarding>({type: OnboardingActionTypes.VALIDATE_ONBOARDING_DATA, data: data})
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingPage);
