import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { OnboardingLicensingStepProps } from "./onboarding-page.types";
import GetLicensingPlanPage from "../my-license-page/get-license-page.component";
import SubscriptionPayment from '../my-license-page/get-license-payment';
import { CompleteBanner } from 'shared';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { connect } from "react-redux";
import { Grow } from '@material-ui/core';
import { Dispatch } from "redux";
import { IBroadcastSubscriptionMessage, TSubscriptionReducerActions } from "../../redux/subscriptions/subscription-page.actions";
import { SubscriptionActionTypes } from "../../redux/subscriptions/subscription-page.types";
import { CallHistoryMethodAction, push } from "connected-react-router";
import { StoreState } from "../../redux/root-reducer";
import { IBroadcastMessage, SeveritySnackbarEnum } from "../batch-upload/ContentUpload.types";
import { SnackbarComponent } from 'shared';
import { selectSubscriptionBroadcast } from "../../redux/subscriptions/subscription-page.selector";
import {config} from "../../config";

const OnboardingLicensingStep: React.FC<OnboardingLicensingStepProps> = ({ ...props }) => {
  const {subscriptionBroadcast, broadcastSubscriptionAction, handlePageChange, redirectToHome} = props;
  const { t } = useTranslation();
  const [stripePromise, setStripePromise] = useState(null);

  const [paymentSnackbar, setPaymentSnackbar] = useState(false);

  const [currentStep, setCurrentStep] = useState(1);

  useEffect(() => {
    if(stripePromise === null)
      loadStripeInstance();
  }, [])

  useEffect(() => {
    if(subscriptionBroadcast.message && subscriptionBroadcast.severity) {
      setPaymentSnackbar(true);
    } 
  }, [subscriptionBroadcast])

  const handleNextPage = () => {
    setCurrentStep(currentStep + 1);
  }

  const loadStripeInstance = async () => {
    await loadStripe(config.REACT_APP_STRIPE_LOAD_KEY!)
      .then((result: any) => {
        setStripePromise(result);
      }).catch((error: any) => {
        broadcastSubscriptionAction({severity: SeveritySnackbarEnum.error, message: "Failed to load instance of Stripe"});
      })
  }

  const renderLicensingPurchaseStep = () => {
    if(currentStep === 1) {
      return <GetLicensingPlanPage
                handlePageChange={handleNextPage}/>
    } else if(currentStep === 2) {
      return <Elements stripe={stripePromise}>
              <SubscriptionPayment handlePageChange={handlePageChange}/>
            </Elements>
    } else if(currentStep === 3) {
      return <Grow in={true}>
        <CompleteBanner redirect={redirectToHome} headerText={t("License.Complete.Banner.Heading")} isInOnboarding={true}
          heading={t("License.Complete.Banner.Caption")} contentText={t("License.Complete.Banner.ContentText")}
          imagePath="https://bucket-wi-dev1.s3.eu-north-1.amazonaws.com/home/health.jpg"/>
      </Grow>
    }
  }

  const handleClose = () => {
    setPaymentSnackbar(false);
  }

  return (
    <React.Fragment>
      <div className="options-head">
        <h3>
          {t("Onboarding.Licensing.Message")}
        </h3>
      </div>    
      {renderLicensingPurchaseStep()}

      {/* To resolve errors and messages from payment process */}
      <SnackbarComponent
        showSnackbar={paymentSnackbar}
        handleClose={handleClose}
        severity={subscriptionBroadcast.severity}
        message={subscriptionBroadcast.message}
        />
    </React.Fragment>
  );
};


const mapStateToProps = (state: StoreState): { subscriptionBroadcast: IBroadcastMessage; } => {
  return {
    subscriptionBroadcast: selectSubscriptionBroadcast(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<TSubscriptionReducerActions | CallHistoryMethodAction>) => {
  return {
    broadcastSubscriptionAction: (data: IBroadcastMessage) => dispatch<IBroadcastSubscriptionMessage>({
      type: SubscriptionActionTypes.BROADCAST_SUBSCRIPTION_MESSAGE, data: data
    }),
    redirectToHome: () => dispatch(push('/'))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingLicensingStep);
