import gql from "graphql-tag";

export const GET_CONTENT_COUNT = gql`
	query countContentsInSet($contentSetId: Int!) {
		countContentsInSet(contentSetId: $contentSetId)
  	}
`;

export const GET_CONTENT_SET_BY_SET_ID = gql`
query getFullContentInContentSetQuery($contentSetId: Int!, $userId: Int) {
	getFullContentInContentSet(contentSetId: $contentSetId, userId: $userId) {
	  content {
		content {
		  id
		  pathToFileCompressed
		  pathToFileThumbnail
		  headline
		  isOwned
		  licensingType
		  size
		}
		contentRate {
			flatRate
		}
	  }
	  contentSet {
		title
	  }
	}
  }
`;

export const GET_CONTENT_SET_BY_ARTICLE_ID = gql`
query getFullContentInContentSetByArticleIdQuery(
	$articleId: Int!
	$userId: Int
  ) {
	getFullContentInContentSetByArticleId(
	  articleId: $articleId
	  userId: $userId
	) {
	  content {
		content {
		  id
		  pathToFileCompressed
		  pathToFileThumbnail
		  isOwned
		  licensingType
		  size
		}
	  }
	  contentSet {
		title
	  }
	  article {
		title
		description
		audioId
	  }
	  audioFile {
		id
		pathToFile
		headline
		publishDate
		caption
		type
		licensingType
		externalLink
		externalLinkMessage
	  }
	}
  }
  
`;

export const GET_CONTENT_INFO = gql`
	query getFullContentInfoQuery($contentId: Int!, $userId: Int) {
		getFullContentInfo(contentId: $contentId, userId: $userId) {
			user {
				username
				role
				person {
					id
					firstName
					lastName
				}
				company {
					id
					name
				}
			}
			content {
				id
				pathToFileCompressed
				pathToFileThumbnail
				headline
				publishDate
				caption
				type
				licensingType
				externalLink
				externalLinkMessage
				code
			}
			location {
				country {
					country
				}
				city {
					city
				}
				province {
					stateProvince
				}
			}
			copyright {
				byLine
			}
			contentRate {
				flatRate
			}
		}
	}
`;

export const GET_COMMENTS = gql`
	query getCommentsQuery($contentId: Int!) {
		getComments(contentId: $contentId) {
			comment {
				publishDate
				id
				comment
			}
			user {
				id
				username
				role
				person {
					id
					firstName
					lastName
				}
				company {
					id
					name
				}
			}
		}
	}
`;

export const POST_COMMENT = gql`
	mutation addCommentMutation($contentId: Int!, $userId: Int!, $comment: String!) {
		addComment(contentId: $contentId, userId: $userId, comment: $comment) {
			id
		}
	}
`;

export const GET_CONTENT_NAVIGATION_THREAD_BY_ID = gql`
	query getContentNavigationByThreadId($threadIds: [Int!]!) {
		getContentNavigationByThreadId(threadIds: $threadIds) {
			channel {
				id
				title
				code
			}
			subChannel {
				id
				title
			}
			thread {
				id
				title
			}
		}
	}
`;

export const GET_SINGLE_CONTENT_RATE = gql`
	query getContentRateByTypeQuery($licenseType: String!, $contentType: String!, $role: String!) {
		getContentRateByType(licenseType: $licenseType, contentType: $contentType, role: $role) {
			flatRate
		}
	}
`;

export const GET_LICENSE_PLAN_BY_ID = gql`
	query getLicensingPlanByIdQuery($id: Int!) {
		getLicensingPlanById(id: $id) {
			licensingType
			totalPrice
			period
			type
			amount
		}
	}
`;

export const GET_EDITORIAL_RATES = gql`
	query getEditorialRatesQuery {
		getEditorialRates {
			rate
			size
		}
	}
`;

export const GET_AUDIO_FILE_PRICE = gql`
	query getAudioFilePriceQuery($userId: Int!, $audioFileId: Int!) {
		getAudioFilePrice(userId: $userId, audioFileId: $audioFileId) {
			flatRate
		}
	}
`;

export const GET_LICENSE_PLAN_BY_USER_ID_AND_TYPE = gql`
	query getPlanByIdAndTypeQuery($userId: Int!, $licensingType: String!) {
		getPlanByIdAndType(userId: $userId, licensingType: $licensingType) {
			id
			licensingPlanId
			amountLeft
			purchaseDate
			expirationDate
			plan {
				period
				type
				licensingType
				totalPrice
				amount
			}
		}
	}
`;

export const GET_CONTENT_NAVIGATION_BY_CONTENT_ID = gql`
	query getContentNavigationsByContentId($contentId: Int!) {
		getContentNavigationsByContentId(contentId: $contentId) {
			channel {
				id
				title
				code
			}
			subChannel {
				id
				title
			}
			thread {
				id
				title
			}
		}
	}
`;

export const CHECK_IS_CONTENT_OWNED = gql`
query checkIfContentIsOwnedQuery($contentId: Int!, $userId: Int!) {
	checkIfContentIsOwned(contentId: $contentId, userId: $userId){
		  isOwned
	  sizes
	}
  }
`;

export const GET_ALL_LIGHT_BOXES_OF_USER = gql`
query getAllUserLightboxes(
  $userId: Int!
) {
  getAllUserLightboxes(
    userId: $userId
  ){
    total
    lightboxes{
      contentLightboxId
      lightboxName
      coverId
      userId
      timestamp
      cover{
        pathToFileThumbnail
        pathToFileCompressed
			}
    }
  }
}
`;