import { User } from '../../redux/user/user.types';

export interface PrivateRouteProps {
    component: any;
    path: string;
    exact: boolean;
    currentUser: User;
    loginSuccessAction: (data: User) => void;
}

export enum ContributorsRoutes {
    CONTENT_UPLOAD = '/content-upload',
    UPLOADED_CONTENT = '/my-content',
    ONBOARDING = '/onboarding',
    LIGHT_BOX = '/light-box',
    LIGHT_BOX_CONTENT_PAGE = '/light-box/:lightBoxId',
    MY_SALES = '/my-sales',
}

export enum ProMemberRoutes {
    ONBOARDING = '/onboarding',
    MY_LICENSE_PAGE = '/my-license-page',
    MY_DOWNLOADS_PAGE = '/my-downloads',
    GET_LICENSE_PLAN = '/get-license-plan-page',
    CART_PAGE = '/cart',
    LIGHT_BOX = '/light-box',
    LIGHT_BOX_CONTENT_PAGE = '/light-box/:lightBoxId',
}

export enum MemberRoutes {
    ONBOARDING = '/onboarding',
    CART_PAGE = '/cart',
}

export enum AdminRoutes {
    LIGHT_BOX = '/light-box',
    LIGHT_BOX_CONTENT_PAGE = '/light-box/:lightBoxId',
}