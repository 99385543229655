import { createTheme } from "@material-ui/core/styles";
import "./cart-page.styles.scss";
import {
  EditorialSizes,
  ItemForCart,
  LicensePlan
} from "../photo-details/photo-details.types";
import { makeStyles } from '@material-ui/core/styles';
import { User } from "../../redux/user/user.types";
import { Addresses } from "../../redux/onboarding/onboarding.types";
import { TaxForOrder } from "../../redux/cart/cart-page.types";
import { IBroadcastMessage } from "../batch-upload/ContentUpload.types";
import { UserLicensingPlan } from "../my-license-page/my-license-page.types";

export enum PaymentMethods {
  PAYPAL = "paypal",
  CARD = "card",
  Crypto = "crypto"
}

export enum CartSteps {
  MANAGE = "CartPage.ManageCart",
  SUBSCRIPTION = "CartPage.PayWithSubscription",
  PAYMENT = "CartPages.Payments",
  COMPLETE = "Onboarding.Step.Complete"
}
export enum SingleContentTypes {
  Image = "image",
  Video = "video",
  Audio = "audio"
}

export enum InvoiceTemplateTypes {
  STANDARD = "standard",
  SUBSCRIPTION = "subscription",
  MIXED = "mixed"
}

export interface CartStepOneProps {
  cartItems: ItemForCart[];
  cartItemsFromSub: ItemForCart[];
  cartItemsCount: number;
  subtotal: number;
  userHasValidLicensePlans: boolean;
  currentUser?: User;
  handlePageChange: () => void;
  broadcastCartAction: (data: IBroadcastMessage) => void;
  removeFromCartAction: (item: ItemForCart, isPartOfplan: boolean) => void;
  emptyCart: () => void;
}
export interface CartPageProps {
  cartItems: ItemForCart[];
  cartItemsFromSub: ItemForCart[];
  cartError: IBroadcastMessage;
  cartItemsCount: number;
  currentUser: User;
  toggleLoginModalAction: () => void;
  broadcastCartAction: (data: IBroadcastMessage) => void;
  clearBroadcast: () => void;
}
export interface CartPaymentProps {
  total: number;
  cartItems: ItemForCart[];
  cartItemsFromSub: ItemForCart[];
  currentUser: User;
  tax: TaxForOrder | null;
  additionalBillingAddress: Addresses | null;
  broadcastCartAction: (data: IBroadcastMessage) => void;
  handlePageBack: () => void;
  handlePageChange: () => void;
  emptyCart: () => void;
  addTaxAction: (data: TaxForOrder) => void;
}

export interface CartStepSubscriptionsProps {
  cartItems: ItemForCart[]; 
  cartItemsFromSub: ItemForCart[]; 
  licensePlans: UserLicensingPlan[];
  subtotal: number; 
  total: number; 
  currentUser: User;
  isLicensePlanInvalid: boolean;
  tax: TaxForOrder | null;
  emptyCart: () => void;
  handlePageBack: () => void;
  handlePageChange: () => void;
  removeFromCartAction: (item: ItemForCart, isPartOfPlan: boolean) => void;
  broadcastCartAction: (data: IBroadcastMessage) => void;
}

export interface ContentOrder {
  id: number;
  pathToFile: string;
  pathToFileCompressed: string;
}

export interface CartTableProps {
  cartItems: ItemForCart[];
  subtotal: number;
  cartIsForSub?: boolean;
  handleRemove: (item: ItemForCart) => void;
}

export enum CartTableHeadings {
  PREVIEW = "Preview",
  HEADLINE = "Headline",
  ITEM_TYPE = "ItemType",
  LICENSE_TYPE = "LicenseType",
  PRICE = "Price",
  SIZE = "Size",
  REMOVE = "Remove"
}

export const stepperTheme = createTheme({
  overrides: {
    MuiStepIcon: {
      completed: {
        color: "rgb(12, 175, 149) !important"
      },
      active: {
        color: "rgb(12, 175, 149) !important"
      }
    },
    MuiPaper: {
      root: {
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
        "&:before": {
          display: "none"
        }
      }
    },
    MuiStepLabel: {
      active: {
        color: "rgb(12, 175, 149) !important"
      },
      completed: {
        color: "rgb(12, 175, 149) !important"
      }
    }
  }
});

export const detailAccordions = makeStyles((theme) => ({
  MuiAccordionRoot: {
    width: '100%',
    minHeight: '200px'
  }
}));

export interface ReplacementsDto {
  tableItems?: string;
  total?: number;
  url?: string;
}

export interface EmailDto {
  email: string;
  accessTemplate: string;
  replacements?: ReplacementsDto;
}

export enum LicenseOriginTypes {
  STANDARD = "standard",
  SUBSCRIPTION = "subscription",
  CREDIT = "credit"
}

export interface ContentOrderDto {
  contentId: number;
  contentPrice: number;
  contentTax?: number;
  licenseOrigin: LicenseOriginTypes;
  size?: string;
  amount?: number;
  totalPrice?: number;
  period?: number;
  role?: string;
  isbn?: string;
  issn?: string;
}

export interface Order {
  id: number;
  userId: number;
}

export interface ContentFromOrder {
  id: number;
  bucketKey: string;
  type: SingleContentTypes;
}

export interface OrderDto {
  order: Order;
  contentOrders: ContentOrderDto[];
  contents: ContentFromOrder[];
}

export interface ReactPreviewProps {
  item: ItemForCart;
}
export interface CartSummaryProps {
  loading: boolean;
  tax: TaxForOrder | null; 
  cartItems: ItemForCart[]; 
  cartItemsFromSub: ItemForCart[]; 
  subtotal: number; 
  total: number; 
  currentUser: User;
  userLicensingPlans: UserLicensingPlan[];
  isLicensePlanInvalid?: boolean;
  isSummaryForSubscriptions?: boolean;
}

export interface TaxLineItemInput {
  id: string;
  quantity?: number;
  unit_price: number;
  discount: number;
}

export interface CompleteBannerStepProps {
  currentUser: User;
  redirectToHome: () => void;
  broadcastCartErrorAction: (data: IBroadcastMessage) => void;
}
export interface SubscriptionItemsNumbersByType {
  standard: number;
  premium: number;
}

export interface UserPlanToUpdateParams {
  userLicensingPlanId: number;
  itemCount: number;
}
