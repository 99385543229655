import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  AddressComponentProps,
  AddressTypes,
  buttonStyles
} from "./onboarding-page.types";
import {
  TextField,
  Accordion,
  AccordionSummary,
  AccordionActions,
  Button,
  Checkbox,
  FormControlLabel,
  InputAdornment
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { RoleTypes } from "../register/register.types";
import { OnboardingAddressAllYup } from "./yup-validation-objects";
import axios from "axios";
import { IBroadcastMessage, SeveritySnackbarEnum } from "../../components/batch-upload/ContentUpload.types";
import { IBroadcastContentUploadMessage, TContentUploadActions } from "../../redux/content-upload/content-upload.actions";
import { Dispatch } from "redux";
import { ContentUploadActionTypes } from "../../redux/content-upload/content-upload.types";
import { connect } from "react-redux";
import { IBillingAddress, IBroadcastOnboardingError, IMailingAddress, ISetIsUseMailingAddressChecked, IToggleAboutYouAccordion, IToggleBillingAddressAccordion, IToggleEditorsInformationAccordion, IToggleLicensingAccordion, IToggleMailingAddressAccordion, TOnboardingReducerAction } from "../../redux/onboarding/onboarding.actions";
import { Addresses, AddressSuggestion, OnboardingActionTypes } from "../../redux/onboarding/onboarding.types";
import { StoreState } from "../../redux/root-reducer";
import { selectCurrentUser } from "../../redux/user/user.selectors";
import { User } from "../../redux/user/user.types";
import { SnackbarComponent } from "shared";
import { IAddBillingAddress, TCartReducerActions } from "../../redux/cart/cart-page.actions";
import { CartActionTypes } from "../../redux/cart/cart-page.types";
import { LocationGroups, RapidApiCityDto, RapidApiCountryDto, RapidApiProvinceDto } from "../../globals/locations/locations.types";
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { selectBillingAddressAccordionState, selectBillingAddressInformation, selectIsUseMailingAddressCheckedState, selectMailingAddressAccordionState, selectMailingAddressInformation } from "../../redux/onboarding/onboarding.selectors";
import './onboarding-page.styles.scss';
import { useLazyQuery } from "@apollo/react-hooks";
import { VALIDATE_ADDRESS } from "./queries";
import { doesCountryExistInRapidApi, doesProvinceExistInRapidApi } from "../../components/batch-upload/helperFunctions";
import {config} from "../../config";

const AddressComponent: React.FC<AddressComponentProps> = ({ ...props }) => {
  const { userRole, addressType, broadcastOnboardingLocationErrorAction, saveBillingAddressAction, saveMailingAddressAction,
    mailingAddress, billingAddress, addBillingAddressForCheckout, toggleAnotherBilling, isInCheckout,
    toggleBillingAddressAccordion, toggleLicensingAccordionAction, toggleEditorsAccordionAction, toggleMailingAddressAccordion,
    toggleBillingAddressAccordionAction, toggleAboutYouAccordionAction, toggleMailingAddressAccordionAction,
    setIsUseMailingAddressSelectedAction, isUseMailingAddressChecked } = props;
  const classes = buttonStyles();
  const [open, setOpen] = useState(isInCheckout ? true : false);
  const [headlineMessage, setHeadlineMessage] = useState("");
  const [showSavedSnackbar, setShowSavedSnackbar] = useState(false);
  const [showSaveFailedSnackbar, setShowSaveFailedSnackbar] = useState(false);
  const [initialValuesInstance, setInitialValuesInstance] = useState({
    country: "",
    stateProvince: "",
    city: "",
    zipCodeNumber: "",
    addressLineOne: "",
    addressLineTwo: ""
  })
  const [isChecked, setIsChecked] = useState(false);
  const [countries, setCountries] = useState<RapidApiCountryDto[]>([]);
  const [countryCode, setCountryCode] = useState<string>("");
  const [stateCode, setStateCode] = useState<string>("");
  const [provinces, setProvinces] = useState<RapidApiProvinceDto[]>([]);
  const [cities, setCities] = useState<RapidApiCityDto[]>([]);

  const { t } = useTranslation();

  const isMember = userRole === RoleTypes.Member;
  const isProOrganization = userRole === RoleTypes.Pro_organization;
  const isContributor = userRole === RoleTypes.Contributor_agency || userRole === RoleTypes.Contributor_individual;
  const isProFreelancer = userRole === RoleTypes.Pro_freelancer;

  useEffect(() => {
    if (toggleBillingAddressAccordion && addressType === AddressTypes.BILLING) {
      setOpen(true);
    }
    else if (toggleMailingAddressAccordion && addressType === AddressTypes.MAILING) {
      setOpen(true);
      toggleMailingAddressAccordionAction(false);
    }
  }, [toggleBillingAddressAccordion, toggleMailingAddressAccordion]);

  useEffect(() => {
    if (isChecked) {
      setIsUseMailingAddressSelectedAction(true);
    }
    else {
      setIsUseMailingAddressSelectedAction(false);
      saveBillingAddressAction({
        country: "",
        countryCode: "",
        state: "",
        stateCode: "",
        city: "",
        zipCode: "",
        addressLineOne: "",
        addressLineTwo: ""
      })
    }
  }, [isChecked])

  useEffect(() => {
    const values = setSchemaDefaultInstance();

    setInitialValuesInstance(values);
  }, [billingAddress, mailingAddress])

  useEffect(() => {
    if (isUseMailingAddressChecked) {
      saveBillingAddressAction(mailingAddress);
    }
  }, [isUseMailingAddressChecked])

  useEffect(() => {
    fetchLocations(LocationGroups.COUNTRY);

    if (addressType === AddressTypes.BILLING) {
      setHeadlineMessage(t("Onboarding.Address.Billing.Address.Heading"));
    }
    else {
      setHeadlineMessage(t("Onboarding.Address.Mailing.Address.Heading"));
    }
  }, [addressType]);

  const getLocationURL = (group: string, country?: RapidApiCountryDto, province?: RapidApiProvinceDto) => {
    if (group === LocationGroups.COUNTRY) {
      return `${config.REACT_APP_PUBLIC_SERVER_HOST}/countries`;
    }
    else if (group === LocationGroups.PROVINCE && country) {
      return `${config.REACT_APP_PUBLIC_SERVER_HOST}/provinces/${country.countryCode}`
    }
    else if (group === LocationGroups.CITY && province) {
      return `${config.REACT_APP_PUBLIC_SERVER_HOST}/cities/${countryCode}/${province.isoCode}`
    }
  };

  const getLocationSetter = (group: string, result: any) => {
    if (group === LocationGroups.COUNTRY) {
      setCountries(result.data);
      return;
    }
    else if (group === LocationGroups.PROVINCE) {
      setProvinces(result.data);
      return;
    }
    else if (group === LocationGroups.CITY) {
      setCities(result.data);
      return;
    }
  };

  const fetchLocations = (group: string, country?: RapidApiCountryDto, province?: RapidApiProvinceDto) => {
    axios({
      method: "GET",
      url: getLocationURL(group, country, province)
    })
      .then((res: any) => getLocationSetter(group, res))
      .catch((error: any) => {
        broadcastOnboardingLocationErrorAction({
          severity: SeveritySnackbarEnum.error,
          message: t("Onboarding.snackbar.Error.FetchingLocation")
        })
      })
  }

  const getValidationSchema = () => {
    if (isMember) {
      return OnboardingAddressAllYup.OnboardingAddressMemberYup;
    } else {
      return OnboardingAddressAllYup.OnboardingAddressDefaultYup;
    }
  };

  const handleClick = () => {
    setOpen(!open);
  };

  const setSchemaDefaultInstance = () => {
    if (addressType === AddressTypes.BILLING) {
      if (billingAddress.countryCode && billingAddress.stateCode) {
        setCountryCode(billingAddress.countryCode);
        setStateCode(billingAddress.stateCode);
      }
      return {
        country: billingAddress.country ? billingAddress.country : "",
        stateProvince: billingAddress.state ? billingAddress.state : "",
        city: billingAddress.city ? billingAddress.city : "",
        zipCodeNumber: billingAddress.zipCode ? billingAddress.zipCode : "",
        addressLineOne: billingAddress.addressLineOne ? billingAddress.addressLineOne : "",
        addressLineTwo: billingAddress.addressLineTwo ? billingAddress.addressLineTwo : ""
      }
    } else {
      if (mailingAddress.countryCode && mailingAddress.stateCode) {
        setCountryCode(mailingAddress.countryCode);
        setStateCode(mailingAddress.stateCode);
      }
      return {
        country: mailingAddress.country ? mailingAddress.country : "",
        stateProvince: mailingAddress.state ? mailingAddress.state : "",
        city: mailingAddress.city ? mailingAddress.city : "",
        zipCodeNumber: mailingAddress.zipCode ? mailingAddress.zipCode : "",
        addressLineOne: mailingAddress.addressLineOne ? mailingAddress.addressLineOne : "",
        addressLineTwo: mailingAddress.addressLineTwo ? mailingAddress.addressLineTwo : ""
      }
    }
  }

  const validateAddress = () => {

  }

  const { handleSubmit, handleChange, setFieldValue, resetForm, values, errors } = useFormik({
    initialValues: initialValuesInstance,
    validateOnBlur: true,
    validationSchema: getValidationSchema(),
    enableReinitialize: true,
    onSubmit(values) {
      const { country, stateProvince, zipCodeNumber, city, addressLineOne, addressLineTwo } = values;

      //triggers only if AddressComponent is being used for payments
      if (isInCheckout) {
        addBillingAddressForCheckout({
          country: country,
          countryCode: countryCode,
          state: stateProvince,
          stateCode: stateCode,
          city: city,
          zipCode: zipCodeNumber,
          addressLineOne: addressLineOne,
          addressLineTwo: addressLineTwo
        })

        setShowSavedSnackbar(true);
        return;
      }

      if (addressType === AddressTypes.BILLING) {
        saveBillingAddressAction({
          country: country,
          countryCode: countryCode,
          state: stateProvince,
          stateCode: stateCode,
          city: city,
          zipCode: zipCodeNumber,
          addressLineOne: addressLineOne,
          addressLineTwo: addressLineTwo
        })
        if (isProOrganization) {
          toggleEditorsAccordionAction();
        }
        else if (isProFreelancer) {
          toggleAboutYouAccordionAction();
        }
        else if (isContributor) {
          toggleLicensingAccordionAction();
        }
      } else if (addressType === AddressTypes.MAILING) {
        saveMailingAddressAction({
          country: country,
          countryCode: countryCode,
          state: stateProvince,
          stateCode: stateCode,
          city: city,
          zipCode: zipCodeNumber,
          addressLineOne: addressLineOne,
          addressLineTwo: addressLineTwo
        })
        toggleBillingAddressAccordionAction(true);
      }
      setOpen(false);
    }
  });

  const handleAutoCompleteCountriesChange = (event: any, newValue: any) => {
    if (countries) {
      const country = countries.filter(option => {
        return option.country === newValue;
      })[0];
      const isValidCountry = doesCountryExistInRapidApi(countries, country);
      if (isValidCountry) {
        setCountryCode(country.countryCode);
        if (values.country !== "") {
          setFieldValue(LocationGroups.PROVINCE, "");
          setFieldValue(LocationGroups.CITY, "");
          setFieldValue("zipCodeNumber", "");
          setFieldValue("addressLine1", "");
          setFieldValue("addressLine2", "");
        }
        fetchLocations(LocationGroups.PROVINCE, country);
        setFieldValue(LocationGroups.COUNTRY, country.country);
      }
    }
  }

  const handleAutocompleteCountriesInputChange = (event: any, value: any) => {
    if (value === '') {
      setFieldValue(LocationGroups.COUNTRY, "");
      setCountryCode("");
      setFieldValue(LocationGroups.PROVINCE, "");
      setStateCode("");
      setFieldValue(LocationGroups.CITY, "");
    }
  }

  const handleAutoCompleteProvinceChange = (event: any, newValue: any) => {
    if (provinces) {
      const province = provinces.filter(option => {
        return option.province === newValue;
      })[0];
      const isValidProvince = doesProvinceExistInRapidApi(provinces, province);
      if (isValidProvince) {
        setStateCode(province.isoCode);
        fetchLocations(LocationGroups.CITY, undefined, province);
        setFieldValue(LocationGroups.PROVINCE, province.province);
      }
    }
  }

  const handleAutocompleteProvincesInputChange = (event: any, value: any) => {
    if (value === '') {
      setFieldValue(LocationGroups.PROVINCE, "");
      setStateCode("");
      setFieldValue(LocationGroups.CITY, "");
    }
  }

  const handleAutoCompleteCityChange = (event: any, newValue: any) => {
    if (cities) {
      const city = cities.filter(option => {
        return option.city === newValue;
      })[0];
      //call VALIDATE_ADDRESS
      //set processing
      if (city) {
        setFieldValue(LocationGroups.CITY, city.city);
      }
      else {
        setFieldValue(LocationGroups.CITY, newValue);
      }
    }
  }

  const handleClose = () => {
    setShowSavedSnackbar(false);
    setShowSaveFailedSnackbar(false);
  }

  const handleUseMailingAddressCheckboxChange = () => {
    setIsChecked(prevState => !prevState);
  }

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <Accordion expanded={open}>
          <AccordionSummary
            expandIcon={!open ? <ExpandMoreIcon /> : null}
            aria-controls="panel1c-content"
            id="panel1c-header"
            onClick={handleClick}
          >
            <div className="title">{headlineMessage}</div>
          </AccordionSummary>
          <div className="accordion-container">
            <div className="loading-container">

            </div>
            <div>
              <Autocomplete
                freeSolo id="countryInput"
                style={{ width: '100%' }}
                options={countries.map((option) => option.country)}
                disableClearable
                autoHighlight
                value={values.country}
                onChange={handleAutoCompleteCountriesChange}
                onInputChange={handleAutocompleteCountriesInputChange}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'countryInput',
                    }}
                    name='country-input'
                    id="countryInput" variant='standard' placeholder="Enter country"
                  />
                )}
              />
              <div className="helper-text-select">
                {errors.country ? errors.country : null}
              </div>
            </div>

            <span>
              <Autocomplete
                freeSolo id="autocomplete-free-solo"
                style={{ width: '100%' }}
                options={provinces.map((option) => option.province)}
                disableClearable
                autoHighlight
                value={values.stateProvince}
                onChange={handleAutoCompleteProvinceChange}
                onInputChange={handleAutocompleteProvincesInputChange}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'provinceInput',
                    }}
                    id="province-input" variant='standard' placeholder="Enter province" name="province-input"
                  />
                )}
              />
              <div className="helper-text-select">
                {errors.stateProvince ? errors.stateProvince : null}
              </div>
            </span>
            <span>
              <Autocomplete
                freeSolo id="autocomplete-free-solo"
                style={{ width: '100%' }}
                options={cities.map((option) => option.city)}
                disableClearable
                autoHighlight
                autoSelect
                value={values.city}
                onChange={handleAutoCompleteCityChange}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'cityInput',
                    }}
                    id="city-input" variant='standard' placeholder="Enter city" name='city-input'
                  />
                )}
              />
              <div className="helper-text-select">
                {errors.city ? errors.city : null}
              </div>
            </span>

            {
              !isMember ?
                <React.Fragment>
                  <TextField
                    inputProps={{
                      autoComplete: 'zipCodeInput',
                    }}
                    id="zipCodeNumber"
                    onChange={handleChange}
                    name="zipCodeNumber"
                    label={t("Onboarding.ZipCode")}
                    value={values.zipCodeNumber}
                    helperText={errors.zipCodeNumber ? errors.zipCodeNumber : null}
                  />
                  <TextField
                    inputProps={{
                      autoComplete: 'addressLineOneInput',
                    }}
                    id="addressLineOne"
                    onChange={handleChange}
                    name="addressLineOne"
                    label={t("Onboarding.Address.AddressLine1")}
                    value={values.addressLineOne}
                    helperText={errors.addressLineOne ? errors.addressLineOne : null}
                  />
                  <TextField
                    inputProps={{
                      autoComplete: 'addressLineTwoInput',
                    }}
                    id="addressLineTwo"
                    onChange={handleChange}
                    name="addressLineTwo"
                    label={t("Onboarding.Address.AddressLine2")}
                    value={values.addressLineTwo}
                    helperText={errors.addressLineTwo ? errors.addressLineTwo : null}
                  />
                </React.Fragment> : null
            }
            {addressType === AddressTypes.BILLING && !isInCheckout ?
              <span>
                <FormControlLabel
                  id="agreement-check"
                  labelPlacement="start"
                  label={t("Onboarding.Use.MailingAddress")}
                  control={
                    <Checkbox
                      color="default"
                      onChange={handleUseMailingAddressCheckboxChange}
                      name="merchandiseAgreement"
                      checked={isUseMailingAddressChecked ? true : false}
                    />
                  }
                />
              </span>
              : null}

            {/* TAXJAR SUGGESTIONS */}
            {/* {addressSuggestions.length > 0 && addressIsValid ? 
                addressSuggestions.map((suggestion: AddressSuggestion) => {
                  return null
                })
              :
                <Typography color="textPrimary" gutterBottom variant="body2">
                    No suggestions for this address.
                </Typography>
              } */}

            <AccordionActions style={{ justifyContent: open ? "flex-end" : "center" }}>
              <Button type="submit" classes={{ root: classes.greenButtonRoot }}>
                {t("Onboarding.StepOne.Save.button")}
              </Button>
            </AccordionActions>
          </div>
        </Accordion>
      </form>

      <SnackbarComponent
        showSnackbar={showSavedSnackbar}
        handleClose={handleClose}
        severity="success"
        message={t("Cart.Billing.Saved")}
      />

      <SnackbarComponent
        showSnackbar={showSaveFailedSnackbar}
        handleClose={handleClose}
        severity="success"
        message={t("Cart.Billing.Saved.Failed")}
      />
    </React.Fragment>
  )
};

const mapStateToProps = (state: StoreState): { currentUser: User, mailingAddress: Addresses, billingAddress: Addresses, toggleBillingAddressAccordion: boolean, toggleMailingAddressAccordion: boolean, isUseMailingAddressChecked: boolean } => {
  return {
    currentUser: selectCurrentUser(state),
    mailingAddress: selectMailingAddressInformation(state),
    billingAddress: selectBillingAddressInformation(state),
    toggleBillingAddressAccordion: selectBillingAddressAccordionState(state),
    toggleMailingAddressAccordion: selectMailingAddressAccordionState(state),
    isUseMailingAddressChecked: selectIsUseMailingAddressCheckedState(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch<TOnboardingReducerAction | TCartReducerActions>) => {
  return {
    broadcastOnboardingLocationErrorAction: (data: IBroadcastMessage) => dispatch<IBroadcastOnboardingError>({ type: OnboardingActionTypes.BROADCAST_ONBOARDING_ERROR, data: data }),
    addBillingAddressForCheckout: (data: Addresses) => dispatch<IAddBillingAddress>({ type: CartActionTypes.ADD_BILLING_ADDRESS, data: data }),
    saveMailingAddressAction: (data: Addresses) => dispatch<IMailingAddress>({ type: OnboardingActionTypes.MAILING_ADDRESS, data: data }),
    saveBillingAddressAction: (data: Addresses) => dispatch<IBillingAddress>({ type: OnboardingActionTypes.BILLING_ADDRESS, data: data }),
    toggleBillingAddressAccordionAction: (data: boolean) => dispatch<IToggleBillingAddressAccordion>({ type: OnboardingActionTypes.TOGGLE_BILLING_ADDRESS, data: data }),
    toggleLicensingAccordionAction: () => dispatch<IToggleLicensingAccordion>({ type: OnboardingActionTypes.TOGGLE_LICENSING }),
    toggleEditorsAccordionAction: () => dispatch<IToggleEditorsInformationAccordion>({ type: OnboardingActionTypes.TOGGLE_EDITORS_INFORMATION }),
    toggleAboutYouAccordionAction: () => dispatch<IToggleAboutYouAccordion>({ type: OnboardingActionTypes.TOGGLE_ABOUT_YOU }),
    toggleMailingAddressAccordionAction: (data: boolean) => dispatch<IToggleMailingAddressAccordion>({ type: OnboardingActionTypes.TOGGLE_MAILING_ADDRESS, data: data }),
    setIsUseMailingAddressSelectedAction: (data: boolean) => dispatch<ISetIsUseMailingAddressChecked>({ type: OnboardingActionTypes.SET_IS_MAILING_ADDRESS_CHECKED, data: data })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddressComponent);
