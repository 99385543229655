import { User } from "../../redux/user/user.types";
import { Channel } from "../home-component/home.types";
import { ChannelContextRouteParams } from "../channels/channels.types";
import { makeStyles } from "@material-ui/core/styles";
import { LocalContent } from "../../redux/local-content/local-content.types";

const DRAWER_WIDTH = 240;
export interface channelSidebarProps {
  selectedChannel: Channel;
  selectedSubChannel: SubChannel;
  selectedThread: Thread;
  currentUser: User;
  routeParams: ChannelContextRouteParams;
  userLocationPermission: boolean;
  localContentData: LocalContent[];
  redirectToSubChannel: (channel: string, subchannel: string) => void;
  redirectToThread: (channel: string, subchannel: string, thread: string) => void;
  selectChannelAction: (channel: Channel) => void;
  selectSubChannelAction: (subChannel: SubChannel) => void;
  selectThreadAction: (thread: Thread) => void;
  removeThreadAction: () => void;
  toggleWrongPathPageAction: () => any;
}

export interface SubChannel {
  id: number;
  title: string;
}

export interface Thread {
  id: number;
  title: string;
  count?: number;
}

export const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '70%',
    margin:'0 auto'
  },
  svgIcon:{
    color: "#808080"
  }
}));