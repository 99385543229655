export function generateChannelColorArray(channelCode: string, variables: any) {
  const colorArray = [];

  colorArray[0] = variables[channelCode]
  colorArray[1] = variables[channelCode.concat('_lighter')];
  colorArray[2] = variables[channelCode.concat('_lightest')];
  colorArray[3] = variables[channelCode.concat('_follow')];

  return colorArray;
}

export function lowerFirstLetter(channel : string) {
  if(channel){
    return channel.charAt(0).toLowerCase() + channel.slice(1);
  }
}

export function isEmpty(obj : Object) {
  for(let key in obj) {
      if(obj.hasOwnProperty(key))
          return false;
  }
  return true;
}
