import { LocationFilterProps } from "../../redux/search-bar/search-bar.types";
import { Thread } from "../../components/channelsSidebar/channelSideBars.types";
import { Channel } from "../../components/home-component/home.types";
import { Subchannel } from "../../components/onboarding/onboarding-page.types";
import { IBroadcastMessage } from "components/batch-upload/ContentUpload.types";

export enum TableFilterActions {
    TOGGLE_TABLE_FILTER_CONTENT_TYPE = 'TOGGLE_TABLE_FILTER_CONTENT_TYPE',
    TOGGLE_TABLE_FILTER_CONTENT_STATUS = "TOGGLE_TABLE_FILTER_CONTENT_STATUS",
    TOGGLE_TABLE_FILTER_UPLOAD_DATE = 'TOGGLE_TABLE_FILTER_UPLOAD_DATE',
    TOGGLE_TABLE_FILTER_PUBLISH_DATE = 'TOGGLE_TABLE_FILTER_PUBLISH_DATE',
    TOGGLE_TABLE_FILTER_CHANNEL = 'TOGGLE_TABLE_FILTER_CHANNEL',
    TOGGLE_TABLE_FILTER_SUBCHANNEL = 'TOGGLE_TABLE_FILTER_SUBCHANNEL',
    TOGGLE_TABLE_FILTER_THREAD = 'TOGGLE_TABLE_FILTER_THREAD',
    TOGGLE_TABLE_FILTER_LOCATION = 'TOGGLE_TABLE_FILTER_LOCATION',
    BROADCAST_ERROR = "BROADCAST_ERROR",
    CLEAR_BROADCAST = "CLEAR_BROADCAST",
    RESET_TABLE_FILTERS = 'RESET_FILTERS'
}

export interface TableFilterState {
    contentStatus: string;
    contentType: string;
    uploadDate: Date[];
    publishDate: Date[];
    channelFilter: Channel;
    subchannelFilter: Subchannel;
    threadFilter: Thread;
    locationFilter: LocationFilterProps;
    fetchError: IBroadcastMessage;
}