import {SearchBarActions, SearchBarState} from './search-bar.types';
import {TSearchReducerActions} from './search-bar.actions';
import { ContentFromOptions, ContentTypesForSearch } from '../../components/search-bar/searchBar.types';
import { IBroadcastMessage } from '../../components/batch-upload/ContentUpload.types';

const InitialState: SearchBarState = {
    searchBarExpanded: false,
    searchString: "",
    totalResults: 0,
    totalResultsChannelContex: 0,
    userSearchResults: [],
    contentSearchResults: [],
    contentFrom: ContentFromOptions.PRO_CONTRIBUTORS,
    contentType: ContentTypesForSearch.ALL,
    dateFilter: [],
    colourFilter: '',
    locationFilter: {city: [], stateProvince: "", country: ""},
    locationNearMeFilter: {city: [], stateProvince: "", country: ""},
    channelFilter: {id: 0, title: "", code: ""},
    subchannelFilter: {id: 0, title: ""},
    threadFilter: {id: 0, title: ""},
    searchBroadcastMessage: {} as IBroadcastMessage,
    addToCartBroadcastMessage: {} as IBroadcastMessage,
    contentPerPage: 25
}

export const searchBarReducer = ( state = InitialState, action: TSearchReducerActions): SearchBarState => {
    switch (action.type) {
        case SearchBarActions.EXPAND_SEARCH_BAR: {
            return {
                ...state,
                searchBarExpanded: !state.searchBarExpanded
            }
        }
        case SearchBarActions.LOAD_TOTAL_RESULTS: {
            return {
                ...state,
                totalResults: action.data
            }
        }
        case SearchBarActions.LOAD_TOTAL_RESULTS_CHANNEL_CONTEX: {
            return {
                ...state,
                totalResultsChannelContex: action.data
            }
        }
        case SearchBarActions.LOAD_USER_SEARCH_RESULTS: {
            return {
                ...state,
                userSearchResults: action.data
            }
        }
        case SearchBarActions.LOAD_CONTENT_SEARCH_RESULTS: {
            return {
                ...state,
                contentSearchResults: action.data
            }
        }
        case SearchBarActions.TOGGLE_SEARCH_STRING: {
            return {
                ...state,
                searchString: action.data
            }
        }
        case SearchBarActions.TOGGLE_FILTER_CONTENT_FROM: {
            return {
                ...state,
                contentFrom: action.data
            }
        }
        case SearchBarActions.TOGGLE_FILTER_CONTENT_TYPE: {
            return {
                ...state,
                contentType: action.data
            }
        }
        case SearchBarActions.TOGGLE_FILTER_DATE: {
            return {
                ...state,
                dateFilter: action.data
            }
        }
        case SearchBarActions.TOGGLE_FILTER_LOCATION: {
            return {
                ...state,
                locationFilter: action.data
            }
        }
        case SearchBarActions.TOGGLE_FILTER_LOCATION_NEAR_ME: {
            return {
                ...state,
                locationNearMeFilter: action.data
            }
        }
        case SearchBarActions.TOGGLE_FILTER_COLOUR: {
            return {
                ...state,
                colourFilter: action.data
            }
        }
        case SearchBarActions.TOGGLE_FILTER_CHANNEL: {
            return {
                ...state,
                channelFilter: action.data
            }
        }

        case SearchBarActions.TOGGLE_FILTER_SUBCHANNEL: {
            return {
                ...state,
                subchannelFilter: action.data
            }
        }
        case SearchBarActions.TOGGLE_FILTER_THREAD: {
            return {
                ...state,
                threadFilter: action.data
            }
        }
        case SearchBarActions.CLEAR_BROADCAST:
            return {
              ...state,
              searchBroadcastMessage: {} as IBroadcastMessage,
            };
        case SearchBarActions.CLEAR_ADD_TO_CART_BROADCAST:
            return {
                ...state,
                addToCartBroadcastMessage: {} as IBroadcastMessage,
            };
        case SearchBarActions.RESET_FILTERS: {
            return {
                ...state,
                contentFrom: ContentFromOptions.PRO_CONTRIBUTORS,
                contentType: ContentTypesForSearch.ALL,
                dateFilter: [],
                colourFilter: '',
                locationFilter: {city: [], stateProvince: "", country: ""},
                locationNearMeFilter: {city: [], stateProvince: "", country: ""},
                channelFilter: {id: 0, title: "", code: ""},
                subchannelFilter: {id: 0, title: ""},
                threadFilter: {id: 0, title: ""},
            }
        }
        case SearchBarActions.RESET_FILTERS_CHANNELS: {
            return {
                ...state,
                contentFrom: ContentFromOptions.PRO_CONTRIBUTORS,
                contentType: ContentTypesForSearch.ALL,
                dateFilter: [],
                colourFilter: '',
                locationFilter: {city: [], stateProvince: "", country: ""},
                locationNearMeFilter: {city: [], stateProvince: "", country: ""},
            }
        }
        case SearchBarActions.RESET_SEARCH_RESULTS: {
            return {
                ...state,
                totalResults: 0,
                totalResultsChannelContex: 0,
                userSearchResults: [],
                contentSearchResults: []
            }
        }
        case SearchBarActions.RESET_DATE_FILTER: {
            return {
                ...state,
                dateFilter: []
            }
        }
        case SearchBarActions.RESET_LOCATIONS_FILTER: {
            return {
                ...state,
                locationFilter: {city: [], stateProvince: "", country: ""},
                locationNearMeFilter: {city: [], stateProvince: "", country: ""},
            }
        }
        case SearchBarActions.RESET_CONTENT_FROM_FILTER: {
            return {
                ...state,
                contentFrom: ContentFromOptions.PRO_CONTRIBUTORS
            }
        }
        case SearchBarActions.BROADCAST_SEARCH_ERROR: {
            return {
                ...state,
                searchBroadcastMessage: action.data
            }
        }
        case SearchBarActions.BROADCAST_ADD_TO_CART: {
            return {
                ...state,
                addToCartBroadcastMessage: action.data
            }
        }
        case SearchBarActions.TOGGLE_CONTENT_PER_PAGE: {
            return {
                ...state,
                contentPerPage: action.data
            }
        }
        default:
            return state;
    }
}