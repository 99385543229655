import { ErrorPagesTypes, PageState } from "./error-page.types";
import {TPageReducerActions} from './error-page.actions'
const InitialState: PageState = {
    toggleNoAccessPage: false,
    toggleNotLoggedPage: false,
    toggleWrongPathPage: false
}

export const pageReducer = (state = InitialState, action: TPageReducerActions) : PageState => {
    switch(action.type){
        case ErrorPagesTypes.ToggleNoAccessPage:
            return {
                toggleWrongPathPage: false,
                toggleNotLoggedPage: false,
                toggleNoAccessPage: true
            };
        case ErrorPagesTypes.ToggleNotLoggedPage:
            return {
                toggleWrongPathPage: false,
                toggleNotLoggedPage: true,
                toggleNoAccessPage: false
            };
        case ErrorPagesTypes.ToggleWrongPathPage:
            return {
                toggleWrongPathPage: true,
                toggleNotLoggedPage: false,
                toggleNoAccessPage: false
            };
        case ErrorPagesTypes.ResetTogglesPage:
            return InitialState;
        default:
            return state;
    }
}

export default pageReducer;