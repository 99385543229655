import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AddComment from "./photo-details-add-comment.component";
import { CommentType, CommentProps, CommentsBoxProps, UserAvatarProps } from "./photo-details.types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { Avatar, Stack } from "@mui/material";
import { GET_PROFILE_PICTURE } from "../navigation/queries";
import { useLazyQuery } from "@apollo/react-hooks";
import Linkify from 'react-linkify';

// Comment box
export const CommentsBox: React.FC<CommentsBoxProps> = ( { ...props } ) => {
  const { isLoggedIn, comments, contentId, userId, refetch, signIn, color } = props;
  const hasComments = comments.length > 0;
  const { t } = useTranslation();
  return (
    <div className="comment-box">
      <div className="comments-details">
        <h2 className="alling-left">{t( "Photo.Details.Container.Comments" )}({comments.length}):</h2>
      </div>
      {isLoggedIn ? (
        <AddComment
          contentId={contentId}
          userId={userId}
          refetch={refetch}
        />
      ) : (
        <div className="alling-left">
          <span className="sign-in" onClick={signIn}>
            {t( "Banner.Login.button" )}
          </span>{" "}
          {t( "Photo.Details.Container.To.Comment" )}
        </div>
      )}
      {hasComments ? (
        <div className="comments">
          {comments.map( ( comment: CommentType, index: number ) => (
            <CommentComponent commentItem={comment} key={index} />
          ) )}
        </div>
      ) : (
        <h3 className="alling-left">
          {t( "Photo.Details.Container.No.Comments.Yet" )}
        </h3>
      )}
     
    </div>
  );
};

export const CommentComponent: React.FC<CommentProps> = ( { ...props } ) => {
  const { commentItem } = props;

  const {comment: commentText, publishDate: dateTime} = commentItem.comment;
  const formattedDateTime = new Date(dateTime).toString();
  const [day, month, date, year, time] = formattedDateTime.split(" ");
  const [name, setName] = useState("");
  const [userPic, setUserPic] = useState("")
  const [fetchProfileImage, { data, loading, error }] = useLazyQuery(GET_PROFILE_PICTURE, { fetchPolicy: "no-cache" })

  useEffect(() => {
    if (commentItem && commentItem.user) {
      fetchProfileImage({ variables: { userId: commentItem.user.id } })
    }
  }, [commentItem, fetchProfileImage] );

  useEffect(() => {
    if (data && data.getUserProfilePicture) {
      setUserPic(data.getUserProfilePicture)
    }
  }, [data] );

  useEffect(() => {
    if (commentItem.user && commentItem.user.company) {
      setName(commentItem.user.company.name)
    }
    else if (commentItem.user && commentItem.user.person) {
      const userName = commentItem.user.person.firstName + " " + commentItem.user.person.lastName;
      setName(userName)
    }

  }, [commentItem])
  return (
    <div className="comment">
      <div className="name-and-tag">
        <UserAvatar profilePicture={userPic} />
        <div className="name-and-comment">
          <p className="name-pill">{name}</p>
          <p className="date-pill">{`${month} ${date}, ${year} at ${time.substring(0,5)}`}</p>
          <div className="comment-container">
            <Linkify  componentDecorator={(decoratedHref, decoratedText, key) => (
            <a target="blank" href={decoratedHref} key={key}>
                {decoratedText}
            </a>)}>{commentText}</Linkify>
          </div>
        </div>
      </div>

    </div>
  );
};

export const UserAvatar: React.FC<UserAvatarProps> = ({ ...props }) => {
  const { profilePicture, onClick } = props;

  return (
    <span onClick={onClick}>
      {profilePicture ? (
        <Avatar src={profilePicture} sx={{ width: 56, height: 56 }}></Avatar>
      ) : (
        <Avatar sx={{ width: 56, height: 56 }}>
          <FontAwesomeIcon size="5x" icon={faUser} />
        </Avatar>
      )}
    </span>

  )
}
