import { IBroadcastMessage } from "components/batch-upload/ContentUpload.types";
import { TileData } from "../../components/home-component/home.types";

export enum PhotoDetailsActionTypes {
    STORE_PHOTO_DETAILS = 'STORE_PHOTO_DETAILS',
    CHANGE_PHOTO_DETAILS = 'CHANGE_PHOTO_DETAILS',
    SELECT_PHOTO_FROM_SET = 'SELECT_PHOTO_FROM_SET',
    SET_CLICKED_IMAGE_TO_ADD_TO_CLICK_BOX = 'SET_CLICKED_IMAGE_TO_ADD_TO_CLICK_BOX',
    BROADCAST_MESSAGE = 'PHOTO_DETAILS_BROADCAST_MESSAGE',
    CLEAR_BROADCAST = 'CLEAR_BROADCAST',
}

export interface PhotoDetailsState {
    item: TileData;
    currentSelectedPhoto: number;
    clickedImageToAddToLightBox: number;
    broadcastMessage: IBroadcastMessage;
}