import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import TextField from '@material-ui/core/TextField';
import { withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { ChangePasswordProps,forgotPassword} from './changePassword.types';
import { push, CallHistoryMethodAction } from 'connected-react-router';
import { TUserReducerActions } from 'redux/user/user.actions';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { CHANGE_PASSWORD } from './queries';
import { StoreState } from 'redux/root-reducer';
import './changePassword.styles.scss';
import { useMutation } from '@apollo/react-hooks';
import {PASSWORD_REGEX} from 'shared';
import {useTranslation} from 'react-i18next'

const ChangePassword: React.FC<ChangePasswordProps> = ({...props}) => {

    const {route, redirectToLogin} = props;
    const [state, setResponseMessage] = useState({
        response:'',
    });
    const styles = forgotPassword();
    const {t} = useTranslation();
    const [changePasswordMutation] = useMutation(CHANGE_PASSWORD);
    const changePassword = (): void => {
        // event.preventDefault();
        const token = route.split('/').slice(-1)[0];
        const {password} = values;
        changePasswordMutation({variables: {token: token, password: password}
        }).then((result: any) => {
            //message to Home
            setResponseMessage({response: t("ChangePassword.Success")});
            redirectToLogin();
        }).catch((error: any) => {
            setResponseMessage({response: t("ChangePassword.Error")});
        });
    }
    

    const validationSchema = Yup.object ({
        password: Yup.string().required(t("Warning.Password.Required")).matches(
            PASSWORD_REGEX,
            t("Password.Regex")
          ),
          confirmPassword: Yup.string().when("password", {
            is: val => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf(
              [Yup.ref("password")],
              t("Confirm.Password.Message")
            )
          })
    })

    const {handleSubmit, handleChange, values, errors} = useFormik({
        initialValues: {
            password: "",
            confirmPassword: ""
        }, 
        validationSchema,
        onSubmit(values) {
            const {password, confirmPassword} = values;

            if(password === confirmPassword) {
                changePassword();
            }
            // mutation
        }
    })

    return (
        <div className='change-password'>
            <h2 className='title'>{t("ChangePassword.Label")}</h2>
            <form className='change-password-form' onSubmit={handleSubmit}>
                    <TextField 
                        classes = {{root: styles.formControlRoot}}
                        autoComplete='off' type='password' placeholder= {t("NewPassword.Placeholder")}
                        hiddenLabel={true} name='password' variant='standard' 
                        value={values.password} onChange={handleChange} error={errors.password === ""} 
                        helperText={errors.password ? errors.password : null} 
                        InputLabelProps={{shrink: false}}
                        FormHelperTextProps={{ style:{
                            color: 'red',
                            fontSize: '10px',
                            width:'200px'
                        } }}/>
                    <TextField  
                        classes = {{root: styles.formControlRoot}}
                        autoComplete='off' type='password' placeholder={t("ConfirmPassword.Placeholder")} 
                        hiddenLabel={true} name='confirmPassword' variant='standard' 
                        value={values.confirmPassword} onChange={handleChange} error={errors.confirmPassword === ""} 
                        helperText={errors.confirmPassword ? errors.confirmPassword : null} 
                        InputLabelProps={{shrink: false}}
                        FormHelperTextProps={{ style:{
                            color: 'red',
                            fontSize: '10px',
                            width:'200px'
                        } }}/>

                    <Button classes= {{root: styles.buttonRoot}} type='submit' className='submit-button' variant='contained'>{t("ChangePassword.Label")}</Button>
            </form>
        </div>
    );
}

const mapStateToProps = (state: StoreState) : {route: string} => {
    return {
        route: state.router.location.pathname
    }
}

const mapDispatchToProps = (dispatch: Dispatch<TUserReducerActions | CallHistoryMethodAction>) => {
    return {
        redirectToLogin: () => dispatch(push('/'))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);