import { StoreState } from "../root-reducer";
import { createSelector } from "reselect";

const selectPage = (state: StoreState) => state.errorPage;

export const selectNoAccessPage = createSelector(
    [selectPage],
    (errorPage) => errorPage.toggleNoAccessPage
)

export const selectWrongPathPage = createSelector(
    [selectPage],
    (errorPage) => errorPage.toggleWrongPathPage
)

export const selectNotLoggedPage = createSelector(
    [selectPage],
    (errorPage) => errorPage.toggleNotLoggedPage
)