import { createSelector } from 'reselect';
import { StoreState } from 'redux/root-reducer';

const selectUser = (state: StoreState) => state.user;

export const selectCurrentUser = createSelector(
    [selectUser],
    (user) => user.currentUser
);

export const selectCurrentUserId = createSelector(
    [selectUser],
    (user) => user.currentUser!.id!
);

export const selectCurrentUserSFTPAccess = createSelector(
    [selectUser],
    (user) => user.userHasSFTPAccess
)

export const selectRegisteredUser = createSelector(
    [selectUser],
    (user) => user.registeredUser
)

export const selectUserLocationPermission = createSelector(
    [selectUser],
    (user) => user.userLocation.gavePermission
)

export const selectUserCoordinates = createSelector(
    [selectUser],
    (user) => user.userLocation.userCoordinates
)

export const selectCurrentUserRole = createSelector(
    [selectUser],
    (user) => user.currentUser.role!
)

export const selectIsRegisterdUser = createSelector(
    [selectUser],
    (user) => user.registeredUser
)

export const selectHasUserCompletedOnboarding = createSelector(
    [selectUser],
    (user) => user.currentUser.hasCompletedOnboarding!
)

export const selectProfileImage = createSelector(
    [selectUser],
    (user) => user.profileImageUrl
)

export const selectToggleUserLocation = createSelector(
    [selectUser],
    (user) => user.toggleUserLocation
);

export const selectDataTableRefreshState = createSelector(
    [selectUser],
    (user) => user.dataTableRefreshState
);

export const selectUserCurrentLocationLoading = createSelector(
    [selectUser],
    (user) => user.userCurrentLocationLoading
);

export const selectLoadingSearchResults = createSelector(
    [selectUser],
    (user) => user.loadingSearchResults
);