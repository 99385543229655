import React from 'react';
import {useTranslation} from "react-i18next";
import './channel-button.styles.scss'
import '../channels/channelsNavigation.styles.scss'
import {Channel, ChannelButtonProps} from './home.types';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { push, CallHistoryMethodAction } from "connected-react-router";
import { compose } from 'lodash/fp';
import { removeSpaces, removeSpecialChars } from '../../components/navigation/helperFunctions';
import { ChannelActionTypes } from '../../redux/channel-routes/channel.types';
import { IAddChannel, TChannelReducerActions } from '../../redux/channel-routes/channel.actions';

const ChannelButton:React.FC<ChannelButtonProps> = ({...props}) =>
{
    const {channel, isInPic, redirectToChannel,selectChannelAction} = props;
    const handleOnclick = (channel: Channel) => {
        redirectToChannel(channel.title);
        selectChannelAction(channel);
    }
    return(
        <button
            onClick={() => handleOnclick(channel)}
            className={`channel-button ${channel.code} ${isInPic? "channelButtonPicture" : ""}`}
            key={channel.id}>{channel.title}</button>
    )
}
const mapDispatchToProps = (dispatch: Dispatch< CallHistoryMethodAction | TChannelReducerActions>) => {
    return {
        selectChannelAction: (channel: Channel) => dispatch<IAddChannel>({
            type: ChannelActionTypes.ADD_SELECTED_CHANNEL,
            data: channel
        }),
        redirectToChannel: (channel : string) => dispatch(push(`/channels/${compose(removeSpaces, removeSpecialChars)(channel)}`)),
    }
}

export default connect(null, mapDispatchToProps)(ChannelButton);