import { createSelector } from 'reselect';
import { StoreState } from 'redux/root-reducer';

const selectDownloads = (state: StoreState) => state.downloads;

export const selectDownloadingContents = createSelector(
    [selectDownloads],
    (downloads) => downloads.downloadingContents
);

export const selectDatePurchased = createSelector(
    [selectDownloads],
    (downloads) => downloads.datePurchased
);

export const selectedSize = createSelector(
    [selectDownloads],
    (downloads) => downloads.sizePurchased
);
