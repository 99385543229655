import React, { useEffect, useState } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import {
    Button,
    InputLabel,
    Select,
    MenuItem,
    Link,
    TextField,
    Chip,
    ImageListItem,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    AccordionActions,
    IconButton,
    CardActionArea,
    Card,
    CardMedia,
    ListItem,
    Breadcrumbs,
    FormControlLabel,
    makeStyles,
    Tooltip
} from '@material-ui/core';
import ImageList from '@material-ui/core/ImageList'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import {
    ContentMetadataProps,
    inputFormTheme,
    ImageMetadata,
    Copyright,
    FileMeta,
    Source,
    LocationMeta,
    MainInformation,
    SelectedChannelCategory,
    License,
    LicensingTypes,
    GroupsNames,
    ContentSource,
} from './EditContentMetadata.types';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { StoreState } from '../../redux/root-reducer';
import { Dispatch } from 'redux';
import {
    IAddChannelToContentSet,
    IAddChannelToIndividualImage,
    IBroadcastContentUploadMessage,
    IUpdateSet,
    TContentUploadActions
} from '../../redux/content-upload/content-upload.actions';
import { connect } from 'react-redux';
import { ContentSet, ContentSetCategory, IBroadcastMessage, IndividualImageCategory, RadioButtonGroup, SeveritySnackbarEnum, UploadedFileResponse } from './ContentUpload.types';
import {
    selectContentSets,
    selectIndividualImagesCategory,
    selectRadioButtonValue,
    selectUploadedFiles
} from '../../redux/content-upload/content-upload.selectors';
import { FETCH_METADATA } from './metadataQueries';
import {
    UPDATE_KEYWORDS,
    DELETE_KEYWORDS,
    UPDATE_LOCATION,
    UPDATE_COPYRIGHT,
    UPDATE_FILE_DETAILS,
    UPDATE_FILE_SOURCE,
    CONTRIBUTOR_AGENCY,
    UPDATE_LICENSE_TYPE,
    UPDATE_LICENSE_TYPES,
    UPDATE_KEYWORDSTOSET,
    UPDATE_MAIN_INFORMATION,
    UPDATE_MAIN_INFORMATION_SET,
    UPDATE_COPYRIGHTS,
    UPDATE_LOCATIONS
} from './queries';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import SelectChannelsComponent from './selectChannelsComponent';
import Moment from "react-moment";
import { ContentUploadActionTypes } from '../../redux/content-upload/content-upload.types';
import './EditContentMetadata.styles.scss';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SyncLoader from 'react-spinners/SyncLoader';
import "./EditContentMetadata.styles.scss";
import { useTranslation } from "react-i18next";
import Image from 'react-async-image';
import { EditableTextField } from 'shared';
import axios from 'axios';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { selectCurrentUser } from '../../redux/user/user.selectors';
import { User } from '../../redux/user/user.types';
import { LocationGroups, RapidApiCityDto, RapidApiCountryDto, RapidApiProvinceDto } from '../../globals/locations/locations.types';
import { doesCityExistInRapidApi, doesCountryExistInRapidApi, doesProvinceExistInRapidApi } from './helperFunctions';
import { SubChannel } from '../../components/channelsSidebar/channelSideBars.types';
import { config } from '../../config';
import { createFilterOptions, FilterOptionsState } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    coverPhoto: {
        backgroundColor: 'rgb(12, 175, 149) !important',
        paddingLeft: '16px !important',
        paddingRight: '16px !important'
    },
    gridListRoot: {
        maxHeight: '60vh'
    },
    gridListTileRoot: {
        height: '100% !important'
    }
}));

const EditContentMetadata: React.FC<ContentMetadataProps> = ({ ...props }) => {
    const classes = useStyles();
    const {
        currentUser, contentSetsState, editSetFromAdmin, selectedRadioButton, uploadedFiles, channels, individualImageCategories,
        addIndividualImageNavigation, updateSetAction, addChannelToContent, handleRejectImage, handleApproveImage, broadcastUploadErrorAction
    } = props;
    
    const [selectedImage, setSelectedImage] = useState<Partial<UploadedFileResponse>>({});

    const [contentSetTitle, setContentSetTitle] = useState("");
    const [contentSetIndex, setContentSetIndex] = useState<number>(0);
    const [editMode, setEditMode] = useState({
        category: true,
        mainInfo: true,
        location: true,
        keywords: true,
        copyright: true,
        licensing: true,
    });
    const [addCategoryIndex, setAddCategoryIndex] = useState(1);
    const [imageMetadata, setImageMetadata] = useState<Partial<ImageMetadata>>({});
    const [keywordState, setKeywordState] = useState('');

    const [loadingIndicator, setLoadingIndicator] = useState(false);

    //State properties for locations
    const [countries, setCountries] = useState<RapidApiCountryDto[]>([]);
    const [countryCode, setCountryCode] = useState<string>("");
    const [selectedCountry, setSelectedCountry] = useState<RapidApiCountryDto>({
        country: "",
        countryCode: ''
    });

    const [provinceIsoCode, setProvinceIsoCode] = useState<string>("");
    const [provinces, setProvinces] = useState<RapidApiProvinceDto[]>([]);

    const [selectedProvince, setSelectedProvince] = useState<RapidApiProvinceDto>({
        province: '',
        isoCode: '',
        countryId: ''
    });

    const [cities, setCities] = useState<RapidApiCityDto[]>([]);
    const [selectedCity, setSelectedCity] = useState<RapidApiCityDto>({
        city: '',
        longitude: 0,
        latitude: 0
    })

    // State properties for Image Category dropdown
    const [selectedChannels, setSelectedChannels] = useState<any[]>([]);
    const [selectedSubchannels, setSelectedSubchannels] = useState<any[]>([]);
    const [selectedThreads, setSelectedThreads] = useState<any[]>([]);
    const [selectedDropdownData, setSelectedDropdownData] = useState({
        channel: { selectedId: 0, selectedChannelTitle: "" },
        subchannel: { selectedId: 0, selectedChannelTitle: "" },
        thread: { selectedId: 0, selectedChannelTitle: "" },
    });
    const [suggestedThreadSubchannel, setSuggestedThreadSubchannel] = useState<SubChannel>({ id: 0, title: '' });
    const [suggestThreadTitle, setSuggestThreadTitle] = useState('');
    const selectedDropdownThread = selectedDropdownData.thread.selectedId;
    //autocomplete state hooks for options
    const [contributorOptions, setContributorOptions] = useState<User[]>([]);

    const [fetchMetadata, { data }] = useLazyQuery(FETCH_METADATA, { fetchPolicy: 'no-cache' });
    const [fetchAgencies, { data: agencyData }] = useLazyQuery(CONTRIBUTOR_AGENCY, { fetchPolicy: 'cache-and-network' });

    useEffect(() => {
        if (selectedRadioButton === RadioButtonGroup.SET && contentSetsState && contentSetsState[contentSetIndex]) {
            const setFirstItem = getContentSetFirstItem();
            if (setFirstItem) {
                setSelectedImage(setFirstItem!);
                fetchMetadata({ variables: { 
                    contentId: setFirstItem!.id, 
                    threadIds: contentSetsState![contentSetIndex].selectedThreadIds ? contentSetsState![contentSetIndex].selectedThreadIds : [0], 
                    suggestThreadSubchannelId: suggestedThreadSubchannel.id,
                    suggestThreadTitle: suggestThreadTitle} });
            } else {
                broadcastUploadErrorAction({
                    severity: SeveritySnackbarEnum.error,
                    message: "Failed to fetch set contents."
                });
            }
        }
        if (selectedRadioButton === RadioButtonGroup.INDIVIDUAL && uploadedFiles && uploadedFiles.length > 0) {
            setSelectedImage(uploadedFiles[0]);
            const categories = individualImageCategories.find((category: any) => category.contentId === selectedImage.id);
            fetchMetadata({ variables: { 
                contentId: uploadedFiles[0].id, 
                threadIds: categories?.selectedThreads ? categories.selectedThreads : 0, 
                selectedChannelTitlesuggestThreadSubchannelId: suggestedThreadSubchannel.id,
                suggestThreadTitle: suggestThreadTitle} });
        }
    }, [contentSetsState, uploadedFiles]);

    const [updateKeywordsMutation] = useMutation(UPDATE_KEYWORDS);
    const updateKeywords = (contentId: number, keywords: string[]): void => {
        updateKeywordsMutation({
            variables: { contentId: contentId, keywordsToBeAdded: keywords }
        }).then((result: any) => {
            setImageMetadata({
                ...imageMetadata,
                keywords: {
                    keywordsToShow: imageMetadata.keywords?.keywordsToShow!,
                    keywordsToAdd: [],
                    keywordsToDelete: imageMetadata.keywords?.keywordsToDelete
                }
            });
            broadcastUploadErrorAction({
                severity: SeveritySnackbarEnum.success,
                message: t("Success.Keywords.Update")
            });
        }).catch((error: any) => {
            broadcastUploadErrorAction({
                severity: SeveritySnackbarEnum.error,
                message: t("Error.Keywords.Update")
            });
        })
    }

    const [deleteKeywordsMutation] = useMutation(DELETE_KEYWORDS);
    const deleteKeywords = (contentId: number, keywordsToDelete: string[]) => {
        deleteKeywordsMutation({
            variables: { contentId: contentId, keywordsToBeDeleted: keywordsToDelete }
        }).then((result: any) => {
            setImageMetadata({
                ...imageMetadata,
                keywords: {
                    keywordsToShow: imageMetadata.keywords?.keywordsToShow!,
                    keywordsToAdd: imageMetadata.keywords?.keywordsToAdd,
                    keywordsToDelete: []
                }
            });
            broadcastUploadErrorAction({
                severity: SeveritySnackbarEnum.success,
                message: "Successfully deleted keywords"
            });
        }).catch((error: any) => {
            broadcastUploadErrorAction({
                severity: SeveritySnackbarEnum.error,
                message: "Error while deleting keywords"
            });
        })
    }

    const [updateKeywordsToSetMutation] = useMutation(UPDATE_KEYWORDSTOSET);
    const updateKeywordsToSet = (contentIds: number[], keywordsToAdd: string[], keywordsToDelete: string[]): void => {
        updateKeywordsToSetMutation({
            variables: { contentIds: contentIds, keywordsToBeAdded: keywordsToAdd, keywordsToBeDeleted: keywordsToDelete }
        }).then((result: any) => {
            broadcastUploadErrorAction({
                severity: SeveritySnackbarEnum.success,
                message: t("ApplyToSet.Success")
            });
        }).catch((error: any) => {
            broadcastUploadErrorAction({
                severity: SeveritySnackbarEnum.error,
                message: t("ApplyToSet.Error")
            });
        })
    }

    const [updateLocationsMutation] = useMutation(UPDATE_LOCATIONS);
    const updateLocations = (contentIds: number[], input: LocationMeta): void => {
        updateLocationsMutation({
            variables: {
                contentIds: contentIds,
                city: input.city,
                stateProvince: input.stateProvince,
                country: input.country,
                countryCode: input.countryCode,
                stateCode: input.stateCode,
                latitude: input.latitude,
                longitude: input.longitude,
            }
        })
            .then((result: any) => {
                broadcastUploadErrorAction({
                    severity: SeveritySnackbarEnum.success,
                    message: t("ApplyToSet.Success")
                })
            })
            .catch((error: any) => {
                broadcastUploadErrorAction({
                    severity: SeveritySnackbarEnum.error,
                    message: t("ApplyToSet.Error")
                });
            });
    }

    const [updateCopyrightMutation] = useMutation(UPDATE_COPYRIGHT);
    const updateCopyright = (contentId: number, input: Copyright): void => {
        updateCopyrightMutation({
            variables: {
                contentId: contentId,
                byLine: input.byLine,
                credit: input.credit,
                copyrightNotice: input.copyrightNotice,
                specialInstructions: input.specialInstructions,
                sourceUserId: input.contentSource?.userId
            },
        })
            .then((result: any) => {
                broadcastUploadErrorAction({
                    severity: SeveritySnackbarEnum.success,
                    message: t("Success.Copyright.Update")
                })
            })
            .catch((error: any) => {
                broadcastUploadErrorAction({
                    severity: SeveritySnackbarEnum.error,
                    message: t("Error.Copyright.Update")
                });
            });
    };

    const [updateCopyrightsMutation] = useMutation(UPDATE_COPYRIGHTS);
    const updateCopyrights = (contentIds: number[], input: Copyright): void => {
        updateCopyrightsMutation({
            variables: {
                contentIds: contentIds,
                byLine: input.byLine,
                credit: input.credit,
                copyrightNotice: input.copyrightNotice,
                specialInstructions: input.specialInstructions,
                sourceUserId: input.contentSource?.userId
            }
        }).then((result: any) => {
            broadcastUploadErrorAction({
                severity: SeveritySnackbarEnum.success,
                message: t("ApplyToSet.Success")
            });
        })
            .catch((error: any) => {
                broadcastUploadErrorAction({
                    severity: SeveritySnackbarEnum.error,
                    message: t("ApplyToSet.Error")
                });
            })
    }

    const [updateFileDetailsMutation] = useMutation(UPDATE_FILE_DETAILS);
    const updateFileDetails = (contentId: number, input: FileMeta): void => {
        updateFileDetailsMutation({
            variables: {
                contentId: contentId,
                fileName: input.fileName,
                fileSize: input.fileSize,
                fileType: input.fileType,
                imageWidth: input.imageWidth,
                imageHeight: input.imageHeight,
                cameraModel: input.cameraModel,
            },
        })
            .then((result: any) => {
                broadcastUploadErrorAction({
                    severity: SeveritySnackbarEnum.success,
                    message: t("Success.FileDetails.Update")
                });
            })
            .catch((error: any) => {
                broadcastUploadErrorAction({
                    severity: SeveritySnackbarEnum.error,
                    message: t("Error.FileDetails.Update")
                });
            });
    };

    const [updateLicensingTypesMutation] = useMutation(UPDATE_LICENSE_TYPES);
    const updateLicensingTypes = (contentIds: number[], input: License): void => {
        updateLicensingTypesMutation({
            variables: {
                contentIdArray: contentIds,
                licensingType: input.type
            }
        })
            .then((result: any) => {
                broadcastUploadErrorAction({
                    severity: SeveritySnackbarEnum.success,
                    message: t("ApplyToSet.Success")
                });
            })
            .catch((error: any) => {
                broadcastUploadErrorAction({
                    severity: SeveritySnackbarEnum.error,
                    message: t("ApplyToSet.Error")
                });
            })
    }

    const [updateFileSourceMutation] = useMutation(UPDATE_FILE_SOURCE);
    const updateFileSource = (contentId: number, input: Source): void => {
        updateFileSourceMutation({
            variables: {
                contentId: contentId,
                source: input.source,
                createdDate: input.createdDate,
                createdTime: input.createdTime,
                uploadedDate: input.uploadedDate,
                originalFileName: input.originalFileName,
            },
        })
            .then((result: any) => {
                broadcastUploadErrorAction({
                    severity: SeveritySnackbarEnum.success,
                    message: t("Success.FileSources.Update")
                })
            })
            .catch((error: any) => {
                broadcastUploadErrorAction({
                    severity: SeveritySnackbarEnum.error,
                    message: t("Error.FileSources.Update")
                });
            });
    };

    const [updateLocationMutation] = useMutation(UPDATE_LOCATION);
    const updateLocation = (contentId: number, input: LocationMeta): void => {
        updateLocationMutation({
            variables: {
                contentId: contentId,
                city: input.city,
                stateProvince: input.stateProvince,
                country: input.country,
                countryCode: input.countryCode,
                stateCode: input.stateCode,
                latitude: input.latitude,
                longitude: input.longitude,
            },
        })
            .then((result: any) => {
                broadcastUploadErrorAction({
                    severity: SeveritySnackbarEnum.success,
                    message: t("Success.Locations.Update")
                })
            })
            .catch((error: any) => {
                broadcastUploadErrorAction({
                    severity: SeveritySnackbarEnum.error,
                    message: t("Error.Locations.Update")
                });
            });
    };

    const [updateLicenseTypeMutation] = useMutation(UPDATE_LICENSE_TYPE);
    const updateLicenseType = (contentId: number, input: License): void => {
        updateLicenseTypeMutation({
            variables: {
                id: contentId,
                licensingType: input.type
            },
        }).then((result: any) => {
            broadcastUploadErrorAction({
                severity: SeveritySnackbarEnum.success,
                message: t("Success.LicenseType.Update")
            });
        }).catch((error: any) => {
            broadcastUploadErrorAction({
                severity: SeveritySnackbarEnum.error,
                message: t("Error.LicenseType.Update")
            });
        })
    }

    const [updateMainInfromationMutation] = useMutation(UPDATE_MAIN_INFORMATION);
    const updateMainInfromation = (contentId: number, input: MainInformation): void => {
        updateMainInfromationMutation({
            variables: {
                contentId: contentId,
                objectName: input.objectName,
                headline: input.headline,
                caption: input.caption,
                externalLink: input.externalLink,
                externalLinkMessage: input.externalLinkMessage
            }
        }).then((result: any) => {
            broadcastUploadErrorAction({
                severity: SeveritySnackbarEnum.success,
                message: t("Success.MainInfo.Update")
            })
        }).catch((error: any) => {
            broadcastUploadErrorAction({
                severity: SeveritySnackbarEnum.error,
                message: t("Error.MainInfo.Update")
            })
        })
    };

    const [updateMainInformationToSetMutation] = useMutation(UPDATE_MAIN_INFORMATION_SET);
    const updateMainInfromationToSet = (contentIds: number[], input: MainInformation): void => {
        updateMainInformationToSetMutation({
            variables: {
                contentIds: contentIds,
                objectName: input.objectName,
                headline: input.headline,
                caption: input.caption,
                externalLink: input.externalLink,
                externalLinkMessage: input.externalLinkMessage
            }
        }).then((result: any) => {
            broadcastUploadErrorAction({
                severity: SeveritySnackbarEnum.success,
                message: t("ApplyToSet.Success")
            })
        }).catch((error: any) => {
            broadcastUploadErrorAction({
                severity: SeveritySnackbarEnum.error,
                message: t("ApplyToSet.Error")
            })
        })
    }

    useEffect(() => {
        if (data && data.fetchCopyright) {
            let location = data.fetchLocation;
            let copyright = data.fetchCopyright;
            setImageMetadata({
                mainInformation: data.getContentById,
                location: {
                    city: location && location.city ? location.city.city : '',
                    country: location && location.country ? location.country.country : '',
                    countryCode: location && location.country ? location.country.countryCode : '',
                    stateProvince: location && location.province ? location.province.stateProvince : '',
                    stateCode: location && location.province ? location.province.stateCode : '',
                    longitude: location && location.locationCoordinate ? location.locationCoordinate.longitude : 0,
                    latitude: location && location.locationCoordinate ? location.locationCoordinate.latitude : 0,
                    zipCode: location && location.zipCode ? location.zipCode.zipCodeNumber : ''
                },
                keywords: {
                    keywordsToShow: data.fetchKeywords,
                    keywordsToAdd: [],
                    keywordsToDelete: []
                },
                copyright: {
                    byLine: copyright.byLine,
                    credit: copyright.credit,
                    copyrightNotice: copyright.copyrightNotice,
                    specialInstructions: copyright.specialInstructions,
                    contentSource: {
                        userId: copyright.sourceUser ? copyright.sourceUserId : -1,
                        companyName: copyright.sourceUser ? copyright.sourceUser.company?.name : ""
                    }
                },
                source: data.fetchFileSources,
                fileDetails: data.fetchFileDetails,
                license: {
                    type: data.fetchLicensingType
                }
            });

            let categoryResult = data.getFullContentNavigation;
            if (categoryResult.length > 0) {
                let channelObjects: SelectedChannelCategory[] = categoryResult.map((category: any) => ({
                    selectedId: category.channel.id,
                    selectedChannelTitle: category.channel.title
                }));
                let subchannelObjects: SelectedChannelCategory[] = categoryResult.map((category: any) => ({
                    selectedId: category.subChannel.id,
                    selectedChannelTitle: category.subChannel.title
                }));
                let threadObjects: SelectedChannelCategory[] = categoryResult.map((category: any) => ({
                    selectedId: category.thread.id,
                    selectedChannelTitle: category.thread.title
                }));

                setSelectedChannels(channelObjects);
                setSelectedSubchannels(subchannelObjects);
                setSelectedThreads(threadObjects);
                if (selectedRadioButton === RadioButtonGroup.SET) {
                    setAddCategoryIndex(contentSetsState![contentSetIndex!].selectedThreadIds! ? contentSetsState![contentSetIndex!].selectedThreadIds!.length : 1)
                } else {
                    const hasCategories = individualImageCategories.find(category => category.contentId === selectedImage.id!);
                    setAddCategoryIndex(hasCategories && hasCategories.selectedThreads ? selectedThreads.length + 1 : 1);
                }
            } else {
                resetThreadCategoryState();
            }
            setLoadingIndicator(false);
        }
    }, [data]);

    useEffect(() => {
        setAddCategoryIndex(selectedThreads.length + 1);
    }, [selectedThreads]);

    useEffect(() => {
        fetchCountries();
    }, []);

    const fetchCountries = () => {
        axios({
            method: 'GET',
            url: `${config.REACT_APP_PUBLIC_SERVER_HOST}/countries`,
        })
            .then((res: any) => setCountries(res.data))
            .catch((error: any) => {
                // console.log(error)
            })
    }

    const fetchProvinces = (countryCode: string) => {
        axios({
            method: 'GET',
            url: `${config.REACT_APP_PUBLIC_SERVER_HOST}/provinces/${countryCode}`,
        })
            .then((res: any) => {
                setProvinces(res.data);
            })
            .catch((error: any) => {
                // console.log(error)
            });
    }

    const fetchCities = (countryCode: string, isoCode: string) => {
        axios({
            method: 'GET',
            url: `${config.REACT_APP_PUBLIC_SERVER_HOST}/cities/${countryCode}/${isoCode}`,
        })
            .then((res: any) => setCities(res.data))
            .catch((error: any) => {
                // console.log(error)
            })
    }

    useEffect(() => {
        if (editMode.copyright && contributorOptions.length === 0) {
            fetchAgencies();
        }
    }, [editMode.copyright]);

    useEffect(() => {
        if (agencyData && agencyData.getContributorAgencies) {
            setContributorOptions(agencyData.getContributorAgencies);
        }
    }, [agencyData]);

    const getContentSetFirstItem = () => {
        if (contentSetsState && contentSetsState[contentSetIndex].files.length > 0) {
            return contentSetsState[contentSetIndex].files[0];
        }
    }

    const handleChange = (event: any, valueCorrectType: any, group: string) => {
        event.preventDefault();

        const name = event.target.name;

        if (group === GroupsNames.KEYWORDS) {
            setKeywordState(valueCorrectType);
        } else if (group === GroupsNames.COPYRIGHT) {
            setImageMetadata({
                ...imageMetadata,
                copyright: { ...imageMetadata.copyright!, [name]: valueCorrectType },
            });
        } else if (group === GroupsNames.FILE_DETAILS) {
            setImageMetadata({
                ...imageMetadata,
                fileDetails: {
                    ...imageMetadata.fileDetails!,
                    [name]: valueCorrectType,
                },
            });
        } else if (group === GroupsNames.SOURCE) {
            setImageMetadata({
                ...imageMetadata,
                source: { ...imageMetadata.source!, [name]: valueCorrectType },
            });
        } else if (group === GroupsNames.MAIN_INFO) {
            setImageMetadata({
                ...imageMetadata,
                mainInformation: {
                    ...imageMetadata.mainInformation!,
                    [name]: valueCorrectType,
                },
            });
        } else if (group === GroupsNames.LOCATION) {
            setImageMetadata({
                ...imageMetadata,
                location: { ...imageMetadata.location!, [name]: valueCorrectType },
            });
        } else if (group === GroupsNames.LICENSING) {
            setImageMetadata({
                ...imageMetadata,
                license: { ...imageMetadata.license!, [name]: valueCorrectType },
            });
        } else if (group === GroupsNames.SET_TITLE) {
            setContentSetTitle(valueCorrectType);
        }
    };

    const handleDelete = (chipToDelete: string) => {

        const remainingKeywords = imageMetadata.keywords?.keywordsToShow!.filter((chip: any) => {
            return chip.name !== chipToDelete
        });

        setImageMetadata({
            ...imageMetadata,
            keywords: {
                keywordsToShow: remainingKeywords!,
                keywordsToAdd: imageMetadata.keywords?.keywordsToAdd,
                keywordsToDelete: [...imageMetadata.keywords?.keywordsToDelete!, chipToDelete]
            }
        });
    };
    const checkIfKeywordIsAlreadyIncluded = (keywordName:string) => {
        if(imageMetadata.keywords && imageMetadata.keywords.keywordsToShow.length > 0){
            const checkNames = imageMetadata.keywords.keywordsToShow.filter( keyword => {return keyword.name === keywordName.trimEnd().trimStart()})
            if( checkNames.length > 0 ) {
                return true
            }
            else {
                return false
            }
        }
        else {
            return false
        } 
    }

    const addKeyword = () => {
        
        let chip = [{ name: "", __typename: "Keyword" }];
        let chipName = [];

        if(keywordState.includes(",") || keywordState.includes(";")){
        chipName = keywordState.split(/,|;/).map(keyword => keyword.trim()).filter(keyword => keyword.trim().length > 0);
        chip = chipName.map(chip => ({ name: chip, __typename: "Keyword" }))
        }
        else{
        chip = [{ name: keywordState, __typename: "Keyword" }];
        chipName = [chip[0].name];
        }

        if (imageMetadata.keywords && imageMetadata.keywords.keywordsToShow) {
        let identicalKeywords = false;
        const existingKeywords = [];
        const newChip = [];
        const newChipName = [];
        for(let i=0;i<chipName.length;++i){
            if(checkIfKeywordIsAlreadyIncluded(chipName[i]) ||
            imageMetadata.keywords.keywordsToAdd?.includes(chipName[i])){
            existingKeywords.push(chipName[i]);
            identicalKeywords = true;
            }
            else{
            newChipName.push(chipName[i]);
            newChip.push({ name: chipName[i], __typename: "Keyword" });
            }
        }

        if (!identicalKeywords) {
            setImageMetadata({
            ...imageMetadata,
            keywords: {
                keywordsToShow: [...imageMetadata.keywords.keywordsToShow!, ...chip],
                keywordsToAdd: [...imageMetadata.keywords.keywordsToAdd!, ...chipName],
                keywordsToDelete: imageMetadata.keywords?.keywordsToDelete,
            },
            });
        } 
        else if(chipName.length > 1){
            setImageMetadata({
            ...imageMetadata,
            keywords: {
                keywordsToShow: [...imageMetadata.keywords.keywordsToShow!, ...newChip],
                keywordsToAdd: [...imageMetadata.keywords.keywordsToAdd!, ...newChipName],
                keywordsToDelete: imageMetadata.keywords?.keywordsToDelete,
            },
            });
            broadcastUploadErrorAction({
            severity: SeveritySnackbarEnum.warning,
            message: `Keyword ${existingKeywords} already exists`,
            });
        }
        else {
            setImageMetadata({
            ...imageMetadata,
            keywords: {
                keywordsToShow: [...imageMetadata.keywords.keywordsToShow!],
                keywordsToAdd: [...imageMetadata.keywords.keywordsToAdd!],
                keywordsToDelete: imageMetadata.keywords?.keywordsToDelete,
            },
            });
            broadcastUploadErrorAction({
            severity: SeveritySnackbarEnum.warning,
            message: `Keyword ${existingKeywords} already exists`,
            });
        }
        } else {
        setImageMetadata({
            ...imageMetadata,
            keywords: {
            keywordsToShow: [...chipName],
            keywordsToAdd: [...chipName],
            keywordsToDelete: imageMetadata.keywords?.keywordsToDelete,
            },
        });
        }

        setKeywordState("");
    };

    const handleEditModeChange = (value: string) => {
        setEditMode({ ...editMode, [value]: false });
    };

    const changeSetTitle = (set: ContentSet, value: string) => {
        if (value.length <= 1 || value.length >= 500) {
            broadcastUploadErrorAction({
                severity: SeveritySnackbarEnum.warning,
                message: t("ContentSet.Name.Validation")
            });
            return;
        }

        const updatedSet: ContentSet = {
            id: set.id,
            title: value,
            files: set.files,
            coverPhoto: set.coverPhoto!,
            selectedThreadIds: set.selectedThreadIds!
        };

        updateSetAction(updatedSet);
        setContentSetTitle("");
    };

    useEffect(() => {
        if (selectedDropdownThread === -1) {
            setSuggestedThreadSubchannel({ id: selectedDropdownData.subchannel.selectedId, title: selectedDropdownData.subchannel.selectedChannelTitle });
            setSuggestThreadTitle(selectedDropdownData.thread.selectedChannelTitle);
        }

    }, [selectedDropdownData]);

    const assignThreadsToContentInRedux = () => {
        const selectedDropdownThreadId = selectedDropdownThread;

        if((selectedDropdownData.subchannel.selectedId === 0 || selectedDropdownData.channel.selectedId === 0)  && selectedDropdownThreadId !== 0) {
            broadcastUploadErrorAction({
                severity: SeveritySnackbarEnum.error,
                message: t("Error.Dropdown.Menu.Subchannel.Channel")
            });
            return;
        }

        if (selectedThreads.filter(thread => thread.selectedId === selectedDropdownThreadId).length > 0) {
            //if thread is already selected
            broadcastUploadErrorAction({
                severity: SeveritySnackbarEnum.error,
                message: t("Error.Thread.Already.Selected")
            });
            return;
        } else if (addCategoryIndex <= 3 && selectedThreads.length === addCategoryIndex - 1 && selectedDropdownThreadId !== 0) {
            //normal flow when we assign to thread
            assignToSelectedChannelArray();
            broadcastUploadErrorAction({
                severity: SeveritySnackbarEnum.success,
                message: t("Success.Content.Update")
            });
        } else if (selectedDropdownData.channel.selectedId !== 0
            && (selectedDropdownData.subchannel.selectedId === 0 || selectedDropdownThreadId === 0)) {
            //if dropdown menu is not filled properly
            broadcastUploadErrorAction({
                severity: SeveritySnackbarEnum.error,
                message: t("Error.Dropdown.Menu")
            });
            return;
        }

        const threadIds = selectedThreads.map(thread => {
            return thread.selectedId
        })

        if (selectedRadioButton === RadioButtonGroup.SET) {
            const selectedContentSetId = contentSetsState![contentSetIndex].id;
            let selectedThreads = selectedDropdownThreadId === 0 ? threadIds : threadIds.concat(selectedDropdownThread);

            const setCategory: ContentSetCategory = {
                selectedThreads: selectedThreads,
                selectedContentSetId: selectedContentSetId,
                subchannel: suggestedThreadSubchannel,
                suggestedThreadTitle: suggestThreadTitle.length > 0 ? suggestThreadTitle : ''
            }
            addChannelToContent(setCategory);
            resetDropdownData();

        } else if (selectedRadioButton === RadioButtonGroup.INDIVIDUAL) {
            const imageCategory: IndividualImageCategory = {
                selectedThreads: selectedDropdownThreadId === 0 ? threadIds : threadIds.concat(selectedDropdownThread),
                contentId: selectedImage.id!,
                subchannel: suggestedThreadSubchannel,
                suggestedThreadTitle: suggestThreadTitle.length > 0 ? suggestThreadTitle : ''
            };
            addIndividualImageNavigation(imageCategory);
            resetDropdownData();
        }
    }

    const addAnotherCategory = () => {
        if (selectedThreads.filter(thread => thread.selectedId === selectedDropdownThread).length > 0 && selectedDropdownThread !== -1) {
            //if thread is already selected
            broadcastUploadErrorAction({
                severity: SeveritySnackbarEnum.error,
                message: t("Error.Thread.Already.Selected")
            });
            return;
        } else if (selectedThreads.filter(thread => thread.selectedId === selectedDropdownThread).length > 0 && selectedDropdownThread === -1) {
            broadcastUploadErrorAction({
                severity: SeveritySnackbarEnum.error,
                message: t("Error.Suggest.Thread.Already.Selected")
            });
            return;
        } else if (addCategoryIndex <= 3 && selectedThreads.length === addCategoryIndex - 1 && selectedDropdownThread !== 0) {
            //normal flow when we assign to thread
            assignToSelectedChannelArray();
            resetDropdownData();
        } else if (addCategoryIndex > 3) {
            //when limit of 3 breadcrumbs is reached
            broadcastUploadErrorAction({
                severity: SeveritySnackbarEnum.warning,
                message: t("Breadcrumbs.Limit")
            });
            return;
        }
    }

    const SaveChanges = (value: string) => {
        setEditMode({ ...editMode, [value]: true });
        if (selectedImage.id) {
            switch (value) {
                case GroupsNames.CATEGORY:
                    assignThreadsToContentInRedux();
                    break;
                case GroupsNames.MAIN_INFO:
                    let mainInfoMeta = imageMetadata.mainInformation;
                    if (mainInfoMeta) {
                        updateMainInfromation(selectedImage.id!, mainInfoMeta);
                    }
                    break;
                case GroupsNames.LOCATION:
                    let locationMeta = imageMetadata.location;
                    if (locationMeta) {
                        updateLocation(selectedImage.id!, locationMeta);
                    }
                    break;
                case GroupsNames.KEYWORDS:
                    let keywordMeta = imageMetadata.keywords;
                    const contentId = selectedImage.id!;
                    const keywordsToAdd = keywordMeta?.keywordsToAdd!;
                    const keywordsToDelete = keywordMeta?.keywordsToDelete!;
                    if (keywordMeta) {
                        if (keywordsToAdd && keywordsToAdd.length > 0) {
                            updateKeywords(contentId, keywordsToAdd);
                        }
                        if (keywordsToDelete && keywordsToDelete.length > 0) {
                            deleteKeywords(contentId, keywordsToDelete);
                        }
                    }
                    break;
                case GroupsNames.COPYRIGHT:
                    let copyrightMeta = imageMetadata.copyright;
                    if (copyrightMeta) {
                        updateCopyright(selectedImage.id!, copyrightMeta);
                    }
                    break;
                case GroupsNames.SOURCE:
                    let fileSourceMeta = imageMetadata.source;
                    if (fileSourceMeta) {
                        updateFileSource(selectedImage.id!, fileSourceMeta);
                    }
                    //remove
                    break;
                case GroupsNames.FILE_DETAILS:
                    let fileDetailsMeta = imageMetadata.fileDetails;
                    if (fileDetailsMeta) {
                        updateFileDetails(selectedImage.id!, fileDetailsMeta);
                    }
                    //remove
                    break;
                case GroupsNames.LICENSING:
                    let licenseMeta = imageMetadata.license;
                    if (licenseMeta) {
                        updateLicenseType(selectedImage.id!, licenseMeta);
                    }
                    break;
                default:
                    return null;
            }
        } else {
            broadcastUploadErrorAction({
                severity: SeveritySnackbarEnum.warning,
                message: t("Warning.Image.Selection")
            });
        }
    }

    const handleSelectChannel = (
        selectedId: number,
        selectedChannelTitle: string,
        category: any
    ) => {
        setSelectedDropdownData({
            ...selectedDropdownData,
            [category]: { selectedId, selectedChannelTitle },
        });
        if(category === "channel") {
            setSelectedDropdownData({
                ...selectedDropdownData,
                [category]: { selectedId, selectedChannelTitle },
                subchannel: {selectedId: 0, selectedChannelTitle: ""},
                thread: {selectedId: 0, selectedChannelTitle:""}
            });
        }
    };

    const handleImageClick = async (file: UploadedFileResponse) => {
        if (selectedImage.id === file.id) {
            setSelectedImage(file);
            //reset metadata
        } else {
            setLoadingIndicator(true);
            setSelectedImage(file);
            if (selectedRadioButton === RadioButtonGroup.INDIVIDUAL) {
                resetDropdownData();

                let hasCategories = individualImageCategories.find(category => category.contentId === file.id!);
                if (hasCategories && hasCategories.selectedThreads) {
                    fetchMetadata({ variables: { 
                        contentId: file.id, 
                        threadIds: hasCategories.selectedThreads, 
                        suggestThreadSubchannelId: suggestedThreadSubchannel.id,
                        suggestThreadTitle: suggestThreadTitle } });
                } else {
                    resetThreadCategoryState();
                    fetchMetadata({ variables: { 
                        contentId: file.id, 
                        threadIds: [0],
                        suggestThreadSubchannelId: suggestedThreadSubchannel.id,
                        suggestThreadTitle: suggestThreadTitle
                     } });
                }
            } else if (selectedRadioButton === RadioButtonGroup.SET && contentSetsState && contentSetsState[contentSetIndex]) {
                if (contentSetsState![contentSetIndex].selectedThreadIds) {
                    fetchMetadata({
                        variables: {
                            contentId: file.id,
                            threadIds: contentSetsState![contentSetIndex].selectedThreadIds,
                            suggestThreadSubchannelId: suggestedThreadSubchannel.id,
                            suggestThreadTitle: suggestThreadTitle
                        }
                    });
                } else {
                    resetThreadCategoryState();
                    fetchMetadata({ variables: { 
                        contentId: file.id, 
                        threadIds: [0],
                        suggestThreadSubchannelId: suggestedThreadSubchannel.id,
                        suggestThreadTitle: suggestThreadTitle } });
                }
            }
        }
    }

    const assignToSelectedChannelArray = () => {
        setSelectedChannels([...selectedChannels, selectedDropdownData.channel]);
        setSelectedSubchannels([
            ...selectedSubchannels,
            selectedDropdownData.subchannel,
        ]);
        setSelectedThreads([...selectedThreads, selectedDropdownData.thread]);

    };

    const getFilesInSetIds = (selectedContentSet: ContentSet): number[] => {
        const filesInSetsIds = selectedContentSet.files.map(file => {
            return file.id;
        })

        return filesInSetsIds;
    }

    const applyToSet = (value: string) => {
        setEditMode({ ...editMode, [value]: true });
        let selectedContentSet = contentSetsState![contentSetIndex];

        let licenseTypesMeta = imageMetadata.license;
        let keywordsMeta = imageMetadata.keywords;
        let mainInfoMeta = imageMetadata.mainInformation;
        let copyrightMeta = imageMetadata.copyright;
        let locationMeta = imageMetadata.location;

        const filesInSetsIds = getFilesInSetIds(selectedContentSet);

        switch (value) {
            case GroupsNames.MAIN_INFO:
                if (mainInfoMeta) {
                    updateMainInfromationToSet(filesInSetsIds, mainInfoMeta);
                }
                break;
            case GroupsNames.LOCATION:
                if (locationMeta) {
                    updateLocations(filesInSetsIds, locationMeta);
                }
                break;
            case GroupsNames.KEYWORDS:
                if (keywordsMeta) {
                    updateKeywordsToSet(filesInSetsIds, keywordsMeta.keywordsToAdd!, keywordsMeta.keywordsToDelete!);
                }
                break;
            case GroupsNames.COPYRIGHT:
                if (copyrightMeta) {
                    updateCopyrights(filesInSetsIds, copyrightMeta);
                }
                break;
            case GroupsNames.LICENSING:
                if (licenseTypesMeta) {
                    updateLicensingTypes(filesInSetsIds, licenseTypesMeta);
                }
                break;
            default:
                return null;
        }

        broadcastUploadErrorAction({
            severity: SeveritySnackbarEnum.success,
            message: t("ApplyToSet.Success")
        });
    };

    const handleAutoCompleteAgencyChange = (event: any, newValue: any) => {
        if (selectedImage.id) {
            const user = contributorOptions.filter(option => {
                return option.company?.name === newValue
            })[0];
            const contentSource: ContentSource = { userId: user.id, companyName: newValue };
            setImageMetadata({ ...imageMetadata, copyright: { ...imageMetadata.copyright!, contentSource: contentSource } });
        } else {
            broadcastUploadErrorAction({
                severity: SeveritySnackbarEnum.warning,
                message: t("Warning.MoveToSet")
            });
        }
    }

    const handleAutoCompleteCountriesChange = (event: any, newValue: any) => {
        if (selectedImage.id && countries) {
            const country = countries.filter(option => {
                return option.country === newValue;
            })[0]
            handleOnClickCountry(country);
        }
    }

    const handleAutoCompleteProvinceChange = (event: any, newValue: any) => {
        if (selectedImage.id && selectedCountry && provinces) {
            const province = provinces.filter(option => {
                return option.province === newValue;
            })[0];
            handleOnClickProvince(province);
        }
    }
    const handleAutoCompleteCityChange = (event: any, newValue: any) => {
        if (selectedImage.id && selectedCountry && cities) {
            const city = cities.filter(option => {
                return option.city === newValue;
            })[0];
            handleOnClickCity(city);
        }
    }

    const handleAutoCompleteAgencyInputChange = (event: any, value: string) => {
        if (value === "") {
            const user = contributorOptions.filter(option => {
                return option.company?.name === value
            })[0];
            const contentSource: ContentSource = { userId: user ? user.id : 0, companyName: value };
            setImageMetadata({ ...imageMetadata, copyright: { ...imageMetadata.copyright!, contentSource: contentSource } });
        }
    }

    const handleCityChange = (value: string): void=>{
        const city: RapidApiCityDto = {
            city: value,
            latitude: 0,
            longitude: 0
        };
        handleOnClickCity(city);
    }
    const getBreadcrumbs = () => {
        let result: any[] = [];
        for (let i = 0; i < addCategoryIndex - 1; i++) {
            result.push(
                <div key={i} className='breadcrumb-container'>
                    <Breadcrumbs aria-label="navigation-breadcrumb">
                        <Link color="inherit" href="#">
                            {selectedChannels[i].selectedChannelTitle}
                        </Link>
                        <Link color="inherit" href="#">
                            {selectedSubchannels[i].selectedChannelTitle}
                        </Link>
                        <Link color="inherit" href="#">
                            {selectedThreads[i].selectedChannelTitle}
                        </Link>
                    </Breadcrumbs>
                    <IconButton disabled={editMode.category} onClick={() => removeCategory(i)}>
                        <DeleteIcon />
                    </IconButton>
                </div>);
        }

        return result;
    };

    const removeCategory = (index: number) => {
        let updatedThreads = selectedThreads.filter(thread => thread.selectedId !== selectedThreads[index].selectedId);
        let updatedChannels = selectedChannels;
        let updatedSubChannels = selectedSubchannels;

        updatedChannels.splice(index, 1);
        updatedSubChannels.splice(index, 1);
        setAddCategoryIndex(prevState => prevState - 1);

        if (updatedThreads) {
            let selectedThreads = updatedThreads.map(thread => {
                return thread.selectedId
            });

            if (selectedRadioButton === RadioButtonGroup.SET) {
                const selectedContentSetId = contentSetsState![contentSetIndex].id;
                addChannelToContent({ selectedThreads, selectedContentSetId });
            } else if (selectedRadioButton === RadioButtonGroup.INDIVIDUAL) {
                let imageCategory: IndividualImageCategory = {
                    selectedThreads: selectedThreads,
                    contentId: selectedImage.id!
                };
                addIndividualImageNavigation(imageCategory);
            }
            setSelectedThreads(updatedThreads);
            setSelectedSubchannels(updatedSubChannels);
            setSelectedChannels(updatedChannels);
        }
    }

    const resetThreadCategoryState = () => {
        setAddCategoryIndex(1);
        setSelectedChannels([]);
        setSelectedSubchannels([]);
        setSelectedThreads([]);
    }

    const handleArrowClick = (event: any, nextCommand: string) => {
        if (nextCommand === "forward") {
            setContentSetIndex(prevState => prevState + 1);
        } else if (nextCommand === "backward") {
            setContentSetIndex(prevState => prevState - 1);
        }
        setEditMode({ category: true, mainInfo: true, location: true, keywords: true, copyright: true, licensing: true });
        resetDropdownData();
        if (nextCommand === "forward") {
            setSelectedImage(contentSetsState![contentSetIndex! + 1].files[0]);
            fetchMetadata({ variables: { 
                contentId: contentSetsState![contentSetIndex! + 1].files[0].id, 
                threadIds: contentSetsState![contentSetIndex + 1].selectedThreadIds ? contentSetsState![contentSetIndex + 1].selectedThreadIds : [0],
                suggestThreadSubchannelId: suggestedThreadSubchannel.id,
                suggestThreadTitle: suggestThreadTitle } });
        }
        else if (nextCommand === "backward") {
            setSelectedImage(contentSetsState![contentSetIndex! - 1].files[0]);
            fetchMetadata({ variables: { 
                contentId: contentSetsState![contentSetIndex! - 1].files[0].id, 
                threadIds: contentSetsState![contentSetIndex - 1].selectedThreadIds ? contentSetsState![contentSetIndex - 1].selectedThreadIds : [0],
                suggestThreadSubchannelId: suggestedThreadSubchannel.id,
                suggestThreadTitle: suggestThreadTitle } });
        }
    }

    const resetDropdownData = () => {
        setSelectedDropdownData({
            ...selectedDropdownData,
            channel: { selectedId: 0, selectedChannelTitle: '' },
            subchannel: { selectedId: 0, selectedChannelTitle: '' },
            thread: { selectedId: 0, selectedChannelTitle: '' }
        });
    }

    const handleOnClickCountry = (country: RapidApiCountryDto) => {
        setSelectedCountry(country);
        setImageMetadata({
            ...imageMetadata,
            location: Object.assign({}, { city: '', stateProvince: '', stateCode: '', longitude: 0, latitude: 0, zipCode: '', country: country.country, countryCode: country.countryCode })
        });
    }

    const handleOnClickProvince = (province: RapidApiProvinceDto) => {
        setSelectedProvince(province);
        setImageMetadata({
            ...imageMetadata,
            location: Object.assign({}, imageMetadata.location, { stateProvince: province.province, stateCode: province.isoCode, city: '', longitude: 0, latitude: 0 })
        })
    }

    const handleOnClickCity = (city: RapidApiCityDto) => {
        setSelectedCity(city);
    }


    useEffect(() => {
        if (data && data.fetchLocation && data.fetchLocation.country) {
            const country = data.fetchLocation.country;
            const isValid = doesCountryExistInRapidApi(countries, country);
            setSelectedCountry({
                country: isValid ? country.country : "",
                countryCode: isValid ? country.countryCode : ""
            });
            fetchProvinces(country.countryCode);
        }
    }, [data,countries]);

    useEffect(() => {
        if (data && data.fetchLocation && data.fetchLocation.province) {
            const province = data.fetchLocation.province;
            const country = data.fetchLocation.country;
            const isValidProvince = doesProvinceExistInRapidApi(provinces, province)
            setSelectedProvince({
                province: isValidProvince ? province.stateProvince : "",
                isoCode: isValidProvince ? province.stateCode : "",
                countryId: selectedCountry.countryCode
            })
            fetchCities(country.countryCode, province.stateCode);
        }
    }, [data]);

    useEffect(() => {
        if (data && data.fetchLocation && data.fetchLocation.city) {
            const city = data.fetchLocation.city;
            const locationCoordinates = data.fetchLocation.locationCoordinates;
            const isValidCity = doesCityExistInRapidApi(cities, city);
            setSelectedCity({
                city: isValidCity ? city.city : '',
                longitude: isValidCity && locationCoordinates ? locationCoordinates.longitude : 0,
                latitude: isValidCity && locationCoordinates ? locationCoordinates.latitude : 0
            })
        }
    }, [data])

    useEffect(() => {
        if (selectedCountry.country !== '' && selectedCountry.countryCode !== '') {
            setCountryCode(selectedCountry.countryCode);
            setImageMetadata({
                ...imageMetadata,
                location: Object.assign({}, imageMetadata.location, { country: selectedCountry.country, countryCode: selectedCountry.countryCode })
            });
            fetchProvinces(selectedCountry.countryCode);
        }
    }, [selectedCountry]);

    useEffect(() => {
        if (selectedProvince.province !== '' || selectedProvince.isoCode !== '') {
            setProvinceIsoCode(selectedProvince.isoCode);
            setImageMetadata({
                ...imageMetadata,
                location: Object.assign({}, imageMetadata.location, { stateProvince: selectedProvince.province, stateCode: selectedProvince.isoCode })
            })
            fetchCities(countryCode, selectedProvince.isoCode)
        }
    }, [selectedProvince]);

    useEffect(() => {
        if (selectedCity.city !== '') {
            setImageMetadata({
                ...imageMetadata,
                location: Object.assign({}, imageMetadata.location, { city: selectedCity.city, latitude: selectedCity.latitude, longitude: selectedCity.longitude })
            });
        }
    }, [selectedCity]);

    const resetLocations = () => {
        setSelectedCountry({ country: "", countryCode: '' });
        setCountryCode('');
        setSelectedProvince({ province: '', isoCode: '', countryId: '' });
        setSelectedCity({ city: '', longitude: 0, latitude: 0 });
        setImageMetadata({
            ...imageMetadata,
            location: Object.assign({}, imageMetadata.location, { country: '', countryCode: '', stateProvince: '', stateCode: '', city: '', latitude: 0, longitude: 0 })
        });
    }

    const { t } = useTranslation();

    const countryFilter = createFilterOptions<RapidApiCountryDto>();
    const provinceFilter = createFilterOptions<RapidApiProvinceDto>();

    return (
        <div className="edit-metadata-container">
            <SyncLoader css={`position: fixed; margin: 0; padding: 0; top: 50vh; left: 0; height: 100%;
                z-index: 10; width: 100%; opacity: .5;`} size={20} color={"#36D2B3"} loading={loadingIndicator} />
            <MuiThemeProvider theme={inputFormTheme}>
                {contentSetsState || uploadedFiles ? (
                    <div className="content-vertical-bar">
                        <Tooltip title={contentSetsState && contentSetsState[contentSetIndex] && contentSetsState[contentSetIndex].title ? contentSetsState![contentSetIndex].title : ''}
                            disableFocusListener disableTouchListener>
                            <label className="content-set-label">
                                {selectedRadioButton === RadioButtonGroup.SET &&
                                    contentSetsState &&
                                    contentSetsState[contentSetIndex]
                                    ? <FormControlLabel aria-label='content-set-title'
                                        onClick={(event) => event.stopPropagation()}
                                        onFocus={(event) => event.stopPropagation()}
                                        control={
                                            <EditableTextField value={contentSetsState![contentSetIndex].title}
                                                name="textfield" autoFocus={true}
                                                onSubmit={(value: string) => changeSetTitle(contentSetsState![contentSetIndex], value)} />
                                        } label='' />
                                    : null}
                            </label>
                        </Tooltip>
                        <div className="vertical-bar-buttons">
                            {selectedRadioButton === RadioButtonGroup.SET &&
                                contentSetsState &&
                                contentSetsState.length > 1 ? (
                                <div>
                                    {selectedRadioButton === RadioButtonGroup.SET && contentSetsState && contentSetsState.length > 1 ?
                                        <React.Fragment>
                                            <Button size='small' variant="contained" color='default'
                                                onClick={(event: any) => handleArrowClick(event, "backward")}
                                                disabled={contentSetIndex === 0}
                                                startIcon={<ArrowBackIcon fontSize='small' />}>
                                                {t("Previous.Button")}
                                            </Button>

                                            <Button size='small' variant="contained" color='default'
                                                onClick={(event: any) => handleArrowClick(event, "forward")}
                                                disabled={contentSetIndex === contentSetsState.length - 1}
                                                endIcon={<ArrowForwardIcon fontSize='small' />}>
                                                {t("Next.Button")}
                                            </Button>
                                        </React.Fragment>
                                        : null}
                                </div>
                            ) : null}
                        </div>

                        {selectedRadioButton === RadioButtonGroup.SET && contentSetsState && contentSetsState[contentSetIndex] ?
                            <ImageList rowHeight={120} cols={1} classes={{ root: classes.gridListRoot }}>
                                {contentSetsState[contentSetIndex].files.map((file: UploadedFileResponse) => (
                                    <ImageListItem key={file.id} cols={1} classes={{ root: classes.gridListTileRoot }}>
                                        <ListItem button selected={selectedImage.id === file.id}
                                            classes={{
                                                gutters: contentSetsState[contentSetIndex].coverPhoto?.id === file.id
                                                    ? classes.coverPhoto : ''
                                            }}
                                            onClick={() => handleImageClick(file)} aria-label="selectable-image">
                                            <Image src={file.url} className="image-container" loading='auto'
                                                placeholder={<div className="placeholder"> {t("ContentUpload.Failed.Displayment")}</div>} />
                                        </ListItem>
                                    </ImageListItem>
                                ))}
                            </ImageList>
                            :
                            <ImageList rowHeight={120} cols={1} classes={{ root: classes.gridListRoot }}>
                                {uploadedFiles.map((file: UploadedFileResponse) => (
                                    <ImageListItem key={file.id} cols={1} classes={{ root: classes.gridListTileRoot }}>
                                        <ListItem button selected={selectedImage.id === file.id}
                                            onClick={() => handleImageClick(file)} aria-label="selectable-image">
                                            <Image src={file.url} className="image-container" loading='auto'
                                                placeholder={<div className="placeholder">{t("ContentUpload.Failed.Displayment")}.</div>} />
                                        </ListItem>
                                    </ImageListItem>
                                ))}
                            </ImageList>
                        }
                    </div>) : null
                }
                <div className='content-image-metadata'>
                    <Card>
                        <CardActionArea>
                            {editSetFromAdmin && selectedImage.url && selectedImage.isApproved === undefined ?
                                <div className='button-group'>
                                    <Button size='small' variant='contained'
                                        onClick={() => handleRejectImage!(selectedImage.id!)}><CloseIcon />
                                        {t("RejectImage.Button")}
                                    </Button>
                                    <Button size='small' variant='contained'
                                        onClick={() => handleApproveImage!(selectedImage.id!)}><DoneIcon />
                                        {t("ApproveImage.Button")}
                                    </Button>
                                </div>
                                : null}

                            {editSetFromAdmin && selectedImage.isApproved !== undefined ?
                                selectedImage.isApproved ?
                                    <label className='header-label'> {t("Approved.Label")} <CheckCircleIcon
                                        style={{ fill: 'green', verticalAlign: 'middle' }} /></label>
                                    :
                                    <label className='header-label'> {t("Rejected.Label")} <CancelIcon
                                        style={{ fill: 'red', verticalAlign: 'middle' }} /></label>
                                : null}
                            <CardMedia component='img' title={selectedImage.code} image={selectedImage.url} />
                        </CardActionArea>
                    </Card>

                    {/* CHANNELS AND SUBCHANNELS */}
                    <Accordion defaultExpanded>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} id='category-image-set'>
                            {selectedRadioButton === RadioButtonGroup.SET ?
                                <label className='header-label'> {t("Channel.AccordionActions.Label.Set")} </label>
                                :
                                <label className='header-label'> {t("ChannelAccordionActions.Label.Individual")} </label>
                            }
                        </AccordionSummary>
                        <AccordionDetails>
                            {getBreadcrumbs()}
                            {addCategoryIndex < 4 ?
                                <SelectChannelsComponent disabled={editMode.category}
                                    selectedDropdownData={selectedDropdownData}
                                    handleSelect={handleSelectChannel}
                                    addToAnother={addAnotherCategory}
                                    broadcastUploadErrorAction={broadcastUploadErrorAction}
                                    channels={channels} />
                                : null}
                        </AccordionDetails>
                        <AccordionActions>
                            <Button size='small' variant='contained' disabled={editMode.category}
                                onClick={addAnotherCategory}>{t("AddToAnother.Button")}</Button>
                            {editMode.category ?
                                <IconButton onClick={() => handleEditModeChange(GroupsNames.CATEGORY)}>
                                    <EditIcon />
                                </IconButton>
                                :
                                <Button size='small' variant='contained'
                                    onClick={() => SaveChanges(GroupsNames.CATEGORY)}>{t("Save.Changes.Button")}</Button>}
                        </AccordionActions>
                    </Accordion>

                    {/* MAIN INFORMATION */}
                    <Accordion defaultExpanded>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} id='main-information-metadata'>
                            <label className='header-label'> {t("Main.Information.Label")}</label>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className='accordion-details'>
                                <div className='form-field-row'>
                                    <InputLabel htmlFor="object-name-input"
                                        shrink={true}>{t("ObjectName.Input.Label")}{" "}</InputLabel>
                                    <TextField autoComplete="off" variant='standard' disabled={editMode.mainInfo}
                                        onChange={(e: any) => handleChange(e, e.target.value, GroupsNames.MAIN_INFO)}
                                        label="" name='objectName'
                                        value={imageMetadata.mainInformation ? imageMetadata.mainInformation.objectName : ""} />

                                </div>
                                <div className='form-field-row'>
                                    <InputLabel htmlFor="headline-input"
                                        shrink={true}> {t("Headline.Input.Label")}{" "}</InputLabel>
                                    <TextField autoComplete="off" variant='standard' disabled={editMode.mainInfo}
                                        onChange={(e: any) => handleChange(e, e.target.value, GroupsNames.MAIN_INFO)}
                                        label="" name="headline"
                                        value={imageMetadata.mainInformation ? imageMetadata.mainInformation.headline : ""} />
                                </div>
                                <div className='form-field-row'>
                                    <InputLabel htmlFor="caption-input"
                                        shrink={true}>{t("Caption.Input.Label")}{" "}</InputLabel>
                                    <TextField autoComplete="off" variant='standard' disabled={editMode.mainInfo} multiline
                                        onChange={(e: any) => handleChange(e, e.target.value, GroupsNames.MAIN_INFO)}
                                        label="" name="caption"
                                        value={imageMetadata.mainInformation ? imageMetadata.mainInformation.caption : ""} />
                                </div>
                                <div className='form-field-row'>
                                    <InputLabel htmlFor="caption-input"
                                        shrink={true}>{t("ExternalLink.Input.Label")}{" "}</InputLabel>
                                    <TextField autoComplete="off" variant='standard' disabled={editMode.mainInfo} multiline
                                        onChange={(e: any) => handleChange(e, e.target.value, GroupsNames.MAIN_INFO)}
                                        label="" name="externalLink"
                                        value={imageMetadata.mainInformation ? imageMetadata.mainInformation.externalLink : ""} />
                                </div>
                                <div className='form-field-row'>
                                    <InputLabel htmlFor="caption-input"
                                        shrink={true}>{t("ExternalLinkMessage.Input.Label")} </InputLabel>
                                    <TextField autoComplete="off" variant='standard' disabled={true} multiline
                                        onChange={(e: any) => handleChange(e, e.target.value, GroupsNames.MAIN_INFO)}
                                        label="" name="externalLinkMessage"
                                        value={imageMetadata.mainInformation ? imageMetadata.mainInformation.externalLinkMessage : ""} />
                                </div>
                            </div>
                        </AccordionDetails>
                        <AccordionActions>
                            {selectedRadioButton === RadioButtonGroup.SET ?
                                <Button size='small' variant='contained' disabled={editMode.mainInfo} onClick={() => applyToSet(GroupsNames.MAIN_INFO)}>{t("ApplyToSet.Button")}</Button>
                                : null}
                            {editMode.mainInfo ?
                                <IconButton onClick={() => handleEditModeChange(GroupsNames.MAIN_INFO)}>
                                    <EditIcon />
                                </IconButton>
                                :
                                <Button size='small' variant='contained'
                                    onClick={() => SaveChanges(GroupsNames.MAIN_INFO)}>{t("Save.Changes.Button")}</Button>}
                        </AccordionActions>
                    </Accordion>
                    {/* LOCATIONS */}
                    <Accordion defaultExpanded>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} id='location-metadata'>
                            <label className='header-label'>{t("Location.Label")}</label>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className='accordion-details'>
                                <div className='form-field-row'>
                                    <InputLabel htmlFor="country-input"
                                        shrink={true}>{t("Country.Input.Label")}{" "}</InputLabel>
                                    <Autocomplete
                                        id="autocomplete-country"
                                        style={{ width: '100%' }}
                                        value={imageMetadata.location ? imageMetadata.location.country : ""}
                                        options={countries.map((option) => option.country)}
                                        disabled={editMode.location}
                                        disableClearable
                                        autoHighlight
                                        onChange={handleAutoCompleteCountriesChange}
                                        filterOptions={(options: any, params: FilterOptionsState<any>): any => {
                                            const filtered = countryFilter(options, params);
                                            return filtered;
                                          }}
                                        renderInput={(params: any) => (
                                            <TextField
                                                {...params}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'countryInput',
                                                }}
                                                id="country-input" variant='standard' placeholder="Enter country"
                                                value={imageMetadata.location ? imageMetadata.location.country : ""} name={LocationGroups.COUNTRY}
                                                disabled={editMode.location} />
                                        )}
                                    />
                                </div>
                                <div className='form-field-row'>
                                    <InputLabel htmlFor="province-input"
                                        shrink={true}>{t("Province.Input.Label")}{" "}</InputLabel>
                                    <Autocomplete
                                        id="autocomplete-province"
                                        style={{ width: '100%' }}
                                        value={imageMetadata.location ? imageMetadata.location.stateProvince : ""}
                                        options={provinces.map((option) => option.province)}
                                        disabled={editMode.location}
                                        disableClearable
                                        autoHighlight
                                        onChange={handleAutoCompleteProvinceChange}
                                        filterOptions={(options: any, params: FilterOptionsState<any>): any => {
                                            const filtered = provinceFilter(options, params);
                                            return filtered;
                                          }}
                                        renderInput={(params: any) => (
                                            <TextField
                                                {...params}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'stateProvinceInput',
                                                }}
                                                autoComplete='off'
                                                id="province-input" variant='standard' placeholder="Enter province"
                                                value={imageMetadata.location ? imageMetadata.location.stateProvince : ""} name={LocationGroups.PROVINCE}
                                                disabled={editMode.location} />
                                        )}
                                    />
                                </div>
                                <div className='form-field-row'>
                                    <InputLabel htmlFor="city-input"
                                        shrink={true}>{t("City.Input.Label")}{" "}</InputLabel>
                                    <Autocomplete
                                        freeSolo id="autocomplete-city"
                                        style={{ width: '100%' }}
                                        value={imageMetadata.location ? imageMetadata.location.city : ""}
                                        options={cities.map((option) => option.city)}
                                        disabled={editMode.location}
                                        disableClearable
                                        autoHighlight
                                        onChange={handleAutoCompleteCityChange}
                                        renderInput={(params: any) => (
                                            <TextField
                                                {...params}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'cityInput',
                                                }}
                                                onChange={(e)=>{
                                                    handleCityChange(e.target.value);
                                                }}
                                                autoComplete='off'
                                                id="city-input" variant='standard' placeholder="Enter city"
                                                value={imageMetadata.location ? imageMetadata.location.city : ""} name={LocationGroups.CITY}
                                                disabled={editMode.location} />
                                        )}
                                    />
                                </div>

                                <div className='form-field-row'>
                                    <InputLabel htmlFor="country-code-input"
                                        shrink={true}>{t("CountryCode.Input.Label")}{" "}</InputLabel>
                                    <TextField autoComplete="off" variant='standard' type='text' disabled={true}
                                        onChange={(e: any) => handleChange(e, e.target.value, GroupsNames.LOCATION)}
                                        name="countryCode"
                                        value={imageMetadata.location ? imageMetadata.location.countryCode : ""} />
                                </div>
                                {/*                                 
                                <div className='form-field-row'>
                                    <InputLabel htmlFor="country-code-input"
                                        shrink={true}>{t("ZipCode.Input.Label")}{" "}</InputLabel>
                                    <TextField variant='standard' type='text' disabled={true}
                                        onChange={(e: any) => handleChange(e, e.target.value, 'location')}
                                        name="zipCode"
                                        value={imageMetadata.location ? imageMetadata.location.zipCode : ""} />
                                </div> */}

                                <div className='form-field-row'>
                                    <InputLabel htmlFor="latitude-input"
                                        shrink={true}>{t("GPSLatitude.Input.Label")}{" "}</InputLabel>
                                    <TextField autoComplete="off" variant='standard' type='number' disabled={true}
                                        onChange={(e: any) => handleChange(e, e.target.valueAsNumber, GroupsNames.LOCATION)}
                                        name="latitude"
                                        value={imageMetadata.location ? imageMetadata.location.latitude : 0} />
                                </div>
                                <div className='form-field-row'>
                                    <InputLabel htmlFor="longitude-input"
                                        shrink={true}>{t("GPSLongitude.Input.Label")}{" "}</InputLabel>
                                    <TextField autoComplete="off" variant='standard' type='number' disabled={true}
                                        onChange={(e: any) => handleChange(e, e.target.valueAsNumber, GroupsNames.LOCATION)}
                                        name="longitude"
                                        value={imageMetadata.location ? imageMetadata.location.longitude : 0} />
                                </div>
                            </div>
                        </AccordionDetails>
                        <AccordionActions>
                            {selectedRadioButton === RadioButtonGroup.SET ?
                                <Button size='small' variant='contained' disabled={editMode.location} onClick={() => applyToSet(GroupsNames.LOCATION)}>{t("ApplyToSet.Button")}</Button>
                                : null}
                            {/* <Button size='small' variant='contained' disabled={editMode.location} onClick={() => resetLocations()}>
                                Discard changes
                            </Button> */}
                            {editMode.location ?
                                <IconButton onClick={() => handleEditModeChange(GroupsNames.LOCATION)}>
                                    <EditIcon />
                                </IconButton>
                                :
                                <Button size='small' variant='contained'
                                    onClick={() => SaveChanges(GroupsNames.LOCATION)}>{t("Save.Changes.Button")}</Button>}
                        </AccordionActions>
                    </Accordion>

                    {/* LICENSING */}
                    <Accordion defaultExpanded>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} id='license-metadata'>
                            <label className='header-label'>{t("License.Label")}</label>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className='form-field-row'>
                                <InputLabel htmlFor="license-input"
                                    shrink={true}>{t("LicenseType.Input.Label")}{" "}</InputLabel>
                                <Select variant='standard'
                                    onChange={(e: any) => handleChange(e, e.target.value, GroupsNames.LICENSING)}
                                    disabled={editMode.licensing} id="license-input"
                                    value={imageMetadata.license ? imageMetadata.license.type : ""}
                                    name='type'>
                                    {
                                        Object.values(LicensingTypes).map(licensingType => {
                                            return (
                                                <MenuItem key={licensingType} value={licensingType}>{t("LicenseType." + licensingType)}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </div>
                        </AccordionDetails>
                        <AccordionActions>
                            {selectedRadioButton === RadioButtonGroup.SET ?
                                <Button size='small' variant='contained' disabled={editMode.licensing} onClick={() => applyToSet(GroupsNames.LICENSING)}>{t("ApplyToSet.Button")}</Button>
                                : null}
                            {editMode.licensing ?
                                <IconButton onClick={() => handleEditModeChange(GroupsNames.LICENSING)}>
                                    <EditIcon />
                                </IconButton>
                                :
                                <Button size='small' variant='contained'
                                    onClick={() => SaveChanges(GroupsNames.LICENSING)}>{t("Save.Changes.Button")}</Button>}
                        </AccordionActions>
                    </Accordion>
                </div>
                <div className='content-image-metadata'>
                    {/* KEYWORDS */}
                    <Accordion defaultExpanded>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} id='keywords-metadata'>
                            <label className='header-label'>{t("Keyword.Label")}</label>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className='tag-input-container'>
                                {imageMetadata.keywords ? imageMetadata.keywords.keywordsToShow.map((data: any) => {
                                    return (
                                        <Chip key={imageMetadata.keywords?.keywordsToShow!.indexOf(data)} variant='outlined'
                                            disabled={editMode.keywords} label={data.name}
                                            onDelete={() => handleDelete(data.name)} />
                                    );
                                }) : null}

                                <TextField autoComplete="off" variant="standard" name='keywordState' value={!editMode.keywords ? keywordState : ""}
                                    disabled={editMode.keywords}
                                    onKeyUp={(e: any)=>{
                                        if (e.key === 'Enter') {
                                            addKeyword()
                                      }}}
                                    onChange={(e: any) => handleChange(e, e.target.value, GroupsNames.KEYWORDS)}
                                    placeholder={t("Add.Keywords.Placeholder")} />
                            </div>
                        </AccordionDetails>
                        <AccordionActions>
                            <Button size='small' variant='contained'
                                disabled={(keywordState.length < 3 || keywordState.length > 100) || editMode.keywords}
                                onClick={addKeyword}>{t("Keywords.Add.Button")}
                            </Button>
                            {selectedRadioButton === RadioButtonGroup.SET ?
                                <Button size='small' disabled={editMode.keywords} variant='contained' onClick={() => applyToSet(GroupsNames.KEYWORDS)}>{t("ApplyToSet.Button")}</Button>
                                : null}
                            {editMode.keywords ?
                                <IconButton onClick={() => handleEditModeChange(GroupsNames.KEYWORDS)}>
                                    <EditIcon />
                                </IconButton>
                                :
                                <Button size='small' variant='contained'
                                    onClick={() => SaveChanges(GroupsNames.KEYWORDS)}>{t("Save.Changes.Button")}</Button>}
                        </AccordionActions>
                    </Accordion>

                    {/* COPYRIGHT */}
                    <Accordion defaultExpanded>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} id='copyright-metadata'>
                            <label className='header-label'>{t("Copyright.Label")}</label>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className='accordion-details'>
                                <div className='form-field-row'>
                                    <InputLabel htmlFor="by-line-input"
                                        shrink={true}>{t("By-line.Input.Label")}{" "}</InputLabel>
                                    <TextField autoComplete="off" variant='standard' disabled={editMode.copyright}
                                        value={imageMetadata.copyright ? imageMetadata.copyright.byLine : ""}
                                        name="byLine"
                                        onChange={(e: any) => handleChange(e, e.target.value, GroupsNames.COPYRIGHT)} />
                                </div>
                                <div className='form-field-row'>
                                    <InputLabel htmlFor="credit-input"
                                        shrink={true}>{t("Credit.Input.Label")}{" "}</InputLabel>
                                    <TextField autoComplete="off" variant='standard' disabled={editMode.copyright}
                                        value={imageMetadata.copyright ? imageMetadata.copyright.credit : ""}
                                        name="credit"
                                        onChange={(e: any) => handleChange(e, e.target.value, GroupsNames.COPYRIGHT)} />
                                </div>
                                <div className='form-field-row'>
                                    <InputLabel htmlFor="copyright-notice-input"
                                        shrink={true}>{t("CopyrightNotice.Input.Label")}{" "} </InputLabel>
                                    <TextField autoComplete="off" variant='standard' disabled={editMode.copyright}
                                        value={imageMetadata.copyright ? imageMetadata.copyright.copyrightNotice : ""}
                                        name="copyrightNotice"
                                        onChange={(e: any) => handleChange(e, e.target.value, GroupsNames.COPYRIGHT)} />
                                </div>
                                <div className='form-field-row'>
                                    <InputLabel htmlFor="instructions-input"
                                        shrink={true}>{t("SpecialInstructions.Input.Label")}{" "}</InputLabel>
                                    <TextField autoComplete="off" variant='standard' disabled={editMode.copyright} multiline
                                        value={imageMetadata.copyright ? imageMetadata.copyright.specialInstructions : ""}
                                        name="specialInstructions"
                                        onChange={(e: any) => handleChange(e, e.target.value, GroupsNames.COPYRIGHT)} />
                                </div>
                            </div>
                        </AccordionDetails>
                        <AccordionActions>
                            {selectedRadioButton === RadioButtonGroup.SET ?
                                <Button size='small' variant='contained' disabled={editMode.copyright} onClick={() => applyToSet(GroupsNames.COPYRIGHT)}>{t("ApplyToSet.Button")}</Button>
                                : null}
                            {editMode.copyright ?
                                <IconButton onClick={() => handleEditModeChange(GroupsNames.COPYRIGHT)}>
                                    <EditIcon />
                                </IconButton>
                                :
                                <Button size='small' variant='contained'
                                    onClick={() => SaveChanges(GroupsNames.COPYRIGHT)}>{t("Save.Changes.Button")}</Button>}
                        </AccordionActions>
                    </Accordion>

                    {/* SOURCE */}
                    <Accordion defaultExpanded>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} id='source-metadata'>
                            <label className='header-label'>{t("Source.Label")}</label>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className='accordion-details'>
                                <div className='form-field-row'>
                                    <InputLabel htmlFor="source-input"
                                        shrink={true}>{t("Source.Label") + ':'}{" "}</InputLabel>
                                    <TextField autoComplete="off" variant='standard' disabled={true}
                                        value={imageMetadata.source ? imageMetadata.source.source : ""}
                                        name="source"
                                        onChange={(e: any) => handleChange(e, e.target.value, GroupsNames.SOURCE)} />
                                </div>
                                <div className='form-field-row'>
                                    <InputLabel htmlFor="created-date-input"
                                        shrink={true}>{t("CreatedDate.Input.Label")}{" "}</InputLabel>
                                    <TextField autoComplete="off" variant='standard' disabled={true}
                                        value={imageMetadata.source ? imageMetadata.source.createdDate : new Date()}
                                        name="createdDate"
                                        onChange={(e: any) => handleChange(e, e.target.value, GroupsNames.SOURCE)}>
                                        <Moment format="DD.MM.YYYY">
                                            {imageMetadata.source?.createdDate}
                                        </Moment>
                                    </TextField>
                                </div>
                                <div className='form-field-row'>
                                    <InputLabel htmlFor="created-time-input"
                                        shrink={true}>{t("CreatedTime.Input.Label")}{" "}</InputLabel>
                                    <TextField autoComplete="off" variant='standard' disabled={true}
                                        value={imageMetadata.source ? imageMetadata.source.createdTime : ""}
                                        name="createdTime"
                                        onChange={(e: any) => handleChange(e, e.target.value, GroupsNames.SOURCE)} />
                                </div>
                                <div className='form-field-row'>
                                    <InputLabel htmlFor="uploaded-input"
                                        shrink={true}> {t("UploadedDate.Input.Label")}{" "}</InputLabel>
                                    <TextField autoComplete="off" variant='standard' disabled={true}
                                        value={imageMetadata.source ? imageMetadata.source.uploadedDate : new Date()}
                                        name="uploadedDate"
                                        onChange={(e: any) => handleChange(e, e.target.value, GroupsNames.SOURCE)}>
                                        <Moment format="DD.MM.YYYY">
                                            {imageMetadata.source?.uploadedDate}
                                        </Moment>
                                    </TextField>
                                </div>
                                <div className='form-field-row'>
                                    <InputLabel htmlFor="filename-input"
                                        shrink={true}>{t("OriginalFilename.Input.Label")}{" "}</InputLabel>
                                    <TextField autoComplete="off" variant='standard' disabled={true}
                                        name="originalFileName"
                                        value={imageMetadata.source ? imageMetadata.source.originalFileName : ""}
                                        onChange={(e: any) => handleChange(e, e.target.value, GroupsNames.SOURCE)} />
                                </div>
                            </div>
                        </AccordionDetails>
                        <AccordionActions>
                        </AccordionActions>
                    </Accordion>

                    {/* FILE */}
                    <Accordion defaultExpanded>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} id='file-metadata'>
                            <label className='header-label'>{t("File.Label")}</label>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className='accordion-details'>
                                <div className='form-field-row'>
                                    <InputLabel htmlFor="filename-input"
                                        shrink={true}>{t("Filename.Input.Label")}{" "}</InputLabel>
                                    <TextField autoComplete="off" variant='standard' disabled={true}
                                        value={imageMetadata.fileDetails ? imageMetadata.fileDetails.fileName : ""} name="fileName"
                                        onChange={(e: any) => handleChange(e, e.target.value, GroupsNames.FILE_DETAILS)} />
                                </div>
                                <div className='form-field-row'>
                                    <InputLabel htmlFor="filesize-input"
                                        shrink={true}> {t("Filesize.Input.Label")}{" "}</InputLabel>
                                    <TextField autoComplete="off" variant='standard' disabled={true}
                                        value={imageMetadata.fileDetails ? imageMetadata.fileDetails.fileSize : ""} name="fileSize"
                                        onChange={(e: any) => handleChange(e, e.target.value, GroupsNames.FILE_DETAILS)} />
                                </div>
                                <div className='form-field-row'>
                                    <InputLabel htmlFor="filetype-input"
                                        shrink={true}>{t("Filetype.Input.Label")}{" "}</InputLabel>
                                    <TextField autoComplete="off" variant='standard' disabled={true}
                                        value={imageMetadata.fileDetails ? imageMetadata.fileDetails.fileType : ""} name="fileType"
                                        onChange={(e: any) => handleChange(e, e.target.value, GroupsNames.FILE_DETAILS)} />
                                </div>
                                <div className='form-field-row'>
                                    <InputLabel htmlFor="width-input"
                                        shrink={true}>{t("ImageWidth.Input.Label")}{" "}</InputLabel>
                                    <TextField autoComplete="off" variant='standard' type='number' disabled={true}
                                        value={imageMetadata.fileDetails ? imageMetadata.fileDetails.imageWidth : 0} name="imageWidth"
                                        onChange={(e: any) => handleChange(e, e.target.valueAsNumber, GroupsNames.FILE_DETAILS)} />
                                </div>
                                <div className='form-field-row'>
                                    <InputLabel htmlFor="length-input"
                                        shrink={true}>{t("ImageHeight.Input.Label")}{" "}</InputLabel>
                                    <TextField autoComplete="off" variant='standard' type='number' disabled={true}
                                        value={imageMetadata.fileDetails ? imageMetadata.fileDetails.imageHeight : 0} name="imageHeight"
                                        onChange={(e: any) => handleChange(e, e.target.valueAsNumber, GroupsNames.FILE_DETAILS)} />
                                </div>
                                <div className='form-field-row'>
                                    <InputLabel htmlFor="model-input"
                                        shrink={true}>{t("CameraModel.Input.Label")}{" "}</InputLabel>
                                    <TextField autoComplete="off" variant='standard' disabled={true}
                                        value={imageMetadata.fileDetails ? imageMetadata.fileDetails.cameraModel : ""} name="cameraModel"
                                        onChange={(e: any) => handleChange(e, e.target.value, GroupsNames.FILE_DETAILS)} />
                                </div>
                            </div>
                        </AccordionDetails>
                        <AccordionActions>
                        </AccordionActions>
                    </Accordion>
                </div>
            </MuiThemeProvider>
        </div>
    );
};

const mapStateToProps = (
    state: StoreState
): {
    contentSetsState: ContentSet[],
    selectedRadioButton: string,
    uploadedFiles: UploadedFileResponse[],
    individualImageCategories: IndividualImageCategory[],
    currentUser: User
} => {
    return {
        contentSetsState: selectContentSets(state),
        selectedRadioButton: selectRadioButtonValue(state),
        uploadedFiles: selectUploadedFiles(state),
        individualImageCategories: selectIndividualImagesCategory(state),
        currentUser: selectCurrentUser(state)
    };
};

const mapDispatchToProps = (dispatch: Dispatch<TContentUploadActions>) => {
    return {
        addChannelToContent: (data: ContentSetCategory) =>
            dispatch<IAddChannelToContentSet>({
                type: ContentUploadActionTypes.ADD_CHANNEL_ID,
                data: data,
            }),
        addIndividualImageNavigation: (data: IndividualImageCategory) =>
            dispatch<IAddChannelToIndividualImage>({
                type: ContentUploadActionTypes.ADD_CHANNEL_TO_INDIVIDUAL,
                data: data,
            }),
        broadcastUploadErrorAction: (data: IBroadcastMessage) => dispatch<IBroadcastContentUploadMessage>({
            type: ContentUploadActionTypes.BROADCAST_MESSAGE, data: data
        }),
        updateSetAction: (data: ContentSet) =>
            dispatch<IUpdateSet>({
                type: ContentUploadActionTypes.UPDATE_SET,
                data: data,
            })
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditContentMetadata);
