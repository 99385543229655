import { createSelector } from 'reselect';
import { StoreState } from 'redux/root-reducer';
import { ContentFromOptions, ContentTypesForSearch } from '../../components/search-bar/searchBar.types';

const selectSearchBar = (state:StoreState) => state.searchBar;

export const selectSearchBarExpanded = createSelector(
    [selectSearchBar],
    (search) => search.searchBarExpanded
);

export const selectTotalResultsSearch = createSelector(
    [selectSearchBar],
    (search) => search.totalResults
);

export const selectContentSearchResults = createSelector(
    [selectSearchBar],
    (search) => search.contentSearchResults
);

export const selectUserSearchResults = createSelector(
    [selectSearchBar],
    (search) => search.userSearchResults
);

export const selectSearchString = createSelector(
    [selectSearchBar],
    (search) => search.searchString
);

export const selectContentFromFilter = createSelector(
    [selectSearchBar],
    (search) => search.contentFrom
);

export const selectContentTypeFilter = createSelector(
    [selectSearchBar],
    (search) => search.contentType
);

export const selectDateFilter = createSelector(
    [selectSearchBar],
    (search) => search.dateFilter
);

export const selectLocationFilter = createSelector(
    [selectSearchBar],
    (search) => search.locationFilter
);

export const selectLocationNearMeFilter = createSelector(
    [selectSearchBar],
    (search) => search.locationNearMeFilter
);

export const selectColourFilter = createSelector(
    [selectSearchBar],
    (search) => search.colourFilter
);

export const selectChannelFilter = createSelector(
    [selectSearchBar],
    (search) => search.channelFilter
);

export const selectSubchannelFilter = createSelector(
    [selectSearchBar],
    (search) => search.subchannelFilter
);

export const selectThreadFilter = createSelector(
    [selectSearchBar],
    (search) => search.threadFilter
);

export const selectTotalResultsChannelContex = createSelector(
    [selectSearchBar],
    (search) => search.totalResultsChannelContex
)

export const selectFilterDataIsInInitialState = createSelector(
    [selectSearchBar],
    (search) => search.contentFrom === ContentFromOptions.PRO_CONTRIBUTORS &&
    search.contentType === ContentTypesForSearch.ALL &&
    search.dateFilter.length === 0 &&
    search.locationFilter.city.length === 0 &&
    search.locationFilter.country ===  ""
);

export const selectSearchBroadcastMessage = createSelector(
    [selectSearchBar],
    (search) => search.searchBroadcastMessage
);

export const selectAddToCartBroadcastMessage = createSelector(
    [selectSearchBar],
    (search) => search.addToCartBroadcastMessage
);

export const selectContentPerPage = createSelector(
    [selectSearchBar],
    (search) => search.contentPerPage
);