import gql from "graphql-tag";

export const GET_ALL_LICENSING_PLANS = gql`
  query getAvailableLicensingPlansQuery {
    getAvailableLicensingPlans {
      id
      amount
      type
      totalPrice
      period
      licensingType
    }
  }
`;

export const BUY_LICENSE_PLAN = gql`
  mutation buyLicensePlanMutation($userId: Int!, $licensePlanId: Int!, $tax: Float!) {
    buyLicensePlan(userId: $userId, licensePlanId: $licensePlanId, tax: $tax) {
      id
    }
  }
`;

export const CONFIRM_LICENSE_PLAN = gql`
  mutation confirmLicensePlanMutation($planId: Int!) {
    confirmLicensePlan(planId: $planId)
  }
`;

export const SEND_LICENSE_PLAN_INVOICE = gql`
  mutation sendLicensePlanInvoiceMutation($userId: Int!, $licensePlanId: Int!) {
    sendLicensePlanInvoice(userId: $userId, licensePlanId: $licensePlanId)
  }
`;

export const DELETE_LICENSING_PLAN = gql`
    mutation deleteLicensePlanMutation($userId: Int!, $licensePlanId: Int!) {
        deleteLicensePlanForUser(userId: $userId, licensePlanId: $licensePlanId)
    }
`;

export const GET_USER_LICENSING_PLANS = gql`
  query getUserLicensingPlansQuery($userId: Int!) {
    getUserLicensingPlans(userId: $userId)
    {
      id
      licensingPlanId
      amountLeft
      purchaseDate
      expirationDate
      plan
      {
        period
        type
        licensingType
        totalPrice
			}
    }
  }
`;
