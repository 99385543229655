import React, { useEffect, useRef, useState } from "react";
import Button from "@material-ui/core/Button";
import { useMutation } from "react-apollo-hooks";
import { LOGOUT_USER } from "../home-component/queries";
import LoginModal from "../login/LoginModal";
import RegisterModal from "../register/RegisterModal";
import { connect } from "react-redux";
import { UserActionTypes, User } from "../../redux/user/user.types";
import {
  ILogoutFailure,
  ILogoutSuccess,
  IStartLogout,
  TUserReducerActions,
} from "../../redux/user/user.actions";
import { Dispatch } from "redux";
import { push, CallHistoryMethodAction } from "connected-react-router";
import { NavBarProps } from "./navbar.types";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { Channel } from "../../components/home-component/home.types";
import { StoreState } from "../../redux/root-reducer";
import { TranslationsActionTypes } from "../../redux/translations/translations.types";
import {
  IChangeLanguage,
  TTranslationsReducerActions,
} from "../../redux/translations/translations.actions";
import {
  IResetChannels,
  TChannelReducerActions,
} from "../../redux/channel-routes/channel.actions";
import { Link, makeStyles, MenuItem, withStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { slide as Menu } from "react-burger-menu";
import "../channels/channelsNavigation.styles.scss";
import "./navbar.styles.scss";
import SignInBanner from "../banner-component/sign-in-banner";
import {
  IToggleBannerHidden,
  TSignInBannerReducerActions,
  IToggleBannerShown,
} from "../../redux/sign-in-banner/sign-in-banner.actions";
import { BannerActionTypes } from "../../redux/sign-in-banner/sign-in-banner.types";
import {
  IToggleLogin,
  TModalReducerActions,
} from "../../redux/modal-visibility/modal.actions";
import { ModalActionTypes } from "../../redux/modal-visibility/modal.types";
import { GET_CHANNELS } from "../channels/queries";
import { Link as RouterLink } from "react-router-dom";
import UserNav from "./user-nav.component";
import "react-flags-select/scss/react-flags-select.scss";
import { selectCurrentUser } from "../../redux/user/user.selectors";
import SearchBar from "../search-bar/searchBar.component";
import RegisterModalWraper from "../register-modal-wraper.component/register-modal-wraper";
import {
  IToggleRegisterWraperHidden,
  TRegisterModalWraperReducerActions,
} from "../../redux/register-modal-wraper/register-modal-wraper.actions";
import { RegisterWraperActionTypes } from "../../redux/register-modal-wraper/register-modal-wraper.types";
import { selectSearchBarExpanded } from "../../redux/search-bar/search-bar.selectors";
import {
  IResetPage,
  TPageReducerActions,
} from "../../redux/error-page/error-page.actions";
import { ErrorPagesTypes } from "../../redux/error-page/error-page.types";
import { RoleTypes } from "../../components/register/register.types";
import {
  IExpandSearchBar,
  IResetFilters,
  IResetSearchResults,
  IToggleSearchString,
  TSearchReducerActions,
} from "../../redux/search-bar/search-bar.actions";
import { SearchBarActions } from "../../redux/search-bar/search-bar.types";
import { ChannelActionTypes } from "../../redux/channel-routes/channel.types";
import {
  IRemoveDroppedProfileImage,
  TOnboardingReducerAction,
} from "../../redux/onboarding/onboarding.actions";
import { OnboardingActionTypes } from "../../redux/onboarding/onboarding.types";
import { CHECK_USER } from "./queries";
import { use } from "passport";
import { config } from "../../config";
import { useCookies } from "react-cookie";

const useStyles = makeStyles((theme) => ({
  list: {
    display: "flex",
    flexDirection: "column",
  },
  paper: {
    border: "1px solid #d3d4d5",
  },
}));

const Navbar: React.FC<NavBarProps> = ({ ...props }) => {
  const classes = useStyles();
  const [cookies, setCookie, removeCookie] = useCookies();

  const [channels, setChannels] = useState<Channel[]>([]);
  const { data, loading, error } = useQuery(GET_CHANNELS);
  const {
    currentUser,
    searchBarExpanded,
    logoutUserAction,
    logoutSuccessAction,
    logoutFailureAction,
    redirectToHome,
    redirectToCart,
    ChangeLanguageTo,
    toggleBanner,
    setShownBanner,
    toggleLogin,
    toggleRegister,
    path,
    resetTogglesPageAction,
    resetSearchResults,
    resetChannelsAction,
    toggleSearchBarExpanded,
    removeDroppedFileAction,
    resetFiltersAction,
    toggleSearchStringAction,
  } = props;
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const [
    checkIfUserStillExists,
    {
      data: userData,
      loading: userLoading,
      error: userError,
      refetch: userRefetch,
    },
  ] = useLazyQuery(CHECK_USER);

  const logoImageUrl = `${config.REACT_APP_CLOUDFRONT_PATH}/assets/logo.png`;

  const [userExists, setUserExists] = useState(true);
  const [logoutUserMutation] = useMutation(LOGOUT_USER);

  const logoutUser = (event: any): void => {
    event.preventDefault();
    logoutUserAction();
    logoutUserMutation()
      .then((result: any) => {
        logoutSuccessAction();
        redirectToHome();
      })
      .catch((response: any) => {
        logoutFailureAction(response.message);
      });
  };

  useEffect(() => {
    if (Object.keys(cookies).length === 0) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  }, [cookies]);

  useEffect(() => {
    if (currentUser && currentUser.id) {
      checkIfUserStillExists({ variables: { userId: currentUser.id } });
    }
  }, [currentUser]);

  useEffect(() => {
    if (userData) {
      if (!userData.checkIfUserExists) {
        logoutUserAction();
        logoutUserMutation()
          .then((result: any) => {
            logoutSuccessAction();
            redirectToHome();
          })
          .catch((response: any) => {
            logoutFailureAction(response.message);
          });
      }
    }
  }, [userData]);

  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const timer: any = useRef(null);

  useEffect((): any => {
    const handleResize = () => {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    };
    window.addEventListener("resize", handleResize);

    return (event: any) => {
      window.removeEventListener("resize", handleResize);
    };
  });
  const ButtonHome = withStyles({
    root: {
      boxShadow: `1px 4px 5px 0.1px #d6d3d6`,
      textTransform: "none",
    },
    label: {
      fontSize: "100%",
    },
  })(Button);

  useEffect(() => {
    if (data && data.getAllChannels) {
      setChannels(data.getAllChannels);
    }
    if (currentUser.email === undefined) {
      timer.current = setTimeout(function () {
        toggleBanner(false);
        setShownBanner(true);
      }, 3000);
      return () => clearTimeout(timer.current);
    }
  }, [data]);

  const takeATour = () => {};

  const myWorld = () => {};

  const handleChange = (event: any) => {
    // event.preventDefault();
    let value = event;
    if (event === "US") {
      value = "EN";
    }

    changeLanguage(value.toLowerCase());
  };

  const changeLanguage = (language: string): any => {
    // console.log(language + "library: " + i18n.language);
    if (i18n.language !== language) {
      ChangeLanguageTo(language);

      i18n.changeLanguage(language).then(() => {
        // console.log("Public: Language changed successfully to", language);
      });
    }
  };

  const handleOpenLogin = () => {
    toggleLogin();
    clearTimeout(timer.current);
  };
  const handleOpenRegister = () => {
    toggleRegister(false);
    clearTimeout(timer.current);
  };

  const handleClick = () => {
    toggleSearchStringAction("");
    resetSearchResults();
    resetChannelsAction();
    resetTogglesPageAction();
    removeDroppedFileAction();
    resetFiltersAction();
    redirectToHome();
    if (searchBarExpanded) {
      toggleSearchBarExpanded();
    }
  };

  const toggleHomeOrSearch = () => {
    if (path !== "/" || searchBarExpanded) {
      return (
        <div className="search-bar">
          <ButtonHome
            onClick={handleClick}
            style={{
              fontFamily: "sans-serif",
              backgroundColor: "white",
              color: "black",
              float: "left",
              marginLeft: "20px",
              borderRadius: "25px",
              fontSize:
                window.innerWidth > 1180
                  ? "1.2rem"
                  : window.innerWidth > 700
                  ? "1rem"
                  : "0.8rem",
              textTransform: "none",
            }}
          >
            {t("Navbar.Home.Button")}
          </ButtonHome>
        </div>
      );
    } else {
      return (
        <div className="search-bar">
          <SearchBar isExpandable={true} />
        </div>
      );
    }
  };

  const handleCartClick = (event: any) => {
    event.preventDefault();

    redirectToCart();
    resetTogglesPageAction();
  };

  return (
    <div className="navigation-bar-container">
      <SignInBanner />
      <LoginModal />
      <RegisterModal />
      <RegisterModalWraper />
      <div className="header-container">
        
        <div className="logo-container">
          <img
            className="logo-image"
            src={`${config.REACT_APP_CLOUDFRONT_PATH}/assets/logo.png`}
            onClick={handleClick}
          />
          {/*<Link className="logo-link" href= "logo.png">  </Link>*/}
        </div>
        <div className="search-and-menu-container">
          {toggleHomeOrSearch()}
          <div>
            <div className="options">
              {/*TODO: Uncomment when the functionalities are completed*/}
              {/*<Link className='navigation-link' onClick={takeATour}>Take a Tour</Link>*/}
              {/*<Link className='navigation-link' onClick={myWorld}>My world</Link>*/}
              {/* <div className='navigation-link' onClick={()=>{}}>
                 <Link className='navigation-link'>Cart</Link>
                 <span className='item-count'>{10}</span>
              </div> */}
              {/*TODO: Uncomment when it gets completed*/}
              {/*<RouterLink className='navigation-link' to="/my-content">Content</RouterLink>*/}

              {currentUser && currentUser.email ? (
                <UserNav />
              ) : (
                <div className="buttonDiv">
                  <Button
                    className="menuItem widthSm"
                    style={{ borderRadius: 50 }}
                    onClick={handleOpenLogin}
                  >
                    {t("Banner.Login.button")}
                  </Button>
                  <Button
                    className="menuItem widthSm"
                    style={{ borderRadius: 50 }}
                    onClick={handleOpenRegister}
                  >
                    {t("Register.button")}
                  </Button>
                </div>
              )}

              {Object.keys(currentUser).length > 0 &&
              (currentUser.role === RoleTypes.Admin || currentUser.role === RoleTypes.SuperAdmin ||
              currentUser.role === RoleTypes.Contributor_agency ||
                currentUser.role === RoleTypes.Contributor_individual ? (
                <div></div>
              ) : (
                <p className="menuItem widthSm" onClick={handleCartClick}>
                  {t("Cart.Button")}
                </p>
              ))}

              <Link
                className="nav-link-option menuItem widthSm"
                target="_blank"
                href={`${config.REACT_APP_CLOUDFRONT_PATH}/assets/pdf/World-Illustrated- about.pdf`}
              >
                {t("Navbar.AboutUs")}
              </Link>

              {/*TODO: uncomment for internationalization feature
                        <ReactFlagsSelect onSelect={handleChange}
                        className="language-select"
                        alignOptions="left"
                        defaultCountry="US"
                        countries={["US", "DE", "BG", "SE", "IT", "FR"]} 
                        customLabels={{"US":LanguageAbbreviations.EN.toUpperCase() ,"DE": LanguageAbbreviations.DE.toUpperCase() , "BG":LanguageAbbreviations.BG.toUpperCase(), "SE": LanguageAbbreviations.SE.toUpperCase(), "IT": LanguageAbbreviations.IT.toUpperCase(), "FR": LanguageAbbreviations.FR.toUpperCase()}} 
                    />*/}
            </div>
            <div className="burger-menu">
              {/* <IconButton aria-label="cart" className='navigation-link' onClick={toggleCart}>
                                <Badge badgeContent={2} color="secondary">
                                    <ShoppingCartIcon />
                                </Badge>
                            </IconButton> */}
              <Menu right isOpen={false}>
                {/*TODO: Uncomment when the functionalities are completed */}
                {/*<Link className='navigation-link' onClick={takeATour}>Take a Tour</Link>*/}
                {/*<Link className='navigation-link' onClick={myWorld}>My world</Link>*/}
                {currentUser && currentUser.email ? (
                  <div>
                    {/*TODO: Extract in constants*/}
                    <p className="userEmail textLeft">{currentUser.email}</p>
                    {currentUser &&
                    (currentUser.role === RoleTypes.Contributor_individual ||
                      currentUser.role === RoleTypes.Contributor_agency) ? (
                      <RouterLink className="nav-link-option" to="/content-upload">
                        <p className="menuItem textLeft">
                          {t("UserNav.Upload.Content")}
                        </p>
                      </RouterLink>
                    ) : null}
                    {currentUser &&
                    (currentUser.role === RoleTypes.Contributor_individual ||
                      currentUser.role === RoleTypes.Contributor_agency) ? (
                      <RouterLink className="navigation-link" to="/my-content">
                        <p className="menuItem textLeft">
                          {t("UserNav.My.Content")}
                        </p>
                      </RouterLink>
                    ) : null}
                    {currentUser 
                     ? (
                      <RouterLink className="navigation-link" to="/light-box">
                        <p className="menuItem textLeft">
                          {t("UserNav.LightBox")}
                        </p>
                      </RouterLink>
                    ) : null}
                    {currentUser &&
                    (currentUser.role === RoleTypes.Contributor_individual ||
                      currentUser.role === RoleTypes.Contributor_agency) ? (
                      <RouterLink className="navigation-link" to="/my-sales">
                        <p className="menuItem textLeft">
                          {t("UserNav.My.Sales")}
                        </p>
                      </RouterLink>
                    ) : null}
                    {currentUser &&
                    (currentUser.role === RoleTypes.Pro_freelancer ||
                      currentUser.role === RoleTypes.Pro_organization) ? (
                      <RouterLink className="navigation-link" to="/my-license-page">
                        <p className="menuItem textLeft">
                          {t("UserNav.My.Licensing.Plans")}
                        </p>
                      </RouterLink>
                    ) : null}
                    {currentUser &&
                    (currentUser.role === RoleTypes.Pro_freelancer ||
                      currentUser.role === RoleTypes.Pro_organization) ? (
                      <RouterLink className="navigation-link" to="/my-downloads">
                        <p className="menuItem textLeft">
                          {t("UserNav.Downloads")}
                        </p>
                      </RouterLink>
                    ) : null}
                    <Link
                      className="nav-link-option"
                      target="_blank"
                      href={`${config.REACT_APP_CLOUDFRONT_PATH}/assets/pdf/World-Illustrated- about.pdf`}

                    >
                      <p className="menuItem textLeft">{t("Navbar.AboutUs")}</p>
                    </Link>
                    <p className="menuItem textLeft" onClick={logoutUser}>
                      {t("UserNav.Logout")}
                    </p>
                  </div>
                ) : (
                  <div className="signup-signin-group">
                    <Button
                      className="menuItem"
                      style={{ borderRadius: 50 }}
                      onClick={handleOpenLogin}
                    >
                      {t("Banner.Login.button")}
                    </Button>
                    <Button
                      className="menuItem"
                      style={{ borderRadius: 50 }}
                      onClick={handleOpenRegister}
                    >
                      {t("Register.button")}
                    </Button>
                  </div>
                )}

                {/*TODO: extract generic select box*/}
                {/*TODO: uncomment from internationalization feature
                                <ReactFlagsSelect
                                onSelect={handleChange}
                                className="language-select"
                                alignOptions="left"
                                defaultCountry="US"
                                countries={["US", "DE", "BG", "SE", "IT", "FR"]} 
                            customLabels={{"US":LanguageAbbreviations.EN.toUpperCase() ,"DE": LanguageAbbreviations.DE.toUpperCase() , "BG":LanguageAbbreviations.BG.toUpperCase(), "SE": LanguageAbbreviations.SE.toUpperCase(), "IT": LanguageAbbreviations.IT.toUpperCase(), "FR": LanguageAbbreviations.FR.toUpperCase()}} />*/}
              </Menu>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (
  state: StoreState
): { searchBarExpanded: boolean; currentUser: User; path: string } => {
  return {
    searchBarExpanded: selectSearchBarExpanded(state),
    currentUser: selectCurrentUser(state),
    path: state.router.location.pathname,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<
    | TUserReducerActions
    | CallHistoryMethodAction
    | TTranslationsReducerActions
    | TSignInBannerReducerActions
    | TModalReducerActions
    | TChannelReducerActions
    | TRegisterModalWraperReducerActions
    | TPageReducerActions
    | TSearchReducerActions
    | TOnboardingReducerAction
  >
) => {
  return {
    logoutUserAction: () =>
      dispatch<IStartLogout>({ type: UserActionTypes.LogoutUser }),
    logoutSuccessAction: () =>
      dispatch<ILogoutSuccess>({ type: UserActionTypes.LogoutUserSuccess }),
    logoutFailureAction: (message: string) =>
      dispatch<ILogoutFailure>({
        type: UserActionTypes.LogoutUserError,
        data: message,
      }),
    redirectToHome: () => dispatch(push("/")),
    redirectToCart: () => dispatch(push("/cart")),
    ChangeLanguageTo: (language: string) =>
      dispatch<IChangeLanguage>({
        type: TranslationsActionTypes.ChangeLanguage,
        data: language,
      }),
    toggleBanner: (show: boolean) =>
      dispatch<IToggleBannerHidden>({
        type: BannerActionTypes.TOGGLE_BANNER_HIDDEN,
        data: show,
      }),
    setShownBanner: (show: boolean) =>
      dispatch<IToggleBannerShown>({
        type: BannerActionTypes.SET_BANNER_SHOWN,
        data: show,
      }),
    toggleLogin: () =>
      dispatch<IToggleLogin>({ type: ModalActionTypes.ToggleLoginModal }),
    toggleRegister: (show: boolean) =>
      dispatch<IToggleRegisterWraperHidden>({
        type: RegisterWraperActionTypes.TOGGLE_REGISTER_WRAPER_HIDDEN,
        data: show,
      }),
    resetTogglesPageAction: () =>
      dispatch<IResetPage>({ type: ErrorPagesTypes.ResetTogglesPage }),
    resetSearchResults: () =>
      dispatch<IResetSearchResults>({
        type: SearchBarActions.RESET_SEARCH_RESULTS,
      }),
    toggleSearchStringAction: (data: string) =>
      dispatch<IToggleSearchString>({
        type: SearchBarActions.TOGGLE_SEARCH_STRING,
        data: data,
      }),
    resetChannelsAction: () =>
      dispatch<IResetChannels>({ type: ChannelActionTypes.RESET_CHANNELS }),
    toggleSearchBarExpanded: () =>
      dispatch<IExpandSearchBar>({ type: SearchBarActions.EXPAND_SEARCH_BAR }),
    removeDroppedFileAction: () =>
      dispatch<IRemoveDroppedProfileImage>({
        type: OnboardingActionTypes.REMOVE_PROFILE_IMAGE,
      }),
    resetFiltersAction: () =>
      dispatch<IResetFilters>({ type: SearchBarActions.RESET_FILTERS }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
