import gql from "graphql-tag";

export const CREATE_OR_ADD_TO_LIGHT_BOX = gql`
  mutation createOrAddToLightbox(
    $lightboxId: Int
    $userId: Int!
    $coverId: Int
    $lightboxName: String
    $contentId: Int
    $contentSetId: Int
    $articleId: Int
    $threadId: Int
  ) {
    createOrAddToLightbox(
      lightboxId: $lightboxId
      userId: $userId
      coverId: $coverId
      lightboxName: $lightboxName
      contentId: $contentId
      contentSetId: $contentSetId
      articleId: $articleId
      threadId: $threadId
    )
  }
`;

export const FETCH_LIGHT_BOXES = gql`
query getUserLightboxes(
    $userId: Int!
    $pageNumber: Int!
    $contentPerPage: Int!
  ) {
    getUserLightboxes(
      userId: $userId
      pageNumber: $pageNumber
      contentPerPage: $contentPerPage
    ){
      total
      lightboxes{
        contentLightboxId
        lightboxName
        coverId
        userId
        timestamp
        cover{
          pathToFileThumbnail
          pathToFileCompressed
              }
      }
    }
  }
`;

export const DELETE_LIGHT_BOX = gql`
query deleteLightbox(
    $lightboxId: Int!
  ) {
    deleteLightbox(
      lightboxId: $lightboxId
    )
  }
`;

export const GET_LIGHT_BOX_DETAILS = gql`
query getUserLightboxDetails(
  $lightboxId: Int!
  $userId: Int!
  $pageNumber: Int!
  $contentPerPage: Int!
) {
  getUserLightboxDetails(
    lightboxId: $lightboxId
    userId: $userId
    pageNumber: $pageNumber
    contentPerPage: $contentPerPage
  ) {
    totalLightboxContents
    lightboxDetails {
      contentLightboxId
      lightboxName
      coverId
      userId
      timestamp
      cover {
        pathToFileThumbnail
        pathToFileCompressed
      }
    }
    lightboxContents {
      contentInLightboxId
      lightboxId
      contentId
      contentSetId
      articleId
      threadId
      contentInfo {
        id
        userId
        code
        type
        objectName
        headline
        licensingType
        caption
        pathToFileThumbnail
        pathToFileCompressed
        isOwned
        size
      }
      contentRate{
        id
        licensingType
        flatRate
        contentType
        role
      }
    }
  }
}
`;

export const DELETE_LIGHT_BOX_CONTENT = gql`
query deleteLightboxContent(
  $lightboxId: Int!
  $contentId: Int!
  
) {
  deleteLightboxContent(
    lightboxId: $lightboxId
    contentId: $contentId
  )
}
`;

