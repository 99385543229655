import gql from 'graphql-tag';

export const GET_SALES = gql`
query getUserSalesHistory(
  $userId: Int!
  $pageNumber: Int!
  $contentPerPage: Int!
) {
  getUserSalesHistory(
    userId: $userId
    pageNumber: $pageNumber
    contentPerPage: $contentPerPage
  ){
    totalSales
    totalEarn
    todayEarn
    contentSales{
      contentPrice
      sellerCommission
      adminCommission
      size
      orderId
      contentId
      contentTax
      licenseOrigin
    	order{
        id
				orderDate
        totalPrice
        tax
        userId
        invoiceNumber
        invoiceUrl
        user{
          id
          username
          person{
            id
            email
            phoneNumber
            firstName
            lastName
          }
				}
      }
      content{
        id
        headline
        code
        pathToFileCompressed
        pathToFileThumbnail
         user{
          id
          username
          role
           company{
            id
            name
            companyType
            userContentType
          }				}
      }
    }
  }
}
`;

export const UPDATE_PREFFERED_CURRENCY = gql`
    mutation updatePrefferedCurrency($userId: Int!, $prefferedCurrency: String!) {
        updatePrefferedCurrency(userId: $userId, prefferedCurrency: $prefferedCurrency)
    }
`