import {
  ContentNavigation,
  Location
} from "../../components/contributor-content/contributor-content.types";
import { User } from "../../redux/user/user.types";
import { Channel } from "../home-component/home.types";
import { Copyright } from "../../components/batch-upload/EditContentMetadata.types";
import { UserLicensingPlan } from "generated/graphql";
import { IBroadcastMessage } from "components/LightBox/lightBox.types";

export enum EditorialSizes {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
  ONLINEONLY = "online only"
}

export enum ItemTypeLicensing {
  isPartOfPlan = "partOfPlan",
  isEditorial = "editorial",
  isStandard = "standard"
}

export enum RouteParamsContentTypes {
  IMAGE = 'image',
  SET = 'set',
  ARTICLE = 'article'
}
export interface PhotoDetailsProps {
  routeParams: RouteParams;
  currentUser: User;
  item?: any;
  currentSelectedPhoto: number;
  selectedChannel: Channel;
  cartItemsFromSub: ItemForCart[];
  lightBoxBroadcast: IBroadcastMessage;
  broadcastMessage: IBroadcastMessage;
  clickedImageToAddToLightBox: number;
  galleryViewEnabled: boolean;
  redirectToShare: ( redirect: string ) => void;
  toggleLogin: () => void;
  selectPhotoAction: ( data: number ) => void;
  redirectToPhotoDetals: ( path: string ) => void;
  addToCartAction: ( item: ItemForCart ) => void;
  addToSubscriptionCartAction: ( item: ItemForCart ) => void;
  selectChannelAction: ( data: Channel ) => void;
  selectSubChannelAction: ( data: Channel ) => void;
  selectThreadAction: ( data: Channel ) => void;
  clearBroadcastMessage: ( ) => void;
}

export interface PhotoDetailsPageProps {
  routeParams: RouteParams;
}

export interface RouteParams {
  contentType?: string;
  contentArticleId?: string;
  contentSetId?: string;
  contentId: string;
  threadId: string;
}

export interface ContentSet {
  content: ContentInSet[];
  contentSet: {
    title: string;
  };
  article?: {
    title: string;
    description: string;
    audioId?: number;
  };
  audioFile?: {
    pathToFile: string;
  };
}

export interface ContentInSet {
  content: {
    id: number;
    pathToFileCompressed: string;
    pathToFileThumbnail: string;
    headline: string;
    isOwned: boolean;
    licensingType: string;
    size: string;
  };
  contentRate: {
    flatRate: number;
  };
  keywords: {
    name: string[];
  };
}

export interface AddCommentProps {
  contentId?: number;
  userId?: number;
  refetch: () => void;
}

export interface DescriptionProps {
  description: string;
}

export interface PriceBoxMessageNonLoggedProps {
  signIn: () => void;
}

export interface SocialAndPricingProps {
  item: ItemForCart;
  isPartOfPlan: boolean;
  isOwned: boolean;
  ownedSizes: string[];
  licensingPlan?: UserLicensingPlan;
  currentUser: any;
}

export interface AddToCartButtonProps {
  item: ItemForCart;
  size?: string;
  isPartOfPlan: boolean;
  currentSubItems: any;
  currentCartItems: any;
  iconOnly?: boolean;
  addToCartAction: ( item: ItemForCart ) => void;
  addToSubscriptionCartAction: ( item: ItemForCart ) => void;
}

export interface EditorialPriceBoxProps {
  item: ItemForCart;
  ownedSizes: string[];
  currentUser?: User;
  clearBroadcastAction?: () => void;
}

export interface SubscriptionBuyBoxProps {
  item: ItemForCart;
  licensingPlan?: UserLicensingPlan;
}

export interface CommentsBoxProps {
  isLoggedIn: boolean;
  comments: CommentType[];
  color: string;
  contentId?: number;
  userId?: number;
  signIn: () => void;
  refetch: () => void;
}

export interface CommentType {
  user: User;
  comment: Comment;
}

export interface Comment {
  id: number;
  publishDate: Date;
  comment: string;
  userId: number;
}
export interface CommentProps {
  commentItem: CommentType;
}

export interface ShareContainerProps {
  currentPath: string;
  copyToClipboard: () => void;
}

export interface LicensePlan {
  id: number;
  licensingType: string;
  totalPrice: number;
  period?: number;
  type: string;
  amount: number;
}

export interface EditorialRate {
  rate: number;
  size: string;
}

export interface ItemForCart {
  content: PhotoDetailsContent;
  editorialSize?: string;
  isbn?: string;
  issn?: string;
  flatRate: number;
}

export interface PhotoDetailsContent {
  id: number;
  pathToFileCompressed: string;
  pathToFileThumbnail?: string;
  headline: string;
  publishDate: Date;
  caption: string;
  code?: string;
  type: string;
  licensingType: string;
  externalLink: string;
  externalLinkMessage: string;
  pathToFile?: string;
  flatRate?: number;
  size?: string;
  amount?: number;
  totalPrice?: number;
  period?: number;
  role?: string;
  // content: {
  //   id: number;
  //   headline: string;
  //   pathToFileCompressed: string;
  //   pathToFileThumbnail: string;
  //   pathToFile: string;
  //   type: string;
  //   licensingType: string;
  //   size?: EditorialSizes;
  //   amount?: number;
  //   totalPrice?: number;
  //   period?: number;
  //   role?: string;
  // };
  // contentRate: {
  //   flatRate: number;
  // };
}

export interface FullContentInfo {
  user: User;
  content: PhotoDetailsContent;
  location: Location;
  copyright: Copyright;
  flatRate: number;
  comments: CommentType[];
  navigation: ContentNavigation[];
  isOwned: boolean;
}

export const ISBN_REGEX = new RegExp(
  "^(?:ISBN(?:-1[03])?:?●)?(?=[0-9X]{10}$|(?=(?:[0-9]+[-●]){3})[-●0-9X]{13}$|97[89][0-9]{10}$|(?=(?:[0-9]+[-●]){4})[-●0-9]{17}$)(?:97[89][-●]?)?[0-9]{1,5}[-●]?[0-9]+[-●]?[0-9]+[-●]?[0-9X]$"
);

export interface UserAvatarProps {
  profilePicture: string;
  onClick?: ( event: React.MouseEvent<HTMLElement> ) => void;
}


export interface ValidationProps {
  comment: string;
  contentId: number | undefined;
  userId: number | undefined;
}

export interface LightBox{
  contentLightboxId: number;
  lightboxName: string;
  coverId: number | null;
  userId: number;
  timestamp: string;
  cover: null;
}