import { createTheme } from '@material-ui/core/styles';
import { User } from '../../redux/user/user.types';
import { ContentSet, UploadedFileResponse, IndividualImageCategory, IBroadcastMessage } from './ContentUpload.types';

export interface ContentMetadataProps {
    metadata?: ImageMetadata;
    contentSetsState: ContentSet[];
    uploadedFiles: UploadedFileResponse[];
    selectedRadioButton: string;
    channels: any[];
    editSetFromAdmin: boolean;
    individualImageCategories: IndividualImageCategory[];
    currentUser: User;
    handleRejectImage?: (id: number) => void;
    handleApproveImage?: (id: number) => void;
    updateSetAction: (data: ContentSet) => void;
    addIndividualImageNavigation: (data: IndividualImageCategory) => void;
    addChannelToContent: (data: any) => void;
    broadcastUploadErrorAction: (data: IBroadcastMessage) => void;
}

export interface SelectedChannelCategory {
    selectedId: number;
    selectedChannelTitle: string;
}

export interface ImageMetadata {
    category: Category[];
    mainInformation: MainInformation; 
    location: LocationMeta;
    license: License;
    keywords: Keyword;
    copyright: Copyright;
    source: Source;
    fileDetails: FileMeta;
}

export interface Category {
    channelId: number;
    subChannelId: number;
    threadId: number;
}

export interface MainInformation {
    objectName: string;
    headline: string;
    caption: string;
    externalLink: string;
    externalLinkMessage: string;
}

export interface LocationMeta {
    city: string;
    stateProvince: string;
    country: string;
    countryCode: string;
    stateCode: string;
    latitude: number;
    longitude: number;
    zipCode: string;
}

export interface License {
    type: string;
}

export interface Keyword {
    keywordsToShow: any[];
    keywordsToAdd?: any[];
    keywordsToDelete?: any[];
}

export interface ContentSource {
    userId: number | undefined;
    companyName: string;
}
export interface Copyright {
    byLine: string;
    credit: string;
    copyrightNotice: string;
    specialInstructions: string;
    contentSource?: ContentSource;
}

export interface Source {
    source: string;
    createdDate: Date;
    createdTime: string;
    uploadedDate: Date;
    originalFileName: string;
}

export interface FileMeta {
    fileName: string;
    fileSize: string;
    fileType: string;
    imageWidth: number;
    imageHeight: number;
    cameraModel: string;
}

export enum LicensingTypes {
    STANDARD = 'standard',
    EDITORIAL = 'editorial',
    PREMIUM = 'premium'
}

export enum GroupsNames {
    KEYWORDS = 'keywords',
    COPYRIGHT = 'copyright',
    FILE_DETAILS = 'fileDetails',
    SOURCE = 'source',
    MAIN_INFO = 'mainInfo',
    LOCATION = 'location',
    LICENSING = 'licensing',
    SET_TITLE = 'setTitle',
    CATEGORY = 'category',
}
export interface SelectChannelsProps {
    disabled: boolean;
    channels: any[];
    selectedDropdownData: any;
    addToAnother: () => void;
    handleSelect: (selectedId: any, selectedChannelTitle: string, category: any, suggestedThreadTitle?: string) => void;
    broadcastUploadErrorAction: (data: IBroadcastMessage) => void;
}

export const inputFormTheme = createTheme({
    overrides: {
        MuiAccordion: {
            root: {
                backgroundColor: '#edebeb',
                margin: '5% !important',
                borderRadius: '15px !important'
            }
        },
        MuiAccordionDetails: {
            root: {
                flexDirection: 'column'
            }
        },
        MuiAccordionSummary: {
            content: {
                margin: '0 !important',
            }
        },
        MuiFormLabel: {
            root: {
                color: 'black',
            }
        },
        MuiFormControl: {
            root: {
                width: '100%'
            }
        },
        MuiInputLabel: {
            root: {
                width: '50%',
                // marginTop: '16px'
            },
            formControl: {
                position: 'relative',
                marginTop: '10px'
            }
        },
        MuiOutlinedInput: {
            root: {
                width: '100%'
            },
            input: {
                padding: '8px 12px'
            }
        },
        MuiButton: {
            root: {
                marginRight: '5px',
                marginBottom: '5px',
                backgroundColor: 'white !important',
                borderRadius: '15px'
            },
            containedSizeSmall: {
                fontSize: '0.6rem'
            }
        },
        MuiInputBase: {
            root: {
                width: '100%',

                "$:disabled" : {
                    color: '#edebeb'
                }
            }
        },
        MuiInput: {
            formControl: {
                marginTop: '0px !important'
            },
            underline: {
                '&:after': {
                    borderBottom: '2px solid rgb(12, 175, 149)'
                }
            }
        },
        MuiCard: {
            root: {
                maxWidth: '100%',
                maxHeight: '100%',
                margin: '5%'
            }
        },
        MuiBreadcrumbs: {
            ol: {
                fontSize: '0.8em'
            }
        },
        MuiChip: {
            root: {
                maxWidth: '-webkit-fill-available',
                height: 'auto'
            },
            label: {
                whiteSpace: 'normal'
            }
        }
    }
});

export const CHANNEL_TITLE_REGEX = /^((?![\^\~]).)*$/;