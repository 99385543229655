import axios from 'axios';
import { IFileWithMeta } from 'react-dropzone-uploader';
import { UploadedFileResponse } from './ContentUpload.types';

export const uploadToBucket = async (index: string, uploadedFile: IFileWithMeta, userId: number, url: string, config: any): Promise<UploadedFileResponse> => {
    const formData = new FormData();
    
    const operation = {
        "query": `mutation singleUploadMutation($file: Upload!, $userId: String!, $index: String!) {
            singleUpload(file: $file, userId: $userId, index: $index) {
                id
                filename
                mimetype
                encoding
                key
                url
                code
                index
            }
          }`, "variables": { "file": null, "userId": userId.toString(), "index": index }
    } 

    const map = {"0":["variables.file"]};

    formData.append('operations', JSON.stringify(operation));
    formData.append('map', JSON.stringify(map));
    formData.append('0', uploadedFile.file);

    return axios({
        method: 'POST',
        url: url,
        data: formData,
        headers: config
    })
    .then((response: any) => { 
        if(response.data.errors) {
            return Promise.reject(response.data.errors[0].message);
        }

        const responseProps = response.data.data.singleUpload;

        const fileResponse: UploadedFileResponse = {
            id: responseProps.id,
            filename: responseProps.filename,
            mimetype: responseProps.mimetype,
            encoding: responseProps.encoding,
            key: responseProps.key,
            url: responseProps.url,
            code: responseProps.code,
            blobObject: uploadedFile.file,
            index: responseProps.index
        }
        //UploadedFileResponse
        return Promise.resolve(fileResponse);
    })
    .catch((error: any) => {
        return Promise.reject(error);
    });
}

export const uploadToBucketMultiple = async (uploadedFiles: IFileWithMeta[], userId: number, url: string, config: any) : Promise<UploadedFileResponse[]> => {
    const formData = new FormData();

    const defaultFilesArray: File[] = new Array(uploadedFiles.length).fill(null);

    const operation = {
        "query": `mutation multipleFileUploadMutation($userId: String!, $files: [Upload!]!) {
            multipleFileUpload(userId: $userId, files: $files) {
                id
                filename
                mimetype
                encoding
                key
                url
                code
            }
          }`, "variables": {  "userId": userId.toString(), "files": defaultFilesArray }
    }

    formData.append('operations', JSON.stringify(operation));

    // TODO: simplify
    let map = new Map();

    uploadedFiles.forEach((file, index) => {
        map.set(`${index}`, [`variables.files.${index}`]);
    })

    let mapAsObject = Array.from(map).reduce((obj, [key, value]) => (
        Object.assign(obj, { [key]: value })
    ), {});

    formData.append('map', JSON.stringify(mapAsObject));

    uploadedFiles.forEach((file, index) => {
        formData.append(`${index}`, file.file);
    })

    return axios({
        method: 'POST',
        url: url,
        data: formData,
        headers: config
    })
        .then((response: any) => {
            const responseProps = response.data.data.multipleFileUpload;
            const fileResponses: UploadedFileResponse[] = [];

            responseProps.forEach((uploadedFileResponse: UploadedFileResponse, index: number) => {
                const fileResponse: UploadedFileResponse = {
                    id: uploadedFileResponse.id,
                    filename: uploadedFileResponse.filename,
                    mimetype: uploadedFileResponse.mimetype,
                    encoding: uploadedFileResponse.encoding,
                    key: uploadedFileResponse.key,
                    url: uploadedFileResponse.url,
                    code: uploadedFileResponse.code,
                    blobObject: uploadedFiles[index].file
                }
                fileResponses.push(fileResponse);
            })

            return Promise.resolve(fileResponses);
        })
        .catch((error: any) => {
            return Promise.reject(error.response);
        });
}