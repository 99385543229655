import React, { useEffect, useState } from "react";
import { MenuItem, Menu, Fade, makeStyles } from "@material-ui/core";
import { StoreState } from "../../redux/root-reducer";
import { UserActionTypes, User } from "../../redux/user/user.types";
import { Dispatch } from "redux";
import {
  ILogoutFailure,
  ILogoutSuccess,
  ISetCompletedOnboarding,
  ISetUserProfileImage,
  IStartLogout,
  TUserReducerActions,
} from "../../redux/user/user.actions";
import { connect } from "react-redux";
import { push, CallHistoryMethodAction } from "connected-react-router";
import { useMutation } from "react-apollo-hooks";
import { LOGOUT_USER } from "../home-component/queries";
import { UserNavProps } from "./navbar.types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  IResetPage,
  TPageReducerActions,
} from "../../redux/error-page/error-page.actions";
import { ErrorPagesTypes } from "../../redux/error-page/error-page.types";
import { RoleTypes } from "../../components/register/register.types";
import { IEmptyCart, TCartReducerActions } from "redux/cart/cart-page.actions";
import { CartActionTypes } from "../../redux/cart/cart-page.types";
import { OnboardingActionTypes } from "../../redux/onboarding/onboarding.types";
import {
  IResetOnboarding,
  TOnboardingReducerAction,
} from "../../redux/onboarding/onboarding.actions";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { GET_PROFILE_PICTURE } from "./queries";
import {
  IEmptySubscription,
  TSubscriptionReducerActions,
} from "../../redux/subscriptions/subscription-page.actions";
import { SubscriptionActionTypes } from "../../redux/subscriptions/subscription-page.types";
import {
  selectCurrentUser,
  selectProfileImage,
} from "../../redux/user/user.selectors";
import { UserAvatar } from "../photo-details/comments-container";
import { padding } from "@mui/system";

const useStyles = makeStyles((theme) => ({
  list: {
    display: "flex",
    flexDirection: "column",
    padding: "1rem",
  },
  paper: {
    border: "1px solid #d3d4d5",
  },
}));

const UserNav: React.FC<UserNavProps> = ({ ...props }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    currentUser,
    logoutUserAction,
    logoutSuccessAction,
    logoutFailureAction,
    redirectToHome,
    redirectToMyLicensePage,
    path,
    resetTogglesPageAction,
    emptyCartAction,
    resetOnboardingAction,
    setCompletedOnboardingAction,
    removeSubscriptions,
    userProfileImage,
    setUserProfileImageAction,
  } = props;
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorElement);

  const [fetchProfileImage, { data, loading, error }] = useLazyQuery(
    GET_PROFILE_PICTURE,
    { variables: { userId: currentUser.id }, fetchPolicy: "no-cache" }
  );

  const [logoutUserMutation] = useMutation(LOGOUT_USER);
  const logoutUser = (event: any): void => {
    event.preventDefault();

    logoutUserAction();
    logoutUserMutation()
      .then((result: any) => {
        emptyCartAction();
        removeSubscriptions();
        resetOnboardingAction();
        logoutSuccessAction();
        redirectToHome();
      })
      .catch((response: any) => {
        logoutFailureAction(response.message);
      });
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorElement(null);
    resetTogglesPageAction();
  };

  useEffect(() => {
    if (data && data.getUserProfilePicture) {
      setUserProfileImageAction(data.getUserProfilePicture);
    }
  }, [data]);

  useEffect(() => {
    fetchProfileImage();
  }, [userProfileImage]);

  return (
    <React.Fragment>
      {userProfileImage !== "" ? (
        // <div className="profile-image-container">
        //     <img src={userProfileImage}
        //         className='profile-image'
        //         onClick={handleClick}
        //     />
        // </div>
        <UserAvatar profilePicture={userProfileImage} onClick={handleClick} />
      ) : (
        <button className="navigation-profile" onClick={handleClick}>
          <FontAwesomeIcon size="2x" icon={faUser} />
        </button>
      )}
      <Menu
        id="fade-menu"
        getContentAnchorEl={null}
        anchorEl={anchorElement}
        keepMounted
        open={open}
        classes={{ paper: classes.paper, list: classes.list }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <p className="userEmail textLeft">{currentUser.email}</p>
        {currentUser.role === RoleTypes.Contributor_individual ||
        currentUser.role === RoleTypes.Contributor_agency ? (
          <div>
            <RouterLink
              className="nav-link-option"
              to="/content-upload"
              onClick={handleClose}
            >
              <p className="menuItem textLeft">{t("UserNav.Upload.Content")}</p>
            </RouterLink>
            {/*TODO: Rework the private route to check the currentUser's role before being able to open the link*/}
            <RouterLink
              className="nav-link-option"
              to="/my-content"
              onClick={handleClose}
            >
              <p className="menuItem textLeft">{t("UserNav.My.Content")}</p>
            </RouterLink> 
            <RouterLink
              className="nav-link-option"
              to="/my-sales"
              onClick={handleClose}
            >
              <p className="menuItem textLeft">{t("UserNav.My.Sales")}</p>
            </RouterLink>
          </div>
        ) : null}
        {currentUser ? (
          <RouterLink
            className="nav-link-option"
            to="/light-box"
            onClick={handleClose}
          >
            <p className="menuItem textLeft">
              {t("UserNav.LightBox")}
            </p>
          </RouterLink>
        ) : null}
        {currentUser &&
        (currentUser.role === RoleTypes.Pro_freelancer ||
          currentUser.role === RoleTypes.Pro_organization) ? (
          <RouterLink
            className="nav-link-option"
            to="/my-license-page"
            onClick={handleClose}
          >
            <p className="menuItem textLeft">
              {t("UserNav.My.Licensing.Plans")}
            </p>
          </RouterLink>
        ) : null}
         {currentUser &&
                (currentUser.role === RoleTypes.Pro_freelancer ||
                  currentUser.role === RoleTypes.Pro_organization) ? (
                   <RouterLink className="nav-link-option" to="/my-downloads">
                    <p className="menuItem textLeft">
                      {t("UserNav.Downloads")}
                    </p>
                  </RouterLink>
                ) : null}
        <p className="menuItem textLeft" onClick={logoutUser}>
          {t("UserNav.Logout")}
        </p>
      </Menu>
    </React.Fragment>
  );
};
const mapStateToProps = (
  state: StoreState
): { currentUser: User; path: string; userProfileImage: string } => {
  return {
    currentUser: selectCurrentUser(state),
    path: state.router.location.pathname,
    userProfileImage: selectProfileImage(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<
    | TUserReducerActions
    | CallHistoryMethodAction
    | TPageReducerActions
    | TCartReducerActions
    | TOnboardingReducerAction
    | TSubscriptionReducerActions
  >
) => {
  return {
    logoutUserAction: () =>
      dispatch<IStartLogout>({ type: UserActionTypes.LogoutUser }),
    logoutSuccessAction: () =>
      dispatch<ILogoutSuccess>({ type: UserActionTypes.LogoutUserSuccess }),
    logoutFailureAction: (message: string) =>
      dispatch<ILogoutFailure>({
        type: UserActionTypes.LogoutUserError,
        data: message,
      }),
    redirectToHome: () => dispatch(push("/")),
    redirectToMyLicensePage: () => dispatch(push("/my-license-page")),
    resetTogglesPageAction: () =>
      dispatch<IResetPage>({ type: ErrorPagesTypes.ResetTogglesPage }),
    emptyCartAction: () =>
      dispatch<IEmptyCart>({ type: CartActionTypes.EMPTY_CART }),
    resetOnboardingAction: () =>
      dispatch<IResetOnboarding>({
        type: OnboardingActionTypes.RESET_ONBOARDING,
      }),
    setCompletedOnboardingAction: (data: boolean) =>
      dispatch<ISetCompletedOnboarding>({
        type: UserActionTypes.SET_COMPLETED_ONBOARDING,
        data: data,
      }),
    removeSubscriptions: () =>
      dispatch<IEmptySubscription>({
        type: SubscriptionActionTypes.EMPTY_SUBSCRIPTION,
      }),
    setUserProfileImageAction: (data: string) =>
      dispatch<ISetUserProfileImage>({
        type: UserActionTypes.SET_USER_PROFILE_IMAGE,
        data: data,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserNav);
