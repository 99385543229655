import React, { useState } from "react";
import { TextField } from "@material-ui/core";
import { useMutation } from "@apollo/react-hooks";
import { POST_COMMENT } from "./queries";
import { AddCommentProps } from "./photo-details.types";
import { useTranslation } from "react-i18next";


const URL_EXLCUSION_REGEX = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/

const AddComment: React.FC<AddCommentProps> = ( { ...props } ) => {
  const { contentId, userId, refetch } = props;
  const [showAddComment, setShowAddComment] = useState( false );
  const [comment, setComment] = useState( "" );
  const [addComment] = useMutation( POST_COMMENT );
  const [error, setError] = useState("");
  const { t } = useTranslation();

  
  const handleOpenClose = () => {
    setShowAddComment( !showAddComment );
  };

  
  const handleChange = ( e: any ) => {
    let comment = e.target.value;
    if(URL_EXLCUSION_REGEX.test(comment))
    {
      setError(t("Add.Comment.No.Url.Allowed"))
    }
    else{
      setError("")
    }
    setComment( comment );
  };
  const handleSubmit = ( e: any ) => {
    if ( comment !== "" && error === "" && contentId && userId) {
      addComment( {
        variables: { contentId: contentId, userId: userId, comment: comment }
      } ).then( ( result: any ) => {
        refetch();
        setComment( "" );
      } );
    }
    else if (error !== t("Add.Comment.No.Url.Allowed") && contentId && userId ) {
      setError(t("Add.Comment.Rquired"))
    }
    else {
      setError("");
    }
  };

  return (
    <div className="add-comment-box">
      <div className="add-comment-form">
        <TextField
          id="standard-basic"
          onChange={handleChange}
          value={comment}
          multiline
          maxRows={10}
          variant="standard"
          label="Write your comment..."
          helperText = {error}
        />
        <button onClick={handleSubmit} className="post-comment">
          Send
        </button>
      </div>
    </div>
  );
};
export default AddComment;
