import React, { useEffect, useState } from 'react';
import { Accordion, AccordionSummary, Button, AccordionActions } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useTranslation } from "react-i18next";
import './onboarding-page.styles.scss'
import EditorsAccordionComponent from './editors-accordion.component';
import { buttonStyles, EditorsAccordion, EditorsInformationProps } from './onboarding-page.types';
import { EditorsInformation, OnboardingActionTypes } from '../../redux/onboarding/onboarding.types';
import { IBroadcastOnboardingError, IClearOnboardingBroadcast, IEditorsInformation, IRemoveEditor, IToggleAboutYouAccordion, IValidEditorsAccordion, TOnboardingReducerAction } from '../../redux/onboarding/onboarding.actions';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { StoreState } from '../../redux/root-reducer';
import { selectEditorsAccordionState, selectEditorsInformation, selectIsValidEditorsAccordion, selectOnboardingError } from '../../redux/onboarding/onboarding.selectors';
import { v4 as uuidv4 } from 'uuid';
import { IBroadcastMessage, SeveritySnackbarEnum } from '../../components/batch-upload/ContentUpload.types';
import { SnackbarComponent } from "shared";

const EditorsListComponent: React.FC<EditorsInformationProps> = ({ ...props }) => {
    const { editorsInformation, onboardingError, toggleEditorsAccordion, isValidEditorsAccordion, toggleAboutYouAccordionAction,
        deleteEditorAction, validateEditorsAccordionAction, saveEditorsInformationAction,
        broadcastOnboardingErrorAction, clearOnboardingBroadcastAction } = props;
    const [open, setOpen] = useState(false);
    const [editorsAccordions, setEditorsAccordions] = useState<EditorsAccordion[]>([]);
    const [isAddEditorClicked, setIsAddEditorClicked] = useState<boolean>(false);
    const [isSaveButtonClicked, setIsSaveButtonClicked] = useState<boolean>(false);
    const [editors, setEditors] = useState<EditorsInformation[]>([]);
    const [isDeleteClicked, setIsDeleteClicked] = useState<boolean>(false);
    const [showOnboardingSnackbar, setShowOnboardingSnackbar] = useState(false);

    const { t } = useTranslation();
    const classes = buttonStyles();

    useEffect(() => {
        if (toggleEditorsAccordion) {
            setOpen(true);
        }
    }, [toggleEditorsAccordion]);

    //creates the first accordion on initial render, needed so we can add the accordion in the array of accordion componnets and create an id for it
    useEffect(() => {
        if(editorsInformation && editorsInformation.length> 0) {
            renderFilledInformationInAccordions();
        }
        else {
            let newAccordion: EditorsAccordion = {
                id: uuidv4(),
                validatedData: false,
                isLastAddedBeforeSave: false,
            };
            setEditorsAccordions([...editorsAccordions, newAccordion]);
        }
        
    }, []);

    useEffect(() => {
        if (isValidEditorsAccordion && !isSaveButtonClicked) {
            updateAccordionsToValidated();
            addEditor();
            setIsAddEditorClicked(false);
        }
        if (isValidEditorsAccordion && isSaveButtonClicked) {
            updateAccordionsToValidated();
            setOpen(false);
            toggleAboutYouAccordionAction();
        }
        validateEditorsAccordionAction(false);

    }, [isValidEditorsAccordion]);

    useEffect(() => {
        if (onboardingError.severity && onboardingError.message) {
            setShowOnboardingSnackbar(true);
            setIsAddEditorClicked(false);
            setIsSaveButtonClicked(false);
            setIsDeleteClicked(false);
        }
    }, [onboardingError]);

    useEffect(() => {
        if (editors && isSaveButtonClicked && !isDeleteClicked) {
            saveEditorsInformationAction(editors);
            setIsSaveButtonClicked(false);
            setIsAddEditorClicked(false);
        }
    }, [editors]);

    useEffect(() => {
        if (isDeleteClicked) {
            renderAccordion();
            broadcastOnboardingErrorAction({
                severity: SeveritySnackbarEnum.success,
                message: "Editor deleted successfully."
            });
            setOpen(false);
        }
    }, [editorsAccordions]);

    const handleClick = () => {
        setOpen(!open);
    };

    const handleSaveButton = () => {
        setIsSaveButtonClicked(true);
    }

    const updateAccordionsToValidated = () => {
        editorsAccordions.map((accordion: EditorsAccordion) => {
            return accordion.validatedData = true;
        })

        if (isSaveButtonClicked) {
            editorsAccordions[editorsAccordions.length - 1].isLastAddedBeforeSave = true;
        }
    }

    const deleteAccordion = (accordionToDeleteId: string) => {
        const newAccordions = editorsAccordions.filter((accordion: EditorsAccordion) => {
            return accordion.id !== accordionToDeleteId;
        })
        setEditorsAccordions(newAccordions)
    }

    const deleteEditorFromStateAndRedux = (id: string) => {
        if (editorsInformation.length > 0) {
            deleteEditorAction(id);
        }
        const newEditors = editors.filter((editor) => {
            return editor.id !== id;
        });

        setEditors(newEditors);
    }

    const handleDeleteEditor = (id: string) => {
        if (editorsAccordions.length === 1) {
            setIsDeleteClicked(false);
        } else {
            setIsDeleteClicked(true);
            deleteAccordion(id);
            deleteEditorFromStateAndRedux(id);
        }

    }

    //creates the next accordions on click of 'Add another' button
    const addEditor = () => {
        let newAccordion: EditorsAccordion = {
            id: uuidv4(),
            validatedData: false,
            isLastAddedBeforeSave: false,
        };
        setEditorsAccordions([...editorsAccordions, newAccordion]);
        setIsSaveButtonClicked(false);
        setIsAddEditorClicked(false);
    }

    const handleAddEditorButton = () => {
        setIsAddEditorClicked(true);
    }

    const addNewEditorInState = (newEditor: EditorsInformation) => {
        setEditors([...editors, newEditor]);
    }

    const handleClose = () => {
        setShowOnboardingSnackbar(false);
        clearOnboardingBroadcastAction();
    };

    const renderAccordion = () => {
        return editorsAccordions.map((accordion: EditorsAccordion) => {
            return <EditorsAccordionComponent
                accordion={accordion}
                key={accordion.id}
                isAddEditorButtonCLicked={isAddEditorClicked}
                isSaveButtonClicked={isSaveButtonClicked}
                handleDelete={handleDeleteEditor}
                addNewEditor={addNewEditorInState}
                addEditorAccordion={addEditor} />
        })
    };

    const renderFilledInformationInAccordions = () => {
        const accordions = editorsInformation.map((editor) => {
            const newAccordion: EditorsAccordion = {
                id: editor.id,
                validatedData: false,
                isLastAddedBeforeSave: false,
            }
            return newAccordion;
        });

        setEditorsAccordions(accordions);
        setEditors(editorsInformation);
    }

    return (
        <React.Fragment>
            <Accordion expanded={open}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1c-content"
                    id="panel1c-header"
                    onClick={handleClick}
                >
                    <div className="title">{t("Onboarding.Editors.Headline")}</div>
                </AccordionSummary>
                {
                    renderAccordion()
                }
                <AccordionActions style={{ justifyContent: 'center' }}>
                    <Button size="small" variant="contained" type='submit'
                        className='white-round-button' onClick={handleAddEditorButton}>
                        {t('Onboarding.AddEditor.Button.Label')}
                    </Button>
                    <Button classes={{ root: classes.greenButtonRoot }} onClick={handleSaveButton}>
                        {t("Onboarding.StepOne.Save.button")}
                    </Button>
                </AccordionActions>
            </Accordion>
            <SnackbarComponent showSnackbar={showOnboardingSnackbar} handleClose={handleClose}
                severity={onboardingError.severity}
                message={onboardingError.message} />
        </React.Fragment>
    );
};

const mapStateToProps = (state: StoreState): { toggleEditorsAccordion: boolean, isValidEditorsAccordion: boolean, onboardingError: IBroadcastMessage, editorsInformation: EditorsInformation[] } => {
    return {
        editorsInformation: selectEditorsInformation(state),
        toggleEditorsAccordion: selectEditorsAccordionState(state),
        isValidEditorsAccordion: selectIsValidEditorsAccordion(state),
        onboardingError: selectOnboardingError(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch<TOnboardingReducerAction>) => {
    return {
        deleteEditorAction: (data: string) => dispatch<IRemoveEditor>({ type: OnboardingActionTypes.REMOVE_EDITOR, data: data }),
        toggleAboutYouAccordionAction: () => dispatch<IToggleAboutYouAccordion>({ type: OnboardingActionTypes.TOGGLE_ABOUT_YOU }),
        validateEditorsAccordionAction: (data: boolean) => dispatch<IValidEditorsAccordion>({ type: OnboardingActionTypes.VALIDATE_EDITORS_ACCORDION, data: data }),
        broadcastOnboardingErrorAction: (data: IBroadcastMessage) => dispatch<IBroadcastOnboardingError>({ type: OnboardingActionTypes.BROADCAST_ONBOARDING_ERROR, data: data }),
        clearOnboardingBroadcastAction: () => dispatch<IClearOnboardingBroadcast>({ type: OnboardingActionTypes.CLEAR_ONBOARDING_BROADCAST }),
        saveEditorsInformationAction: (data: EditorsInformation[]) => dispatch<IEditorsInformation>({ type: OnboardingActionTypes.EDITORS_INFORMATION, data: data })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(EditorsListComponent);