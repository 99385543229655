import React, { useEffect, useState } from 'react';
import { CartStepSubscriptionsProps } from './cart-page.types';
import OrderSummary from './cart-order-summary.component';
import { Box, Grow } from '@material-ui/core';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IBroadcastCartError, IEmptyCart, IRemoveFromCart, TCartReducerActions } from '../../redux/cart/cart-page.actions';
import { CartActionTypes, TaxForOrder } from '../../redux/cart/cart-page.types';
import { IBroadcastMessage, SeveritySnackbarEnum } from '../batch-upload/ContentUpload.types';
import { selectCartError, selectCurrentCartItems, selectCurrentCartSubtotal, selectCurrentCartTotal, selectCurrentSubItems, selectTax } from "../../redux/cart/cart-page.selector";
import { ItemForCart } from '../photo-details/photo-details.types';
import { StoreState } from '../../redux/root-reducer';
import { User } from '../../redux/user/user.types';
import { selectCurrentUser } from '../../redux/user/user.selectors';
import { CartTable } from './cart-step-one-table.component';
import { useMutation } from '@apollo/react-hooks';
import { ADD_CONTENT_ORDER, CONFIRM_CONTENT_ORDER, SEND_INVOICE, UPDATE_AMOUNT_LEFT } from './queries';
import { generateInvoiceTable, generateInvoiceTableSubscription, getContentIds, getInvoiceTemplateType, getUpdateParamsForPlan, sendInvoice } from './cart-page-helperFunctions';
import { useTranslation } from 'react-i18next';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import PulseLoader from 'react-spinners/PulseLoader';

const CartStepSubscriptions: React.FC<CartStepSubscriptionsProps> = ({ ...props }) => {
  const { cartItems, cartItemsFromSub, licensePlans, currentUser, total, subtotal, isLicensePlanInvalid, tax,
    emptyCart, removeFromCartAction, handlePageBack, handlePageChange, broadcastCartAction } = props;

  const [processing, setProcessing] = useState(false);
  const [addOrders] = useMutation(ADD_CONTENT_ORDER);
  const [updateCounts] = useMutation(UPDATE_AMOUNT_LEFT);
  const [sendInvoiceMutation] = useMutation(SEND_INVOICE);
  const [confirmOrder] = useMutation(CONFIRM_CONTENT_ORDER); 
  const [orderId, setOrderId] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    if (orderId !== 0) {
      updateLicensePlans();
    }
  }, [orderId])

  const handleSubmit = () => {
    setProcessing(true);
    checkoutSubItems();
  }

  const checkoutSubItems = () => {
    addOrders({
      variables: {
        userId: currentUser!.id,
        contents: getContentIds([], cartItemsFromSub, tax, currentUser?.role),
        totalTax: 0.0
      }
    })
    .then((result: any) => {
      if (result.data.addOrder) {
        const orderId = result.data.addOrder.id;
        //after the order is done setting we will continue from the useEffect(sometimes orderId doesnt get set on time)
        setOrderId(orderId);
      } else {
        registerOrderHandlingError();
      }
    })
    .catch((error: any) => {
      registerOrderHandlingError();
    });
  };

  const updateLicensePlans = async () => {
    const subscribtionUpdateParams = getUpdateParamsForPlan(
      cartItemsFromSub,
      licensePlans
    );

    const updatePromises = subscribtionUpdateParams.map(async planUpdateParams => {
      return await updateCounts({
        variables: {
          userLicensingPlanId: planUpdateParams.userLicensingPlanId,
          numberOfItemsBought: planUpdateParams.itemCount
        }
      });
    });

    await Promise.all(updatePromises)
      .then((result: any) => {
        //confirmOrder
        confirmOrderMutation(orderId);
      }).catch((error: any) => {
        registerOrderHandlingError();
      })
  }

  const confirmOrderMutation = (orderId: number) => {
    confirmOrder({variables: {orderId: orderId}})
      .then((result: any) => {
        if(result) {
          setTimeout(() => {
            emptyCart();
            handlePageChange();
          }, 3000);

          broadcastCartAction({severity: SeveritySnackbarEnum.success, message: `Purchase successful. An invoice regarding your order will be sent to ${currentUser.email}.`});
          //send invoice
          sendInvoiceForOrder();
        }
      }).catch((error: any) => {
        registerOrderHandlingError();
      })
  }

  const sendInvoiceForOrder = () => {
    const standardLicenseItems = generateInvoiceTable(cartItemsFromSub, tax);
    const subscriptionItems = generateInvoiceTableSubscription(cartItemsFromSub);
    const invoiceTemplateType = getInvoiceTemplateType(cartItems, cartItemsFromSub);
    
    sendInvoice(currentUser.email, standardLicenseItems, subscriptionItems, total, orderId, invoiceTemplateType, sendInvoiceMutation)
      .catch((error: any) => {
          registerInvoiceError();
      });
  }

  const registerOrderHandlingError = () => {
    broadcastCartAction({ severity: SeveritySnackbarEnum.error, message: t("Cart.Order.Unsuccessful") });
  }

  const registerInvoiceError = () => {
    broadcastCartAction({ severity: SeveritySnackbarEnum.error, message: "Failed to generate invoice for this purchase. Contact the WorldIllustrated team in order to get your invoice." });
  }

  const handleRemove = (item: ItemForCart) => {
    removeFromCartAction(item, false);
  };

  return (
    <React.Fragment>
      <Grow in={true}>
        <Box component="div" display={cartItemsFromSub.length > 0 ? "block" : "none"}>
          <div className="cart-table-container">
            <div className="cart-table-heading">
              <h2>License plan cart</h2>
            </div>
            <CartTable cartItems={cartItemsFromSub} cartIsForSub={true} subtotal={subtotal} handleRemove={handleRemove} />
          </div>
        </Box>
      </Grow>

      <div className="cart-payment-container">
        {/* ORDER SUMMARY */}
        <OrderSummary
          loading={false}
          userLicensingPlans={licensePlans}
          isSummaryForSubscriptions={true}
          isLicensePlanInvalid={isLicensePlanInvalid}
        />

        <div className="cart-step-navigation">
          <button className='back-button'
            onClick={handlePageBack}>
            <span className="button-text">
              <ArrowBackIcon /> {t("Back.Button")}
            </span>
          </button>

          {cartItems.length === 0 ?
            <button className="pay-button" disabled={processing} onClick={handleSubmit}>
              {processing ?
              <PulseLoader css={`display: block; margin: 0 auto;`} size={10} margin={3}  
                color={"#EBEBEB"} loading={processing}/>
              : 
              "Complete checkout"}          
            </button>
          : 
            <button className='next-page'
              onClick={handlePageChange}>
              <span className="button-text">
                {t("NextStep.Button")} <ArrowForwardIcon />
              </span>
            </button>
          }
        </div>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = (state: StoreState): {
  cartItems: ItemForCart[]; cartItemsFromSub: ItemForCart[];
  subtotal: number; total: number; currentUser: User; cartError: IBroadcastMessage, tax: TaxForOrder | null
} => {
  return {
    cartItems: selectCurrentCartItems(state),
    cartItemsFromSub: selectCurrentSubItems(state),
    subtotal: selectCurrentCartSubtotal(state),
    total: selectCurrentCartTotal(state),
    currentUser: selectCurrentUser(state),
    cartError: selectCartError(state),
    tax: selectTax(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch<TCartReducerActions>) => {
  return {
    removeFromCartAction: (item: ItemForCart, isPartOfPlan: boolean) => dispatch<IRemoveFromCart>({
      type: CartActionTypes.REMOVE_FROM_CART,
      data: item,
      isPartOfPlan
    }),
    emptyCart: () => dispatch<IEmptyCart>({ type: CartActionTypes.EMPTY_CART }),
    broadcastCartAction: (data: IBroadcastMessage) => dispatch<IBroadcastCartError>({
      type: CartActionTypes.BROADCAST_CART_ERROR, data: data
    })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CartStepSubscriptions);