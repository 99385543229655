import React, { useEffect, useState } from 'react';
import { SearchBarProps, SearchBarFiltersProps, SearchCategoryTypes, ContentFromOptions, ContentTypesForSearch, useStyles, composeSearchQueryUrl } from './searchBar.types';
import SearchIcon from '@material-ui/icons/Search';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from "react-i18next";
import './searchBar.styles.scss';
import { Input, IconButton, Box, Divider } from '@mui/material';
import { Dispatch } from 'redux';
import { IExpandSearchBar, IToggleSearchString, TSearchReducerActions } from '../../redux/search-bar/search-bar.actions';
import { SearchBarActions } from '../../redux/search-bar/search-bar.types';
import { connect } from 'react-redux';
import { StoreState } from '../../redux/root-reducer';
import { selectSearchBarExpanded, selectSearchString } from '../../redux/search-bar/search-bar.selectors';
import { CallHistoryMethodAction, push, replace } from 'connected-react-router';

const SearchBar: React.FC<SearchBarProps> = ({ ...props }) => {
    const { searchBarExpanded, searchString, isExpandable, path,
        toggleAdvancedSearchBarAction, toggleSearchStringAction, redirectToSearchResultsPage } = props;
    const { t } = useTranslation();
    const classes = useStyles();
    const [searchValue, setSearchValue] = useState(searchString);

    const handleChange = (event: any) => {
        event.preventDefault();
        const value = event.target.value;

        setSearchValue(value);
    }

    const toggleSearchBar = () => {
        toggleAdvancedSearchBarAction();
    }

    const onKeyPress = (event: any) => {
        if (event.keyCode === 13) {
            search(event);
        }
    }

    const resetSearchValue = (event: any) => {
        event.preventDefault();

        setSearchValue("");
        if(searchBarIsOnChannelsPage()) {
            toggleSearchStringAction("");
        }
    }

    //Changed default search type to Content only
    //TODO: Change to BOTH when feature will be released
    const search = (event: any) => {
        event.preventDefault();

        if(searchBarExpanded) {
            toggleSearchBar();
        }

        toggleSearchStringAction(searchValue);

        if (!searchBarIsOnChannelsPage() && !searchBarIsOnResultsPage()) {
            redirectToSearchResultsPage({
                searchString: searchValue,
                searchType: SearchCategoryTypes.CONTENT,
                contentFrom: ContentFromOptions.ALL_MEMBERS,
                contentType: ContentTypesForSearch.ALL,
                dateFilter: [],
                locationFilter: { city: [], stateProvince: "", country: ""},
                channelFilter: { id: 0, title: "", code: "" },
                subchannelFilter: { id: 0, title: "" },
                threadFilter: { id: 0, title: "" }
            });
        }
    }

    const searchBarIsOnResultsPage = () => {
        return path.indexOf("/results") > -1;
    }

    const searchBarIsOnChannelsPage = () => {
        return path.indexOf("/channels") > -1;
    }

    useEffect(() => {
        searchString ? setSearchValue(searchString) : setSearchValue("");
    }, [searchString])

    useEffect(()=>{
        return ()=>{
            setSearchValue("");
            if(searchBarIsOnChannelsPage()) {
                toggleSearchStringAction("");
            }
        }
    },[])
    return (
        <React.Fragment>
            <Box component="form" sx={{display: 'flex', alignItems: 'center', width: '100%' }}>
                <Input
                    sx={{ flex: 1 }}
                    inputProps={{ 'aria-label': 'search_wil' }}
                    value={searchValue}
                    classes={{underline: classes.inputUnderline}}
                    placeholder={t("Navbar.SearchBar")}
                    onKeyDown={onKeyPress}
                    onChange={handleChange}
                    startAdornment={
                        searchValue === "" ?
                        <React.Fragment>
                            <IconButton type="button" sx={{ p: '4px' }} aria-label="search" size="large">
                                <SearchIcon />
                            </IconButton>
                            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                        </React.Fragment>
                        : null
                    }
                    endAdornment={
                        isExpandable && searchValue === "" ? 
                            searchBarExpanded ?
                                <IconButton type="button" onClick={() => toggleSearchBar()} 
                                    sx={{ p: '10px' }} aria-label="advanced_search_toggle" size="large">
                                    <ExpandLessIcon />
                                </IconButton>
                            :
                                <IconButton type="button" onClick={() => toggleSearchBar()} 
                                    sx={{ p: '10px' }} aria-label="advanced_search_toggle" size="large">
                                    <ExpandMoreIcon />
                                </IconButton>
                            :
                            searchValue !== "" ?
                                <React.Fragment>
                                    <IconButton type="reset" onClick={resetSearchValue} 
                                        sx={{ p: '10px' }} aria-label="reset" size="large">
                                        <CloseIcon />
                                    </IconButton>
                                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                                    <IconButton type="submit" onClick={search} 
                                        sx={{ p: '10px' }} aria-label="search" size="large">
                                        <SearchIcon />
                                    </IconButton>
                                </React.Fragment>
                            : 
                            null
                    }
                />
            </Box>
            {/* <Autocomplete
                 id="grouped-demo"
                 options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                 groupBy={(option) => option.firstLetter}
                 getOptionLabel={(option) => option.title}
                 style={{ width: 300 }}
                 renderInput={(params) => <TextField {...params} label="With categories" variant="outlined" />}
                 />  */}
        </React.Fragment>
    )
}

const mapStateToProps = (state: StoreState): { 
    searchString: string, searchBarExpanded: boolean, path: string } => {
    return {
        searchBarExpanded: selectSearchBarExpanded(state),
        searchString: selectSearchString(state),
        path: state.router.location.pathname
    }
}

const mapDispatchToProps = (dispatch: Dispatch<TSearchReducerActions | CallHistoryMethodAction>) => {
    return {
        toggleAdvancedSearchBarAction: () => dispatch<IExpandSearchBar>({
            type: SearchBarActions.EXPAND_SEARCH_BAR
        }),
        toggleSearchStringAction: (data: string) => dispatch<IToggleSearchString>({
            type: SearchBarActions.TOGGLE_SEARCH_STRING,
            data: data
        }),
        redirectToSearchResultsPage: (data: SearchBarFiltersProps) => dispatch(push(composeSearchQueryUrl(data)))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);