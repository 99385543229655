import { makeStyles } from "@material-ui/core"

export interface LoginModalProps {
    toggleRegisterModal: boolean;
    toggleForgotPasswordModal: boolean;
    toggleLoginModal: boolean
    resetTogglesModalAction: () => void;
    toggleBanner: (hide: boolean) => void;
    toggleLogin: () => void;
    toggleRegister: (show: boolean) => void;
    toggleRegisterAsRoleModal: boolean;
    toggleRegisterAsRole: (show: boolean) => void;
}

export const dialogStyles = makeStyles(() => ({
    dialogRoot: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    dialogPaper: {
        borderRadius: '25px !important',
        overflowY: 'hidden'
    },
    closeButton:{
        padding:'0px'
    }
}))