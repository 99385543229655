import { makeStyles } from "@material-ui/core/styles";
import { SubChannel, Thread } from "../channelsSidebar/channelSideBars.types";
import { LocationFilterProps, UserSource } from "../../redux/search-bar/search-bar.types";
import { Channel } from "../home-component/home.types";
import { User, UserCoordinates } from "../../redux/user/user.types";
import { UserUploadedContent } from "../contributor-content/contributor-content.types";
import { IBroadcastMessage } from "../batch-upload/ContentUpload.types";

export enum SearchCategoryTypes {
    CONTENT = "Content",
    MEMBER_PROFILES = "Member Profiles",
    BOTH = "both"
}

export enum ContentStatus {
    pending = "pending",
    approved = "approved",
    rejected = "rejected"
}

export enum ContentTypesForSearch {
    ALL = 'AllContent',
    IMAGE= 'image',
    // VIDEO = 'video',
    AUDIO= 'audio',
    ARTICLE = 'article'
}

export interface ContributorTypeFilterProps {
  contentFromFilter: ContentFromOptions;
  isInChannelContex: boolean;
  toggleContentFromFilter: (data: ContentFromOptions) => void;
}

export enum ContentFromOptions {
  ALL_MEMBERS = "AllMembers",
  MW_USER = "MwUser",
  PRO_CONTRIBUTORS = "proContributors",
}

export enum ChannelTypes {
    CHANNEL = 'channel',
    SUBCHANNEL = 'subchannel',
    THREAD = 'thread'
}

export enum SearchFieldTypes {
    SEARCH_FIELD = "search-field",
    CONTENT_FROM_FIELD = "content-from-field",
    LOCATION_FIELD = "location-field",
    CONTENT_TYPE_FIELD = "content-type-field",
    CHANNEL_FILTER = "channel-filter",
    SUBCHANNEL_FILTER = "subchannel-filter",
    THREAD_FILTER = "thread-filter",
    DECADE_PICKER = 'decade-picker'
}

export enum SearchClearSections {
    DATE_FILTER = 'date-filter',
    LOCATIONS_FILTER = 'locations-filter',
    CONTENT_FROM_FILTER = 'content-from-filter'
}


export const getParameterByName = (name: string, url: string) => {
    const formattedName = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + formattedName + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);

    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export interface SearchBarProps {
    searchBarExpanded: boolean;
    searchString: string;
    isExpandable: boolean;
    path: string;
    toggleAdvancedSearchBarAction: () => void;
    redirectToSearchResultsPage: (data: SearchBarFiltersProps) => void;
    toggleSearchStringAction: (data: string) => void;
}

export interface SearchBarExpandedProps {
    searchString: string;
    contentFrom: number | ContentFromOptions;
    contentType: ContentTypesForSearch; 
    dateFilter: Date[]; 
    colourFilter: string;
    locationFilter: LocationFilterProps;
    channelFilter: Channel; 
    subchannelFilter: SubChannel; 
    threadFilter: Thread;
    path: string;
    selectChannelAction: (channel: Channel) => void;
    selectSubChannelAction: (subChannel: SubChannel) => void;
    selectThreadAction: (thread: Thread) => void;
    toggleSearchStringAction: (data: string) => void;
    toggleContentTypeFilter:(data: ContentTypesForSearch) => void;
    toggleContentFromFilter:(data: number | ContentFromOptions) => void;
    redirectToSearchResultsPage: (data: SearchBarFiltersProps) => void;
    resetFiltersAction: () => void;
    toggleSearchBarExpanded: () => void;
    resetDateFilterAction: () => void;
    resetLocationsFilterAction: () => void;
    resetContentFromFilterAction: () => void;
    redirectToChannel: (channel : string) => void;
    redirectToSubChannel: (channel: string, subChannel: string) => void;
    redirectToThread: (channel: string, subChannel: string, thread: string) => void;
}

export interface SearchFiltersType {
    selectedSearchCategory: SearchCategoryTypes;
}

export interface SearchBarChannelsNavigationProps {
    channelFilter: Channel; 
    subchannelFilter: SubChannel; 
    threadFilter: Thread; 
    selectedChannel: Channel; 
    selectedSubchannel: SubChannel; 
    selectedThread: Thread;
    path: string;
    toggleChannelFilterAction: (data: Channel) => void;
    toggleSubchannelFilterAction: (data: SubChannel) => void;
    toggleThreadFilterAction: (data: Thread) => void;
}

export interface SearchBarResultsProps {
    searchUrl: string;
    searchString: string;
    totalResults: number;
    contentFrom: number | ContentFromOptions; 
    contentType: ContentTypesForSearch;
    dateFilter: Date[];
    colourFilter: string; 
    locationFilter: LocationFilterProps; 
    channelFilter: Channel; 
    subchannelFilter: SubChannel; 
    threadFilter: Thread;
    selectedChannel: Channel;
    searchBarExpanded: boolean;
    searchBroadcastMessage: IBroadcastMessage;
    contentPerPage: number;
    addToCartBroadcastMessage: IBroadcastMessage;
    currentUser: User;
    toggleContentPerPage: (data: number) => void;
    loadTotalResultsAction: (data: number) => void;
    loadUserSearchResultsAction: (data: UserSource[]) => void;
    loadContentSearchResultsAction: (data: UserUploadedContent[]) => void;
    toggleSearchStringAction: (data: string) => void;
    toggleContentFromFilter:(data: number | ContentFromOptions) => void;
    toggleContentTypeFilter:(data: ContentTypesForSearch) => void;
    toggleDateFilter: (data: Date[]) => void;
    toggleLocationFilter: (data: LocationFilterProps) => void;
    toggleFilterColour: (data: string) => void;
    toggleChannelFilterAction: (data: Channel) => void;
    toggleSubchannelFilterAction: (data: SubChannel) => void;
    toggleThreadFilterAction: (data: Thread) => void;
    resetSearchResultsAction: () => void;
    redirectToSearchResultsPage: (url: string) => void;
    broadcastSearchMessage: (data: IBroadcastMessage) => void;
    clearAddToCartBroadcast: () => void;
}

export interface ResultsInfiniteLoaderProps {
    contentSearchResults: UserUploadedContent[]; 
    loadingAll: boolean;
    totalPage: number;
    pageNumber: number;
    contentPerPage: number;
    handleChange: (event: React.ChangeEvent<unknown>, value: number) => void;
    handleContentPerPageChange: (event: any) => void;
}

export interface SearchBarFiltersProps {
    searchString: string;
    searchType: SearchCategoryTypes;
    contentFrom: number | ContentFromOptions;
    contentType: ContentTypesForSearch; 
    dateFilter: Date[]; 
    //colourFilter: string;
    locationFilter: LocationFilterProps;
    channelFilter: Channel; 
    subchannelFilter: SubChannel; 
    threadFilter: Thread;
}

export interface SearchBarUrlProps {
    searchStringUrl: string;
    searchTypeUrl: string;
    contentFromUrl: string | null;
    contentTypeUrl: string | null;
    dateFilterUrl: string | null;
    countryFilterUrl: string | null;
    provinceFilterUrl: string | null;
    cityFilterUrl: string | null;
    channelFilterUrl: string | null;
    subchannelFilterUrl: string | null;
    threadFilterUrl: string | null;
}

export interface DateFilterComponentProps {
    isInResults: boolean;
    isInChannelContex: boolean;
    dateFilter: Date[];
    toggleDateFilter:(data: Date[]) => void;
    resetDateFilterAction: () => void;
}

export interface ColourFilterComponentProps {
    colourFilter: string;
    toggleColourFilter:(data: any) => void;
}

export interface LocationFilterComponentProps {
    locationFilter: LocationFilterProps;
    isInChannelContex: boolean;
    userLocationPermission: boolean;
    userCoordinates: UserCoordinates | null;
    broadcastSearchMessage: (data: IBroadcastMessage) => void;
    toggleUserLocationPopup: (data: boolean) => void;
    resetLocationsFilterAction: () => void;
    toggleLocationsFilter:(data: LocationFilterProps) => void;
}

export interface ContentTypeFilterProps {
    contentType: ContentTypesForSearch;
    isInChannelContex: boolean;
    toggleContentTypeFilter:(data: ContentTypesForSearch) => void;
}

export interface SearchResultsFiltersProps {
    contentFrom: number | ContentFromOptions; 
    contentType: ContentTypesForSearch;
    searchBarExpanded: boolean;
    isInChannelContex: boolean;
    showSearchBar: boolean;
    path: string;
    toggleContentFromFilter:(data: number | ContentFromOptions) => void;
    resetFiltersAction: () => void;
    resetFiltersForChannelsAction: () => void;
    toggleSearchBarExpanded: () => void;
}

export interface SearchBarResultsListProps {
    searchResults: any;
    selectedChannel: string;
    selectedSubChannel: string;
    selectedThread: string;
}

export interface SearchBarResultItemProps {
    photoDetails?: any;
    searchResult: any;
    redirectToPhotoDetals:(item:any, path:string) => void;
    setPhotoDetailsState:(item:any) => void;
    redirectToSubChannel :(channel:string, subchannel: string) => void;
    redirectToThread: (channel:string, subchannel: string, thread: string) => void;
    redirectToChannel: (channel : string) => void;
    selectChannelAction: (channel: string, id : number) => void;
    selectSubChannelAction: (subChannel: string, id : number) => void;
    selectThreadAction: (thread: string, id : number) => void;
    removeSubChannelAction: () => void;
    removeThreadAction: () => void;
    removeChannelAction: () => void;
    selectedChannelId: number;
    selectedThreadId: number;
    selectedSubChannelId: number;
}

export interface SearchBarResultUserProps {
    key: number;
    userObject: UserSource;
    redirectToUserProfile: (userId: number) => void;
}

export const useStyles = makeStyles((theme) => ({
    iconButtonRoot: {
        padding: '6px'
    },
    containerRoot: {
        marginLeft: "9% !important",
        marginRight: '13% !important'
    },
    collapseEntered: {
        marginBottom: '1%'
    },
    inputBaseFullWidth: {
        width: '160% !important'
    },
    buttonRoot: {
        padding: '6px 20px'
    },
    formControlLabelRoot: {
        marginLeft: 0
    },
    searchIconRoot: {
        color: "#808080",
    },
    clearButtonsLabel: {
        fontSize: '1rem'
    },
    inputUnderline: {
        "&:after": {
            borderBottom: `2px solid #0CAF95 !important`
        }
    },
}))

export const composeSearchQueryUrl = (data: SearchBarFiltersProps) => {
    const urlArray = [`/results?search_query=${data.searchString}&cont=${data.searchType}`];
    const hasContentFrom = data.contentFrom && (typeof data.contentFrom === 'number' || typeof data.contentFrom === 'string');
    const hasDateFilter = data.dateFilter && data.dateFilter.length === 2;
    const hasCountryFilter = data.locationFilter.country !== "";
    const hasProvinceFilter = data.locationFilter.stateProvince !== "";
    const hasCityFilters =  data.locationFilter.city.length > 0;
    const isSelectedChannel = data.channelFilter.id !== 0;
    const isSelectedSubchannel = data.subchannelFilter.id !== 0;
    const isSelectedThread = data.threadFilter.id !== 0;
    if(hasContentFrom) {
        urlArray.push(`&from=${data.contentFrom}`);
    }

    if(data.contentType) {
        urlArray.push(`&type=${data.contentType}`);
    }

    if(hasDateFilter) {
        urlArray.push(`&dt=${data.dateFilter[0].toISOString()}/${data.dateFilter[1].toISOString()}`);
    }

    if(hasCountryFilter) {
        urlArray.push(`&ctr=${data.locationFilter.country}`);
    }

    if(hasProvinceFilter) {
        urlArray.push(`&pr=${data.locationFilter.stateProvince}`)
    }

    if(hasCityFilters) {
        urlArray.push(`&ct=${data.locationFilter.city.join("_")}`)
    }

    if(isSelectedChannel) {
        urlArray.push(`&ch=${data.channelFilter.id}`);
    }

    if(isSelectedSubchannel) {
        urlArray.push(`&sc=${data.subchannelFilter.id}`);
    }

    if(isSelectedThread) {
        urlArray.push(`&th=${data.threadFilter.id}`);
    }


    return urlArray.join("");
}

export const decomposeSearchQueryUrl = (url: string) => {
    const result = {
        searchStringUrl: getParameterByName('search_query', url)!,
        searchTypeUrl: getParameterByName('cont', url)!,
        contentFromUrl: getParameterByName('from', url),
        contentTypeUrl: getParameterByName('type', url),
        dateFilterUrl: getParameterByName('dt', url),
        countryFilterUrl: getParameterByName('ctr', url),
        provinceFilterUrl: getParameterByName('pr', url),
        cityFilterUrl: getParameterByName('ct', url),
        channelFilterUrl: getParameterByName('ch', url),
        subchannelFilterUrl: getParameterByName('sc', url),
        threadFilterUrl: getParameterByName('th', url)
    }

    return result;
}

