import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {publicStore, history, persistedStore} from './redux/store';
import ApolloClient from 'apollo-boost';
import {ApolloProvider} from 'react-apollo';
import {ApolloProvider as ApolloHooksProvider} from 'react-apollo-hooks';
import {I18nextProvider} from "react-i18next";
import i18n from './plugins/i18n';
import {PersistGate} from 'redux-persist/integration/react';
import './index.scss';
import App from './App';
import { CookiesProvider } from 'react-cookie';
import {config} from "./config";

const client = new ApolloClient({
    uri: `${config.REACT_APP_PUBLIC_SERVER_HOST}/graphql`,
    credentials: 'include'
});
const crrUser = publicStore.getState().user.currentUser;
const userOnboarding = publicStore.getState().user.currentUser?.hasCompletedOnboarding!;
const selectedChannel = publicStore.getState().channels.selectedChannel.title;
const selectedPhotoDetails = publicStore.getState().photoDetails.item;
const selectedPhotoId = publicStore.getState().photoDetails.currentSelectedPhoto;
const searchBarExpanded = publicStore.getState().searchBar.searchBarExpanded;
const crrPathname = publicStore.getState().router.location.pathname;

ReactDOM.render(
        <ApolloProvider client={client}>
            <ApolloHooksProvider client={client}>
                <Provider store={publicStore}>
                    <PersistGate loading={null} persistor={persistedStore}>
                        <I18nextProvider i18n={i18n}>
                            <CookiesProvider>
                                <App history={history} currentUser={crrUser} selectedChannel={selectedChannel} userHasCompletedOnboarding={userOnboarding}
                                    item={selectedPhotoDetails} selectedItemId={selectedPhotoId} searchBarExpanded={searchBarExpanded} currentPathname={crrPathname}/>
                            </CookiesProvider>
                        </I18nextProvider>
                    </PersistGate>
                </Provider>
            </ApolloHooksProvider>
        </ApolloProvider>,
    document.getElementById('root'),
);