import React, { useEffect, useState } from "react";
import { InputLabel, Select, MenuItem, TextField, InputAdornment, IconButton } from "@material-ui/core";
import { CHANNEL_TITLE_REGEX, SelectChannelsProps } from "./EditContentMetadata.types";
import { GET_SUBCHANNELS, GET_THREADS } from "../channelsSidebar/queries";
import "./EditContentMetadata.styles.scss";
import { useLazyQuery } from "@apollo/react-hooks";
import { useTranslation } from "react-i18next";
import DoneIcon from "@material-ui/icons/Done";
import { SeveritySnackbarEnum } from "./ContentUpload.types";
import { checkIfThreadExistsInSubchannnel } from "./helperFunctions";
import { GET_ALL_THREADS } from "./queries";

const SelectChannelsComponent: React.FC<SelectChannelsProps> = ({ ...props }) => {
    const { disabled, channels, selectedDropdownData, handleSelect, broadcastUploadErrorAction, addToAnother } = props;
    const { t } = useTranslation();
    const enterKeyCode = 13;
    const [channelQuery, setChannelsQuery] = useState(GET_SUBCHANNELS);
    const [dataQuery, { data, loading, error }] = useLazyQuery(channelQuery);
    const [channelData, setChannelData] = useState({
        channels: channels,
        subchannels: [],
        threads: []
    });

    const [allThreads, setAllThreads] = useState([]);

    const [fetchAllThreads, { data: threadData, loading: loadingThreds, error: threadsError }] = useLazyQuery(GET_ALL_THREADS);

    const [suggestedThreadTitle, setSuggestedThreadTitle] = useState<string>("");
    const [anchorElem, setAnchorElem] = useState<null | HTMLElement>(null);

    useEffect(() => {
        if (data && data.getAllSubChannelsByChannelId) {
            setChannelData({ ...channelData, subchannels: data.getAllSubChannelsByChannelId });
        }
        else if (data && data.getAllThreadsBySubChannelId) {
            setChannelData({ ...channelData, threads: data.getAllThreadsBySubChannelId });
        }

    }, [data]);

    useEffect(() => {
        if (threadData && threadData.getThreadsBySubchannelId) {
            setAllThreads(threadData.getThreadsBySubchannelId);
        }
    }, [threadData])

    const handleSelectChange = (event: any) => {
        event.preventDefault();
        const selectedId = parseInt(event.target.value, 10);
        const selectedField = event.target.name;

        if (selectedField === 'channel') {
            setChannelsQuery(GET_SUBCHANNELS);
        } else if (selectedField === 'subchannel') {
            setChannelsQuery(GET_THREADS);
            fetchAllThreads({ variables: { subchannelId: selectedId } })

        }

        if (selectedField !== 'thread' && selectedField !== 'newThread') {
            dataQuery({ variables: { id: selectedId } });
        }
    };

    const handleChange = (event: any) => {
        event.preventDefault();
        if (event.target.name === "newThread") {
            setSuggestedThreadTitle(event.target.value);
        }
    };

    const handleClickDone = () => {
        const isThreadExistingInSubchannel = checkIfThreadExistsInSubchannnel(allThreads, suggestedThreadTitle);

        if (!isThreadExistingInSubchannel && suggestedThreadTitle.match(CHANNEL_TITLE_REGEX)) {
            handleSelect(-1, suggestedThreadTitle, "thread");
            setAnchorElem(null);
            setSuggestedThreadTitle('');
            addToAnother();
            broadcastUploadErrorAction({
                severity: SeveritySnackbarEnum.success,
                message: "New thread added successfully"
            })
        } else if (isThreadExistingInSubchannel && isThreadExistingInSubchannel.status === 'pending' && suggestedThreadTitle.match(CHANNEL_TITLE_REGEX)) {
            handleSelect(isThreadExistingInSubchannel.id, suggestedThreadTitle, "thread");
            setAnchorElem(null);
            setSuggestedThreadTitle('');
            addToAnother();
            broadcastUploadErrorAction({
                severity: SeveritySnackbarEnum.success,
                message: "New thread added successfully"
            })
        }
        else {
            broadcastUploadErrorAction({
                severity: SeveritySnackbarEnum.error,
                message: "This thread already exists in this subchannel or contains symbols like ^ or ~"
            })
            setSuggestedThreadTitle('');
        }

    }

    const onKeyPressEnter = (event: any) => {
        if (event.keyCode == enterKeyCode) {
            handleClickDone();
        }
    }

    useEffect(() => {
        if (selectedDropdownData.thread.selectedId === -1) {
            addToAnother();
        }
    }, [selectedDropdownData.thread]);


    const toggleSuggestThreadTextField = (
        event: React.MouseEvent<HTMLDivElement>
    ) => {
        setAnchorElem(anchorElem ? null : event.currentTarget);
    };

    return (
        <div className='accordion-details'>
            <div className='form-field-row'>
                <InputLabel htmlFor="channel-input" shrink={true}> {t("Enter.Channel")}</InputLabel>
                <Select variant='standard' name="channel" onChange={handleSelectChange} disabled={disabled}
                    value={selectedDropdownData.channel.selectedId !== 0 ? selectedDropdownData.channel.selectedId : ''}>
                    {channels.map((channel: any) => (
                        <MenuItem key={channel.id} value={channel.id} onClick={() => handleSelect(channel.id, channel.title, "channel")}>{t('Channel.' + channel.code)}</MenuItem>
                    ))}
                </Select>
            </div>
            <div className='form-field-row'>
                <InputLabel htmlFor="subchannel-input" shrink={true}>{t("Enter.Subchannel")}{" "}</InputLabel>
                <Select variant='standard' name="subchannel" onChange={handleSelectChange} disabled={disabled}
                    value={selectedDropdownData.subchannel.selectedId !== 0 ? selectedDropdownData.subchannel.selectedId : ''}>
                    {channelData.subchannels.map((subchannel: any) => (
                        <MenuItem key={subchannel.id} value={subchannel.id} onClick={() => handleSelect(subchannel.id, subchannel.title, "subchannel")}>{subchannel.title}</MenuItem>
                    ))}
                </Select>
            </div>
            <div className='form-field-row'>
                <InputLabel htmlFor="thread-input" shrink={true}> {t("Enter.Thread")}{" "}</InputLabel>
                <Select variant='standard' name="thread" onChange={handleSelectChange} disabled={disabled} open={Boolean(anchorElem)} onClick={toggleSuggestThreadTextField}
                    value={selectedDropdownData.thread.selectedId !== 0 ? selectedDropdownData.thread.selectedId : ''}>
                    {channelData.threads.map((thread: any) => (
                        <MenuItem key={thread.id} value={thread.id} onClick={() => handleSelect(thread.id, thread.title, "thread")}>{thread.title}</MenuItem>
                    ))}
                    {selectedDropdownData.channel.selectedId !== 0 && selectedDropdownData.subchannel.selectedId !== 0 ?
                        <MenuItem onKeyDown={(e) => e.stopPropagation()}>
                            <TextField
                                autoComplete="off"
                                variant="standard"
                                name="newThread"
                                value={suggestedThreadTitle}
                                placeholder={t('NewThread.Placeholder')}
                                data-shrink={false}
                                onKeyDown={onKeyPressEnter}
                                onClick={(e) => e.stopPropagation()}
                                onChange={handleChange}
                                InputProps={{
                                    endAdornment:
                                        suggestedThreadTitle.length > 0 ? (
                                            <InputAdornment position="end">
                                                <IconButton onClick={handleClickDone} disabled={suggestedThreadTitle.length < 3 || suggestedThreadTitle.length > 150}>
                                                    <DoneIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ) : null
                                }}
                            />
                        </MenuItem> : null
                    }

                </Select>
            </div>
            <hr />
        </div>
    )
}

export default SelectChannelsComponent;
