import { makeStyles } from "@material-ui/core";
import { Channel } from "../home-component/home.types";

export interface TrendingProps {
  colors?: any;
  selectChannelAction: (channel: Channel) => void;
  selectSubChannelAction: (subChannel: Channel) => void;
  selectThreadAction: (thread: Channel) => void;
  redirectToThread: (channel: string, subChannel: string, thread: string) => void;
}

export const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}))
