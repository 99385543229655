import { StoreState } from "redux/root-reducer";
import { createSelector } from "reselect";

const selectOnboarding = (state: StoreState) => state.onboarding;

export const selectGeneralInformationPerson = createSelector(
    [selectOnboarding],
    (onboarding) => onboarding.generalInformationPerson
)

export const selectGeneralInformationCompany = createSelector(
    [selectOnboarding],
    (onboarding) => onboarding.generalInformationCompany
)

export const selectResponsiblePersonInfromation = createSelector(
    [selectOnboarding],
    (onboarding) => onboarding.personResponsibleForAccount
)

export const selectLicensingInformation = createSelector(
    [selectOnboarding],
    (onboarding) => onboarding.licensing
)

export const selectMailingAddressInformation = createSelector(
    [selectOnboarding],
    (onboarding) => onboarding.mailingAddress
)

export const selectBillingAddressInformation = createSelector(
    [selectOnboarding],
    (onboarding) => onboarding.billingAddress
)

export const selectEditorsInformation = createSelector(
    [selectOnboarding],
    (onboarding) => onboarding.editorsInformation
)

export const selectAppropriateChannels = createSelector(
    [selectOnboarding],
    (onboarding) => onboarding.appropriateChannels
)

export const selectAboutYouInformation = createSelector(
    [selectOnboarding],
    (onboarding) => onboarding.aboutYou
)

export const selectDroppedFile = createSelector(
    [selectOnboarding],
    (onboarding) => onboarding.profileImage
)

export const selectResponsiblePersonAccordionState = createSelector(
    [selectOnboarding],
    (onboarding) => onboarding.accordionsState.toggleResponsiblePerson
)

export const selectMailingAddressAccordionState = createSelector(
    [selectOnboarding],
    (onboarding) => onboarding.accordionsState.toggleMailingAddress
)

export const selectBillingAddressAccordionState = createSelector(
    [selectOnboarding],
    (onboarding) => onboarding.accordionsState.toggleBillingAddress
)

export const selectLicensingAccordionState = createSelector(
    [selectOnboarding],
    (onboarding) => onboarding.accordionsState.toggleLicensing
)

export const selectEditorsAccordionState = createSelector(
    [selectOnboarding],
    (onboarding) =>onboarding.accordionsState.toggleEditorsInformation
)

export const selectAppropriateChannelsAccordionState = createSelector(
    [selectOnboarding],
    (onboarding) => onboarding.accordionsState.toggleAppropriateChannels
)

export const selectAboutYouAccordionState = createSelector(
    [selectOnboarding],
    (onboarding) => onboarding.accordionsState.toggleAboutYou
)

export const selectIsUseMailingAddressCheckedState = createSelector(
    [selectOnboarding],
    (onboarding) => onboarding.isUseMailingAddressChecked
)

export const selectIsOnboardingValid = createSelector(
    [selectOnboarding],
    (onboarding) => onboarding.isOnboardingValid
)

export const selectIsValidEditorsAccordion = createSelector(
    [selectOnboarding],
    (onboarding) => onboarding.isValidEditorAccordion
)

export const selectOnboardingError = createSelector(
    [selectOnboarding],
    (onboarding) => onboarding.onboardingError
)