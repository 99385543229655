import React from "react";
import ChannelScreenComponent from "../channelScreen/channelScreen";
import ChannelsSidebar from "../channelsSidebar/channelsSidebar";
import { ChannelContextProps } from "./channels.types";
import SearchResultsFilters from '../search-bar/searchResultsFilters';
import './channelsNavigation.styles.scss'
import { StoreState } from "redux/root-reducer";
import { connect } from "react-redux";
import ErrorPageComponent from '../error-page/errorPage.component';
import { selectWrongPathPage } from "../../redux/error-page/error-page.selectors";

const ChannelContext: React.FC<ChannelContextProps> = ({ ...props }) => {
    const { routeParams ,toggleWrongPathPage } = props;

    return (
        <div>
            {
                !toggleWrongPathPage ?
                    <div>
                        <ChannelScreenComponent isInChannelScreen={true} routeParams={routeParams} />
                        <SearchResultsFilters showSearchBar={false} isInChannelContex={true} />
                        <ChannelsSidebar routeParams={routeParams} />
                    </div>
                    :
                    <ErrorPageComponent />
            }

        </div>
    )
}

const mapStateToProps = (state: StoreState): { toggleWrongPathPage: boolean } => {
    return {
        toggleWrongPathPage: selectWrongPathPage(state)
    }
}

export default connect(mapStateToProps, null)(ChannelContext);