import gql from 'graphql-tag';
import { EmailDto } from '../cart/cart-page.types'

export const FORGOT_PASSWORD = gql`
    mutation forgotPasswordMutation($details: EmailDto!) {
        forgotPassword(details: $details)
    }
`;

export const CHANGE_PASSWORD = gql`
    mutation changePasswordMutation($token: String!, $password: String!) {
        changePassword(data: {token: $token, password: $password}) {
            email
        }
    }
`;