import React from "react";
import { CartStepOneProps } from "./cart-page.types";
import { Box, Typography, Grow } from '@material-ui/core';
import { StoreState } from "redux/root-reducer";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  selectCartItemsCount,
  selectCurrentCartItems,
  selectCurrentCartSubtotal,
  selectCurrentSubItems
} from "../../redux/cart/cart-page.selector";
import {
  TCartReducerActions,
  IRemoveFromCart,
  IEmptyCart,
  IBroadcastCartError
} from "../../redux/cart/cart-page.actions";
import { CartActionTypes } from "../../redux/cart/cart-page.types";
import { ItemForCart } from "../photo-details/photo-details.types";
import { useTranslation } from "react-i18next";
import { CartTable } from "./cart-step-one-table.component";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { IBroadcastMessage, SeveritySnackbarEnum } from "../batch-upload/ContentUpload.types";

const CartStepOne: React.FC<CartStepOneProps> = ({ ...props }) => {
  const {
    cartItems,
    cartItemsFromSub,
    cartItemsCount,
    subtotal,
    userHasValidLicensePlans,
    handlePageChange,
    removeFromCartAction,
    broadcastCartAction,
    emptyCart
  } = props;
  const { t } = useTranslation();
  const handleRemove = (item: ItemForCart) => {
    removeFromCartAction(item, false);
  };

  const handleEmptyCart = () => {
    emptyCart();
    broadcastCartAction({ severity: SeveritySnackbarEnum.success, message: "Emptied cart" });
  }

  return (
    <React.Fragment>
      {/* Table for Editorial content purchase */}
      <Grow in={true}>
        <Box component="div" display={cartItems.length === 0 && cartItemsFromSub.length > 0 ? "none" : "block"}>
          <div className="cart-table-container">
            <div className="cart-table-heading">
              <h2>Cart</h2>
            </div>
            <CartTable cartItems={cartItems} handleRemove={handleRemove} subtotal={subtotal}/>
          </div>
        </Box>
      </Grow>

      {/* Error in case active license plans are invalid */}
      {!userHasValidLicensePlans ? 
        <Typography variant="h6" color="error">
          Cannot continue to checkout due to invalid license plans for the selected content.
          Consider updating your license plans in order to continue with this order.
          You can check our license plans from <a href="/my-license-page">My license plans</a>
        </Typography>
        : null
      } 

      {/* Table for Subscription plans */}
      <Grow in={true}>
        <Box component="div" display={cartItemsFromSub.length > 0 ? "block" : "none"}>
          <div className="cart-table-container">
            <div className="cart-table-heading">
              <h2>License plan cart</h2>
            </div>
            <CartTable cartItems={cartItemsFromSub} cartIsForSub={true} subtotal={subtotal} handleRemove={handleRemove} />
          </div>
        </Box>
      </Grow>

      {cartItemsCount !== 0 && userHasValidLicensePlans ?
        <div className="cart-step-navigation">
            <button onClick={handleEmptyCart} className="empty-cart">
              Empty cart
            </button>

            <button className='next-page'
              onClick={handlePageChange}>
              <span className="button-text">
                {t("NextStep.Button")} <ArrowForwardIcon />
              </span>
            </button>
        </div>
      : null}
    </React.Fragment>
  );
};
const mapStateToProps = (state: StoreState): { cartItems: ItemForCart[]; cartItemsFromSub: ItemForCart[]; subtotal: number; 
  cartItemsCount: number; } => {
  return {
    cartItems: selectCurrentCartItems(state),
    cartItemsFromSub: selectCurrentSubItems(state),
    subtotal: selectCurrentCartSubtotal(state),
    cartItemsCount: selectCartItemsCount(state)
  };
};
const mapDispatchToProps = (dispatch: Dispatch<TCartReducerActions>) => {
  return {
    broadcastCartAction: (data: IBroadcastMessage) => dispatch<IBroadcastCartError>({
      type: CartActionTypes.BROADCAST_CART_ERROR, data: data
    }),
    removeFromCartAction: (item: ItemForCart, isPartOfPlan: boolean) =>
      dispatch<IRemoveFromCart>({
        type: CartActionTypes.REMOVE_FROM_CART,
        data: item,
        isPartOfPlan
      }),
    emptyCart: () => dispatch<IEmptyCart>({ type: CartActionTypes.EMPTY_CART })
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CartStepOne);
