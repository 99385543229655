import React, { useEffect, useState } from "react";
import CookieConsent from "react-cookie-consent";
import { useTranslation } from "react-i18next";
import { CookieConsentComponentProps } from "./coockie-consent.types";
import { UPDATE_COOKIE_STATUS } from "./queries";
import {
  IBroadcastMessage,
  SeveritySnackbarEnum,
} from "../batch-upload/ContentUpload.types";
import { useMutation } from "react-apollo";
import { StoreState } from "../../redux/root-reducer";
import { User } from "../../redux/user/user.types";
import { selectCurrentUser } from "../../redux/user/user.selectors";
import {
  IBroadcastContentUploadMessage,
  TContentUploadActions,
} from "../../redux/content-upload/content-upload.actions";
import { ContentUploadActionTypes } from "../../redux/content-upload/content-upload.types";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import "./cookie-consent.styles.scss";
import { useCookies } from "react-cookie";

const CookieConsentComponent: React.FC<CookieConsentComponentProps> = ({
  ...props
}) => {
  const { currentUser, broadcastCookieAction } = props;
  const [cookieName, setCookieName] = useState(
    "World_illustrated_Accept_Cookies_Tracker"
  );
  const [cookies, setCookie, removeCookie] = useCookies();
  const [cookieLife, setCookieLife] = useState(90000);

  const [updateUserCookieStatusMutation] = useMutation(UPDATE_COOKIE_STATUS);
  const updateUserCookieStatus = () => {
    if (currentUser && currentUser.email !== "") {
      updateUserCookieStatusMutation({ variables: { userId: currentUser.id } })
        .then((result: any) => {})
        .catch((error: any) => {
          broadcastCookieAction({
            severity: SeveritySnackbarEnum.error,
            message: error,
          });
        });
    }
  };

  const calculateExp = (days: number) => {
    let date = new Date();
    date.setDate(date.getDate() + days);
    return date;
  };

  const { t } = useTranslation();

  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept"
      cookieName={cookieName}
      containerClasses="cookie-container"
      buttonClasses="pill-button"
      overlay={true}
      expires={cookieLife}
      onAccept={() => {
        setCookie(cookieName, "", {
          path: "/",
          expires: calculateExp(cookieLife),
        });
        updateUserCookieStatus();
      }}
    >
      {t("Cookie.Consent.Text.Part.One")}{" "}
      {/* <span style={{ fontSize: "10px" }}>{t("Cookie.Consent.Text.Part.Two")}</span> */}
    </CookieConsent>
  );
};

const mapStateToProps = (
  state: StoreState
): {
  currentUser: User;
} => {
  return {
    currentUser: selectCurrentUser(state),
  };
};
const mapDispatchToProps = (dispatch: Dispatch<TContentUploadActions>) => {
  return {
    broadcastCookieAction: (data: IBroadcastMessage) =>
      dispatch<IBroadcastContentUploadMessage>({
        type: ContentUploadActionTypes.BROADCAST_MESSAGE,
        data: data,
      }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CookieConsentComponent);
