import { IBroadcastMessage } from "components/batch-upload/ContentUpload.types";
import startOfYesterday from "date-fns/startOfYesterday/index.js";
import { TTableFiltersReducerActions } from "./table-filter.actions";
import { TableFilterActions, TableFilterState } from "./table-filter.types";

const InitialState: TableFilterState = {
    contentStatus: '',
    contentType: '',
    uploadDate: [],
    publishDate: [],
    channelFilter: { id: 0, title: "", code: "" },
    subchannelFilter: { id: 0, title: "" },
    threadFilter: { id: 0, title: "" },
    locationFilter: { city: [], stateProvince: "", country: "" },
    fetchError: {} as IBroadcastMessage
}

export const tableFilterReducer = (state = InitialState, action: TTableFiltersReducerActions): TableFilterState => {
    switch (action.type) {
        case TableFilterActions.TOGGLE_TABLE_FILTER_CONTENT_STATUS: {
            return {
                ...state,
                contentStatus: action.data
            }
        }
        case TableFilterActions.TOGGLE_TABLE_FILTER_CONTENT_TYPE: {
            return {
                ...state,
                contentType: action.data
            }
        }
        case TableFilterActions.TOGGLE_TABLE_FILTER_UPLOAD_DATE: {
            return {
                ...state,
                uploadDate: action.data
            }
        }
        case TableFilterActions.TOGGLE_TABLE_FILTER_PUBLISH_DATE: {
            return {
                ...state,
                publishDate: action.data
            }
        }
        case TableFilterActions.TOGGLE_TABLE_FILTER_CHANNEL: {
            return {
                ...state,
                channelFilter: action.data
            }
        }
        case TableFilterActions.TOGGLE_TABLE_FILTER_SUBCHANNEL: {
            return {
                ...state,
                subchannelFilter: action.data
            }
        }
        case TableFilterActions.TOGGLE_TABLE_FILTER_THREAD: {
            return {
                ...state,
                threadFilter: action.data
            }
        }
        case TableFilterActions.TOGGLE_TABLE_FILTER_LOCATION: {
            return {
                ...state,
                locationFilter: action.data
            }
        }
        case TableFilterActions.BROADCAST_ERROR: {
            return {
                ...state,
                fetchError: action.data
            }
        }
        case TableFilterActions.CLEAR_BROADCAST: {
            return {
                ...state,
                fetchError: {} as IBroadcastMessage
            }
        }
        case TableFilterActions.RESET_TABLE_FILTERS: {
            return {
                ...state,
                contentStatus: '',
                contentType: '',
                uploadDate: [],
                publishDate: [],
                channelFilter: { id: 0, title: "", code: "" },
                subchannelFilter: { id: 0, title: "" },
                threadFilter: { id: 0, title: "" },
                locationFilter: { city: [], stateProvince: "", country: "" }
            }
        }
        default: { 
            return state;
        }
    }
}