import { IBroadcastMessage, SeveritySnackbarEnum } from '../batch-upload/ContentUpload.types';
import { CallHistoryMethodAction, push } from 'connected-react-router';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IBroadcastCartError, TCartReducerActions } from '../../redux/cart/cart-page.actions';
import { CartActionTypes } from '../../redux/cart/cart-page.types';
import { StoreState } from '../../redux/root-reducer';
import { CompleteBanner } from 'shared';
import { CompleteBannerStepProps, OrderDto, SingleContentTypes } from './cart-page.types';
import JSZip from 'jszip';
import { saveAs } from "file-saver";
import { Grow } from '@material-ui/core';
import AWS from "aws-sdk";
import './cart-page.styles.scss';
import { format } from 'date-fns';
import { selectCurrentUser } from '../../redux/user/user.selectors';
import { User } from '../../redux/user/user.types';
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_ORDERS_BY_USER_ID } from './queries';
import { GetObjectOutput } from 'aws-sdk/clients/s3';
import SyncLoader from 'react-spinners/SyncLoader';
import { checkPrmiseResults } from './cart-page-helperFunctions';
import {config as conf} from '../../config';
import axios from 'axios';
import fileDownload from 'js-file-download';
import { downloadContent } from '../utils/download';

const CompleteBannerContainer: React.FC<CompleteBannerStepProps> = ({ ...props }) => {
  const { currentUser, redirectToHome, broadcastCartErrorAction } = props;
  const [order, setOrder] = useState<OrderDto>();
  const { t } = useTranslation();

  const [purchasedImages, setPurchasedImages] = useState<number[]>([]);

  const [fetchOrderData, { data: orderData, loading: loadingData, refetch }] = useLazyQuery(GET_ORDERS_BY_USER_ID, { fetchPolicy: "no-cache" });

  const handleDownloadError = ()=>{
    broadcastCartErrorAction({ severity: SeveritySnackbarEnum.error, message: t("Error.Download.Content") })
  }

  const handleDownload = ()=>{
    if(currentUser?.token){
      downloadContent({purchasedImages: purchasedImages,token: currentUser.token, fileName: 'wi_image', onError: handleDownloadError});
    }
  }

  useEffect(() => {
    fetchOrderData({ variables: { userId: currentUser.id } });
  }, []);

  useEffect(() => {
    if (order) {
      const imagesIds = order.contents.filter(content => content.type === SingleContentTypes.Image).map(content => {return content.id });

      setPurchasedImages(imagesIds);
    }
  }, [order]);

  useEffect(() => {
    if (orderData && orderData.getLastOrderByUserId) {
      setOrder(orderData.getLastOrderByUserId);
    }
  }, [orderData]);

  return (
    <React.Fragment>
      <Grow in={true}>
        <CompleteBanner redirect={redirectToHome} headerText={t("Payment.Complete.Banner.Headline")} isInOnboarding={false}
          heading={t("Payment.Complete.Banner.Caption")} contentText={t("Payment.Complete.Banner.ContentText")}
          imagePath="https://bucket-wi-dev1.s3.eu-north-1.amazonaws.com/home/health.jpg" startDownload={handleDownload} />
      </Grow>

      {/* {processingZip ?
        <SyncLoader
          css={`display: block; margin: 10px auto;
          text-align: center; width: 100%; height: 100%; z-index: 100;`}
          size={20}
          color={"#36D2B3"}
          loading={processingZip}
        />
        :
        null
      } */}
    </React.Fragment>
  )
}

const mapStateToProps = (state: StoreState): { currentUser: User } => {
  return {
    currentUser: selectCurrentUser(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<CallHistoryMethodAction | TCartReducerActions>) => {
  return {
    redirectToHome: () => dispatch(push("/")),
    broadcastCartErrorAction: (data: IBroadcastMessage) => dispatch<IBroadcastCartError>({
      type: CartActionTypes.BROADCAST_CART_ERROR, data: data
    })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompleteBannerContainer);