import { ContentStatus, ContentTypesForSearch, ContentFromOptions } from "../../components/search-bar/searchBar.types";
import { SubChannel, Thread } from '../../components/channelsSidebar/channelSideBars.types';
import { Channel } from '../../components/home-component/home.types';
import { UserUploadedContent } from "../../components/contributor-content/contributor-content.types";
import { IBroadcastMessage } from "../../components/batch-upload/ContentUpload.types";

export enum SearchBarActions {
    EXPAND_SEARCH_BAR = 'EXPAND_SEARCH_BAR',
    LOAD_TOTAL_RESULTS = 'LOAD_TOTAL_RESULTS',
    LOAD_TOTAL_RESULTS_CHANNEL_CONTEX = 'LOAD_TOTAL_RESULTS_CHANNEL_CONTEX',
    LOAD_USER_SEARCH_RESULTS = 'LOAD_USER_SEARCH_RESULTS',
    LOAD_CONTENT_SEARCH_RESULTS = 'LOAD_CONTENT_SEARCH_RESULTS',
    TOGGLE_SEARCH_STRING = "TOGGLE_SEARCH_STRING",
    TOGGLE_FILTER_CONTENT_FROM = 'TOGGLE_FILTER_CONTENT_FROM',
    TOGGLE_FILTER_CONTENT_TYPE = 'TOGGLE_FILTER_CONTENT_TYPE',
    TOGGLE_FILTER_DATE = 'TOGGLE_FILTER_DATE',
    TOGGLE_FILTER_LOCATION = 'TOGGLE_FILTER_LOCATION',
    TOGGLE_FILTER_LOCATION_NEAR_ME = 'TOGGLE_FILTER_LOCATION_NEAR_ME',
    TOGGLE_FILTER_COLOUR = 'TOGGLE_FILTER_COLOUR',
    TOGGLE_FILTER_CHANNEL = 'TOGGLE_FILTER_CHANNEL',
    TOGGLE_FILTER_SUBCHANNEL = 'TOGGLE_FILTER_SUBCHANNEL',
    TOGGLE_FILTER_THREAD = 'TOGGLE_FILTER_THREAD',
    RESET_FILTERS = 'RESET_FILTERS',
    RESET_FILTERS_CHANNELS = 'RESET_FILTERS_CHANNELS',
    RESET_SEARCH_RESULTS = 'RESET_SEARCH_RESULTS',
    RESET_DATE_FILTER = 'RESET_DATE_FILTER',
    RESET_LOCATIONS_FILTER = 'RESET_LOCATION_FILTER',
    RESET_CONTENT_FROM_FILTER = 'RESET_CONTENT_FROM_FILTER',
    BROADCAST_SEARCH_ERROR = 'BROADCAST_SEARCH_ERROR',
    TOGGLE_CONTENT_PER_PAGE = 'TOGGLE_CONTENT_PER_PAGE',
    BROADCAST_ADD_TO_CART = 'BROADCAST_ADD_TO_CART',
    CLEAR_BROADCAST = 'CLEAR_BROADCAST',
    CLEAR_ADD_TO_CART_BROADCAST = 'CLEAR_ADD_TO_CART_BROADCAST',
}

export interface SearchBarState {
    searchBarExpanded: boolean;
    searchString: string;
    totalResults: number;
    totalResultsChannelContex: number;
    userSearchResults: UserSource[];
    contentSearchResults: UserUploadedContent[];
    contentFrom: number | ContentFromOptions;
    contentType: ContentTypesForSearch;
    dateFilter: Date[];
    locationFilter: LocationFilterProps;
    locationNearMeFilter: LocationNearMeFilterProps;
    colourFilter: string;
    channelFilter: Channel;
    subchannelFilter: SubChannel;
    threadFilter: Thread;
    searchBroadcastMessage: IBroadcastMessage;
    addToCartBroadcastMessage: IBroadcastMessage;
    contentPerPage: number;
}

export interface UserSource {
    id: number;
    email: string;
    first_name: string;
    username: string;
    last_name: string;
    organization_name: string;
    profilePic?: string;
}

export interface LocationFilterProps {
    country: string;
    stateProvince: string;
    city: string[];
}

export interface LocationNearMeFilterProps {
    country: string;
    stateProvince: string;
    city: string[];
}
