import React, { useState, useEffect } from 'react';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import CloseIcon from '@material-ui/icons/Close';
import { TextField, Select, MenuItem, Button, InputBase, Collapse, Paper, Autocomplete, Grid, Typography, Grow, AutocompleteChangeReason } from '@mui/material';
import { StoreState } from 'redux/root-reducer';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IResetFilters, IToggleFilterContentFrom, IExpandSearchBar, IToggleFilterContentType, TSearchReducerActions, IResetFiltersChannels } from '../../redux/search-bar/search-bar.actions';
import { CallHistoryMethodAction } from 'connected-react-router';
import { selectColourFilter, selectContentFromFilter, selectContentTypeFilter, selectDateFilter, selectSearchBarExpanded } from '../../redux/search-bar/search-bar.selectors';
import { ContentFromOptions, ContentTypesForSearch, SearchFieldTypes, SearchResultsFiltersProps, useStyles } from './searchBar.types';
import { SearchBarActions, UserSource } from '../../redux/search-bar/search-bar.types';
import DateFilterComponent from './dateFilterComponent';
import SearchBarExpanded from './searchBarExpanded.component';
import ContentTypeFilterComponent from './contentTypeFilterComponent';
import './searchResultsFilters.styles.scss';
import { useTranslation } from 'react-i18next';
import LocationFilterComponent from './locationsFilterComponent';
import SearchBar from '../search-bar/searchBar.component';
import { SEARCH_USERS } from './queries';
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_FULL_USER_DETAILS } from '../onboarding/queries';
import ContributorTypeFilterComponent from './contributorTypeFilterComponent';

const SearchResultsFilters: React.FC<SearchResultsFiltersProps> = ({...props}) => {
    const { contentFrom, contentType, searchBarExpanded, showSearchBar, isInChannelContex, path,
        toggleContentFromFilter, resetFiltersAction, resetFiltersForChannelsAction, toggleSearchBarExpanded} = props;

    const [autoCompleteUserResults, setAutoCompleteUserResults] = useState<UserSource[]>([]);
    const [userSearchString, setUserSearchString] = useState("");
    const [searchUsers, {data, loading, error}] = useLazyQuery(SEARCH_USERS);
    const [getUserDetails, {data: userData, error: userError}] = useLazyQuery(GET_FULL_USER_DETAILS);
    const [filtersOpen, setFiltersOpen] = useState(true);
    const { t } = useTranslation();
    const classes = useStyles();

    const getDefaultUserInstance = () => {
        return {
            id: 0,
            email: "",
            first_name: "",
            username: "",
            last_name: "",
            organization_name: ""
        };
    }
    const [selectedUser, setSelectedUser] = useState<UserSource>(getDefaultUserInstance());

    useEffect(() => {
        setAutoCompleteUserResults([]);
    }, [error, userError])

    useEffect(() => {
        if(typeof contentFrom === "number" && contentFrom !== selectedUser.id) {
            getUserDetails({variables: {id: contentFrom}});
        }
    }, [contentFrom])

    useEffect(() => {
        if(data && data.searchUsers && userSearchString !== "") {
            const userObjects = data.searchUsers.searchEdges.map((hit: any) => {
                return hit.node._source;
            })
            setAutoCompleteUserResults(userObjects);
        }
    }, [data])

    useEffect(() => {
        if(userData && userData.getFullUserDetails) {
            const user = userData.getFullUserDetails;
            setSelectedUser({
                id: user.id,
                email: user.email,
                username: user.username,
                first_name: user.person ? user.person.firstName : "",
                last_name: user.person ? user.person.lastName : "",
                organization_name: user.company ? user.company.name : ""
            })
        }
    }, [userData])

    const handleToggleButtonChange = (event: any) => {
        event.preventDefault();
        const value = event.currentTarget.value;
        if(contentFrom === value) {
            toggleContentFromFilter(0);
        } else {
            toggleContentFromFilter(value);
        }  
    }

    const handleChange = (event: any, value: any) => {
        setUserSearchString(value);
        searchUsers({variables: {title: value, first: 40, after: ''}});
    }

    const handleAutoCompleteUserSearchChange = (event: any, newValue: any, reason: string) => {
        if(newValue && reason === 'selectOption') {
            const user = autoCompleteUserResults.find((user: UserSource) => {
                return user.username === newValue;
            });
            setSelectedUser(user ? user : getDefaultUserInstance());
            toggleContentFromFilter(user ? user.id : 0)
            setUserSearchString("");
        } else if(reason === 'clear') {
            toggleContentFromFilter(ContentFromOptions.PRO_CONTRIBUTORS);
            resetContentFromUserFilters();
        }
    }

    const openAdvancedSearchMenu = () => {
        toggleSearchBarExpanded();
    }

    const resetContentFromUserFilters = () => {
        setSelectedUser(getDefaultUserInstance());
        setUserSearchString("");
    }

    const resetAllFilters = () => {
        if(isInChannelContext()) {
            resetFiltersForChannelsAction();
        } else {
            resetFiltersAction();
        }
        resetContentFromUserFilters();
    }

    const toggleFilters = () => {
        setFiltersOpen(prevState => !prevState);
    }
    
    const isInChannelContext = () => {
        return path.indexOf("/channels") > -1;
    }

    return (
        <React.Fragment>
            {searchBarExpanded ? 
                <Collapse in={searchBarExpanded} timeout={1000} mountOnEnter unmountOnExit classes={{entered: classes.collapseEntered}}>
                    <Paper elevation={4} style={{height: '100%', paddingTop: '1%'}}>
                        <SearchBarExpanded/>
                    </Paper>
                </Collapse>
            : 
            null}
            <div className='filters-container'>
                <div className="filters-row">
                    <Grow in={filtersOpen && !searchBarExpanded} mountOnEnter unmountOnExit>
                        <div className="filters-components">
                            <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1} 
                                sx={{m: "0", flex: "3", minWidth: '450px'}}>
                                <Typography color="textPrimary" align="center" variant="body2">{t("ContentFrom.Field")}</Typography>
                                {/* <Button  className={contentFrom === ContentFromOptions.ALL_MEMBERS ? 'selected-round-button' :'white-round-button'} size="medium" variant="contained"
                                    value={ContentFromOptions.ALL_MEMBERS} onClick={handleToggleButtonChange} classes={{root: classes.buttonRoot}}>
                                    {t("ContentFrom." + ContentFromOptions.ALL_MEMBERS)}
                                </Button> */}
                                <ContributorTypeFilterComponent isInChannelContex={true} />
                                
                                <Grid item xs={5}>
                                    <Autocomplete id="autocomplete-search-users"
                                        inputValue={userSearchString}
                                        value={selectedUser.id !== 0 ? selectedUser.username : ""}
                                        options={autoCompleteUserResults.map((option: UserSource) => option.username)}
                                        autoHighlight
                                        fullWidth
                                        noOptionsText={"No users found"}
                                        onChange={handleAutoCompleteUserSearchChange}
                                        onInputChange={handleChange}
                                        renderInput={(params: any) => (
                                            <TextField 
                                                {...params}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'userSearch',
                                                }}
                                                autoComplete='userSearch'
                                                id="user-search" variant='standard' 
                                                placeholder={t("ContentFrom.Placeholder")}
                                                name={SearchFieldTypes.CONTENT_FROM_FIELD}/>
                                        )}
                                    /> 
                                </Grid>
                            </Grid>                    

                            <Grid container direction="row" alignItems="center" justifyContent="flex-start" spacing={1} 
                                sx={{flex: "1", minWidth: '160px'}}>
                                <ContentTypeFilterComponent isInChannelContex={false}/>
                            </Grid>
                            
                            <Grid container direction="row" alignItems="center" justifyContent="center" spacing={1} 
                                sx={{flex: "2", minWidth: '400px'}}>
                                    <Typography color="textPrimary" align="left" variant="body2">{t("Filter.By")}</Typography>
                                    <LocationFilterComponent isInChannelContex={false}/>
                                    <DateFilterComponent isInResults={true} isInChannelContex={false}/>
                                    {/* TODO: Uncomment when functionallity is ready */}
                                    {/* <ColourFilterComponent/> */}
                            </Grid>

                            <Grid container direction="row" alignItems="center" justifyContent="flex-end" spacing={1} 
                                sx={{flex: "1", minWidth: '160px'}}>
                                <Button size="medium" variant="contained" 
                                    className='white-round-button' onClick={openAdvancedSearchMenu}>
                                    {t("AdvancedSearch.Button")}
                                </Button>
                            </Grid>
                        </div>
                    </Grow>
                    <Grow in={!searchBarExpanded} mountOnEnter>
                        <div className='result-search-bar'>
                            {showSearchBar ? 
                                    <SearchBar isExpandable={false}/> 
                            : null}
                        </div>
                    </Grow>
                    
                </div>
                <Grow in={!searchBarExpanded} mountOnEnter>
                    <div className="hide-filters-section">   
                        {filtersOpen ?  
                            <Button size="small" className='clear-round-button'
                                onClick={resetAllFilters} endIcon={<CloseIcon/>}>
                                {t("ClearFilters.Button")}
                            </Button>      
                        : null}           
                        <Button size="small" className='clear-round-button'
                            onClick={toggleFilters} endIcon={filtersOpen ? <ExpandLessIcon/> : <ExpandMoreIcon/>}>
                            {filtersOpen ? t("HideFilters.Button") : t("ShowFilters.Button")}
                        </Button>
                    </div>
                </Grow>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state: StoreState) : {contentFrom: number | ContentFromOptions, contentType: ContentTypesForSearch, 
    dateFilter: Date[], colourFilter: any, searchBarExpanded: boolean, path: string} => {
    return {
        contentFrom: selectContentFromFilter(state),
        contentType: selectContentTypeFilter(state),
        dateFilter: selectDateFilter(state),
        colourFilter: selectColourFilter(state),
        searchBarExpanded: selectSearchBarExpanded(state),
        path: state.router.location.pathname
    }
}

const mapDispatchToProps = (dispatch: Dispatch<TSearchReducerActions | CallHistoryMethodAction>) => {
    return {
        toggleContentFromFilter:(data: number | ContentFromOptions) => dispatch<IToggleFilterContentFrom>({
            type: SearchBarActions.TOGGLE_FILTER_CONTENT_FROM,
            data: data
        }),
        resetFiltersAction: () => dispatch<IResetFilters>({
            type: SearchBarActions.RESET_FILTERS
        }),
        resetFiltersForChannelsAction: () => dispatch<IResetFiltersChannels>({
            type: SearchBarActions.RESET_FILTERS_CHANNELS
        }),
        toggleSearchBarExpanded: () => dispatch<IExpandSearchBar>({
            type: SearchBarActions.EXPAND_SEARCH_BAR
        })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultsFilters);