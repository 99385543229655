import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, InputLabel, MenuItem, TextField, SelectChangeEvent } from '@mui/material';
import Select from "@mui/material/Select";
import { CREATE_OR_ADD_TO_LIGHT_BOX } from '../../components/LightBox/queries';
import React, {useState, useEffect} from 'react';
import { useMutation, useQuery } from 'react-apollo-hooks';
import SyncLoader from 'react-spinners/SyncLoader';
import { LightBox } from './photo-details.types';
import { GET_ALL_LIGHT_BOXES_OF_USER } from './queries';
import { StoreState } from '../../redux/root-reducer';
import { IBroadcastLightBoxMessage, IClearBroadcast, TLightBoxActions } from '../../redux/lightbox/lightbox.actions';
import { LightBoxActionTypes } from '../../redux/lightbox/lightbox.types';
import { IBroadcastMessage } from '../../components/LightBox/lightBox.types';
import { Dispatch } from 'redux';
import { selectCurrentUser } from '../../redux/user/user.selectors';
import { selectLightBoxBroadcast } from '../../redux/lightbox/lightbox.selector';
import { User } from '../../redux/user/user.types';
import { connect } from 'react-redux';
import { SeveritySnackbarEnum } from '../../components/batch-upload/ContentUpload.types';
import { SnackbarComponent } from 'shared';
import { useLazyQuery } from 'react-apollo';

const AddToLightBoxModal: React.FC<any> = ({...props})=>{
    const {showAddToLightBoxModal, handleAddToLightBoxClose, currentUser, broadcastAction, clickedImageToAddToLightBox, contentDetailsIds, handleAddToLightBoxSnackBarOpen} = props;
    const [title,setTitle] = useState<string>("");
    const [titleError,setTitleError] = useState<boolean>(false);
    const [loading,setLoading] = useState<boolean>(false);
    const [createNewLightBox,setCreateNewLightBox] = useState<boolean>(false);
    const [selectedLightBox,setSelectedLightBox] = useState<string>("0");
    const [allUserLightBoxes,setAllUserLightBoxes] = useState<LightBox[]>([]);
    const [createLightBoxMutation] = useMutation(CREATE_OR_ADD_TO_LIGHT_BOX);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>)=>{
        setTitle(event.target.value);
    }

    const handleCreateNewChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCreateNewLightBox(event.target.checked);
      };

      const handleLightBoxChange = (event: SelectChangeEvent) => {
        setSelectedLightBox(event.target.value as string);
      };
    const [fetchUserLightBoxes, {data:allLightBoxes,loading:allLightBoxesLoading,error}] = useLazyQuery(GET_ALL_LIGHT_BOXES_OF_USER,{
        variables: 
        {
            userId: currentUser?.id
        },
        fetchPolicy: 'cache-and-network'
    });

    const handleAddOrCreateToLightBox = ()=>{
        setLoading(true);
        let variables = {};
        if(createNewLightBox){
            variables = {
                userId: currentUser?.id,
                lightboxName: title,
                coverId: clickedImageToAddToLightBox,
                contentId: clickedImageToAddToLightBox,
                contentSetId: parseInt(contentDetailsIds.contentSetId),
                articleId: parseInt(contentDetailsIds?.contentArticleId),
                threadId: parseInt(contentDetailsIds.threadId)
            }
        }
        else{
            variables = {
                lightboxId: parseInt(selectedLightBox),
                userId: currentUser?.id,
                contentId: clickedImageToAddToLightBox,
                contentSetId: parseInt(contentDetailsIds.contentSetId),
                articleId: parseInt(contentDetailsIds?.contentArticleId),
                threadId: parseInt(contentDetailsIds.threadId)
            }
        }
       
        createLightBoxMutation({
            variables
        }).then((res: any)=>{
            setLoading(false);
            handleAddToLightBoxClose();
            handleAddToLightBoxSnackBarOpen();
            if(res.data.createOrAddToLightbox){
                let message = "";
                if(createNewLightBox){
                    message = "Light box created successfully";
                }
                else{
                    message = "Successfully added image to lightbox ";
                }
                fetchUserLightBoxes();
                setTitle("");
                setSelectedLightBox("0");
                broadcastAction({severity: SeveritySnackbarEnum.success, message: message});
            }
        }) .catch(e => {
            setLoading(false);
            handleAddToLightBoxClose();
            handleAddToLightBoxSnackBarOpen();
            let message = "";
            if(createNewLightBox){
                message = "Light box creation failed";
            }
            else{
                message = "Failed to add image to lightbox";
            }
            broadcastAction({severity: SeveritySnackbarEnum.error, message: message});
        })
    }

    useEffect(()=>{
        if(currentUser?.id){
            fetchUserLightBoxes();
        }
    },[currentUser]);

    useEffect(()=>{
        if(allLightBoxes && allLightBoxes.getAllUserLightboxes.lightboxes.length > 0){
            setAllUserLightBoxes(allLightBoxes.getAllUserLightboxes.lightboxes);
        }
    },[allLightBoxes]);

    useEffect(()=>{
        if(title && (title?.length <=3 || title?.length >=30)){
            setTitleError(true);
        }
        else{
            setTitleError(false);
        }
    },[title]);


    return <div>
        <Dialog open={showAddToLightBoxModal} keepMounted
                onClose={()=>{
                    handleAddToLightBoxClose();
                    setSelectedLightBox("0");
                    setTitle("")
                }} aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description">
                <DialogTitle id="alert-dialog-slide-title">Create or add image to lightBox</DialogTitle>
                <DialogContent>
                <FormControlLabel control={<Checkbox
                    checked={createNewLightBox}
                    onChange={handleCreateNewChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                    />} label="Create new light box" />   
                <TextField autoFocus margin="dense" label="Enter light box title"
                    type="text" fullWidth name='dialogTextField' value={title}
                    error={titleError}
                    className={createNewLightBox ? 'show' : 'hide'}
                    helperText={titleError ? "Title must contain more than 3 and less than 30 characters" : ""}
                    data-shrink={false} onChange={handleChange} />
                <InputLabel className={createNewLightBox ? 'hide' : 'show'} id="demo-simple-select-label">Select Light box</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedLightBox}
                    label="Select Light box"
                    onChange={handleLightBoxChange}
                    className={createNewLightBox ? 'hide' : 'show'}
                >
                {
                    allUserLightBoxes.map((lightBox: any)=>{
                        return <MenuItem value={lightBox.contentLightboxId}>{lightBox.lightboxName}</MenuItem>
                    })
                }
                </Select>
                </DialogContent>
                {loading ? 
                    <div style={{display: 'flex', justifyContent: 'center',alignItems: 'center', width: '100%', height: '40px'}}>
                        <SyncLoader css={`display: block; margin: 0 auto; border-color: red;`} size={10} 
                        color={"#36D2B3"} loading={loading}/>
                    </div> : 
                    <DialogActions>
                   
                    <Button variant="outlined" onClick={handleAddToLightBoxClose} color="primary">
                        Cancel
                    </Button>
                   
                    <Button variant="outlined" disabled={createNewLightBox ? title.length === 0 || titleError : selectedLightBox === "0"}
                    color="primary" onClick={handleAddOrCreateToLightBox}>
                    {createNewLightBox ? 'Create' : 'Add'}
                    </Button>
                </DialogActions>
                }
            </Dialog>
    </div>
}

const mapStateToProps = (state: StoreState): {
    currentUser: User;
    lightBoxBroadcast: IBroadcastMessage;
  } => {
    return {
      currentUser: selectCurrentUser(state),
      lightBoxBroadcast: selectLightBoxBroadcast(state)
    };
  };

  const mapDispatchToProps = (dispatch: Dispatch<TLightBoxActions>) => {
    return {
        broadcastAction: (data: IBroadcastMessage) => dispatch<IBroadcastLightBoxMessage>({
            type: LightBoxActionTypes.BROADCAST_MESSAGE, data: data
        }),
        clearBroadcast: () => dispatch<IClearBroadcast>({type: LightBoxActionTypes.CLEAR_BROADCAST})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddToLightBoxModal);