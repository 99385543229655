import React, { useEffect, useState } from 'react';
import {ChannelCovers, HomeComponentProps, TileData, useStyles} from './home.types';
import {CHANNEL_COVERS} from './queries'
import './home.styles.scss';
import { connect } from 'react-redux';
import MasonryItem from './masonry-item.component';
import { useQuery } from "@apollo/react-hooks";


const HomeComponent: React.FC<HomeComponentProps> = ({...props}) => {

    const [channelCovers, setChanneCovers] = useState<ChannelCovers[]>([]);
    const {data, loading, error} = useQuery(CHANNEL_COVERS);

    useEffect(() => {
        if(data && data.getChannelCovers) {
            setChanneCovers(data.getChannelCovers);
        }
    }, [data]);

    return(
        <div>
            <div className='gallery-container'>
                {channelCovers
                .map(item => (
                    <MasonryItem key={item.channelId} item={item} />
                ))}
                
            
            {/*TODO: Uncomment when we apply the generated code from Revive*/}
            {/*<div className='ads'>*/}
            {/*    ADS SPACE*/}
            {/*</div>*/}
            </div>
        </div>
    )
}

export default connect(null, null)(HomeComponent);