import React, { useState, useEffect } from "react";
import "./photo-details.styles.scss";
import {
  PhotoDetailsProps,
  ContentSet,
  ContentInSet,
  ItemForCart,
  ItemTypeLicensing,
  RouteParamsContentTypes,
} from "./photo-details.types";
import {
  Description,
  SocialAndPricing,
  ShareContainer,
  PriceBoxMessageNonLogged,
} from "./photo-details-containers";
import { StoreState } from "redux/root-reducer";
import { connect } from "react-redux";
import { push, CallHistoryMethodAction } from "connected-react-router";
import { Dispatch } from "redux";
import { SnackbarComponent } from "shared";
import { User } from "../../redux/user/user.types";
import {
  selectBroadcastMessage,
  selectClickedImageToAddToLightBox,
  selectCurrentlySelectedPhoto,
  selectPhotoDetails,
} from "../../redux/photo-details/photo-details.selector";
import {
  IToggleLogin,
  TModalReducerActions,
} from "../../redux/modal-visibility/modal.actions";
import { ModalActionTypes } from "../../redux/modal-visibility/modal.types";
import { selectCurrentUser } from "../../redux/user/user.selectors";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import {
  GET_CONTENT_SET_BY_SET_ID,
  GET_CONTENT_SET_BY_ARTICLE_ID,
  GET_CONTENT_INFO,
  GET_COMMENTS,
  GET_AUDIO_FILE_PRICE,
  GET_LICENSE_PLAN_BY_USER_ID_AND_TYPE,
  GET_CONTENT_NAVIGATION_BY_CONTENT_ID,
  CHECK_IS_CONTENT_OWNED,
  GET_CONTENT_COUNT,
} from "./queries";
import Moment from "react-moment";
import {
  IClearBroadcastMessage,
  ISelectPhotoFromSet,
  TPhotoDetailsActions,
} from "../../redux/photo-details/photo-details.actions";
import { PhotoDetailsActionTypes } from "../../redux/photo-details/photo-details.types";
import { CartActionTypes } from "../../redux/cart/cart-page.types";
import {
  IAddToCart,
  IAddToSubscriptionCart,
  TCartReducerActions,
} from "../../redux/cart/cart-page.actions";
import { generateChannelColorArray } from "../channelScreen/helperFunctions";
import variables from "../../assets/globalVariables.module.scss";
import { Channel } from "../home-component/home.types";
import {
  selectCurrentChannel,
  selectGalleryView,
} from "../../redux/channel-routes/channel.selectors";
import ReactAudioPlayer from "react-audio-player";
import ReactWaterMark from "react-watermark-component";
import { LoadingSpinner } from "shared";
import {
  IAddChannel,
  IAddSubChannel,
  IAddThread,
  TChannelReducerActions,
} from "../../redux/channel-routes/channel.actions";
import { ChannelActionTypes } from "../../redux/channel-routes/channel.types";
import { getLocation } from "../locations/locations.functions";
import { CommentsBox } from "./comments-container";
import { UserLicensingPlan } from "generated/graphql";
import { selectCurrentSubItems } from "../../redux/cart/cart-page.selector";
import { checkIfPlanHasAvailableAmount } from "./photo-details-helper-functions";
import { useTranslation } from "react-i18next";
import { getAudioFileName } from "../cart/cart-page-helperFunctions";
import { RoleTypes } from "../register/register.types";
import CircularProgress from "@mui/material/CircularProgress";
import { Dialog, DialogContent, DialogTitle, IconButton, Skeleton } from "@mui/material";
import CommentSkeleton from "./CommentSkeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import AddToLightBoxModal from "./AddToLightBoxModal";
import { selectLightBoxBroadcast } from "../../redux/lightbox/lightbox.selector";
import { IBroadcastMessage } from "components/LightBox/lightBox.types";
import PhotoContainer from "./photo-container/PhotoContainer";
import StackGrid, {easings, transitions} from "react-stack-grid";
import GalleryViewPhotoDetails from './galleryVIewPhotoDetails';
import CloseIcon from '@material-ui/icons/Close';
import GalleryVIewPhotoDetails from "./galleryVIewPhotoDetails";

const PhotoDetails: React.FC<PhotoDetailsProps> = ({ ...props }) => {
  const {
    routeParams,
    currentSelectedPhoto,
    currentUser,
    selectedChannel,
    cartItemsFromSub,
    lightBoxBroadcast,
    galleryViewEnabled,
    redirectToShare,
    selectPhotoAction,
    toggleLogin,
    broadcastMessage,
    addToCartAction,
    redirectToPhotoDetals,
    selectChannelAction,
    selectSubChannelAction,
    selectThreadAction,
    addToSubscriptionCartAction,
    clickedImageToAddToLightBox,
    clearBroadcastMessage
  } = props;
  const [showCopiedSnackbar, setshowCopiedSnackbar] = useState(false);
  const [showAddToCartSnackbar, setshowAddToCartSnackbar] = useState(false);

  const [dataQuery, setQuery] = useState(GET_CONTENT_INFO);
  const [showPhotoDetailsModal, setShowPhotoDetailsModal] = useState(false);
  const { scaleDown } = transitions;
  const styles = {
    customMaxWidth: {
      maxWidth: "none" // arbitrary value
    }
  };
  const [state, setState] = useState({
    duration: 0,
    gutter: 15,
    easing: easings.quartOut,
    transition: "fadeDown",
    rtl: false,
  });

  const [heights, setHeights] = useState<number[]>([]);

  const { duration, gutter, easing, transition, rtl } = state;

  const [
    fetchTotalContentCount,
    {
      data: totalContent,
      loading: totalContentCountLoading,
      error: totalContentCountError,
    },
  ] = useLazyQuery(GET_CONTENT_COUNT, { fetchPolicy: "no-cache" });
  const [fetchPhotoDetails, { data, loading, error, refetch }] = useLazyQuery(
    dataQuery,
    { fetchPolicy: "no-cache" }
  );

  const [contentQuery, setContentQuery] = useState(GET_CONTENT_SET_BY_SET_ID);
  const [
    fetchContentSetData,
    { data: setData, loading: setLoading, error: setError },
  ] = useLazyQuery(contentQuery,{ fetchPolicy: "no-cache" });

  const [planQuery, setPlanQuery] = useState<any>();
  const [
    fetchUserPlanData,
    { data: planData, loading: planLoading, error: planError },
  ] = useLazyQuery(planQuery,{ fetchPolicy: "no-cache" });

  const [audioFileQuery, setAudioFileQuery] = useState(GET_AUDIO_FILE_PRICE);
  const [
    fetchAudioFileData,
    {
      data: audioData,
      loading: audioLoading,
      error: audioError,
      refetch: audioRefetch,
    },
  ] = useLazyQuery(audioFileQuery);

  const [checkIfOwnedQuery, setCheckIfOwnedQuery] = useState(
    CHECK_IS_CONTENT_OWNED
  );
  const [
    fetchOwnedData,
    {
      data: ownedData,
      loading: ownedLoading,
      error: ownedError,
      refetch: ownedRefetch,
    },
  ] = useLazyQuery(checkIfOwnedQuery, { fetchPolicy: "no-cache" });
  const [
    fetchOwnedAudioData,
    {
      data: ownedAudioData,
      loading: ownedAudioLoading,
      error: ownedAudioError,
      refetch: ownedAudioRefetch,
    },
  ] = useLazyQuery(checkIfOwnedQuery, { fetchPolicy: "no-cache" });

  const {
    data: commentsData,
    loading: commentsLoading,
    error: commentError,
    refetch: commentsRefetch,
  } = useQuery(GET_COMMENTS, {
    variables: {
      contentId: parseInt(routeParams.contentId),
    },
  });
  const [connectedSet, setConnectedSet] = useState<ContentSet>();
  const [currentItem, setCurrentItem] = useState<any>();
  const [comments, setComments] = useState([]);
  const [audioFile, setAudioFile] = useState<any>();
  const [audioFileName, setAudioFileName] = useState("");
  const [audioFilePrice, setAudioFilePrice] = useState<number>();
  const [audioFileOwned, setAudioFileOwned] = useState(false);
  const currentPath = window.location.href.toString();
  const [colors, setColors] = useState<string>(
    generateChannelColorArray(selectedChannel.code, variables)[1]
  );
  const [showAddToLightBoxSnackbar, setShowAddToLightBoxSnackbar] =
    useState<boolean>(false);
  const [showBroadcastMessage, setShowBroadcastMessage] = useState(false);

  const [threadId, setThreadId] = useState(parseInt(routeParams.threadId));
  const [singleRate, setSingleRate] = useState<any>();
  const [licensingPlan, setLicensingPlan] = useState<UserLicensingPlan[]>([]);

  const [isPartOfPlan, setIsPartOfPlan] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isPartOfSet, setIsPartOfSet] = useState(false);
  const [isPartOfArticle, setIsPartOfArticle] = useState(false);
  const [isOwned, setOwned] = useState(false);
  const [ownedSizes, setOwnedSizes] = useState([]);
  const [showAddToLightBoxModal, setShowAddToLightBoxModal] =
    useState<boolean>(false);

  const [allowedRoles, setAllowedRoles] = useState<RoleTypes[]>([
    RoleTypes.Pro_freelancer,
    RoleTypes.Pro_organization,
  ]);
  const commentIconColor = generateChannelColorArray(
    selectedChannel.code,
    variables
  )[0];
  const { t } = useTranslation();

  const handleBroadcastSnackbarClose = ()=>{
    clearBroadcastMessage();
    setShowBroadcastMessage(false);
  }

  useEffect(()=>{
    if(broadcastMessage.severity && broadcastMessage.message){
      setShowBroadcastMessage(true);
    }
  },[broadcastMessage]);

  useEffect(() => {
    if (routeParams.contentSetId) {
      fetchTotalContentCount({
        variables: {
          contentSetId: parseInt(routeParams.contentSetId!),
        },
      });
    }
  }, []);

  useEffect(() => {
    if (currentUser.email !== undefined) {
      setIsLoggedIn(true);
      setQuery(GET_CONTENT_INFO);
    }
  }, [currentUser]);

  useEffect(() => {
    if (commentsData && commentsData.getComments) {
      setComments(commentsData.getComments);
    }
  }, [commentsData]);

  useEffect(() => {
    if (audioFile && currentUser.email !== "") {
      fetchOwnedAudioData({
        variables: {
          contentId: parseInt(audioFile.id),
          userId: currentUser.id,
        },
      });
    }
  }, [audioFile]);

  useEffect(() => {
    if (ownedAudioData) {
      setAudioFileOwned(ownedAudioData.checkIfContentIsOwned);
    }
  }, [ownedAudioData]);

  useEffect(() => {
    if (contentQuery === GET_CONTENT_SET_BY_SET_ID) {
      if (setData && setData.getFullContentInContentSet) {
        setConnectedSet(setData.getFullContentInContentSet);
        setIsPartOfSet(true);
      }
    } else if (contentQuery === GET_CONTENT_SET_BY_ARTICLE_ID) {
      if (setData && setData.getFullContentInContentSetByArticleId) {
        setConnectedSet(setData.getFullContentInContentSetByArticleId);
        setIsPartOfSet(true);
        setIsPartOfArticle(true);
      }
    }
  }, [contentQuery, setData]);

  useEffect(() => {
    if (data && data.getFullContentInfo) {
      setCurrentItem(data.getFullContentInfo);
      if (
        routeParams.contentType &&
        routeParams.contentType === RouteParamsContentTypes.ARTICLE
      ) {
        setContentQuery(GET_CONTENT_SET_BY_ARTICLE_ID);
      } else if (
        routeParams.contentType &&
        routeParams.contentType === RouteParamsContentTypes.SET
      ) {
        setContentQuery(GET_CONTENT_SET_BY_SET_ID);
      }
      setQuery(GET_CONTENT_NAVIGATION_BY_CONTENT_ID);
    }
  }, [data]);

  useEffect(() => {
    if (
      routeParams.contentType &&
      routeParams.contentType === RouteParamsContentTypes.ARTICLE &&
      contentQuery === GET_CONTENT_SET_BY_ARTICLE_ID
    ) {
      fetchContentSetData({
        variables: { articleId: parseInt(routeParams.contentArticleId!), userId: currentUser?.id },
      });
    } else if (
      routeParams.contentType &&
      routeParams.contentType === RouteParamsContentTypes.SET &&
      contentQuery === GET_CONTENT_SET_BY_SET_ID
    ) {
      fetchContentSetData({
        variables: { contentSetId: parseInt(routeParams.contentSetId!), userId: currentUser?.id },
      });
    }
  }, [contentQuery, currentUser]);

  useEffect(() => {
    if (dataQuery === GET_CONTENT_INFO) {
      fetchPhotoDetails({
        variables: {
          contentId: parseInt(routeParams.contentId),
          userId: currentUser.id,
        },
      });
    }
    if (dataQuery === GET_CONTENT_NAVIGATION_BY_CONTENT_ID) {
      fetchPhotoDetails({
        variables: { contentId: parseInt(routeParams.contentId) },
      });
    }
  }, [dataQuery]);

  // GET NAV BY THREAD

  useEffect(() => {
    if (data && data.getContentNavigationsByContentId) {
      let res = data.getContentNavigationsByContentId;
      let contentNavigation: any;
      if (selectedChannel.id !== -1) {
        contentNavigation = res.find(
          (navigation: any) => navigation.channel.id === selectedChannel.id
        );
      }
      // else {
      //   contentNavigation = res.find((navigation: any) => navigation.channel.id === item.channelId)
      // }
      if (contentNavigation === undefined) {
        let res = data.getContentNavigationsByContentId[0];
        selectChannelAction(res.channel);
        selectSubChannelAction(res.subChannel);
        selectThreadAction(res.thread);
      }
      if (contentNavigation && currentItem && contentNavigation.thread) {
        selectThreadAction(contentNavigation.thread);
        if (contentNavigation.channel) {
          selectChannelAction(contentNavigation.channel);
        }
        if (contentNavigation.subChannel) {
          selectSubChannelAction(contentNavigation.subChannel);
        }
      }
    }
  }, [data]);

  // GET PLAN
  useEffect(() => {
    if (
      planQuery === GET_LICENSE_PLAN_BY_USER_ID_AND_TYPE &&
      currentUser.id &&
      currentItem?.content
    ) {
      fetchUserPlanData({
        variables: {
          userId: currentUser.id,
          licensingType: currentItem?.content?.licensingType,
        },
      });
    }
  }, [currentItem, currentUser, planQuery]);

  useEffect(() => {
    if (planData && planData.getPlanByIdAndType) {
      setLicensingPlan(planData.getPlanByIdAndType);
      if (planData.getPlanByIdAndType.length === 0 && currentItem) {
        setSingleRate(currentItem?.contentRate);
      }
    }
  }, [planData]);

  useEffect(() => {
    if (connectedSet && connectedSet.audioFile) {
      setAudioFile(connectedSet.audioFile);
    }
  }, [connectedSet]);

  useEffect(() => {
    if (currentSelectedPhoto === parseInt(routeParams.contentId)) {
      setQuery(GET_CONTENT_INFO);
    }
  }, [currentSelectedPhoto]);

  useEffect(() => {
    if (currentItem) {
      setPlanQuery(GET_LICENSE_PLAN_BY_USER_ID_AND_TYPE);
    }
  }, [currentItem]);

  useEffect(() => {
    if (routeParams.contentId) {
      selectPhotoAction(parseInt(routeParams.contentId));
      setQuery(GET_CONTENT_INFO);
      if (currentUser && currentUser.id) {
        fetchOwnedData({
          variables: {
            contentId: parseInt(routeParams.contentId),
            userId: currentUser.id,
          },
        });
      }
    }
  }, [routeParams.contentId, currentUser]);

  useEffect(() => {
    if (ownedData) {
      setOwned(ownedData.checkIfContentIsOwned.isOwned);
      setOwnedSizes(ownedData.checkIfContentIsOwned.sizes);
    }
  }, [ownedData]);

  useEffect(() => {
    if (selectedChannel) {
      setColors(generateChannelColorArray(selectedChannel.code, variables)[1]);
    }
  }, [selectedChannel]);

  useEffect(() => {

    if (
      currentItem &&
      licensingPlan.length > 0 &&
      checkIfPlanHasAvailableAmount(
        cartItemsFromSub,
        licensingPlan[0].amountLeft!,
        licensingPlan[0].plan.licensingType!
      )
    ) {
      setIsPartOfPlan(true);
    } else {
      setIsPartOfPlan(false);
    }
  }, [licensingPlan, currentItem, cartItemsFromSub]);

  useEffect(() => {
    if (audioFile && audioFile.pathToFile) {
      setAudioFileName(getAudioFileName(audioFile.pathToFile));
      fetchAudioFileData({
        variables: { userId: currentUser.id, audioFileId: audioFile.id },
      });
    }
  }, [audioFile]);

  useEffect(() => {
    if (audioData && audioData.getAudioFilePrice) {
      setAudioFilePrice(audioData.getAudioFilePrice.flatRate);
    }
  }, [audioData]);

  const handleClose = () => {
    setshowCopiedSnackbar(false);
    setshowAddToCartSnackbar(false);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(currentPath);
    setshowCopiedSnackbar(true);
  };

  const signIn = () => {
    toggleLogin();
  };

  const handlePictureClick = (id: number) => {
    if (id === currentSelectedPhoto) {
      return;
    } else {
      selectPhotoAction(id);
      redirectToNewItem(id);
    }
  };

  const handleGalleryViewPictureClick = (id: number) => {
    selectPhotoAction(id);
    setShowPhotoDetailsModal(true);
    redirectToNewItem(id);
  };

  const handleAddToLightBoxClose = () => {
    setShowAddToLightBoxModal(false);
  };

  const showPricing = () => {
    if (
      (singleRate ||
        isPartOfPlan ||
        currentItem?.content?.licensingType ===
          ItemTypeLicensing.isEditorial) &&
      currentUser.email &&
      !loading &&
      !planLoading &&
      !setLoading &&
      !audioLoading &&
      !commentsLoading &&
      checkAllowedRoles()
    ) {
      return true;
    } else {
      return false;
    }
  };

  const checkAllowedRoles = () => {
    let isRoleAllowed = false;
    allowedRoles.map((role: RoleTypes) => {
      if (role === currentUser.role) {
        isRoleAllowed = true;
      }
    });

    return isRoleAllowed;
  };

  const getCurrentPath = (id: number) => {
    let path = id.toString() + "/thread/" + threadId;

    const article =
      routeParams &&
      routeParams.contentType === RouteParamsContentTypes.ARTICLE;
    const contentSet =
      routeParams && routeParams.contentType === RouteParamsContentTypes.SET;

    if (article) {
      path =
        "article/" +
        routeParams.contentArticleId +
        "/set/" +
        routeParams.contentSetId +
        "/" +
        id +
        "/thread/" +
        threadId;
    } else if (contentSet) {
      path =
        "set/" + routeParams.contentSetId + "/" + id + "/thread/" + threadId;
    }

    return path;
  };

  const redirectToNewItem = (id: number) => {
    const path = getCurrentPath(id);
    redirectToPhotoDetals(path);
  };

  const handleContexMenu = (event: any) => {
    event.preventDefault();
  };

  const handleRedirectToExternalLink = (currentItem: any) => {
    window.open(currentItem?.content.externalLink!, "_blank");
  };

  const handleAddToLightBoxSnackBarClose = () => {
    setShowAddToLightBoxSnackbar(false);
  };

  const handleAddToLightBoxSnackBarOpen = () => {
    setShowAddToLightBoxSnackbar(true);
  };

  const handleShowAddToLightBoxModal = () => {
    setShowAddToLightBoxModal(true);
  };

  const handlePhotoDetailsModalClose = () =>{
    setShowPhotoDetailsModal(false);
  }

  const handleAddToCartVisibility = (item: ContentInSet): boolean =>{
    if(currentUser?.id){
      
      if(setLoading || item?.content?.isOwned)return false;
      if(item?.content?.licensingType === 'editorial'){
        return true;
      }
      else{
        if(item.contentRate){
          return true;
        }
        else if(item?.content?.licensingType === licensingPlan[0]?.plan?.licensingType){
          return true;
        }
      }
    }
    return false;
  }

  // TODO: pass path_to_file only for audio files!
  const getContent = () => {
    const content = {
      id: currentItem?.content?.id,
      pathToFileCompressed: currentItem?.content?.pathToFileCompressed,
      pathToFileThumbnail: currentItem?.content?.pathToFileThumbnail,
      headline: currentItem?.content?.headline,
      publishDate: currentItem?.content?.publishDate,
      caption: currentItem?.content?.caption,
      code: currentItem?.content?.code,
      type: currentItem?.content?.type,
      licensingType: currentItem?.content?.licensingType,
      externalLink: currentItem?.content?.externalLink,
      externalLinkMessage: currentItem?.content?.externalLinkMessage,
      pathToFile: currentItem?.content?.pathToFile,
      amount:
        licensingPlan.length > 0 ? licensingPlan[0].plan.amount : undefined,
      totalPrice:
        licensingPlan.length > 0 ? licensingPlan[0].plan.totalPrice : undefined,
      period:
        licensingPlan.length > 0 && licensingPlan[0].plan.period
          ? licensingPlan[0].plan.period
          : undefined,
      role: currentUser.role,
    };
    return content;
  };

  return (
    <div
      className={`page-container ${
        isPartOfSet ? "" : "padding-on-single-content"
      }`}
    >
      {/* {loading ? <LoadingSpinner className="loader-spinner" /> : null} */}
      <div className="content-container" onContextMenu={handleContexMenu}>
        {galleryViewEnabled ? (
          <div className="gallery-view-set-container">
            <StackGrid
            monitorImagesLoaded={true}
            duration={duration}
            columnWidth={
              window.innerWidth >= 1820
                ? "20%"
                : window.innerWidth >= 1024
                ? "25%"
                : window.innerWidth >= 768
                ? "33%"
                : "50%"
            }
            easing={easing}
            transition={transition}
            gutterHeight={gutter}
            gutterWidth={gutter}
            rtl={rtl}
            appear={scaleDown.fadeDown}
            appeared={scaleDown.appeared}
            enter={scaleDown.enter}
            entered={scaleDown.entered}
            leaved={scaleDown.leaved}
          >
            {connectedSet
              ? connectedSet.content.map((setItem: ContentInSet) => (
                <div
                key={setItem.content.id}
                className="set-container-image-container"
              >
                <div
                  className={
                    setItem.content.id !== currentItem?.content.id
                      ? "set-container-image image-clickable"
                      : "set-container-image-cover"
                  }
                >
                  <PhotoContainer
                    src={setItem.content.pathToFileThumbnail}
                    alt={setItem.content.headline}
                    key={setItem.content.id}
                    colors={colors}
                    handleShowAddToLightBoxModal={
                      handleShowAddToLightBoxModal
                    }
                    onClick={() => handleGalleryViewPictureClick(setItem.content.id)}
                    contentId={setItem.content.id}
                    content={setItem}
                    showAddToLightBoxButton={
                      currentUser?.id !== undefined && !setLoading
                    }
                    showAddToCartButton={
                      handleAddToCartVisibility(setItem)
                    }
                    showBorder={false}
                  />
                </div>
              </div>
                ))
              : setLoading &&
                Array.from(Array(10).keys()).map(
                  (item: number, index: number) => (
                    <Skeleton
                      variant="rectangular"
                      width={340}
                      height={250}
                      className="image-skeleton"
                    />
                  )
                )}
            </StackGrid>
          </div>
        ) : (
          <div className="set-container">
            {connectedSet
              ? connectedSet.content.map((setItem: ContentInSet) => (
                  <div
                    key={setItem.content.id}
                    className="set-container-image-container"
                  >
                    <div
                      className={
                        setItem.content.id !== currentItem?.content.id
                          ? "set-container-image image-clickable"
                          : "set-container-image-cover"
                      }
                    >
                      <PhotoContainer
                        src={setItem.content.pathToFileThumbnail}
                        alt={setItem.content.headline}
                        key={setItem.content.id}
                        content={setItem}
                        colors={colors}
                        handleShowAddToLightBoxModal={
                          handleShowAddToLightBoxModal
                        }
                        onClick={() => handlePictureClick(setItem.content.id)}
                        contentId={setItem.content.id}
                        showAddToLightBoxButton={
                          currentUser?.id !== undefined && !setLoading
                        }
                        showAddToCartButton={
                          handleAddToCartVisibility(setItem)
                        }
                        showBorder={false}
                      />
                    </div>
                  </div>
                ))
              : Array.from(
                  Array(
                    totalContentCountLoading
                      ? 3
                      : totalContent?.countContentsInSet || 0
                  ).keys()
                ).map((item: number) => (
                  <div key={item} className="set-container-image-container">
                    {/* <div style={{width: '200px',height: '300px', display: 'flex', justifyContent: 'center', alignItems:'center'}}>
                    <CircularProgress style={{'color': '#0caf95'}} size={40} thickness={4}/>
                  </div> */}
                    <div style={{ marginBottom: "0.5rem" }}>
                      <Skeleton
                        variant="rectangular"
                        className="full-width"
                        height={150}
                      />
                    </div>
                  </div>
                ))}
          </div>
        )}
        {!galleryViewEnabled && (
          <div
            className={` image-info-conainer ${
              isPartOfSet ? "imageContainerPartial" : "imageContainerFull"
            }`}
          >
            <div className="image-container">
              <div className="image-wrapper">
                {currentItem ? (
                  <div className={`image-style image-style-fullWidht`}>
                    <div>
                      <PhotoContainer
                        src={currentItem?.content.pathToFileCompressed}
                        alt={decodeURI(currentItem?.content.headline)}
                        colors={colors}
                        onClick={() =>
                          handleGalleryViewPictureClick(currentItem.content.id)
                        }
                        content={currentItem}
                        contentId={currentItem.content.id}
                        showAddToLightBoxButton={
                          !setLoading &&
                          !connectedSet &&
                          currentUser?.id !== undefined
                        }
                        showAddToCartButton={
                          !connectedSet &&
                          handleAddToCartVisibility(currentItem)
                        }
                        handleShowAddToLightBoxModal={
                          handleShowAddToLightBoxModal
                        }
                        showBorder
                      />
                    </div>
                  </div>
                ) : (
                  <div className="image-box">
                    <div className={`image-style image-style-fullWidht`}>
                      <Skeleton variant="rectangular" className="full-width" />
                    </div>
                  </div>
                )}
                {currentItem?.content?.externalLink &&
                currentItem?.content?.externalLink !== "N/A" &&
                currentItem?.content?.externalLinkMessage ? (
                  <button
                    className="external-link-button"
                    onClick={() => handleRedirectToExternalLink(currentItem)}
                  >
                    {decodeURI(currentItem?.content?.externalLinkMessage)}
                  </button>
                ) : null}
              </div>
              <div className="image-description-fullscreen">
                <ShareContainer
                  currentPath={currentPath}
                  copyToClipboard={copyToClipboard}
                />

                <div className="heading-continer">
                  {connectedSet ? (
                    <h3 className="headline">
                      {connectedSet.contentSet.title}
                    </h3>
                  ) : (
                    setLoading && (
                      <p>
                        <Skeleton
                          variant="rectangular"
                          width={400}
                          height={50}
                        />
                      </p>
                    )
                  )}
                  {/* {routeParams.contentType === RouteParamsContentTypes.ARTICLE ||
                  routeParams.contentType === RouteParamsContentTypes.SET ? (
                  <h3 className="headline">{currentItem?.content?.headline}</h3>
                ) : null}
                {routeParams.contentType !== RouteParamsContentTypes.ARTICLE &&
                  routeParams.contentType !== RouteParamsContentTypes.SET &&
                  currentItem ? (
                  <h3 className="headline">{currentItem?.content?.headline}</h3>
                ) : null} */}
                </div>

                <div>
                  {currentItem ? (
                    <Description description={currentItem?.content?.caption} />
                  ) : (
                    <Skeleton variant="rectangular" width={400} height={100} />
                  )}
                  {currentItem ? (
                    <>
                      <h3 className="info-text">
                        Location:{" "}
                        {currentItem?.location ? (
                          <span className="font-weight-normal">
                            {getLocation(currentItem?.location)}
                          </span>
                        ) : null}
                      </h3>

                      <h3 className="info-text">
                        Date:{" "}
                        <span className="font-weight-normal">
                          <Moment format="DD/MM/YYYY">
                            {currentItem
                              ? currentItem?.content?.publishDate
                              : ""}
                          </Moment>
                        </span>
                      </h3>

                      <h3 className="info-text">
                        Photographer:{" "}
                        <span className="font-weight-normal">
                          {currentItem?.copyright.byLine
                            ? currentItem?.copyright.byLine
                            : null}
                        </span>
                      </h3>
                    </>
                  ) : (
                    <>
                      <p>
                        <Skeleton
                          variant="rectangular"
                          width={400}
                          height={50}
                        />
                      </p>
                      <p>
                        <Skeleton
                          variant="rectangular"
                          width={400}
                          height={50}
                        />
                      </p>
                      <p>
                        <Skeleton
                          variant="rectangular"
                          width={400}
                          height={50}
                        />
                      </p>
                    </>
                  )}
                  {audioFile ? (
                    <div className="audio-player">
                      <div className="file-name">{audioFileName}</div>
                      <span>
                        <ReactAudioPlayer
                          src={audioFile.pathToFile}
                          controls
                          controlsList="nodownload"
                          preload="metadata"
                        />
                        {audioFilePrice ? (
                          <span>
                            <h3>
                              {t("Photo.Details.Purchase.Audio.File.Headline")}
                            </h3>
                            <SocialAndPricing
                              item={{
                                content: audioFile,
                                flatRate: audioFilePrice,
                              }}
                              isPartOfPlan={false}
                              isOwned={audioFileOwned}
                              ownedSizes={ownedSizes}
                              currentUser={currentUser}
                            />
                          </span>
                        ) : null}
                      </span>
                    </div>
                  ) : null}
                  {showPricing() ? (
                    <SocialAndPricing
                      item={{
                        content: getContent(),
                        flatRate: currentItem?.contentRate?.flatRate,
                      }}
                      isPartOfPlan={isPartOfPlan}
                      isOwned={isOwned}
                      ownedSizes={ownedSizes}
                      licensingPlan={licensingPlan[0]}
                      currentUser={currentUser}
                    />
                  ) : !currentUser.email ? (
                    <PriceBoxMessageNonLogged signIn={signIn} />
                  ) : null}
                  {/*Open tags when client requests it
                                    <h3 className="info-text">Tags:</h3>
                                  <div className="tag-container">
                                      {
                                          currentItem?.keywords.map((tag: any, index: number) => (
                                              <button className="tags" key={index}># {tag.name}</button>
                                          ))
                                      }

                                  </div>
                                  */}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="Article">
        {isPartOfArticle && connectedSet && connectedSet.article ? (
          <React.Fragment>
            <h3 className="headline">{connectedSet.article.title} </h3>

            <div
              className="article-text"
              dangerouslySetInnerHTML={{
                __html: connectedSet.article.description,
              }}
            ></div>
          </React.Fragment>
        ) : null}
      </div>
      {comments && !loading && currentItem ? (
        <CommentsBox
          color={commentIconColor}
          isLoggedIn={isLoggedIn}
          comments={comments}
          userId={currentUser.id}
          contentId={currentItem?.content.id}
          signIn={signIn}
          refetch={commentsRefetch}
        />
      ) : (
        <div style={{ margin: "1rem" }}>
          <p>
            <Skeleton variant="rectangular" width={400} height={50} />
          </p>
          <CommentSkeleton />
          <CommentSkeleton />
          <Skeleton variant="rectangular" width={600} height={100} />
        </div>
      )}
      <SnackbarComponent
        showSnackbar={showCopiedSnackbar}
        handleClose={handleClose}
        severity="success"
        message="Link copied to clipboard"
      />
      <SnackbarComponent
        showSnackbar={showAddToCartSnackbar}
        handleClose={handleClose}
        severity="success"
        message="Added to cart"
      />
      <SnackbarComponent
        showSnackbar={showBroadcastMessage}
        handleClose={handleBroadcastSnackbarClose}
        severity={broadcastMessage.severity}
        message={broadcastMessage.message}
      />
      <AddToLightBoxModal
        showAddToLightBoxModal={showAddToLightBoxModal}
        handleAddToLightBoxClose={handleAddToLightBoxClose}
        handleAddToLightBoxSnackBarOpen={handleAddToLightBoxSnackBarOpen}
        clickedImageToAddToLightBox={clickedImageToAddToLightBox}
        contentDetailsIds={routeParams}
      />
      <SnackbarComponent
        showSnackbar={showAddToLightBoxSnackbar}
        handleClose={handleAddToLightBoxSnackBarClose}
        severity={lightBoxBroadcast.severity}
        message={lightBoxBroadcast.message}
      />
      <Dialog fullWidth={true}
        maxWidth={"xl"} open={showPhotoDetailsModal}
        onClose={handlePhotoDetailsModalClose} aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handlePhotoDetailsModalClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
            <GalleryVIewPhotoDetails routeParams={routeParams}/>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (
  state: StoreState
): {
  currentUser: User;
  currentSelectedPhoto: number;
  selectedChannel: Channel;
  cartItemsFromSub: ItemForCart[];
  lightBoxBroadcast: IBroadcastMessage;
  clickedImageToAddToLightBox: number;
  galleryViewEnabled: boolean;
  broadcastMessage: IBroadcastMessage;
} => {
  return {
    currentUser: selectCurrentUser(state),
    currentSelectedPhoto: selectCurrentlySelectedPhoto(state),
    selectedChannel: selectCurrentChannel(state),
    cartItemsFromSub: selectCurrentSubItems(state),
    lightBoxBroadcast: selectLightBoxBroadcast(state),
    clickedImageToAddToLightBox: selectClickedImageToAddToLightBox(state),
    galleryViewEnabled: selectGalleryView(state),
    broadcastMessage: selectBroadcastMessage(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<
    | CallHistoryMethodAction
    | TChannelReducerActions
    | TModalReducerActions
    | TCartReducerActions
    | TPhotoDetailsActions
  >
) => {
  return {
    selectChannelAction: (channel: Channel) =>
      dispatch<IAddChannel>({
        type: ChannelActionTypes.ADD_SELECTED_CHANNEL,
        data: channel,
      }),
    selectSubChannelAction: (subChannel: Channel) =>
      dispatch<IAddSubChannel>({
        type: ChannelActionTypes.ADD_SELECTED_SUBCHANNEL,
        data: subChannel,
      }),
    selectThreadAction: (thread: Channel) =>
      dispatch<IAddThread>({
        type: ChannelActionTypes.ADD_SELECTED_THREAD,
        data: thread,
      }),
    redirectToShare: (redirect: string) => dispatch(push(redirect)),
    toggleLogin: () =>
      dispatch<IToggleLogin>({ type: ModalActionTypes.ToggleLoginModal }),
    changePhotoDetailsState: (id: any) =>
      dispatch<TPhotoDetailsActions>({
        type: PhotoDetailsActionTypes.CHANGE_PHOTO_DETAILS,
        data: id,
      }),
    addToCartAction: (item: ItemForCart) =>
      dispatch<IAddToCart>({ type: CartActionTypes.ADD_TO_CART, data: item }),
    addToSubscriptionCartAction: (item: ItemForCart) =>
      dispatch<IAddToSubscriptionCart>({
        type: CartActionTypes.ADD_TO_SUBSCRIPTION_CART,
        data: item,
      }),
    selectPhotoAction: (data: number) =>
      dispatch<ISelectPhotoFromSet>({
        type: PhotoDetailsActionTypes.SELECT_PHOTO_FROM_SET,
        data: data,
      }),
    redirectToPhotoDetals: (path: string) => dispatch(push(`/content/${path}`)),
    clearBroadcastMessage: () =>
    dispatch<IClearBroadcastMessage>({
      type: PhotoDetailsActionTypes.CLEAR_BROADCAST,
    }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PhotoDetails);
