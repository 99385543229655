import { CURRENT_USER } from '../login/queries'
import React, { useEffect } from 'react'
import { useQuery } from 'react-apollo-hooks'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { IToggleNoAccess, IToggleNotLogged, IToggleWrongPath, TPageReducerActions } from '../../redux/error-page/error-page.actions'
import { ErrorPagesTypes } from '../../redux/error-page/error-page.types'
import { ErrorPageProps } from './errorPage.types'
import ErrorPageContainer from './ErrorPageContainer'
import {checkPath} from '../privateRouter/privateRouter'
import { ContributorsRoutes, MemberRoutes, ProMemberRoutes } from '../privateRouter/privateRouter.types'

const ErrorPageComponent: React.FC<ErrorPageProps> = ({ ...props }) => {
    const { 
        toggleNoAccessPageAction, 
        toggleNotLoggedPageAction, 
        toggleWrongPathPageAction ,
    } = props;

    const contributorsRoutes:any[] = Object.values(ContributorsRoutes)
    const proMembersRoutes:any[] = Object.values(ProMemberRoutes)
    const membersRoutes:any[] = Object.values(MemberRoutes)
    const routes = (contributorsRoutes.concat(proMembersRoutes.concat(membersRoutes)))
                    .filter((elem,index,self) => {
                        return index === self.indexOf(elem)
                    })
    const { data, loading, error } = useQuery(CURRENT_USER);

    const currentPath = props.history;

    useEffect(()=>{
        toggleErrorPage()
    },[data])

    const toggleErrorPage = () => {
        if (data) {
            if(!checkPath(routes, currentPath!)){
                toggleWrongPathPageAction();   
            }
            else{
                if (data.currentUser ) {
                    toggleNoAccessPageAction();
                }
                if (!data.currentUser ) {
                    toggleNotLoggedPageAction();
                }
            }
            
        }
    }

    return (
        <div>
            <ErrorPageContainer/>
        </div>
    )
}


const mapDispatchToProps = (dispatch: Dispatch<TPageReducerActions>) => {
    return {
        toggleNoAccessPageAction: () => dispatch<IToggleNoAccess>({ type: ErrorPagesTypes.ToggleNoAccessPage }),
        toggleNotLoggedPageAction: () => dispatch<IToggleNotLogged>({ type: ErrorPagesTypes.ToggleNotLoggedPage }),
        toggleWrongPathPageAction: () => dispatch<IToggleWrongPath>({ type: ErrorPagesTypes.ToggleWrongPathPage })
    }
}

export default connect(null, mapDispatchToProps)(ErrorPageComponent)