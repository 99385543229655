import React, { useState, useEffect } from 'react';

import './ImageUploadingDisplay.styles.scss';
import {connect} from "react-redux";
import {chunkUploadedFiles} from "../../redux/content-upload/content-upload.selectors";
import {StoreState} from "../../redux/root-reducer";

const ImageUploadingDisplay: React.FC<any> = ({...props}) => {
    const {chunkedFile, id} = props;

    // console.log(props);
    if(chunkedFile[id] !== undefined){
        return(
            <div className={'uploadPercentWrap'}>
                <div className={'uploadPercent'} style={{width: chunkedFile[id]*0.98 +'%'}}></div>
            </div>
        )
    }else{
        return (
            <span></span>
        )
    }
}


const mapStateToProps = (state: StoreState): {chunkedFile: any} => {
    return {
        chunkedFile: chunkUploadedFiles(state)
    }
}

export default connect(mapStateToProps, {})(ImageUploadingDisplay);