import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { push, CallHistoryMethodAction } from "connected-react-router";
import FollowChipComponent from './FollowChip';
import ChannelChipComponent from './ChannelChip';
import { useMutation } from "@apollo/react-hooks";
import { SUBSCRIBE, CHECK_FOLLOWING, UNSUBSCRIBE } from './queries';
import { channelScreenProps, CHANNEL_TYPES } from './channelScreen.types';
import './channelScreen.styles.scss';
import variables from '../../assets/globalVariables.module.scss';
import { StoreState } from "redux/root-reducer";
import { generateChannelColorArray, isEmpty } from './helperFunctions'
import { User } from '../../redux/user/user.types';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { composePathSubChannles, composePathChannels } from '../channelsSidebar/helperFunctions';
import {
    TChannelReducerActions,
    IRemoveThread,
    IRemoveSubChannel,
    IRemoveChannel,
    IAddChannel,
    IAddSubChannel,
    IAddThread,
    IToggleGalleryView
} from "../../redux/channel-routes/channel.actions";
import { ChannelActionTypes } from "../../redux/channel-routes/channel.types";
import { selectCurrentChannel, selectCurrentSubChannel, selectCurrentThread, selectGalleryView } from "../../redux/channel-routes/channel.selectors";
import { selectCurrentUser, selectLoadingSearchResults } from "../../redux/user/user.selectors";
import { Channel, TileData } from "../home-component/home.types";
import SearchBar from '../search-bar/searchBar.component'
import { selectFilterDataIsInInitialState, selectTotalResultsChannelContex } from "../../redux/search-bar/search-bar.selectors";
import { ILoadSearchResultsChannelContexCount, ILoadSearchResultsCount, TSearchReducerActions } from "../../redux/search-bar/search-bar.actions";
import { SearchBarActions } from "../../redux/search-bar/search-bar.types";
import { SubChannel, Thread } from "../channelsSidebar/channelSideBars.types";
import { CircularProgress } from "@material-ui/core";
import { FormControlLabel, Switch } from "@mui/material";
import { selectPhotoDetails } from "../../redux/photo-details/photo-details.selector";

const ChannelScreenComponent: React.FC<channelScreenProps> = ({ ...props }) => {
    const {selectedChannel, selectedThread, selectedSubChannel, currentUser, routeParams, galleryViewEnabled,
        isInChannelScreen, totalSearchResults, isFilterDataInInitialState, loadingSearchResults, selectedPhotoDetails, redirectToHome, removeThreadAction, 
        removeSubChannelAction, removeChannelAction, redirectToChannel, redirectToSubChannel, 
        loadTotalResultsAction, selectChannelAction, selectSubChannelAction, selectThreadAction, toggleGalleryView } = props;

    const [colors, setColors] = useState<string[]>([]);
    const [isFollowing, setFollowing] = useState<boolean>(false);
    const [clickFollow, setClickFollowToRerender] = useState<boolean>(false);
    const [subscribe] = useMutation(SUBSCRIBE);
    const [unsubscribe] = useMutation(UNSUBSCRIBE);
    const [checkIfFollowing, {loading}] = useMutation(CHECK_FOLLOWING);

    const onlyChannelSelected = (selectedThread.id === -1 && selectedSubChannel.id === -1);
    const subChannelSelected = (selectedThread.id === -1 && selectedSubChannel.id !== -1);
    const threadSelected = (selectedThread.id !== -1);
    const lowerBreakpoint = 700;

    const handleGalleryViewToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
      toggleGalleryView(event.target.checked);
    };

    useEffect(() => {
        if (onlyChannelSelected) {
            if(!isEmpty(currentUser)) {
                isUserFollowingSelectedChannel(selectedChannel.id, CHANNEL_TYPES.CHANNEL);
            }   
        }
        if (subChannelSelected) {
            if(!isEmpty(currentUser)){
                isUserFollowingSelectedChannel(selectedSubChannel.id, CHANNEL_TYPES.SUBCHANNEL);
            }  
        }
        if (threadSelected) {
            if(!isEmpty(currentUser)){
                isUserFollowingSelectedChannel(selectedThread.id, CHANNEL_TYPES.THREAD);
            }
        }
    }, [clickFollow, selectedSubChannel, selectedThread, selectedChannel]);

    useEffect(() => {
        setColors(generateChannelColorArray(selectedChannel.code, variables))
    }, [selectedChannel]);

    useEffect(() => {
        if (!routeParams?.subchannelName) {
            removeSubChannelAction();
        } 

        if (!routeParams?.threadName) {
            removeThreadAction();
        }
    }, [routeParams]);

    const isUserFollowingSelectedChannel = (selectedId: number, selectedType: string) => {
        checkIfFollowing({
            variables: {
                userId: currentUser.id,
                channelVariationId: selectedId,
                channelVariation: selectedType
            }
        })
            .then((result: any) => {
                setFollowing(result.data.checkIfFollowing)
            });
    }

    const handleClick = (id: number) => {
        if (id === 1 && selectedSubChannel.id !== -1) {
            removeSubChannelAction();
            removeThreadAction();
            selectChannelAction(selectedChannel);
            redirectToChannel(selectedChannel.title);
        }
        if (id === 2 && selectedThread.id !== -1) {
            removeThreadAction();
            selectSubChannelAction(selectedSubChannel);
            redirectToSubChannel(selectedChannel.title, selectedSubChannel.title);
        }
    }

    const handleDelete = () => {
        if (onlyChannelSelected) {
            removeChannelAction();
            redirectToHome();
        }
        if (subChannelSelected) {
            removeSubChannelAction();
            redirectToChannel(selectedChannel.title);
        }
        if (threadSelected) {
            removeThreadAction();
            redirectToSubChannel(selectedChannel.title, selectedSubChannel.title);
        }
    }

    const handleOnFollow = () => {
        if (onlyChannelSelected) {
            subscribe({
                variables: {
                    userId: currentUser.id,
                    channelVariationId: selectedChannel.id,
                    channelVariation: CHANNEL_TYPES.CHANNEL
                }
            }).then((result: any) => {
                setClickFollowToRerender(!clickFollow);
            });
        }
        if (subChannelSelected) {
            subscribe({
                variables: {
                    userId: currentUser.id,
                    channelVariationId: selectedSubChannel.id,
                    channelVariation: CHANNEL_TYPES.SUBCHANNEL
                }
            }).then((result: any) => {
                setClickFollowToRerender(!clickFollow);
            });
        }
        if (threadSelected) {
            subscribe({
                variables: {
                    userId: currentUser.id,
                    channelVariationId: selectedThread.id,
                    channelVariation: CHANNEL_TYPES.THREAD
                }
            }).then((result: any) => {
                setClickFollowToRerender(!clickFollow);
            });
        }
    }

    const handleOnUnfollow = () => {
        if (onlyChannelSelected) {
            unsubscribe({
                variables: {
                    userId: currentUser.id,
                    channelVariationId: selectedChannel.id,
                    channelVariation: CHANNEL_TYPES.CHANNEL
                }
            }).then((result: any) => {
                setClickFollowToRerender(!clickFollow);
            });
        }
        if (subChannelSelected) {
            unsubscribe({
                variables: {
                    userId: currentUser.id,
                    channelVariationId: selectedSubChannel.id,
                    channelVariation: CHANNEL_TYPES.SUBCHANNEL
                }
            }).then((result: any) => {
                setClickFollowToRerender(!clickFollow);
            });
        }
        if (threadSelected) {
            unsubscribe({
                variables: {
                    userId: currentUser.id,
                    channelVariationId: selectedThread.id,
                    channelVariation: CHANNEL_TYPES.THREAD
                }
            }).then((result: any) => {
                setClickFollowToRerender(!clickFollow);
            });
        }
    }

    const renderChip = (includeDeleteButton: boolean, isFirstChip: boolean, label: string, id: number) => {
        return (
            <ChannelChipComponent
                handleClick={() => handleClick(id)}
                handleDelete={handleDelete}
                colors={colors}
                includeDeleteButton={includeDeleteButton}
                label={label}
                isFirstChip={isFirstChip}
            />
        )
    }

    return (

        <div className='mainContainer'>
            <div className="upperBand" style={{ backgroundColor: colors[1] }} key="state">
                <div className="upper-actions">
                    <div className='chip-container'>
                        {/* Rendering Channels section */}
                        {renderChip((!(selectedSubChannel.id !== -1 || selectedThread.id !== -1) && isInChannelScreen), true, selectedChannel.title, 1)}

                        {selectedSubChannel.id !== -1 ? <ArrowForwardIosIcon
                            style={{
                                marginTop: '35px',
                                color: colors[0],
                                fontSize: '1.2rem'
                            }} /> : null}
                        {/* Rendering Subchannels section */}
                        {selectedSubChannel.id !== -1 ?
                            renderChip((selectedSubChannel.id !== -1 && selectedThread.id === -1 && isInChannelScreen), false, window.innerWidth > lowerBreakpoint ? selectedSubChannel.title : (selectedSubChannel.title.length < 10 ? selectedSubChannel.title : (selectedSubChannel.title.substring(0, 10) + '...')), 2) :
                            null
                        }

                        {selectedThread.id !== -1 ? <ArrowForwardIosIcon
                            style={{
                                marginTop: '35px',
                                color: colors[0],
                                fontSize: '1.2rem'
                            }} /> : null}
                        {/* Rendering Thread section */}
                        {
                            selectedThread.id !== -1 ?
                                renderChip(isInChannelScreen, false, window.innerWidth > lowerBreakpoint ? selectedThread.title : (selectedThread.title.length < 10 ? selectedThread.title : (selectedThread.title.substring(0, 10) + '...')), 3) :
                                null
                        }

                        {isInChannelScreen ? <span className='spanResults container-item'>{loadingSearchResults ? <CircularProgress style={{color: colors[0]}} size={20} /> :  totalSearchResults + ' results'}</span> : null}
                        <FollowChipComponent
                            currentUser={currentUser}
                            isFollowing={isFollowing}
                            colors={colors}
                            handleOnFollow={handleOnFollow}
                            handleOnUnfollow={handleOnUnfollow}
                        />
                    </div>
                    {!isInChannelScreen && (selectedPhotoDetails.type === 'Content set' || selectedPhotoDetails.type === 'Article') && <div className="gallery-view-container">
                        <FormControlLabel control={ <Switch
                            checked={galleryViewEnabled}
                            onChange={handleGalleryViewToggle}
                            inputProps={{ 'aria-label': 'controlled' }}
                            />} label="Gallery view" />
                    </div>}
                </div>
                {isInChannelScreen ? 
                <div className='search-bar'>
                    <SearchBar isExpandable={false}/>
                </div> : null }
            </div>
        </div>
    );
};

const mapStateToProps = (state: StoreState): {
    selectedChannel: Channel; selectedThread: SubChannel;
    selectedSubChannel: Thread; currentUser: User; totalSearchResults: number;
    isFilterDataInInitialState: boolean;loadingSearchResults: boolean; galleryViewEnabled: boolean; selectedPhotoDetails: TileData
} => {
    return {
        selectedChannel: selectCurrentChannel(state),
        selectedSubChannel: selectCurrentSubChannel(state),
        selectedThread: selectCurrentThread(state),
        currentUser: selectCurrentUser(state),
        totalSearchResults: selectTotalResultsChannelContex(state),
        isFilterDataInInitialState: selectFilterDataIsInInitialState(state),
        loadingSearchResults: selectLoadingSearchResults(state),
        galleryViewEnabled: selectGalleryView(state),
        selectedPhotoDetails: selectPhotoDetails(state),
    }
}


const mapDispatchToProps = (dispatch: Dispatch<CallHistoryMethodAction | TSearchReducerActions | TChannelReducerActions>) => {
    return {
        redirectToHome: () => dispatch(push('/')),
        redirectToSubChannel: (channel: string, subChannel: string) => dispatch(push(`/channels/${composePathSubChannles(channel, subChannel)}`)),
        redirectToChannel: (channel: string) => dispatch(push(`/channels/${composePathChannels(channel)}`)),
        removeThreadAction: () => dispatch<IRemoveThread>({
            type: ChannelActionTypes.REMOVE_SELECTED_THREAD
        }),
        removeSubChannelAction: () => dispatch<IRemoveSubChannel>({
            type: ChannelActionTypes.REMOVE_SELECTED_SUBCHANNEL
        }),
        removeChannelAction: () => dispatch<IRemoveChannel>({
            type: ChannelActionTypes.REMOVE_SELECTED_CHANNEL
        }),
        loadTotalResultsAction: (data: number) => dispatch<ILoadSearchResultsChannelContexCount>({
            type: SearchBarActions.LOAD_TOTAL_RESULTS_CHANNEL_CONTEX, 
            data: data
        }),
        selectChannelAction: (channel: Channel) => dispatch<IAddChannel>({
            type: ChannelActionTypes.ADD_SELECTED_CHANNEL,
            data: channel
        }),
        selectSubChannelAction: (subChannel: SubChannel) => dispatch<IAddSubChannel>({
            type: ChannelActionTypes.ADD_SELECTED_SUBCHANNEL,
            data: subChannel
        }),
        selectThreadAction: (thread: Thread) => dispatch<IAddThread>({
            type: ChannelActionTypes.ADD_SELECTED_THREAD,
            data: thread
        }),
        toggleGalleryView: (data: boolean) => dispatch<IToggleGalleryView>({
            type: ChannelActionTypes.TOGGLE_GALLERY_VIEW,
            data: data
        }),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ChannelScreenComponent);