import { ChannelContextRouteParams } from "../channels/channels.types";
import { User } from "../../redux/user/user.types";
import { Channel, TileData } from "../home-component/home.types";
import { SubChannel, Thread } from "../channelsSidebar/channelSideBars.types";

export interface channelScreenProps {
  selectedChannel: Channel;
  selectedSubChannel: SubChannel;
  selectedThread: Thread;
  totalSearchResults: number;
  routeParams?: ChannelContextRouteParams;
  isFilterDataInInitialState: boolean;
  galleryViewEnabled: boolean;
  selectedPhotoDetails: TileData;
  redirectToHome: () => void;
  removeSubChannelAction: () => void;
  removeThreadAction: () => void;
  removeChannelAction: () => void;
  redirectToChannel: (channel : string) => void;
  redirectToSubChannel: (channel:string, subChannel: string) => void;
  loadTotalResultsAction: (data: number) => void;
  selectChannelAction: (channel: Channel) => void;
  selectSubChannelAction: (subChannel: SubChannel) => void;
  selectThreadAction: (thread: Thread) => void;
  toggleGalleryView: (data: boolean) => void;
  currentUser: User;
  isInChannelScreen: boolean;
  loadingSearchResults: boolean;
  
}

export const CHANNEL_TYPES = {
  CHANNEL : "CHANNEL",
  SUBCHANNEL : "SUBCHANNEL",
  THREAD : "THREAD"
}



