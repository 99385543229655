import React, { useEffect, useState } from "react";
import {compose} from 'lodash/fp';
import {GET_CHANNELS} from './queries';
import {removeSpaces, removeSpecialChars} from '../navigation/helperFunctions';
import {connect} from "react-redux";
import {Dispatch} from "redux";
import { push, CallHistoryMethodAction } from "connected-react-router";
import { ChannelProps } from './channels.types';
import { useQuery } from "@apollo/react-hooks";
import { Channel } from "../home-component/home.types";
import { ChannelActionTypes } from "../../redux/channel-routes/channel.types";
import { IAddChannel, IRemoveChannel, IRemoveSubChannel, IRemoveThread, TChannelReducerActions } from "../../redux/channel-routes/channel.actions";
import './channelsNavigation.styles.scss';
import {useTranslation} from 'react-i18next';
import { selectSearchBarExpanded } from "../../redux/search-bar/search-bar.selectors";
import { StoreState } from "../../redux/root-reducer";
import { Collapse, Paper } from "@material-ui/core";

const ChannelsComponent: React.FC<ChannelProps> = ({...props}) => {
    const {t} = useTranslation();
    const [channels, setChannels] = useState<Channel[]>([]);
    const {data, loading, error} = useQuery(GET_CHANNELS);
    const { searchBarExpanded, selectChannelAction, redirectToChannel, removeChannelAction, removeSubChannelAction, removeThreadAction } = props;

    useEffect(() => {
        if(data && data.getAllChannels) {
            const sortedChannels: Channel[] = data.getAllChannels;
            sortedChannels.sort(function (a: Channel, b: Channel) {
                if (a.title < b.title) {
                  return -1;
                }
                if (a.title > b.title) {
                  return 1;
                }
                return 0;
              });
            setChannels([...sortedChannels]);
        }
    }, [data]);

    const handleOnClick = (channel: Channel) => {
        removeChannelAction();
        removeSubChannelAction();
        removeThreadAction();
        selectChannelAction(channel);
        redirectToChannel(channel.title);
    }

    return (
        <div className='channels-container'>
            {channels.map(channel => {
                return <button onClick={() => handleOnClick(channel)} className={`channel-button ${channel.code}`}
                                key={channel.id}>{t('Channel.' + channel.code)}</button>
            })}
        </div>  
    );
};

const mapStateToProps = (state: StoreState) : {searchBarExpanded: boolean} => {
    return {
        searchBarExpanded: selectSearchBarExpanded(state)
    }
}

const mapDispatchToProps = (dispatch: Dispatch< CallHistoryMethodAction | TChannelReducerActions>) => {
    return {
        selectChannelAction: (channel: Channel) => dispatch<IAddChannel>({
            type: ChannelActionTypes.ADD_SELECTED_CHANNEL,
            data: channel
        }),
        redirectToChannel: (channel : string) => dispatch(push(`/channels/${compose(removeSpaces, removeSpecialChars)(channel)}`)),
        removeThreadAction: () => dispatch<IRemoveThread>({
            type: ChannelActionTypes.REMOVE_SELECTED_THREAD
          }),
        removeSubChannelAction: () => dispatch<IRemoveSubChannel>({
            type: ChannelActionTypes.REMOVE_SELECTED_SUBCHANNEL
        }),
        removeChannelAction: () => dispatch<IRemoveChannel>({
            type: ChannelActionTypes.REMOVE_SELECTED_CHANNEL
        }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChannelsComponent);