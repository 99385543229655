import React,{useState, useEffect} from 'react';
import { IBroadcastMessage, LightBoxComponentProps, LightBoxProps } from './lightBox.types';
import LightBoxCard from './LightBoxCard';
import AddIcon from '@mui/icons-material/Add';
import { Button, Pagination } from '@mui/material';
import './lightBox.styles.scss'
import { useMutation } from 'react-apollo-hooks';
import { CREATE_OR_ADD_TO_LIGHT_BOX, DELETE_LIGHT_BOX, FETCH_LIGHT_BOXES } from './queries';
import { selectCurrentUser } from '../../redux/user/user.selectors';
import { connect } from 'react-redux';
import { StoreState } from '../../redux/root-reducer';
import { User } from '../../redux/user/user.types';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TablePagination, TextField } from '@material-ui/core';
import SyncLoader from 'react-spinners/SyncLoader';
import { LightBoxActionTypes } from '../../redux/lightbox/lightbox.types';
import { IBroadcastLightBoxMessage, IClearBroadcast, TLightBoxActions } from '../../redux/lightbox/lightbox.actions';
import { Dispatch } from 'redux';
import { selectLightBoxBroadcast } from '../../redux/lightbox/lightbox.selector';
import { SeveritySnackbarEnum } from '../../components/batch-upload/ContentUpload.types';
import { SnackbarComponent, ConfirmationDialog } from  "shared";
import { useQuery, useLazyQuery } from '@apollo/react-hooks';


const LightBox: React.FC<LightBoxComponentProps> = ({...props})=>{
    const {currentUser, broadcastAction, lightBoxBroadcast, clearBroadcast} = props;
    const [lightBoxes,setLightBoxes] = useState<LightBoxProps[]>([])
    const [totalPages,setTotalPages] = useState<number>(0)
    const [showLightBoxModal,setShowLightBoxModal] = useState<boolean>(false);
    const [creatingLightBox,setCreatingLightBox] = useState<boolean>(false);
    const [deletingLightBox,setDeletingLightBox] = useState<boolean>(false);
    const [titleError,setTitleError] = useState<boolean>(false);
    const [lightBoxSnackbar,setLightBoxSnackbar] = useState<boolean>(false);
    const [confirmationDialogOpen,setConfirmationDialogOpen] = useState<boolean>(false);
    const [deleteLightBoxId,setDeleteLightBoxId] = useState<number>(0);
    const [pageNumber,setPageNumber] = useState<number>(0);
    const [selectedLightBoxTitle,setSelectedLightBoxTitle] = useState<string>("");
    const [contentPerPage,setContentPerPage] = useState<number>(10);
    const [createLightBoxMutation] = useMutation(CREATE_OR_ADD_TO_LIGHT_BOX);
    const [deleteLightBoxMutation] = useMutation(DELETE_LIGHT_BOX);
    const [value, setValue] = useState<string | undefined>();

    const [fetchLightBoxes,{data,loading,error}] = useLazyQuery(FETCH_LIGHT_BOXES,{
        variables: {
            userId: currentUser?.id,
            pageNumber: 1,
            contentPerPage
        },
        fetchPolicy: 'cache-and-network'
    });

    const createLightBox = (lightBoxTitle: string)=>{
        createLightBoxMutation({
            variables: {
                userId: currentUser?.id,
                lightboxName: lightBoxTitle
            }
        }).then((res: any)=>{
            if(res.data.createOrAddToLightbox){
                setShowLightBoxModal(false);
                broadcastAction({severity: SeveritySnackbarEnum.success, message: "Light box created successfully"});
                fetchLightBoxes();
            }
        }) .catch(e => {
            broadcastAction({severity: SeveritySnackbarEnum.error, message: "Light box creation failed"});
        })
    }

    const deleteLightBox = (lightBoxId: number)=>{
        deleteLightBoxMutation({
            variables: {
                lightboxId: lightBoxId
            }
        }).then((res: any)=>{
            if(res.data.deleteLightbox){
                setConfirmationDialogOpen(false);
                setDeletingLightBox(false);
                broadcastAction({severity: SeveritySnackbarEnum.success, message: "Light box deleted successfully"});
                fetchLightBoxes();
            }
        }) .catch(e => {
            setDeletingLightBox(false);
            setConfirmationDialogOpen(false);
            broadcastAction({severity: SeveritySnackbarEnum.error, message: "Light box deletion failed"});
        })
    }

    const handleChange = (event: any) => {
        event.preventDefault();

        const value = event.target.value;
        setValue(value);
    }

    const openLightBoxModal = ()=>{
        setShowLightBoxModal(true);
    }

    const handleClose = ()=>{
        setShowLightBoxModal(false);
    }

    const handleSnackBarClose = () => {
        setLightBoxSnackbar(false);
        clearBroadcast();
    }

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPageNumber(value);
        fetchLightBoxes({variables: {
            userId: currentUser?.id,
            pageNumber: value,
            contentPerPage
        }})
    };

    const handleConfirmationDialogClose = () => {
        setConfirmationDialogOpen(false);
    };

    const handleConfirmationDialogAgree = () => {
        setDeletingLightBox(true);
        deleteLightBox(deleteLightBoxId)
    }

    useEffect(()=>{
        fetchLightBoxes();
    },[]);

    useEffect(()=>{
    if(data){
        setLightBoxes(data.getUserLightboxes.lightboxes);
        const totalLightBoxes = data.getUserLightboxes.total;
        if(totalLightBoxes > 0){
            if(totalLightBoxes % contentPerPage === 0){
                setTotalPages(totalLightBoxes / contentPerPage)
            }
            else{
                setTotalPages(Math.floor(totalLightBoxes / contentPerPage)+1)
            }
        }

    }
    },[data]);

    useEffect(()=>{
        if(value && (value?.length <=3 || value?.length >=30)){
            setTitleError(true);
        }
        else{
            setTitleError(false);
        }
    },[value]);
    useEffect(() => {
        if(lightBoxBroadcast.message && lightBoxBroadcast.severity) {
          setLightBoxSnackbar(true);
        }
      }, [lightBoxBroadcast]);


    return <div className="container">
        <div className='headline'>
            <span className='headline-title'>My light boxes</span>
            <button className='add-button' onClick={openLightBoxModal}>
                Create Light box
            </button>
        </div>
       <div className="light-box-container">
        <div className='light-boxes'>
            {
                loading ?  <SyncLoader css={`display: block; margin: 0 auto; border-color: red;`} size={20} color={"#36D2B3"} loading={loading}/> : lightBoxes.length === 0 && "You don't have any light boxes yet."
            }
            {
                !loading && lightBoxes.map((lightbox: LightBoxProps)=>{
                return <LightBoxCard {...lightbox} handleConfirmationDialogOpen={setConfirmationDialogOpen} handleDeleteLightBox={setDeleteLightBoxId} handleSelectedLightBoxTitle={setSelectedLightBoxTitle} />
                })
            }
        </div>
        <Pagination style={{margin: '0 auto 1rem auto'}} count={totalPages} page={pageNumber === 0 ? 1 : pageNumber} onChange={handlePageChange} />
       </div>
       <ConfirmationDialog open={confirmationDialogOpen} title={`Are you sure you want to delete "${selectedLightBoxTitle}"?`}
                loading={deletingLightBox}
                contentText={""}
                rejectButtonText={"Cancel"} acceptButtonText={"Delete"}
                handleClose={handleConfirmationDialogClose}
                handleConfirmationDialogAgree={handleConfirmationDialogAgree} />
       <Dialog open={showLightBoxModal} keepMounted
                onClose={handleClose} aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description">
                <DialogTitle id="alert-dialog-slide-title">{"Create LightBox"}</DialogTitle>
                <DialogContent>
                   
                        <TextField autoFocus margin="dense" label="Enter light box title"
                            type="text" fullWidth name='dialogTextField' value={value}
                            error={titleError}
                            helperText={titleError ? "Text must contain more than 3 and less than 30 characters" : ""}
                            data-shrink={false} onChange={handleChange} />
                </DialogContent>
                {creatingLightBox ? 
                    <div style={{display: 'flex', justifyContent: 'center',alignItems: 'center', width: '100%', height: '40px'}}>
                        <SyncLoader css={`display: block; margin: 0 auto; border-color: red;`} size={10} 
                        color={"#36D2B3"} loading={creatingLightBox}/>
                    </div> : 
                    <DialogActions>
                   
                    <Button variant="outlined" onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                   
                    <Button variant="outlined"
                    onClick={() => {if(value){
                        createLightBox(value)}
                    }} color="primary">
                    Create
                    </Button>
                </DialogActions>
                }
            </Dialog>
            <SnackbarComponent
                showSnackbar={lightBoxSnackbar}
                handleClose={handleSnackBarClose}
                severity={lightBoxBroadcast.severity}
                message={lightBoxBroadcast.message}
            />
    </div>
}

const mapStateToProps = (state: StoreState): {
    currentUser: User;
    lightBoxBroadcast: IBroadcastMessage;
  } => {
    return {
      currentUser: selectCurrentUser(state),
      lightBoxBroadcast: selectLightBoxBroadcast(state)
    };
  };

  const mapDispatchToProps = (dispatch: Dispatch<TLightBoxActions>) => {
    return {
        broadcastAction: (data: IBroadcastMessage) => dispatch<IBroadcastLightBoxMessage>({
            type: LightBoxActionTypes.BROADCAST_MESSAGE, data: data
        }),
        clearBroadcast: () => dispatch<IClearBroadcast>({type: LightBoxActionTypes.CLEAR_BROADCAST})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LightBox);
  