import { LightBoxActionTypes, LightBoxState } from "./lightbox.types";
import { IBroadcastMessage } from "../../components/LightBox/lightBox.types";
import { TLightBoxActions } from "./lightbox.actions";

const InitialState: LightBoxState = {
  lightBoxBroadcast: {} as IBroadcastMessage,
};

export const lightBoxReducer = (
  state = InitialState,
  action: TLightBoxActions
): LightBoxState => {
  switch (action.type) {
    case LightBoxActionTypes.BROADCAST_MESSAGE:
      return {
        ...state,
        lightBoxBroadcast: action.data,
      };
    case LightBoxActionTypes.CLEAR_BROADCAST:
      return {
        ...state,
        lightBoxBroadcast: {} as IBroadcastMessage,
      };
    default:
      return state;
  }
};
export default lightBoxReducer;
