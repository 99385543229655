import {SubscriptionState , SubscriptionActionTypes } from "./subscription-page.types";
import { TSubscriptionReducerActions } from "./subscription-page.actions";
import { LicensePlan } from "../../components/photo-details/photo-details.types";
import { addSubToCart, calculateSubtotalSub } from "./subscription-page.helperFunctions";
import { IBroadcastMessage } from "../../components/batch-upload/ContentUpload.types";
import { getToTwoDecimals } from "../../components/cart/cart-page-helperFunctions";

const InitialState: SubscriptionState = {
  subscriptionPlan: {} as LicensePlan,
  subTotal: 0,
  total: 0,
  taxForOrder: null,
  subscriptionBroadcast: {} as IBroadcastMessage
};

const subscriptionReducer = (state = InitialState, action: TSubscriptionReducerActions): SubscriptionState => {
  switch (action.type) {
    case SubscriptionActionTypes.ADD_SUBSCRIPTION:
      return {
        ...state,
        subscriptionPlan: action.data,
        subTotal: parseFloat(getToTwoDecimals(action.data.totalPrice))
      };
    case SubscriptionActionTypes.REMOVE_SUBSCRIPTION:
      return {
        ...state,
        subscriptionPlan: {} as LicensePlan,
        taxForOrder: null
      };
    case SubscriptionActionTypes.ADD_TAX: 
      return {
        ...state,
        total: parseFloat(getToTwoDecimals(state.subTotal + action.data.amountToCollect)),
        taxForOrder: action.data
      }
    case SubscriptionActionTypes.BROADCAST_SUBSCRIPTION_MESSAGE:
      return {
        ...state,
        subscriptionBroadcast: action.data
      }
    case SubscriptionActionTypes.CLEAR_BROADCAST:
      return {
        ...state,
        subscriptionBroadcast: {} as IBroadcastMessage
      }
    case SubscriptionActionTypes.EMPTY_SUBSCRIPTION:
      return {
        ...state,
        subscriptionPlan: {} as LicensePlan,
        subTotal: 0,
        total: 0,
        taxForOrder: null
      };
    default:
      return state;
  }
};

export default subscriptionReducer;
