import React from "react";
import './contact.styles.scss';
import { Button, Link, TextField } from "@mui/material";

const Contact: React.FC<any> = ({...props}) => {

  return <div className="contact-container">
    <p className="contact-title">Contact Us</p>
    <form className="contact-form">
      <TextField label="Name" variant="outlined" className="form-field" />
      <TextField label="Email" variant="outlined" className="form-field" />
      <TextField label="Subject" variant="outlined" className="form-field" />
      <TextField label="Message" variant="outlined" multiline rows={4} className="form-field" />
      <Button variant="contained" color="primary" type="submit">
        Submit
      </Button>
    </form>
  </div>;
};

export default Contact;