import axios from 'axios';
import { UploadedFileResponse } from '../../components/batch-upload/ContentUpload.types';
import { IFileWithMeta } from 'react-dropzone-uploader';
import { UploadedProfileImageResponse } from './onboarding-page.types';

export const uploadToBucketProfileImage = async (uploadedFile: IFileWithMeta, userId: number, shortBiography: string, url: string, config: any) => {
    const formData = new FormData();

    const operation = {
        "query":`mutation addAboutYouInformation($userId: Int!, $file: Upload!, $shortBiography: String!) { 
            addAboutYouInformation(userId: $userId, file: $file, shortBiography: $shortBiography)
        }`, "variables" : {"userId": userId, "file": null, "shortBiography": shortBiography}
    }

    const map = {"0":["variables.file"]};

    formData.append('operations', JSON.stringify(operation));
    formData.append('map', JSON.stringify(map));
    formData.append('0', uploadedFile.file);

    return axios({
        method: 'POST',
        url: url,
        data: formData,
        headers: config
    })
    .then((response: any) => { 
        if(response.data.errors) {
            return Promise.reject(response.data.errors[0].message);
        }

        const responseProps = response.data.data.addAboutYouInformation;

        const fileResponse: UploadedProfileImageResponse = {
            filename: responseProps.filename,
            mimetype: responseProps.mimetype,
            encoding: responseProps.encoding,
            key: responseProps.key,
            url: responseProps,
            blobObject: uploadedFile.file
        }
        //UploadedFileResponse
        return Promise.resolve(responseProps);
    })
    .catch((error: any) => {
        return Promise.reject(error);
    });
}