import { TranslationsActionTypes, TranslationsState } from './translations.types';
import {TTranslationsReducerActions} from './translations.actions';

const InitialState: TranslationsState = {
    language: 'en'
};

export const translationsReducer = (state = InitialState, action: TTranslationsReducerActions) : TranslationsState => {
    switch(action.type) {
        case TranslationsActionTypes.ChangeLanguage:
            return {
                language: action.data
            };
        default:
            return state;
    }
}

export default translationsReducer;