import { TLocalContentReducerActions } from "./local-content.actions";
import { LocalContentActionTypes, LocalContentState } from "./local-content.types";

const InitialState: LocalContentState = {
    localContentData: []
}

export const localContentReducer = (state = InitialState, action: TLocalContentReducerActions): LocalContentState => {
    switch(action.type) {
        case LocalContentActionTypes.LOADED_DATA:
            return { 
                localContentData: action.data
            }
        case LocalContentActionTypes.RESET_LOADED_CONTENT:
            return { 
                localContentData: []
            }
        default: 
            return state;
    }
}

export default localContentReducer;