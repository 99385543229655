import { createStore, applyMiddleware } from 'redux';
import { rootReducer } from './root-reducer';
import { routerMiddleware } from 'connected-react-router'
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import storage from 'redux-persist/lib/storage';
import { persistStore } from "redux-persist";

export const history = createBrowserHistory();

export const rootConfig = {
        key: 'root',
        storage: storage,
        blacklist: ['modal, contentUpload']
};

export const publicStore = createStore(rootReducer(history),
        applyMiddleware(thunk, routerMiddleware(history)));

export const persistedStore = persistStore(publicStore);