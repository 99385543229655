import React from "react";
import {Dialog} from "@material-ui/core";
import RegisterContainer from "./RegisterContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { StoreState } from "../../redux/root-reducer";
import { connect } from "react-redux";
import LoginContainer from "../login/LoginContainer";
import { Dispatch } from "redux";
import { TModalReducerActions, IResetToggles, IToggleRegister, IToggleRegisterAsRole } from "../../redux/modal-visibility/modal.actions";
import { ModalActionTypes } from "../../redux/modal-visibility/modal.types";
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import ForgotPasswordContainer from "../forgot-password/ForgotPasswordContainer";
import { useTranslation } from 'react-i18next';
import "./register.styles.scss";
import { IToggleBannerHidden, TSignInBannerReducerActions, ISetRegisterType } from "../../redux/sign-in-banner/sign-in-banner.actions";
import { BannerActionTypes } from "../../redux/sign-in-banner/sign-in-banner.types";
import { RegisterModalProps, dialogStyles } from './register.types'
import { selectForgotPasswordModal, selectLoginModal, selectRegisterAsRoleModal, selectRegisterModal } from "../../redux/modal-visibility/modal.selectors";
import { selectRegisteredUser } from "../../redux/user/user.selectors";

const RegisterModal: React.FC<RegisterModalProps> = ({ ...props }) => {

    const { registeredUser, toggleLoginModal, toggleForgotPasswordModal, toggleRegisterModal, resetTogglesModalAction, toggleRegister, toggleBanner, setRegisterType, toggleRegisterAsRole, toggleRegisterAsRoleModal } = props;
    const styles=dialogStyles();
    const handleOpenRegister = () => {
        toggleRegister()
        toggleBanner(true)
    }

    const handleCloseRegister = () => {
        resetTogglesModalAction();
        setRegisterType('pro_organization') //TODO: return to 'member' when clients want member functionalities
    }

    const LoadToggledModal = () => {
        if (toggleLoginModal)
            return <LoginContainer />;
        else if (toggleForgotPasswordModal)
            return <ForgotPasswordContainer />;
        else
            return <RegisterContainer />;
    }

    return (
        <div>
                <Dialog 
                    classes = {{root: styles.dialogRoot, paper: styles.dialogPaper, paperWidthSm: styles.dialogPaperWidthSm}}
                    style={{zIndex: 10000}}
                    closeAfterTransition={true}
                    onClose={handleCloseRegister}
                    open={toggleRegisterModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 800
                    }}>

                    <Fade in={toggleRegisterModal}>
                        <div className='register-modal'>
                            <button className='close-button-register' aria-label='google' onClick={handleCloseRegister}>
                                <FontAwesomeIcon className='icon-button-register' icon={faTimes}/>
                            </button>
                            {LoadToggledModal()}
                        </div>
                    </Fade>
                </Dialog>
        </div>
    )
}

const mapStateToProps = (state: StoreState): { registeredUser: boolean, toggleLoginModal: boolean, toggleForgotPasswordModal: boolean, toggleRegisterModal: boolean, toggleRegisterAsRoleModal: boolean } => {
    return {
        registeredUser: selectRegisteredUser(state),
        toggleLoginModal: selectLoginModal(state),
        toggleForgotPasswordModal: selectForgotPasswordModal(state),
        toggleRegisterModal: selectRegisterModal(state),
        toggleRegisterAsRoleModal: selectRegisterAsRoleModal(state)
    }
}

const mapDispatchToProps = (dispatch: Dispatch<TModalReducerActions | TSignInBannerReducerActions>) => {
    return {
        resetTogglesModalAction: () => dispatch<IResetToggles>({ type: ModalActionTypes.ResetTogglesModal }),
        toggleRegister: () => dispatch<IToggleRegister>({ type: ModalActionTypes.ToggleRegisterModal }),
        toggleBanner: (show: boolean) => dispatch<IToggleBannerHidden>({ type: BannerActionTypes.TOGGLE_BANNER_HIDDEN, data: show }),
        setRegisterType: (registerType: string) => dispatch<ISetRegisterType>({ type: BannerActionTypes.SET_REGISTER_TYPE, data: registerType }),
        toggleRegisterAsRole: () => dispatch<IToggleRegisterAsRole>({ type: ModalActionTypes.ToggleRegisterAsRoleModal })
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(RegisterModal);