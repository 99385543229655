export const translationsEN = {

  //SALES TABLE
  "Sales.Column.Preview": "Preview",
  "Sales.Column.Buyer": "Buyer",
  "Sales.Column.Code": "Code",
  "Sales.Column.Headline": "Headline",
  "Sales.Column.Invoice": "Invoice",
  "Sales.Column.Date": "Date",
  "Sales.Column.Income": "Income",
  "Sales.Column.Tax": "Tax",
  "Sales.NoResults.Message": "You have not sold any contents",
  "Sales.AddMore.Button": "Add more",
  "Sales.Title": "My sales",
  "Error.Fetch.Sales": "Error when fetching sold contents.",

  //CONTRIBUTER TABLE
  "ContributorTable.Column.Preview": "Preview",
  "ContributorTable.Column.Code": "Code",
  "ContributorTable.Column.Headline": "Headline",
  "ContributorTable.Column.Type": "Type",
  "ContributorTable.Column.Status": "Status",
  "ContributorTable.Column.UploadedOn": "Uploaded on",
  "ContributorTable.Column.PublishedOn": "Published on",
  "ContributorTable.Column.Navigation": "Navigation",
  "ContributorTable.Column.Location": "Location",
  "ContributorTable.Column.ExternalLink": "External link",
  "ContributorTable.NoResults.Message": "You have not uploaded any content",
  "ContributorTable.AddMore.Button": "Add more",
  "ContributorTable.Title": "Uploaded content",
  "Error.Fetch.UploadedContent": "Error when fetching uploaded content.",

  //Download TABLE
  "DownloadTable.Column.Thumbnail": "Thumbnail",
  "DownloadTable.Column.Code": "Code",
  "DownloadTable.Column.Title": "Title",
  "DownloadTable.Column.DatePurchased": "Date purchased",
  "DownloadTable.Column.PhotographerName": "Photographer Name",
  "DownloadTable.Column.SizePurchased": "Size purchased",
  "DownloadTable.Column.Link": "Link",
  "DownloadTable.Column.Download": "Download",
  "Error.Fetch.Downloads": "Error when fetching purchased content.",
  "Error.Download.Content": "Error when downloading content.",
  "DownloadTable.NoResults.Message": "You have not purchased any content",

  //PURCHASE HISTORY
  "MyDownloads.Title": "Downloads",
  
  //MODALS
  "ChangePassword.Success": "Password changed successfully.",
  "ChangePassword.Error" : "Error while changing password",
  "Warning.Password.Required": "Password is required",
  "Password.Regex": "Field required at least 8 Characters, one uppercase letter, one number and one special case character",
  "Confirm.Password.Message": "Both password need to be the same",
  "ChangePassword.Label": "Change password",
  "ConfirmPassword.Placeholder": "Confirm new password",
  "NewPassword.Placeholder": "New password",
  "Register.Wrapper.RegisterAs": "Register as a:",
  "Register.Wrapper.Member.Message": "For individuals who want to follow content and use our social media tools for free",
  "Register.Wrapper.Pro.Message": "For businesses and professionals who need to buy content for work",
  "Register.Wrapper.Contributor.Message": "For professionals and the talented who wish to sell their content",
  "Register.Wrapper.Pro.Button" : "Pro Member",
  "Register.Wrapper.Contributor.Button" : "Contributor",
  "Register.Wrapper.Member.Button" : "Become a Member",

  //NAVBAR & BANNER
  "Navbar.Home.Button": "Home",
  "Navbar.SearchBar": "Search",
  "Navbar.AboutUs": "About us",
  "Cart.Button": "Cart",
  "Register.button": "Register",
  "UserNav.My.Content": "My content",
  "UserNav.LightBox": "LightBox",
  "UserNav.My.Sales": "My sales",
  "UserNav.Upload.Content": "Upload content",
  "UserNav.Logout": "Logout",
  "UserNav.My.Licensing.Plans": "My license plans",
  "UserNav.Downloads": "Downloads",
  "Banner.Login.button": "Sign in",
  "Banner.RegisterAsContributor.button": "Become a Contributor",
  "Banner.RegisterAsMember.button": "Become a Member",
  "Banner.RegisterAsPro.button": "Become a Pro member",
  "Login.Banner.Message": "Sign in",
  "SignIn.noAccount": "Don’t have an account yet?",
  "Login.Banner.SignIn.Message": "Sign in to world illustrated to access member only features",
  "Login.Banner.Register.Message": "Share your own content and access member only features",
  "Login.Banner.Free": "it's free",
  "Login.Banner.NoAccount.Message": "Don’t have an account yet?",
  "Login.Banner.Contributor.Message": "Are you a professional or talented photographer, videographer or journalist? Publish and sell your work via world illustrated",
  "Login.Banner.Pro.Message": "Are you a professional who needs to license content for work?",

  //Content upload messages
  "Article.Snackbar.Upload.Success": "Article uploaded successfully",
  "Article.Snackbar.Upload.Fail": "Article upload failed",
  "Article.Snackbar.Wrong.Type": "Uploaded wrong file type! Only .txt files allowed",
  "Article.Snackbar.Update.Success": "Article updated successfully",
  "Article.Snackbar.SaveChanges.Fail": "Article heading and description must contain more than 1 symbol and heading must not exceed 200 symbols",
  "Article.Snakbar.RemoveArticle.Success": "Article and audio file removed successfully",
  "Article.Snackbar.Remove.Fail": "Failed to delete article with id: ",
  "Article.Name.Placeholder": "Set article name...",
  "Article.SunEditor.Placeholder": "Please type here...",
  "Article.Remove.Button": "Remove article",
  "Article.Save.Changes": "Save changes",
  "Article.UploadTXT.Button": "Upload TXT",
  "Audio.Upload.Button": "Upload audio file",
  "Audio.Snackbar.Remove.Success": "Audio file removed successfully",
  "Audio.Snackbar.Upload.Fail": "Failed to upload audio file. Try uploading again",
  "Audio.Snackbar.Upload.Success": "Audio file uploaded successfully",
  "ContentUpload.Snackbar.SelectNav": "At least one channel navigation must be selected per image or content set before completing the upload",
  "ContentUpload.Snackbar.SelectCoverTitle": "All content sets must have a title and cover photo specified.",
  "ContentUpload.AssingToThread": "Linking content to channels...",
  "ContentUpload.Snackbar.Success": "Content uploaded successfully to world illustrated. Redirecting to next page...",
  "ContentUpload.Snackbar.AssignToThread.Fail": 'Failed to link threads to content. Retry?',
  "ContentUpload.Snackbar.Uploading": "Uploading audio files...",
  "ContentUpload.Audio.Fail": "Audio file upload failed. Retry?",
  "ContentUpload.MultipleAudio.Upload.Fail": "Failed to upload certain audio files: ",
  "ContentUpload.Snackbar.UploadingArticle": "Uploading articles...",
  "ContentUpload.Snackbar.Upload.Fail": 'Articles upload failed. Retry?',
  "ContentUpload.Snackbar.Error": "An error occured while uploading.",
  "ContentUpload.Snackbar.noPublicSFTPAccess": "You can not upload SFTP content using this site",
  "ContentUpload.CertainFiles.Fail": "Failed to upload certain files: ",
  "ContentUpload.Snackbar.Upload.ContentSet": 'Uploading content sets...',
  'ContentUpload.Set.Fail': 'Content sets upload failed. Retry?',
  "ContentUpload.Fetch.TempFolder": "Fetching uploaded content from your temporary folder",
  "ContentUpload.Delete.TempFolder": "Deleting content from temporary folder...",
  "Delete.TempFolder.Fail": "Something went wrong when deleting your data from FTP temporary folder",
  "Fetch.FSTP.Error": "Something went wrong when fetching your data from SFTP server",
  "Compressing.Message": "Compressing your uploaded content. This may take a few minutes...",
  "Compressing.Fail": "Something went wrong when compressing your files",
  "Individual.Items": "Individual items",
  "Content.Set": "Content set",
  "Upload.Public.Content.Info": "Upload as public content information",
  "Upload.Content.Description.Text": "Your uploaded content will be published after approval. Once approved, it will be visible to everyone using the site. Please check the submission guide below for more information.",
  "Upload.Content.FTP.Description.Text": "You can upload by browsing your desktop files or by dragging them to this area. If you are an agency, and you want to upload content using an FTP connection, please click on the button below.",
  "Download.sFTP": "Upload via FTP",
  "Download.Browser": "Upload via browser tool",
  "Download.Guide": "Download submission guide",
  "Upload.Incomplete.Text": "Save upload flow",
  "Back.Button": "Back",
  "Complete.Button": "Complete",
  "NextStep.Button": "Next step",
  "Skip.Button": "Skip",
  "FTP.Upload.Confirm.FileZilla": " The upload process in FileZilla must be completed in order to proceed to the next step. Please make sure the folders are organized in the way you would like them to be displayed and that you have one set per folder. When you are ready, please click 'Yes, proceed to next step' ",
  "FileZilla.Finished": "Confirm FileZilla process is finished!",
  "SFTP.ConfirmationDialog.Accept.Button": "Yes, proceed to next step",
  "SFTP.ConfirmationDialog.Reject.Button": "No, still uploading",
  "Delete.TempFolder.Confirmation": "Delete FTP temporary folder",
  "FTP.Upload.Delete.Folder": "After the content upload process is completed, the temporary folder in your FTP tool will be removed.",
  "Okay.Button": "Okay",
  "ContentUpload.DeleteFile.Success": "File deleted successfully",
  "ContentUpload.DeleteFile.Error": "Failed to delete file. Something went wrong!",
  "ContentUpload.Failed.Displayment": "Failed to display...",
  "LoggedIn.Error": "You have to be logged in to submit content for upload",
  "Click.Drag.Label": "Click or drag file to this area to upload",
  "Validation.File.Count": "Exceeded maximum file count for upload. The maximum is 50 per upload",
  "Success.Keywords.Update" : "Keywords updated successfully",
  "Error.Keywords.Update": "Something went wrong while updating keywords. Please try again",
  "Success.Copyright.Update" : "Copyright updated successfully",
  "Error.Copyright.Update": "Something went wrong while updating copyright. Please try again",
  "Success.FileDetails.Update": "File details updated successfully.",
  "Error.FileDetails.Update": "Something went wrong while updating File Details. Please try again",
  "Success.LicenseType.Update": "License type updated successfully.",
  "Error.LicenseType.Update": "Something went wrong while updating license type. Please try again",
  "Error.FileSources.Update": "Something went wrong while updating file sources. Please try again",
  "Success.FileSources.Update": "File Sources updated successfully. ",
  "Success.Locations.Update": "Locations updated successfully",
  "Error.Locations.Update": "Something went wrong while updating locations. Please try again",
  "Success.MainInfo.Update": "Main information updated successfully",
  "Error.MainInfo.Update": "Something went wrong while updating main information. Please try again",
  "Success.Content.Update": "Content updated successfully",
  "Error.Thread.Already.Selected": "Same thread is selected multiple times",
  "Error.Suggest.Thread.Already.Selected" : "You can suggest only one thread per set/image",
  "Error.Dropdown.Menu": "Select thread from dropdown menu",
  "Error.Dropdown.Menu.Subchannel.Channel" : "Select full navigation from dropdown menu",
  "Breadcrumbs.Limit": "Cannot add more than 3 navigations for each content set",
  "Warning.Image.Selection": "Select an image in order to edit its metadata",
  "ApplyToSet.Success": "Changes applied to whole content set successfully",
  "ApplyToSet.Error": "Failed to apply changes to whole set",
  "Warning.MoveToSet": "In order to assign a photo to new content set, you must first select a photo",
  "Previous.Button": "Previous set",
  "Next.Button": "Next set",
  "RejectImage.Button": "Reject image",
  "ApproveImage.Button": "Approve image",
  "Approved.Label": "APPROVED",
  "Rejected.Label": "REJECTED",
  "Channel.AccordionActions.Label.Set": "CATEGORY (FOR CONTENT SET)",
  "ChannelAccordionActions.Label.Individual": "CATEGORY (FOR SINGLE CONTENT)",
  "AddToAnother.Button": "Add to another",
  "Save.Changes.Button": "Save changes",
  "Main.Information.Label": "Main information",
  "ObjectName.Input.Label": "Object name:",
  "Headline.Input.Label": "Headline:",
  "Caption.Input.Label": "Caption:",
  "ExternalLink.Input.Label": "External link:",
  "ExternalLinkMessage.Input.Label" : "External link message: ",
  "Location.Label": "Location",
  "Country.Input.Label": "Country:",
  "NoCountries": "No countries",
  "Province.Input.Label": "Province:",
  "NoProvinces": "No provinces",
  "City.Input.Label": "City:",
  "NoCities": "No cities",
  "CountryCode.Input.Label": "Country code:",
  "ZipCode.Input.Label": "Zip code: ",
  "GPSLatitude.Input.Label": "GPS latitude:",
  "GPSLongitude.Input.Label": "GPS longitude:",
  "ApplyToSet.Button": "Apply to set",
  "License.Label": "License",
  "LicenseType.Input.Label": "Type:",
  "LicenseType.editorial": "Editorial",
  "LicenseType.premium": "Premium",
  "LicenseType.standard": "Standard",
  "Keyword.Label": "Keywords",
  "Add.Keywords.Placeholder": "Add keywords...",
  "Keywords.Add.Button": "Add",
  "Copyright.Label": "Copyright",
  "By-line.Input.Label": "By-line:",
  "Credit.Input.Label": "Credit:",
  "CopyrightNotice.Input.Label": "Copyright notice:",
  "SpecialInstructions.Input.Label": "Special instructions:",
  "AgencyName.Input": "Agency name:",
  "Source.Label": "Source",
  "CreatedDate.Input.Label": "Created date:",
  "CreatedTime.Input.Label": "Created time:",
  "UploadedDate.Input.Label": "Uploaded date:",
  "OriginalFilename.Input.Label": "Original filename:",
  "File.Label": "File",
  "Filename.Input.Label": "Filename:",
  "Filesize.Input.Label": "Filesize:",
  "Filetype.Input.Label": "Filetype:",
  "ImageWidth.Input.Label": "Image width:",
  "ImageHeight.Input.Label": "Image height:",
  "CameraModel.Input.Label": "Camera model:",
  "NewSet.Create.Success": "New content set created successfully",
  "ContentSet.Name.Exist": "A content set with this title already exists. Choose a different name",
  "ContentSet.Name.Validation": "A content set title must contain at least 1 symbol and not exceed 500 symbols",
  "ContentSet.CoverPhoto.Error": "Cannot move cover photo to new set. First change the cover photo",
  "Keywoed.allready.exists":"Keyword allready exists",
  "Move.Photos.Success": "Photos moved successfully",
  "Set.Cover.Succes": "Cover photo set successfully",
  "ContentSet.Remove.Success": "Content set removed successfully",
  "ContentSet.Find.Fail": "Failed to find content set",
  "Unselect.All.In.Content.Set": "Unselect all",
  "Select.All.In.Content.Set": "Select all",
  "Select.As.Cover.Button": "Select as cover image",
  "Remove.Content.Set.Button": "Remove content set",
  "Uploaded.Files.Error.Message": "Uploaded files need to be arranged in content sets before proceeding to next step",
  "Add.Another.Set.Button": "Add new set",
  "Move.To.Set.Button": "Move to another set",
  "SelectImages.Button" : "Select image(s)",
  "Stop.SelectImages.Button" : "Confirm image selection",
  "RemoveContentSet.Confirmation.Title": "Removal of content set",
  "RemoveContentSet.Confirmation.Context": "Are you sure you want to remove this content set? Files will be marked as unsorted",
  "No.Button": "No",
  "Yes.Button": "Yes",
  "NewSet.Placeholder": "New set",
  "Enter.Channel": "Channel:",
  "Enter.Subchannel": "Subchannel:",
  "Enter.Thread": "Thread:",
  "FTP.Request.Sent.Success": "Request for FTP access sent successfully",
  "FTP.Request.Sent.Fail": "Failed to send FTP access request",
  "UserId.Fail": "Invalid user id",
  "Typography.FTP.Upload": "FTP upload",
  "FTP.Upload.paragraph": "You can use your favourite FTP tool to upload content. When using this method, please organize your content in sets. The maximum number of images per set should not exceed 50. When the files are uploaded, please follow the instructions to name your set, choose a cover image, add key words, and proper references.",
  "FTP.Upload.gutterBottom": "In order to upload using FTP,  you will need to:",
  "FTP.Upload.Request": "- request your personal FTP key, a special key that serves as your password to your account and which you will receive as an attachment in an email - the host name that corresponds to the FTP server is ",
  "FTP.Upload.Username": "- your username is: ",
  "FTP.No.Account": "No FTP account available for this user",
  "Warning.Generate.FTPAccount": "To be able to use this option, you need to have FTP account created first. You can click on the button below to generate one.",
  "FTP.Upload.Active.Account": "if you have an active account, your user name is: ",
  "Request.FTPUser.Button": "Request FTP user",
  "RequestSent.FTPUser.Button": "Request Sent",
  'NewThread.Placeholder': "New thread: ",
  "Step.SFTPStep":"sFTP Upload",
  "Step.PickItems":"Pick items",
  "Step.ManageContent":"Manage content",
  "Step.AddArticle":"Add article (optional)",
  "Step.ReviewData":"Review data",
  "Name.Set.Placeholder" : "Add title",
  "Warning.EnableImageSelection" : "You must enable image selection in order to select images",

  //CART
  "Payment.Complete.Banner.Headline": "Successful purchase",
  "Payment.Complete.Banner.Caption": "Congratulations!",
  "Payment.Complete.Banner.ContentText": "You successfully purchased content from world illustrated",
  "CartTableHeading.Preview": "Preview",
  "CartTableHeading.Headline": "Headline",
  "CartTableHeading.ItemType": "Item type",
  "CartTableHeading.LicenseType": "License type",
  "CartTableHeading.Price": "Price",
  "CartTableHeading.Size": "Size",
  "CartTableHeading.Remove": "Remove",
  "Choose.Card.Type": "Use a credit/debit card",
  "Cart.Order.Summary": "Order summary",
  "CartPages.Payments": "Payment preferences",
  "CartPage.PayWithSubscription": "Subscription preferences",
  "CartPage.ManageCart": "Manage cart",
  "Cart.User.Not.Valid": "No valid user available in order to continue to checkout",
  "Cart.Snackbar.Unsuccessful":"An error occured during payment. Reload the page and try again",
  "Cart.Order.Unsuccessful": "An error occured when creating your order. Reload the page and try again",
  "Cart.SnackBar.Successful":"Payment successful",
  "Cart.Billing.Add.Another": "Use another billing address",
  "Cart.Billing.Cancel.Billing": "Remove new billing address",
  "Cart.Billing.Saved": "Billing Address saved successfully",
  "Cart.Billing.Saved.Failed": "Failed to save Billing Address. Refresh form and try again...",
  "Cart.Checkout.Error": "An error occured during checkout. Please refresh the page and try again.",

  //CHANNELS
  "Channel.military": "Military",
  "Channel.planet": "Our Planet",
  "Channel.artsCulture": "Arts & Culture",
  "Channel.design": "Design",
  "Channel.music": "Music",
  "Channel.hobby": "Hobbies",
  "Channel.news": "News",
  "Channel.famous": "Famous",
  "Channel.fashion": "Fashion",
  "Channel.sport": "Sport",
  "Channel.health": "Health",
  "Channel.foodDrink": "Food & Drink",
  "Channel.techScience": "Tech & Science",
  "Channel.junior": "Junior",
  "Channel.community": "Community",
  "Channel.transport": "Transport",

  //ONBOARDING
  "Message.Fill.Fields" : "Please fill the following fields:",
  "Message.Welcome" : "Please complete this form to help us personalize your experience",
  "Onboarding.StepTwo.Subjects.Head": "Subjects that you are interested in",
  "Onboarding.StepTwo.Topics.Head": "Help us offer you relevant topics. What are you interested in",
  "Onboarding.StepTwo.Keywords": "Type in keywords",
  "Onboarding.StepOne.About.you": "About you(optional)",
  "Onboarding.StepOne.ProfilePicture.head": "Choose a profile logo or picture",
  "Onboarding.StepOne.Biography.head": "Add a short presentation or biography",
  "Onboarding.StepOne.Save.button": "Save",
  "Onboarding.StepOne.Validate.button": "Validate",
  "Onboarding.StepOne.GeneralInfo": "General information",
  "Onboarding.StepOne.Country.placeholder": "Select country",
  "Onboarding.StepOne.State.placeholder": "Select state/province",
  "Onboarding.StepOne.City.placeholder": "Select city",
  "Onboarding.StepOne.BirthYear.placeholder": "Select year of birth",
  "First.Name": "First name",
  "Last.Name": "Last name",
  "Country": "Country",
  "State": "State/Province",
  "City": "City",
  "Gender": "Gender",
  "Year.Of.Birth": "Year of birth",
  "Onboarding.Step.General.infromation": "General information",
  "Onboarding.Step.Preferences": "Preferences",
  "Onboarding.Step.Complete": "Complete",
  "Onboarding.Step.Licensing": "Subscription plans and credits",
  "Filters.Date.To": 'To',
  "Filters.Date.From": "From",
  "Onboarding.StepTwo.Headline.Member": "As a Member, you can use our social media tools nad personalize the content feed in to your My world account. Please enter keywords that reflect your intrests and select from the options below.",
  "Onboarding.snackbar.Error.FetchingLocation": "Error when fetching location",
  "Onboarding.OrganizationName":"Organization name",
  "Onboarding.Gender.Male":"Male",
  "Onboarding.Gender.Female":"Female",
  "Onboarding.Gender.PrefereNotToAnswer":"Prefer not to answer",
  "Onboarding.VatNumber":"VAT number(optional)",
  "Onboarding.CompanyType":"Company type",
  "Onboarding.Placeholder.CompanyType":"Select company type",
  "Onboarding.ContentType":"Content type",
  "Onboarding.PlaceholderContentType":"Select content type",
  "Onboarding.AccountPerson.Headline":"Person responsible for the account",
  "Oboarding.JobTitle":"Job title",
  "Onboarding.JobTitle.Placeholder":"Select job title",
  "Onboarding.EmailAddress":"Email address",
  "Onboarding.PhoneNumber":"Phone number",
  "Onboarding.StepOne.Save":"Save",
  "Onboarding.Address.Mailing.Address.Heading":"Mailing address",
  "Onboarding.Address.Billing.Address.Heading":"Billing address",
  "Onboarding.Address.Country":"Country",
  "Onboarding.Address.StateProvince":"State/Province",
  "Onboarding.Address.City":"City",
  "Onboarding.Address.AddressLine1":"Address line 1",
  "Onboarding.Address.AddressLine2":"Address line 2",
  "Onboarding.IsRequired":" is required",
  "Onboarding.Names.Regex":"Name must not include special symbols (/,.* .etc)",
  "Onboarding.ZipCode":"Zip code",
  "Onboarding.Address.Error.Address":"Address",
  "Onboarding.Channels.Heading":"Channels most appropriate for your content",
  "Onboarding.Channels.Error.Text":"You should select at least one channel",
  "Onboarding.Licensing.Heading":"Licensing",
  "Onboarding.LicencingType":"License type",
  "Onboarding.LicensingType.Placeholder":"Select license type",
  "Onboarding.Currency":"Preferred currency",
  "Onboarding.Currency.Placeholder":"Select currency",
  "Onboarding.Agreement":"I agree that my content may be reproduced as prints or posters and on merchandise products",
  "Onboarding.StepOne.EmptyFields":"Please fill the following fields:",
  "Onboarding.StepOne.Licensing.LicensingType":"Licensing type is required",
  "Onboarding.StepOne.Licensing.Currency":"Preffered currency is required",
  "Onboarding.StepOne.Licensing.Agreement":"Agreement to sell is required",
  "Onboarding.Licensing.Message" : "If you want to buy a content subscription or credits, please select from below. You can always buy single image or items",
  'Onboarding.NewsletterEmail': 'Newsletter email',
  'Onboarding.AccountingEmail': 'Accounting email',
  'Onboarding.Complete.HeaderText' : "Registration complete",
  'Onboarding.Complete.Heading' : "Congratulations!",
  'Onboarding.Complete.ProMember.ContentText' : "Welcome to the world illustrated community. You have successfully completed your Pro member registration.\n Your Pro membership account enables you to search for and buy the content that best suits your needs. Depending on the content type, you can buy single images, subscriptions or credits. You can use our on-line pricing tools to price editorial images for specific usages.",
  'Onboarding.Complete.Member.ContentText' : "As a Member, you can use our social media tools nad personalize the content feed in to your My world account. Please enter keywords that reflect your intrests and select from the options below.",
  "Onboarding.Complete.Contributor.ContentText" : "Welcome to the community of world illustrated. You have successfully completed your Contributor registration. \n From now on you can upload images, videos and articles which will be offered for licensing to both professional buyers and to individuals. ",
  "Onboarding.Editors.Title" : "Title",
  "Onboarding.Editors.Headline" : "Editors and researchers information (if you have more than one)",
  "Onboarding.AddEditor.Button.Label" : "Add another editor/researcher +",
  "Onboarding.Editor.Title" : "Editor/ researcher ",
  "Onboarding.FirstName.Label" : "First name",
  "Onboarding.LastName.Label" : "Last name",
  "Onboarding.JobTitle.Label" : "Job title",
  "Onboarding.Country.Label" : "Country",
  "Onboarding.State.Label" : "State/Province",
  "Onboarding.City.Label" : "City",
  "Onboarding.Gender.Label" : "Gender",
  "Onboarding.YearOfBirth.Label" : "Year of birth",
  "Onboarding.CompnayType.Label" : "Company type",
  "Onboarding.ContentType.Label" : "Content type",
  "Onboarding.EmailNewsletter.Label" : "Email newsletters",
  "Onboarding.EmailAccounting.Label" : "Email accounting",
  "Onboarding.LicensingType.Label" : "Licensing type",
  "Onboarding.PreferredCurrency.Label" : "Preferred currency",
  "Onboarding.Subscription.Heading" : "Subscriptions",
  "Onboarding.Credit.Heading" : "Credits",
  "Onboarding.Buy.Button": "Buy",
  "Onboarding.Go.Back.To.Selection.Button":"Go back to plans",
  "Onboarding.File.Upload" : "Drag or drop file",
  "Onboarding.Use.MailingAddress" : "Click here if you want to use your mailing address",
  "Onboarding.Year.Max" : "You must be at least 18 years old to use this website",
  "Onboarding.Names.Length":"A name should be between 1 and 100 characters",
  "Onboarding.Phonenumber.Length":"A phone number should be between 5 and 13 characters",
  "Onboarding.Address.Regex" : "Your address is not valid",
  "Onboarding.ZipCode.Regex" : "Your zip code is not valid",
  
  //SEARCH
  "Search.Placeholder": "Search here",
  "Content.RadioButton": "Content",
  "MemberProfiles.Radio.Button": "Member profiles",
  "ContentFrom.Field": "Content from: ",
  "ContentFrom.Placeholder": "Enter name...",
  "ContentUploadedFrom.AllMembers": "All",
  "ContentUploadedFrom.MwUser": "My World",
  "ContentUploadedFrom.proContributors": "Pro Contributors",
  "ContentType.Field": "Content type:",
  "ContentType.AllContent" : "All content",
  "ContentType.image": "Image",
  "ContentType.video": "Video",
  "ContentType.audio": "Audio",
  "ContentType.article": "Article",
  "Location.Field": "Location: ",
  "Date.Field": "Date: ",
  "Colour.Field": "Colour: ",
  "Channel.Field": "Channel: ",
  "Channel.Placeholder": "Enter channel",
  "Subchannel.Field": "Subchannel: ",
  "Subchannel.Placeholder": "Enter subchannel",
  "Thread.Field": "Thread: ",
  "Thread.Placeholder": "Enter thread",
  "ClearAllFilters.Button": "Clear all filters",
  "Date.Validation.InvalidDate": "Invalid date range selected!",
  "Date.Validation.SelectBoth" : "Select both start and end date!",
  "StartDate.Label": "Start date",
  "EndDate.Label": "End date",
  "Date.Close.Button": "Close",
  "Date.Apply.Button": "Apply",
  "Suggestions.Label": "Suggestions for your next search: ",
  "Suggestions.SpelledCorrectly" : "Make sure that all words are spelled correctly.",
  "Suggestions.DifferentKeywords": "Try different keywords.",
  "Suggestions.GeneralKeywords": "Try more general keywords.",
  "Suggestions.FewerKeywords": "Try fewer keywords.",
  "FollowUser.Button": "Follow",
  "User.Failed": "Failed to display",
  "ContentFrom.AllMembers": "All members",
  "ContentFrom.proContributors": "Pro contributors",
  "Filter.By": "By: ",
  "AdvancedSearch.Button" : "Advanced search",
  "ClearFilters.Button": "Clear filters",
  "HideFilters.Button": "Hide filters",
  "ShowFilters.Button": "Show filters",


  //Photo details
  "Photo.Details.Containers.Part.Of.Subscription.Part.One":"This item is available in your subscription plan, you have ",
  "Photo.Details.Containers.Part.Of.Subscription.Part.Two":" items left acording to your plan",
  "Photo.Details.Container.Already.Own.This.Item":"You already own this item.",
  "Photo.Details.Add.To.Cart":"Add to cart +",
  "Photo.Details.Container.Price":"Price: $",
  "Photo.Details.Container.No.Comments.Yet":"No comments yet, be the first to comment.",
  "Photo.Details.Container.Comments":"Comments",
  "Photo.Details.Container.To.Comment":"to comment",
  "Photo.Details.Purchase.Audio.File.Headline":"Buy the audio file.",
  "Photo.Detail.Not.Logged.User.Pricing.Message.Part.One":"You are currently not logged in. Please",
  "Photo.Detail.Not.Logged.User.Pricing.Message.Part.Two":"to be able to purchase content",
  "Add.Comment.No.Url.Allowed":"Urls/Links are not allowed in comments.",
  "Add.Comment.Rquired":"You cannot post an empty comment",


  //My License page
  "My.License.Page.Main.Header":"My license plans",
  "My.License.Page.Get.More.Plans":"Get subscription plans",
  "My.License.Page.Headers.Id":"ID",
  "My.License.Page.Headers.Content.Type": "Content type",
  "My.License.Page.Headers.PlanType": "Plan type",
  "My.License.Page.Headers.Amount": "Amount of items",
  "My.License.Page.Headers.Period": "Period",
  "My.License.Page.Headers.PlanPrice": "Plan price",
  "My.License.Page.Headers.Get.Plan.Button": "Buy plan",
  "My.License.Page.Headers.Exp.Date": "Expiration date",
  "Get.License.Plans.Page.Main.Header":"Available plans",
  "Get.License.Plans.Select.Plan.Step.Label":"Select a plan",
  "Get.License.Plans.Payments.Step.Label":"Payment",
  "Get.License.Plans.Complete.Step.Label": "Complete",
  "License.Complete.Banner.Heading": "You have successfully purchased license plan for world illustrated",
  "License.Complete.Banner.Caption": "Congratulations!",
  "License.Complete.Banner.ContentText": "world illustrated license plans allow you to browse freely and purchase content licensed with the same type as your subscription/credit. Keep in mind that each user is allowed to have 1 Standard and 1 Premium active license plan at a time.",
  "My.License.Page.Plan.Periods.Monthly":"1 month",
  "My.License.Page.Plan.Periods.Yearly" : "12 months",

  //Cookie consent
  "Cookie.Consent.Text.Part.One":"This website uses cookies to enhance the user experience.",
  "Cookie.Consent.Text.Part.Two":"This bit of text is smaller.",
  "Onboarding.Emails.Regex":"Invalid email address",
  
  "Download.button":"Download"
  
  
  
  
  
  
  
  
  

  
  











};
