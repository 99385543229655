import React, { useState, useEffect } from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { StoreState } from '../../redux/root-reducer';
import { ResultsInfiniteLoaderProps } from './searchBar.types';
import SyncLoader from 'react-spinners/SyncLoader';
import { UserSource } from '../../redux/search-bar/search-bar.types';
import { selectContentPerPage, selectContentSearchResults, selectUserSearchResults } from '../../redux/search-bar/search-bar.selectors';
import { TSearchReducerActions } from '../../redux/search-bar/search-bar.actions';
import ContentList from '../contents/ContentList';
import { UserUploadedContent } from '../contributor-content/contributor-content.types';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import { Pagination } from '@mui/material';

const ResultsInfiniteLoader: React.FC<ResultsInfiniteLoaderProps> = ({...props}) => {
    const {contentSearchResults,loadingAll, totalPage, pageNumber, contentPerPage, handleChange, handleContentPerPageChange} = props;
    const [tileData, setTileData] = useState<UserUploadedContent[]>([]);

    const perPageOptions = [25,50,75,100];
    useEffect(() => {
        if (contentSearchResults.length > 0) {            
            setTileData([...contentSearchResults]);
        } else {
            setTileData([]);
        }
    }, [contentSearchResults]);

    return (
        <React.Fragment>
            <ContentList loadingSearch={loadingAll} tileData={tileData} contentPerPage={contentPerPage} />
            {totalPage > 1 && <div className='pagination-container'>
                <span className='pagination-label'>Per page</span>
                <FormControl>
                    <Select
                        value={contentPerPage.toString()}
                        onChange={handleContentPerPageChange}
                    >
                    {perPageOptions.map(item=><MenuItem value={item}>{item}</MenuItem>)}
                    </Select>
                </FormControl>
                <Pagination className='pagination-component' count={totalPage} page={pageNumber === 0 ? 1 : pageNumber} onChange={handleChange} />
            </div>}
           
        </React.Fragment>
    )
}

const mapStateToProps = (state: StoreState): {userSearchResults: UserSource[]; contentSearchResults: UserUploadedContent[]; contentPerPage: number} => {
    return {
        userSearchResults: selectUserSearchResults(state),
        contentSearchResults: selectContentSearchResults(state),
        contentPerPage: selectContentPerPage(state)
    }
}

const mapDispatchToProps = (dispatch: Dispatch<TSearchReducerActions>) => {
    return {
        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResultsInfiniteLoader);