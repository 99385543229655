import React, { useState, useRef } from "react";
import {
  Button,
  ButtonGroup,
  Paper,
  MenuList,
  MenuItem,
  Popper,
  Grow,
} from "@mui/material";
import {
  ContentTypeFilterProps,
  ContentFromOptions,
  ContributorTypeFilterProps,
} from "./searchBar.types";
import { ClickAwayListener } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import "./searchResultsFilters.styles.scss";
import { StoreState } from "../../redux/root-reducer";
import {
  selectContentFromFilter,
  selectContentTypeFilter,
} from "../../redux/search-bar/search-bar.selectors";
import {
  IToggleFilterContentType,
  TSearchReducerActions,
} from "../../redux/search-bar/search-bar.actions";
import { Dispatch } from "redux";
import { SearchBarActions } from "../../redux/search-bar/search-bar.types";
import { connect } from "react-redux";
import { IToggleFilterContentFrom } from "../../redux/search-bar/search-bar.actions";

const ContributorTypeFilterComponent: React.FC<ContributorTypeFilterProps> = ({
  ...props
}) => {
  const { contentFromFilter, isInChannelContex, toggleContentFromFilter } =
    props;
  const { t } = useTranslation();
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);

  const openContentTypeDropdown = () => {
    setOpen((prevState) => !prevState);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  };

  const handleContentTypeChange = (event: any, value: ContentFromOptions) => {
    event.preventDefault();

    toggleContentFromFilter(value);
    setTimeout(() => {
      setOpen(false);
    }, 500);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };
  return (
    <React.Fragment>
      <Button
        ref={anchorRef}
        size={isInChannelContex ? "small" : "medium"}
        aria-controls={open ? "menu-list-grow" : undefined}
        variant="contained"
        className="white-round-button"
        onClick={openContentTypeDropdown}
        endIcon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      >
        {t("ContentUploadedFrom." + contentFromFilter.toString())}
      </Button>
      <Popper
        open={open}
        style={{ zIndex: 5 }}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem
                    value={ContentFromOptions.ALL_MEMBERS}
                    onClick={(event: any) =>
                      handleContentTypeChange(
                        event,
                        ContentFromOptions.ALL_MEMBERS
                      )
                    }
                  >
                    {t("ContentUploadedFrom." + ContentFromOptions.ALL_MEMBERS)}
                  </MenuItem>
                  <MenuItem
                    value={ContentFromOptions.MW_USER}
                    onClick={(event: any) =>
                      handleContentTypeChange(event, ContentFromOptions.MW_USER)
                    }
                  >
                    {t("ContentUploadedFrom." + ContentFromOptions.MW_USER)}
                  </MenuItem>
                  <MenuItem
                    value={ContentFromOptions.PRO_CONTRIBUTORS}
                    onClick={(event: any) =>
                      handleContentTypeChange(
                        event,
                        ContentFromOptions.PRO_CONTRIBUTORS
                      )
                    }
                  >
                    {t(
                      "ContentUploadedFrom." +
                        ContentFromOptions.PRO_CONTRIBUTORS
                    )}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
};

const mapStateToProps = (state: StoreState): { contentFromFilter: any } => {
  return {
    contentFromFilter: selectContentFromFilter(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<TSearchReducerActions>) => {
  return {
    toggleContentFromFilter: (data: any) =>
      dispatch<IToggleFilterContentFrom>({
        type: SearchBarActions.TOGGLE_FILTER_CONTENT_FROM,
        data: data,
      }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContributorTypeFilterComponent);
