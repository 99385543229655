import { Content, ContentNavigation } from "../../components/contributor-content/contributor-content.types";

export enum LocalContentActionTypes {
    LOADED_DATA = "LOADED_DATA",
    RESET_LOADED_CONTENT = "RESET_LOADED_CONTENT"
}

export interface LocalContentState {
    localContentData: LocalContent[];
}

export interface LocalContent {
    content: Content;
    contentNavigations: ContentNavigation[];
    contentSetId?: number;
    articleId?: number;
}