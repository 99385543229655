import React, { useState } from "react";
import { Dialog, Backdrop, Fade } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { bannerStyles, SignInBannerProps } from "./sign-in-banner.types";
import { StoreState } from "../../redux/root-reducer";
import { connect } from "react-redux";
import "./sign-in-banner.styles.scss";
import {
  ISetRegisterType,
  IToggleBannerHidden,
  TSignInBannerReducerActions,
} from "../../redux/sign-in-banner/sign-in-banner.actions";
import { BannerActionTypes } from "../../redux/sign-in-banner/sign-in-banner.types";
import { Dispatch } from "redux";
import {
  IToggleLogin,
  TModalReducerActions,
  IToggleRegister,
} from "../../redux/modal-visibility/modal.actions";
import { useTranslation } from "react-i18next";
import { ModalActionTypes } from "../../redux/modal-visibility/modal.types";
import { useCookies } from "react-cookie";

const SignInBanner: React.FC<SignInBannerProps> = ({ ...props }) => {
  const [cookies, setCookie, removeCookie] = useCookies();

  const { banner, toggleBanner, toggleLogin, toggleRegister, setRegisterType } =
    props;
  const [response, setResponseState] = useState("");
  const { t } = useTranslation();
  const classes = bannerStyles();
  const divStyle = {
    backgroundImage:
      'url("https://images.unsplash.com/photo-1547333041-8c938965d679?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80")',
  };

  const handleClick = () => {
    setTimeout(() => {
      if (Object.keys(cookies).length === 0) {
        document.body.style.overflowY = "hidden";
      } else {
        document.body.style.overflowY = "auto";
      }
    }, 300);
    toggleBanner(true);
  };
  const handleOpenLogin = () => {
    toggleLogin();
    toggleBanner(true);
  };
  const handleOpenRegister = (e: any) => {
    toggleRegister();
    toggleBanner(true);
    setRegisterType(e.target.value);
  };

  return (
    <React.Fragment>
      <Dialog
        classes={{
          root: classes.dialogRoot,
          paper: classes.dialogPaper,
          paperWidthSm: classes.dialogPaperWithSm,
        }}
        style={{ zIndex: 10000 }}
        closeAfterTransition={true}
        onClose={handleClick}
        open={!banner}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        BackdropProps={{ timeout: 800 }}
      >
        <Fade in={!banner}>
          <div className="container">
            <div className="head-container">
              <div className="headline">
                <h1>world illustrated</h1>
              </div>
              <div>
                <button className="close-button-banner" onClick={handleClick}>
                  <FontAwesomeIcon
                    className="icon-button-banner"
                    icon={faTimes}
                  />
                </button>
              </div>
            </div>
            <div className="banner-container" style={divStyle}>
              {response ? <div className="error-box">{response}</div> : null}

              <div className="sign-in-labels">
                <div className="label-wraper">
                  <span className="labels">
                    {t("Login.Banner.SignIn.Message")}
                  </span>
                </div>
                <div className="label-wraper">
                  <span className="labels">
                    {t("Login.Banner.NoAccount.Message")}
                  </span>
                </div>
                <div className="label-wraper">
                  <span className="labels">
                    {t("Login.Banner.Contributor.Message")}
                  </span>
                </div>
                <div className="label-wraper">
                  <span className="labels">
                    {t("Login.Banner.Pro.Message")}
                  </span>
                </div>
              </div>
              <div className="sign-in-buttons">
                <div className="button-wraper">
                  <button className="button-styles" onClick={handleOpenLogin}>
                    {t("Banner.Login.button")}
                  </button>
                </div>
                {/*<div className="button-wraper">
                                        <button className="button-styles" value="member"
                                                onClick={handleOpenRegister}>{t('Banner.RegisterAsMember.button')}</button>
                                    </div>*/}
                <div className="button-wraper">
                  <button
                    className="button-styles"
                    value="contributor"
                    onClick={handleOpenRegister}
                  >
                    {t("Banner.RegisterAsContributor.button")}
                  </button>
                </div>
                <div className="button-wraper">
                  <button
                    className="button-styles"
                    value="pro"
                    onClick={handleOpenRegister}
                  >
                    {t("Banner.RegisterAsPro.button")}
                  </button>
                </div>
              </div>
              <div className="image"></div>
            </div>
          </div>
        </Fade>
      </Dialog>
    </React.Fragment>
  );
};
const mapStateToProps = (state: StoreState): { banner: boolean } => {
  return {
    banner: state.signin.hidden,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<TSignInBannerReducerActions | TModalReducerActions>
) => {
  return {
    toggleBanner: (show: boolean) =>
      dispatch<IToggleBannerHidden>({
        type: BannerActionTypes.TOGGLE_BANNER_HIDDEN,
        data: show,
      }),
    toggleLogin: () =>
      dispatch<IToggleLogin>({ type: ModalActionTypes.ToggleLoginModal }),
    toggleRegister: () =>
      dispatch<IToggleRegister>({ type: ModalActionTypes.ToggleRegisterModal }),
    setRegisterType: (registerType: string) =>
      dispatch<ISetRegisterType>({
        type: BannerActionTypes.SET_REGISTER_TYPE,
        data: registerType,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignInBanner);
