import gql from 'graphql-tag';

export const USER_SOURCE_FRAGMENT = gql`
    fragment UserSource on UserSourceDto {
        id
        email
        username
        first_name
        last_name
        organization_name
    }
`;