import { Article, ContentSet, IndividualImageCategory } from "../../components/batch-upload/ContentUpload.types";

export const updateSet = (updatedSet: ContentSet, allSets: ContentSet[]) => {
    return allSets.map((set: ContentSet) => set.id === updatedSet.id ? updatedSet : set);
}

export const addOrUpdateArticle = (articles: Article[], newArticle: Article) => {
    if(articles.find(art => art.contentSetId === newArticle.contentSetId)) {
        return articles.map((article: Article) => article.contentSetId === newArticle.contentSetId ? newArticle : article);
    } else {
        return articles.concat(newArticle)
    }
}

export const addChannelToContent = (contentSets: ContentSet[], threadIds: number[], contentSetId: string) => {
    const selectedContentSet = contentSets.find(contentSet => contentSet.id === contentSetId);
    if(selectedContentSet) {
        selectedContentSet.selectedThreadIds = threadIds;
        const otherSets = contentSets.filter(contentSet => contentSet.id !== contentSetId);
        return otherSets.concat(selectedContentSet);
    }
    return contentSets;
}

export const addImageCategory = (imageCategories: IndividualImageCategory[], newCategory: IndividualImageCategory) => {
    let imageCategory = imageCategories.find(category => category.contentId === newCategory.contentId);

    if(imageCategory) {
        let temp = imageCategories.filter(category => category.contentId !== imageCategory!.contentId);
        temp.push(newCategory);
        return temp;
    }

    return imageCategories.concat(newCategory);
}