import { Addresses } from "../onboarding/onboarding.types";
import { ItemForCart } from "../../components/photo-details/photo-details.types";
import { IBroadcastMessage } from "../../components/batch-upload/ContentUpload.types";

export enum CartActionTypes {
  ADD_TO_CART = "ADD_TO_CART",
  REMOVE_FROM_CART = "REMOVE_FROM_CART",
  ADD_BILLING_ADDRESS = "ADD_BILLING_ADDRESS",
  ADD_TAX = "ADD_TAX",
  BROADCAST_CART_ERROR = "BROADCAST_CART_ERROR",
  EMPTY_CART = "EMPTY_CART",
  ADD_TO_SUBSCRIPTION_CART = "ADD_TO_SUBSCRIPTION_CART",
  CLEAR_BROADCAST = "CLEAR_BROADCAST"
}

export interface CartState {
  itemsInCart: ItemForCart[];
  itemsFromSub: ItemForCart[];
  subtotal: number;
  total: number;
  taxForOrder: TaxForOrder | null;
  additionalBillingAddress: Addresses | null;
  cartError: IBroadcastMessage;
}

export interface TaxForOrder {
  totalAmount: number;
  shipping: number;
  taxableAmount: number;
  amountToCollect: number;
  rate: number;
  hasNexus: boolean;
  freightTaxable: boolean;
  taxSource: string;
  exemptionType?: string;
  jurisdictions: any;
  breakdown?: any;
}