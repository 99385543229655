import { Channel } from "../home-component/home.types";
import { History } from "history";
import { User as ReduxUser} from '../../redux/user/user.types'
import { IBroadcastMessage } from "../../components/batch-upload/ContentUpload.types";
import { Subchannel } from "components/onboarding/onboarding-page.types";
import { Thread as ThreadFilter } from "../../components/channelsSidebar/channelSideBars.types";
import { LocationFilterProps } from "../../redux/search-bar/search-bar.types";

export interface UploadedContentProps {
    currentUser: ReduxUser;
    updateError: IBroadcastMessage;
    history: History;
    contentStatusFilter: string;
    contentTypeFilter: string;
    uploadDateFilter: Date[];
    publishDateFilter: Date[];
    channelFilter: Channel;
    subchannelFilter: Subchannel;
    threadFilter: ThreadFilter;
    locationFilter: LocationFilterProps;
    dataTableRefreshState: boolean;
    fetchError: IBroadcastMessage;
    toggleContentTypeFilterAction: (data: string) => void;
    toggleContentStatusFilterAction: (data: string) => void;
    toggleUploadDateFilterAction: (data: Date[]) => void;
    togglePublishDateFilterAction: (data: Date[]) => void;
    toggleChannelFilterAction: (data: Channel) => void;
    toggleSubchannelFilterAction: (data: Subchannel) => void;
    toggleThreadFilterAction: (data: ThreadFilter) => void;
    toggleLocationFilterAction: (data: LocationFilterProps) => void;
    resetTableFilterAction: () => void;
    toggleDataTableRefreshState: (data: boolean) => void;
    broadcastFetchErrorAction: (data: IBroadcastMessage) => void;
    clearBroadcastErrorAction: () => void;
};

export interface PreferredCurrencyProps { 
    currentUser: ReduxUser;
    broadcastCurrencyUpdateAction: (data: IBroadcastMessage) => void;
}

export interface UserUploadedContent {
    contentSetId: number;
    articleId: number;
    audioId: number;
    contentNavigations: ContentNavigation[];
    content: Content;
    contentRate: ContentRate;
    hash: string;
    publishDate: Date;
    uploadDate: Date;
    type: string;
    title: string;
    status: string;
    location: Location;
    contributor: User;
}
export interface ContentRate{
    flatRate: number;
}
export interface User {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    username?:string;
    role?: string;
    subscriptions?: any;
    organizationName?: any;
}
export interface ContentNavigation {
    channel: Channel;
    subChannel: SubChannel;
    thread: Thread;
}

export interface SubChannel extends Channel {
    channelId: number;
}

export interface Thread extends Channel {
    subChannelId: number;
    status: string;
    hasContent : boolean;
}
export interface Country {
    country: string;
}

export interface Province {
    stateProvince: string;
}

export interface City {
    city: string;
}
export interface Location {
    country: Country;
    province: Province;
    city: City;
}
export interface Content {
    id: number;
    headline: string;
    type: string;
    status: string;
    publishDate: Date;
    externalLink: string;
    externalLinkMessage: string;
    caption: string;
    licensingType: string;
    isOwned: boolean;
    pathToFileCompressed: string;
    pathToFileThumbnail: string;
    code: string;
}

export interface Currency {
    id: number;
    value: string;
    symbol: string;
}
export enum CurrencyType {
    usd = '$',
    eur = '€',
    gbp = '£',
}

export enum ContentStatus {
    INCOMPLETE = "incomplete",
    PUBLISHED = "approved",
    PENDING_APPROVAL = "pending",
    DENIED = "rejected",
}