import { useLazyQuery } from "@apollo/react-hooks";
import React, { useEffect, useState } from "react";
import "./my-license-page.styles.scss";
import { Typography, Grow } from '@material-ui/core';
import {
  Header,
  MyLicensingPlanPageProps,
  UserLicensingPlan
} from "./my-license-page.types";
import AddIcon from '@material-ui/icons/Add';
import { GET_USER_LICENSING_PLANS } from "./queries";
import { User } from "../../redux/user/user.types";
import { selectCurrentUser } from "../../redux/user/user.selectors";
import { StoreState } from "redux/root-reducer";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { push, CallHistoryMethodAction } from "connected-react-router";
import { MyLicensingPlanPageRow } from "./license-page.containers";
import { useTranslation } from "react-i18next";
import { checkIfUserHasBothLicenseTypes } from "./license-page-helperFunctions";
import SyncLoader from "react-spinners/SyncLoader";
import { IEmptySubscription, TSubscriptionReducerActions } from "redux/subscriptions/subscription-page.actions";
import { SubscriptionActionTypes } from "../../redux/subscriptions/subscription-page.types";

const MyLicensePage: React.FC<MyLicensingPlanPageProps> = ({ ...props }) => {
  const { currentUser, redirectToGetPlansPage , removeSubscriptions} = props;
  const [licensePlans, setLicensePlans] = useState<UserLicensingPlan[]>([]);
  const [getUserPlans, { data, loading, error, refetch }] = useLazyQuery(GET_USER_LICENSING_PLANS, { fetchPolicy: "cache-and-network" });
  const { t } = useTranslation();

  const handleRedirect = () => {
    removeSubscriptions();
    redirectToGetPlansPage();
  };

  useEffect(() => {
      getUserPlans({variables: { userId: currentUser.id }});
  }, []);

  useEffect(() => {
    if (data && data.getUserLicensingPlans) {
      setLicensePlans(data.getUserLicensingPlans);
    }
  }, [data]);

  const headers: Header = {
    headerId: t("My.License.Page.Headers.Id"),
    headerContentType: t("My.License.Page.Headers.Content.Type"),
    headerPlanType: t("My.License.Page.Headers.PlanType"),
    headerAmount: t("My.License.Page.Headers.Amount"),
    headerPeriod: t("My.License.Page.Headers.Period"),
    headerPlanPrice: t("My.License.Page.Headers.PlanPrice"),
    expDate: t("My.License.Page.Headers.Exp.Date"),
  };

  return (
    <div className="page-wrapper">
      <div className="license-page-display">
        <h3 className="license-page-headline">
          {t("My.License.Page.Main.Header")}
        </h3>
        <div className="license-page-table">
          <Grow in={true}>
            <React.Fragment>
              <MyLicensingPlanPageRow isHeader={true} header={headers} />
              {loading ? 
                <SyncLoader css={`display: block; margin: 0 auto; border-color: red;`} size={20} 
                color={"#36D2B3"} loading={loading}/>
                : 
                licensePlans.map((plan, index) => {
                  return (
                    <MyLicensingPlanPageRow
                      isHeader={false}
                      tableItems={plan}
                      key={index}
                    />
                  );
                })
              }

              {licensePlans.length === 0 && !loading ?
                <Typography component="div" variant="body1" gutterBottom color="textPrimary">
                    No valid license plans available for this user
                </Typography> 
              : null}
            </React.Fragment>
          </Grow>
        </div>
      </div>
      
      {!checkIfUserHasBothLicenseTypes(licensePlans)? 
        <button className="get-license-plans-button" onClick={handleRedirect}>
          <AddIcon/> {t("My.License.Page.Get.More.Plans")}
        </button>
      : null}
    </div>
  );
};

const mapStateToProps = (state: StoreState): { currentUser: User; } => {
  return {
    currentUser: selectCurrentUser(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch<CallHistoryMethodAction | TSubscriptionReducerActions>) => {
  return {
    redirectToGetPlansPage: () => dispatch(push(`/get-license-plan-page`)),
    removeSubscriptions: () =>
      dispatch<IEmptySubscription>({
        type: SubscriptionActionTypes.EMPTY_SUBSCRIPTION
      }),

  };

};
export default connect(mapStateToProps, mapDispatchToProps)(MyLicensePage);
