import { createTheme } from "@material-ui/core/styles";
import { User } from "../../redux/user/user.types";
import { Channel } from "../home-component/home.types";
import { AboutYou, Addresses, AppropriateChannels, EditorsInformation, Gender, GeneralInformationCompany, GeneralInformationPerson, Licensing, OnboardingValidation, PersonResponsibleForAccount } from "../../redux/onboarding/onboarding.types";
import { DroppedFile, IBroadcastMessage } from "components/batch-upload/ContentUpload.types";
import { makeStyles } from '@material-ui/core/styles';
import { brandColor } from "../../assets/globalVariables.module.scss";
import { FormikErrors } from "formik";
import { LicensingTypes } from "components/batch-upload/EditContentMetadata.types";
import { Thread } from "components/channelsSidebar/channelSideBars.types";

//--------------------------STEP ONE PROPS-----------------------------//
export interface GeneralInformationProps {
  userRole: string;
  currentUser: User;
  generalInformationPerson: GeneralInformationPerson;
  generalInformationCompany: GeneralInformationCompany;
  toggleResponsiblePersonAccordionAction: () => void;
  saveGeneralInformationPersonAction: (data: GeneralInformationPerson) => void;
  saveGeneralInformationCompanyAction: (data: GeneralInformationCompany) => void;
}
export interface PersonResponsibleForAccountProps {
  userRole: string;
  responsiblePerson: PersonResponsibleForAccount;
  toggleResponsiblePersonAccordion: boolean;
  toggleMailingAddressAccordionAction: (data: boolean) => void;
  savePersonResponsibleForAccountInfoAction: (data: PersonResponsibleForAccount) => void;
}
export interface AddressComponentProps {
  currentUser: User;
  userRole: string;
  addressType: string;
  mailingAddress: Addresses;
  billingAddress: Addresses;
  isInCheckout?: boolean;
  toggleMailingAddressAccordion: boolean;
  toggleBillingAddressAccordion: boolean;
  isUseMailingAddressChecked: boolean;
  toggleBillingAddressAccordionAction: (data: boolean) => void;
  toggleLicensingAccordionAction: () => void;
  toggleEditorsAccordionAction: () => void;
  toggleAboutYouAccordionAction: () => void;
  toggleMailingAddressAccordionAction: (data: boolean) => void;
  broadcastOnboardingLocationErrorAction: (data: IBroadcastMessage) => void;
  saveMailingAddressAction: (data: Addresses) => void;
  saveBillingAddressAction: (data: Addresses) => void;
  addBillingAddressForCheckout: (data: Addresses) => void;
  toggleAnotherBilling?: () => void;
  setIsUseMailingAddressSelectedAction: (data: boolean) => void;
}
export interface LicensingOnbordingProps {
  userRole: string;
  licensing: Licensing;
  toggleLicensingAccordion: boolean;
  toggleAppropriateChannelsAccordionAction: () => void;
  saveLicensingInfromationAction: (data: Licensing) => void;
}

export interface AppropriateChannelsComponentProps {
  toggleAppropriateChannels: boolean;
  toggleAboutYouAccordionAction: () => void;
  saveAppropriateChannelsAction: (data: AppropriateChannels) => void;
}
export interface AboutYouProps {
  profilePicture: DroppedFile | null;
  toggleAboutYouAccordion: boolean;
  saveAboutYouInformationAction: (data: AboutYou) => void;
  removeDroppedFileAction: () => void;
}
export interface EditorsAccordionProps {
  editorsInformation: EditorsInformation[];
  accordion: EditorsAccordion;
  isAddEditorButtonCLicked: boolean;
  isSaveButtonClicked: boolean;
  onboardingError: IBroadcastMessage;
  addEditorAccordion: () => void;
  broadcastOnboardingErrorAction: (data: IBroadcastMessage) => void;
  validateEditorsAccordionAction: (data: boolean) => void;
  clearOnboardingBroadcastAction: () => void; 
  handleDelete: (id: string, event: any) => any;
  addNewEditor: (newEditor: EditorsInformation) => void;
}

export interface EditorsInformationProps {
  editorsInformation : EditorsInformation[];
  toggleEditorsAccordion: boolean;
  isValidEditorsAccordion: boolean;
  onboardingError: IBroadcastMessage;
  clearOnboardingBroadcastAction: () => void; 
  broadcastOnboardingErrorAction: (data: IBroadcastMessage) => void;
  toggleAboutYouAccordionAction: () => void;
  validateEditorsAccordionAction: (data: boolean) => void;
  deleteEditorAction: (data: string) => void;
  saveEditorsInformationAction: (data: EditorsInformation[]) => void; 
}

export interface EditorsAccordion {
  id: string;
  validatedData: boolean;
  isLastAddedBeforeSave: boolean;
}

//-----------------------INPUT TYPES--------------------//
export interface LocationInput {
  country: string;
  countryCode?: string;
  stateProvince: string;
  stateCode?: string;
  city: string;
}

export interface AddressInput {
  addressType?: AddressTypes;
  addressLine1: string;
  addressLine2: string;
  zipCode: string;
}

export type UploadedProfileImageResponse = {
  filename: string;
  mimetype: string;
  encoding: string;
  key: string;
  url: string;
  blobObject?: File;
}

//----------------UPLOAD TYPES-------------------//
export interface DragAndDropOnboardingProps {
  profileImage: DroppedFile | null;
  removeDroppedFileAction: () => void;
  addProfileImageAction: (data: DroppedFile) => void;
}

//----------------------PAGES----------------------//
export interface OnboardingPageProps {
  currentUser: User;
  currentUserId: number;
  generalInformationPerson: GeneralInformationPerson;
  generalInformationCompany: GeneralInformationCompany;
  responsiblePerson: PersonResponsibleForAccount;
  licensing: Licensing;
  mailingAddress: Addresses;
  billingAddress: Addresses;
  appropriateChannels: AppropriateChannels;
  editorsInformation: EditorsInformation[];
  aboutYou: AboutYou;
  onboardingError: IBroadcastMessage;
  hasUserCompletedOnboarding: boolean;
  isOnboardingValid: OnboardingValidation;
  setUserProfileImageAction: (data: string) => void;
  broadcastOnboardingErrorAction: (data: IBroadcastMessage) => void;
  setCompletedOnboardingAction: (data: boolean) => void;
  validateOnboardingDataAction: (data: User) => void;
}
export interface OnboardingStepTwoProps {
  headline: string;
}
export interface OnboardingStepOneProps {
  currentUserRole?: string;
  currentUserId?: number;
}
export interface LicensePlan {
  id: number;
  amount: number;
  type: string;
  totalPrice: number;
  period?: number;
  licensingType: string;
}

export interface OnboardingLicensingStepProps {
  subscriptionBroadcast: IBroadcastMessage;
  handlePageChange: () => void;
  broadcastSubscriptionAction: (data: IBroadcastMessage) => void;
  redirectToHome: () => void;
}

//-----------------------CHANNELS---------------------------//
export interface Subchannel {
  id: number;
  title: string;
  channelId?: number;
}
export interface Subchannels {
  channel: Channel;
  subchannels: Subchannel[];
}

export interface Threads {
  channel: Channel;
  subchannel: Subchannel;
  threads: Thread[];
}

//--------------ENUMS--------------------//
export enum OnboardingSteps {
  GENERAL_INFORMATION = "Onboarding.Step.General.infromation",
  PREFERENCES = "Onboarding.Step.Preferences",
  LICENSING = "Onboarding.Step.Licensing",
  COMPLETE = "Onboarding.Step.Complete"
}

export enum InfoValues {
  firstName = "First.Name",
  lastName = "Last.Name",
  country = "Country",
  state = "State",
  city = "City",
  gender = "Gender",
  yearOfBirth = "Year.Of.Birth"
}

export enum PlanType {
  Credit = "Credit",
  Subscription = "Subscription",
}

export enum UserTypeSplitOnbording {
  MEMBER = "member",
  INDIVIDUAL_FREELANCER = "individiual_freelancer",
  AGENCY = "agency",
  ORGANIZATION = "organzation"
}

export enum AddressTypes {
  BILLING = 'billing',
  MAILING = 'mailing'
}

//----------------------STYLE OVERRIDES-----------------//

export const stepperTheme = createTheme({
  overrides: {
    MuiStepIcon: {
      completed: {
        color: "rgb(12, 175, 149) !important"
      },
      active: {
        color: "rgb(12, 175, 149) !important"
      }
    },
    MuiPaper: {
      root: {
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
        "&:before": {
          display: "none"
        }
      },
      elevation1: {
        boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important"
      }
    },
    MuiStepLabel: {
      active: {
        color: "rgb(12, 175, 149) !important"
      },
      completed: {
        color: "rgb(12, 175, 149) !important"
      }
    },
    MuiAccordion: {
      root: {
        background: "#e9e9e9 !important",
        border: "none",
        outline: "none",
        marginBottom: "10px",
        transition: "margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important",
        boxShadow: "none",
        "&:before": {
          content: "none"
        }
      }
    },
    MuiSvgIcon: {
      root: {
        //color: "white"
      }
    },
    MuiFormControlLabel: {
      root: {
        paddingLeft: "20px"
      }
    },
    // MuiCollapse: {
    //   container: {
    //     boxShadow: "none"
    //   }
    // },
    MuiTextField: {
      root: {
        marginBottom: "10px"
      }

    }
  }
});

export const dragAndDropStyles = makeStyles((theme) => ({
  svgIcon: {
    fill: "gray",
    borderRadius: '100px',
    backgroundColor: 'lightgrey'
  }
}))

export const buttonStyles = makeStyles((theme) => ({
  greenButtonRoot: {
    color: 'white !important',
    background: `${brandColor} !important`,
    fontSize: '1rem !important',
    margin: '15px 5px !important',
    lineHeight: '1.5',
    fontFamily: 'DM Sans Medium, sans-serif',
    border: 0,
    cursor: 'pointer',
    display: 'inline-flex',
    outline: 0,
    borderRadius: '25px !important',
    textTransform: 'none',
    '&: hover': {
      color: 'white',
      background: 'black'
    },
    '&:active': {
      transform: 'translateY(2px)'
    }
  },
  whiteButtonRoot: {
    color: 'black',
    background: 'white',
    fontSize: '1rem',
    lineHeight: '1.5',
    margin: '15px 5px',
    fontFamily: 'DM Sans Medium, sans-serif',
    border: '1px black',
    cursor: 'pointer',
    display: 'inline-flex',
    outline: 0,
    borderRadius: '25px',
    textTransform: 'none',
    '&: hover': {
      color: 'white',
      background: 'black'
    },
    '&:active': {
      transform: 'translateY(2px)'
    }
  }
}));

