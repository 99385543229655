import { RapidApiCityDto, RapidApiCountryDto, RapidApiProvinceDto } from "../../globals/locations/locations.types";

export const doesCountryExistInRapidApi = (countries: RapidApiCountryDto[], selectedCountry: RapidApiCountryDto): boolean => {
    if (selectedCountry) {
        const isValid = countries.find(country => {
            return country.country === selectedCountry.country && country.countryCode === selectedCountry.countryCode;
        })

        if (isValid) {
            return true;
        }
    }
    return false;
}

export const doesProvinceExistInRapidApi = (provinces: RapidApiProvinceDto[], selectedProvince: RapidApiProvinceDto): boolean => {
    if (selectedProvince) {
        const isValid = provinces.find(province => {
            return province.province === selectedProvince.province && province.isoCode === selectedProvince.isoCode;
        })

        if (isValid) {
            return true;
        }
    }
    return false;
}

export const doesCityExistInRapidApi = (cities: RapidApiCityDto[], selectedCity: RapidApiCityDto): boolean => {
    if (selectedCity) {
        const isValid = cities.find(city => {
            return city.city === selectedCity.city;
        })

        if (isValid) {
            return true
        }
    }

    return false;
}

export const parseThreadTitle = (title: string): string  => {
    const parsedTitle = title.toLowerCase().replace(/[^a-zA-Z0-9]/g, "");
    return parsedTitle;
}

export const checkIfThreadExistsInSubchannnel = (threads: any[] , threadTitle: string) => {
    const parsedThreadTitle = parseThreadTitle(threadTitle);
    
    const isExisting = threads.find((thread) => {
        return parseThreadTitle(thread.title) === parsedThreadTitle;
    })
    return isExisting;
}