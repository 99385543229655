import React from "react";
import { Dialog} from "@material-ui/core";
import RegisterContainer from '../register/RegisterContainer';
import LoginContainer from "./LoginContainer";
import ForgotPasswordContainer from '../forgot-password/ForgotPasswordContainer';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { StoreState } from "../../redux/root-reducer";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { TModalReducerActions, IResetToggles, IToggleLogin, IToggleRegister } from "../../redux/modal-visibility/modal.actions";
import { ModalActionTypes } from "../../redux/modal-visibility/modal.types";
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import "./modal.styles.scss";
import { IToggleBannerHidden, TSignInBannerReducerActions } from "../../redux/sign-in-banner/sign-in-banner.actions";
import { BannerActionTypes } from "../../redux/sign-in-banner/sign-in-banner.types";
import { IToggleRegisterWraperHidden, TRegisterModalWraperReducerActions } from "redux/register-modal-wraper/register-modal-wraper.actions";
import { RegisterWraperActionTypes } from "../../redux/register-modal-wraper/register-modal-wraper.types";
import {LoginModalProps, dialogStyles} from './login.types'
import { selectForgotPasswordModal, selectLoginModal, selectRegisterAsRoleModal, selectRegisterModal } from "../../redux/modal-visibility/modal.selectors";

const LoginModal: React.FC<LoginModalProps> = ({ ...props }) => {
    const { toggleRegisterAsRole, toggleRegisterAsRoleModal, toggleRegisterModal, toggleForgotPasswordModal, toggleLoginModal, resetTogglesModalAction, toggleBanner, toggleLogin, toggleRegister } = props;
    const styles = dialogStyles();
    const handleCloseLogin = () => {
        resetTogglesModalAction();
    }
    const LoadToggledModal = () => {
        if (toggleRegisterModal) {
            return <RegisterContainer />;
        }
        else if (toggleForgotPasswordModal) {
            return <ForgotPasswordContainer />;
        }
        else if (toggleRegisterAsRoleModal) {
            toggleRegisterAsRole(false)
            return <RegisterContainer />
        }
        else {
            return <LoginContainer />;
        }
    }

    return (
        <div>
                <Dialog 
                    classes = {{root: styles.dialogRoot, paper: styles.dialogPaper}}
                    style={{zIndex:10000}}
                    closeAfterTransition={true}
                    onClose={handleCloseLogin}
                    open={toggleLoginModal || toggleForgotPasswordModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 800
                    }}>

                    <Fade in={toggleLoginModal || toggleForgotPasswordModal}>
                        <div className='login-modal'>
                            <button className='close-button-login' aria-label='google' onClick={handleCloseLogin}>
                                <FontAwesomeIcon className='icon-button-login' icon={faTimes}/>
                            </button>
                            {LoadToggledModal()}
                        </div>
                    </Fade>
                </Dialog>
        </div>
    )
}

const mapStateToProps = (state: StoreState): { toggleForgotPasswordModal: boolean, toggleRegisterModal: boolean, toggleLoginModal: boolean, toggleRegisterAsRoleModal: boolean } => {
    return {
        toggleForgotPasswordModal: selectForgotPasswordModal(state),
        toggleRegisterModal: selectRegisterModal(state),
        toggleLoginModal: selectLoginModal(state),
        toggleRegisterAsRoleModal: selectRegisterAsRoleModal(state)
    }
}

const mapDispatchToProps = (dispatch: Dispatch<TModalReducerActions | TSignInBannerReducerActions | TRegisterModalWraperReducerActions>) => {
    return {
        resetTogglesModalAction: () => dispatch<IResetToggles>({ type: ModalActionTypes.ResetTogglesModal }),
        toggleLogin: () => dispatch<IToggleLogin>({ type: ModalActionTypes.ToggleLoginModal }),
        toggleBanner: (show: boolean) => dispatch<IToggleBannerHidden>({ type: BannerActionTypes.TOGGLE_BANNER_HIDDEN, data: show }),
        toggleRegisterAsRole: (show: boolean) => dispatch<IToggleRegisterWraperHidden>({ type: RegisterWraperActionTypes.TOGGLE_REGISTER_WRAPER_HIDDEN, data: show }),
        toggleRegister: () => dispatch<IToggleRegister>({ type: ModalActionTypes.ToggleRegisterModal }),

    }
}


export default connect(mapStateToProps, mapDispatchToProps)(LoginModal);