import { connect } from 'react-redux';
import { UserState, RegisterState, UserActionTypes, User } from '../../redux/user/user.types';
import { TUserReducerActions, IStartRegister, IRegisterSuccess, IRegisterFailure, ILoginSuccess} from '../../redux/user/user.actions';
import { StoreState } from '../../redux/root-reducer';
import { Dispatch} from 'redux';
import { REGISTER_USER } from './queries';
import { LOGIN_USER} from '../login/queries';
import { graphql } from 'react-apollo';
import {flowRight as compose} from 'lodash';
import { Register } from 'shared';
import { push, CallHistoryMethodAction } from 'connected-react-router';
import { IToggleLogin, IToggleForgotPassword, TModalReducerActions, IResetToggles } from '../../redux/modal-visibility/modal.actions';
import { ModalActionTypes } from '../../redux/modal-visibility/modal.types';
import {TTranslationsReducerActions} from "../../redux/translations/translations.actions";
import {TranslationsState} from "../../redux/translations/translations.types";
import { IResetPage, TPageReducerActions } from '../../redux/error-page/error-page.actions';
import { ErrorPagesTypes } from '../../redux/error-page/error-page.types';

const mapStateToProps = (state: StoreState) : {user: UserState, translations: TranslationsState, registerType:string} => {
    return {
        user: state.user,
        translations: state.translations,
        registerType: state.signin.registerType
    }
}

const mapDispatchToProps = (dispatch: Dispatch<TUserReducerActions |  CallHistoryMethodAction | TModalReducerActions | TTranslationsReducerActions | TPageReducerActions>) => {
    return {
        registerUserAction: () => dispatch<IStartRegister>({type: UserActionTypes.RegisterUser}),
        registerUserSuccess: (data: RegisterState) => {
            dispatch<IRegisterSuccess>({type: UserActionTypes.RegisterUserSuccess, data: data});
            dispatch<IResetToggles>({type: ModalActionTypes.ResetTogglesModal});
            dispatch<IResetPage>({type: ErrorPagesTypes.ResetTogglesPage})
        },
        redirectToOnboarding: () => dispatch(push('/onboarding')),
        loginSuccessAction: (data: User) => dispatch<ILoginSuccess>({type: UserActionTypes.LoginSuccess, data: data}),
        registerUserFailure: (message: string) => dispatch<IRegisterFailure>({type: UserActionTypes.RegisterUserError, data: message}),
        authenticateWithThirdParty: (socNetwork: string) => {
            window.location.replace(socNetwork);
        },
        toggleLoginModalAction: () => dispatch<IToggleLogin>({type: ModalActionTypes.ToggleLoginModal}),
        toggleForgotPasswordModalAction: () => dispatch<IToggleForgotPassword>({type: ModalActionTypes.ToggleForgotPasswordModal})   
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(compose(graphql(LOGIN_USER, {name: "loginUserMutation"}), graphql(REGISTER_USER, {name: 'registerUserMutation'}))(Register));