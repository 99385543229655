import {PhotoDetailsActionTypes, PhotoDetailsState} from './photo-details.types';
import {TPhotoDetailsActions} from './photo-details.actions';
import {TileData} from '../../components/home-component/home.types';
import { IBroadcastMessage } from '../../components/LightBox/lightBox.types';

const InitialState: PhotoDetailsState = {
    item: {} as TileData,
    currentSelectedPhoto: 0,
    clickedImageToAddToLightBox: 0,
    broadcastMessage: {} as IBroadcastMessage,
};

export const photoDetailsReducer = (state = InitialState, action: TPhotoDetailsActions): PhotoDetailsState => {
    switch (action.type) {
        case PhotoDetailsActionTypes.STORE_PHOTO_DETAILS:
            return {
                ...state,
                currentSelectedPhoto: action.data.id,
                item: action.data
            };
        case PhotoDetailsActionTypes.SELECT_PHOTO_FROM_SET:
            return {
                ...state,
                currentSelectedPhoto: action.data
            }
        case PhotoDetailsActionTypes.SET_CLICKED_IMAGE_TO_ADD_TO_CLICK_BOX:
            return {
                ...state,
                clickedImageToAddToLightBox: action.data
            }
        case PhotoDetailsActionTypes.BROADCAST_MESSAGE:
            return {
                ...state,
                broadcastMessage: action.data
            }
        case PhotoDetailsActionTypes.CLEAR_BROADCAST:
            return {
                ...state,
                broadcastMessage: {} as IBroadcastMessage
            }
        default:
            return state;
    }
}
export default photoDetailsReducer;