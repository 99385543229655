import React, { useState } from 'react';
import { Button, Paper, MenuList, MenuItem, Popper, Grow } from '@mui/material';
import { ClickAwayListener } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { CurrencyType, PreferredCurrencyProps } from './contributor-content.types';
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_PREFFERED_CURRENCY } from './queries';
import { StoreState } from '../../redux/root-reducer';
import { User } from '../../redux/user/user.types';
import { selectCurrentUser } from '../../redux/user/user.selectors';
import { connect } from 'react-redux';
import { IBroadcastMessage, SeveritySnackbarEnum } from '../../components/batch-upload/ContentUpload.types';
import { IBroadcastContentUploadMessage, TContentUploadActions } from '../../redux/content-upload/content-upload.actions';
import { ContentUploadActionTypes } from '../../redux/content-upload/content-upload.types';
import { Dispatch } from 'redux';

const PreferredCurrencyComponent: React.FC<PreferredCurrencyProps> = ({ ...props }) => {
    const { currentUser, broadcastCurrencyUpdateAction} = props;
    const anchorRef = React.useRef<HTMLButtonElement>(null);
    const currentUserId = currentUser ? currentUser.id : 0;

    const [open, setOpen] = useState(false);
    const [preferredCurrency, setPrefferedCurrency] = useState(currentUser ? currentUser.preferredCurrency : '');

    const [updatePrefferedCurrencyMutation] = useMutation(UPDATE_PREFFERED_CURRENCY);
    const updatePrefferedCurrency = (currentUserId: number, selectedCurrency: string) => {
        return updatePrefferedCurrencyMutation({
            variables: {
                userId: currentUserId,
                prefferedCurrency: selectedCurrency
            }
        }).then((result: any) => {
            broadcastCurrencyUpdateAction({severity: SeveritySnackbarEnum.success, message: "Preferred currency updated successfully"})
        }).catch((error: any) => {
            broadcastCurrencyUpdateAction({severity: SeveritySnackbarEnum.error, message: error.graphQLErrors[0].message})
        })
    };

    const openContentTypeDropdown = () => {
        setOpen(prevState => !prevState);
    };

    const handleListKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === 'Escape') {
            setOpen(false);
        }
    };

    const handleClose = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpen(false);
    };

    const handleChangeCurrency = (event: any, value: any) => {
        event.preventDefault();
        if (preferredCurrency && currentUserId) {
            updatePrefferedCurrency(currentUserId, value);
            setPrefferedCurrency(value);
        }
        setOpen(false);
    };

    const convertPreferredCurrency = (currency: string, myEnum: any): any => {
        let currencySymbol;
        (Object.keys(myEnum) as (keyof typeof myEnum)[]).find((key: any) => {
                if(key === currency) {
                    currencySymbol = myEnum[key]
                }
            }
        );
        return currencySymbol;
    }

    return (
        <React.Fragment>
            <Button ref={anchorRef} size="small" aria-controls={open ? 'menu-list-grow' : undefined}
                variant="contained" className='white-round-button'
                onClick={openContentTypeDropdown} endIcon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}>
                {convertPreferredCurrency(preferredCurrency!, CurrencyType)}
            </Button>
            <Popper open={open} style={{ zIndex: 5 }} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                    <MenuItem key="usd" value="usd" onClick={(event: any) => {handleChangeCurrency(event, "usd")}}>{CurrencyType.usd}</MenuItem>
                                    <MenuItem key="gbp" value="gbp" onClick={(event: any) => {handleChangeCurrency(event, "gbp")}}>{CurrencyType.gbp}</MenuItem>
                                    <MenuItem key='eur' value="eur" onClick={(event: any) => {handleChangeCurrency(event, "eur")}}>{CurrencyType.eur}</MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </React.Fragment>
    );
}

const mapStateToProps = (state: StoreState): { currentUser: User } => {
    return {
        currentUser: selectCurrentUser(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch<TContentUploadActions>) => {
    return {
        broadcastCurrencyUpdateAction: (data: IBroadcastMessage) => dispatch<IBroadcastContentUploadMessage>({
            type: ContentUploadActionTypes.BROADCAST_MESSAGE, data: data
        })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PreferredCurrencyComponent);