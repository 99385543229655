import { getToTwoDecimals } from "../../components/cart/cart-page-helperFunctions";
import { LicensingTypes } from "../../components/batch-upload/EditContentMetadata.types";
import { ItemForCart } from "../../components/photo-details/photo-details.types";

export const addToCart = (itemsInCart: ItemForCart[], newItem: ItemForCart) => {
    if (!itemsInCart.find((item: ItemForCart) => item.content.id === newItem.content.id && item.flatRate === newItem.flatRate)) {
      const currentSubItems = itemsInCart.concat([newItem]);
      return currentSubItems;
    } else {
        return itemsInCart;
    }
}

export const calculateSubtotal = (itemsInCart: ItemForCart[], newItem: ItemForCart) => {
    const subTotalWithoutNewItem = itemsInCart.reduce( function (prev, curr) {return prev + curr.flatRate}, 0);

    if (!itemsInCart.find((item: ItemForCart) => item.content.id === newItem.content.id && item.flatRate === newItem.flatRate)) {
        const subtotal = subTotalWithoutNewItem + newItem.flatRate;
        return parseFloat(getToTwoDecimals(subtotal));
    } else {
        return parseFloat(getToTwoDecimals(subTotalWithoutNewItem));
    }
}

export const checkItemToRemove = (itemInCart: ItemForCart, newItem: ItemForCart) =>
{
    if(newItem.content.licensingType === LicensingTypes.EDITORIAL && (itemInCart.content.id !== newItem.content.id || itemInCart.content.size !==  newItem.content.size))
    {
        return true
    }
    else if(newItem.content.licensingType !== LicensingTypes.EDITORIAL && itemInCart.content.id !== newItem.content.id)
    {
        return true
    }else 
    {
        return false
    }
}