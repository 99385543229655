import gql from 'graphql-tag';

export const GET_ORDER_LIST = gql`
query userOrderList(
  $licenseOrigin: String!
  $userId: Int!
  $pageNumber: Int!
  $contentPerPage: Int!
  $photographerName: String!
  $size: String!
  $orderDate: [String!]!
  $searchString: String!
) {
  userOrderList(
    licenseOrigin: $licenseOrigin
    userId: $userId
    pageNumber: $pageNumber
    contentPerPage: $contentPerPage
    photographerName: $photographerName
    size: $size
    orderDate: $orderDate
    searchString: $searchString
  ) {
    totalOrders
    contentOrders {
      id
      size
      content {
        id
        pathToFileThumbnail
        pathToFileCompressed
        code
        headline
        contentInSet {
          contentSetId
          contentSet {
            article {
              articleId
            }
          }
        }
        threadContents {
          threadId
        }
        copyright {
          byLine
        }
      }
      order {
        id
        orderDate
        user {
          id
          person {
            firstName
            lastName
          }
        }
      }
    }
  }
}
`;

export const UPDATE_PREFFERED_CURRENCY = gql`
    mutation updatePrefferedCurrency($userId: Int!, $prefferedCurrency: String!) {
        updatePrefferedCurrency(userId: $userId, prefferedCurrency: $prefferedCurrency)
    }
`

export const GET_ALL_CONTENT_COUNT = gql`
    query getAllContentCountInDatatables($contributorId: Int, $status: String, $isFMC: Boolean) {
        getAllContentCountInDatatables(contributorId: $contributorId, status: $status, isFMC: $isFMC){
            articlesCount
            contentSetsCount
            singleContentCount
        }
    }
`