import React, { useEffect, useState } from 'react';
import { CartSummaryProps, detailAccordions } from './cart-page.types';
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Radio, Accordion, AccordionSummary, Divider, Grow } from "@material-ui/core";
import './cart-page.styles.scss';
import { StoreState } from '../../redux/root-reducer';
import { TCartReducerActions, IEmptyCart } from "../../redux/cart/cart-page.actions";
import { CartActionTypes, TaxForOrder } from "../../redux/cart/cart-page.types";
import { Dispatch } from "redux";
import { selectTax, selectCurrentCartItems, selectCurrentCartSubtotal, selectCurrentCartTotal, selectCurrentSubItems } from '../../redux/cart/cart-page.selector';
import SyncLoader from 'react-spinners/SyncLoader';
import { capitalizeFirstLetter } from '../photo-details/photo-details-helper-functions';
import { selectCurrentUser } from '../../redux/user/user.selectors';
import { User } from '../../redux/user/user.types';
import { ItemForCart } from '../photo-details/photo-details.types';
import { UserLicensingPlan } from '../my-license-page/my-license-page.types';
import { getExpirationDate, getRemainingDays, getToTwoDecimals } from './cart-page-helperFunctions';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chip: {
            margin: theme.spacing(0.5),
        },
        section1: {
            margin: theme.spacing(3, 2),
            textAlign: 'left',
            position: 'relative',
        },
        section2: {
            margin: theme.spacing(2),
        },
        section3: {
            margin: theme.spacing(3, 1, 1),
        },
        loadingBox: {
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: '0',
            left: '0',
            opacity: '1',
            zIndex: 19
        }
    }),
);

const OrderSummary: React.FC<CartSummaryProps> = ({ ...props }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const accClasses = detailAccordions();
    const { loading, userLicensingPlans, currentUser, cartItems, cartItemsFromSub,
        subtotal, total, tax, isSummaryForSubscriptions, isLicensePlanInvalid } = props;

    return (
        <Grow in={true}>
            <Accordion expanded={true} classes={{root: accClasses.MuiAccordionRoot}}>
                <AccordionSummary id="panel1c-header">
                    <div className="title">
                        {t("Cart.Order.Summary")}
                    </div>
                </AccordionSummary>
                {loading ?
                    <div className={classes.loadingBox}>
                        <SyncLoader css={`display: block; margin: 0 auto; border-color: red;`} size={20}
                            color={"#36D2B3"} loading={loading} />
                    </div>
                    : null}

                <div className="accordion-container">
                    {isSummaryForSubscriptions ?
                        <React.Fragment>
                            <Grid container alignItems="center">
                                <Typography color="textSecondary" variant="body2">
                                    {`You will be deducted ${cartItemsFromSub.length}
                            credit(s) for this order from your license plans`}
                                </Typography>

                                {/* License Plans */}
                                {userLicensingPlans.map((license: UserLicensingPlan) => (
                                    <React.Fragment key={license.id}>
                                        <Grid container direction="row" spacing={3}>
                                            <Grid item xs>
                                                <Typography color="textPrimary" variant="h6">
                                                    {license.expirationDate ? `${getRemainingDays(license.purchaseDate, license.expirationDate)} Days ${license.plan.type}, ` : ""}
                                                    {license.expirationDate ? `${capitalizeFirstLetter(license.plan.licensingType)} License plan` : `${capitalizeFirstLetter(license.plan.licensingType)} License`}
                                                </Typography>
                                                <Typography color="textPrimary" variant="subtitle1">
                                                    {`Purchases left with this plan: ${license.amountLeft}`}
                                                </Typography>
                                                <Typography color="textPrimary" variant="body2">
                                                    {license.expirationDate ? `Expiration date for your plan is ${getExpirationDate(license.expirationDate)}` : ""}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography gutterBottom variant="h6">
                                                    {`$${getToTwoDecimals(license.plan.totalPrice)}`}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid>
                                            <Divider />
                                        </Grid>
                                    </React.Fragment>
                                ))}

                                {userLicensingPlans.length === 0 ?
                                    <Typography color="textPrimary" gutterBottom variant="h6">
                                        No licensing plan found for this user.
                                Find more about our license plans <a href="/my-license-page">HERE</a>
                                    </Typography>
                                    : null}
                            </Grid>

                            <Divider />

                            {isLicensePlanInvalid && userLicensingPlans.length > 0 ?
                                <Typography variant="h6" color="error">
                                    Insufficient credits left on your subscription plans.
                                    Consider updating your license plans in order to continue with this order.
                            You can check our license plans from <a href="/my-license-page">My license plans</a>
                                </Typography>
                                : null}
                            <Grid container alignItems="center">
                                <Grid item xs>
                                    <Typography color="textPrimary" variant="h6">
                                        Total credits
                            </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6">
                                        {`${cartItemsFromSub.length}`}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <Grid container alignItems="center" spacing={4}>
                                <Grid item xs>
                                    <Typography color="textPrimary" variant="body1">
                                        Subtotal
                            </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6">
                                        {`$${getToTwoDecimals(subtotal)}`}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container alignItems="center">
                                <Grid item xs>
                                    <Typography color="textPrimary" variant="body1">
                                        VAT/GST
                            </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6">
                                        {`$${tax ? getToTwoDecimals(tax.amountToCollect) : getToTwoDecimals(0)}`}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                            <Grid container alignItems="center">
                                <Grid item xs>
                                    <Typography color="textPrimary" variant="h6">
                                        Total amount to pay
                            </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6">
                                        {`$${getToTwoDecimals(total)}`}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    }
                </div>
            </Accordion>
        </Grow>
    )
}

const mapStateToProps = (state: StoreState): {
    tax: TaxForOrder | null, cartItems: ItemForCart[]; cartItemsFromSub: ItemForCart[];
    subtotal: number; total: number; currentUser: User;
} => {
    return {
        tax: selectTax(state),
        cartItems: selectCurrentCartItems(state),
        cartItemsFromSub: selectCurrentSubItems(state),
        subtotal: selectCurrentCartSubtotal(state),
        total: selectCurrentCartTotal(state),
        currentUser: selectCurrentUser(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch<TCartReducerActions>) => {
    return {
        emptyCart: () => dispatch<IEmptyCart>({ type: CartActionTypes.EMPTY_CART })
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderSummary);