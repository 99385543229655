import React, { useEffect, useState } from "react";
import { channelSidebarProps, SubChannel, Thread, useStyles } from './channelSideBars.types'
import { GET_SUBCHANNELS, GET_THREADS_CONTENT_COUNT } from './queries'
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { push, CallHistoryMethodAction } from "connected-react-router";
import { composePathSubChannles, composePathThreads, isDouble, includesLash, getWordsBeforeThe16letter, getButtonTextByLines } from './helperFunctions';
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { StoreState } from "redux/root-reducer";
import { User } from "redux/user/user.types";
import { Drawer, InputAdornment, Chip, IconButton, makeStyles, TextField } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import variables from '../../assets/globalVariables.module.scss';
import { generateChannelColorArray } from "../channelScreen/helperFunctions";
import SearchIcon from '@material-ui/icons/Search';
import { IAddSubChannel, TChannelReducerActions, IAddThread, IRemoveThread, IAddChannel } from "../../redux/channel-routes/channel.actions";
import { ChannelActionTypes } from "../../redux/channel-routes/channel.types";
import './channelsSidebar.styles.scss'
import ContentDisplay from "../contents/ContentDisplay";
import TrendingContentComponent from "../trendingContent/TrendingContent";
import { selectCurrentChannel, selectCurrentSubChannel, selectCurrentThread } from "../../redux/channel-routes/channel.selectors";
import { selectCurrentUser, selectUserLocationPermission } from "../../redux/user/user.selectors";
import { Channel } from "../home-component/home.types";
import { GET_CHANNELS } from "../channels/queries";
import LocalContentComponent from "../../components/local-content/local-content.component";
import { selectLocalContentData } from "../../redux/local-content/local-content.selectors";
import { LocalContent } from "../../redux/local-content/local-content.types";
import { SnackbarComponent } from "shared";
import { Autocomplete } from "@mui/material";
import { IToggleWrongPath, TPageReducerActions } from "../../redux/error-page/error-page.actions";
import { ErrorPagesTypes } from "../../redux/error-page/error-page.types";

const ChannelsSidebar: React.FC<channelSidebarProps> = ({ ...props }) => {
    const { selectedSubChannel, selectedThread, localContentData, selectedChannel, routeParams, userLocationPermission, currentUser,
        redirectToThread, redirectToSubChannel, selectChannelAction, selectSubChannelAction, selectThreadAction, removeThreadAction, toggleWrongPathPageAction } = props;
      
    const breakpoint = 1024;

    const [showChannelsSnackbar, setShowChannelsSnackbar] = useState(false);
    const [open, setOpen] = useState(true);
    const [activeLink, setActive] = useState<number>(-1);
    const [colors, setColors] = useState<any[]>([]);
    const [channels, setChannels] = useState<Channel[]>([]);
    const [subChannels, setSubChannels] = useState<SubChannel[]>([]);
    const [threads, setThreads] = useState<Thread[]>([]);
    const [searchFilter, setSearchFilter] = useState("");

    const { data: dataChannels, error: channelsError } = useQuery(GET_CHANNELS, { fetchPolicy: 'cache-and-network' });
    const [fetchSubchannels, { data: dataSub, error: subError }] = useLazyQuery(GET_SUBCHANNELS, { fetchPolicy: 'cache-and-network' });
    const [fetchThreadCount, { data: dataThreads, error: threadsError }] = useLazyQuery(GET_THREADS_CONTENT_COUNT, { fetchPolicy: 'cache-and-network' });

    const classes = useStyles();

    useEffect(() => {
        if (channelsError || subError || threadsError)
            setShowChannelsSnackbar(true);
    }, [channelsError, subError, threadsError])

    useEffect(() => {
        if (routeParams.subchannelName) {
            fetchThreadCount({ variables: { id: selectedSubChannel.id } });
        }
    }, [selectedSubChannel])

    useEffect(() => {
        if (dataChannels && dataChannels.getAllChannels) {
            setChannels(dataChannels.getAllChannels);
            if (routeParams.channelName.toLowerCase() !== selectedChannel.title.toLowerCase().replace(/[^A-Z0-9]/ig, '')) {
                let match = dataChannels.getAllChannels.find((channel: any) => channel.title.toLowerCase().replace(/[^A-Z0-9]/ig, '') === routeParams.channelName.toLowerCase());
                if (match) {
                    selectChannelAction({ id: match.id, title: match.title, code: match.code });
                } else {
                    toggleWrongPathPageAction();
                }
            }

            fetchSubchannels({ variables: { id: selectedChannel.id } });
        }
    }, [dataChannels, selectedThread]);

    useEffect(() => {
        if (dataSub && dataSub.getAllSubChannelsByChannelId) {
            setSubChannels(dataSub.getAllSubChannelsByChannelId);
            if (routeParams.subchannelName && routeParams.subchannelName.toLowerCase() !== selectedSubChannel.title.toLowerCase().replace(/[^A-Z0-9]/ig, '')) {
                let match = dataSub.getAllSubChannelsByChannelId.find((subchannel: any) => subchannel.title.toLowerCase().replace(/[^A-Z0-9]/ig, '') === routeParams.subchannelName!.toLowerCase());
                if (match) {
                    selectSubChannelAction({ id: match.id, title: match.title });
                } else {
                    toggleWrongPathPageAction();
                }
            }

            if (routeParams.subchannelName) {
                fetchThreadCount({variables: {id: selectedSubChannel.id}});
            }
        }
    }, [dataSub])

    useEffect(() => {
        if (dataThreads && dataThreads.getEveryThreadContentCountinSubchannel) {
            setThreads(dataThreads.getEveryThreadContentCountinSubchannel);
            if (routeParams.threadName && routeParams.threadName.toLowerCase() !== selectedThread.title.toLowerCase().replace(/[^A-Z0-9]/ig, '')) {
                let match = dataThreads.getEveryThreadContentCountinSubchannel.find((thread: any) =>   { 
                    return thread.title.toLowerCase().replace(/[^A-Z0-9]/ig, '') === routeParams.threadName!.toLowerCase()
                });
                if (match) {
                    selectThreadAction({ id: match.id, title: match.title });
                } else {
                    toggleWrongPathPageAction();
                }
            }
        }
    }, [dataThreads])

    useEffect(() => {
        if (selectedThread.title === '') {
            setActive(-1);
        }
        setColors(generateChannelColorArray(selectedChannel.code, variables))
    }, [selectedChannel, selectedSubChannel, selectedThread])

    const drawerStyles = makeStyles((theme) => ({
        drawerPaper: {
            position: 'relative',
            borderRadius: '25px',
            alignItems: 'start',
            paddingBottom: '20px',
            backgroundColor: colors[1] + '!important',
        },
        buttonRoot: {
            fontFamily: 'sans-serif',
            float: 'left',
            fontSize: 'inherit',

            "&:hover": {
                boxShadow: `1px 4px 16px ${colors[0]}`
            }
        },
        buttonLabel: {
            textAlign: 'left'
        }
    }));

    const drawer = drawerStyles();

    const handleDrawer = () => {
        setOpen(prevState => !prevState);
    };

    const handleSearchChange = (event: any) => {
        event.preventDefault();
        const value = event.target.value;
        setSearchFilter(value);
    }

    const handleClose = () => {
        setShowChannelsSnackbar(false);
    }

    const handleOnClickSubchannels = (subchannel: SubChannel) => {
        selectSubChannelAction(subchannel);
        fetchThreadCount({ variables: { id: selectedSubChannel.id } });
        setSearchFilter("");
        redirectToSubChannel(selectedChannel.title, subchannel.title);
    }

    const handleOnClickThreads = (thread: Thread) => {
        selectThreadAction(thread);
        setSearchFilter("");
        redirectToThread(selectedChannel.title, selectedSubChannel.title, thread.title);
        if (activeLink === -1 || activeLink !== thread.id) {
            setActive(thread.id);
        }
        else {
            redirectToSubChannel(selectedChannel.title, selectedSubChannel.title);
            setActive(-1);
            removeThreadAction();
        }
    };

    const returnButtonLabel = (firstLineOfButton: string, secondLineOfButton: string) => {
        return secondLineOfButton.length > 0 ? (
            <><span>{firstLineOfButton}</span> <br></br> <span>{secondLineOfButton}</span></>
        ) : firstLineOfButton;
    }

    const splitButton = (buttonText: string) => {
        let buttonTextArr = buttonText.split(" ");
        let arrBefore16: string[] = [];
        getWordsBeforeThe16letter(buttonTextArr, arrBefore16)
        let lines = getButtonTextByLines(buttonTextArr, arrBefore16);

        return returnButtonLabel(lines.firstLineOfButton, lines.secondLineOfButton);
    }

    const splitByLash = (buttonText: string) => {
        let buttonTextArr = buttonText.split("/");
        return (
            <><span>{buttonTextArr[0] + '/'}</span> <br></br> <span>{buttonTextArr[1]}</span></>
        )
    }

    return (
        <>
            <div className="sidebar-container">
                <IconButton
                    aria-label="open-drawer"
                    onClick={handleDrawer}
                    className={open ? "hide" : "show-right-icon"}
                >
                    <ChevronRightIcon classes={{ root: classes.svgIcon }} style={{ fontSize: '2rem' }} />
                </IconButton>
                <div className={open ? "drawer-container" : "no-drawer-container"}>
                    <Drawer
                        style={{height: 'auto'}}
                        variant={'persistent'}
                        transitionDuration={500}
                        anchor='left'
                        open={open}
                        classes={{ paper: drawer.drawerPaper }}
                    >
                        <IconButton
                            onClick={handleDrawer}
                            aria-label="open drawer"
                            className={!open ? "hide" : "show-left-icon"}
                        >
                            <ChevronLeftIcon classes={{ root: classes.svgIcon }} style={{ fontSize: '2.5rem' }} />
                        </IconButton>
                        {selectedSubChannel.id !== -1 ?
                            <Autocomplete
                                id="autocomplete-search-threads"
                                classes={{ root: classes.formControl }}
                                options={threads}
                                autoHighlight
                                getOptionLabel={(option: Thread) => option ? option.title : ""}
                                renderInput={(params: any) => (
                                    <TextField
                                        {...params}
                                        id="channel-input" variant='standard' placeholder="Search"
                                        value={searchFilter} name="thread-search" className='text-field'
                                        onChange={handleSearchChange}
                                        InputProps={{
                                            startAdornment:
                                                <InputAdornment position="start">
                                                    <SearchIcon classes={{ root: classes.svgIcon }} />
                                                </InputAdornment>
                                        }}
                                    />
                                )}
                            /> :
                            <Autocomplete
                                id="autocomplete-search-subchannels"
                                classes={{ root: classes.formControl }}
                                value={selectedSubChannel}
                                options={subChannels}
                                autoHighlight
                                getOptionLabel={(option: SubChannel) => option ? option.title : ""}
                                renderInput={(params: any) => (
                                    <TextField
                                        {...params}
                                        id="subchannel-input" variant='standard' placeholder="Search"
                                        value={searchFilter} name="subchannel-search" className='text-field'
                                        onChange={handleSearchChange}
                                        InputProps={{
                                            startAdornment:
                                                <InputAdornment position="start">
                                                    <SearchIcon classes={{ root: classes.svgIcon }} />
                                                </InputAdornment>
                                        }}
                                    />
                                )}
                            />
                        }
                        {
                            selectedSubChannel.id !== -1 ?
                                threads.filter(thread => thread.title.toLowerCase().includes(searchFilter.toLowerCase())).map((channel: Thread) => {
                                    return channel && channel.count && channel.count > 0 ? 
                                        <Chip
                                            label={includesLash(channel.title) ? splitByLash(channel.title) : splitButton(channel.title)}
                                            classes={{ root: drawer.buttonRoot, label: drawer.buttonLabel }}
                                            style={{
                                                backgroundColor: activeLink === channel.id ? colors[0] : colors[2],
                                                color: activeLink === channel.id ? 'white' : '#000',
                                                marginTop: '5%',
                                                marginLeft: '7%',
                                                justifyContent: 'start',
                                                maxWidth: '200px',
                                                borderRadius: '20px',
                                                paddingTop: isDouble(channel.title) || includesLash(channel.title) ? '30px' : '19px',
                                                paddingBottom: isDouble(channel.title) || includesLash(channel.title) ? '30px' : '19px',
                                            }}
                                            onClick={() => {
                                                handleOnClickThreads(channel);
                                            }}
                                            key={channel.id} /> : null
                                    })
                                :
                                subChannels.filter(subchannel => subchannel.title.toLowerCase().includes(searchFilter.toLowerCase())).map((channel: SubChannel) => (
                                    <Chip
                                        label={includesLash(channel.title) ? splitByLash(channel.title) : splitButton(channel.title)}
                                        classes={{ root: drawer.buttonRoot, label: drawer.buttonLabel }}
                                        style={{
                                            backgroundColor: activeLink === channel.id ? colors[0] : colors[2],
                                            color: activeLink === channel.id ? 'white' : '#000',
                                            marginTop: '5%',
                                            marginLeft: '7%',
                                            justifyContent: 'start',
                                            maxWidth: '200px',
                                            borderRadius: '20px',
                                            paddingTop: isDouble(channel.title) || includesLash(channel.title) ? '30px' : '19px',
                                            paddingBottom: isDouble(channel.title) || includesLash(channel.title) ? '30px' : '19px',
                                        }}
                                        onClick={() => {
                                            handleOnClickSubchannels(channel);
                                        }}
                                        key={channel.id} />
                                ))
                        }

                    </Drawer>
                </div>

                <main className={localContentData.length === 0 && !open ? "content-container-no-local-no-sidebar" : localContentData.length === 0 ? "content-container-no-local" : "content-container"}>
                    <ContentDisplay selectedChannel={selectedChannel}
                        selectedThread={selectedThread} selectedSubChannel={selectedSubChannel} />
                </main>

                {userLocationPermission && localContentData.length > 0 ?
                    <LocalContentComponent />
                    : null}

                <div className='trending-component'>
                    <TrendingContentComponent colors={colors}/>
                </div>
            </div>
            <SnackbarComponent
            showSnackbar={showChannelsSnackbar}
            handleClose={handleClose}
            severity="error"
            message="Server error occurred while trying to get content"
        />
     </>
    );
};


const mapStateToProps = (state: StoreState): {
    selectedChannel: Channel, selectedThread: SubChannel, selectedSubChannel: Thread,
    currentUser: User, userLocationPermission: boolean, localContentData: LocalContent[]
} => {
    return {
        selectedChannel: selectCurrentChannel(state),
        selectedSubChannel: selectCurrentSubChannel(state),
        selectedThread: selectCurrentThread(state),
        currentUser: selectCurrentUser(state),
        userLocationPermission: selectUserLocationPermission(state),
        localContentData: selectLocalContentData(state)
    }
}


const mapDispatchToProps = (dispatch: Dispatch<CallHistoryMethodAction | TChannelReducerActions | TPageReducerActions>) => {
    return {
        selectChannelAction: (channel: Channel) => dispatch<IAddChannel>({
            type: ChannelActionTypes.ADD_SELECTED_CHANNEL,
            data: channel
        }),
        selectSubChannelAction: (subChannel: SubChannel) => dispatch<IAddSubChannel>({
            type: ChannelActionTypes.ADD_SELECTED_SUBCHANNEL,
            data: subChannel
        }),
        selectThreadAction: (thread: Thread) => dispatch<IAddThread>({
            type: ChannelActionTypes.ADD_SELECTED_THREAD,
            data: thread
        }),
        redirectToSubChannel: (channel: string, subChannel: string) => dispatch(push(`/channels/${composePathSubChannles(channel, subChannel)}`)),
        redirectToThread: (channel: string, subChannel: string, thread: string) => dispatch(push(`/channels/${composePathThreads(channel, subChannel, thread)}`)),
        removeThreadAction: () => dispatch<IRemoveThread>({
            type: ChannelActionTypes.REMOVE_SELECTED_THREAD
        }),
        toggleWrongPathPageAction: () => dispatch<IToggleWrongPath>({ type: ErrorPagesTypes.ToggleWrongPathPage })
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ChannelsSidebar);