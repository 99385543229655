import gql from 'graphql-tag';

export const GET_PROFILE_PICTURE = gql`
    query getUserProfilePicture($userId: Int!) {
        getUserProfilePicture(userId: $userId)
    }
`

export const CHECK_USER = gql`
    query checkIfUserExistsQuery( $userId: Int!){
        checkIfUserExists(userId: $userId)
    }
`;