import { LicensingTypes } from "../../components/batch-upload/EditContentMetadata.types";
import { Channel } from "../../components/home-component/home.types";
import { DroppedFile, IBroadcastMessage } from "../../components/batch-upload/ContentUpload.types";

export enum OnboardingActionTypes {
    GENERAL_INFO_PERSON = 'GENERAL_INFO_PERSON',
    GENERAL_INFO_COMPANY = 'GENERAL_INFO_COMPANY',
    PERSON_RESPONSIBLE_FOR_ACCOUNT = 'PERSON_RESPONSIBLE_FOR_ACCOUNT',
    MAILING_ADDRESS = 'MAILING_ADDRESS',
    BILLING_ADDRESS = 'BILLING_ADDRESS',
    LICENSING = 'LICENSING',
    APPROPRIATE_CHANNELS = 'APPROPRIATE_CHANNELS',
    ABOUT_YOU = 'ABOUT_YOU',
    EDITORS_INFORMATION = 'EDITORS_INFORMATION',
    ADD_PROFILE_IMAGE = "ADD_PROFILE_IMAGE",
    REMOVE_PROFILE_IMAGE = "REMOVE_PROFILE_IMAGE",
    REMOVE_EDITOR = "REMOVE_EDITOR",
    RESET_ONBOARDING = 'RESET_ONBOARDING',
    TOGGLE_GENERAL_INFORMATION = 'TOGGLE_GENERAL_INFORMATION',
    TOGGLE_RESPONSIBLE_PERSON = 'TOGGLE_RESPONSIBLE_PERSON',
    TOGGLE_MAILING_ADDRESS = 'TOGGLE_MAILING_ADDRESS',
    TOGGLE_BILLING_ADDRESS = 'TOGGLE_BILLING_ADDRESS',
    TOGGLE_LICENSING = 'TOGGLE_LICENSING',
    TOGGLE_ABOUT_YOU = 'TOGGLE_ABOUT_YOU',
    TOGGLE_APPROPRIATE_CHANNELS = 'TOGGLE_APPROPRIATE_CHANNELS',
    TOGGLE_EDITORS_INFORMATION = 'TOGGLE_EDITORS_INFORMATION',
    SET_IS_MAILING_ADDRESS_CHECKED = 'SET_IS_MAILING_ADDRESS_CHECKED',
    VALIDATE_ONBOARDING_DATA = "VALIDATE_ONBOARDING_DATA",
    VALIDATE_EDITORS_ACCORDION = "VALIDATE_EDITORS_ACCORDION",
    BROADCAST_ONBOARDING_ERROR = "BROADCAST_ONBOARDING_ERROR",
    CLEAR_ONBOARDING_BROADCAST = "CLEAR_ONBOARDING_BROADCAST",
}
export interface OnboardingState {
    generalInformationPerson: GeneralInformationPerson;
    generalInformationCompany: GeneralInformationCompany;
    personResponsibleForAccount: PersonResponsibleForAccount;
    mailingAddress: Addresses;
    billingAddress: Addresses;
    licensing: Licensing;
    appropriateChannels: AppropriateChannels;
    aboutYou: AboutYou;
    editorsInformation: EditorsInformation[];
    profileImage: DroppedFile | null;
    accordionsState : AccordionsState;
    isUseMailingAddressChecked: boolean;
    isOnboardingValid: OnboardingValidation;
    isValidEditorAccordion: boolean;
    onboardingError: IBroadcastMessage;
}

export enum Gender {
    MALE = 'Male',
    FEMALE = 'Female',
    NO_ANSWER = 'Prefer not to answer'
}
export interface GeneralInformationPerson {
    firstName: string;
    lastName: string;
    vatNumber: string;
    yearOfBirth: number;
    gender?: Gender;
    emailNewsletter?: string;
    emailAccounting?: string;
}

export interface GeneralInformationCompany {
    organizationName: string;
    vatNumber: string;
    companyType?: string;
    contentType?: string;
    emailNewsletter?: string;
    emailAccounting?: string;
}

export interface PersonResponsibleForAccount {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    jobTitle?: string;
}

export interface Addresses {
    country: string;
    countryCode: string;
    state: string;
    stateCode: string;
    city: string;
    zipCode: string;
    addressLineOne: string;
    addressLineTwo: string;
}

export interface AddressSuggestion {
    country: string;
    state: string;
    city: string;
    zip: string;
    street: string;
}

export interface Licensing {
    licenseType: LicensingTypes;
    prefferedCurrency: string;
    agreeForMerchandise: boolean;
}

export interface AppropriateChannels {
    channels: Channel[];
}

export interface AboutYou {
    profileImage: DroppedFile | null;
    shortBiography: string;
}

export interface EditorsInformation {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    title: string;
}

export interface AccordionsState {
    toggleGeneralInformation : boolean;
    toggleResponsiblePerson: boolean;
    toggleMailingAddress: boolean;
    toggleBillingAddress: boolean;
    toggleLicensing: boolean;
    toggleAppropriateChannels: boolean;
    toggleAboutYou: boolean;
    toggleEditorsInformation: boolean;
}

export interface OnboardingValidation {
    status: boolean;
    validationMessage: string;
}
