import {connect} from 'react-redux';
import {FORGOT_PASSWORD} from "./queries";
import {graphql} from 'react-apollo';
import {ForgotPassword} from 'shared';
import { IToggleRegister, IToggleLogin, TModalReducerActions,IToggleRegisterAsRole } from '../../redux/modal-visibility/modal.actions';
import { Dispatch } from 'redux';
import { ModalActionTypes } from '../../redux/modal-visibility/modal.types';
import {TTranslationsReducerActions} from "../../redux/translations/translations.actions";
import {StoreState} from "../../redux/root-reducer";
import {TranslationsState} from "../../redux/translations/translations.types";

const mapStateToProps = (state: StoreState) : {translations: TranslationsState} => {
    return {
        translations: state.translations
    }
}

const mapDispatchToProps = (dispatch: Dispatch<TModalReducerActions | TTranslationsReducerActions>) => {
    return {
        toggleRegisterModalAction: () => dispatch<IToggleRegister>({type: ModalActionTypes.ToggleRegisterModal}),
        toggleLoginModalAction: () => dispatch<IToggleLogin>({type: ModalActionTypes.ToggleLoginModal}),
        toggleRegisterAsRoleModalAction:()=>dispatch<IToggleRegisterAsRole>({type:ModalActionTypes.ToggleRegisterAsRoleModal})
    }
};

export const graphqlComposition = (graphql(FORGOT_PASSWORD, {name: "forgotPasswordMutation"}))(ForgotPassword);

export default connect(mapStateToProps, mapDispatchToProps)(graphqlComposition);