import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Accordion, AccordionSummary, AccordionActions, Button } from "@material-ui/core";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { AboutYouProps, buttonStyles } from "./onboarding-page.types";
import { useTranslation } from "react-i18next";
import { Dispatch } from "redux";
import { IAboutYou, IRemoveDroppedProfileImage, TOnboardingReducerAction } from "../../redux/onboarding/onboarding.actions";
import { AboutYou, OnboardingActionTypes } from "../../redux/onboarding/onboarding.types";
import { connect } from "react-redux";
import { DroppedFile } from "../../components/batch-upload/ContentUpload.types";
import { StoreState } from "redux/root-reducer";
import DragAndDropOnboarding from './dragAndDropOnboarding.component'
import { selectAboutYouAccordionState, selectDroppedFile } from "../../redux/onboarding/onboarding.selectors";
import { selectCurrentUserId } from "../../redux/user/user.selectors";
import './onboarding-page.styles.scss';

const AboutYouComponent: React.FC<AboutYouProps> = ({ ...props }) => {
  const {profilePicture, saveAboutYouInformationAction, removeDroppedFileAction, toggleAboutYouAccordion} = props;
  const [open, setOpen] = useState(false);
  const [shortBiography, setShortBiography] = useState("");
  const classes = buttonStyles();

  useEffect(() => {
    if(toggleAboutYouAccordion) {
      setOpen(true);
    }
  }, [toggleAboutYouAccordion])

  const handleClick = () => {
    saveAboutYouInformationAction({
      profileImage: profilePicture,
      shortBiography: shortBiography,
    });

    setOpen(!open);
  };
  const handleContentChange = (content: string) => {
    setShortBiography(content);
  };

  const { t } = useTranslation();

  return (
    <Accordion expanded={open}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1c-content"
        id="panel1c-header"
        onClick={handleClick}
      >
        <div className="title">{t("Onboarding.StepOne.About.you")}</div>
      </AccordionSummary>
      <div className="about-you-container">
        <div className="profile-pic-container">
          <h4>{t("Onboarding.StepOne.ProfilePicture.head")}</h4>
          <DragAndDropOnboarding />
        </div>
        <div className="text-field-container">
          <h4>{t("Onboarding.StepOne.Biography.head")}</h4>
          <SunEditor
            onChange={handleContentChange}
            lang="en"
            setOptions={{
              customPlugins: [],
              buttonList: [["bold", "underline", "italic", "strike", "list"]],
              maxHeight: "100%",
              minHeight: "120px",
              maxCharCount: 1000,
            }}
            
          />
        </div>
      </div>
      <AccordionActions style={{justifyContent: 'center'}}>
        <Button onClick={handleClick} classes={{root: classes.greenButtonRoot}}>
          {t("Onboarding.StepOne.Save.button")}
        </Button>
      </AccordionActions>
    </Accordion>
  );
};

const mapStateToProps = (state: StoreState): {profilePicture: DroppedFile | null, currentUserId: number, toggleAboutYouAccordion: boolean} => {
  return {
      profilePicture: selectDroppedFile(state),
      currentUserId: selectCurrentUserId(state),
      toggleAboutYouAccordion: selectAboutYouAccordionState(state),
  }
}

const mapDispatchToProps = (dispatch: Dispatch<TOnboardingReducerAction>) => {
  return {
    saveAboutYouInformationAction: (data: AboutYou) => dispatch<IAboutYou>({ type: OnboardingActionTypes.ABOUT_YOU, data: data }),
    removeDroppedFileAction: () => dispatch<IRemoveDroppedProfileImage>({ type: OnboardingActionTypes.REMOVE_PROFILE_IMAGE })
  }
};

export default connect(mapStateToProps, mapDispatchToProps) (AboutYouComponent);
