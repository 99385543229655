import { createSelector } from "reselect";
import { StoreState } from "redux/root-reducer";

const selectCart = (state: StoreState) => state.cart;

export const selectCurrentCartItems = createSelector(
  [selectCart],
  cart => cart.itemsInCart
);

export const selectCurrentCartSubtotal = createSelector(
  [selectCart],
  cart => cart.subtotal
);

export const selectCurrentCartTotal = createSelector(
  [selectCart],
  cart => cart.total
);

export const selectCurrentSubItems = createSelector(
  [selectCart],
  cart => cart.itemsFromSub
);

export const selectTax = createSelector(
  [selectCart],
  cart => cart.taxForOrder
);

export const selectCartError = createSelector(
  [selectCart],
  cart => cart.cartError
);

export const selectIfOrderHasTax = createSelector(
  [selectCart],
  cart => cart.taxForOrder ? true : false
);

export const selectBillingAddress = createSelector(
  [selectCart],
  cart => cart.additionalBillingAddress
);

export const selectCartItemsCount = createSelector(
  [selectCart],
  cart => cart.itemsFromSub.length + cart.itemsInCart.length
);
