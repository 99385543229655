import { Chip, makeStyles } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { channelChipProps } from './channelChip.types';
import './channelScreen.styles.scss';
  
const ChannelChipComponent: React.FC<channelChipProps> = ({...props}) => {
    const { handleClick, handleDelete, colors, includeDeleteButton, label, isFirstChip} = props;

    const chipStyles = makeStyles(() => ({
      chipRoot:{
        color:'white !important',
        fontFamily: 'sans-serif',
        margin: '0 5px ',
        marginRight:'5px ',
        borderRadius: '20px !important',
      },
      chipDeleteIcon:{
        color:'white'
      }
    }))

   const classes = chipStyles();

  const renderChipChannel=(includeDeleteButton : boolean, label: string, isFirstChip: boolean) => {
    return <Chip
        label={label}
        onClick={handleClick}
        onDelete={includeDeleteButton ? handleDelete : undefined}
        size = {'medium'}
        classes = {{root: classes.chipRoot, deleteIcon: classes.chipDeleteIcon}}
        style={{ 
          backgroundColor: colors[0],
          fontSize: '1.3rem',
          marginTop:'30px',
          marginLeft: isFirstChip ? '25px' : '0px'
        }} 
      />
  }


  return (
      <Fragment>
        {renderChipChannel(includeDeleteButton, label ,isFirstChip)}
      </Fragment>
  );
};



export default ChannelChipComponent;