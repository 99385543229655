import React, { useState, useEffect } from 'react';
import { ChannelTypes, SearchBarChannelsNavigationProps, SearchFieldTypes } from './searchBar.types';
import { Channel } from '../home-component/home.types';
import { Paper, Grow, Button, Grid, IconButton, TextField, Typography, Autocomplete } from '@mui/material';
import './searchBar.styles.scss';
import { SubChannel, Thread } from '../channelsSidebar/channelSideBars.types';
import { StoreState } from '../../redux/root-reducer';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import { Dispatch } from 'redux';
import { IResetFilters, IToggleFilterChannel, IToggleFilterSubchannel, IToggleFilterThread, TSearchReducerActions } from '../../redux/search-bar/search-bar.actions';
import { SearchBarActions } from '../../redux/search-bar/search-bar.types';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { GET_CHANNELS } from '../channels/queries';
import SyncLoader from 'react-spinners/SyncLoader';
import { GET_SUBCHANNELS, GET_THREADS } from '../channelsSidebar/queries';
import { selectChannelFilter, selectSubchannelFilter, selectThreadFilter } from '../../redux/search-bar/search-bar.selectors';
import { selectCurrentChannel, selectCurrentSubChannel, selectCurrentThread } from '../../redux/channel-routes/channel.selectors';

const SearchBarChannelsNavigation: React.FC<SearchBarChannelsNavigationProps> = ({...props}) => {
    const {channelFilter, subchannelFilter, threadFilter, selectedChannel, selectedSubchannel, selectedThread, path,
        toggleChannelFilterAction, toggleSubchannelFilterAction, toggleThreadFilterAction} = props;

    const [selectedNavigationField, setSelectedNavigationField] = useState("");
    const { t } = useTranslation();
    //CHANNELS SEARCH QUERIES
    const [channelSearchString, setChannelSearchString] = useState("");
    const [subchannelSearchString, setSubchannelSearchString] = useState("");
    const [threadSearchString, setThreadSearchString] = useState("");
    
    // RESULTS
    const [channels, setChannels] = useState<Channel[]>([]);
    const [subchannels, setSubchannels] = useState<SubChannel[]>([]);
    const [threads, setThreads] = useState<Thread[]>([]);

    //QUERIES
    const { data: dataChannel, loading: channelsLoading, error: error1 } = useQuery(GET_CHANNELS, {fetchPolicy: "no-cache"});
    const [fetchSubchannels, {data: dataSub, loading: subLoading}] = useLazyQuery(GET_SUBCHANNELS, {fetchPolicy: "no-cache"});
    const [fetchThreads, {data: dataThread, loading: threadLoading}] = useLazyQuery(GET_THREADS, {fetchPolicy: "no-cache"});

    useEffect(() => {
        if (dataChannel && dataChannel.getAllChannels) {
            setChannels(dataChannel.getAllChannels);

            //check here if other channels are selected and load the channels for them as well
            if(channelFilter.id !== 0) {
                fetchSubchannels({variables: {id: selectedChannel.id}})
            }

            if(subchannelFilter.id !== 0) {
                fetchThreads({ variables: {id: selectedSubchannel.id}});
            }
        }
    }, [dataChannel]);

    useEffect(() => {
        if (dataSub && dataSub.getAllSubChannelsByChannelId) {
            setSubchannels(dataSub.getAllSubChannelsByChannelId);
        }
    }, [dataSub]);

    useEffect(() => {
        if (dataThread && dataThread.getAllThreadsBySubChannelId) {
            setThreads(dataThread.getAllThreadsBySubChannelId);
        }
    }, [dataThread]);

    useEffect(() => {
        if(isInChannelContext()) {
            if (selectedChannel.id !== -1) {
                toggleChannelFilterAction(selectedChannel);
                fetchSubchannels({variables: {id: selectedChannel.id}})
            }
            if (selectedSubchannel.id !== -1) {
                toggleSubchannelFilterAction(selectedSubchannel);
                fetchThreads({ variables: {id: selectedSubchannel.id}});
            }
            if (selectedThread.id !== -1) {
                toggleThreadFilterAction(selectedThread)
            }
        }
    }, [selectedChannel, selectedSubchannel, selectedThread]);

    const handleChange = (event: any) => {
        event.preventDefault();
        let name = event.target.name;
        let value = event.target.value;

        if (name === SearchFieldTypes.CHANNEL_FILTER) {
            setChannelSearchString(value);
        } else if (name === SearchFieldTypes.SUBCHANNEL_FILTER) {
            setSubchannelSearchString(value);
        } else if (name === SearchFieldTypes.THREAD_FILTER) {
            setThreadSearchString(value);
        }
    }

    const handleOnClick = (channel: any) => {
        if (selectedNavigationField === ChannelTypes.CHANNEL) {
            fetchSubchannels({variables: {id: channel.id}})
            setSelectedNavigationField(ChannelTypes.SUBCHANNEL);
        } else if (selectedNavigationField === ChannelTypes.SUBCHANNEL) {
            fetchThreads({ variables: {id: channel.id}});
            setSelectedNavigationField(ChannelTypes.THREAD);
        }
    }

    const handleFocusField = (event: any, name: string) => {
        event.preventDefault();

        if (name === SearchFieldTypes.CHANNEL_FILTER) {
            setSelectedNavigationField(ChannelTypes.CHANNEL);
        } else if (name === SearchFieldTypes.SUBCHANNEL_FILTER) {
            setSelectedNavigationField(ChannelTypes.SUBCHANNEL);
        } else if (name === SearchFieldTypes.THREAD_FILTER) {
            setSelectedNavigationField(ChannelTypes.THREAD);
        }
    }

    const handleResetChannel = (event: any, type: string) => {
        event.preventDefault();

        if (type === ChannelTypes.CHANNEL) {
            toggleChannelFilterAction({ id: 0, title: "", code: "" });
            toggleSubchannelFilterAction({ id: 0, title: "" });
            toggleThreadFilterAction({ id: 0, title: "" });
            setSubchannels([]);
            setThreads([]);
        } else if (type === ChannelTypes.SUBCHANNEL) {
            toggleSubchannelFilterAction({ id: 0, title: "" });
            toggleThreadFilterAction({ id: 0, title: "" });
            setThreads([]);
        } else if (type === ChannelTypes.THREAD) {
            toggleThreadFilterAction({ id: 0, title: "" });
        }
        resetChannelSearchStrings();
    }

    const resetChannelSearchStrings = () => {
        setChannelSearchString("");
        setSubchannelSearchString("");
        setThreadSearchString("");
    }

    const selectNavigation = (type: string, channel: any) => {

        if (type === ChannelTypes.CHANNEL) {
            toggleChannelFilterAction(channel);
            setSubchannels([]);
            setThreads([]);
            toggleSubchannelFilterAction({ id: 0, title: "" });
            toggleThreadFilterAction({ id: 0, title: "" });
        } else if (type === ChannelTypes.SUBCHANNEL) {
            toggleSubchannelFilterAction(channel);
            setThreads([]);
            toggleThreadFilterAction({ id: 0, title: "" });
        } else if (type === ChannelTypes.THREAD) {
            toggleThreadFilterAction(channel);
        }
    }

    const isInChannelContext = () => {
        return path.indexOf("/channels") > -1;
    }

    const isLoading = () => {
        return channelsLoading || subLoading || threadLoading;
    }

    return (
        <div className="advanced-search-channels-column">
            <div className='advanced-search-second-column'>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={0.5} sx={{width: '100%'}}>
                    <Grid item xs={4}>
                        <Typography color="textPrimary" align="left" variant="body2">{t("Channel.Field")}</Typography>
                    </Grid>
                    <Grid item xs={7}>
                        <Autocomplete
                            id="autocomplete-search-channels"
                            value={isInChannelContext() ? selectedChannel : channelFilter}
                            options={channels}
                            disabled={isInChannelContext()}
                            autoHighlight
                            getOptionLabel={(option: Channel) => option ? option.title : ""}
                            renderInput={(params: any) => (
                                <TextField
                                    {...params}
                                    id="channel-input" variant='standard' placeholder={t("Channel.Placeholder")}
                                    value={channelSearchString} name={SearchFieldTypes.CHANNEL_FILTER} className='text-field'
                                    onFocus={(event: any) => handleFocusField(event, SearchFieldTypes.CHANNEL_FILTER)} 
                                    onChange={handleChange}
                                    InputProps={{
                                        endAdornment: null
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton style={{ fill: 'black', cursor: 'pointer' }} onClick={(event) => handleResetChannel(event, ChannelTypes.CHANNEL)} >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={0.5} sx={{width: '100%'}}>
                    <Grid item xs={4}>
                        <Typography color="textPrimary" align="left" variant="body2">{t("Subchannel.Field")}</Typography>
                    </Grid>
                    <Grid item xs={7}>
                        <Autocomplete
                            id="autocomplete-search-subchannels"
                            value={subchannelFilter}
                            options={subchannels}
                            autoHighlight
                            getOptionLabel={(option: SubChannel) => option ? option.title : ""}
                            renderInput={(params: any) => (
                                <TextField
                                    {...params}
                                    id="subchannel-input" variant='standard' placeholder={t("Subchannel.Placeholder")}
                                    value={subchannelSearchString} name={SearchFieldTypes.SUBCHANNEL_FILTER} className='text-field'
                                    onFocus={(event: any) => handleFocusField(event, SearchFieldTypes.SUBCHANNEL_FILTER)}
                                    onChange={handleChange}
                                    InputProps={{
                                        endAdornment: null
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton style={{ fill: 'black', cursor: 'pointer' }} onClick={(event) => handleResetChannel(event, ChannelTypes.SUBCHANNEL)} >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={0.5} sx={{width: '100%'}}>
                    <Grid item xs={4}>
                        <Typography color="textPrimary" align="left" variant="body2">{t("Thread.Field")}</Typography>
                    </Grid>
                    <Grid item xs={7}>
                        <Autocomplete
                            id="autocomplete-search-threads"
                            value={threadFilter}
                            options={threads}
                            autoHighlight
                            getOptionLabel={(option: Thread) => option ? option.title : ""}
                            renderInput={(params: any) => (
                                <TextField
                                    {...params}
                                    id="thread-input" variant='standard' placeholder={t("Thread.Placeholder")}
                                    value={threadSearchString} name={SearchFieldTypes.THREAD_FILTER} className='text-field'
                                    onFocus={(event: any) => handleFocusField(event, SearchFieldTypes.THREAD_FILTER)} 
                                    onChange={handleChange}
                                    InputProps={{
                                        endAdornment: null
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton style={{ fill: 'black', cursor: 'pointer' }} onClick={(event) => handleResetChannel(event, ChannelTypes.THREAD)} >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </div>
            <div className='advanced-search-third-column'>
                <SyncLoader css={`z-index: 999; position: absolute; top: 50%; left: 50%;
                    transform: translate(-50%, -50%);`} size={10} 
                    color={"#36D2B3"} loading={isLoading()}/>

                <Grow in={!isLoading()}>
                    <Paper elevation={0}>
                        {selectedNavigationField === ChannelTypes.CHANNEL ? 
                            channels.filter((channel: Channel) => channel.title.toLowerCase().includes(channelSearchString.toLowerCase())).map((channel: Channel) => (
                                <Button variant='contained' size='large' onClick={() => {
                                    selectNavigation(ChannelTypes.CHANNEL, channel);
                                    handleOnClick(channel);
                                }} 
                                    className={`channel-button-search-bar channel-button ${channel.code}`} 
                                    key={channel.id}>{channel.title.length > 15 ? channel.title.substring(0, 15).concat('...') : channel.title}</Button>
                                    ))
                        : null}

                        {selectedNavigationField === ChannelTypes.SUBCHANNEL ? 
                            subchannels.filter((subchannel: SubChannel) => subchannel.title.toLowerCase().includes(subchannelSearchString.toLowerCase())).map((channel: SubChannel) => (
                                <Button variant='contained' size='large' onClick={() => {
                                    selectNavigation(ChannelTypes.SUBCHANNEL, channel);
                                    handleOnClick(channel);
                                }} 
                                    className={`channel-button-search-bar channel-button ${channelFilter.code.concat('-search')}`} 
                                    key={channel.id}>{channel.title.length > 15 ? channel.title.substring(0, 15).concat('...') : channel.title}</Button>
                                ))
                        : null}

                        {selectedNavigationField === ChannelTypes.THREAD ? 
                            threads.filter((thread: Thread) => thread.title.toLowerCase().includes(threadSearchString.toLowerCase())).map((channel: Thread) => (
                                <Button variant='contained' size='large' onClick={() => {
                                    selectNavigation(ChannelTypes.THREAD, channel);
                                    handleOnClick(channel);
                                }} 
                                    className={`channel-button-search-bar channel-button ${channelFilter.code.concat('-search')}`} 
                                    key={channel.id}>{channel.title.length > 15 ? channel.title.substring(0, 15).concat('...') : channel.title}</Button>
                                ))
                        : null}
                    </Paper>
                </Grow>
            </div>
        </div>
    )
}

const mapStateToProps = (state: StoreState): { channelFilter: Channel, subchannelFilter: SubChannel, 
        threadFilter: Thread, selectedChannel: Channel, selectedSubchannel: SubChannel, selectedThread: Thread,
        path: string } => {
    return {
        channelFilter: selectChannelFilter(state),
        subchannelFilter: selectSubchannelFilter(state),
        threadFilter: selectThreadFilter(state),
        selectedChannel: selectCurrentChannel(state),
        selectedSubchannel: selectCurrentSubChannel(state),
        selectedThread: selectCurrentThread(state),
        path: state.router.location.pathname
    }
}

const mapDispatchToProps = (dispatch: Dispatch<TSearchReducerActions>) => {
    return {
        resetFiltersAction: () => dispatch<IResetFilters>({
            type: SearchBarActions.RESET_FILTERS
        }),
        toggleChannelFilterAction: (data: Channel) => dispatch<IToggleFilterChannel>({
            type: SearchBarActions.TOGGLE_FILTER_CHANNEL,
            data: data
        }),
        toggleSubchannelFilterAction: (data: SubChannel) => dispatch<IToggleFilterSubchannel>({
            type: SearchBarActions.TOGGLE_FILTER_SUBCHANNEL,
            data: data
        }),
        toggleThreadFilterAction: (data: Thread) => dispatch<IToggleFilterThread>({
            type: SearchBarActions.TOGGLE_FILTER_THREAD,
            data: data
        })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchBarChannelsNavigation);