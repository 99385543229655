import { createSelector } from 'reselect';
import { StoreState } from 'redux/root-reducer';

const selectContentUpload = (state: StoreState) => state.contentUpload;

export const selectUploadedFiles = createSelector(
    [selectContentUpload],
    (contentUpload) => contentUpload.uploadedFiles
);


export const chunkUploadedFiles = createSelector(
    [selectContentUpload],
    (contentUpload) => contentUpload.chunkedFile
);

export const selectDroppedFiles = createSelector(
    [selectContentUpload],
    (contentUpload) => contentUpload.droppedFiles
);

export const selectContentSets = createSelector(
    [selectContentUpload],
    (contentUpload) => contentUpload.contentSets
);

export const selectRadioButtonValue = createSelector(
    [selectContentUpload],
    (contentUpload) => contentUpload.selectedRadioButton
);

export const selectArticles = createSelector(
    [selectContentUpload],
    (contentUpload) => contentUpload.articles
);

export const selectAudioFiles = createSelector(
    [selectContentUpload],
    (contentUpload) => contentUpload.audioFiles
);

export const selectIndividualImagesCategory = createSelector(
    [selectContentUpload],
    (contentUpload) => contentUpload.individualImagesCategory
);

export const selectUploadError = createSelector(
    [selectContentUpload],
    (contentUpload) => contentUpload.uploadError
)

export const selectChangesToBeSaved = createSelector(
    [selectContentUpload],
    (contentUpload) => contentUpload.changesToBeSaved
)

export const selectImageUploadStatus = createSelector(
    [selectContentUpload],
    (contentUpload) => contentUpload.imageUploadStatus
)