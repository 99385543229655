import { createSelector } from "reselect";
import { StoreState } from "redux/root-reducer";

const subscriptionPurchase = (state: StoreState) => state.subscriptionToBuy;

export const selectCurrentSubscriptionToPurhcase = createSelector(
  [subscriptionPurchase],
  subscriptionPurchase => subscriptionPurchase.subscriptionPlan
);

export const selectCurrentSubscriptionSubtotal = createSelector(
  [subscriptionPurchase],
  subscriptionPurchase => subscriptionPurchase.subTotal
);

export const selectCurrentSubscriptionTotal = createSelector(
  [subscriptionPurchase],
  subscriptionPurchase => subscriptionPurchase.total
);

export const selectTaxSub = createSelector(
  [subscriptionPurchase],
  subscriptionPurchase => subscriptionPurchase.taxForOrder
);

export const selectLicenseCount = createSelector(
  [subscriptionPurchase],
  subscriptionPurchase => subscriptionPurchase.subscriptionPlan
);

export const selectSubscriptionBroadcast = createSelector(
  [subscriptionPurchase],
  subscriptionPurchase => subscriptionPurchase.subscriptionBroadcast
);