import {ContentUploadActionTypes, ContentUploadState } from './content-upload.types';
import { TContentUploadActions } from './content-upload.actions';
import { addOrUpdateArticle, addImageCategory, updateSet } from './content-upload.functions';
import { DroppedFile, RadioButtonGroup, UploadedFileResponse, SeveritySnackbarEnum, IBroadcastMessage, ContentSet, ImageUploadStatus } from '../../components/batch-upload/ContentUpload.types';

const InitialState: ContentUploadState = {
    chunkedFile: {},
    uploadedFiles: [],
    contentSets: [],
    articles: [],
    audioFiles: [],
    droppedFiles: [],
    selectedRadioButton: RadioButtonGroup.SET,
    individualImagesCategory: [],
    uploadError: {} as IBroadcastMessage,
    changesToBeSaved: false,
    imageUploadStatus: {}
};

export const contentUploadReducer = (state = InitialState, action: TContentUploadActions) => {
    switch(action.type) {
        case ContentUploadActionTypes.TOGGLE_RADIO_BUTTON: 
            return {
                ...state,
                selectedRadioButton: action.data
            }
        case ContentUploadActionTypes.ADD_FILE:
            return {
                ...state,
                droppedFiles: state.droppedFiles.concat(action.data)
            }
        case ContentUploadActionTypes.REMOVE_FILE:
            return {
                ...state,
                droppedFiles: state.droppedFiles.filter((file: DroppedFile) => parseInt(file.fileWithMeta.meta.id, 10) !== action.data)
            }
        case ContentUploadActionTypes.CHUNKED_FILE:
            return {
                ...state,
                chunkedFile: action.data
            }
        case ContentUploadActionTypes.UPLOAD_FILES:
            return {
                ...state,
                uploadedFiles: state.uploadedFiles.concat(action.data)
            }
        case ContentUploadActionTypes.DELETE_FILES: 
            return {
                ...state,
                uploadedFiles: state.uploadedFiles.filter((file: UploadedFileResponse) => !action.data.includes(file.id))
            }
        case ContentUploadActionTypes.UPLOAD_ARTICLE:
            return {
                ...state,
                articles: state.articles.length === 0 ? state.articles.concat(action.data) 
                    : addOrUpdateArticle(state.articles, action.data)
            }
        case ContentUploadActionTypes.UPDATE_ARTICLE:
            return {
                ...state,
                articles: addOrUpdateArticle(state.articles, action.data)
            }
        case ContentUploadActionTypes.DELETE_ARTICLE:
            return {
                ...state,
                audioFiles: state.audioFiles.filter(audioFile => audioFile.articleId !== action.data),
                articles: state.articles.filter(article => article.contentSetId !== action.data)
            }
        case ContentUploadActionTypes.UPLOAD_SET:
            return {
                ...state,
                contentSets: state.contentSets.concat(action.data),
                uploadedFiles: state.uploadedFiles.filter((file: UploadedFileResponse) => !action.data.files.includes(file))
            }
        case ContentUploadActionTypes.UPDATE_SET:
            return {
                ...state,
                contentSets: updateSet(action.data, state.contentSets)
            }
        case ContentUploadActionTypes.DELETE_SET:
            return {
                ...state,
                contentSets: state.contentSets.filter((set: ContentSet) => set.id !== action.data)
            }
        case ContentUploadActionTypes.ADD_CHANNEL_ID:
            return {
                ...state,
                contentSets: state.contentSets.map(contentSet => contentSet.id === action.data.selectedContentSetId ? 
                    {...contentSet, selectedThreadIds: action.data.selectedThreads, suggestedThreadTitle: action.data.suggestedThreadTitle, subchannel: action.data.subchannel} : contentSet)
            }
        case ContentUploadActionTypes.ADD_AUDIO_FILE:
            return {
                ...state,
                audioFiles: state.audioFiles.concat(action.data)
            }
        case ContentUploadActionTypes.DELETE_AUDIO_FILE:
            return {
                ...state,
                audioFiles: state.audioFiles.filter(audioFile => audioFile.articleId !== action.data),
            }
        case ContentUploadActionTypes.ADD_CHANNEL_TO_INDIVIDUAL:
            return {
                ...state,
                individualImagesCategory: addImageCategory(state.individualImagesCategory, action.data)
            }
        case ContentUploadActionTypes.BROADCAST_MESSAGE:
            return {
                ...state,
                uploadError: action.data
            }
        case ContentUploadActionTypes.TOGGLE_SAVED_CHANGES:
            return {
                ...state,
                changesToBeSaved: action.data
            }
        case ContentUploadActionTypes.TOGGLE_IMAGE_UPLOAD_STATUS:
            {
                const newStatus = {...state.imageUploadStatus};
                if(action.data.id){
                    newStatus[action.data.id] = action.data.isUploading;
                }
                return {
                    ...state,
                    imageUploadStatus: {...newStatus}
                }
            }
        case ContentUploadActionTypes.RESET_CONTENT: 
            return {
                chunkedFile: {},
                imageUploadStatus: {},
                uploadedFiles: [],
                contentSets: [],
                articles: [],
                audioFiles: [],
                droppedFiles: [],
                selectedRadioButton: RadioButtonGroup.SET,
                individualImagesCategory: [],
                uploadError: {} as IBroadcastMessage,
                changesToBeSaved: false
            }
        default: 
            return state;
    }
}