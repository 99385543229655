import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { LicensingOnbordingProps, buttonStyles } from "./onboarding-page.types";
import {
  Accordion,
  AccordionSummary,
  AccordionActions,
  Button,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { RoleTypes } from "../register/register.types";
import { GET_LICENSE_TYPES, GET_CURRENCIES } from "./queries";
import { useLazyQuery } from "@apollo/react-hooks";
import { OnboardingLicensingAllYup } from "./yup-validation-objects";
import { Dispatch } from "redux";
import { ILicensing, IToggleAppropriateChannelsAccordion, TOnboardingReducerAction } from "../../redux/onboarding/onboarding.actions";
import { Licensing, OnboardingActionTypes } from "../../redux/onboarding/onboarding.types";
import { connect } from "react-redux";
import { StoreState } from "../../redux/root-reducer";
import { selectLicensingAccordionState, selectLicensingInformation } from "../../redux/onboarding/onboarding.selectors";
import { LicensingTypes } from "../batch-upload/EditContentMetadata.types";
import { capitalizeFirstLetter } from "../photo-details/photo-details-helper-functions";

const LicensingOnbording: React.FC<LicensingOnbordingProps> = ({ ...props }) => {
  const { userRole, saveLicensingInfromationAction, licensing, toggleLicensingAccordion, toggleAppropriateChannelsAccordionAction} = props;
  const [open, setOpen] = useState(false);
  
  const { t } = useTranslation();
  const [dataQuery, setDataQuery] = useState(GET_LICENSE_TYPES);
  const [fetchData, { data, loading, error }] = useLazyQuery(dataQuery, {
    fetchPolicy: "no-cache"
  });
  const [licenseTypes, setLicenseTypes] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const classes = buttonStyles();

  useEffect(() => {
    if (dataQuery === GET_LICENSE_TYPES) {
      if (data && data.getLicenseTypes) {
        setLicenseTypes(data.getLicenseTypes);
        setDataQuery(GET_CURRENCIES);
      }
    } else if (dataQuery === GET_CURRENCIES) {
      if (data && data.getCurrencies) {
        setCurrencies(data.getCurrencies);
      }
    }
  }, [data]);

  useEffect(() => {
    if (toggleLicensingAccordion) {
      setOpen(true);
    }
  }, [toggleLicensingAccordion]);

  useEffect(() => {
    if(open) {
      fetchData();
    }
  }, [open])

  const getValidationSchema = () => {
    if (userRole === RoleTypes.Pro_organization) {
      return OnboardingLicensingAllYup.OnboardingLicensingProORGYup
    } else {
      return OnboardingLicensingAllYup.OnboardingLicensingDefaultYup
    }
  };
  const handleClick = () => {
    setOpen(!open);
  };

  const validationSchema = getValidationSchema();

  const { handleSubmit, handleChange, values, errors } = useFormik({
    initialValues: {
      licenseType: LicensingTypes.STANDARD,
      prefferedCurrency: '',
      merchandiseAgreement: false
    },
    validateOnBlur: true,
    validationSchema,
    onSubmit(values) {
      const { licenseType, prefferedCurrency, merchandiseAgreement } = values;
      saveLicensingInfromationAction({
        licenseType: values.licenseType,
        prefferedCurrency: values.prefferedCurrency,
        agreeForMerchandise: values.merchandiseAgreement
      });
      setOpen(false);
      toggleAppropriateChannelsAccordionAction();
    }
  });

  return (
    <form onSubmit={handleSubmit}>
      <Accordion expanded={open}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
          onClick={handleClick}
        >
          <div className="title">{t("Onboarding.Licensing.Heading")}</div>
        </AccordionSummary>
        <div className="accordion-container">
          <span>
            <Select variant='standard' name="licenseType" onClick={handleChange} placeholder={t("Onboarding.LicensingType.Label")} className='dropdown-component' defaultValue={licensing.licenseType ? licensing.licenseType : t("Onboarding.LicensingType.Label")}>
              <MenuItem className='gray-color' key='Licensing type' disabled>{t("Onboarding.LicensingType.Label")}</MenuItem>
              {
                licenseTypes.map((licenseType: string) => {
                  return <MenuItem value={licenseType} key={licenseType}>{capitalizeFirstLetter(licenseType)}</MenuItem>
                })
              }
            </Select>
            <div className="helper-text-select">
              {errors.licenseType ? errors.licenseType : null}
            </div>
          </span>
          <span>
          <Select variant='standard' name="prefferedCurrency" onClick={handleChange} placeholder={t("Onboarding.PreferredCurrency.Label")} className='dropdown-component' defaultValue={licensing.prefferedCurrency ? licensing.prefferedCurrency : t("Onboarding.PreferredCurrency.Label")}>
              <MenuItem className='gray-color' value={t("Onboarding.PreferredCurrency.Label")} key='Prefered currency' disabled>{t("Onboarding.PreferredCurrency.Label")}</MenuItem>
              {
                currencies.map((currency: string) => {
                  return <MenuItem value={currency} key={currency}>{currency.toUpperCase()}</MenuItem>
                })
              }
            </Select>
            <div className="helper-text-select">
              {errors.prefferedCurrency ? errors.prefferedCurrency : null}
            </div>
          </span>
          <span>
            <FormControlLabel
              id="agreement-check"
              labelPlacement="end"
              label={t("Onboarding.Agreement")}
              control={
                <Checkbox
                  color="default"
                  onChange={handleChange}
                  defaultChecked={licensing.agreeForMerchandise === true ? true : false}
                  name="merchandiseAgreement"
                />
              }
            />
            <div className="helper-text-select">
              {errors.merchandiseAgreement ? errors.merchandiseAgreement : null}
            </div>
          </span>
        </div>
        <AccordionActions style={{justifyContent: 'center'}}>
          <Button type="submit" classes={{root: classes.greenButtonRoot}}>
            {t("Onboarding.StepOne.Save.button")}
          </Button>
        </AccordionActions>
      </Accordion>
    </form>
  );
};

const mapStateToProps = (state: StoreState): {licensing: Licensing, toggleLicensingAccordion: boolean} => {
  return {
    licensing: selectLicensingInformation(state),
    toggleLicensingAccordion: selectLicensingAccordionState(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch<TOnboardingReducerAction>) => {
  return {
    saveLicensingInfromationAction: (data: Licensing) => dispatch<ILicensing>({ type: OnboardingActionTypes.LICENSING, data: data }),
    toggleAppropriateChannelsAccordionAction: () => dispatch<IToggleAppropriateChannelsAccordion>({ type: OnboardingActionTypes.TOGGLE_APPROPRIATE_CHANNELS})
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(LicensingOnbording)