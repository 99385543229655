import { SubChannel, Thread } from "components/channelsSidebar/channelSideBars.types";
import { Channel } from "../../components/home-component/home.types";

export enum ChannelActionTypes {
    ADD_SELECTED_CHANNEL = 'ADD_SELECTED_CHANNEL',
    REMOVE_SELECTED_CHANNEL = 'REMOVE_SELECTED_CHANNEL',
    ADD_SELECTED_SUBCHANNEL = 'ADD_SELECTED_SUBCHANNEL',
    REMOVE_SELECTED_SUBCHANNEL = 'REMOVE_SELECTED_SUBCHANNEL',
    ADD_SELECTED_THREAD = 'ADD_SELECTED_THREAD',
    REMOVE_SELECTED_THREAD = 'REMOVE_SELECTED_THREAD',
    RESET_CHANNELS = "RESET_CHANNELS",
    TOGGLE_GALLERY_VIEW = "TOGGLE_GALLERY_VIEW",
}

export interface ChannelState {
    selectedChannel: Channel;
    selectedSubChannel: SubChannel;
    selectedThread: Thread;
    galleryViewEnabled: boolean;
}