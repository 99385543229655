import { ChannelActionTypes, ChannelState } from './channel.types';
import { TChannelReducerActions } from './channel.actions';

const InitialState: ChannelState = {
  selectedChannel: {id: -1, title: '', code: ''},
  selectedSubChannel: {id: -1, title: ''},
  selectedThread: {id: -1, title: ''},
  galleryViewEnabled: false
};

export const channelReducer = (state = InitialState, action: TChannelReducerActions): ChannelState => {
    switch(action.type) {
        case ChannelActionTypes.ADD_SELECTED_CHANNEL:
            return { 
                ...state,
                selectedChannel: {id:action.data.id, title: action.data.title, code: action.data.code}
            };
        case ChannelActionTypes.REMOVE_SELECTED_CHANNEL:
            return {
                ...state,
                selectedChannel : {id: -1, title: '', code: ''}
            };
        case ChannelActionTypes.ADD_SELECTED_SUBCHANNEL:
            return { 
                ...state,
                selectedSubChannel : {id: action.data.id, title: action.data.title}
            };
        case ChannelActionTypes.REMOVE_SELECTED_SUBCHANNEL:
            return {
                ...state,
                selectedSubChannel : {id: -1, title: ''}
            };
        case ChannelActionTypes.ADD_SELECTED_THREAD:
            return { 
                ...state,
                selectedThread : {id: action.data.id, title: action.data.title}
            };
        case ChannelActionTypes.REMOVE_SELECTED_THREAD:
            return {
                ...state,
                selectedThread: {id: -1, title: ''}
            };
        case ChannelActionTypes.TOGGLE_GALLERY_VIEW:
            return {
                ...state,
                galleryViewEnabled: action.data
            };
        case ChannelActionTypes.RESET_CHANNELS: 
            return InitialState;
        default:
            return state;
    }
}

export default channelReducer;