import {  makeStyles } from "@material-ui/core/styles";

  export const wrapperStyles = makeStyles(()=>({
      dialogRoot: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '1000px',
        height: 'auto',
        margin: '0 auto',
      },
      dialogPaper:{
        borderRadius: '25px !important',
      },
      dialogPaperWidthSm:{
        maxWidth:'none'
      }
  }))
  export interface RegisterModalWraperProps { 
    banner: boolean;
    toggleBanner: (show:boolean) => void;
    toggleRegister:() => void;
    setRegisterType: (registerType:string) => void;
    toggleRegisterAsRole:()=>void;
}