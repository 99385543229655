import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Channel } from "../home-component/home.types";
import { Accordion, AccordionSummary, AccordionActions, Button } from "@material-ui/core";
import { useLazyQuery } from "@apollo/react-hooks";
import { GET_CHANNELS } from "../channels/queries";
import { useTranslation } from "react-i18next";
import { AppropriateChannelsComponentProps, buttonStyles } from "./onboarding-page.types";
import { AppropriateChannels, OnboardingActionTypes } from "../../redux/onboarding/onboarding.types";
import { IAppropriateChannels, IToggleAboutYouAccordion, TOnboardingReducerAction } from "../../redux/onboarding/onboarding.actions";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { StylesProvider } from "@material-ui/core/styles";
import './onboarding-page.styles.scss';
import { selectAppropriateChannelsAccordionState } from "../../redux/onboarding/onboarding.selectors";
import { StoreState } from "../../redux/root-reducer";


const AppropriateChannelsComponent: React.FC<AppropriateChannelsComponentProps> = ({...props}) => {
  const { toggleAppropriateChannels, saveAppropriateChannelsAction , toggleAboutYouAccordionAction} = props;

  const [open, setOpen] = useState(false);
  const [channels, setChannels] = useState<Channel[]>([]);
  const [selectedChannels, setSelectedChannels] = useState<Channel[]>([]);

  const [getDataInfo, { data, loading, error }] = useLazyQuery(GET_CHANNELS, {
    fetchPolicy: "no-cache"
  });
  const [errors, setErrors] = useState({
    channels: ""
  });
  const { t } = useTranslation();
  const classes = buttonStyles();

  useEffect(() => {
    if(toggleAppropriateChannels) {
      setOpen(true);
    }
  }, [toggleAppropriateChannels])

  useEffect(() => {
    if (data && data.getAllChannels) {
      setChannels(data.getAllChannels);
    }
  }, [data]);

  useEffect(() => {
    if (selectedChannels.length !== 0) {
      setErrors({ channels: "" });
    }
  }, [selectedChannels]);

  useEffect(() => {
    if(open) {
      getDataInfo();
    }
  }, [open])

  const handleAccordionAcction = () => {
    setOpen(!open);
  };

  const handleChannelSelect = (channel: Channel) => {
    if (selectedChannels.includes(channel)) {
      const tempSelectedChannels = selectedChannels.filter((selectedChannel: Channel) => {
        if (selectedChannel !== channel) {
          return selectedChannel;
        }
      })
      setSelectedChannels(tempSelectedChannels);
    } else {
      setSelectedChannels(selectedChannels.concat([channel]));
    }
  };

  const getChannelColor = (code: string) => {
    const channelColorClass = code.concat("-add");
    return channelColorClass;
  };

  const handleSave = () => {
    if (selectedChannels.length === 0) {
      setErrors({ channels: t("Onboarding.Channels.Error.Text") });
    } else {
      saveAppropriateChannelsAction({channels: selectedChannels})
      setOpen(!open);
      toggleAboutYouAccordionAction();
    }
  };

  return (
    <StylesProvider injectFirst>
    <Accordion expanded={open}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1c-content"
        id="panel1c-header"
        onClick={handleAccordionAcction}
      >
        <div className="title">{t("Onboarding.Channels.Heading")}</div>
      </AccordionSummary>
      <div className="accordion-container">
        <div className="step-one-channels">
          {channels.map(channel => {
            return (
              <button
                onClick={() => handleChannelSelect(channel)} className={`channel-button-add onboarding-channel-buttons ${selectedChannels.includes(channel) ? getChannelColor(channel.code) : "" }`}
                key={channel.id}
              >
                <span className="button-text">{channel.title + " "}</span>
              </button>
            );
          })}
        </div>
        <div className="helper-text-select">
          {errors.channels !== "" ? errors.channels : null}
        </div>
      </div>
      <AccordionActions style={{justifyContent: 'center'}}>
        <Button onClick={handleSave} classes={{root: classes.greenButtonRoot}}>
          {t("Onboarding.StepOne.Save.button")}
        </Button>
      </AccordionActions>
    </Accordion>
    </StylesProvider>
  );
};

const mapStateToProps = (state: StoreState): {toggleAppropriateChannels: boolean} => {
  return {
    toggleAppropriateChannels: selectAppropriateChannelsAccordionState(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch<TOnboardingReducerAction>) => {
  return {
    saveAppropriateChannelsAction: (data: AppropriateChannels) => dispatch<IAppropriateChannels>({ type: OnboardingActionTypes.APPROPRIATE_CHANNELS, data: data }),
    toggleAboutYouAccordionAction: () => dispatch<IToggleAboutYouAccordion>({ type: OnboardingActionTypes.TOGGLE_ABOUT_YOU})
  }
};

export default connect(mapStateToProps, mapDispatchToProps) (AppropriateChannelsComponent);
