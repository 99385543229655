import { UserActionTypes, UserState, User, UserLocation } from './user.types';
import {TUserReducerActions} from './user.actions';

const InitialState: UserState = {
    awaitingAuthentication: false,
    authenticated: false,
    registeredUser: false,
    currentUser: {} as User,
    userHasSFTPAccess: false,
    userLocation: {
        gavePermission: false, 
        userCoordinates: null
    },
    dataTableRefreshState: false,
    toggleUserLocation: false,
    profileImageUrl: '',
    userCurrentLocationLoading: false,
    loadingSearchResults: false
};

export const userReducer = ( state = InitialState, action: TUserReducerActions): UserState => {
            switch(action.type) {
                case UserActionTypes.RegisterUser:
                    return {
                        ...state,
                        awaitingAuthentication: true,
                    };
                case UserActionTypes.RegisterUserSuccess:
                    return {
                        ...state,
                        awaitingAuthentication: false,
                        registeredUser: true,
                        currentUser: action.data
                    };
                case UserActionTypes.RegisterUserError:
                    return {
                         ...state,
                          awaitingAuthentication: false,
                          registeredUser: false
                    };
                case UserActionTypes.Login:
                    return {
                        ...state,
                        awaitingAuthentication: true,
                        registeredUser: false
                    };
                case UserActionTypes.LoginSuccess:
                    return {
                        ...state,
                        awaitingAuthentication: false,
                        authenticated: true,
                        currentUser: action.data,
                    };
                case UserActionTypes.LoginError:
                    return {
                        ...state,
                        awaitingAuthentication: false,
                        authenticated: false
                    };
                case UserActionTypes.LogoutUser:
                    return {
                        ...state,
                        awaitingAuthentication: true
                    };
                case UserActionTypes.LogoutUserSuccess:
                    return {
                        ...state,
                        awaitingAuthentication: false,
                        authenticated: false,
                        registeredUser: false,
                        currentUser: {} as User,
                        userHasSFTPAccess: false,
                        profileImageUrl: ''
                    };
                case UserActionTypes.LogoutUserError:
                    return {
                        ...state,
                        awaitingAuthentication: false,
                        authenticated: true
                    };
                case UserActionTypes.LoginThirdParty:
                    return {
                        ...state,
                        authenticated: true,
                        currentUser: action.data
                    };
                case UserActionTypes.LoginThirdPartyError:
                    return {
                        ...state,
                        awaitingAuthentication: false,
                        authenticated: false
                    };
                case UserActionTypes.SET_SFTP_ACCESS:
                    return {
                        ...state,
                        userHasSFTPAccess: action.data
                    }
                case UserActionTypes.GET_USER_CURRENT_LOCATION:
                    return {
                        ...state,
                        userLocation: action.data
                    }
                case UserActionTypes.TOGGLE_USER_CURRENT_LOCATION:
                    return {
                        ...state,
                        toggleUserLocation: action.data
                    }
                case UserActionTypes.TOGGLE_DATA_TABLE_REFRESH_STATE:
                    return {
                        ...state,
                        dataTableRefreshState: action.data
                    }
                case UserActionTypes.SET_COMPLETED_ONBOARDING:
                    return {
                        ...state,
                        currentUser: {...state.currentUser, hasCompletedOnboarding: action.data} 
                    }
                case UserActionTypes.SET_USER_PROFILE_IMAGE: 
                    return  {
                        ...state,
                        profileImageUrl: action.data
                    } 
                case UserActionTypes.TOGGLE_USER_CURRENT_LOCATION_LOADING: 
                    return  {
                        ...state,
                        userCurrentLocationLoading: action.data
                    }
                case UserActionTypes.TOGGLE_LOADING_SEARCH_RESULTS: 
                    return  {
                        ...state,
                        loadingSearchResults: action.data
                    }
                default:
                    return state;
            }
        }

export default userReducer;