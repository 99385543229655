import gql from "graphql-tag";

export const ADD_CONTENT_ORDER = gql`
  mutation addOrderMutation($contents: [ContentOrderDto!]!, $userId: Int!, $totalTax: Float!) {
    addOrder(contents: $contents, userId: $userId, totalTax: $totalTax) {
      id
      totalPrice
    }
  }
`;

export const CONFIRM_CONTENT_ORDER = gql`
  mutation confirmOrderMutation($orderId: Int!) {
    confirmOrder(orderId: $orderId)
  }
`;

export const GET_ORDERS_BY_USER_ID = gql`
  query getLastOrderByUserIdQuery($userId: Int!) {
    getLastOrderByUserId(userId: $userId) {
      order {
        id
        userId
      }
      contentOrders {
        contentId
        contentPrice
      }
      contents {
        id
        bucketKey
        type
      }
    }
  }
`;

export const SEND_INVOICE = gql`
  mutation sendInvoiceMutation($details: EmailDto!, $orderId: Int!, $invoiceTemplateType: String!) {
    sendInvoice(details: $details, orderId: $orderId, invoiceTemplateType: $invoiceTemplateType)
  }
`;

export const GET_USER_BILLING_ADDRESS = gql`
  query getUserBillingAddress($userId: Int!) {
    getUserBillingAddress(userId: $userId) {
      address {
        addressLine1
        addressLine2
      }
      country {
        country
        countryCode
      }
      province {
        stateProvince
        stateCode
      }
      city {
        city
      }
      zipCode {
        zipCodeNumber
      }
    }
  }
`;

export const CALCULATE_TAX_FOR_ORDER = gql`
  query calculateTaxForOrder($userId: Int!, $totalAmount: Float!, $lineItems: [TaxLineItemInput!]!, $locationInput: LocationInput!, $addressInput: AddressInput!) {
    calculateTaxForOrder(userId: $userId, totalAmount: $totalAmount, lineItems: $lineItems, locationInput: $locationInput, addressInput: $addressInput) {
      totalAmount
      shipping
      taxableAmount
      amountToCollect
      rate
      hasNexus
      freightTaxable
      taxSource
      exemptionType
      jurisdictions {
        country
        county
        state
        city
      }
      breakdown {
        taxableAmount
        taxCollectable
        combinedTaxRate
        stateTaxableAmount
        stateTaxRate
        stateTaxCollectable
        countyTaxableAmount
        countyTaxRate
        countyTaxCollectable
        cityTaxableAmount
        cityTaxRate
        cityTaxCollectable
        specialDistrictTaxableAmount
        specialTaxRate
        specialDistrictTaxCollectable
        gstTaxableAmount
        gstTaxRate
        gst
        pstTaxableAmount
        pstTaxRate
        pst
        qstTaxableAmount
        qstTaxRate
        qst
        countryTaxableAmount
        countryTaxRate
        countryTaxCollectable
        lineItems {
          taxableAmount
          taxCollectable
          stateTaxableAmount
          stateSalesTaxRate
          stateAmount
          specialTaxRate
          specialDistrictTaxableAmount
          specialDistrictAmount
          id
          countyTaxableAmount
          countyTaxRate
          countyAmount
          combinedTaxRate
          cityTaxableAmount
          cityTaxRate
          cityAmount
          gstTaxableAmount
          gstTaxRate
          gst
          pstTaxableAmount
          pstTaxRate
          pst
          qstTaxableAmount
          qstTaxRate
          qst
          countryTaxableAmount
          countryTaxRate
          countryTaxCollectable
        }
      }
    }
  }
`;

export const UPDATE_AMOUNT_LEFT = gql`
  mutation updateAmountLeftMutation($userLicensingPlanId: Int!, $numberOfItemsBought: Int!) {
    updateAmountLeft(userLicensingPlanId:$userLicensingPlanId, numberOfItemsBought:$numberOfItemsBought){
      id
      amountLeft
    }
  }
`;

export const CREATE_TRANSACTION_TAXJAR = gql`
  query createTransactionMutation($stripeId: String!, $stripeDate: String!, $lineItems: [TaxLineItemInput!]!, $locationInput: LocationInput!, $addressInput: AddressInput!, $totalTax: Float!, $totalAmount: Float!) {
    createTransaction(stripeId: $stripeId, stripeDate: $stripeDate, lineItems: $lineItems, locationInput: $locationInput, addressInput: $addressInput, totalTax: $totalTax, totalAmount: $totalAmount) {
      transaction_id
      user_id
      transaction_date
      transaction_reference_id
      provider
      exemption_type
      to_country
      to_zip
      to_state
      to_city
      to_street
      amount
      shipping
      sales_tax
      line_items {
        id
        quantity
        product_identifier
        description
        unit_price
        discount
        sales_tax
      }
    }
  }
`;
