import React, { Fragment, useEffect, useState } from "react";
import {Chip, Container, makeStyles, Popover, Typography, withStyles} from '@material-ui/core';
import { followChipProps } from './FollowChip.types';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import {isEmpty} from './helperFunctions'
import './channelScreen.styles.scss';
  

const useStyles = makeStyles((theme) => ({
    popover: {
      pointerEvents: 'none',
    },
    paper: {
      padding: theme.spacing(1),
    },
    containerRoot: {
      marginLeft: '0 ',
      marginRight: '0 ',
      marginTop: '2.2% ',
      borderRadius: '20px ',
      padding: '19px 0',
      width: '133px ',
      '@media (min-width: 200px)': {
        padding: '0'
      }
    }
}));

const FollowChipComponent: React.FC<followChipProps> = ({...props}) => {
    const { currentUser, colors, isFollowing, handleOnFollow, handleOnUnfollow} = props;
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [dimensions,setDimensions] = useState({
        height:window.innerHeight,
        width:window.innerWidth
      })
      const upperBreakpoint = 1180;
      const lowerBreakpoint = 700;
      useEffect(():any=>{
        const handleResize=()=>{
          setDimensions({
            height:window.innerHeight,
            width:window.innerWidth
          })
        }
        window.addEventListener('resize',handleResize)
  
        return (event:any) =>{
          window.removeEventListener('resize',handleResize)
        }
      })

    const followChip = makeStyles(() => ({
      chipRoot:{
          color: `${colors[3]} !important`,
          border: `1px solid ${colors[3]}`,
          borderRadius: '20px',
          fontSize: '1.3rem',
          marginLeft: '10px',
          marginTop:'30px'
      },
      deleteIcon:{
        color: `${colors[3]}`,
        "&:hover": {
            color: `${colors[0]}`
          }
    }
    }))

    const stylesChip = followChip();
    // const ConditionalWrapper = (args : { condition : any, wrapper : any, children : any } )  => 
    // args.condition ? args.wrapper(args.children) : args.children;


    const handlePopoverOpen = (event : any) => {
        setAnchorEl(event.currentTarget);
      };
    
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    
    function renderFollow(){
        if(!isEmpty(currentUser)){
            return(
                <Chip 
                classes = {{root: stylesChip.chipRoot, deleteIcon: stylesChip.deleteIcon}}
                label= {isFollowing ? "Unfollow" : "Follow"}
                style={{backgroundColor: colors[1]}}
                size = 'medium'
                onClick={isFollowing ? handleOnUnfollow : handleOnFollow}
                onDelete={isFollowing? handleOnUnfollow : handleOnFollow}
                deleteIcon={isFollowing ? <RemoveIcon /> : <AddIcon />}
                disabled={isEmpty(currentUser)}
                /> 
            )
        }
        else{
            return(
                <Container
                    classes = {{root:classes.containerRoot}}
                    aria-owns={open ? 'mouse-over-popover' : undefined}
                    aria-haspopup="true"
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                >
                    <Chip
                        classes = {{root: stylesChip.chipRoot, deleteIcon: stylesChip.deleteIcon}}
                        label= {isFollowing ? "Unfollow" : "Follow"}
                        style={{backgroundColor: colors[1]}}
                        size = {window.innerWidth < 1180 ? 'small' : 'medium'}
                        onClick={isFollowing ? handleOnUnfollow : handleOnFollow}
                        onDelete={isFollowing? handleOnUnfollow : handleOnFollow}
                        deleteIcon={isFollowing ? <RemoveIcon /> : <AddIcon />}
                        disabled={isEmpty(currentUser)}
                    /> 
                </Container>     
            )
        }
    }

    return (
         <Fragment>
              {renderFollow()}
              <Popover
                id="mouse-over-popover"
                classes={{
                    root: classes.popover,
                    paper: classes.paper,
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
               >
                <Typography style={{color:'red'}}>You must log in to be able to follow</Typography>
            </Popover>
          </Fragment>
    );
};



export default FollowChipComponent;