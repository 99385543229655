import { ContentSet, UploadedFileResponse, Article, IBroadcastMessage, DroppedFile, AudioFile, IndividualImageCategory, ImageUploadStatus } from '../../components/batch-upload/ContentUpload.types';

export enum ContentUploadActionTypes {
    TOGGLE_RADIO_BUTTON = "TOGGLE_RADIO_BUTTON",
    ADD_FILE = "ADD_FILE",
    REMOVE_FILE = "REMOVE_FILE",
    CHUNKED_FILE = "CHUNKED_FILE",
    UPLOAD_SET = "UPLOAD_SET",
    DELETE_FILES = "DELETE_FILES",
    UPDATE_SET = "UPDATE_SET",
    DELETE_SET = "DELETE_SET",
    UPLOAD_FILES = "UPLOAD_FILES",
    ADD_CHANNEL_ID = "ADD_CHANNEL_ID",
    UPLOAD_ARTICLE = "UPLOAD_ARTICLE",
    UPDATE_ARTICLE = "UPDATE_ARTICLE",
    DELETE_ARTICLE = "DELETE_ARTICLE",
    ADD_AUDIO_FILE = "ADD_AUDIO_FILE",
    DELETE_AUDIO_FILE = "DELETE_AUDIO_FILE",
    LOAD_METADATA = "LOAD_METADATA",
    ADD_CHANNEL_TO_INDIVIDUAL= "ADD_CHANNEL_TO_INDIVIDUAL",
    RESET_CONTENT = "RESET_CONTENT",
    BROADCAST_MESSAGE = "CONTENT_UPLOAD_BROADCAST_MESSAGE",
    TOGGLE_SAVED_CHANGES = "TOGGLE_SAVED_CHANGES",
    TOGGLE_IMAGE_UPLOAD_STATUS = "TOGGLE_IMAGE_UPLOAD_STATUS",
} 

export interface ContentUploadState {
    chunkedFile: any;
    uploadedFiles: UploadedFileResponse[];
    imageUploadStatus: any;
    contentSets: ContentSet[];
    articles: Article[];
    audioFiles: AudioFile[];
    droppedFiles: DroppedFile[];
    selectedRadioButton: string;
    individualImagesCategory: IndividualImageCategory[];
    uploadError: IBroadcastMessage;
    changesToBeSaved: boolean;
}