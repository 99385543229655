import React, { useState, useEffect } from "react";
import {
  ManageContentProps,
  manageContentTheme,
  ContentSet,
  UploadedFileResponse,
  RadioButtonGroup,
  IBroadcastMessage,
  SeveritySnackbarEnum,
  ContentUploadMethods,
  Article,
} from "./ContentUpload.types";
import { StoreState } from "../../redux/root-reducer";
import {
  selectUploadedFiles,
  selectContentSets,
  selectRadioButtonValue,
  selectArticles,
} from "../../redux/content-upload/content-upload.selectors";
import AddIcon from "@material-ui/icons/Add";
import {
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  ListItem,
  MuiThemeProvider,
  TextField,
  InputAdornment,
  IconButton,
  FormControlLabel,
  MenuItem,
  Zoom,
  Popper,
  Grow,
  Paper,
  MenuList,
  makeStyles,
  Tooltip
} from "@material-ui/core";
import { connect } from "react-redux";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Dispatch } from "redux";
import {
  TContentUploadActions,
  IUploadSet,
  IUpdateSet,
  IUploadFiles,
  IDeleteFiles,
  IBroadcastContentUploadMessage,
  IDeleteSet,
  IDeleteArticle,
} from "../../redux/content-upload/content-upload.actions";
import { ContentUploadActionTypes } from "../../redux/content-upload/content-upload.types";
import { EditableTextField } from "shared";
import { v4 as uuidv4 } from "uuid";
import PopperComponent from "./PopperComponent";
import DoneIcon from "@material-ui/icons/Done";
import { useTranslation } from "react-i18next";
import Image from 'react-async-image';
import { ConfirmationDialog } from 'shared';
import "./ManageContent.styles.scss";

const useStyles = makeStyles((theme) => ({
  coverPhoto: {
    backgroundColor: 'rgb(12, 175, 149) !important'
  }
}));

const ManageContent: React.FC<ManageContentProps> = ({ ...props }) => {
  const {
    selectedRadioButton, uploadedFiles, contentSetsState, articles, selectedContentUploadMethod, uploadSetAction,
    updateSetAction, deleteSetAction, uploadFilesAction, deleteArticleAction, deleteFilesAction, broadcastUploadErrorAction } = props;
  const [selectedContentSetImages, setSelectedContentSetImages] = useState<number[]>([]);
  const [selectedSetId, setSelectedSetId] = useState<string>("");
  const [selectedImages, setSelectedImages] = useState<number[]>([]);
  const [contentSets, setContentSets] = useState<ContentSet[]>([]);
  const [contentSetTitle, setContentSetTitle] = useState("");
  const [newSetTitle, setNewSetTitle] = useState<string>("");
  const [anchorElem, setAnchorElem] = useState<null | HTMLElement>(null);
  const [isSelectionEnabled, setIsSelectionEnabled] = useState<boolean>(false);

  const [removeSetConfirmationDialog, setRemoveSetConfirmationDialog] = useState(false);
  const [removeSetId, setRemoveSetId] = useState("");
  const { t } = useTranslation();
  const classes = useStyles();

  useEffect(() => {
    setContentSets(contentSetsState);
  }, [contentSetsState]);

  const toggleUploadFilesMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorElem(anchorElem ? null : event.currentTarget);
  };

  const handleFilesClick = (id: number) => {
    if (isSelectionEnabled) {
      if (!selectedImages.includes(id)) {
        setSelectedImages([...selectedImages, id]);
        setSelectedContentSetImages([]);
      } else {
        setSelectedImages(selectedImages.filter((item) => item !== id));
      }
    }
    else {
      broadcastUploadErrorAction({
        severity: SeveritySnackbarEnum.warning,
        message: t("Warning.EnableImageSelection")
      })
    }
  };
  const handleSelectImagesButton = () => {
    setIsSelectionEnabled(true);
  }

  const handleStopSelection = () => {
    setIsSelectionEnabled(false);
  }

  const handleContentSetClick = (setId: string, fileId: number) => {
    if (isSelectionEnabled) {
      if (selectedSetId === "") {
        setSelectedSetId(setId);
      } else if (selectedSetId !== setId) {
        setSelectedContentSetImages([]);
        setSelectedSetId(setId);
      }

      if (!selectedContentSetImages.includes(fileId)) {
        setSelectedContentSetImages([...selectedContentSetImages, fileId]);
        setSelectedImages([]);
      } else {
        setSelectedContentSetImages(
          selectedContentSetImages.filter((item) => item !== fileId)
        );
      }
    }
    else {
      broadcastUploadErrorAction({
        severity: SeveritySnackbarEnum.warning,
        message: t("Warning.EnableImageSelection")
      })
    }
  };

  const selectAllPhotosInContentSet = (setId: string) => {
    setSelectedSetId(setId);
    setSelectedImages([]);
    let selectedSet = contentSets.find((set: ContentSet) => set.id === setId);
    if (selectedSet) {
      let setIds = selectedSet.files.map((file: UploadedFileResponse) => { return file.id });
      setSelectedContentSetImages(setIds);
    }
  }

  const unselectAllPhotos = () => {
    setNewSetTitle("");
    setSelectedSetId("");
    setSelectedImages([]);
    setSelectedContentSetImages([]);
  }

  const selectAllUploadedPhotos = () => {
    setSelectedSetId("");
    setSelectedContentSetImages([]);
    let uploadedFilesIds = uploadedFiles.map((file: UploadedFileResponse) => { return file.id });
    setSelectedImages(uploadedFilesIds);
  }

  const createNewSet = () => {
    const set: ContentSet = {
      id: uuidv4(),
      title: "",
      files: uploadedFiles.filter((file: UploadedFileResponse) =>
        selectedImages.includes(file.id)
      ),
    };
    setContentSets([...contentSets, set]);
    setSelectedImages([]);
    uploadSetAction(set);
    broadcastUploadErrorAction({
      severity: SeveritySnackbarEnum.success,
      message: t("NewSet.Create.Success")
    });
  };

  const handleChange = (event: any) => {
    event.preventDefault();

    if (event.target.name === "newSet") {
      setNewSetTitle(event.target.value);
    }
  };

  const handleAccordionExpandChange = (setTitle: string, event: object, expanded: boolean) => {
    // console.log(expanded, event);
  }

  const changeSetTitle = (set: ContentSet, text: string) => {
    if (text.length <= 1 || text.length >= 500) {
      broadcastUploadErrorAction({
        severity: SeveritySnackbarEnum.warning,
        message: t("ContentSet.Name.Validation")
      });
    } else if (contentSets.find((contentSet: ContentSet) => contentSet.title === text && contentSet.id !== set.id)) {
      broadcastUploadErrorAction({
        severity: SeveritySnackbarEnum.error,
        message: t("ContentSet.Name.Exist")
      });
    } else {
      const updatedSet: ContentSet = {
        id: set.id,
        title: text,
        files: set.files,
        coverPhoto: set.coverPhoto,
      };

      updateSetAction(updatedSet);
    }

    setContentSetTitle("");
  };

  const moveToNewSet = (selectedUploadedImages?: number[]) => {
    if (selectedUploadedImages && newSetTitle) {
      if (newSetTitle.length <= 1 || newSetTitle.length >= 500) {
        broadcastUploadErrorAction({
          severity: SeveritySnackbarEnum.warning,
          message: t("ContentSet.Name.Validation")
        });
        return;
      }

      const uploadedImages = uploadedFiles.filter((file) =>
        selectedImages.includes(file.id)
      );

      const newSet: ContentSet = {
        id: uuidv4(),
        title: newSetTitle,
        files: uploadedImages,
      };

      uploadSetAction(newSet);
      deleteFilesAction(selectedImages);
    } else {
      const setWithSelectedFiles = contentSets.find((set: ContentSet) => set.id === selectedSetId);
      const selectedFiles = setWithSelectedFiles!.files.filter((file: UploadedFileResponse) => selectedContentSetImages.includes(file.id));

      if (setWithSelectedFiles?.coverPhoto && selectedFiles.includes(setWithSelectedFiles?.coverPhoto)) {
        broadcastUploadErrorAction({
          severity: SeveritySnackbarEnum.error,
          message: t("ContentSet.CoverPhoto.Error")
        });
        unselectAllPhotos();
        return;
      }

      const newSet: ContentSet = {
        id: uuidv4(),
        title: newSetTitle,
        files: selectedFiles,
      };

      uploadSetAction(newSet);

      const updatedOldSet: ContentSet = {
        id: setWithSelectedFiles!.id,
        title: setWithSelectedFiles!.title,
        files: setWithSelectedFiles!.files.filter(
          (file: UploadedFileResponse) =>
            !selectedContentSetImages.includes(file.id)
        ),
        coverPhoto: setWithSelectedFiles?.coverPhoto,
      };

      if (updatedOldSet.files.length > 0) {
        updateSetAction(updatedOldSet);
      } else {
        removeContentSetWhenMovingToSet(updatedOldSet.id);
      }

      broadcastUploadErrorAction({
        severity: SeveritySnackbarEnum.success,
        message: t("Move.Photos.Success")
      });
    }
    unselectAllPhotos();
  };

  const moveToExistingSet = (selectedSet: ContentSet, selectedUploadedImages?: number[]) => {
    if (selectedUploadedImages) {
      const uploadedImages = uploadedFiles.filter((file) =>
        selectedImages.includes(file.id)
      );

      if (selectedSet) {
        const updatedSet: ContentSet = {
          id: selectedSet.id,
          title: selectedSet.title,
          files: selectedSet.files.concat(uploadedImages),
          coverPhoto: selectedSet.coverPhoto,
        };

        updateSetAction(updatedSet);
      }

      unselectAllPhotos();
      deleteFilesAction(selectedUploadedImages);
      broadcastUploadErrorAction({
        severity: SeveritySnackbarEnum.success,
        message: t("Move.Photos.Success")
      });
      return;
    }

    const setWithSelectedFiles = contentSets.find((set: ContentSet) => set.id === selectedSetId);
    const selectedFiles = setWithSelectedFiles!.files.filter((file: UploadedFileResponse) => selectedContentSetImages.includes(file.id));

    if (setWithSelectedFiles?.coverPhoto && selectedFiles.includes(setWithSelectedFiles?.coverPhoto)) {
      broadcastUploadErrorAction({
        severity: SeveritySnackbarEnum.error,
        message: t("ContentSet.CoverPhoto.Error")
      });
      setSelectedContentSetImages([]);
      setSelectedSetId("");
      setNewSetTitle("");
      return;
    }

    if (selectedSet) {
      const updatedSet: ContentSet = {
        id: selectedSet.id,
        title: selectedSet.title,
        files: selectedSet.files.concat(selectedFiles),
        coverPhoto: selectedSet.coverPhoto,
      };

      updateSetAction(updatedSet);

      const updatedOldSet: ContentSet = {
        id: setWithSelectedFiles!.id,
        title: setWithSelectedFiles!.title,
        files: setWithSelectedFiles!.files.filter(
          (file: UploadedFileResponse) =>
            !selectedContentSetImages.includes(file.id)
        ),
        coverPhoto: setWithSelectedFiles?.coverPhoto,
      };
      if (updatedOldSet.files.length > 0) {
        updateSetAction(updatedOldSet);
      } else {
        removeContentSetWhenMovingToSet(updatedOldSet.id);
      }

      unselectAllPhotos();
      broadcastUploadErrorAction({
        severity: SeveritySnackbarEnum.success,
        message: t("Move.Photos.Success")
      });
    }
  };

  const selectCoverPhoto = (selectedPhotoId: number, set: ContentSet) => {
    let allSets = contentSets;

    let setIndex = allSets.findIndex(
      (contentSet: ContentSet) => contentSet.id === set.id
    );
    let coverPhotoFile = set.files.find(
      (item: UploadedFileResponse) => item.id === selectedPhotoId
    );

    if (setIndex !== -1) {
      allSets[setIndex].coverPhoto = coverPhotoFile;
      updateSetAction(allSets[setIndex]);
    }

    setSelectedContentSetImages([]);
    broadcastUploadErrorAction({
      severity: SeveritySnackbarEnum.success,
      message: t("Set.Cover.Succes")
    });
  };

  const removeContentSet = (id: string) => {
    setRemoveSetId(id);
    setRemoveSetConfirmationDialog(true);
  };

  const removeContentSetWhenMovingToSet = (id: string) => {
    if (id !== '') {
      const setToRemove = contentSets.find((set: ContentSet) => set.id === id);
      const articleExists = articles.find((article: Article) => article.contentSetId === id);

      if (setToRemove) {
        deleteSetAction(setToRemove.id);
        broadcastUploadErrorAction({
          severity: SeveritySnackbarEnum.success,
          message: t("ContentSet.Remove.Success")
        });
      }

      if (articleExists) {
        deleteArticleAction(articleExists.contentSetId);
      }
    }
    else {
      broadcastUploadErrorAction({
        severity: SeveritySnackbarEnum.error,
        message: t("ContentSet.Find.Fail")
      });
    }
  }

  const handleRemoveSetDialogAgree = () => {
    setRemoveSetConfirmationDialog(false);
    if (removeSetId !== "") {
      const setToRemove = contentSets.find((set: ContentSet) => set.id === removeSetId);
      const articleExists = articles.find((article: Article) => article.contentSetId === removeSetId);

      if (setToRemove) {
        uploadFilesAction(setToRemove.files);
        deleteSetAction(setToRemove.id);
        broadcastUploadErrorAction({
          severity: SeveritySnackbarEnum.success,
          message: t("ContentSet.Remove.Success")
        });
      }

      if (articleExists) {
        deleteArticleAction(articleExists.contentSetId);
      }
    } else {
      broadcastUploadErrorAction({
        severity: SeveritySnackbarEnum.error,
        message: t("ContentSet.Find.Fail")
      });
    }
  }

  const handleRemoveSetDialogClose = () => {
    setRemoveSetConfirmationDialog(false);
    setRemoveSetId("");
  }

  return (
    <MuiThemeProvider theme={manageContentTheme}>
      <div className='content-sets-container'>
        {contentSets.map((set: ContentSet) => (
          <Zoom in={true} key={set.id}>
            <Accordion defaultExpanded={selectedContentUploadMethod === ContentUploadMethods.BROWSER_UPLOAD}
              TransitionProps={{ unmountOnExit: selectedContentUploadMethod === ContentUploadMethods.SFTP_UPLOAD }}
              onChange={(e: any, exp: boolean) => handleAccordionExpandChange(set.title, e, exp)}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                id='category-image-set'>
                <Tooltip title={set.title} disableFocusListener disableTouchListener>
                  <FormControlLabel aria-label='content-set-title'
                    onClick={(event) => event.stopPropagation()}
                    onFocus={(event) => event.stopPropagation()}
                    control={
                      <EditableTextField value={set.title} name="editableTextfield" autoFocus={true} placeholder={t("Name.Set.Placeholder")}
                        onSubmit={(text: string) => changeSetTitle(set, text)} />
                    } label='' />
                </Tooltip>
              </AccordionSummary>
              <AccordionDetails>
                <div className='images-container'>
                  {set.files.map((file: UploadedFileResponse) => (
                    <div key={file.id} className="preview-box">
                      <ListItem button selected={selectedContentSetImages.includes(file.id)}
                        classes={{ gutters: set.coverPhoto?.id === file.id ? classes.coverPhoto : '' }}
                        onClick={() => handleContentSetClick(set.id, file.id)} aria-label="selectable-image">
                        <Image src={file.url} className="image" loading='auto'
                          placeholder={<div className="placeholder"> {t("ContentUpload.Failed.Displayment")} </div>} />
                      </ListItem>
                    </div>
                  ))}
                </div>
              </AccordionDetails>
              <AccordionActions>
                <div className='manage-content-buttons'>
                  <Button
                    size="small"
                    className="white-button"
                    disabled={
                      selectedContentSetImages.length !== 1 ||
                      selectedSetId !== set.id
                    }
                    onClick={() =>
                      selectCoverPhoto(selectedContentSetImages[0], set)
                    }
                    variant="contained"
                    component="span"
                  >
                    {t("Select.As.Cover.Button")}
                  </Button>
                  <PopperComponent currentSetId={set.id} selectedContentSetImages={selectedContentSetImages}
                    selectedSetId={selectedSetId} contentSets={contentSets} newSetTitle={newSetTitle}
                    moveToExistingSet={moveToExistingSet} moveToNewSet={moveToNewSet} handleChange={handleChange} />
                  {isSelectionEnabled ?
                    <Button
                      size="small"
                      className="white-button"
                      onClick={handleStopSelection}
                      variant="contained"
                      component="span">
                      {t("Stop.SelectImages.Button")}
                    </Button>
                    :
                    <Button
                      size="small"
                      className="white-button"
                      onClick={() => handleSelectImagesButton()}
                      variant="contained"
                      component="span">
                      {t("SelectImages.Button")}
                    </Button>
                  }

                  {selectedSetId === set.id && selectedContentSetImages.length === set.files.length ?
                    <Button
                      size="small"
                      className="white-button"
                      onClick={() => unselectAllPhotos()}
                      variant="contained"
                      component="span">
                      {t("Unselect.All.In.Content.Set")}
                    </Button>
                    :
                    <Button
                      size="small"
                      className="white-button"
                      onClick={() => selectAllPhotosInContentSet(set.id)}
                      variant="contained"
                      component="span">
                      {t("Select.All.In.Content.Set")}
                    </Button>
                  }
                  <Button
                    size="small"
                    className="white-button"
                    onClick={() => removeContentSet(set.id)}
                    variant="contained"
                    component="span"
                  >
                    {t("Remove.Content.Set.Button")}
                  </Button>
                </div>
              </AccordionActions>
            </Accordion>
          </Zoom>
        ))}

        <div className='uploaded-files-container'>
          {uploadedFiles.length > 0 ? (
            <label className="uploaded-files">
              {t("Uploaded.Files.Error.Message")}
            </label>
          ) : null}
          {uploadedFiles.map((file: UploadedFileResponse) => (
            <div className="preview-box" key={file.id}>
              <ListItem
                button
                selected={selectedImages.includes(file.id)}
                onClick={() => handleFilesClick(file.id)}
                aria-label="selectable-image"
              >
                <Image src={file.url} className="image" loading='auto'
                  placeholder={<div className="placeholder">{t("ContentUpload.Failed.Displayment")}</div>} />
              </ListItem>
            </div>
          ))}
        </div>

        {selectedRadioButton === RadioButtonGroup.SET && uploadedFiles.length !== 0 ? (
          <div className="manage-content-buttons">
            {isSelectionEnabled ?
              <Button
                size="small"
                className="white-button"
                onClick={handleStopSelection}
                variant="contained"
                component="span">
                {t("Stop.SelectImages.Button")}
              </Button>
              :
              <Button
                size="small"
                className="white-button"
                onClick={() => handleSelectImagesButton()}
                variant="contained"
                component="span">
                {t("SelectImages.Button")}
              </Button>
            }
            {selectedImages.length === uploadedFiles.length && selectedImages.length > 0 ?
              <Button
                size="small"
                className="white-button"
                onClick={() => unselectAllPhotos()}
                variant="contained"
                component="span">
                {t("Unselect.All.In.Content.Set")}
              </Button>
              :
              <Button
                size="small"
                className="white-button"
                disabled={uploadedFiles.length === 0}
                onClick={() => selectAllUploadedPhotos()}
                variant="contained"
                component="span">
                {t("Select.All.In.Content.Set")}
              </Button>
            }
            <Button
              size="small"
              className="white-button"
              hidden={uploadedFiles.length === 0}
              disabled={uploadedFiles.length === 0}
              onClick={createNewSet}
              variant="contained"
              component="span"
              endIcon={<AddIcon />}
            >
              {t("Add.Another.Set.Button")}
            </Button>
            {
              contentSets.length !== 0 ? 
              <Button
              size="small"
              className="white-button"
              aria-controls="move-to-set-menu"
              aria-haspopup="true"
              hidden={uploadedFiles.length > 0 && contentSets.length === 0}
              disabled={selectedImages.length === 0}
              onClick={toggleUploadFilesMenuClick}
              variant="contained"
              component="span"
              endIcon={<ExpandMoreIcon />}
            >
              {t("Move.To.Set.Button")}
            </Button> : null
            }
            <Popper
              open={Boolean(anchorElem)}
              anchorEl={anchorElem}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <MenuList
                      autoFocusItem={Boolean(anchorElem)}
                      id="menu-list-grow"
                    >
                      {contentSets!.map((set: ContentSet) => (
                        <MenuItem
                          key={set.id}
                          onClick={() => {
                            setAnchorElem(null);
                            moveToExistingSet(set, selectedImages);
                          }}
                        >
                          {set.title}
                        </MenuItem>
                      ))}
                      <MenuItem onKeyDown={(e) => e.stopPropagation()}>
                        <TextField
                          autoComplete="off"
                          variant="standard"
                          name="newSet"
                          value={newSetTitle}
                          placeholder={t('NewSet.Placeholder')}
                          data-shrink={false}
                          onChange={handleChange}
                          InputProps={{
                            endAdornment:
                              newSetTitle.length > 0 ? (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() => {
                                      setAnchorElem(null);
                                      moveToNewSet(selectedImages);
                                    }}
                                  >
                                    <DoneIcon />
                                  </IconButton>
                                </InputAdornment>
                              ) : null,
                          }}
                        />
                      </MenuItem>
                    </MenuList>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        ) : null}
      </div>
      <ConfirmationDialog open={removeSetConfirmationDialog} title={t('RemoveContentSet.Confirmation.Title')}
        contentText={t("RemoveContentSet.Confirmation.Context")}
        rejectButtonText={t("No.Button")}
        acceptButtonText={t("Yes.Button")}
        handleClose={handleRemoveSetDialogClose}
        handleConfirmationDialogAgree={handleRemoveSetDialogAgree} />
    </MuiThemeProvider>
  );
};

const mapStateToProps = (state: StoreState): {
  uploadedFiles: UploadedFileResponse[],
  contentSetsState: ContentSet[],
  selectedRadioButton: string,
  articles: Article[]
} => {
  return {
    uploadedFiles: selectUploadedFiles(state),
    contentSetsState: selectContentSets(state),
    selectedRadioButton: selectRadioButtonValue(state),
    articles: selectArticles(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch<TContentUploadActions>) => {
  return {
    uploadSetAction: (data: ContentSet) =>
      dispatch<IUploadSet>({
        type: ContentUploadActionTypes.UPLOAD_SET,
        data: data,
      }),
    updateSetAction: (data: ContentSet) =>
      dispatch<IUpdateSet>({
        type: ContentUploadActionTypes.UPDATE_SET,
        data: data,
      }),
    deleteSetAction: (data: string) =>
      dispatch<IDeleteSet>({
        type: ContentUploadActionTypes.DELETE_SET,
        data: data
      }),
    uploadFilesAction: (data: UploadedFileResponse[]) =>
      dispatch<IUploadFiles>({
        type: ContentUploadActionTypes.UPLOAD_FILES,
        data: data,
      }),
    deleteFilesAction: (data: number[]) =>
      dispatch<IDeleteFiles>({
        type: ContentUploadActionTypes.DELETE_FILES,
        data: data,
      }),
    broadcastUploadErrorAction: (data: IBroadcastMessage) => dispatch<IBroadcastContentUploadMessage>({
      type: ContentUploadActionTypes.BROADCAST_MESSAGE, data: data
    }),
    deleteArticleAction: (data: string) => dispatch<IDeleteArticle>(
      {
        type: ContentUploadActionTypes.DELETE_ARTICLE, data: data
      })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageContent);
