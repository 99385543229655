import React, { useEffect, useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { TextField, Accordion, AccordionSummary, Typography, Grow } from "@material-ui/core";
import { connect } from "react-redux";
import { AddressTypes } from '../onboarding/onboarding-page.types';
import { selectBillingAddress, selectCurrentCartItems, selectCurrentCartTotal, selectCurrentSubItems, selectTax } from "../../redux/cart/cart-page.selector";
import { StoreState } from "redux/root-reducer";
import { CartPaymentProps, detailAccordions, PaymentMethods } from "./cart-page.types";
import OrderSummary from './cart-order-summary.component';
import { useFormik } from "formik";
import SyncLoader from 'react-spinners/SyncLoader';
import {
  TCartReducerActions,
  IEmptyCart,
  IAddTaxForOrder,
  IBroadcastCartError
} from "../../redux/cart/cart-page.actions";
import { CartActionTypes, TaxForOrder } from "../../redux/cart/cart-page.types";
import { Dispatch } from "redux";
import { ADD_CONTENT_ORDER, CONFIRM_CONTENT_ORDER, UPDATE_AMOUNT_LEFT, CALCULATE_TAX_FOR_ORDER, GET_USER_BILLING_ADDRESS, SEND_INVOICE, CREATE_TRANSACTION_TAXJAR } from "./queries";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { useTranslation } from "react-i18next";
import {
  createStripeAddressToken,
  generateInvoiceTable,
  generateInvoiceTableSubscription,
  getAddressInput,
  getContentIds,
  getInvoiceTemplateType,
  getLineItems,
  getLineItemsWithSub,
  getLocationInput,
  getUpdateParamsForPlan,
  sendInvoice
} from "./cart-page-helperFunctions";
import './cart-page.styles.scss';
import AddressComponent from "../onboarding/step-one-address.component";
import { Addresses } from "../../redux/onboarding/onboarding.types";
import { ItemForCart } from "../photo-details/photo-details.types";
import { Stripe, StripeCardElement, StripeElements } from "@stripe/stripe-js";
import { IBroadcastMessage, SeveritySnackbarEnum } from "../batch-upload/ContentUpload.types";
import { User } from "../../redux/user/user.types";
import { selectCurrentUser } from "../../redux/user/user.selectors";
import { GET_USER_LICENSING_PLANS } from "../my-license-page/queries";
import { stripePayment } from "../stripe-payment-axios-function/stripe-axios-payment-function";
import { UserLicensingPlan } from "../my-license-page/my-license-page.types";
import PulseLoader from 'react-spinners/PulseLoader';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { CardDetailsList } from "../onboarding/yup-validation-objects";

const CartPayment: React.FC<CartPaymentProps> = ({ ...props }) => {
  const { total, cartItems, cartItemsFromSub, currentUser, additionalBillingAddress, tax,
    handlePageBack, handlePageChange, emptyCart, addTaxAction, broadcastCartAction } = props;

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(PaymentMethods.CARD);
  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    cardType: selectedPaymentMethod
  });

  const [processing, setProcessing] = useState(false);
  const [useAnotherBillingAddress, setUseAnotherBilling] = useState(false);

  const [addOrder] = useMutation(ADD_CONTENT_ORDER);
  const [confirmOrder] = useMutation(CONFIRM_CONTENT_ORDER); 
  const [updateCounts] = useMutation(UPDATE_AMOUNT_LEFT);
  const [sendInvoiceMutation] = useMutation(SEND_INVOICE);
  const { t } = useTranslation();
  const classes = detailAccordions();
  const stripe = useStripe();
  const elements = useElements();

  //BILLING ADDRESS, PAYMENT METHOD AND LICENSE PLAN
  const [userBillingAddress, setUserBillingAddress] = useState<Addresses | undefined>(undefined);
  
  const [showAddressValidationError, setAddressValidationError] = useState(false);
  const [addressValidationError, setAddressError] = useState("");
  const [showCardValidationError, setCardValidationError] = useState(false);
  const [cardValidationError, setCardError] = useState("");
  const [stripeTransactionDetails, setStripeTransactionDetails] = useState({
    id: "",
    date: new Date()
  });
  const [orderId, setOrderId] = useState(0);
  const [userLicensingPlans, setUserLicensingPlans] = useState<UserLicensingPlan[]>([]);
  const [fetchBillingAddress, { data: billingData, loading: billingLoading, error: billingError }] = useLazyQuery(GET_USER_BILLING_ADDRESS);
  const [calculateTaxForOrder, { data: taxData, loading: taxLoading, error: taxError }] = useLazyQuery(CALCULATE_TAX_FOR_ORDER);
  const [createTaxjarTransaction, {data: transactionData, loading: transactionLoading, error: transactionError}] = useLazyQuery(CREATE_TRANSACTION_TAXJAR);
  const [fetchLicensingPlan, { data: licenseData, loading: loadingData, refetch }] = useLazyQuery(GET_USER_LICENSING_PLANS, {
    fetchPolicy: "no-cache"
  });

  const cardElementOptions = {
    hidePostalCode: true
  };

  useEffect(() => {
    //if there is no address, fetch the address
    if (!userBillingAddress && !additionalBillingAddress) {
      fetchBillingAddress({ variables: { userId: currentUser.id } });
    }
    if (userLicensingPlans) {
      fetchLicensingPlan({ variables: { userId: currentUser.id } });
    }
  }, [])

  useEffect(() => {
    if (orderId !== 0) {
      confirmPayment();
    }
  }, [orderId])

  useEffect(() => {
    if(stripeTransactionDetails.id !== "") {
      createTransaction();
    }
  }, [stripeTransactionDetails])

  useEffect(() => {
    //here we recalculate if an address is changed or fetched
    if ((useAnotherBillingAddress && additionalBillingAddress) || (!useAnotherBillingAddress && userBillingAddress)) {
      calculateTax();
    }
  }, [useAnotherBillingAddress, userBillingAddress, additionalBillingAddress])

  useEffect(() => {
    if (taxError) {
      broadcastCartAction({severity: SeveritySnackbarEnum.error, message: "Failed to calculate tax for this billing address. Try again with a different address"});
    }

    if(transactionError) {
      broadcastCartAction({severity: SeveritySnackbarEnum.error, message: "Failed to create order transaction."});
    }
  }, [taxError, transactionError]);

  useEffect(() => {
    if (taxData && taxData.calculateTaxForOrder) {
      addTaxAction(taxData.calculateTaxForOrder);
    }
  }, [taxData])

  useEffect(() => {
    if (licenseData && licenseData.getUserLicensingPlans) {
      setUserLicensingPlans(licenseData.getUserLicensingPlans);
    }
  }, [licenseData]);

  useEffect(() => {
    if (billingData && billingData.getUserBillingAddress && billingData.getUserBillingAddress.country) {
      if(billingData.getUserBillingAddress.country) {
        const addr = billingData.getUserBillingAddress;

        setUserBillingAddress({
          country: addr.country?.country,
          countryCode: addr.country ? addr.country.countryCode : "",
          state: addr.province ? addr.province.stateProvince : "",
          stateCode: addr.province ? addr.province.stateCode : "",
          city: addr.city ? addr.city.city : "",
          zipCode: addr.zipCode ? addr.zipCode.zipCodeNumber : "",
          addressLineOne: addr.address ? addr.address.addressLine1 : "",
          addressLineTwo: addr.address ? addr.address.addressLine2 : ""
        });
      } else {
        broadcastCartAction({severity: SeveritySnackbarEnum.info, 
          message: "Failed to find your billing address. Use this form to fill in your valid billing address."});
        setUseAnotherBilling(true);
      }
    }
  }, [billingData]);

  const calculateTax = () => {
    //here we recalculate if an address is changed or fetched
    if ((useAnotherBillingAddress && additionalBillingAddress) || (!useAnotherBillingAddress && userBillingAddress)) {
      const locationInput = getLocationInput(useAnotherBillingAddress, additionalBillingAddress, userBillingAddress);
      const addressInput = getAddressInput(useAnotherBillingAddress, additionalBillingAddress, userBillingAddress);
      const allItems = cartItems.concat(cartItemsFromSub);

      if (locationInput && addressInput) {
        calculateTaxForOrder({
          variables:
          {
            userId: currentUser.id,
            totalAmount: total,
            lineItems: getLineItems(allItems),
            locationInput: locationInput,
            addressInput: addressInput
          }
        });
      }
    }
  }

  const createTransaction = () => {
    const locationInput = getLocationInput(useAnotherBillingAddress, additionalBillingAddress, userBillingAddress);
    const addressInput = getAddressInput(useAnotherBillingAddress, additionalBillingAddress, userBillingAddress);
    
    const cartLineItems = getLineItems(cartItems);
    const cartSubLineItems = getLineItemsWithSub(cartItemsFromSub);
    const lineItems = cartLineItems.concat(cartSubLineItems);
    
    createTaxjarTransaction({
      variables: {
        stripeId: stripeTransactionDetails.id, 
        stripeDate: stripeTransactionDetails.date, 
        lineItems: lineItems, 
        locationInput: locationInput, 
        addressInput: addressInput, 
        totalTax: tax?.amountToCollect, 
        totalAmount: total
      }
    })
  }

  const validationSchema = CardDetailsList;

  const { handleSubmit, handleChange, values, errors } = useFormik({
    initialValues: {
      firstName: state.firstName,
      lastName: state.lastName
    },
    validateOnBlur: true,
    validationSchema,
    onSubmit(values) {
      const { firstName, lastName } = values;
      if(firstName.trim() !== "" && lastName.trim() !== "") {
        checkout();
      }
    }
  });

  const checkIfAddressIsValid = () => {
    return (userBillingAddress?.countryCode) || 
          (additionalBillingAddress?.countryCode) ? true : false;
  }

  const checkIfPageIsValid = (stripe: Stripe | null, elements: StripeElements | null, cardElement: StripeCardElement | null) => {
    return stripe && elements && cardElement;
  }

  const taxIsValid = () => {
    return tax;
  }

  const checkout = async () => {
    resetValidationErrors();
    setProcessing(true);

    const cardElement = elements ? elements.getElement(CardElement) : null;
    if (!checkIfPageIsValid(stripe, elements, cardElement) || !taxIsValid()) {
      registerPaymentProcessError();
      return;
    }

    if(!checkIfAddressIsValid()) {
      displayAddressValidationError();
      return;
    }

    //create the payment intent
    await stripe!.createPaymentMethod({
      type: PaymentMethods.CARD,
      card: cardElement!
    }).then((result: any) => {
      if(result.error) {
        displayCardValidationError(result.error.message);
      } else {
        const allItems = cartItems.concat(cartItemsFromSub);
        //add order to db
        addOrderMutation(allItems);
      }
    }).catch((error: any) => {
      registerPaymentProcessError();
      return;
    })
  };

  const addOrderMutation = (items: ItemForCart[]) => {
    addOrder({
      variables: {
        contents: getContentIds(cartItems, cartItemsFromSub, tax, currentUser.role!),
        userId: currentUser.id,
        totalTax: tax ? tax.amountToCollect : 0
      }
    })
    .then((result: any) => {
      if (result.data.addOrder) {
        const orderId = result.data.addOrder.id;
        //after the order is done setting we will continue from the useEffect(sometimes orderId doesnt get set on time)
        setOrderId(orderId);
      } else {
        registerOrderHandlingError();
      }
    })
    .catch((error: any) => {
      registerOrderHandlingError();
    });
  }

  const updateLicensePlans = async () => {
    const subscribtionUpdateParams = getUpdateParamsForPlan(
      cartItemsFromSub,
      userLicensingPlans
    );

    if(subscribtionUpdateParams.length === 0) {
      setTimeout(() => {
        emptyCart();
        handlePageChange();
      }, 3000);
     
      broadcastCartAction({ severity: SeveritySnackbarEnum.success, message: `Payment successful. An invoice regarding your order will be sent to ${currentUser.email}.` });
      sendInvoiceForOrder();
      setProcessing(false);
    } else {
      const subscriptionPromises = subscribtionUpdateParams.map(async planUpdateParams => {
        return await updateCounts({
          variables: {
            userLicensingPlanId: planUpdateParams.userLicensingPlanId,
            numberOfItemsBought: planUpdateParams.itemCount
          }
        });
      });
  
      await Promise.all(subscriptionPromises)
        .then((result: any) => {
          setTimeout(() => {
            emptyCart();
            handlePageChange();
          }, 3000);
          setProcessing(false);
          broadcastCartAction({ severity: SeveritySnackbarEnum.success, message: `Payment successful. An invoice regarding your order will be sent to ${currentUser.email}.` });
          sendInvoiceForOrder();
        })
        .catch((resut: any) => {
          registerOrderHandlingError();
        })
    }
  }

  const sendInvoiceForOrder = () => {
    const standardLicenseItems = generateInvoiceTable(cartItems, tax);
    const subscriptionItems = generateInvoiceTableSubscription(cartItemsFromSub);
    const invoiceTemplateType = getInvoiceTemplateType(cartItems, cartItemsFromSub);

    sendInvoice(currentUser.email, standardLicenseItems, subscriptionItems, total, orderId, invoiceTemplateType, sendInvoiceMutation )
      .catch((error: any) => {
        registerInvoiceError();
      });
  }

  const confirmPayment = async () => {
    const cardElement = elements ? elements.getElement(CardElement) : null;
    if(cardElement) {
      const fullName = state.firstName + " " + state.lastName;
      const stripeAddressToken = createStripeAddressToken(fullName, useAnotherBillingAddress, additionalBillingAddress, userBillingAddress);
      const paymentToken = await stripe!.createToken(cardElement, stripeAddressToken)
        .catch((error: any) => {
          registerPaymentProcessError();
          return;
        });
      const amountInCents = Math.floor(total * 100);
  
      await stripePayment(paymentToken, amountInCents, "usd", currentUser!.email)
        .then((result: any) => {
          if(result.paid) {
            //confirm order, empty cart, stop processing
            confirmOrderMutation(orderId);
            setStripeTransactionDetails({id: result.id, date: new Date(result.created * 1000)});
          }
        }).catch((error: Error) => {
          registerStripeError(error.message);
        });
    } else {
      registerPaymentProcessError();
    }
  }

  const confirmOrderMutation = (orderId: number) => {
    confirmOrder({variables: {orderId: orderId}})
      .then((result: any) => {
        if(result) {
          updateLicensePlans();
        }
      }).catch((error: any) => {
        registerOrderHandlingError();
      })
  }

  const resetValidationErrors = () => {
    setAddressValidationError(false);
    setAddressError("");
    setCardValidationError(false);
    setCardError("");
  }

  const displayAddressValidationError = () => {
    setAddressValidationError(true);
    setProcessing(false);
    setAddressError("Invalid billing address. Make sure you have submitted a valid country and state.");
  }

  const displayCardValidationError = (error: string) => {
    setCardValidationError(true);
    setProcessing(false);
    setCardError(error);
  }

  const registerStripeError = (errorMessage: string) => {
    broadcastCartAction({ severity: SeveritySnackbarEnum.error, message: `Payment error: ${errorMessage}` });
    setProcessing(false);
  }

  const registerPaymentProcessError = () => {
    broadcastCartAction({ severity: SeveritySnackbarEnum.error, message: t("Cart.Snackbar.Unsuccessful") });
    setProcessing(false);
  }

  const registerOrderHandlingError = () => {
    broadcastCartAction({ severity: SeveritySnackbarEnum.error, message: t("Cart.Order.Unsuccessful") });
    setProcessing(false);
  }

  const registerInvoiceError = () => {
    broadcastCartAction({ severity: SeveritySnackbarEnum.error, message: "Failed to generate invoice for this purchase. Contact the WorldIllustrated team in order to get your invoice." });
    setProcessing(false);
  }

  const toogleUseAnotherBillingAddress = () => {
    setUseAnotherBilling(!useAnotherBillingAddress);
  }

  return (
    <div className="cart-payment-container">
      {billingLoading ?
        <div className="loading-box">
          <SyncLoader css={`display: block; margin: 0 auto; border-color: red;`} size={20}
            color={"#36D2B3"} loading={billingLoading} />
        </div>
        : null}
        
        {/* Uncomment when implemented */}
        {/* <button className="cart-paypal-checkout"><h2>Checkout with PayPal</h2></button> */}

        {/* BILLING ADDRESS */}
        {useAnotherBillingAddress ?
          <Grow in={true}>
            <AddressComponent isInCheckout={true}
              userRole={currentUser.role!} addressType={AddressTypes.BILLING} />
          </Grow>
          :
          <Grow in={true}>
            <Accordion expanded={true}>
              <AccordionSummary aria-controls="panel1c-content" id="panel1c-header">
                <div className="title">{t("Onboarding.Address.Billing.Address.Heading")}</div>
              </AccordionSummary>
              <div className="accordion-container">
                <TextField
                  fullWidth
                  autoComplete="off"
                  id="country-input"
                  disabled={true}
                  label={t("Onboarding.Country.Label")}
                  name="country"
                  value={userBillingAddress ? userBillingAddress.country : ''}
                />
                <TextField
                  fullWidth
                  autoComplete="off"
                  id="province-input"
                  disabled={true}
                  label={t("Onboarding.State.Label")}
                  name="state"
                  value={userBillingAddress ? userBillingAddress.state : ''}
                />
                <TextField
                  fullWidth
                  autoComplete="off"
                  id="city-input"
                  disabled={true}
                  label={t("Onboarding.City.Label")}
                  name="city"
                  value={userBillingAddress ? userBillingAddress.city : ''}
                />
                <TextField
                  fullWidth
                  autoComplete="off"
                  id="zipCode-input"
                  disabled={true}
                  label={t("Onboarding.ZipCode")}
                  name="zipCode"
                  value={userBillingAddress ? userBillingAddress.zipCode : ''}
                />
                <TextField
                  fullWidth
                  autoComplete="off"
                  id="addressLine1-input"
                  disabled={true}
                  label={t("Onboarding.Address.AddressLine1")}
                  name="addressLineOne"
                  value={userBillingAddress ? userBillingAddress.addressLineOne : ''}
                />
                <TextField
                  fullWidth
                  autoComplete="off"
                  id="addressLine2-input"
                  disabled={true}
                  label={t("Onboarding.Address.AddressLine2")}
                  name="addressLineTwo"
                  value={userBillingAddress ? userBillingAddress.addressLineTwo : ''}
                />
              </div>
            </Accordion>
          </Grow>
        }

        {showAddressValidationError ? 
          <Typography variant="body2" gutterBottom color="error">
            {addressValidationError}
          </Typography>
        : null}

        <Grow in={true}>
          <button onClick={() => toogleUseAnotherBillingAddress!()} className="back-button">
            <span className="button-text">
              {useAnotherBillingAddress ? t("Cart.Billing.Cancel.Billing") : t("Cart.Billing.Add.Another")}
            </span>
          </button>
        </Grow>

      <form onSubmit={handleSubmit}>
        {/* USE A CREDIT/DEBIT CARD */}
        <div className="details-container">
          <div className="detail"> 
            {selectedPaymentMethod === PaymentMethods.CARD ?
              <Grow in={true}>
                <Accordion expanded={true} classes={{root: classes.MuiAccordionRoot}}>
                  <AccordionSummary id="panel1c-header">
                    <div className="title">
                      {t("Choose.Card.Type")}
                    </div>
                  </AccordionSummary>
                  <div className="accordion-container">
                    <TextField
                      autoComplete="off"
                      type="text"
                      label="First name"
                      name="firstName"
                      value={values.firstName}
                      onChange={handleChange}
                      helperText={errors.firstName ? errors.firstName : null}
                    />
                    <TextField
                      autoComplete="off"
                      type="text"
                      label="Last name"
                      value={values.lastName}
                      name="lastName"
                      onChange={handleChange}
                      helperText={errors.lastName ? errors.lastName : null}
                    />

                    <CardElement options={cardElementOptions} />

                    {showCardValidationError ? 
                      <Typography variant="body2" gutterBottom color="error">
                        {cardValidationError}
                      </Typography>
                    : null}
                  </div>
                </Accordion>
              </Grow>
            : null}
          </div>
         
          {/* ORDER SUMMARY */}
          <div className="detail">
            <OrderSummary
              loading={taxLoading}
              userLicensingPlans={userLicensingPlans} />
          </div>
        </div>

        <div className="cart-step-navigation">
          <button className='back-button'
            onClick={handlePageBack}>
            <span className="button-text">
              <ArrowBackIcon /> {t("Back.Button")}
            </span>
          </button>

          <button type="submit" className="pay-button" disabled={processing}>
            {processing ?
              <PulseLoader css={`display: block; margin: 0 auto;`} size={10} margin={3}  
              color={"#EBEBEB"} loading={processing}/>
            : 
            "Complete checkout"}
          </button>
        </div>
      </form>
    </div>
  );
};
const mapStateToProps = (state: StoreState): {
  currentUser: User, cartItems: ItemForCart[]; cartItemsFromSub: ItemForCart[];
  total: number; tax: TaxForOrder | null; additionalBillingAddress: Addresses | null
} => {
  return {
    currentUser: selectCurrentUser(state),
    cartItems: selectCurrentCartItems(state),
    cartItemsFromSub: selectCurrentSubItems(state),
    total: selectCurrentCartTotal(state),
    tax: selectTax(state),
    additionalBillingAddress: selectBillingAddress(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch<TCartReducerActions>) => {
  return {
    emptyCart: () => dispatch<IEmptyCart>({ type: CartActionTypes.EMPTY_CART }),
    addTaxAction: (data: TaxForOrder) => dispatch<IAddTaxForOrder>({ type: CartActionTypes.ADD_TAX, data: data }),
    broadcastCartAction: (data: IBroadcastMessage) => dispatch<IBroadcastCartError>({
      type: CartActionTypes.BROADCAST_CART_ERROR, data: data
    })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CartPayment);
