import { Skeleton } from '@mui/material'
import React from 'react'

const CommentSkeleton: React.FC<any> = () => {
  return (
    <div className="comment-skeleton">
          <div className="comment-skeleton-user">
            <Skeleton variant="circular" width={40} height={40} />
            <Skeleton variant="rectangular" width={100} height={10} />
          </div>
          <p><Skeleton variant="rectangular" width={300} height={20} /></p>
          <p><Skeleton variant="rectangular" width={200} height={20} /></p>
        </div>
  )
}

export default CommentSkeleton;