import React, { useState } from "react";
import { AddToCartButtonProps, EditorialSizes, ISBN_REGEX, ItemForCart } from "./photo-details.types";
import { useTranslation } from "react-i18next";
import { checkEnumVal } from "./photo-details-containers";
import { Dispatch } from "redux";
import { SnackbarComponent } from "shared";
import { connect } from "react-redux";
import { IAddToCart, IAddToSubscriptionCart, TCartReducerActions } from "../../redux/cart/cart-page.actions";
import { CartActionTypes } from "../../redux/cart/cart-page.types";
import { StoreState } from "../../redux/root-reducer";
import { selectCurrentCartItems, selectCurrentSubItems } from "../../redux/cart/cart-page.selector";
import './photo-details.styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartPlus } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "@mui/material";

const AddToCartButton: React.FC<AddToCartButtonProps> = ( { ...props } ) => {
  const { item, size, isPartOfPlan,currentSubItems, currentCartItems, addToCartAction, addToSubscriptionCartAction, iconOnly=false } = props;
  const [showAddToCartSnackbar, setshowAddToCartSnackbar] = useState( false );
  const [invalidISSNSnackbar, setInvalidISSNSnackbar] = useState( false );
  const [invalidISBNSnackbar, setInvalidISBNSnackbar] = useState( false );
  const { t } = useTranslation();


  const addToCartHandler = () => {
    let editorialSize = checkEnumVal(EditorialSizes, size!);

    let contentToAdd: ItemForCart = {
      content: item.content,
      editorialSize: editorialSize,
      flatRate: item.flatRate,
      isbn: item.isbn,
      issn: item.issn
    };

    // TODO: Uncomment when it is decided
    // if(item.isbn && item.isbn !== "" &&  !ISBN_REGEX.test(item.isbn))
    // {
    //   setInvalidISBNSnackbar(true)
    // }
    // else if(item.issn !== "" && !issn(item.issn)    )
    // {
    //   setInvalidISSNSnackbar(true)
    // }

    if (isPartOfPlan) {
      addToSubscriptionCartAction(contentToAdd);
      setshowAddToCartSnackbar(true);
    } else {
      addToCartAction( contentToAdd );
      setshowAddToCartSnackbar( true );
    }

  };

  const handleClose = () => {
    setshowAddToCartSnackbar( false );
    setInvalidISSNSnackbar( false );
    setInvalidISBNSnackbar( false );
  };

  return (
    <span>
      {iconOnly ? <FontAwesomeIcon className='add-to-cart-icon-button' icon={faCartPlus} onClick={() => {
          addToCartHandler();
        }}/> : <button
        className={`${ isPartOfPlan ? "subscriptio-add-to-cart" : "add-to-cart"
          }`}
        onClick={() => {
          addToCartHandler();
        }}
      >
        {t( "Photo.Details.Add.To.Cart" )}
      </button>}
      <SnackbarComponent
        showSnackbar={showAddToCartSnackbar}
        handleClose={handleClose}
        severity="success"
        message="Added to cart"
      />
      <SnackbarComponent
        showSnackbar={invalidISSNSnackbar}
        handleClose={handleClose}
        severity="error"
        message="Invalid ISSN number"
      />
      <SnackbarComponent
        showSnackbar={invalidISBNSnackbar}
        handleClose={handleClose}
        severity="error"
        message="Invalid ISBN number"
      />
    </span>
  );
};


const mapStateToProps = (state: StoreState): {
  currentSubItems: any; currentCartItems: any;
} => {
  return {
    currentSubItems: selectCurrentSubItems(state),
    currentCartItems: selectCurrentCartItems(state),
  };
};

const mapDispatchToProps = ( dispatch: Dispatch<TCartReducerActions> ) => {
  return {
    addToCartAction: ( item: ItemForCart ) =>
      dispatch<IAddToCart>( { type: CartActionTypes.ADD_TO_CART, data: item } ),
    addToSubscriptionCartAction: ( item: ItemForCart ) =>
      dispatch<IAddToSubscriptionCart>( {
        type: CartActionTypes.ADD_TO_SUBSCRIPTION_CART,
        data: item
      } )
  };
};

export default connect( mapStateToProps, mapDispatchToProps )( AddToCartButton );
