import { RoleTypes } from "../../components/register/register.types";
import { User } from "../user/user.types";
import { Addresses, AppropriateChannels, EditorsInformation, GeneralInformationCompany, GeneralInformationPerson, Licensing, OnboardingState, OnboardingValidation, PersonResponsibleForAccount } from "./onboarding.types";

export const addEditorsInformation = (editors: EditorsInformation[], newEditors: EditorsInformation[]) => {
    return editors.concat(newEditors);
}

const isContributor = [RoleTypes.Contributor_individual.toString(), RoleTypes.Contributor_agency.toString()];
const isProMember = [RoleTypes.Pro_freelancer.toString(), RoleTypes.Pro_organization.toString()];

const isEmpty = (input?: string) => {
    return !input || (typeof (input) === 'string' && input.trim().length === 0);
}

export const validateOnboardingData = (state: OnboardingState, currUser: User) => {
    let resultsArray: OnboardingValidation[] = [];
    if (isContributor.includes(currUser.role!)) {
        //user is contributor
        const genInfoResult = validateGeneralInformation(state.generalInformationCompany, state.generalInformationPerson, currUser.role!);
        const resPersonResult = validateResponsiblePerson(state.personResponsibleForAccount, currUser.role!);
        const addrOneResult = validateAddress(state.mailingAddress);
        const addrTwoResult = validateAddress(state.billingAddress);
        const licensingResult = validateLicensing(state.licensing);
        const channelsResult = validateChannels(state.appropriateChannels);

        resultsArray = [genInfoResult, resPersonResult, addrOneResult, addrTwoResult, licensingResult, channelsResult];
    } else if (isProMember.includes(currUser.role!)) {
        //user is pro member
        const genInfoResult = validateGeneralInformation(state.generalInformationCompany, state.generalInformationPerson, currUser.role!);
        const resPersonResult = validateResponsiblePerson(state.personResponsibleForAccount, currUser.role!);
        const addrOneResult = validateAddress(state.mailingAddress);
        const addrTwoResult = validateAddress(state.billingAddress);

        resultsArray = [genInfoResult, resPersonResult, addrOneResult, addrTwoResult];
    } else {
        return { status: false, validationMessage: "Invalid User role." };
    }

    if (resultsArray.some((result: OnboardingValidation) => result.status === false)) {
        const falseResults = resultsArray.filter((result: OnboardingValidation) => result.status === false);
        const falseResultsMessages = falseResults.map((result: OnboardingValidation) => {
            return result.validationMessage;
        })
        return { status: false, validationMessage: falseResultsMessages.join("\n") };
    } else {
        return { status: true, validationMessage: "" };
    }
}

const validateGeneralInformation = (genInfoCompany: GeneralInformationCompany, genInfoPerson: GeneralInformationPerson, userRole: string): OnboardingValidation => {
    let validationResult = false;

    if (userRole === RoleTypes.Contributor_individual) {
        validationResult = !isEmpty(genInfoPerson.firstName) &&
            !isEmpty(genInfoPerson.lastName) &&
            genInfoPerson.yearOfBirth > 1900;
    } else if (userRole === RoleTypes.Contributor_agency) {
        validationResult = !isEmpty(genInfoCompany.organizationName);
    } else if (userRole === RoleTypes.Pro_freelancer) {
        validationResult = !isEmpty(genInfoPerson.firstName) &&
            !isEmpty(genInfoPerson.lastName) &&
            genInfoPerson.yearOfBirth > 1900 &&
            !isEmpty(genInfoPerson.emailAccounting) &&
            !isEmpty(genInfoPerson.emailNewsletter);
    } else if (userRole === RoleTypes.Pro_organization) {
        validationResult = !isEmpty(genInfoCompany.organizationName) &&
            !isEmpty(genInfoCompany.companyType) &&
            !isEmpty(genInfoCompany.contentType) &&
            !isEmpty(genInfoCompany.emailAccounting) &&
            !isEmpty(genInfoCompany.emailNewsletter);
    }

    return validationResult ? { status: true, validationMessage: "" } : { status: false, validationMessage: "Invalid user data in 'General information'." };
}

const validateResponsiblePerson = (responsibleAccount: PersonResponsibleForAccount, userRole: string): OnboardingValidation => {
    let validationResult = false;
    if (userRole === RoleTypes.Contributor_agency) {
        validationResult = !isEmpty(responsibleAccount.firstName) &&
            !isEmpty(responsibleAccount.lastName) &&
            !isEmpty(responsibleAccount.email) &&
            !isEmpty(responsibleAccount.phoneNumber);
    }
    else {
        validationResult = !isEmpty(responsibleAccount.firstName) &&
            !isEmpty(responsibleAccount.lastName) &&
            !isEmpty(responsibleAccount.jobTitle) &&
            !isEmpty(responsibleAccount.email) &&
            !isEmpty(responsibleAccount.phoneNumber);
    }
    return validationResult ? { status: true, validationMessage: "" } : { status: false, validationMessage: "Invalid user data in 'Person responsible for this account'." };
}

const validateAddress = (address: Addresses): OnboardingValidation => {
    const validationResult = !isEmpty(address.country) &&
        !isEmpty(address.countryCode) &&
        !isEmpty(address.state) &&
        !isEmpty(address.stateCode) &&
        !isEmpty(address.city) &&
        !isEmpty(address.zipCode);

    return validationResult ? { status: true, validationMessage: "" } : { status: false, validationMessage: "Invalid address format filled. Please fill all the required fields." };
}

const validateLicensing = (licensing: Licensing): OnboardingValidation => {
    const validationResult = !isEmpty(licensing.prefferedCurrency);

    return validationResult ? { status: true, validationMessage: "" } : { status: false, validationMessage: "Invalid user data in 'Licensing'." };
}

const validateChannels = (channels: AppropriateChannels): OnboardingValidation => {
    const validationResult = channels.channels.length > 0;

    return validationResult ? { status: true, validationMessage: "" } : { status: false, validationMessage: "No channels selected for your content." };
}

export const validateEditorsAccordion = (editor: EditorsInformation): OnboardingValidation => {
    const validationResult = !isEmpty(editor.firstName) &&
        !isEmpty(editor.lastName) &&
        !isEmpty(editor.title) &&
        !isEmpty(editor.phoneNumber) &&
        !isEmpty(editor.email);

    return validationResult ? { status: true, validationMessage: "" } : { status: false, validationMessage: "You must fill all data to add another editor." }
}