import React from 'react';
import {Dialog, Backdrop, Fade} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {wrapperStyles,RegisterModalWraperProps} from "./register-modal-wraper.types"
import {StoreState} from "../../redux/root-reducer";
import {connect} from "react-redux";
import "./register-modal-wraper.styles.scss"
import {IToggleRegisterWraperHidden, TRegisterModalWraperReducerActions} from "../../redux/register-modal-wraper/register-modal-wraper.actions";
import {RegisterWraperActionTypes} from "../../redux/register-modal-wraper/register-modal-wraper.types";
import {Dispatch} from "redux";
import {TModalReducerActions, IToggleRegister, IToggleRegisterAsRole} from "../../redux/modal-visibility/modal.actions";
import {useTranslation} from "react-i18next";
import { ModalActionTypes } from "../../redux/modal-visibility/modal.types";
import {TSignInBannerReducerActions,ISetRegisterType} from "../../redux/sign-in-banner/sign-in-banner.actions";
import {BannerActionTypes} from "../../redux/sign-in-banner/sign-in-banner.types";

const RegisterModalWraper: React.FC<RegisterModalWraperProps> = ({...props}) => {
    const {banner, toggleBanner, toggleRegister,setRegisterType,toggleRegisterAsRole} = props;
    const { t } = useTranslation();
    const classes= wrapperStyles();
    const divStyle = {
        backgroundImage: 'url("https://images.unsplash.com/photo-1547333041-8c938965d679?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80")'
    }

    const handleClick = () =>
    {
        toggleBanner(true)
    }
    const handleOpenRegister = (e:any) => {
        toggleRegister();
        toggleBanner(true);
        setRegisterType(e.target.value);
    }
    return(
        <React.Fragment>
                <Dialog 
                    style={{zIndex: 10000}}
                    classes={{paper: classes.dialogPaper, paperWidthSm: classes.dialogPaperWidthSm}}
                    closeAfterTransition={true} onClose={handleClick} open={!banner} aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description" BackdropComponent={Backdrop}
                    BackdropProps={{ timeout: 800 }}>
                    <Fade in={!banner}>
                        <div className="register-wraper-container">
                             <button className='close-button' aria-label='google' onClick={handleClick}>
                                <FontAwesomeIcon className='icon-button' icon={faTimes}/>
                            </button>
                                <h1>world illustrated</h1>
                                <div className="sign-in-buttons">
                                    <div className="button-wraper">
                                        <div className="label register-label"> {t("Register.Wrapper.RegisterAs")}</div>
                                        {/*TODO:uncomment when clients want member functionalities
                                        <button className="button-styles" value="member" onClick={handleOpenRegister}>{ t('RegisterAsMember.button') }</button>
                                        <div className="label">{t("Register.Wrapper.Member.Message")}</div>*/}
                                    </div>
                                    <div className="button-wraper">
                                        <button className="button-styles" value="pro" onClick={handleOpenRegister}>{ t("Register.Wrapper.Pro.Button") }</button>
                                        <div className="label">{t("Register.Wrapper.Pro.Message")}</div>
                                    </div>
                                    <div className="button-wraper">
                                        <button className="button-styles" value="contributor" onClick={handleOpenRegister}>{ t('Register.Wrapper.Contributor.Button') }</button>
                                        <div className="label">{t("Register.Wrapper.Contributor.Message")}</div>
                                    </div>
                                </div>
                            </div>
                    </Fade>
                </Dialog>
      </React.Fragment>
    )
}
const mapStateToProps = (state: StoreState): {banner:boolean } => {
    return {
        banner: state.registerWraper.hidden
    }
}

const mapDispatchToProps = (dispatch: Dispatch<TRegisterModalWraperReducerActions | TModalReducerActions | TSignInBannerReducerActions>) => {
    return {
        toggleBanner: (show:boolean) => dispatch<IToggleRegisterWraperHidden>({type: RegisterWraperActionTypes.TOGGLE_REGISTER_WRAPER_HIDDEN, data:show}),
        toggleRegister: () => dispatch<IToggleRegister>({type: ModalActionTypes.ToggleRegisterModal}),
        setRegisterType: (registerType:string) => dispatch<ISetRegisterType>({type: BannerActionTypes.SET_REGISTER_TYPE, data:registerType}),
        toggleRegisterAsRole:()=>dispatch<IToggleRegisterAsRole>({type:ModalActionTypes.ToggleRegisterAsRoleModal})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterModalWraper);