import React, { useEffect, useState } from 'react';
import { ContentItemProps, ContentTypes } from "./content.types";
import { Dispatch } from "redux";
import { push, CallHistoryMethodAction } from "connected-react-router";
import { StoreState } from "redux/root-reducer";
import { GET_CH_IDS } from "./queries";
import Moment from "react-moment";
import { connect } from 'react-redux';
import { TPhotoDetailsActions } from '../../redux/photo-details/photo-details.actions'
import { PhotoDetailsActionTypes, } from '../../redux/photo-details/photo-details.types'
import { generateChannelColorArray } from '../channelScreen/helperFunctions';
import variables from '../../assets/globalVariables.module.scss';
import { composePathChannels, composePathSubChannles, composePathThreads } from '../channelsSidebar/helperFunctions';
import { ChannelActionTypes } from '../../redux/channel-routes/channel.types';
import { IAddChannel, IAddSubChannel, IAddThread, IRemoveChannel, IRemoveSubChannel, IRemoveThread, TChannelReducerActions } from '../../redux/channel-routes/channel.actions';
import { useLazyQuery, useMutation } from 'react-apollo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpen, faPhotoVideo, faImage } from '@fortawesome/free-solid-svg-icons';
import { selectCurrentChannel, selectCurrentSubChannel, selectCurrentThread } from "../../redux/channel-routes/channel.selectors";
import { Channel } from "../home-component/home.types";
import { SmoothImageRender, SnackbarComponent } from 'shared';
import './contentItem.styles.scss';
import '../onboarding/onboarding-page.styles.scss';
import { selectChannelFilter, selectSubchannelFilter, selectThreadFilter } from '../../redux/search-bar/search-bar.selectors';
import { SubChannel, Thread } from '../channelsSidebar/channelSideBars.types';
import { ContentNavigation } from '../contributor-content/contributor-content.types';
import { faCartPlus, faDownload } from "@fortawesome/free-solid-svg-icons";
import { CHECK_IS_CONTENT_OWNED, GET_LICENSE_PLAN_BY_USER_ID_AND_TYPE } from '../../components/photo-details/queries';
import { downloadContent } from '../../components/utils/download';
import { selectCurrentUser } from '../../redux/user/user.selectors';
import { User } from '../../redux/user/user.types';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@material-ui/icons/Close';
import EditorialPriceBoxContent from '../../components/photo-details/photo-container/editorialPriceBoxContent';
import { ItemForCart } from '../../components/photo-details/photo-details.types';
import { checkIfPlanHasAvailableAmount } from '../../components/photo-details/photo-details-helper-functions';
import { selectCurrentCartItems, selectCurrentSubItems } from '../../redux/cart/cart-page.selector';
import { IBroadcastMessage, SeveritySnackbarEnum } from '../../components/batch-upload/ContentUpload.types';
import { IAddToCart, IAddToSubscriptionCart } from '../../redux/cart/cart-page.actions';
import { CartActionTypes } from '../../redux/cart/cart-page.types';
import { TCartReducerActions } from '../../redux/cart/cart-page.actions';
import { IBroadcastLightBoxMessage, IClearBroadcast, TLightBoxActions } from '../../redux/lightbox/lightbox.actions';
import { LightBoxActionTypes } from '../../redux/lightbox/lightbox.types';
import { selectLightBoxBroadcast } from '../../redux/lightbox/lightbox.selector';
import { IBroadcastAddToCartMessage, IBroadcastSearchMessage, TSearchReducerActions } from '../../redux/search-bar/search-bar.actions';
import { SearchBarActions } from '../../redux/search-bar/search-bar.types';
import { useQuery } from 'react-apollo-hooks';
import { UserLicensingPlan } from '../../components/my-license-page/my-license-page.types';
import { useTranslation } from 'react-i18next';

const ContentItem: React.FC<ContentItemProps> = ({ ...props }) => {
    const { item, selectedChannel, selectedThread, selectedSubChannel, 
        channelFilter, subchannelFilter, threadFilter, path, currentUser, currentCartItems, cartItemsFromSub,
        selectSubChannelAction, selectThreadAction, redirectToChannel,
        redirectToPhotoDetals, setPhotoDetailsState, selectChannelAction, addToSubscriptionCartAction,
        redirectToSubChannel, redirectToThread, broadCastMessage, addToCartAction, clearBroadcast, isInSearch } = props
    const [getIds] = useMutation(GET_CH_IDS);
    const [colors, setColors] = useState<string[]>([]);
    const [itemChannel, setItemChannel] = useState<Channel>({id: -1, title: "", code: ""});
    const [itemSubChannel, setItemSubchannel] = useState<SubChannel>({id: -1, title: ""});
    const [itemThread, setItemThread] = useState<Thread>({id: -1, title: ""});
    const [showEditorialModal, setShowEditorialModal] = useState(false);
    const [isPartOfPlan, setIsPartOfPlan] = useState(false);
    const [licensingPlan, setLicensingPlan] = useState<UserLicensingPlan[]>([]);
    const { t } = useTranslation();

    const { data: planData, loading: planLoading, error: planError } = useQuery(GET_LICENSE_PLAN_BY_USER_ID_AND_TYPE,{
        variables: {
          userId: currentUser.id,
          licensingType: item.content.licensingType,
        },
      });
    const [
        fetchUserLicensePlans,
        { data: licensePlan, loading: licensePlanLoading, error: licensePlanError },
      ] = useLazyQuery(GET_LICENSE_PLAN_BY_USER_ID_AND_TYPE, {
        fetchPolicy: "cache-and-network",
        onCompleted: (res: any)=>{
        const licensingPlan = res?.getPlanByIdAndType[0];
        const contentToAdd: ItemForCart = {
            content: {
                ...item.content,
                amount:
                licensingPlan ? licensingPlan.plan.amount : undefined,
                totalPrice:
                licensingPlan
                    ? licensingPlan.plan.totalPrice
                    : undefined,
                period:
                licensingPlan && licensingPlan.plan.period
                    ? licensingPlan.plan.period
                    : undefined,
                role: currentUser.role,
                type: 'image'
            },
            // editorialSize: editorialSize,
            flatRate: item?.contentRate?.flatRate,
            };
    
            if (
            licensingPlan &&
            checkIfPlanHasAvailableAmount(
                currentCartItems,
                licensingPlan.amountLeft!,
                licensingPlan.plan.licensingType!
            )
            ) {
                addToSubscriptionCartAction(contentToAdd);
                broadCastMessage({
                severity: SeveritySnackbarEnum.success,
                message: "Added to cart",
                });
            } else {
            addToCartAction(contentToAdd);
            broadCastMessage({
                severity: SeveritySnackbarEnum.success,
                message: "Added to cart",
            });
            }
        }
      });
    
    const handleDownloadError = ()=>{
        broadCastMessage({ severity: SeveritySnackbarEnum.error, message: t("Error.Download.Content") })
    }

    const handleDownload = () => {
        if(currentUser?.token){
            downloadContent({
                purchasedImages: [item.content.id],
                token: currentUser.token,
                fileName: "wi_image",
                onError: handleDownloadError
            });
        }
    };
    const handleClick = () => {
        setPhotoDetailsState(item);
        let path = item.content.id.toString() + '/thread/' + itemThread.id;
        if (item.type === ContentTypes.ARTICLE) {
            path = `article/${item.articleId}/set/${item.contentSetId}/${item.content.id}/thread/${itemThread.id}`;
        }
        else if (item.type === ContentTypes.CONTENT_SET) {
            path = `set/${item.contentSetId}/${item.content.id}/thread/${itemThread.id}`;
        }
        redirectToPhotoDetals(path);
    }

    useEffect(() => {
        if (planData && planData.getPlanByIdAndType) {
          setLicensingPlan(planData.getPlanByIdAndType);
        }
      }, [planData]);

    useEffect(() => {
        if (
          item &&
          licensingPlan.length > 0 &&
          checkIfPlanHasAvailableAmount(
            cartItemsFromSub,
            licensingPlan[0]?.amountLeft!,
            licensingPlan[0]?.plan?.licensingType!
          )
        ) {
          setIsPartOfPlan(true);
        } else {
          setIsPartOfPlan(false);
        }
      }, [licensingPlan, item, cartItemsFromSub]);

    useEffect(() => {
        setColors(generateChannelColorArray(itemChannel.code, variables))
    }, [itemChannel]);

    useEffect(() => {
        //logic here needs changing
        let navigations: ContentNavigation[] = [];

        if(isInChannelContext()) {
            if(selectedChannel.id >= 1) {
                navigations = item.contentNavigations.filter((nav: ContentNavigation) => {return nav.channel.id === selectedChannel.id});
            }

            if(selectedSubChannel.id >= 1) {
                navigations = item.contentNavigations.filter((nav: ContentNavigation) => {return nav.subChannel.id === selectedSubChannel.id});
            }

            if(selectedThread.id >= 1) {
                navigations = item.contentNavigations.filter((nav: ContentNavigation) => {return nav.thread.id === selectedThread.id});
            }
        } else if(channelFilter.id) {
            if(channelFilter.id >= 1) {
                navigations = item.contentNavigations.filter((nav: ContentNavigation) => {return nav.channel.id === channelFilter.id});
            }

            if(subchannelFilter.id >= 1) {
                navigations = item.contentNavigations.filter((nav: ContentNavigation) => {return nav.subChannel.id === subchannelFilter.id});
            }

            if(threadFilter.id >= 1) {
                navigations = item.contentNavigations.filter((nav: ContentNavigation) => {return nav.thread.id === threadFilter.id});
            }
        } else {
            navigations = item.contentNavigations;
        }

        if(navigations.length > 0) {
            setItemChannel(navigations[0].channel);
            setItemSubchannel(navigations[0].subChannel);
            setItemThread(navigations[0].thread);
        }
    }, []);

    const channelsOnClick = () => {
        redirectToChannel(itemChannel.title);
        selectChannelAction(itemChannel);
    }

    const subChannelsOnClick = () => {
        redirectToSubChannel(itemChannel.title, itemSubChannel.title);
        selectChannelAction(itemChannel);
        selectSubChannelAction(itemSubChannel);
    }

    const threadsOnClick = () => {
        redirectToThread(itemChannel.title, itemSubChannel.title, itemThread.title);
        selectChannelAction(itemChannel);
        selectSubChannelAction(itemSubChannel);
        selectThreadAction(itemThread);
    }

    const userIsAllowedToPurchase = (): boolean => {
        if (currentUser?.id) {
          return (
            currentUser?.role === "pro_freelancer" ||
            currentUser?.role === "pro_organization"
          );
        }
        return false;
      };

    const getChannelColor = (code: string) => {
        if (code) {
            return code.concat('-add');
        }
        else {
            return "search-add";
        }

    }

    const getLocationString = (location: any) => {
        let locationStr = '';
        if(location.country.country) {
            locationStr += `${location.country.country ? location.country.country : ""}`;
            locationStr += `${location.province.stateProvince ? ", " + location.province.stateProvince: ""}`;
            locationStr += `${location.city.city ? ", " + location.city.city : ""}`;
        } else {
            locationStr += "Location: N/A";
        }
        
        return locationStr;
    }
    
    const isInChannelContext = () => {
        return path.indexOf("/channels") > -1;
    }

    const handleAddToCart = ()=>{
        if (item?.content?.licensingType === "editorial") {
            setShowEditorialModal(true);
        } else {
            if(item.content.isOwned){
                handleDownload();
            }
            else{
                fetchUserLicensePlans({
                    variables: {
                      userId: currentUser?.id,
                      licensingType: item?.content?.licensingType,
                    },
                  })

            }
        }
    }

    const handleEditorialModalClose = () => {
        setShowEditorialModal(false);
    };

    return (
        <div className='container'>
            <div className='image-container'>
                <div className="icons">
                    {item.type === ContentTypes.ARTICLE ?
                        <button className={`icon-button ${getChannelColor(selectedChannel.code)}`}>
                            <FontAwesomeIcon className='fbColor' icon={faBookOpen}/>
                        </button>
                        : null
                    }
                    {item.type === ContentTypes.CONTENT_SET ?
                        <button className={`icon-button ${getChannelColor(selectedChannel.code)}`}>
                            <FontAwesomeIcon className='fbColor' icon={faPhotoVideo}/>
                        </button>
                        : null
                    }
                    {item.type === ContentTypes.IMAGE ?
                        <button className={`icon-button ${getChannelColor(selectedChannel.code)}`}>
                            <FontAwesomeIcon className='fbColor' icon={faImage}/>
                        </button>
                        : null
                    }
                </div>
                <div className='item-image'>
                    <SmoothImageRender src={item.content.pathToFileThumbnail} alt={item.content.headline} showAddToCartButton />
                    <button style={{backgroundColor: colors[0]}} className='view-set-button' onClick={handleClick}>View Set</button>
                </div>

                <div className="buttonContainer">
                    <div className='image-infotip'>
                        {userIsAllowedToPurchase() && <div className='infotip-container' >
                            {item?.content?.isOwned ? <FontAwesomeIcon style={{backgroundColor: colors[0]}} className='cart-icon-button' icon={faDownload} onClick={handleAddToCart}/> : currentUser?.id && (item.contentRate || isPartOfPlan || item.content.licensingType === 'editorial') && <FontAwesomeIcon style={{backgroundColor: colors[0]}} className='cart-icon-button' icon={faCartPlus} onClick={handleAddToCart}/>}
                        </div>}
                    </div>
                    {
                        isInSearch ?
                            <div className={!isInSearch ? "content-headline-none" : 'image-infotip-headline'}>
                                <div className={!isInSearch ? "content-headline-none" : 'content-headline-search'}>
                                    {
                                        item.type !== ContentTypes.CONTENT_SET && item.type !== ContentTypes.ARTICLE ?
                                            (item.content.headline && item.content.headline.length < 50 ? decodeURI(item.content.headline) : decodeURI(item.content.headline.substring(0, 45)) + '...') :
                                            (item.title && item.title.length < 50 ? item.title : item.title!.substring(0, 45) + '...')
                                    }
                                </div>
                            </div> : null
                    }
                </div>
            </div>
            <div>
                {
                    !isInSearch ?item.type !== ContentTypes.CONTENT_SET && item.type !== ContentTypes.ARTICLE ?
                        (item.content.headline && item.content.headline.length < 50 ? item.content.headline : item.content.headline.substring(0, 45) + '...') :
                        (item.title && item.title.length < 50 ? item.title : item.title!.substring(0, 45) + '...') : null

                }
            </div>
            <Dialog
                open={showEditorialModal}
                closeAfterTransition={true}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>
                <b>Select sizes</b>
                <IconButton
                    aria-label="close"
                    onClick={handleEditorialModalClose}
                    sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                </DialogTitle>
                <DialogContent>
                <EditorialPriceBoxContent contentInfo={item?.content} />
                </DialogContent>
            </Dialog>
        </div>
    );
}
const mapStateToProps = (state: StoreState): {
    photoDetails: any;
    selectedChannel: Channel; selectedThread: SubChannel; selectedSubChannel: Thread; cartItemsFromSub: ItemForCart[];
    channelFilter: Channel; subchannelFilter: SubChannel; threadFilter: Thread; path: string; currentUser: User; currentCartItems: ItemForCart[]; 
} => {
    return {
        currentUser: selectCurrentUser(state),
        photoDetails: state.photoDetails,
        selectedChannel: selectCurrentChannel(state),
        selectedSubChannel: selectCurrentSubChannel(state),
        selectedThread: selectCurrentThread(state),
        channelFilter: selectChannelFilter(state),
        subchannelFilter: selectSubchannelFilter(state),
        threadFilter: selectThreadFilter(state),
        path: state.router.location.pathname,
        currentCartItems: selectCurrentCartItems(state),
        cartItemsFromSub: selectCurrentSubItems(state),
    }
}
const mapDispatchToProps = (dispatch: Dispatch<CallHistoryMethodAction | TPhotoDetailsActions | TChannelReducerActions | TCartReducerActions | TLightBoxActions | TSearchReducerActions>) => {
    return {
        redirectToPhotoDetals: (path: string) => dispatch(push(`/content/${path}`)),
        setPhotoDetailsState: (item: any) => dispatch<TPhotoDetailsActions>({
            type: PhotoDetailsActionTypes.STORE_PHOTO_DETAILS,
            data: item
        }),
        redirectToSubChannel: (channel: string, subChannel: string) => dispatch(push(`/channels/${composePathSubChannles(channel, subChannel)}`)),
        redirectToThread: (channel: string, subChannel: string, thread: string) => dispatch(push(`/channels/${composePathThreads(channel, subChannel, thread)}`)),
        redirectToChannel: (channel: string) => dispatch(push(`/channels/${composePathChannels(channel)}`)),
        selectChannelAction: (channel: Channel) => dispatch<IAddChannel>({
            type: ChannelActionTypes.ADD_SELECTED_CHANNEL,
            data: channel
        }),
        selectSubChannelAction: (subChannel: SubChannel) => dispatch<IAddSubChannel>({
            type: ChannelActionTypes.ADD_SELECTED_SUBCHANNEL,
            data: subChannel
        }),
        selectThreadAction: (thread: Thread) => dispatch<IAddThread>({
            type: ChannelActionTypes.ADD_SELECTED_THREAD,
            data: thread
        }),
        removeThreadAction: () => dispatch<IRemoveThread>({
            type: ChannelActionTypes.REMOVE_SELECTED_THREAD
        }),
        removeSubChannelAction: () => dispatch<IRemoveSubChannel>({
            type: ChannelActionTypes.REMOVE_SELECTED_SUBCHANNEL
        }),
        removeChannelAction: () => dispatch<IRemoveChannel>({
            type: ChannelActionTypes.REMOVE_SELECTED_CHANNEL
        }),
        addToSubscriptionCartAction: (item: ItemForCart) =>
        dispatch<IAddToSubscriptionCart>({
          type: CartActionTypes.ADD_TO_SUBSCRIPTION_CART,
          data: item,
        }),
        
        broadCastMessage: (data: IBroadcastMessage) => dispatch<IBroadcastAddToCartMessage>({
            type: SearchBarActions.BROADCAST_ADD_TO_CART,
            data: data
        }),
        addToCartAction: (item: ItemForCart) =>
        dispatch<IAddToCart>({ type: CartActionTypes.ADD_TO_CART, data: item }),
        clearBroadcast: () =>
        dispatch<IClearBroadcast>({ type: LightBoxActionTypes.CLEAR_BROADCAST }),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ContentItem);