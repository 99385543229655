import gql from 'graphql-tag';

export const LOGIN_USER = gql`
    mutation loginUserMutation($email: String!, $password: String!) {
        login(email: $email, password: $password) {
            id
            email
            role
            username
            uploadFromOtherSources
            preferredCurrency
            hasPublicSFTPAccess
            token
            company {
                name
            }
            person {
                firstName
                lastName
            }
            hasCompletedOnboarding
            logInMaxAge
            logInTime
        }
    }
`;

export const CURRENT_USER = gql`
query getCurrentUser {
  currentUser {
    id
    email
    role
    username
    uploadFromOtherSources
    hasPublicSFTPAccess
    sftpAccessRequest
    __typename
  }
}
`;