import gql from 'graphql-tag';

export const GET_THREADS_FOR_SELECTED_SUBCHANNEL = gql`
    query getThreadsForSelectedSubchannelQuery($channelId: Int!, $subchannelIds:[Int!]!) {
        getThreadsForSelectedSubchannel(channelId: $channelId, subchannelIds:$subchannelIds)
        {   
            channel
            {
                code
            }
            subchannel
            {
                title
            }
            threads
            {
                id,
                title
            }
        }
    }`;

export const GET_SUBCHANNEL_FOR_SELECTED_CHANNEL = gql`
    query getSubchannelsForSelectedChannelsQuery($channelIds:[Int!]!) {
        getSubchannelsForSelectedChannels(channelIds:$channelIds)
        {
            channel
            {
                title,
                code
            }
            subchannels
            {
                id,
                title,
                channelId
            }
        }
    }`;

export const GET_ALL_LICENSING_PLANS = gql`
    query getAvailableLicensingPlansQuery{
        getAvailableLicensingPlans{
            id
            amount
            type
            totalPrice
            period
            licensingType
        }
    }`;

export const GET_COMPANY_TYPES = gql`
   query getCompanyTypesQuery{
        getCompanyTypes
   }
`;
export const GET_CONTENT_TYPE = gql`
    query getContentTypesOnboardingQuery{
        getContentTypesOnboarding
    }
`;

export const GET_JOB_TITLE_CONTRIBUTER = gql`
    query getJobTitleContributerQuery{
        getJobTitleContributors
    }
`;

export const GET_JOB_TITLE_PROMEMBER = gql`
    query getJobTitleProMemberQuery{
        getJobTitleProMembers
    }
`

export const GET_LICENSE_TYPES = gql`
    query getLicenseTypesQuery{
        getLicenseTypes
    }
`;

export const GET_CURRENCIES = gql`
    query getCurrenciesQuery{
    getCurrencies
    }
`;

export const ADD_GENERAL_INFORMATION = gql`
    mutation addGeneralInformation($userId: Int!, $firstName: String, $lastName: String, $organizationName: String, $yearOfBirth: Int, $vatNumber: String!, $emailAccounting: String, $emailNewsletter: String, $userContentType: String, $companyType: String){
        addGeneralInformation(userId: $userId, input: { firstName: $firstName, lastName: $lastName, organizationName: $organizationName, yearOfBirth: $yearOfBirth, vatNumber: $vatNumber, emailAccounting: $emailAccounting, emailNewsletter: $emailNewsletter, userContentType: $userContentType, companyType: $companyType})
    }
`

export const ADD_RESPONSIBLE_PERSON = gql`
    mutation addResponsiblePerson($userId: Int!, $firstName: String!, $lastName: String!, $jobTitle: String!, $email: String!, $phoneNumber: String!) {
        addResponsiblePerson(userId: $userId, input: { firstName: $firstName, lastName: $lastName, jobTitle: $jobTitle, email: $email, phoneNumber: $phoneNumber})
    }
`

export const ADD_LICENSING = gql`
    mutation addLicensing($userId: Int!, $licensingType: String!, $prefferedCurrency: String!, $agreeMerchandise: Boolean!) {
        addLicensing(userId: $userId, input: { licensingType: $licensingType, prefferedCurrency: $prefferedCurrency, agreeMerchandise: $agreeMerchandise})
    }
`

export const ADD_ADDRESS = gql`
    mutation addAddress($userId: Int!, $country: String!, $countryCode: String!, $stateProvince: String!, $stateCode: String!, $city: String!, $addressType: String!, $addressLine1: String!, $addressLine2: String!, $zipCode: String!){
        addAddress(userId: $userId, locationInput: {country: $country, countryCode: $countryCode, stateProvince: $stateProvince, stateCode: $stateCode, city: $city}, addressInput: { addressType: $addressType, addressLine1: $addressLine1, addressLine2: $addressLine2, zipCode: $zipCode})
    }
`

export const ADD_CHANNELS_FOR_CONTENT = gql`
    mutation addChannelsForYourContent($userId: Int!, $channelIds: [Int!]!){
        addChannelsForYourContent(userId: $userId, input: {channelIds: $channelIds})
    }
`

export const ADD_LIST_OF_EDITORS = gql`
    mutation addListOfEditorsInformation($userId: Int!, $firstName: String!, $lastName: String!, $editorTitle: String!, $email: String!, $phoneNumber: String!){
        addListOfEditorsInformation(userId: $userId, input: { firstName: $firstName, lastName: $lastName, editorTitle: $editorTitle, email: $email, phoneNumber: $phoneNumber})
    }
`

export const ADD_ABOUT_YOU = gql`
    mutation addAboutYouInformation($userId: Int!, $file: Upload!, $shortBiography: String!){
        addAboutYouInformation(userId: $userId, file: $file, shortBiography: $shortBiography)
    }
`

export const ADD_ABOUT_YOU_WITHOUT_PROFILE_IMAGE = gql`
mutation addAboutYouWithoutProfileImage($userId: Int!, $shortBiography: String!){
    addAboutYouWithoutProfileImage(userId: $userId, shortBiography: $shortBiography)
    }
`

export const CHECK_USER_ONBOARDING_STATUS = gql`
    query userHasCompletedOnboarding {
        userHasCompletedOnboarding
    }
`

export const UPDATE_USER_ONBOARDING_STATUS = gql`
    mutation updateUserOnboardingStatus($userId: Int!) {
        updateUserOnboardingStatus(userId: $userId)
    }
`

export const GET_FULL_USER_DETAILS = gql`
    query getFullUserDetails($id: Int!) {
        getFullUserDetails(id: $id) {
            id
            email
            username
            company {
                name
            }
            person {
                firstName
                lastName
            }
        }
    }
`

export const VALIDATE_ADDRESS = gql`
    query validateAddressMutation($country: String!, $countryCode: String!, $stateProvince: String!, $stateCode: String!, $city: String!, $addressType: String!, $addressLine1: String!, $addressLine2: String!, $zipCode: String!) {
        validateAddress(locationInput: {country: $country, countryCode: $countryCode, stateProvince: $stateProvince, stateCode: $stateCode, city: $city}, addressInput: { addressType: $addressType, addressLine1: $addressLine1, addressLine2: $addressLine2, zipCode: $zipCode}) {
            country
            state
            city
            zip
            street
        }
    }
`;