
import * as Yup from 'yup';
import i18n from 'i18next'
import { InfoValues } from './onboarding-page.types';

export const PHONE_NUMBER_REGEX = /((?:9[679]|8[035789]|6[789]|5[90]|42|3[578]|2[1-689])|9[0-58]|8[1246]|6[0-6]|5[1-8]|4[013-9]|3[0-469]|2[70]|7|1)(?:\W*\d){0,13}\d$/;
export const VAT_NUMBER_REGEX = /^([a-zA-Z\d]{9,13})$/;
export const NAME_REGEX = /^[\w'\-,.][^0-9_!¡?÷?¿\\+=@#$%ˆ*(){}|~<>;:[\]]{2,}$/;
export const ADDRESS_REGEX = /^[a-zA-Z0-9\s,.'-]{3,100}$/;
export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const ZIP_CODE_REGEX = /^[a-z0-9][a-z0-9\- ]{0,10}[a-z0-9]$/;
export const ZIP_CODE_REGEX_UK = /^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$/;
export const maxNameLength = 100;
export const minNameLength = 1;

// General INFO
const OnboardingGeneralInfoDefaultYup = Yup.object({
    firstName: Yup.string().required(i18n.t(InfoValues.firstName)  + i18n.t('Onboarding.IsRequired')).matches(NAME_REGEX, i18n.t('Onboarding.Names.Regex')).max(maxNameLength, i18n.t('Onboarding.Names.Length')).min(minNameLength, i18n.t('Onboarding.Names.Length')),
    lastName: Yup.string().required(i18n.t(InfoValues.lastName)  + i18n.t('Onboarding.IsRequired')).matches(NAME_REGEX,  i18n.t('Onboarding.Names.Regex')).max(maxNameLength, i18n.t('Onboarding.Names.Length')).min(minNameLength, i18n.t('Onboarding.Names.Length')),
    yearOfBirth: Yup.number().typeError('You must specify a number.')
        .test('len', 'This is not a valid year', (val: any) => val && val.toString().length === 4)
        .min(1940, "Too early year")
        .max(new Date().getFullYear() - 18, i18n.t("Onboarding.Year.Max"))
        .required(i18n.t(InfoValues.yearOfBirth) + i18n.t('Onboarding.IsRequired')),
    gender: Yup.string().required(i18n.t(InfoValues.gender) + i18n.t('Onboarding.IsRequired'))
})


const OnboardingGeneralInfoIndividualYup = Yup.object({ 
    firstName: Yup.string().required(i18n.t(InfoValues.firstName) + i18n.t('Onboarding.IsRequired')).matches(NAME_REGEX, i18n.t('Onboarding.Names.Regex')).max(maxNameLength, i18n.t('Onboarding.Names.Length')).min(minNameLength, i18n.t('Onboarding.Names.Length')),
    lastName: Yup.string().required(i18n.t(InfoValues.lastName) + i18n.t('Onboarding.IsRequired')).matches(NAME_REGEX, i18n.t('Onboarding.Names.Regex')).max(maxNameLength, i18n.t('Onboarding.Names.Length')).min(minNameLength, i18n.t('Onboarding.Names.Length')),
    yearOfBirth: Yup.number().typeError('You must specify a number.')
        .min(1940, "Too early year")
        .test('len', 'This is not a valid year', (val: any) => val && val.toString().length === 4)
        .max(new Date().getFullYear() - 18, i18n.t("Onboarding.Year.Max"))
        .required(i18n.t(InfoValues.yearOfBirth) + i18n.t('Onboarding.IsRequired')),
    vatNumber: Yup.string().notRequired().matches(VAT_NUMBER_REGEX, "VAT number must be between 9 and 13 characters"),
})

const OnboardingGeneralInfoAgencyYup = Yup.object({ 
    organizationName: Yup.string().required(i18n.t("Onboarding.OrganizationName") + i18n.t('Onboarding.IsRequired')).max(maxNameLength, i18n.t('Onboarding.Names.Length')).min(minNameLength, i18n.t('Onboarding.Names.Length')),
    vatNumber: Yup.string().notRequired().matches(VAT_NUMBER_REGEX, "VAT number must be between 9 and 13 characters"),
})

const OnboardingGeneralInfoOrganizationYup = Yup.object({ 
    organizationName: Yup.string().required(i18n.t("Onboarding.OrganizationName") + i18n.t('Onboarding.IsRequired')).max(maxNameLength, i18n.t('Onboarding.Names.Length')).min(minNameLength, i18n.t('Onboarding.Names.Length')),
    vatNumber: Yup.string().notRequired().matches(VAT_NUMBER_REGEX, "VAT number must be between 9 and 13 characters"),
    companyType: Yup.string().required(i18n.t('Onboarding.CompanyType') + i18n.t('Onboarding.IsRequired')),
    contentType: Yup.string().required(i18n.t('Onboarding.ContentType') + i18n.t('Onboarding.IsRequired')),
    newsletterEmail: Yup.string().required(i18n.t('Onboarding.NewsletterEmail') + i18n.t('Onboarding.IsRequired')).matches(EMAIL_REGEX, i18n.t('Onboarding.Emails.Regex')),
    accountingEmail: Yup.string().required(i18n.t('Onboarding.AccountingEmail') + i18n.t('Onboarding.IsRequired')).matches(EMAIL_REGEX, i18n.t('Onboarding.Emails.Regex'))
})
const OnboardingGeneralInfoFreelancerYup = Yup.object({
    firstName: Yup.string().required(i18n.t(InfoValues.firstName) + i18n.t('Onboarding.IsRequired')).matches(NAME_REGEX, i18n.t('Onboarding.Names.Regex')).max(50, i18n.t('Onboarding.Names.Length')).min(1, i18n.t('Onboarding.Names.Length')),
    lastName: Yup.string().required(i18n.t(InfoValues.lastName) + i18n.t('Onboarding.IsRequired')).matches(NAME_REGEX, i18n.t('Onboarding.Names.Regex')).max(50, i18n.t('Onboarding.Names.Length')).min(1, i18n.t('Onboarding.Names.Length')),
    vatNumber: Yup.string().notRequired().matches(VAT_NUMBER_REGEX, "VAT number must be between 9 and 13 characters"),
    yearOfBirth: Yup.number().typeError('You must specify a number.')
        .min(1940, "Too early year")
        .test('len', 'This is not a valid year', (val: any) => val && val.toString().length === 4)
        .max(new Date().getFullYear() - 18, i18n.t("Onboarding.Year.Max"))
        .required(i18n.t(InfoValues.yearOfBirth) + i18n.t('Onboarding.IsRequired')),
    newsletterEmail: Yup.string().required(i18n.t('Onboarding.NewsletterEmail') + i18n.t('Onboarding.IsRequired')).matches(EMAIL_REGEX, i18n.t('Onboarding.Emails.Regex')),
    accountingEmail: Yup.string().required(i18n.t('Onboarding.AccountingEmail') + i18n.t('Onboarding.IsRequired')).matches(EMAIL_REGEX, i18n.t('Onboarding.Emails.Regex'))
})

export const OnboardingGeneralInfoAllYup = {
    OnboardingGeneralInfoDefaultYup,
    OnboardingGeneralInfoIndividualYup,
    OnboardingGeneralInfoAgencyYup,
    OnboardingGeneralInfoOrganizationYup,
    OnboardingGeneralInfoFreelancerYup
}

// Address

const orRegex = (regexes: RegExp[]) => {
    return new RegExp(regexes.map(regex => regex.source).join('|'));
}

const OnboardingAddressDefaultYup = Yup.object({
    country: Yup.string().required(i18n.t('Onboarding.Address.Country') + i18n.t('Onboarding.IsRequired')),
    stateProvince: Yup.string().required(i18n.t('Onboarding.Address.StateProvince') + i18n.t('Onboarding.IsRequired')),
    zipCodeNumber: Yup.string().required(i18n.t('Onboarding.ZipCode') + i18n.t('Onboarding.IsRequired'))
        .matches(orRegex([ZIP_CODE_REGEX, ZIP_CODE_REGEX_UK]), i18n.t("Onboarding.ZipCode.Regex")),
    city: Yup.string().required(i18n.t('Onboarding.Address.City') + i18n.t('Onboarding.IsRequired')),
    addressLineOne: Yup.string().required(i18n.t('Onboarding.Address.Error.Address') + i18n.t('Onboarding.IsRequired')).matches(ADDRESS_REGEX, i18n.t('Onboarding.Address.Regex')),
    addressLineTwo: Yup.string().notRequired().matches(ADDRESS_REGEX, i18n.t('Onboarding.Address.Regex')),

})

const OnboardingAddressMemberYup = Yup.object({
    country: Yup.string().required(i18n.t('Onboarding.Address.Country') + i18n.t('Onboarding.IsRequired')),
    stateProvince: Yup.string().required(i18n.t('Onboarding.Address.StateProvince') + i18n.t('Onboarding.IsRequired')),
    city: Yup.string().required(i18n.t('Onboarding.Address.City') + i18n.t('Onboarding.IsRequired')),
})


export const OnboardingAddressAllYup = {
    OnboardingAddressDefaultYup,
    OnboardingAddressMemberYup
}

//Account Person
const OnboardingAccountPersonDefaultYup = Yup.object({ 
    firstName: Yup.string().required(i18n.t(InfoValues.firstName)  + i18n.t('Onboarding.IsRequired')).matches(NAME_REGEX,  i18n.t('Onboarding.Names.Regex')).max(maxNameLength, i18n.t('Onboarding.Names.Length')).min(minNameLength, i18n.t('Onboarding.Names.Length')),
    lastName: Yup.string().required(i18n.t(InfoValues.lastName)  + i18n.t('Onboarding.IsRequired')).matches(NAME_REGEX,  i18n.t('Onboarding.Names.Regex')).max(maxNameLength, i18n.t('Onboarding.Names.Length')).min(minNameLength, i18n.t('Onboarding.Names.Length')),
    jobTitle: Yup.string().required(i18n.t('Onboarding.JobTitle.Placeholder')  + i18n.t('Onboarding.IsRequired')),
    email:Yup.string().required(i18n.t('Onboarding.EmailAddress')  + i18n.t('Onboarding.IsRequired')).matches(EMAIL_REGEX, i18n.t('Onboarding.Emails.Regex')),
    phoneNumber: Yup.string().required(i18n.t('Onboarding.PhoneNumber')  + i18n.t('Onboarding.IsRequired')).matches(PHONE_NUMBER_REGEX, "Phone number is not valid").max(15, i18n.t('Onboarding.Phonenumber.Length')).min(5, i18n.t('Onboarding.Phonenumber.Length')),
})

const OnboardingAccountPersonMemberYup = Yup.object({ 
    firstName: Yup.string().required(i18n.t(InfoValues.firstName) + i18n.t('Onboarding.IsRequired')).matches(NAME_REGEX, i18n.t('Onboarding.Names.Regex')).max(maxNameLength, i18n.t('Onboarding.Names.Length')).min(minNameLength, i18n.t('Onboarding.Names.Length')),
    lastName: Yup.string().required(i18n.t(InfoValues.lastName) + i18n.t('Onboarding.IsRequired')).matches(NAME_REGEX, i18n.t('Onboarding.Names.Regex')).max(maxNameLength, i18n.t('Onboarding.Names.Length')).min(minNameLength, i18n.t('Onboarding.Names.Length')),
    email:Yup.string().required(i18n.t('Onboarding.EmailAddress')  + i18n.t('Onboarding.IsRequired')).matches(EMAIL_REGEX, i18n.t('Onboarding.Emails.Regex')),
    phoneNumber: Yup.string().required(i18n.t('Onboarding.PhoneNumber')  + i18n.t('Onboarding.IsRequired')).matches(PHONE_NUMBER_REGEX, "Phone number is not valid").max(15, i18n.t('Onboarding.Phonenumber.Length')).min(5, i18n.t('Onboarding.Phonenumber.Length')),
})

export const OnboardingAccountPersonAllYup = {
    OnboardingAccountPersonDefaultYup,
    OnboardingAccountPersonMemberYup
}

//Licensing
const OnboardingLicensingDefaultYup = Yup.object({
    licenseType: Yup.string().required(i18n.t('Onboarding.StepOne.Licensing.LicensingType')),
    prefferedCurrency: Yup.string().required(i18n.t('Onboarding.StepOne.Licensing.Currency')),
    merchandiseAgreement: Yup.boolean().required(i18n.t('Onboarding.StepOne.Licensing.Agreement'))
});


const OnboardingLicensingProORGYup = Yup.object({
    licenseType: Yup.string().required(i18n.t('Onboarding.StepOne.Licensing.LicensingType')),
    prefferedCurrency: Yup.string().required(i18n.t('Onboarding.StepOne.Licensing.Currency'))
});

export const OnboardingLicensingAllYup = {
    OnboardingLicensingDefaultYup,
    OnboardingLicensingProORGYup
}

//Editors list 
export const OnboardingEditorsList = Yup.object({ 
    firstName: Yup.string().matches(NAME_REGEX, i18n.t('Onboarding.Names.Regex')).max(maxNameLength, i18n.t('Onboarding.Names.Length')).min(minNameLength, i18n.t('Onboarding.Names.Length')),
    lastName: Yup.string().matches(NAME_REGEX, i18n.t('Onboarding.Names.Regex')).max(maxNameLength, i18n.t('Onboarding.Names.Length')).min(minNameLength, i18n.t('Onboarding.Names.Length')),
    title: Yup.string(),
    email: Yup.string().matches(EMAIL_REGEX, i18n.t('Onboarding.Emails.Regex')),
    phoneNumber: Yup.string().matches(PHONE_NUMBER_REGEX, "Phone number is not valid").max(15, i18n.t('Onboarding.Phonenumber.Length')).min(5, i18n.t('Onboarding.Phonenumber.Length')),
});

//Card Details
export const CardDetailsList = Yup.object({
    firstName: Yup.string().matches(NAME_REGEX, i18n.t('Onboarding.Names.Regex')).required(),
    lastName: Yup.string().matches(NAME_REGEX, i18n.t('Onboarding.Names.Regex')).required()
});
